import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { environment } from '../environments/environment';
import { CognitoService } from './cognito.service';
import { map, catchError } from 'rxjs/operators'; // Import map from 'rxjs/operators'
import { Roles } from '../constants/roles';
import { lastValueFrom } from 'rxjs';

interface Role {
  value: string;
  label: string;
}

interface UserRole {
  user: string;
  role: number;
  label?: string;
}

interface UserObject {
  user: string;
  role: string;
}

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  public rolesArray: any = [];
  public userRoles: UserRole[] = [];
  public Roles = Roles;
  public roles: any = [];
  public modifiedData: any;

  private userRoleChange = new Subject<void>;
  userRoleChange$ = this.userRoleChange.asObservable();

  constructor(private http: HttpClient, private cognitoService: CognitoService) {
    this.userRoles = [];
  }

  // Function that get the role for a user from the DB
  async getRolesForUser(user: string): Promise<Observable<any>> {
    const url = environment.api.stage + environment.api.route.getUserRoles + "&user=" + user;

    return this.http.get(url).pipe(
      map((response: any) => {
        if (Array.isArray(response)) {
          /*  ////////////////////////////
              If you need role as number please DO NOT change the function return.
              Call the function and then use this.modifiedData that is the same array but in numbers.
              THANKS.
          */  ///////////////////////////
          this.modifiedData = response.map((item: UserObject) => parseInt(item.role, 10));

          return response; ///////// DO NOT TOUCH /////////
        } else {
          console.error('Invalid response format:', response);
          throw new Error('Invalid response format');
        }
      }),
      catchError((error) => {
        if (error.status === 404) {
          return of([]);
        } else {
          console.error('API Error:', error);
          throw error; // Re-throw the error for the calling code to handle
        }
      })
    );
  }


  //returns a string-literal array for Role labels
  async getRoles(): Promise<string[]> {
    try {
      let response: any;
      //Use Cognito Service to fetch the User data.
      if(this.cognitoService.userInfos === '' || this.cognitoService.userInfos === undefined){
        response = await this.cognitoService.getUser();
      }else{
        response = this.cognitoService.userInfos;
      }

      //To convert an Observable into a Promise, we use lastValueFrom()
      //Call a Lambda function to retrieve all Roles associated to a User
      //response.attributes.sub is the User ID needed for the query.
      this.roles = await lastValueFrom(await this.getRolesForUser(response.attributes.sub));

      //Convert the returned JSON object into a simple Array of strings (Role names)
      this.roles = this.roles.map((roleValue: any) => {
        const role = Roles.find((row) => row.value === roleValue.role); // was roleValue.role.toString());
        return role ? role.label : '';
      });


      //Return the array
      return this.roles;
    } catch (error) {

      console.error('Error:', error);
      //Return an empty array in case of errors to avoid janky behaviour
      return [];
    }
  }

  // Function to delete all user roles using a Lambda function
  public async deleteAllUserRole(sub: string) {
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    try{
      // Call the http post request to delete all user roles
      return this.http.post(environment.api.stage + environment.api.route.deleteUserRoles,
        {
          "sub": sub
        }, {headers: headers}
      ).subscribe((response) => {
      });
    } catch(error){
      console.error('Error: ' + error);
      return error;
    }

  }

  // Function to update user role using a Lambda function
  public async updateUserRole(sub: string, role: number) {
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    try {
      // Make an HTTP GET request to the constructed URL
      return this.http.post(environment.api.stage + environment.api.route.updateUserRole,
        {
          "sub": sub,
          "role": role
        }, {headers: headers}
      ).subscribe((response) => {
      });
    } catch (error) {
      // Log and rethrow any errors that occur during the API call
      console.error('API Error:', error);
      throw error;
    }
  }

  // Function called by componnet to get the role labels
  addLabel = (item: UserRole): UserRole => {
    switch (item.role) {
      case 0:
        item.label = '_admin';
        break;
      case 1:
        item.label = '_client';
        break;
      case 2:
        item.label = '_distributor';
        break;
      case 3:
        item.label = '_collections';
        break;
      case 4:
        item.label = '_operations';
        break;
      default:
        item.label = '';
    }
    return item;
  };

  // Function used to toggle roles in user-update components
  toggleRoleUpdate(role: any, userSub: string) {
    // To avoid error if this.userRoles is undefined
    if(this.userRoles === undefined){
      this.userRoles = [];
    }

    if (userSub && role) {
      let index = -1;
      if(this.userRoles){
        index = this.userRoles.findIndex((userRole: any) => userRole.label === role);
      }

      switch (role) {

        case '_collections':
          if (index === -1) {
              // add _collections role as an object
              this.userRoles.push({
                  user: userSub,
                  role: 3,
                  label: '_collections'
              });
          } else {
              // remove _collections role from userRoles array
              this.userRoles.splice(index, 1);
          }
          break;

        case '_client':
          if (index === -1) {
              // add _client role as an object
              this.userRoles.push({
                  user: userSub,
                  role: 1,
                  label: '_client'
              });
          } else {
              // remove _client role from userRoles array
              this.userRoles.splice(index, 1);
          }
          break;

        case '_admin':
          if (index === -1) {
              // add _admin role as an object
              this.userRoles.push({
                  user: userSub,
                  role: 0,
                  label: '_admin'
              });

          } else {
              // remove _admin role from userRoles array
              this.userRoles.splice(index, 1);
          }
          break;

        case '_distributor':
          if (index === -1) {
              // add _distributor role as an object
              this.userRoles.push({
                  user: userSub,
                  role: 2,
                  label: '_distributor'
              });

          } else {
              // remove _distributor role from userRoles array
              this.userRoles.splice(index, 1);
          }
          break;

        case '_operations':
          if (index === -1) {

              this.userRoles.push({
                  user: userSub,
                  role: 4,
                  label: '_operations'
              });

          } else {
              // remove _operations role from userRoles array
              this.userRoles.splice(index, 1);
          }
          break;

        default:
          break;
        }
    } else {
        console.error('this.userRoles, role, or role.role is undefined');
    }
    this.userRoleChange.next();
  }
}
