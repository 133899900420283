import { ModalService } from './../service/device-modal.service';
import { Component, ElementRef, Inject, OnInit, ViewChild, forwardRef } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { CognitoService } from '../service/cognito.service';
import { IotService } from '../service/iot.service';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';
import { RoleService } from '../service/role.service';
import { BinsService } from '../service/bins.service';
import { environment } from '../environments/environment';
import { lastValueFrom } from 'rxjs';
import { DashboardService } from '../service/dashboard.service';
import { CsvService } from '../service/csv.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartService } from '../service/chart.service';
import { PdfService } from '../service/pdf.service';
import { ReportsService } from '../service/reports.service';

export interface ThingList {
  thingName: string;
  status?: string;
  battery_percentage?: string;
  fill_level?: string
}

interface TimestampObject {
  timestamp: number;
}

@Component({
  selector: 'app-client-thing-list',
  templateUrl: './client-thing-list.component.html',
  styleUrls: ['./client-thing-list.component.css', '../../dashboard-tiles.css', '../../global-elements.css']
})

export class ClientThingListComponent implements OnInit {
  @ViewChild('usageCanvas') usageCanvas!: ElementRef;
  @ViewChild('marketCanvas') marketCanvas!: ElementRef;
  @ViewChild('countCanvas') countCanvas!: ElementRef;
  @ViewChild('tonnageCanvas') tonnageCanvas!: ElementRef;

  // Variable to store the language selected value
  public selectedLanguage: string = "";

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  public userSub: any;
  public userUsername: any;
  public userType: any;

  constructor(
    private localStorageService: LocalStorageService,

    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private cognitoService: CognitoService,
    public iotService: IotService,
    public theme: ThemeService,
    public systemMessage: SystemMessageService,
    private binService: BinsService,
    public dashboardService: DashboardService,
    public modal: ModalService,
    public csvExportService: CsvService,
    private route: ActivatedRoute,
    public roleService: RoleService,
    public charts: ChartService,
    public pdf: PdfService,
    public reports: ReportsService,
    private router: Router,

    ){
      this.route.queryParams.subscribe(params => {
        this.userSub = params['userSub'];
        this.userUsername = params['userUsername'];
        this.userType = params['userType'];
    });

      this.cognitoService.confirmValidUser();
      // Check if the user has selected a language in local storage
      //or use a default language
      if (this.languageStatus == null){
        // Set the default language to French
        translate.use('fr');
      } else {
        // Set the default language to the user's selected language
        translate.use(this.languageStatus);
      }
  }

  public dataLoaded: boolean = false;
  public devicesArray: any[] = [];
  public clientThingArray: any[] = [];
  public sortedArray: any[] = [];
  public filteredArray: any[] = [];
  public userClientId: string = ''; // change to id CLd52da630f05de7fdd9 you want to make a test
  public stopLoading: boolean = false;
  public cognitoUsersArray: any[] = [];
  public userFound: any;
  public userData: any;
  public currentClientId: any;
  public thingNames: any;
  public userFilter: string = '';
  public statusFilter: string = '';
  public filterBy: string = '';
  public sortBy: string = '';
  public ascDesc: number | boolean = 1
  public showList: boolean = false;
  public filterOn: boolean = false;
  public originalArray: any[] = []

  public showReportModalStatus: boolean = false;

  showReportModal() {
    // this.showReportModalStatus = true;
    this.reports.showReportModal = true;
  }

  ngAfterViewInit(): void {

    this.pdf.usageCanvas = this.usageCanvas;
    this.pdf.marketCanvas = this.marketCanvas;
    this.pdf.countCanvas = this.countCanvas;
    this.pdf.tonnageCanvas = this.tonnageCanvas;

    // Call the function to detect if the user has reached the end of the page on a mobile device
    // this.detectEndOfPageOnMobile();
  }

  async ngOnInit(): Promise<void> {

    await this.initData();

  }

  /**
   * Asynchronous function to retrieve the bin ID associated with a given thingName.
   * @param thingName - The name of the thing for which to find the bin ID.
   * @returns A Promise that resolves to the bin ID if found, or an empty string if not found.
   */
  async getBinId(thingName: string): Promise<string> {
    // Clear the binArray in the modal
    this.modal.binArray = [];

    // Set binArray using the setBinDetailArray method from the binService
    this.modal.binArray = await this.binService.setBinDetailArray();

    // Get the list of relationships from your dashboard service
    const relationships = this.dashboardService.relationships;

    // Find the relationship in the list based on the thingName
    const relationshipFound = relationships.find(rel => rel.thing_name === thingName);

    // If a relationship is found, return the bin_id; otherwise, return an empty string
    return relationshipFound ? relationshipFound.bin_id : '';
  }

  // Function to sort the array of items by the "binFillLevel" property
  // applySorting: Optional parameter to conditionally apply sorting (default is true)
  sortItemsByFillLevel(applySorting: boolean = true) {
    // Check if sorting should be applied and if the current sorting option is "binFillLevel"
    if (applySorting && this.sortBy === 'binFillLevel') {
        // Use the sort method to rearrange the items in the filteredArray
        this.filteredArray.sort((a, b) => {
            // Sort in ascending or descending order based on the checkbox
            const sortOrder = this.ascDesc ? 1 : -1;

            // If the status is "Inactive", move it to the end, regardless of fillLevel
            if (a.status === 'Inactive' && b.status !== 'Inactive') {
                return 1;
            } else if (a.status !== 'Inactive' && b.status === 'Inactive') {
                return -1;
            }

            // Parse the string values into numeric values for fillLevel
            const aFillLevel = parseFloat(a.fillLevel.replace('%', ''));
            const bFillLevel = parseFloat(b.fillLevel.replace('%', ''));

            // Compare the "fillLevel" properties of items
            if (aFillLevel < bFillLevel) {
                return -1 * sortOrder;
            } else if (aFillLevel > bFillLevel) {
                return 1 * sortOrder;
            } else {
                // If fillLevel values are equal, maintain the existing order
                return 0;
            }
        });
    }
  }

  // Function to sort the array of items by the "lastUpdate" property
  // applySorting: Optional parameter to conditionally apply sorting (default is true)
  sortItemsByLastUpdate(applySorting: boolean = true) {
    // Check if sorting should be applied and if the current sorting option is "lastUpdate"
    if (applySorting && this.sortBy === 'lastUpdate') {
        // Use the sort method to rearrange the items in the filteredArray
        this.filteredArray.sort((a, b) => {
            // Sort in ascending or descending order based on the checkbox
            const sortOrder = this.ascDesc ? 1 : -1;

            // If the status is "Inactive", move it to the end, regardless of lastUpdate
            if (a.status === 'Inactive' && b.status !== 'Inactive') {
                return 1;
            } else if (a.status !== 'Inactive' && b.status === 'Inactive') {
                return -1;
            }

            // Compare the "lastUpdate" properties (timestamps) of items
            if (a.lastUpdate < b.lastUpdate) {
                return -1 * sortOrder;
            } else if (a.lastUpdate > b.lastUpdate) {
                return 1 * sortOrder;
            } else {
                // If lastUpdate values are equal, maintain the existing order
                return 0;
            }
        });
    }
  }

  // Function to sort the array of items by the "battery" property
  // applySorting: Optional parameter to conditionally apply sorting (default is true)
  sortItemsByBattery(applySorting: boolean = true) {
    // Check if sorting should be applied and if the current sorting option is "battery"
    if (applySorting && this.sortBy === 'battery') {
      // Use the sort method to rearrange the items in the filteredArray
      this.filteredArray.sort((a, b) => {
        // Sort in ascending or descending order based on the checkbox
        const sortOrder = this.ascDesc ? 1 : -1;

        // If the status is "Inactive", move it to the end, regardless of battery value
        if (a.status === 'Inactive' && b.status !== 'Inactive') {
          return 1;
        } else if (a.status !== 'Inactive' && b.status === 'Inactive') {
          return -1;
        }

        // Compare the "battery" property of items
        if (a.battery < b.battery) {
          return -1 * sortOrder;
        } else if (a.battery > b.battery) {
          return 1 * sortOrder;
        } else {
          // If battery values are equal, maintain the existing order
          return 0;
        }
      });
    }
  }

  // Function called when there is a change in the sorting option or checkbox
  onSortChange(trigger: string) {
    // Toggle between ascending and descending order
    this.ascDesc = !this.ascDesc;

    // Use a switch statement to handle different sorting options
    switch (this.sortBy) {
      case 'battery':
        // Call the sorting function for battery
        this.sortItemsByBattery();
        break;

      case 'binFillLevel':
        // Call the sorting function for bin fill level
        this.sortItemsByFillLevel();
        break;

      case 'lastUpdate':
        // Call the sorting function for last update
        this.sortItemsByLastUpdate();
        break;

      // Add more cases for additional sorting options if needed

      default:
        // Handling for cases not covered by the existing options
        break;
    }
  }

  // Function to retrieve thing names associated with a given client ID
  // clientId: The client ID for which to fetch thing names
  // Returns an array of matching thing names
  getThingNamesByClientId(clientId: string): string[] {
    // Array to store matching thing names
    const matchingThingNames: string[] = [];

    // Iterate through relationships in the dashboard service
    this.dashboardService.relationships.forEach(item => {
        // Check if the client ID matches the specified client ID
        if (item.client_id === clientId) {
            // Add the thing name to the array if the client ID matches
            matchingThingNames.push(item.thing_name);
        }
    });

    // Return the array of matching thing names
    return matchingThingNames;
  }

  resetDataStatus() {
    this.filteredArray = [];
    this.devicesArray = [];
    this.thingNames = [];
  }

  // Asynchronous function to navigate to the update route for a specific bin
  async routeToUpdateBin(binId: string) {

    // Store the binId in a variable
    const id = binId;

    // Add the 'deviceList' item to the localStorageService
    this.localStorageService.addItem('lastUrl', 'deviceList')

    // Navigate to the 'bin-update' route with the specified binId
    this.router.navigate(['bin-update', id]);
  }

  /**
   * Asynchronously initializes the data for the dashboard.
   */
  async initData() {

    this.reports.showReportModal = false;

    this.resetDataStatus();

    // verify user
    await this.cognitoService.confirmValidUser();

    await this.cognitoService.getCurrentRole([environment.users.role.client], [environment.users.standardUser], );

    const currentUser = await this.cognitoService.getUser()

    this.currentClientId = currentUser.attributes['custom:client_id'];

    try {
        // Clear the array of devices to avoid information duplication
        this.iotService.resetDeviceArray();

        // Get the list of devices and await its completion
        const result = await this.iotService.getDeviceList();
        this.devicesArray = result;

        // console.log(this.devicesArray); // Log the devicesArray for debugging purposes

        // Get the relationships
        await lastValueFrom(await this.dashboardService.getRelationships());

        this.thingNames = this.getThingNamesByClientId(this.currentClientId)

        // Count application devices and parameters
        await this.dashboardService.countApplicationDevicesAndParameters();

        // Use the filteredArray from the dashboard service
        this.filteredArray = this.dashboardService.thingStatusArray;

        // Iterate through the devicesArray to update information in the filteredArray
        for (let i = 0; i < this.devicesArray.length; i++) {
            const binId = await this.getBinId(this.devicesArray[i].thingName)
            let binCapacity = this.modal.obtainBinDepthByBinId(await this.getBinId(this.devicesArray[i].thingName));
            let lastUpdate = this.devicesArray[i].lastUpdate;
            let location = "";
            location = this.devicesArray[i]?.bin?.bin_address;

            // Iterate over this.filteredArray to find the corresponding index
            for (let j = 0; j < this.filteredArray.length; j++) {
                if (this.filteredArray[j].thing_name === this.devicesArray[i].thingName) {
                    // Add binCapacity, fillLevel, and location to the corresponding object in this.filteredArray

                    let hasBin = false;


                    if (!binId || binId === '') {

                      hasBin = false;
                    } else {
                      hasBin = true;

                    }

                    
                    this.filteredArray[j].binCapacity = binCapacity;
                    this.filteredArray[j].fillLevel = this.iotService.checkFillLevelPercentage(this.filteredArray[j].distance_to_lid, binCapacity);
                    this.filteredArray[j].location = location;
                    this.filteredArray[j].lastUpdate = lastUpdate;
                    this.filteredArray[j].hasBin = hasBin;
                    this.filteredArray[j].binId = await this.getBinId(this.devicesArray[i].thingName)
                    

                    break; // Stop iteration when the match is found
                }
            }
        }

        // Define the order of status priority
        const statusOrder = ['Healthy', 'Unhealthy', 'Inactive'];

        // Sort the array based on the status order
        this.filteredArray.sort((a, b) => {
            const statusA = statusOrder.indexOf(a.status);
            const statusB = statusOrder.indexOf(b.status);

            return statusA - statusB;
        });

        this.dataLoaded = true; // Set dataLoaded to true when data initialization is complete
    } catch (error) {
        console.error('Error initializing data:', error);
    }
    // Update the filteredArray based on the specified thing names
    this.filteredArray = this.filterArrayByThingNames(this.filteredArray, this.thingNames);

    // Preserve the original state of filteredArray for reference
    this.originalArray = this.filteredArray;

    // console.log(this.filteredArray); // Log the filteredArray for debugging purposes

    this.stopLoading = true; // Set stopLoading to true to indicate the completion of data loading
  }

  // Function to filter an array of items by specified thing names
  // originalArray: The array of items to be filtered
  // thingNames: The list of thing names to filter by
  // Returns a new array containing only items with thing names present in the specified list
  filterArrayByThingNames(originalArray: any[], thingNames: string[]): any[] {
    // Use the filter method to create a new array with items matching the specified thing names
    return originalArray.filter(item => thingNames.includes(item.thing_name));
  }

  /**
   * Verifies and retrieves the status of a thing from the thingStatusArray in the dashboard service.
   * @param {string} thingName - The name of the thing to verify and retrieve the status for.
   * @returns {string | null} - The status of the specified thing, or "Inactive" if not found or unavailable.
   */
  verifyStatus(thingName: string): string | null {
    // Retrieve the thingStatusArray from the dashboard service
    const thingStatusArray = this.dashboardService.thingStatusArray;

    // Iterate through the array to find the matching thingName
    for (let i = 0; i < thingStatusArray.length; i++) {
        if (thingStatusArray[i].thing_name === thingName) {
            // Return the status if a match is found
            return thingStatusArray[i].status;
        }
    }

    // Return "Inactive" if no match is found or if the thingStatusArray is unavailable
    return "Inactive";
  }

  // Function to convert an array of timestamps to an array of formatted date strings
  // timestamps: An array of TimestampObject containing timestamp values
  // Returns a tuple with two formatted date strings
  convertTimestampsArrayToDates(timestamps: TimestampObject[]): [string, string] {
    // Check for valid input: the array should have exactly two timestamps,
    // and both timestamps should be valid (non-NaN) and non-negative.
    if (timestamps.length !== 2 || isNaN(timestamps[0].timestamp) || isNaN(timestamps[1].timestamp) || timestamps[0].timestamp < 0 || timestamps[1].timestamp < 0) {
        throw new Error('Invalid timestamps provided');
    }

    // Initialize an array to store formatted date strings with default empty values
    const dates: [string, string] = ["", ""];

    // Convert each timestamp to a formatted date string
    for (let i = 0; i < timestamps.length; i++) {
        const timestamp = timestamps[i].timestamp;

        // Convert timestamp to date and format it as a string
        const date = new Date(timestamp * 1000).toLocaleString();

        // Store the formatted date string in the dates array
        dates[i] = date;
    }

    // Return the tuple containing two formatted date strings
    return dates;
  }

  // Function to convert a timestamp to a formatted date string
  // timestamp: A timestamp value to be converted
  // Returns a formatted date string
  convertTimestampToDate(timestamp: number): string {
    // Check for valid input: the timestamp should be a valid (non-NaN) and non-negative number
    if (isNaN(timestamp) || timestamp < 0) {
        throw new Error('Invalid timestamp provided');
    }

    // Convert the timestamp to a Date object and format it as a string
    const date = new Date(timestamp * 1000).toLocaleString();

    // Return the formatted date string
    return date;
  }

  // Async function to export data to CSV
  async exportCSV(thing: any) {
    // Get device shadow information using IoT service
    const shadow = await this.iotService.getDeviceShadow(thing.thing_name);

    // Convert timestamp to date format
    let timestampTest = shadow.timestamp * 1000;
    let date = new Date(timestampTest).toLocaleString();

    // Set CSV file name using the thing_name property
    const fileName = `${thing.thing_name}.csv`;

    // Extract properties from the 'thing' object for CSV data

    // from metadata
    const metadataFirmwareVersion: string = this.convertTimestampToDate(shadow.metadata.reported.dat.fdv.timestamp) || "No Data Available";
    const metadataMcuTemperature: string = this.convertTimestampToDate(shadow.metadata.reported.dat.tmp.timestamp) || "No Data Available";
    const metadataSignalStrength: string = this.convertTimestampToDate(shadow.metadata.reported.dat.sig.timestamp) || "No Data Available";
    const metadataVccMeasurement: string = this.convertTimestampToDate(shadow.metadata.reported.dat.vcc.timestamp) || "No Data Available";
    const metadataBatteryVoltageRemaining: string = this.convertTimestampToDate(shadow.metadata.reported.dat.bat.timestamp) || "No Data Available";
    const metadataDistanceToLid: string = this.convertTimestampToDate(shadow.metadata.reported.dat.dst.timestamp) || "No Data Available";
    const metadataGpsTimestamps = shadow.metadata.reported.dat.gps;
    const metadataGps: string[] = metadataGpsTimestamps ? this.convertTimestampsArrayToDates(metadataGpsTimestamps) ||
    ["No Data Available", "No Data Available"] : ["No Data Available", "No Data Available"];
    const metadataTemperatureZero: string = this.convertTimestampToDate(shadow.metadata.reported.dat.tm0.timestamp) || "No Data Available";
    const metadataNewtworkConnectTime: string = this.convertTimestampToDate(shadow.metadata.reported.dat.nct.timestamp) || "No Data Available";
    const metadataAtmosphericPressure: string = this.convertTimestampToDate(shadow.metadata.reported.dat.pre.timestamp) || "No Data Available";
    const metadataTemperature: string = this.convertTimestampToDate(shadow.metadata.reported.dat.tm2.timestamp) || "No Data Available";
    const metadataHumidity: string = this.convertTimestampToDate(shadow.metadata.reported.dat.hum.timestamp) || "No Data Available";
    const timestampPHN = shadow?.metadata?.reported?.car?.phn?.timestamp;

    const metadataPHN: string = timestampPHN
        ? this.convertTimestampToDate(timestampPHN)
        : "No Data Available";


    const timestampIMEI = shadow?.metadata?.reported?.car?.imei?.timestamp;

    const metadataIMEI: string = timestampIMEI
        ? this.convertTimestampToDate(timestampIMEI)
        : "No Data Available";

    const metadataFMV: string = (shadow?.metadata?.reported?.car?.fmv?.timestamp) ? this.convertTimestampToDate(shadow.metadata.reported.car.fmv.timestamp)    : "No Data Available";

    const timestampICC = shadow?.metadata?.reported?.car?.icc?.timestamp;
    const metadataICC: string = timestampICC ? this.convertTimestampToDate(timestampICC) : "No Data Available";

    const timestampOPER = shadow?.metadata?.reported?.car?.oper?.timestamp;
    const metadataOPER: string = timestampOPER
        ? this.convertTimestampToDate(timestampOPER)
        : "No Data Available";

    const timestampHWI = shadow?.metadata?.reported?.car?.hwi?.timestamp;
    const metadataHWI: string = timestampHWI ? this.convertTimestampToDate(timestampHWI) : "No Data Available";

    const timestampDst = shadow?.metadata?.reported?.dst?.timestamp;

    const metadataDst: string = timestampDst ? this.convertTimestampToDate(timestampDst) : "No Data Available";

    const timestampWelcome = shadow?.metadata?.reported?.welcome?.timestamp;

    const metadataWelcome: string = timestampWelcome ? this.convertTimestampToDate(timestampWelcome) : "No Data Available";

    // from reported
    const reportedFirmwareVersion: string = shadow.state.reported.dat.fdv || "0.0.0";
    const reportedMcuTemperature: number = shadow.state.reported.dat.tmp ?? 0;
    const reportedSignalStrength: number = shadow.state.reported.dat.sig?? 0;
    const reportedVccMeasurement: number = shadow.state.reported.dat.vcc ?? 0;
    const reportedBatteryVoltageRemaining: number = shadow.state.reported.dat.bat ?? 0;
    const reportedDistanceToLid: number = shadow.state.reported.dat.dst ?? 0;
    const reportedGps: number[] = shadow.state.reported.dat.gps || [0, 0];
    const reportedTemperatureZero: number = shadow.state.reported.dat.tm0 ?? 0;
    const reportedNewtworkConnectTime: number = shadow.state.reported.dat.nct ?? 0;
    const reportedAtmosphericPressure: number = shadow.state.reported.dat.pre ?? 0;
    const reportedTemperature: number = shadow.state.reported.dat.tm2 ?? 0;
    const reportedHumidity: number = shadow.state.reported.dat.hum ?? 0;
    const reportedPHN: string = shadow?.state?.reported?.car?.phn ?? "No Data Available";

    const reportedIMEI: string = shadow?.state?.reported?.car?.imei ?? "No Data Available";

    const reportedFMV: string = shadow?.state?.reported?.car?.fmv ?? "No Data Available";

    const reportedICC: string = shadow?.state?.reported?.car?.icc ?? "No Data Available";

    const reportedOPER: string = shadow?.state?.reported?.car?.oper ?? "No Data Available";

    const reportedHWI: string = shadow?.state?.reported?.car?.hwi ?? "No Data Available";

    // from status
    const battery = thing.battery;
    const binCapacity = thing.binCapacity;
    const distanceToLid = thing.distance_to_lid;
    const fillLevel = thing.fillLevel;
    const location = thing.location;
    const status = thing.status;
    const thingName = thing.thing_name;
    const lastUpdate = date;
    let deviceCsv: any [] = []

    // swtich case to apply the right language to the labels of the csv file
    switch(this.charts.getDateLanguage()){
      case "en-CA":
        deviceCsv = [
          {
              "File Name": fileName,
              "Thing Name": thingName,
              "Battery": battery,
              "Bin Capacity": binCapacity,
              "Distance to Lid": distanceToLid,
              "Fill Level": fillLevel,
              "Location": location,
              "Status": status,
              "Last Update": lastUpdate,
              "Reported Firmware Version": reportedFirmwareVersion,
              "Reported MCU Temperature": reportedMcuTemperature,
              "Reported Signal Strength": reportedSignalStrength,
              "Reported VCC Measurement": reportedVccMeasurement,
              "Reported Battery Voltage Remaining": reportedBatteryVoltageRemaining,
              "Reported Distance to Lid": reportedDistanceToLid,
              "Reported GPS": reportedGps,
              "Reported Temperature Zero": reportedTemperatureZero,
              "Reported Network Connect Time": reportedNewtworkConnectTime,
              "Reported Atmospheric Pressure": reportedAtmosphericPressure,
              "Reported Temperature": reportedTemperature,
              "Reported Humidity": reportedHumidity,
              "Reported PHN": reportedPHN,
              "Reported IMEI": reportedIMEI,
              "Reported FMV": reportedFMV,
              "Reported ICC": reportedICC,
              "Reported OPER": reportedOPER,
              "Reported HWI": reportedHWI,
              "Metadata Firmware Version": metadataFirmwareVersion,
              "Metadata MCU Temperature": metadataMcuTemperature,
              "Metadata Signal Strength": metadataSignalStrength,
              "Metadata VCC Measurement": metadataVccMeasurement,
              "Metadata Battery Voltage Remaining": metadataBatteryVoltageRemaining,
              "Metadata Distance to Lid": metadataDistanceToLid,
              "Metadata GPS": metadataGps,
              "Metadata Temperature Zero": metadataTemperatureZero,
              "Metadata Network Connect Time": metadataNewtworkConnectTime,
              "Metadata Atmospheric Pressure": metadataAtmosphericPressure,
              "Metadata Temperature": metadataTemperature,
              "Metadata Humidity": metadataHumidity,
              "Metadata PHN": metadataPHN,
              "Metadata IMEI": metadataIMEI,
              "Metadata FMV": metadataFMV,
              "Metadata ICC": metadataICC,
              "Metadata OPER": metadataOPER,
              "Metadata HWI": metadataHWI,
              "Metadata DST": metadataDst,
              "Metadata Welcome": metadataWelcome
          } ]

      break;

      case "es-MX":
        deviceCsv = [
          {
            "Nombre de Archivo": fileName,
            "Nombre de la Cosa": thingName,
            "Batería": battery,
            "Capacidad del Contenedor": binCapacity,
            "Distancia a la Tapa": distanceToLid,
            "Nivel de Llenado": fillLevel,
            "Ubicación": location,
            "Estado": status,
            "Última Actualización": lastUpdate,
            "Versión del Firmware Informada": reportedFirmwareVersion,
            "Temperatura del MCU Informada": reportedMcuTemperature,
            "Fuerza de Señal Informada": reportedSignalStrength,
            "Medición VCC Informada": reportedVccMeasurement,
            "Voltaje de Batería Restante Informado": reportedBatteryVoltageRemaining,
            "Distancia a la Tapa Informada": reportedDistanceToLid,
            "GPS Informado": reportedGps,
            "Temperatura Cero Informada": reportedTemperatureZero,
            "Tiempo de Conexión a la Red Informado": reportedNewtworkConnectTime,
            "Presión Atmosférica Informada": reportedAtmosphericPressure,
            "Temperatura Informada": reportedTemperature,
            "Humedad Informada": reportedHumidity,
            "PHN Informado": reportedPHN,
            "IMEI Informado": reportedIMEI,
            "FMV Informado": reportedFMV,
            "ICC Informado": reportedICC,
            "OPER Informado": reportedOPER,
            "HWI Informado": reportedHWI,
            "Versión del Firmware en Metadata": metadataFirmwareVersion,
            "Temperatura del MCU en Metadata": metadataMcuTemperature,
            "Fuerza de Señal en Metadata": metadataSignalStrength,
            "Medición VCC en Metadata": metadataVccMeasurement,
            "Voltaje de Batería Restante en Metadata": metadataBatteryVoltageRemaining,
            "Distancia a la Tapa en Metadata": metadataDistanceToLid,
            "GPS en Metadata": metadataGps,
            "Temperatura Cero en Metadata": metadataTemperatureZero,
            "Tiempo de Conexión a la Red en Metadata": metadataNewtworkConnectTime,
            "Presión Atmosférica en Metadata": metadataAtmosphericPressure,
            "Temperatura en Metadata": metadataTemperature,
            "Humedad en Metadata": metadataHumidity,
            "PHN en Metadata": metadataPHN,
            "IMEI en Metadata": metadataIMEI,
            "FMV en Metadata": metadataFMV,
            "ICC en Metadata": metadataICC,
            "OPER en Metadata": metadataOPER,
            "HWI en Metadata": metadataHWI,
            "DST en Metadata": metadataDst,
            "Bienvenida en Metadata": metadataWelcome
        }];

      break;

      default:
        deviceCsv = [
          {
              "Nom de Fichier": fileName,
              "Nom de la Chose": thingName,
              "Batterie": battery,
              "Capacité de la Poubelle": binCapacity,
              "Distance au Couvercle": distanceToLid,
              "Niveau de Remplissage": fillLevel,
              "Emplacement": location,
              "Statut": status,
              "Dernière Mise à Jour": lastUpdate,
              "Version du Firmware Signalée": reportedFirmwareVersion,
              "Température du MCU Signalée": reportedMcuTemperature,
              "Force du Signal Signalée": reportedSignalStrength,
              "Mesure VCC Signalée": reportedVccMeasurement,
              "Tension de Batterie Restante Signalée": reportedBatteryVoltageRemaining,
              "Distance au Couvercle Signalée": reportedDistanceToLid,
              "GPS Signalé": reportedGps,
              "Température Zéro Signalée": reportedTemperatureZero,
              "Temps de Connexion au Réseau Signalé": reportedNewtworkConnectTime,
              "Pression Atmosphérique Signalée": reportedAtmosphericPressure,
              "Température Signalée": reportedTemperature,
              "Humidité Signalée": reportedHumidity,
              "PHN Signalé": reportedPHN,
              "IMEI Signalé": reportedIMEI,
              "FMV Signalé": reportedFMV,
              "ICC Signalé": reportedICC,
              "OPER Signalé": reportedOPER,
              "HWI Signalé": reportedHWI,
              "Version du Firmware dans les Métadonnées": metadataFirmwareVersion,
              "Température du MCU dans les Métadonnées": metadataMcuTemperature,
              "Force du Signal dans les Métadonnées": metadataSignalStrength,
              "Mesure VCC dans les Métadonnées": metadataVccMeasurement,
              "Tension de Batterie Restante dans les Métadonnées": metadataBatteryVoltageRemaining,
              "Distance au Couvercle dans les Métadonnées": metadataDistanceToLid,
              "GPS dans les Métadonnées": metadataGps,
              "Température Zéro dans les Métadonnées": metadataTemperatureZero,
              "Temps de Connexion au Réseau dans les Métadonnées": metadataNewtworkConnectTime,
              "Pression Atmosphérique dans les Métadonnées": metadataAtmosphericPressure,
              "Température dans les Métadonnées": metadataTemperature,
              "Humidité dans les Métadonnées": metadataHumidity,
              "PHN dans les Métadonnées": metadataPHN,
              "IMEI dans les Métadonnées": metadataIMEI,
              "FMV dans les Métadonnées": metadataFMV,
              "ICC dans les Métadonnées": metadataICC,
              "OPER dans les Métadonnées": metadataOPER,
              "HWI dans les Métadonnées": metadataHWI,
              "DST dans les Métadonnées": metadataDst,
              "Bienvenue dans les Métadonnées": metadataWelcome
          }
        ];
      break;
    }
    this.csvExportService.exportToCsv(deviceCsv, fileName);
  }

  /**
   * Retrieves the status of a thing from an array of thing statuses.
   * @param {string} thingName - The name of the thing to retrieve the status for.
   * @param {any[]} thingStatusArray - An array containing objects with thing_name and status properties.
   * @returns {string} - The status of the specified thing, or "Inactive" if not found.
   */
  private getStatusFromThingStatusArray(thingName: string, thingStatusArray: any[]): string {
    // Iterate through the array to find the matching thingName
    for (let i = 0; i < thingStatusArray.length; i++) {
        if (thingStatusArray[i].thing_name === thingName) {
            // Return the status if a match is found
            return thingStatusArray[i].status;
        }
    }

    // Return "Inactive" if no match is found
    return "Inactive";
  }

  getStatsForViewBin(thingName: string) {
    
  }

  /**
   * Gets the battery level style and information based on the provided battery level.
   * @param {any} batteryLevel - The battery level as any type (should be a number).
   * @returns {object} - An object containing icon, color, and title properties.
   */
  getBatteryLevel(batteryLevel: any): object {
    const result = {
        icon: '',
        color: '',
        title: '',
    };

    // Check if batteryLevel is a number and not NaN
    if (typeof batteryLevel === 'number' && !isNaN(batteryLevel)) {
        // Logic to set icon, color, and title based on the value of batteryLevel
        if (batteryLevel > 3.5) {
            return { color: '#63E6BE' };
        } else if (batteryLevel <= 3.5 && batteryLevel > 2.5) {
            return { color: '#63E6BE' };
        } else if (batteryLevel <= 2.5 && batteryLevel > 1.5) {
            return { color: '#FFD43B' };
        } else if (batteryLevel <= 1.5 && batteryLevel > 0) {
            return { color: '#f70202' };
        } else if (batteryLevel === 0) {
            return { color: '#f70202' };
        }

        result.title = `${batteryLevel}V`;
    } else {
        // If batteryLevel is not a number
        return { color: 'gray' };
    }

    return result;
  }

  /**
   * Gets the fill level style based on the provided fill level percentage.
   * @param {string} fillLevel - The fill level as a string with a percentage symbol (%).
   * @returns {object} - An object representing the style with a 'color' property.
   */
    getFillLevelStyle(fillLevel: string): object {
    // Remove the percentage symbol

    const fillLevelWithoutPercent: string = fillLevel ? fillLevel.replace('%', '') : '';

    // Convert to a floating-point number (float)
    const fillLevelFixed: number = parseFloat(fillLevelWithoutPercent);

    // Check if fillLevel is undefined
    if (fillLevel === undefined) {
      return { color: 'gray' }; // Style for gray color (default)
    }

    // Use the number for conditional comparisons
    if (fillLevelFixed <= 60) {
      return { color: 'green' }; // Style for green color
    } else if (fillLevelFixed <= 79.09) {
      return { color: '#B8860B' }; // Style for yellow color
    } else if (fillLevelFixed >= 79.09 && fillLevelFixed <= 100) {
      return { color: 'red'  }; // Style for red color
    } else {
      return { color: 'gray' }; // Style for inactive (default)
    }
  }

  // Function to clear the status filter and trigger a search reset
  onStatusFilterClear() {
    this.statusFilter = ''; // Clear the statusFilter

    // Call the search function to reset the array based on the cleared status filter
    this.search();
  }

  // Function to handle changes in the status filter
  onStatusFilterChange() {
    // Check if the status filter is null
    if (this.statusFilter === null) {
        // If null, call the search function to reset the array
        this.search();
    }

    // Always call the search function, regardless of the status filter value
    this.search();
  }

  // Function to filter data based on status, thing name, bin fill level, and battery level
  search() {
    // Check if data has been loaded
    if (this.dataLoaded) {
      // Use a switch statement to handle different filtering cases
      switch (true) {
        // Status case
        case this.statusFilter === 'Healthy':
        case this.statusFilter === 'Unhealthy':
        case this.statusFilter === 'Inactive':
          // Filter the original array based on status and user input
          this.filteredArray = this.originalArray.filter(item => {
            const isMatchingStatus = this.statusFilter === '' || item.status === this.statusFilter;
            const isMatchingUserFilter = this.userFilter.trim() === '' || item.thing_name.toLowerCase().includes(this.userFilter.toLowerCase());
            return isMatchingStatus && isMatchingUserFilter;
          });
          break;

        // Thing name case
        case this.userFilter.trim() !== '':
          // Filter the original array based on user input only
          this.filteredArray = this.originalArray.filter(item => {
            const isMatchingUserFilter = this.userFilter.trim() === '' || item.thing_name.toLowerCase().includes(this.userFilter.toLowerCase());
            return isMatchingUserFilter;
          });
          break;

        // Bin fill level cases
        case this.statusFilter === 'low':
        case this.statusFilter === 'medium':
        case this.statusFilter === 'high':
          // Filter the original array based on bin fill level and status
          this.filteredArray = this.originalArray.filter(item => {
            const fillLevelPercentage = parseFloat(item.fillLevel.replace('%', '').replace(',', '.'));

            // Determine the range based on the status filter
            let isMatchingFillLevel = false;
            if (this.statusFilter === 'low') {
              isMatchingFillLevel = fillLevelPercentage <= 60;
            } else if (this.statusFilter === 'medium') {
              isMatchingFillLevel = fillLevelPercentage <= 79.09 && fillLevelPercentage > 60;
            } else if (this.statusFilter === 'high') {
              isMatchingFillLevel = fillLevelPercentage >= 79.09 && fillLevelPercentage <= 100;
            }

            return isMatchingFillLevel;
          });
          break;

        // Battery level cases
        case this.statusFilter === 'lowBattery':
        case this.statusFilter === 'mediumBattery':
        case this.statusFilter === 'highBattery':
          // Filter the original array based on battery level and status
          this.filteredArray = this.originalArray.filter(item => {
            const batteryLevel = item.battery;
            const isMatchingBatteryLevel = (this.statusFilter === 'lowBattery' && batteryLevel >= 0 && batteryLevel <= 1.5) ||
                                          (this.statusFilter === 'mediumBattery' && batteryLevel > 1.5 && batteryLevel <= 2.5) ||
                                          (this.statusFilter === 'highBattery' && batteryLevel > 2.5);
            const isMatchingStatus = item.status !== 'Inactive';

            return isMatchingBatteryLevel && isMatchingStatus;
          });
          break;

        // Default case: no specific filter, return the original array
        default:
          this.filteredArray = [...this.originalArray];
          break;
      }
    }
  }
}

