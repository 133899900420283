import { Component, OnInit } from '@angular/core';
import { UserEditModalService } from '../service/user-edit-modal.service';
import { ThemeService } from '../service/theme.service';
import { CognitoService } from '../service/cognito.service';
import { LocalStorageService } from '../local-storage.service';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemMessageService } from '../service/system-message.service';

interface AlertPreference {
  label: string,
  value: number
}

@Component({
  selector: 'app-user-edit-preference',
  templateUrl: './user-edit-preference.component.html',
  styleUrl: './user-edit-preference.component.css'
})
export class UserEditPreferenceComponent implements OnInit{
  private userSub: string = '';
  private alertPreferencesFetchError: boolean = false;

  // public alertPreferencesArray: AlertPreference[] = []
  public alertPreferencesArray: any[] = [];
  public alertPreferencesSMSArray: any[] = [];
  public alertPreferencesEmailArray: any[] = [];
  public deviceUpdateEmail: number = 0;
  public deviceUpdateSms: number = 0;
  public workOrderUpdateEmail: number = 0;
  public workOrderUpdateSms: number = 0;
  public monthlyReportEmail: number = 0;
  public monthlyReportSms: number = 0;
  public isAdvancePreference: boolean = false;

  // Variable used for reference when we save preference without user knowledge
  private advanceOptionClicked: boolean = false;

  constructor(public userEditModalService: UserEditModalService,
              public theme: ThemeService,
              public cognitoService: CognitoService,
              private localStorageService: LocalStorageService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private systemMessageService: SystemMessageService){

  }

  async ngOnInit()   {
    this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();

    // Get the param that was pass from the bin-list of the bin_id selected
    this.activatedRoute.params.subscribe(params => {
      this.userSub = params['sub']; // Set bin_model_id to the param:id receive whit url
    });

    if(this.userSub === '' || this.userSub === undefined){
      this.userSub = await this.cognitoService.getCurrentUserSub();
    }

    await this.getUserAlertPreferences();
  }

  async saveAlertPreferences() {
    // Get all user things related
    await this.userEditModalService.getAllThings();

    // Set Cognito service properties with class properties
    this.cognitoService.deviceUpdateEmail = this.deviceUpdateEmail;
    this.cognitoService.deviceUpdateSms = this.deviceUpdateSms;
    this.cognitoService.workOrderUpdateEmail = this.workOrderUpdateEmail;
    this.cognitoService.workOrderUpdateSms = this.workOrderUpdateSms;
    this.cognitoService.monthlyReportEmail = this.monthlyReportEmail;
    this.cognitoService.monthlyReportSms = this.monthlyReportSms;

    switch(this.localStorageService.getItem('language')){
      case 'fr':
        this.cognitoService.preferedLanguage = 'fr-CA';
        break;
      case 'en':
        this.cognitoService.preferedLanguage = 'en-US';
        break;
      case 'es':
        this.cognitoService.preferedLanguage = 'es-MX';
        break;
      default:
        this.cognitoService.preferedLanguage = 'fr-CA';
        break;
    }

    // Call the function that made the call to lambda function and wait for is response
    (await this.cognitoService.updateUserAlertPreferenceDB(this.userSub, this.userEditModalService.things)).subscribe((response: any) => {
      const result  = response;
      // Check if the update was successful
      if(result.message === 'success' ) {
        // Check if reference variable use when user just want to go to advance option, we sava preference without user know it
        if(!this.advanceOptionClicked){
          // Add an item to local storage indicating successful alerts update
          this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
        }
        this.advanceOptionClicked = false;
      } else {
        // Add an item to local storage indicating fail alerts update
        this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      }
    });

  }

  async getUserAlertPreferences() {
    // Initialize the alertPreferencesArray with empty data to avoid duplicated items
    this.alertPreferencesArray = [];

    // Initialize the alertPreferencesFetchError status with false = no errors
    this.alertPreferencesFetchError = false;

    try {
      // Fetch user alert preferences from the Cognito service
      const res = await lastValueFrom(await this.cognitoService.getUserAlertPreferenceDB(this.userSub));
      // Loop through each key in the response object
      for (const key in res[0]) {
        // Check if the property belongs to the object itself (not the prototypes) and is not "user"
        if (res[0].hasOwnProperty(key) && key !== "user") {
          // Convert the value to a number
          const value = parseInt(res[0][key], 10);

          // Decide in which array to add the object based on the value of key
          if (key.includes('sms')) {
            this.alertPreferencesSMSArray.push({ label: key, value });
          } else if (key.includes('email')) {
            this.alertPreferencesEmailArray.push({ label: key, value });
          }
        }
      }

      // Map SMS alert preferences to corresponding class properties
      for (const item of this.alertPreferencesSMSArray) {
        switch (item.label) {
          case 'device_update_sms':
            this.deviceUpdateSms = item.value;
            break;
          case 'workorder_update_sms':
            this.workOrderUpdateSms = item.value;
            break;
          case 'monthly_report_sms':
            this.monthlyReportSms = item.value;
            break;
        }
      }

      // Map Email alert preferences to corresponding class properties
      for (const item of this.alertPreferencesEmailArray) {
        switch (item.label) {
          case 'device_update_email':
            this.deviceUpdateEmail = item.value;
            break;
          case 'workorder_update_email':
            this.workOrderUpdateEmail = item.value;
            break;
          case 'monthly_report_email':
            this.monthlyReportEmail = item.value;
            break;
        }
      }

      // no error = alertPreferencesFetchError status false = Alert Pref section will be displayed
      this.alertPreferencesFetchError = false;

    } catch (error) {
      // Log any errors that occur during the process
      console.error(error);
      // found error = alertPreferencesFetchError status true = Alert Pref section will not be displayed
      this.alertPreferencesFetchError = true;

    }
  }

  // Function call to open modal of advance preference
  openAvancedOptionModal(){
    // Set the variable to true, this variable is a reference because we save user preference by going to the modal and we don't necessairy waant that user know
    this.advanceOptionClicked = true;

    // Save preference to avoid have no row in alert preference table
    this.saveAlertPreferences();

    if(this.deviceUpdateEmail === 1 || this.deviceUpdateSms === 1 ||
        this.workOrderUpdateEmail === 1 || this.workOrderUpdateSms === 1 ||
        this.monthlyReportEmail === 1 || this.monthlyReportSms === 1){
          // Call the function to open the modal in modal service
          this.userEditModalService.openAvancedOptionModal();
    }else{
      this.systemMessageService.selectRibbon('info', 'mustHaveOneSelectedOption');
    }
  }

  // update class properties based on the provided label
  alertToggle(label: string){

    // Use a switch statement to update alert preferences properties based on the provided label
    switch (true) {

      case label === 'device_update_email':

        // Toggle the deviceUpdateEmail property between 0 and 1
        this.deviceUpdateEmail = (this.deviceUpdateEmail === 0) ? 1 : 0;

        break;

      case label === 'device_update_sms':

        // Toggle the deviceUpdateSms property between 0 and 1
        this.deviceUpdateSms = (this.deviceUpdateSms === 0) ? 1 : 0;

        break;

      case label === 'workorder_update_email':

        // Toggle the workOrderUpdateEmail property between 0 and 1
        this.workOrderUpdateEmail = (this.workOrderUpdateEmail === 0) ? 1 : 0;

        break;

      case label === 'workorder_update_sms':

        // Toggle the workOrderUpdateSms property between 0 and 1
        this.workOrderUpdateSms = (this.workOrderUpdateSms === 0) ? 1 : 0;

        break;

      case label === 'monthly_report_email':

        // Toggle the monthlyReportEmail property between 0 and 1
        this.monthlyReportEmail = (this.monthlyReportEmail === 0) ? 1 : 0;

        break;

      case label === 'monthly_report_sms':

        // Toggle the monthlyReportSms property between 0 and 1
        this.monthlyReportSms = (this.monthlyReportSms === 0) ? 1 : 0;

        break;

      default:
        break;

    }
  }

  // Function that send user back to edit page
  backToUserEdit(){
    this.router.navigate(['/user-edit']);
  }
}
