import { LocalStorageService } from './local-storage.service';
import { Location } from '@angular/common';
import { Injectable, OnInit, Inject, forwardRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class TranslationService implements OnInit{

  selectedLanguage: string = '';
  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');
  constructor(@Inject(forwardRef(() => TranslateService)) @Inject(forwardRef(() => TranslateService)) private translate: TranslateService, private localStorageService:LocalStorageService,
            private location: Location) {

  }
  ngOnInit(): void {

  }

  setLanguage(){
     // Check if the user has selected a language in local storage
      //or use a default language
      if (this.languageStatus == null){
        localStorage.setItem('language', 'fr')
        // Set the default language to French
        this.translate.use('fr');
      } else {
        // Set the default language to the user's selected language
        this.translate.use(this.languageStatus);
      }
  }


  // Define language used to translate
  translateTo(language:string) {
    localStorage.setItem('language', language)
    this.translate.use(language)
    if (window.location.pathname === '/dashboard' || window.location.pathname.includes('device-statistics')) {
        window.location.reload();
    }
  }
}


