
<div [ngClass]="theme.getThemeClass()" class="container-fluid g-0">

    <!-- HEADER -->
    <!-- ONLY DISPLAY HEADER IN OPERATION PAGES -->
    <app-header style="top: 0;" (sideNavToggled)="sideNavStatus = $event;" [ngStyle]="{'z-index': !this.modal.showDeviceModal ? 2 : 'initial'}"></app-header>

    <!-- MAIN -->
    <!-- MAIN CONTENT FOR ACESS PAGE -->
    <router-outlet *ngIf="IsLoginPageActive()" style="flex-grow: 1;"></router-outlet>

    <!-- MAIN CONTENT FOR OPERATION PAGE-->
    <main  *ngIf="!IsLoginPageActive()">

        <!-- SIDE NAV BAR -->
        <!-- DESKTOP - SHOW collapsing SIDE NAV BAR (screenWidthDeadLine = 450) -->
        <div class="hide-small-screen" *ngIf="!smallscreen">
            <app-side-nav  [sideNavStatus]="sideNavStatus" [ngClass]="{'app-side-nav-open': sideNavStatus}"  class="black-border"></app-side-nav>
        </div>

        <!-- DISPLAY CONTENT OF OPERATION PAGES -->
        <!-- DESKTOP -->
        <div class="display-area p-0" [ngClass]="{'display-area-shrink': sideNavStatus}">
            <router-outlet   style="flex-grow: 1;"></router-outlet>
        </div>

    </main>

    <!-- FOOTER - DISPLAYED IN EVERY PAGE  -->
    <app-footer></app-footer>

</div>
