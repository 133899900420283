<div [ngClass]="theme.getThemeClass()" class="container-fluid" (window:scroll)="onScroll()">
<!-- Menu IOT COMPONENT -->
  <header>
    <nav [ngClass]="theme.getThemeClass()" class="navbar navbar-expand-lg navbar-light bg-light">
      <div [ngClass]="theme.getThemeClass()" class="navbar-nav flex-nowrap scale" id="navbarNavAltMarkup">
        <a class="nav-item nav-link" href="javascript:void(0)"
        (click)="apiClicked()" [ngClass]="{'active' : isApiActive}">
        {{ 'logsNavBtnApi' | translate }}
        </a>
        <a class="nav-item nav-link" href="javascript:void(0)"
        (click)="actionsClicked()" [ngClass]="{'active' : isActionsActive}">
        {{ 'logsNavBtnActions' | translate }}
        </a>
        <a class="nav-item nav-link" href="javascript:void(0)"
        (click)="eventsClicked()" [ngClass]="{'active' : isEventsActive}">
        {{ 'logsNavBtnEvents' | translate }}
        </a>
        <!-- Hides two buttons to fit small screen view -->
        <a class="nav-item nav-link hide-small-screen" href="javascript:void(0)"
        (click)="systemsClicked()" [ngClass]="{'active' : isSystemsActive}">
        {{ 'logsNavBtnSystems' | translate }}
        </a>
        <a class="nav-item nav-link hide-small-screen" href="javascript:void(0)"
        (click)="notificationsClicked()" [ngClass]="{'active' : isNotificationsActive}">
        {{ 'logsNavBtnNotifications' | translate }}
        </a>
      </div>
      <!-- Show two buttons to fit small screen view -->
      <div [ngClass]="theme.getThemeClass()" class="navbar-nav flex-nowrap custom" id="navbarNavAltMarkup">
        <a class="nav-item nav-link show-small-screen hide-big-screen" href="javascript:void(0)"
        (click)="systemsClicked()" [ngClass]="{'active' : isSystemsActive}">
        {{ 'logsNavBtnSystems' | translate }}
        </a>
        <a class="nav-item nav-link show-small-screen hide-big-screen" href="javascript:void(0)"
        (click)="notificationsClicked()" [ngClass]="{'active' : isNotificationsActive}">
        {{ 'logsNavBtnNotifications' | translate }}
        </a>
      </div>
    </nav>
  </header>
  <!-- API CONTENT -->
    <div id="search-component" class="input-group" *ngIf="mainContent === 'api'">
      <input #searchBox type="search"  id="search-box" [(ngModel)]="userFilterTest" class="form-control rounded"  placeholder="Filter" aria-label="Search" aria-describedby="search-addon" />
    </div>
    <div [ngClass]="theme.getThemeClass()" class="table-header" *ngIf="mainContent === 'api'">
      <span class="left">{{ 'logsApiTableLabelApiLogs' | translate }}</span>
    </div>
    <div [ngClass]="theme.getThemeClass()" class="table-container" *ngIf="mainContent === 'api'">
      <div  class="table-responsive ">
        <table  class="table table-hover">
          <thead >
            <tr>
              <th [ngClass]="theme.getThemeClass()" scope="col">{{ 'logsApiTableHeaderDevice' | translate }}</th>
              <th [ngClass]="theme.getThemeClass()" scope="col">{{ 'logsApiTableHeaderModel' | translate }}</th>
              <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">{{ 'logsApiTableHeaderType' | translate }}</th>
              <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">{{ 'logsApiTableHeaderClient' | translate }}</th>
              <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">{{ 'logsApiTableHeaderRole' | translate }}</th>
            </tr>
          </thead>
          <ng-container>
            <tbody>
              <tr class="tableRow">
              </tr>
            </tbody>
          </ng-container>
        </table>
      </div>
    </div>
    <br>
  </div>
