<app-system-message></app-system-message>

<div [ngClass]="theme.getThemeClass()" class="container-fluid">  
  <!-- Search input component, shown when mainContent is 'clients' -->
  <header id="search-component" class="input-group">
    <input #searchBox type="search"  id="search-box" [(ngModel)]="userFilterTest" (input)="search()" class="form-control rounded" 
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}" aria-label="Search" aria-describedby="search-addon" />
  </header>

  <!-- Table header for the client list, shown when mainContent is 'clients' -->
  <nav [ngClass]="theme.getThemeClass()" class="table-header" >
    <span class="left customScale1">{{ 'clientList' | translate }}  <a (click)="refreshList()"><i class="fas fa-sync"></i></a> </span>
    <span class="right customScale2"><a routerLink="/client-create" href="javascript:void(0)">&#x2b; {{ 'userlistTableHeaderNew' | translate }} <span class="hide-small-screen">{{ 'userlistTableHeaderNewClient' | translate }}</span></a></span>
  </nav>

  <!-- Table container for the user list, shown when mainContent is 'clients' -->
  <section [ngClass]="theme.getThemeClass()" class="table-container width100">
    <article  class="table-responsive minMarginBotton">
      <table  class="table table-hover">
        <thead >
          <tr>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ 'name' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'legalName' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
                {{ 'email' | translate }}
              </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'phoneNumber' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'address' | translate }}
            </th>
          </tr>
        </thead>
        <ng-container  *ngFor="let client of this.clientArray">
          <tbody>
            <tr class="verticalAlignMiddle">
              <td [ngClass]="theme.getThemeClass()"><a [routerLink]="['/client-edit', client.client_id]" href="javascript:void(0)">{{client.client_name}}</a></td>
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{client.legal_name}}</td>
              <td [ngClass]="theme.getThemeClass()">{{client.email}}</td>
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{this.formatarNumero(client.phone_number)}}</td>
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{client.address}}</td>
            </tr>
          </tbody>              
        </ng-container>            
      </table>
    </article>
  </section>
</div>






