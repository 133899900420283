<app-system-message></app-system-message>
<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluid">
    <section [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'binCreateViewButtonCreateBin' | translate }}</h4>
            <form>
              <!-- BIN NAME -->
              <label class="label-spacer">{{ "binName" | translate }}</label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-dumpster"></i></span>
                <input 
                  [(ngModel)]="this.bin.bin_data.bin_name"
                  id="binName"  
                  type="text" class="form-control" name="bin_name"
                  value="{{ this.bin.bin_data.bin_name }}"
                  >
              </div>
              <!-- Select for bin model number -->
              <label class="label-spacer">{{ "binModel" | translate }}</label>
              <div class="input-group mb-3 firstInput">
                  <span class="input-group-text" id="basic-addon1"><i class="fa fa-hashtag"></i></span>
                  <ng-select [(ngModel)]="this.bin.bin_data.bin_model_id" id="binSelect" class="form-control" name="binSelect"
                  aria-label="Bin_model_number" aria-describedby="basic-addon1"  [ngClass]="{'has-value': this.bin.bin_data.bin_model_id }"
                  (blur)="this.validationService.validateBinModelNumber(this.bin.bin_data.bin_model_id)"
                  [class.error-border]="this.validationService.validationErrorArray[0] == 'binModelError'"
                  (change)="binModelChange()" placeholder="{{ 'binSelectOptionSelectBinModel' | translate }}">
                      <ng-option value="" selected>{{ "binSelectOptionSelectBinModel" | translate }}</ng-option>
                      <ng-container *ngFor="let option of this.bin.bin_model_array">
                          <ng-option *ngIf="option.active === 1" value="{{ option.bin_model_id }}">{{ option.bin_model_number }}</ng-option>
                      </ng-container>
                  </ng-select>
              </div>
              <!-- app-bin-usage is a select embedded -->
              <label class="label-spacer">{{ "adminViewBinUsageNavBar" | translate }}</label>
              <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1"><i class="fa fa-trash"></i></span>
                  <app-bin-usage class="form-control bin-usage"
                  aria-label="Bin usage" aria-describedby="basic-addon1"
                  (blur)="this.validationService.validateBinUsage(this.bin.bin_data.bin_usage)"
                  [class.error-border]="this.validationService.validationErrorArray[0] == 'binUsageError'"></app-bin-usage>
              </div>
              <!-- Select for bin market segment -->
              <label class="label-spacer">{{ "marketSegment" | translate }}</label>
              <div class="input-group mb-3 firstInput">
                  <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-thumbtack"></i></span>
                  <ng-select [(ngModel)]="this.bin.bin_data.market_segment" [ngClass]="{'has-value': this.bin.bin_data.market_segment }" id="binSelect" class="form-control" name="binSelect"
                  aria-label="bin_market_segment" aria-describedby="basic-addon1"
                  (blur)="this.validationService.validateBinModelNumber(this.bin.bin_data.bin_model_id)"
                  [class.error-border]="this.validationService.validationErrorArray[0] == 'binModelError'"
                  (change)="binModelChange()" placeholder="{{ 'marketSegment' | translate }}">
                      <ng-option value="" selected>{{ "marketSegment" | translate }}</ng-option>
                      <ng-container *ngFor="let option of MarketSegment">
                          <ng-option value="{{ option.value }}">{{ option.label | translate }}</ng-option>
                      </ng-container>
                  </ng-select>
              </div>
              <!-- Input field for address with Autocomplete -->
              <label class="label-spacer">{{ "usereditFormLabelAddress" | translate }}</label>
              <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1"><i class="fas fa-search-location"></i></span>
                  <input id="autocomplete" [(ngModel)]="this.bin.bin_data.bin_address" (keydown)="onInputAddressKeydown($event);"
                  type="text" class="form-control" name="bin_location"
                  placeholder="{{ 'clientcreateFormInputFindAddress' | translate }}"aria-label="Enter a place" aria-describedby="basic-addon1">
              </div>
              <!-- Select used to get put an association between bin and client -->
              <label class="label-spacer">{{ "clientName" | translate }}</label>
              <div class="input-group mb-3" *ngIf="this.cognitoService.userType === 'muirwood' || this.cognitoService.userType === 'distributor'">
                <span class="input-group-text" id="basic-addon1"><i class="fas fa-briefcase"></i></span>
                <ng-select [(ngModel)]="this.bin.bin_data.client_id" class="form-control ng-select" name="client"  [ngClass]="{'has-value': this.bin.bin_data.client_id }"
                  aria-label="Client Name" aria-describedby="basic-addon1" (change)="selectedClientChange()" placeholder="{{ 'devicecreateSelectClient' | translate }}">
                    <ng-option value="" selected disabled>{{ "devicecreateSelectClient" | translate }}</ng-option>
                    <ng-container *ngFor="let option of this.cognitoService.clientsInfosArray">
                      <ng-option value="{{ option.client_id }}">{{ option.legal_name }}</ng-option>
                    </ng-container>
                </ng-select>
              </div>
              <!-- Select used to get put an association between bin/client/thing -->
              <div class="input-group mb-3" *ngIf="this.bin.isDeviceArray">
                <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-microchip"></i></span>
                <ng-select [(ngModel)]="this.thing_name" class="form-control ng-select" name="client"  [ngClass]="{'has-value': this.thing_name }"
                  aria-label="Client Name" aria-describedby="basic-addon1" (change)="selectedDeviceChange()" placeholder="{{ 'iotModalViewDeviceName' | translate }}">
                    <ng-option value="" selected>{{ "iotModalViewDeviceName" | translate }}</ng-option>
                    <ng-container *ngFor="let option of this.bin.device_array">
                      <ng-option value="{{ option.thing_name }}">{{ option.thing_name }}</ng-option>
                    </ng-container>
                </ng-select>
              </div>
              <!-- Input for the threshold -->
              <label class="label-spacer">{{ "alertThreshold" | translate }}</label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><i class="fa-regular fa-bell"></i></span>
                <input [(ngModel)]="this.bin.bin_data.threshold" type="number"
                class="form-control" name="threshold" aria-label="bin_depth" (change)="thresholdChange()">
              </div>
              <!-- Input for number of bin to create -->
              <label class="label-spacer">{{ "binsNumber" | translate }}</label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><i class="fa-regular fa-square-plus"></i></span>
                <input [(ngModel)]="this.numberToCreate" (ngModelChange)="changeNumberToCreate()" type="text"
                class="form-control" name="numberToCreate" [disabled]="this.thing_name !== ''"
                placeholder="{{ 'numberToCreate' | translate }}" aria-label="bin_depth">
              </div>
              <!-- Submit button -->
              <nav class="form-group buttonDiv">
                  <button (click)="returnToAdmin()" *ngIf="this.cognitoService.userType === 'muirwood'" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                  <button (click)="returnToDistributorBinDashboard()" *ngIf="this.cognitoService.userType === 'distributor'" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                  <button (click)="returnToClientBinDashboard()" *ngIf="this.cognitoService.userType === 'client'" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                  <button [ngClass]="theme.getThemeClass()" (click)="onSubmit()" type="button" class="btn btn-block btnCreate">{{ 'binCreateViewButtonCreateBin' | translate }}</button>
              </nav>
            </form>
        </article>
    </section>
</div>

