import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { OperatorService } from '../service/operator.service';
import { ValidationService } from '../service/validation.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../local-storage.service';
import { formatDate } from '@angular/common';
import { CognitoService } from '../service/cognito.service';
import { SystemMessageService } from '../service/system-message.service';
import { LocalizationService } from '../service/localization.service';

@Component({
  selector: 'app-operator-create',
  templateUrl: './operator-create.component.html',
  styleUrls: ['./operator-create.component.css']
})
export class OperatorCreateComponent implements OnInit {

  // Variable used in the submit for validation conrfim
  public validateCreate: boolean = false;

  // Variables used in UI HTML to show/hide messages
  private systemMessage: string = '';

  constructor(public theme: ThemeService,
              public operator: OperatorService,
              public validationService: ValidationService,
              private router: Router,
              private localStorageService: LocalStorageService,
              private cognitoService: CognitoService,
              private systemMessageService: SystemMessageService,
              private localizationService: LocalizationService) {

    this.cognitoService.confirmValidUser();
  }

  ngOnInit(): void {
    // Call the function that init the operator data
    this.operator.initOperatorData();
    this.localizationService.initAutocomplete();
  }

  async onSubmit() {

    // Create a variable whit the current date
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'yyyyMMdd', 'en_US');

    // Set created and modified date to current date, modified is required event if it's not so the query won't break
    this.operator.operator_data.created = formattedDate;
    this.operator.operator_data.modified = formattedDate;

    this.operator.operator_data.address = this.localizationService.autoCompletionReturnAddress;

    // Call the fonction in addition of validation service to validate operator_data
    this.checkValidation();

    if(this.validateCreate){
      // If the validation id good will call the create function
      await this.operator.createOperator();
      // Then call the function that will return in entitie page
      this.returnToEntities();
    }else{
      // If system message have nothing it put a generic message
      if(this.systemMessage === ''){
        this.systemMessage = 'alert-danger-generic-message';
      }
      // Set the system message service to display the error to the user
      this.systemMessageService.selectRibbon('danger', this.systemMessage);
    }
  }

  // Function call in addition of validation service to validate input of the
  checkValidation() {
    // Call the function to check if there is an empty input
    this.operator.hasOwnProperty('create');

    if(this.operator.emptyField){
      this.systemMessage = 'fieldEmptyOrIncorrect';
    }

    // Check if all function that we passed before not returning a true value then will set the validation variable to true to be able to create a new taxe type
    if(!this.operator.emptyField && (this.validationService.validationErrorArray[0] === '' || this.validationService.validationErrorArray[0] === undefined)){
      // Set validation variable to true and remove the empty input message
      this.validateCreate = true;
    }
  }

  // Implemented by cancel button to return to the admin component
  returnToEntities(){
    // Put a local store variable so entitie component could know to return on operator-list
    this.localStorageService.addItem('entitiesContent', 'operator-list');
    sessionStorage.setItem("from", "operator-create"); // This variable is used for operator-list to show the proper success message on create or update
    this.router.navigate(['/entities']); // Return to entitie component
  }

  // Function that avoid submitting the page when user press enter at the end of inputting address in address input
  onInputAddressKeydown(event: KeyboardEvent): void {
    if(event.key === 'Enter'){
      event.preventDefault(); // Don't submit the page
    }
  }
}
