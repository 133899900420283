import { Component, OnInit, ViewChild } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { DistributorsService } from '../service/distributors.service';
import { CognitoService } from '../service/cognito.service';
import { RoleService } from '../service/role.service';
import { formatDate } from '@angular/common';
import { WorkOrderDashboardModalService } from '../service/work-order-dashboard-modal.service';
import { SystemMessageService } from '../service/system-message.service';

@Component({
  selector: 'app-client-work-order-dashboard',
  templateUrl: './client-work-order-dashboard.component.html',
  styleUrls: ['./client-work-order-dashboard.component.css', '../../global-elements.css']
})
export class ClientWorkOrderDashboardComponent implements OnInit {
  @ViewChild(WorkOrderDashboardModalService) service: any;

  public ascDesc: number = 0;
  public sortBy: string = '';
  public userFilter: string = '';

  public workOrderArray: any[] = [];
  private originalWorkOrderArray: any[] = [];

  // variables for filter
  private filterCount: number = 0;
  private lastFilterCount: number = 0;
  private isFilter: boolean = false;
  public filterBy: string = '';

  constructor(public theme: ThemeService,
              public distributors: DistributorsService,
              public cognitoService: CognitoService,
              public roleService: RoleService,
              public workOrderModalService: WorkOrderDashboardModalService,
              private systemMessageService: SystemMessageService){

  }

  async ngOnInit() {
    // Call cognito to confirm valid user and get the user type of the current user
    this.cognitoService.confirmValidUser();
    this.cognitoService.getUserType();

    // Call role service to get the current user roles
    await this.roleService.getRoles();

    if(!this.distributors.distributorWorkOrderArray || this.distributors.distributorWorkOrderArray.length === 0){
      // Call the function to get all bin model association of the distributor
      await this.distributors.getAllDistributorWorkOrders(this.cognitoService.clientId);
      this.workOrderArray = this.distributors.distributorWorkOrderArray;
      this.originalWorkOrderArray = this.distributors.distributorWorkOrderArray;
    }
  }

  // Function that return column template for the display grid of the list
  getColumnStyle(): any{
    const numberOfColumn = this.ajustNumberOfColumns(this.getScreenWidth());
    if(numberOfColumn){
      if(this.distributors.distributorWorkOrderArray.length >= numberOfColumn){
        const gridColumns = `repeat(${numberOfColumn}, minmax(0, 325px))`;
        return {
          'grid-template-columns': gridColumns
        };
      }else{
        return {
          'display': `flex`,
          'justify-content': `center`,
          'margin': '0 -10px'
        };
      }
    }
  }

  // Function called when user filter the array
  search(){
    // Set last filter count, filter count and if there's a filter
    this.lastFilterCount = this.filterCount;
    this.isFilter = this.userFilter.toLowerCase() !== '';
    this.filterCount = this.userFilter.length;

    let filterProperty = this.filterBy;
    if(filterProperty === '' || filterProperty === undefined){
      filterProperty = 'work_order_id';
    }

    // Switch if user enter more filter, press backspace or delete filter
    switch(true){

      case (this.filterCount > this.lastFilterCount):
        this.workOrderArray = this.workOrderArray.filter( v => {
          if(v[filterProperty] && typeof v[filterProperty] === 'string'){
            return v[filterProperty].toLowerCase().includes(this.userFilter);
          }else{
            return false;
          }
        });
        this.lastFilterCount = this.filterCount; // Set the count of the user filter
        break;

      case (this.filterCount < this.lastFilterCount):
        this.resetWorkOrderArray(); // Reset the array of bin models
        // Filter the array of bin models
        this.workOrderArray = this.workOrderArray.filter( v => {
          if(v[filterProperty] && typeof v[filterProperty] === 'string'){
            return v[filterProperty].toLowerCase().includes(this.userFilter);
          }else{
            return false;
          }
        });
        this.lastFilterCount = this.filterCount; // Set the count of the user filter
        break;

      case (this.isFilter === false):
        this.resetWorkOrderArray(); // Reset the array of bin models
        this.filterCount = 0;
        this.lastFilterCount = 0;
        break;
    }
  }

  resetWorkOrderArray(){
    this.workOrderArray = [...this.originalWorkOrderArray];
  }

  // Funciton called when user sort the array
  onSortChange(caller: string){
    // Switch the value of ascending or descending
    if(caller === 'switch'){
      if(this.ascDesc === 0){
        this.ascDesc = 1;
      }else{
        this.ascDesc = 0;
      }
    }

    // Avoid sortBy to have no value
    if(this.sortBy === ''){
      this.sortBy = 'work_order_id';
    }

    // Function that sort the list
    this.workOrderArray.sort((a, b) => {
      switch(this.sortBy){
        case 'work_order_id':
          return this.ascDesc === 0 ? b.work_order_id.localeCompare(a.bin_address) : a.work_order_id.localeCompare(b.bin_address);

        case 'work_order_type':
          return this.ascDesc === 0 ? b.work_order_type.localeCompare(a.legal_name) : a.work_order_type.localeCompare(b.legal_name);

        case 'legal_name':
          return this.ascDesc === 0 ? b.legal_name.localeCompare(a.legal_name) : a.legal_name.localeCompare(b.legal_name);
      }
    });
  }

  // Funciton called when user change work order status
  changeWorkOrderStatus(work_order_id: string, status: string){
    this.workOrderModalService.changeStatus(work_order_id, status);
  }

  // Function called when user change the deadline date for the work order
  changeWorkOrderDeadlineDate(work_order_id: string, deadlineDate: string){
    this.workOrderModalService.changeDeadline(work_order_id, deadlineDate);
  }

  // Function called when user change the description of the work order
  changeWorkOrderDescription(work_order_id: string, description: string){
    this.workOrderModalService.changeDescription(work_order_id, description);
  }

  // Function called when user add comments on the work order
  async changeWorkOrderComments(work_order_id: string, comments: string){
    // Get the user sub for inserting in work_order_comments table
    const userSub = await this.cognitoService.getCurrentUserSub();

    // call funciton in the modal service
    this.workOrderModalService.addComments(work_order_id, comments, userSub);
  }

  formatDate(date: number){
    return formatDate(date, 'dd/MM/yyyy', 'en-US');
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  // function called to adjust the number of column of the grid depbending of inner window width
  ajustNumberOfColumns(width: number): number | undefined{
    // Return the floor of the inner page width divided by 450 px as the width of the tile size
    return Math.floor((width / 350) * (90 / 100));
  }

}
