import { ValidationService } from './../service/validation.service';
import { Component, OnInit, Renderer2, HostListener, Inject, forwardRef } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../service/theme.service';
import { NavigationService } from '../service/navigation.service';
import { ClientService } from '../service/client.service';
import { DistributorsService } from '../service/distributors.service';
import { Router } from '@angular/router';
import { WorkOrderService } from '../service/work-order.service';
import { catchError, tap } from 'rxjs';
import { time } from 'console';
import { FilterService } from '../service/filter.service';

@Component({
  selector: 'app-work-order-list',
  templateUrl: './work-order-list.component.html',
  styleUrl: './work-order-list.component.css'
})
export class WorkOrderListComponent implements OnInit {

  // Variable to store the language selected value
  public selectedLanguage: string = "";

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  // Retrieve last content selected from local storage
  lastEntitiesContent:string = this.localStorageService.getItem('adminContent')

  // Variables for user filtering
  public mainContent: string = '';
  public userFilterTest: string ="";
  public userTest: string = "";
  public searchText: string = '';
  public userFilter: string =""; // User filter string - should be changed to device filter

  public filterOrderType: string = '';

  public clientArray: any[] = [];
  public newClientArray: any [] = [];

  public map: any = {};

  // Array Fetch customer data
  public clientsData: any[] = [];

  // Flags for UI state
  public isClientsActive: boolean = false;
  public isDistributorsActive: boolean = false;
  public isProvidersActive: boolean = false;
  public isTaxeTypeActive: boolean = true;

  // Flags for UI state
  public showList: boolean = false;
  public filterOn: boolean = false;
  public loadingMoreData = false;
  public isDistributorsActvive: boolean = false;
  public show = false;

  public userList: any[] = [];
  public workOrderList: any[] = [];
  public workOrderListArray: any[] = [];
  public legalName: any;

  // Value to manage the pagination
  private itemsPerPage = 5;
  private loadedItems = 0;

  public filterCount: number = 0;

  constructor(
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private localStorageService: LocalStorageService,
    public cognitoService: CognitoService,
    public validationService: ValidationService,
    public theme: ThemeService,
    public navigationService: NavigationService,
    public clientService: ClientService,
    public distributorService: DistributorsService,
    private router: Router,
    private renderer: Renderer2,
    private workOrderService: WorkOrderService,
    public filter: FilterService,
  ) {

    Object.keys(this.orderStatusMap).forEach((key) => {
      this.translate.get(this.orderStatusMap[key]).subscribe((translation: string) => {
        this.orderStatusMap[key] = translation;
      });
    });

    Object.keys(this.orderTypeMap).forEach((key) => {
      this.translate.get(this.orderTypeMap[key]).subscribe((translation: string) => {
        this.orderTypeMap[key] = translation;
      });
    });

    this.cognitoService.confirmValidUser();

    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  // not working
  filterOrders(orders: any[], filterOrderType: string): any[] {
    if (!filterOrderType || filterOrderType.trim() === '') {
      return orders; // Se o filtro estiver vazio, retorna a lista completa
    }

    // Filtra com base no tipo de pedido
    return orders.filter(order =>
      order.work_order_type &&
      this.orderTypeMap &&
      this.orderTypeMap &&
      [order.work_order_type]
        ? this.orderTypeMap[order.work_order_type].toLowerCase().includes(filterOrderType.toLowerCase())
        : false
    );
  }

  getCountFilter(){
    return this.filterCount;
  }

  setCountFilter(count: number){
    this.filterCount = count;
  }

  async ngOnInit(): Promise<void> {

    this.getCurrentUser();

    // Function to load initial user data and reset the loading state
    this.getUserList();

    await this.getClientList();

    // Call the getAllUsers function from the cognitoService to retrieve all users
    await this.cognitoService.getAllUsers();

    // Call the getUser function from the cognitoService to retrieve the current user
    await this.cognitoService.getUser();

  }

 // Function to get the client name by client ID
  getClientNameById(clientId: string): string {
    // Find the client in the clientArray based on the provided client ID
    const client = this.clientArray.find(c => c.client_id === clientId);

    // Check if the client is found
    if (client) {
      // If found, return the client's name
      return client.client_name;
    } else {
      // If not found, return a default string or another indication that the client was not found
      return 'Client not found';
    }
  }

  // Asynchronous function to fetch and process client data
  async getClientList() {
    try {
      // Use the clientService to fetch client data from an API
      await this.clientService.getClients().subscribe(
        // Successful response callback
        (response: any) => {
          // Store the API response in the clientsData array
          this.clientsData = response;

          // Create a copy of the clientArray for further processing
          this.clientArray = this.clientsData;
          //console.log(this.clientArray)

          // Remove Muirwood Studio from client List to display correctly to selection client list
          this.clientArray = this.clientArray.filter(client => client.client_name !== 'Muirwood Studio');

          // Deduplicate client data based on client_name and client_id
          this.newClientArray = this.clientArray.reduce((accumulator, item) => {
            const key = item.client_name + item.client_id;
            if (!this.map[key]) {
              this.map[key] = true;
              // Add unique clients to the newClientArray
              accumulator.push({ client_id: item.client_id, client_name: item.client_name });
            }
            return accumulator;
          }, []);
        }
      );
    } catch (error) {
      // Handle and log any errors that occur during the process
      console.error("Error: ", error);
    }

  }

  // Function to find and return the 'given_name' property based on the provided 'sub' parameter
  findGivenNameBySub(sub: string) {

    // Iterate over the array of users
    for (let i = 0; i < this.cognitoService.cognitoUsersArray.length; i++) {
      const user = this.cognitoService.cognitoUsersArray[i];

      //console.log(user.sub)

      // Check if the 'sub' property value matches the provided parameter
      if (user.sub === sub) {

        // If there is a match, return the value of the 'given_name' property
        return user.given_name;
      }
    }

    // Return null if no match is found
    return null;
  }

  // Function to get the user name based on the 'sub' property
  getUserNameBySub(sub: string): string {
    // Find the user in the array based on the 'sub' property
    const user = this.cognitoService.cognitoUsersArray.find(u => u.sub === sub);

    // Check if the user is found
    if (user) {
      // Return the user's name (adjust based on the structure of your user object)
      return `${user.given_name} ${user.family_name}`;
    } else {
      // Return a default string or another indication that the user was not found
      return 'User not found';
    }
  }

  // Asynchronous function to retrieve the current user's sub and update work order data
  async getCurrentUser() {
    try {
      // Retrieve the current user's sub (subject) using the Cognito service
      // and assign it to the created_by property in the workOrderData of workOrderService.
      this.workOrderService.workOrderData.created_by = await this.cognitoService.getCurrentUserSub();
      this.getWorkOrderList();
    } catch (error) {
      // If there's an error while obtaining the current user's data,
      // log an error message to the console.
      console.error('Error retrieving current user data:', error);
    }
  }

  ngAfterViewInit(): void {
    // Call the function to detect if the user has reached the end of the page on a mobile device
    this.detectEndOfPageOnMobile();
  }

  // Function to add single quotes around a given string
  addQuotes(str: string): string {
    // Return the input string enclosed in single quotes
    return `'${str}'`;
  }

  // Object mapping order type keys to their corresponding descriptions
  orderTypeMap: {[key: string]: string} = {
    'M': 'Maintenance',
    'I': 'Installation',
    'C': 'Collect',
    'O': 'Order',
    'R': 'Repair',
    'E': 'Emergency',
    'D': 'Decommission'
  };

  // Object mapping order status keys to their corresponding descriptions
  orderStatusMap: {[key: string]: string} = {
    'C': 'Complete',
    'I': 'In-progress',
    'P': 'Paused',
    'X': 'Cancelled',
    'E': 'Error',
    'D': 'Delayed',
    'O': 'Open',
    'A': 'Assigned'
  };

  // Function to format a timestamp into a human-readable date and time
  formatTimestamp(timestamp: any): string {
    // Check if the timestamp is null
    if (timestamp == null) {
      // Return 'N/A' if the timestamp is null
      return 'N/A';
    }

    // Convert the input to a number if it's a string
    const timestampNumber = typeof timestamp === 'string' ? parseInt(timestamp, 10) : timestamp;

    // Create a Date object using the timestamp (considering whether it's in seconds or milliseconds)
    const date = new Date(timestampNumber > 1e12 ? timestampNumber : timestampNumber * 1000);

    // Format the date as desired using Date class methods
    const formattedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;

    // Return the formatted date
    return formattedDate;
  }

  // Function to get a list of work orders
  getWorkOrderList() {
    // used for test \/
    let a = this.workOrderService.clientIDArrayFixed
    a.push("'CL65642ef85b5a975ede'")

    // Get the creator entity type from the work order data
    const creatorEntityType = this.workOrderService.workOrderData.creator_entity_type;

    // Fetch all work orders based on creator entity type and client ID array
    this.workOrderService.getAllWorkOrders(creatorEntityType, a).pipe(
      tap((response) => {
        // Add the response to the workOrderList array
        this.workOrderList.push(response);
        this.workOrderListArray = this.workOrderList[0]
      }),
      catchError((error) => {
        // Handle errors as needed
        console.error('Erro na API:', error);
        throw error; // Re-throw the error for the calling code to handle
      })
    ).subscribe();
  }

  // get user list and get data with lazy loading
  getUserList() {

    // Retrieve the user list from the cognitoService
    this.userList = this.cognitoService.cognitoUsersArray;

    // Slice the user list to only include the first 'itemsPerPage' users
    this.userList = this.userList.slice(0, this.itemsPerPage);

    // Set the number of loaded items to 'itemsPerPage'
    this.loadedItems = this.itemsPerPage;

  }

  // Load more users when reaching the bottom of the page
  loadMoreItems() {
    // Slice the remaining items based on the loaded items and items per page
    const remainingItems = this.cognitoService.cognitoUsersArray.slice(this.loadedItems, this.loadedItems + this.itemsPerPage);

    // Add more items to the displayed list by concatenating the remaining items
    this.userList = this.userList.concat(remainingItems);

    // Update the count of loaded items
    this.loadedItems += this.itemsPerPage;
  }

  detectEndOfPageOnMobile(): void {
    // Check if the user is using a mobile device
    if (this.isMobileDevice()) {
      // Listen for the 'touchmove' event to track scrolling on the document
      this.renderer.listen('document', 'touchmove', (event: TouchEvent) => {
        // Get the current scroll position, window height, and document height
        const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0;
        const documentHeight = Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.documentElement.clientHeight
        );
        // Check if the user has scrolled to the end of the page and not currently loading more data
        if (scrollPosition + windowHeight >= documentHeight && !this.loadingMoreData) {
          this.loadMoreItems();
        }
      });
    }
  }

  // Function to determine if the current device is a mobile device
  isMobileDevice(): boolean {
    // Get the user agent string and convert it to lowercase
    const userAgent = navigator.userAgent.toLowerCase();

    // Check if the user agent matches any common mobile device keywords
    return /mobile|android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  }

  // Host listener to detect scroll events on the window
  @HostListener('window:scroll', []) onScroll(): void {
    // Get the height of the visible window area
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;

    // Get references to the document's body and html elements
    const body = document.body;
    const html = document.documentElement;

    // Calculate the maximum document height using various metrics
    const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    // Get the current scroll position
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // Check if the user has scrolled near the bottom of the page
    if (documentHeight - windowHeight - scrollPosition < 100 && !this.loadingMoreData && !this.filterOn) {
      this.loadMoreItems()
    }
  }

  identifyUserType(user: any) {
    // Extract user details
    let userSub = user.sub;
    let userUsername = user.username;
    let userType = user.custom_user_type.toLowerCase();

    // Use a switch statement to navigate to the appropriate update route based on user type
    switch(userType) {
      // If the user type is 'client'
      case "client":
        // Navigate to the 'user-update-client' route with query parameters
        this.router.navigate(['/user-update-client'], {
          queryParams: {
            userSub,
            userUsername,
            userType
          }
        });
        break;
      // If the user type is 'distributor'
      case "distributor":
        // Navigate to the 'user-update-distributor' route with query parameters
        this.router.navigate(['/user-update-distributor'], {
          queryParams: {
            userSub,
            userUsername,
            userType
          }
        });
        break;
      // If the user type is 'muirwood'
      case "muirwood":
        // Navigate to the 'user-update-muirwood' route with query parameters
        this.router.navigate(['/user-update-muirwood'], {
          queryParams: {
            userSub,
            userUsername,
            userType
          }
        });
        break;
    }
  }

  /**
   * Retrieve a specific attribute value for a user from their attributes array.
   * @param user - The user object containing attributes.
   * @param attributeName - The name of the attribute to retrieve.
   * @returns The value of the specified attribute or an empty string if not found.
   */
  getUserAttributeValue(user: any, attributeName: string): string {
    // Find the attribute with the given 'attributeName' in the user's attributes array
    const attribute = user.Attributes.find((attr: { Name: string, Value: string }) => attr.Name === attributeName);

    // Return the value of the attribute if found, or an empty string if not found
    return attribute && attribute.Value ? attribute.Value : '';
  }

  // Function to perform user search based on the entered filter text
  search() {


    // Convert the entered filter text to lowercase
    let value = (this.userFilterTest || '').toLowerCase();

    // if(value === 'repair') {
    //   value = 'R'
    //   //console.log(value)
    // }

    let result;


    // Determine whether to show the filtered client list based on the presence of filter text
    this.showList = value !== '';

    //console.log(this.showList)

    if (this.showList) {

      switch (value) {
        // condition filter for order type
        case 'maintenance':
        case 'mantenimiento':
        case 'maintenance':
          value = 'm';
          break;
        case 'installation':
        case 'instalación':
        case 'installation':
          value = 'i';
          break;
        case 'collect':
        case 'recolección':
        case 'collecte':
          value = 'c';
          break;
        case 'order':
        case 'orden':
        case 'commande':
          value = 'o';
          break;
        case 'repair':
        case 'reparación':
        case 'réparation':
          value = 'r';
          break;
        case 'emergency':
        case 'emergencia':
        case 'urgence':
          value = 'e';
          break;
        case 'decommission':
        case 'descomisionamiento':
        case 'désactivation':
          value = 'd';
          break;


        case 'complete':
        case 'completo':
        case 'complet':
          value = 'c';
          break;
        case 'in-progress':
        case 'en progreso':
        case 'en cours':
          value = 'i';
          break;
        case 'paused':
        case 'en pausa':
        case 'en pause':
          value = 'p';
          break;
        case 'cancelled':
        case 'cancelado':
        case 'annulé':
          value = 'x';
          break;
        case 'error':
        case 'error':
        case 'erreur':
          value = 'e';
          break;
        case 'delayed':
        case 'demorado':
        case 'en retard':
          value = 'd';
          break;
        case 'open':
        case 'abierto':
        case 'ouvert':
          value = 'o';
          break;
        case 'assigned':
        case 'asignado':
        case 'assigné':
          value = 'a';
          break;
        default:
          break;
      }





      // Filter the workOrderList based on the entered filter text and update the workOrderListArray
      this.workOrderListArray = this.workOrderList[0].filter((order: any) => {
        // Ensure that v.client_id, v.work_order_type, and v.status are defined before calling toLowerCase
        const clientMatch = order.client_id.toLowerCase().includes(value);
        const typeMatch = order.work_order_type.toLowerCase().includes(value);
        const statusMatch = order.status.toLowerCase().includes(value);

        //console.log(`Client: ${clientMatch}, Type: ${typeMatch}, Status: ${statusMatch}`);

        return clientMatch || typeMatch || statusMatch;
      });

      // Set the filterOn flag to indicate that filtering is active
      this.filterOn = true;
    } else {
      // Clear the filter and reload the initial workOrderList
      this.filterOn = false;
      this.getWorkOrderList();
    }

    // Clear the filterOn flag if the showList is false (no filter text)
    if (!this.showList) {
      this.filterOn = false;
    }
  }

  /* this.userFilterTest = this.userFilterTest.toLowerCase()

    ///////////////////////////////
    switch (this.userFilterTest) {
      case 'complete':
      case 'completo':
      case 'complet':
        //console.log("AAAAAA");
        this.userFilter = 'C';
        break;
      default:
        break;
    }

    //console.log(this.userFilterTest)
    /////////////////////////////////*/

  /**
   * Refresh the list of entities (e.g., clients) by adding an item to local storage
   * and reloading the page.
   */
  refreshList() {
    // Add an item to local storage to trigger a list refresh
    this.localStorageService.addItem('adminContent', 'userList');

    // Reload the page to reflect the updated list
    window.location.reload();
  }

}
