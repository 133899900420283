<div class="hideNavBar"></div>
<section [ngClass]="theme.getThemeClass()" *ngIf="this.userEditModalService.showUserEditAdvancedOption" class="advanced-option-modal">
  <h3>{{ "typeOfAlert" | translate }}</h3>
  <article class="col-10 advanced-options-box">
    <!-- Section for collections -->
    <h5>{{ "_collections" | translate }}</h5>
    <div class="toggle-stack">
      <div class="custom-control custom-switch cs-container">
        <!-- Rounded switch -->
        <label class="switch">
          <input
            type="checkbox"
            [ngModel]="allThingsCollection"
            name="devices"
            (change)="onChangeAllThingsCollection($event)"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <label class="devices-toggle">{{"allDevices" | translate}}</label>
    </div>
    <!-- select for device wanted -->
    <div *ngIf="!allThingsCollection" class="form-group">
      <div class="force-column">
        <label class="form-label">{{ "devices" | translate }}</label>
        <ng-select
          [(ngModel)]="selectedThingsCollection"
          class="form-control ng-select select-collection"
          [multiple]="true"
          (change)="scrollToBottomCollection()"
          [items]="this.userEditModalService.things"
          bindLabel="thing_name"
          #selectCollection
        >
          <ng-container *ngFor="let option of this.userEditModalService.things">
            <ng-option value="{{ option }}">{{ option }}</ng-option>
          </ng-container>
        </ng-select>
      </div>
    </div>
    <hr>
    <!-- Section for threshold -->
    <h5>{{ "threshold" | translate }}</h5>
    <div class="toggle-stack">
      <div class="custom-control custom-switch cs-container">
        <!-- Rounded switch -->
        <label class="switch">
          <input
            type="checkbox"
            [ngModel]="allThingsThreshold"
            name="devices"
            (change)="onChangeAllThingsThreshold($event)"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <label class="devices-toggle">{{"allDevices" | translate}}</label>
    </div>
    <!-- select for device wanted -->
    <div *ngIf="!allThingsThreshold" class="form-group">
      <div class="force-column">
        <label class="form-label">{{ "devices" | translate }}</label>
        <ng-select
          [(ngModel)]="selectedThingsThreshold"
          class="form-control ng-select"
          [multiple]="true"
          (change)="scrollToBottomTreshold()"
          [items]="this.userEditModalService.things"
          bindLabel="thing_name"
          #selectThreshold
        >
          <ng-container *ngFor="let option of this.userEditModalService.things">
            <ng-option value="{{ option.thing_name }}"> {{ option.thing_name }}</ng-option>
          </ng-container>
        </ng-select>
      </div>
    </div>
    <hr>
    <!-- Section for monthly report -->
    <h5>{{ "monthlyReport" | translate }}</h5>
    <div class="toggle-stack">
      <div class="custom-control custom-switch cs-container">
        <!-- Rounded switch -->
        <label class="switch">
          <input
            type="checkbox"
            [ngModel]="allThingsMonthlyReports"
            name="devices"
            (change)="onChangeAllThingsMonthlyReports($event)"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <label class="devices-toggle">{{"allDevices" | translate}}</label>
    </div>
    <!-- select for device wanted -->
    <div *ngIf="!allThingsMonthlyReports" class="form-group">
      <div class="force-column">
        <label class="form-label">{{ "devices" | translate }}</label>
        <ng-select
          [(ngModel)]="selectedThingsMonthlyReports"
          class="form-control ng-select"
          [multiple]="true"
          (change)="scrollToBottomMonthlyReports()"
          [items]="this.userEditModalService.things"
          bindLabel="thing_name"
          #selectMonthlyReport
        >
          <ng-container *ngFor="let option of this.userEditModalService.things">
            <ng-option value="{{ option.thing_name }}"> {{ option.thing_name }}</ng-option>
          </ng-container>
        </ng-select>
      </div>
    </div>
  </article>


  <nav class="advanced-option-btn-nav">
    <button [ngClass]="theme.getThemeClass()" class="btn" (click)="this.userEditModalService.closeModal()">{{ "iotModalButtonClose" | translate }}</button>
    <button [ngClass]="theme.getThemeClass()" class="btn" (click)="this.saveAdvanceOption()">{{ "loginFNewPasswordBtnConfirmNewPw" | translate }}</button>
  </nav>
</section>
