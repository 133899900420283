import { ValidationService } from './../service/validation.service';
import { Component, OnInit, Renderer2, HostListener  } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { Client, ClientService } from '../service/client.service';
import { ThemeService } from '../service/theme.service';
import { IotService } from '../service/iot.service';
import { Subject } from 'rxjs';
import { DeviceModels } from '../constants/thing-models';
import { FormsModule } from '@angular/forms';  // Import FormsModule

@Component({
  selector: 'app-iot-typess',
  standalone: false,
  templateUrl: './iot-typess.component.html',
  styleUrls: ['./iot-typess.component.css'],
  providers: [FormsModule],  // Add FormsModule to the providers
})

export class IotTypessComponent implements OnInit {

  public selectedLanguage: string = "";
  public languageStatus: string = this.localStorageService.getItem('language');
  public mainContent: string = '';
  public userFilterTest: string = "";
  public searchText: string = '';
  private searchterms = new Subject<string>();
  public searchTerms = new Subject<string>();
  public filteredUserTest: any = [];
  public clientArray: any[] = [];
  public clientArrayToFilter: Client[] = [];
  public showList: boolean = false;
  public filterOn: boolean = false;
  public loadingMoreData = false;
  public isClientsActive: boolean = false;
  public isDistributorsActvive: boolean = false;
  public isProvidersActive: boolean = false;
  private itemsPerPage = 5;
  private loadedItems = 0;
  public clientsData: any[] = [];
  public iotArray: any[] = [];
  public novoArrayDeDispositivos: any[] = [];
  public iotDevicesCount: { [key: string]: number[] } = {};
  // public iotDevicesCount: any[] = [];

  constructor(
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private renderer: Renderer2,
    private cognitoService: CognitoService,
    private clientService: ClientService,
    public theme: ThemeService,
    private iotService: IotService,
  ) {
    this.cognitoService.confirmValidUser();
    if (this.languageStatus == null){
      translate.setDefaultLang('fr');
    } else {
      translate.setDefaultLang(this.languageStatus);
    }
  }

  async ngOnInit(): Promise<void> {
    this.getClientList();
    const data = await this.iotService.getThingTypes();
    if (data && data.thingTypes) {
      this.iotArray = data.thingTypes.map((thingType: any) => thingType.thingTypeName);
      this.createNewArray();
      await this.calculateDeviceCounts();
    } else {
      console.error("No thingTypes found in data:", data);
    }
  }

  createNewArray() {
    this.iotArray.forEach(deviceName => {
      const models = DeviceModels.map(model => model.value);
      this.novoArrayDeDispositivos.push([deviceName, ...models]);
    });
  }

  // No seu componente TypeScript
getDeviceCount(thingType: string): number {
  if (this.iotDevicesCount[thingType]) {
    return this.iotDevicesCount[thingType][0] + this.iotDevicesCount[thingType][1];
  } else {
    return 0;
  }
}


  async calculateDeviceCounts() {
    for (const device of this.novoArrayDeDispositivos) {
      //console.log(device);

      const thingType = device[0];

      //console.log(thingType);

      // Check if iotDevicesCount[thingType] is defined, if not, initialize it
      if (!this.iotDevicesCount[thingType]) {
        this.iotDevicesCount[thingType] = [0, 0]; // Initialize with default values or modify as needed
      }

      // //console.log(models);

      const count = await this.iotService.getDeviceCounts(thingType, device.slice(1));

      //console.log(count);

      // Check if count has the expected structure
      if (count && count.length >= 2) {
        this.iotDevicesCount[thingType][0] = count[0].count;
        this.iotDevicesCount[thingType][1] = count[1].count;

        //console.log(this.iotDevicesCount[thingType]);

        //console.log(this.iotDevicesCount[thingType][0]);
        //console.log(this.iotDevicesCount[thingType][1]);

        //console.log(this.iotDevicesCount[thingType]);
      } else {
        console.error('Unexpected structure in count:', count);
      }
    }
  }

  refreshList() {
    this.localStorageService.addItem('entitiesContent', 'clients');
    window.location.reload();
  }

  getClientList() {
    try {
      this.clientService.getClients().subscribe(
        (response: any) => {
          this.clientsData = response;
          this.clientArray = this.clientsData.slice(0, this.itemsPerPage);
          this.loadedItems = this.itemsPerPage;
        }
      );
    } catch (error) {
      console.error("Error: ", error);
    }
  }

  loadMoreItems() {
    const remainingItems = this.clientsData.slice(this.loadedItems, this.loadedItems + this.itemsPerPage);
    this.clientArray = this.clientArray.concat(remainingItems);
    this.loadedItems += this.itemsPerPage;
  }

  changeLanguage(language: string) {
    this.translate.use(language);
  }

  clearButtonsStats() {
    this.isClientsActive = false;
    this.isDistributorsActvive = false;
    this.isProvidersActive = false;
  }

  ngAfterViewInit(): void {
    this.detectEndOfPageOnMobile();
  }

  // Function to perform user search based on the entered filter text
  search() {
    const value = (this.userFilterTest || '').toLowerCase();
    this.showList = value !== '';

    if (this.showList) {
      // Update the following line to filter the iotArray based on the entered filter text
      this.novoArrayDeDispositivos = this.novoArrayDeDispositivos.filter((device: any) => this.doesThingTypeMatch(device[0], value));

      // Set the filterOn flag to indicate that filtering is active
      this.filterOn = true;
    } else {
      // Clear the filter and reload the initial iot data
      this.filterOn = false;
      this.refreshDeviceList();  // Modify to the appropriate method that fetches the initial device list
    }

    // Clear the filterOn flag if the showList is false (no filter text)
    if (!this.showList) {
      this.filterOn = false;
    }
  }

  doesThingTypeMatch(thingType: string, filterText: string): boolean {
    // Adjust this function based on your matching criteria
    return thingType.toLowerCase().includes(filterText.toLowerCase());
  }

  refreshDeviceList() {
    // Clear the existing array before fetching the updated list
    this.novoArrayDeDispositivos = [];

    // Fetch the initial device list using the IotService getThingTypes method
    this.iotService.getThingTypes().then((data: any) => {
      if (data && data.thingTypes) {
        this.iotArray = data.thingTypes.map((thingType: any) => thingType.thingTypeName);
        this.createNewArray();
        this.calculateDeviceCounts();
      } else {
        console.error("No thingTypes found in data:", data);
      }
    });
  }


  detectEndOfPageOnMobile(): void {
    if (this.isMobileDevice()) {
      this.renderer.listen('document', 'touchmove', (event: TouchEvent) => {
        const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0;
        const documentHeight = Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.documentElement.clientHeight
        );

        if (scrollPosition + windowHeight >= documentHeight && !this.loadingMoreData) {
          this.loadMoreItems();
        }
      });
    }
  }

  isMobileDevice(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  }

  @HostListener('window:scroll', []) onScroll(): void {
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (documentHeight - windowHeight - scrollPosition < 100 && !this.loadingMoreData && !this.filterOn) {
      this.loadMoreItems();
    }
  }

  clientsClicked() {
    this.clearButtonsStats();
    this.isClientsActive = true;
    this.mainContent = "clients";
  }

  distributorsClicked() {
    this.clearButtonsStats();
    this.isDistributorsActvive = true;
    this.mainContent = "distributors";
  }

  providersClicked() {
    this.clearButtonsStats();
    this.isProvidersActive = true;
    this.mainContent = "providers";
  }

  formatarNumero(numberSequence: string | undefined | null): string {
    if (numberSequence === undefined || numberSequence === null) {
        return '';
    }

    const justNumbers = numberSequence.replace(/\D/g, '');

    if (justNumbers.length === 10) {
        const parte1 = justNumbers.slice(0, 3);
        const parte2 = justNumbers.slice(3, 6);
        const parte3 = justNumbers.slice(6);
        return `(${parte1}) ${parte2}-${parte3}`;
    } else {
        return numberSequence;
    }
  }
}
