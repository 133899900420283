import { Component } from '@angular/core';
import { BinModalService } from '../service/bin-modal.service';
import { LocalStorageService } from '../local-storage.service';
import { Router } from '@angular/router';
import { ThemeService } from '../service/theme.service';

@Component({
  selector: 'app-bin-modal',
  templateUrl: './bin-modal.component.html',
  styleUrls: ['./bin-modal.component.css']
})
export class BinModalComponent{


  constructor(public binModal: BinModalService,
              public localStorageService: LocalStorageService,
              private route: Router,
              public theme: ThemeService){

  }
}
