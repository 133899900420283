import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { map, catchError, Subject } from 'rxjs';

export interface Operator {
  operator_id: string;
  operator_name: string;
  legal_name: string;
  phone_number: string;
  address: string;
  logo: string;
  email: string;
  active: string;
  created: string;
  modified: string;
}

@Injectable({
  providedIn: 'root'
})
export class OperatorService {

  // Variables used by HTML on success CRUD
  public successMessage: any;

  // Array of operators
  public array: any;
  public operator_array: Operator[]= [];
  public original_operator_array: Operator[] = [];

  // Variable used for the Filter
  public filter_count: number = 0;

  // Variables used for validation
  public emptyField: boolean = false;

  // Variables that bin and bin-model list will used to trigger there function when there is a create or update made
  private changeMade = new Subject<void>;
  changeMade$ = this.changeMade.asObservable();
  private noChangeMade = new Subject<void>;
  noChangeMade$ = this.noChangeMade.asObservable();


  operator_data: Operator = {
    operator_id: '',
    operator_name: '',
    legal_name: '',
    phone_number: '',
    address: '',
    logo: '',
    email: '',
    active: '',
    created: '',
    modified: ''
  }

  constructor(private http: HttpClient) {

  }

  async resetOperatorArray() {
    this.operator_array = [...this.original_operator_array];
  }

  initOperatorData() {
    this.operator_data = {
      operator_id: '',
      operator_name: '',
      legal_name: '',
      phone_number: '',
      address: '',
      logo: '',
      email: '',
      active: '',
      created: '',
      modified: ''
    }
  }

  // Reset the filtered array whitout making a new call to AWS. Used when user clear filter or backspace
  resetFilterOperatorArray(){
    this.operator_array = [...this.original_operator_array];
  }

  // Will be set whit filter service whit the count of the string(userFilter) to know when user do backspace and reset the array
  setCountFilter(count: number){
    this.filter_count = count;
  }

  // Will be called by the component that call filter service to share the last userFilter count
  getCountFilter(){
    return this.filter_count;
  }

  // Function called to check into tAXE TYPE DATA IF THERE's value when created or updated for an additionnal validation
  hasOwnProperty(CRUD:string){
    //reset the value of the emptyField variable
    this.emptyField = false;

    // Check each field if they are empty
    if(this.operator_data.operator_name === ''){
      this.emptyField = true;
    }
    if(this.operator_data.legal_name === ''){
      this.emptyField = true;
    }
    if(this.operator_data.phone_number === ''){
      this.emptyField = true;
    }
    if(this.operator_data.address === ''){
      this.emptyField = true;
    }
    if(this.operator_data.email === ''){
      this.emptyField = true;
    }
    if(CRUD === 'update' && this.operator_data.operator_id === ''){
      this.emptyField = true;
    }
  }

  // Function called from create/update operator
  onSuccessCRUD(){
    // Call the function that will trigger bin-list or bin-model-list that there is a change made in the list
    this.changeHaveBeenMade();

    // Set a timeOut of 5 seconds so the message of success create or update will disapear
    setTimeout(() => {
      this.successMessage = '';
    }, 5000);
  }

  // Function that is been subscribe in operator-list when there is an create or update made in operator-create or operator-update
  // This function will made reinitialize the list of operator when a change apend

  async changeHaveBeenMade(){
    this.changeMade.next();
  }

  async noChangeHaveBeenMade(){
    this.noChangeMade.next();
  }


    //////// Those CRUD functions are the one called by the components. Please do not do function bellow insted of function used to CALL Lambda functions /////////
    //////// No delete function is in because we just update the status of bin and bin-model ///////////



  // Create bin
  async createOperator(){
    // Call the function that call the lambda function
    this.createOperatorLambda().subscribe((response) => {
      this.successMessage = response; // Return response from lambda and put it in success message so bin-list or bin-model-list and will be able to read it
      if(this.successMessage.message === 'success'){
        this.onSuccessCRUD(); // Call a function that will set all thing for the UI
      }else{
        this.noChangeHaveBeenMade();
      }
    });
  }

  // Get the operator list
  async getOperator(){
    return new Promise<any[]>((resolve) => {
      this.getOperatorLambda().subscribe((response) => {
        // Put the array of Bins returned by the lambda function into an tmp array
        this.array = response;
        // Put the tmp array into the service bin model array
        this.operator_array = this.array;
        this.original_operator_array = this.array;
        resolve(this.array);
      });
    });
  }

  // Get operator by id
  getOperatorById(operator_id: string){
    // Loop thought the bin array to get the selected bin
    for(let i = 0; i < this.operator_array.length; i ++){
      // will select only the bin in bin array for the asked id
      if(this.operator_array[i].operator_id === operator_id){
        this.operator_data = this.operator_array[i]; // Initiate the binData whit the selected bin in the bin array
        break;
      }
    }
    return this.operator_data; // Return the selected bin
  }

  // Update bin
  async updateOperator(){
    // Call the function that made the call to lambda function and wait for is response
    this.updateOperatorLambda().subscribe((response) => {
      this.successMessage = response;
      if(this.successMessage.message === 'success'){
        this.onSuccessCRUD(); // Call a function that will set all thing for the UI
      }else{
        this.noChangeHaveBeenMade();
      }
    });
  }


    ////////// All function bellow are function that call the lambda function from AWS. Please do not do functions bellow insted of Lambda calls /////////////
    //////// No delete function is in because we just update the status of bin and bin-model ///////////



  // Create a Bin
  createOperatorLambda(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
     'Content-Type':  'application/json' // Adjust content type as needed
   });
   // Will call the lambda function in createBin url whit the passed data then return a response
   return this.http.post(environment.api.stage + environment.api.route.createOperator, {
     // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
       "operator_name": this.operator_data.operator_name,
       "legal_name": this.operator_data.legal_name,
       "phone_number": this.operator_data.phone_number,
       "address": this.operator_data.address,
       "logo": this.operator_data.logo,
       "email": this.operator_data.email,
       "created": this.operator_data.created,
       "modified": this.operator_data.modified
     }, { headers: headers }
   );
 }

    // Get all the operator
  getOperatorLambda(){
    // Get the url for the lambda function getBinsModels
    const url = environment.api.stage + environment.api.route.getOperators;
    // Will call the lambda function in getBinModel url then return a response
    return this.http.get(url).pipe(
      map((response) => {
        // return the response data
        return response;
      }),
      catchError((error) => {
        console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      })
    );
  }

  // Update the Bin
  updateOperatorLambda(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Call lambda fucntion whit the url of updateBin  and return the response
    return this.http.post(environment.api.stage + environment.api.route.updateOperator, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "operator_id": this.operator_data.operator_id,
        "operator_name": this.operator_data.operator_name,
        "legal_name": this.operator_data.legal_name,
        "phone_number": this.operator_data.phone_number,
        "address": this.operator_data.address,
        "logo": this.operator_data.logo,
        "email": this.operator_data.email,
        "active": this.operator_data.active,
        "modified": this.operator_data.modified
      }, {headers : headers}
    );
  }
}
