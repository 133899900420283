import { Component, Inject, OnInit, forwardRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { ThemeService } from '../service/theme.service';
import { CognitoService } from '../service/cognito.service';
import { ClientService } from '../service/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleService } from '../service/role.service';
import { IotService } from '../service/iot.service';
import { DashboardService } from '../service/dashboard.service';
import { ModalService } from '../service/device-modal.service';
import { BinsService } from '../service/bins.service';
import { DistributorsService } from '../service/distributors.service';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-client-user-list',
  templateUrl: './client-user-list.component.html',
  styleUrls: ['./client-user-list.component.css', '../../global-elements.css']
})
export class ClientUserListComponent implements  OnInit {

  languageStatus:string = this.localStorageService.getItem('language');

  public userSub: any;
  public userUsername: any;
  public userType: any;
  public currentClientId: any;
  public currentClientRole: any;
  public clientUserArray: any[] = [];
  public userFilter: string = "";
  public isDropdownOpen: boolean = false;
  public filterOn: boolean = false;
  public showList: boolean = false;
  public filterBy: string = '';
  public sortBy: string = '';
  public ascDesc: number = 0;

  constructor(
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private localStorageService: LocalStorageService,
    public theme: ThemeService,
    private cognitoService: CognitoService,
    // private dashboard: DashboardService,
    // public charts: ChartService,
    public clientService: ClientService,
    private route: ActivatedRoute,
    public roleService: RoleService,
    public iotService: IotService,
    public dashboardService: DashboardService,
    public modal: ModalService,
    private binService: BinsService,
    public distributorService: DistributorsService,
    private router: Router,

    ){
      this.route.queryParams.subscribe(params => {
        this.userSub = params['userSub'];
        this.userUsername = params['userUsername'];
        this.userType = params['userType'];
    });


      // Check if the user has selected a language in local storage
      //or use a default language
      if (this.languageStatus == null){
        // Set the default language to French
        translate.use('fr');
      } else {
        // Set the default language to the user's selected language
        translate.use(this.languageStatus);
      }

  }

  async ngOnInit() {
    await this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();
    // Call role service to get the current user roles
    await this.roleService.getRoles();

    // Check if user have all permission to be in that component
    await this.cognitoService.getCurrentRole([environment.users.role.administrator], [environment.users.standardUser], true, this.roleService.roles);

    this.initData();
  }

  // Asynchronously initialize data
  async initData() {
    // Confirm that the user is valid using Cognito service
    await this.cognitoService.confirmValidUser();

    // Get the current user using Cognito service
    const currentUser = await this.cognitoService.getUser();

    // Extract and set the current client role from user attributes
    this.currentClientRole = currentUser.attributes['custom:current_role'];

    // Extract and set the current client ID from user attributes
    this.currentClientId = currentUser.attributes['custom:client_id'];

    // Retrieve all users for the current client ID
    await this.getAllClientUsers(this.currentClientId);
  }

  // Function to filter and count users based on user_type
  async getAllClientUsers(clientId: string) {
    // Retrieve users from the client service
    await this.clientService.getUsersByClientId(clientId).then((res) => {
      this.clientUserArray = res;
    });
  }

  // Function to perform user search based on the entered filter text
  search() {
    // Convert the entered filter text to lowercase
    const value = (this.userFilter || '').toLowerCase();

    // Determine whether to show the filtered client list based on the presence of filter text
    this.showList = value !== '';

    if (this.showList) {
      // Filter the clientsData based on the entered filter text and update the clientFound array
      this.clientUserArray = this.clientUserArray.filter((v) => {
        // Ensure that v.client_name is defined before calling toLowerCase
        return v.given_name && v.given_name.toLowerCase().includes(value) ||
        v.family_name && v.family_name.toLowerCase().includes(value) ||
        v.email && v.email.toLowerCase().includes(value);
      });

      // Set the filterOn flag to indicate that filtering is active
      this.filterOn = true;
    } else {
      // Clear the filter and reload the initial user list data
      this.filterOn = false;
      // this.getUserList();
      this.initData();
    }

    // Clear the filterOn flag if the showList is false (no filter text)
    if (!this.showList) {
      this.filterOn = false;
    }
  }

  dropdownAction(){
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  formatarNumero(numberSequence: string | undefined | null): string {
    // Check if the numberSequence is undefined or null
    if (numberSequence === undefined || numberSequence === null) {
        return ''; // Return an empty string
    }

    // Convert numberSequence to a string if it's not already
    const numberString = String(numberSequence);

    // Remove non-numeric characters from the input
    const justNumbers = numberString.replace(/\D/g, '');

    // Check if there is a country code
    const countryCode = justNumbers.length > 10 ? `+${justNumbers.slice(0, -10)}` : '';

    // Get the last 10 digits of the number
    const last10Digits = justNumbers.slice(-10);

    // Format the last 10 digits as (XXX) XXX-XXXX
    const part1 = last10Digits.slice(0, 3);
    const part2 = last10Digits.slice(3, 6);
    const part3 = last10Digits.slice(6);
    const formattedNumber = `(${part1}) ${part2}-${part3}`;

    // Include the country code if it exists
    return countryCode.length > 0 ? `${countryCode} ${formattedNumber}` : formattedNumber;
}


  identifyUserType(user: any) {
    // Extract user details
    let userSub = user.sub;
    let userUsername = user.username;
    let userType = user.custom_user_type.toLowerCase();

    // Use a switch statement to navigate to the appropriate update route based on user type
    switch(userType) {
      // If the user type is 'client'
      case "client":
        // Navigate to the 'user-update-client' route with query parameters
        this.router.navigate(['/user-update-client'], {
          queryParams: {
            userSub,
            userUsername,
            userType
          }
        });
        break;
      // If the user type is 'distributor'
      case "distributor":
        // Navigate to the 'user-update-distributor' route with query parameters
        this.router.navigate(['/user-update-distributor'], {
          queryParams: {
            userSub,
            userUsername,
            userType
          }
        });
        break;
      // If the user type is 'muirwood'
      case "muirwood":
        // Navigate to the 'user-update-muirwood' route with query parameters
        this.router.navigate(['/user-update-muirwood'], {
          queryParams: {
            userSub,
            userUsername,
            userType
          }
        });
        break;
      // If the user type is operator
      case 'operator':
        //Navigate to the user-update-operator component
        this.router.navigate(['/user-update-operator'], {
          queryParams:{
            userSub,
            userUsername,
            userType
          }
        });
        break;
    }
  }

  // Function called to get the style of the columns of the grid list
  getColumnStyle(): any {
    const numberOfColumn = this.ajustNumberOfColumns(this.getScreenWidth());
    if(numberOfColumn){
      if(this.clientUserArray.length >= numberOfColumn){
        const gridColumns = `repeat(${numberOfColumn}, minmax(0, 300px))`;
        return {
          'grid-template-columns': gridColumns
        };
      }else{
        return {
          'display': `flex`,
          'justify-content': `center`,
          'margin': '0 -10px'
        };
      }
    }
  }

  // function called to adjust the number of column of the grid depbending of inner window width
  ajustNumberOfColumns(width: number): number | undefined{
    // Return the floor of the inner page width divided by 450 px as the width of the tile size
    return Math.floor((width / 325) * (90 / 100));
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  onSortChange(caller: string){

  }

  // Navigate to the user update page with specified parameters
  goToUserUpdate(userSub: string, username: string) {

    // Retrieve the user type from the current instance
    let userType = this.userType;

    // Check if the current user has the administrator role
    if (this.currentClientRole === environment.users.role.administrator) {

        // If the user is an administrator, navigate to the user-update-client page
        this.router.navigate(['./user-update-client'], {
            queryParams: {
                userSub,
                username,
                userType
            }
        });
    }
  }

}
