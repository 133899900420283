import { lastValueFrom } from 'rxjs';
import {
  Component,
  OnInit,
  Renderer2,
  Inject,
  forwardRef,
  ViewChild,
  ElementRef,
} from '@angular/core'; // AfterViewInit, ViewChild, ElementRef
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { CognitoService } from '../service/cognito.service';
import { ModalService } from '../service/device-modal.service';
import { FilterService } from '../service/filter.service';
import { IotService, Devices } from '../service/iot.service';
import { ThemeService } from '../service/theme.service';
import { environment } from '../environments/environment';
import { ValidationService } from '../service/validation.service';
import { SystemMessageService } from '../service/system-message.service';
import { S3Service } from '../service/s3.service';
import { RoleService } from '../service/role.service';
import { ClientService } from '../service/client.service';
import { BinsService } from '../service/bins.service';
import { DistributorsService } from '../service/distributors.service';
import { BinArrayItem, DashboardService } from '../service/dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { LocalizationService } from '../service/localization.service';
import { ReportsService, Bin } from '../service/reports.service';
import { CsvService } from '../service/csv.service';
import { PdfService } from '../service/pdf.service';
import { map, catchError } from 'rxjs';
import { ZoneService } from '../service/zone.service';
import { ChartService } from '../service/chart.service';
import { BinUsage } from '../constants/bin-usage';
import { HttpClient } from '@angular/common/http';

interface BinData {
  bin_id: string;
  bin_location: string;
  thing_name: string;
  bin_usage: string;
  bin_address: string;
  legal_name: string;
  market_segment: string;
  threshold: number;
  bin_height: number;
}

export  interface thingsInterface {
  client_id?: string,
  thing_name: string,
  bin_id?: string,
  distributor_id?: string,
}

export  interface zonesInterface {
  client_id?: string,
  zone_id?: string,
  zone_coordinates?: any[],
}

interface ZoneItem {
  zone_id: string,
  zone_name: string;
}

// not used yet
interface FirstArrayItem {
  thing_name: string;
}

// not used yet
interface SecondArrayItem {
  thing_name: string;
}

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.css', '../../global-elements.css']
})

export class ReportModalComponent implements OnInit {
  @ViewChild('usageCanvas') usageCanvas!: ElementRef;
  @ViewChild('marketCanvas') marketCanvas!: ElementRef;
  @ViewChild('countCanvas') countCanvas!: ElementRef;
  @ViewChild('tonnageCanvas') tonnageCanvas!: ElementRef;
  // Variable to store the language selected value
  public selectedLanguage: string = '';

  // Retrieve current language selected from local storage
  languageStatus: string = this.localStorageService.getItem('language');

  constructor(
    @Inject(forwardRef(() => TranslateService))
    private translate: TranslateService,
    private cognitoService: CognitoService,
    private localStorageService: LocalStorageService,
    private renderer: Renderer2,
    public iotService: IotService,
    public modal: ModalService,
    public filter: FilterService,
    public theme: ThemeService,
    public validationService: ValidationService,
    public systemMessageService: SystemMessageService,

    public s3: S3Service,
    private roleService: RoleService,
    private clientService: ClientService,
    private bins: BinsService,
    private distributorService: DistributorsService,
    public dashboardService: DashboardService,
    public route: ActivatedRoute,
    public location: Location,
    public localizationService: LocalizationService,
    public reports: ReportsService,
    public csv: CsvService,
    public pdf: PdfService,
    public zoneService: ZoneService,
    public charts: ChartService,
    public http: HttpClient,

  ) {

    this.route.queryParams.subscribe(params => {
      this.userSub = params['userSub'];
      this.userUsername = params['userUsername'];
      this.userType = params['userType'];
  });

    this.cognitoService.confirmValidUser();
    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null) {
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  public userSub: any;
  public userUsername: any;
  public userType: any;

  // String that controls the section that is displayed to the user
  public mainContent: string = 'devices';

  public userFilter: string = ''; // User filter string - should be changed to device filter
  public newMap: string = ''; // Active map element ID
  public lastExpanded: string = ''; // Store last expanded device ID
  public currentId: string = ''; // Current device ID for modal

  // Various flags and values for UI control
  public showModal = true;
  public showModalInfoDevicesMobile = false;
  public showModalDevice = false;
  public selectedDevice: Devices = {} as Devices;
  public show = false;
  public filterOn: boolean = false;
  public showList: boolean = false;
  public loadingMoreData = false;
  public stopMap: boolean = false;

  public isDevicesActive: boolean = false; // true when Device view is displayed
  public isModelsActive: boolean = false; // true when Models view is displayed
  public isAlertsActive: boolean = false; // true when Alerts view is displayed
  public isConnectorsActive: boolean = false; // true when Connectors view is displayed
  public isActionsActive: boolean = false; // true when Actions view is displayed

  // Variables to handle the positions of the map pin
  public position = { lat: 0, lng: 0 };
  public longitude = 0;
  public latitude = 0;
  public item = 0;

  public relationships: BinArrayItem[] = [];


  // Data arrays and maps for devices, models, alerts, and maps
  public modelArray: any = [];
  public alertArray: any = [];
  public mapaGerado: any = [];
  // public markers: Marker[] = [];
  public map: any;

  public thingStatusArray: any;

  // sortBy varaibles
  public sortBy: string = 'thingName';
  public sortDirection: string = '';

  // Variable used for the device association in modal service
  // public userType: string = '';
  public userRole: any;

  get stateName() {
    return this.show ? 'show' : 'hide';
  }

  // Value to manage the pagination
  public itemsPerPage = 10;
  public loadedItems = 0;

  //Report Filters
  public startDate: any = this.reports.getDateThirtyDaysAgo();
  public endDate: any = this.reports.getCurrentDate();
  public statistic: string = 'Collections';
  public selectedThings: string[] = [];
  public selectedMarkets: any = 'All';

  //Filter Arrays
  public things: any = [];
  public zones: any = [];

  //Filter Booleans
  public allThings: boolean = true;
  public allMarkets: boolean = true;
  public generatingCSV: boolean = false;

  //Reports
  public collections: any[] = [];
  public history: any[] = [];
  public counts: any[] = [];

  public thingNamesArray: any[] = [];
  public deviceThingsArray: any[] = [];
  public zonesData: any[] = [];
  public zonesArray: ZoneItem[] = [];
  public zonesArrayFiltered: any[] = [];
  public currentClientId: any;
  public currentDistributorId: any;

  public currentUser: string = "";

  public isOptionsOpen = false;

  public csvExportOptionSelected: string = "";

  public color: string = "";

  public timestamp: number = 0;

  public thing = '';

  public binThreshold = '';

  public totalCollections: number = 0;

  public tonnage: number = 0;

  public binData: Bin = {
    above_ground: 0,
    active: 0,
    bin_address: '',
    bin_depth: 0,
    bin_height: 0,
    bin_id: '',
    bin_location: '',
    bin_model_number: '',
    bin_postal_code: '',
    bin_shape: '',
    bin_usage: '',
    bin_width: 0,
    client_id: '',
    distributor_id: '',
    thing_name: '',
    bin_volume: 0,
  };

  public bat: string = '';

  public series: any = [];

  public loading: boolean = true;

  public returnedResult = true;

  public totalWasteCollected = 0;

  public maxDaysWithNoCollections: number = 0;
  public minDaysWithNoCollections: number = 0;

  public reportsNew: any;

  public deviceInformation: any = [];

  async ngOnInit(): Promise<void> {

    // Missing role validation logic 

    // Verify user
    const currentUser = await this.cognitoService.getUser();

    // Extract user attributes
    this.currentClientId = currentUser.attributes['custom:client_id'];
    this.currentDistributorId = currentUser.attributes['custom:distributor_id'];

    // Switch statement to determine the user type based on available identifiers
    switch(true){ 
      // Case where currentClientId is defined and not null
      case (this.currentClientId !== undefined && this.currentClientId !== null):
        // Set currentUser to "client" as it indicates a client user
        this.currentUser = "client";
        break;

      // Case where currentDistributorId is defined and not null 
      case (this.currentDistributorId !== undefined && this.currentClientId !== null):
        // Set currentUser to "distributor" as it indicates a distributor user
        this.currentUser = "distributor";
        break;
      // Default case, no action needed
      default: 
      // Handle default case if needed
        break;
    }

    // Extract start and end date from route parameters
    const start = this.route.snapshot.params['start'];
    const end = this.route.snapshot.params['end'];

    // Validate and update start date
    if (
      start !== undefined &&
      (new Date(start) <= new Date(this.endDate) ||
        new Date(start) <= new Date(end))
    ) {
      this.startDate = start;
    }

    // Validate and update end date
    if (end !== undefined && new Date(end) > new Date(this.startDate)) {
      this.endDate = end;
    }

    // Fetch array of device relationships
    (await this.getRelationships()).subscribe();

    // Show/hide modal components
    this.showModal = true;
    this.reports.showReportModal = false;
 
    // Initialize data
    await this.initData();

  }

  // in progress - functio to check if a device dont has data - so inform that to the user 
  findMissingThingNames(firstArray: any[], secondArray: any[]): string[] {
    
    const firstThingNames = firstArray.map(item => item.thing_name);
  
    // Extract the thing_names from the second array
    const secondThingNames = secondArray.map(item => item.thing_name);

    // Finds thing_names that are in secondThingNames but not in firstThingNames
    const missingThingNames = secondThingNames.filter(name => !firstThingNames.includes(name));
    return missingThingNames;
  }

  // Toggle the options state 
  toggleOptions() {
    this.isOptionsOpen = !this.isOptionsOpen;
  }

  // Function to get the zone list
  async getZonesList(): Promise<void> {

    if(this.currentUser  === "distributor") {
      // If no zones are obtained because there is no zone registered with distributor ID
      this.zonesArrayFiltered = [];
     } else {

      try {
        // Call the zoneService to get zones data
        this.zoneService.getZones().subscribe(
          // Successful response callback
          (response: any) => {
            // Store the API response in the zonesData array
            this.zonesData = response;
  
            // Use the filter function to get only items with the desired client_id
            const filteredZonesData = this.zonesData.filter(item => item.client_id === this.currentClientId);
  
            // Update this.zonesData to contain only the filtered items
            this.zonesData = filteredZonesData;
  
            // Add to check if this.zonesData is null or empty
            if (this.zonesData == null || this.zonesData.length === 0) {
              // this.systemMessage.selectRibbon('danger', 'loadingDataGeneralError');
              // this.hasError = true;
    
              return; // Exit the function or handle the case accordingly
            } else {
              // this.hasError = false;
    
            }
    
            // Map the response data to a new format for easier use
            this.zonesArrayFiltered = this.zonesData.map(item => {
              const zoneCoordinates = typeof item.zone_coordinates === 'string'
                ? JSON.parse(item.zone_coordinates)
                : item.zone_coordinates;
    
              return {
                zone_id: item.zone_id,
                zone_name: item.zone_name,
              };
            });
    
            // Copy zonesArrayFiltered to zonesArray
            this.zonesArray.push(...this.zonesArrayFiltered);
    
            return this.zonesArrayFiltered;
          },
        );
      } catch (error) {
        // Handle and log any errors that occur during the process
        console.error('Error: ', error);
      }
     }
    
  }

  async initData() {

    // Fetch the list of zones asynchronously
    const zonesListPromise = this.getZonesList(); // call aws api

    try {

      // Fetch the device list using iotService
      const [deviceList] = await Promise.all([
        this.iotService.getDeviceList()
      ]);

      // Fetch the device list again using iotService and handle the result
      await this.iotService.getDeviceList().then( async (result) => {

        // Populate thingNamesArray with device thing names
        for (const device of result) {
          this.thingNamesArray.push(device.thingName)
        }

        // Get the relationships
        await lastValueFrom(await this.dashboardService.getRelationships())

        // Populate deviceThingsArray with devices from the relationships
        for (const device of this.dashboardService.relationships) {
          const deviceThing: thingsInterface  = {
            client_id: device.client_id,
            thing_name: device.thing_name,
            bin_id: device.bin_id,
            distributor_id: device.distributor_id
           }

           this.deviceThingsArray.push(deviceThing)

        } 

        // Filter deviceThingsArray based on the currentUser type
        switch(true) {
          case (this.currentUser === "client"):
            this.deviceThingsArray = this.deviceThingsArray.filter(item => item.client_id === this.currentClientId)
            break;

          case (this.currentUser === "distributor"):
            this.deviceThingsArray = this.deviceThingsArray.filter(item => item.distributor_id === this.currentDistributorId)
            break;
        }

        // Call the reportFilterDataClientsAndDistributors method and handle the returned data
        const returnResult = (this.reports.reportFilterDataClientsAndDistributors(this.deviceThingsArray, this.zonesArrayFiltered));

        // Populate the things array
        for (const row of returnResult.things) {
          this.things.push({ id: row.thing_name, name: row.thing_name });
        }

        // Populate the zones array
        for (const row of returnResult.zones) {
          this.zones.push({ id: row.zone_id, name: row.zone_name });
        }

      });

    } catch (error) { 
      // Handle and log any errors that occur during data initialization
      console.error('Error initializing data:', error);
      this.systemMessageService.selectRibbon('danger', 'loadingDataGeneralError');
    }
  }

  ngAfterViewInit(): void {
    this.pdf.usageCanvas = this.usageCanvas;
    this.pdf.marketCanvas = this.marketCanvas;
    this.pdf.countCanvas = this.countCanvas;
    this.pdf.tonnageCanvas = this.tonnageCanvas;

    // Call the function to detect if the user has reached the end of the page on a mobile device
    this.detectEndOfPageOnMobile();
  }

  // Function to handle the change event for "All Things" checkbox
  onChangeAllThings(event: any) {
    // Update the value of allThings based on the checked state of the checkbox
    this.allThings = (event.target as HTMLInputElement).checked;
    return this.allThings;
  }
  
  // Function to handle the change event for "All Markets" checkbox
  onChangeAllMarkets(event: any) {
    // Update the value of allMarkets based on the checked state of the checkbox
    this.allMarkets = (event.target as HTMLInputElement).checked;
    return this.allMarkets;
  }

  // Function to close report modal
  closeModal() {
    // Set showModal and showReportModal to false to hide the modals
    this.showModal = false;
    this.reports.showReportModal = false;
  }

  // Asynchronously compiles a report in PDF format
  async compileReport() {
    this.pdf.generatingPDF = true;
    // Set a flag to indicate that PDF generation is in progress
    this.pdf.generatingPDF = true;
    try {
      let things = [];
      // Check if all things or selected things should be included in the report
      if (!this.allThings) {
        // Include only selected things
        things = this.selectedThings;
      } else {
        // Include all things by iterating through the list and extracting IDs
        for (const row of this.things) {
          things.push(row.id);
        }
      }

      // Update PDF parameters with selected things, start date, and end date
      this.pdf.things = things;
      this.pdf.start = this.startDate;
      this.pdf.end = this.endDate;

      // Asynchronously save the PDF
      await this.pdf.savePDF();
    } catch (error) {
      // Handle any errors that occur during PDF generation and update the flag
      this.pdf.generatingPDF = false;
    }
  }

  // Function that will calculate the volume if all field are well filled
  calculateCubicMeters(binVolume: number, dst: number, binHeight: number) {
    // Calculate the multiplier based on the given formula
    const multiplier = (1 - (dst / binHeight));
    // Calculate the result - then return the calculated cubic meters
    return binVolume / 1000 * multiplier;
  }
 
  //Calculate the total waste collected
  calculateWasteCollection(usage: string, totalCubicMetersOfWaste: number): number {
    // Get the weight per cubic meter based on the given usage
    const weightPerCubicMeter = this.getWeightByValue(usage);

    // Check if the inputs are valid and return the calculated total waste collection
    if (!isNaN(totalCubicMetersOfWaste) && weightPerCubicMeter !== undefined) {
      return totalCubicMetersOfWaste * weightPerCubicMeter;
    }
    else {
      return 0; // Return 0 if inputs are not valid
    }
  }

  //Get the average weight for the bin usage type
  getWeightByValue(value: string): number {
    const bin = BinUsage.find((bin) => bin.value === value);
    return bin?.weight || 0;
  }

  eraseOldData() {
    this.history = [];
    this.counts = [];
    this.collections = [];
  }

  // Asynchronous function to export CSV reports based on the selected option
  async exportCSVReports(option: number) {

    // Clear previous data and set generatingCSV flag to true
    this.eraseOldData();
    this.generatingCSV = true;
    
    // Convert start and end dates to Unix timestamps
    const start = this.dateToUnixTimestamp(this.startDate+ "T00:00:00");
    const end = this.dateToUnixTimestamp(this.endDate+ "T00:00:00");

    // Initialize array for selected things
    let things = [];
    if (!this.allThings) {
      things = this.selectedThings;
    } else {
      for (const row of this.things) {
        things.push(row.id);
      }
    }

    try {
      // Fetch report data using reports service
      (await this.reports.getReportData(things, start, end))
        .pipe(
          map((response) => {
            console.log(response)
            return response
          }),
          catchError((error) => {
            console.error('API Error:', error);
            // Handle API error and set generatingCSV to false
            this.generatingCSV = false;
            this.systemMessageService.selectRibbon(
              'danger',
              'alert-danger-generic-message'
            );
            throw error; // Re-throw the error for the calling code to handle
          })
        )
        .subscribe((res: any) => {
          // Parse and process collections, counts, and history data
          for (const collection of JSON.parse(res?.collections)) {
            this.collections.push(collection);
          }

          for (const counts of JSON.parse(res?.counts)) {
            this.counts.push(counts);
          }

          for (const history of JSON.parse(res?.history)) {
            this.history.push(history);
          }

          const deviceReport: any = [];
          const historyArray = this.history;

          // Process history data and update timestamps
          for (const row of historyArray) {
            // Update timestamps to Date objects
            if (row.timestamp.toString().length <= 10) {
              row.timestamp = new Date(row.timestamp * 1000);
            } else {
              row.timestamp = new Date(parseInt(row.timestamp));
            }
            // Update additional timestamps to Date objects
            row.img = 'N/A';
            row.fdv_timestamp = new Date(row.fdv_timestamp * 1000);
            row.img_timestamp = new Date(row.img_timestamp * 1000);
            row.tmp_timestamp = new Date(row.tmp_timestamp * 1000);
            row.vcc_timestamp = new Date(row.vcc_timestamp * 1000);
            row.sig_timestamp = new Date(row.sig_timestamp * 1000);
            row.pre_timestamp = new Date(row.pre_timestamp * 1000);
            row.bat_timestamp = new Date(row.bat_timestamp * 1000);
            row.tm1_timestamp = new Date(row.tm1_timestamp * 1000);
            row.tm2_timestamp = new Date(row.tm2_timestamp * 1000);
            row.dst_timestamp = new Date(row.dst_timestamp * 1000);
            row.tm0_timestamp = new Date(row.tm0_timestamp * 1000);
            row.nct_timestamp = new Date(row.nct_timestamp * 1000);
            row.lfr_timestamp = new Date(row.lfr_timestamp * 1000);
            row.hum_timestamp = new Date(row.hum_timestamp * 1000);
            row.voc_timestamp = new Date(row.voc_timestamp * 1000);
            row.hib_timestamp = new Date(row.hib_timestamp * 1000);

            deviceReport.push(row);
          }
         
          // Export CSV based on the selected option
          switch(option){
                case 1://history report
                  this.csv.exportToCsv(deviceReport, `-History-${this.startDate.replaceAll("-", "")}-${this.endDate.replaceAll("-", "")}.csv`)
                  break;
                case 2://bin report ok
                  this.csv.exportToCsv(this.counts, `-bin-report-${this.startDate.replaceAll("-", "")}-${this.endDate.replaceAll("-", "")}.csv`)
                  break;
                case 3://collection report ok
                  this.systemMessageService.selectRibbon('info', 'openCSV');
                  setTimeout(()=>{this.csv.exportToCsv(this.collections, `-collections-report-${this.startDate.replaceAll("-", "")}-${this.endDate.replaceAll("-", "")}.csv`)},3000)
                  break;
          }

          // Set generatingCSV to false after exporting
          this.generatingCSV = false;
        });
    } catch (error) {
      // Handle and log any errors that occur during CSV export
      this.systemMessageService.selectRibbon(
        'danger',
        'alert-danger-generic-message'
      );
      console.error("CSV Error: ", error)
      this.generatingCSV = false;
    }

  }

  dateToUnixTimestamp(dateString: string): number {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Get the UTC timestamp in milliseconds
    const timestampInMilliseconds = date.getTime();

    // Convert milliseconds to seconds and return
    return Math.floor(timestampInMilliseconds / 1000);
  }

  // Handle End Of Page On Mobile event
  detectEndOfPageOnMobile(): void {
    // Check if the user is using a mobile device
    if (this.isMobileDevice()) {
      // Listen for the 'touchmove' event to track scrolling on the document
      this.renderer.listen('document', 'touchmove', (event: TouchEvent) => {
        // Get the current scroll position, window height, and document height
        const scrollPosition =
          window.scrollY ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0;
        const windowHeight =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight ||
          0;
        const documentHeight = Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.documentElement.clientHeight
        );
        // Check if the user has scrolled to the end of the page and not currently loading more data
        if (
          scrollPosition + windowHeight >= documentHeight &&
          !this.loadingMoreData
        ) {
          // this.loadMoreItems();
        }
      });
    }
  }

  // checks if the application is being accessed from a mobile device
  isMobileDevice(): boolean {
    // Get the user agent string and convert it to lowercase
    const userAgent = navigator.userAgent.toLowerCase();

    // Check if the user agent matches any common mobile device keywords
    return /mobile|android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    );
  }

  // Function to check if a given date is within the specified date range
  isDateIn(date: Date): boolean {
    // Create start and end dates based on the component's start and end date properties
    const start = new Date(this.startDate + 'T00:00:00');
    const end = new Date(this.endDate + 'T00:00:00');

    // Check if the given date is within the specified range
    return date >= start && date <= end;
  }

  async initLineChart() {
    //Get Translated Label for Line Chart
    const yAxisLabel = await this.charts.getChartLabel('yAxisLineChartLabel');

    //Set Line Chart Default dimensions
    this.charts.viewLineChartAdmin = [600, 450];

    //Get Last Row of History array (Most Recent Shadow Data Entry)
    const lastRow = this.reports.historyArray.length - 1;

    //Set Class Parameters for Device Health Check
    this.bat = this.reports.historyArray[lastRow].bat;
    this.timestamp = this.reports.historyArray[lastRow].dst_timestamp;

    const start = new Date(this.startDate);
    start.setUTCHours(5, 0, 0, 0);
    const end = new Date(this.endDate);
    end.setUTCHours(5, 0, 0, 0);

    //X Axis Label
    this.charts.xAxisLabelLineChartAdmin = `${start.toLocaleDateString(
      this.charts.getDateLanguage(),
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
    )} - ${end.toLocaleDateString(this.charts.getDateLanguage(), {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })}`;

    for (let row of this.reports.historyArray) {
      //Format Timestamps
      const date = this.formatDate(row.dst_timestamp);
      if (this.isDateIn(date)) {
        //Format Timestamps
        const formattedDate = date.toLocaleDateString(
          this.charts.getDateLanguage(),
          {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }
        );

        //Line Chart Row Data
        this.series.push({
          name: formattedDate,
          value: (row.dst / this.binData.bin_height) * 100,
        });
      }
    }

    //Compile Line Chart Data
    this.charts.singleLineChart = [
      {
        name: this.reports.historyArray[0].thing_name,
        series: this.series,
      },
    ];

    //Set Y Axis Label
    this.charts.yAxisLabelLineChartAdmin = yAxisLabel;
  }

  async initCollections() {

    //Declare function variables
    let collectionCount = 0, //to count collection for the month
      totalCollections = 0, //total collection count
      barChartRow: any[] = [], // array for monthly collects
      noCollectionCountArray: any[] = [], //to save days with no collection for graphs
      noCollectionsCount: number[] = [], //to saves days with no collection for min/max
      currentRow, //to save current row
      previousRow, //to save previous row
      currentDate: Date, //to save current date
      formattedDate; //to save formatted current date
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]; // Define an array of month names

    //Set Color Pallet for Horizontal Bar Chart
    this.charts.colorSchemeBarChartAdmin.domain = [
      '#1f77b4',
      '#ff7f0e',
      '#2ca02c',
      '#d62728',
      '#9467bd',
      '#8c564b',
      '#e377c2',
      '#7f7f7f',
      '#bcbd22',
      '#17becf',
      '#ff9896',
      '#aec7e8',
    ];

    this.charts.xAxisLabelBarChartAdmin = await this.charts.getChartLabel(
      'xAxisCollectionBarChartLabel'
    );
    this.charts.showXAxisLabelBarChartAdmin = false;
    this.charts.showLegendBarChartAdmin = false;
    this.charts.viewBarChartAdmin = [600, 450];

    //Initialize values for iteration

    let daysWithNoCollectionCount = 0;

    let start = this.dateToUnixTimestamp(this.startDate),
      end = this.dateToUnixTimestamp(this.endDate);
      

    (await this.reports.getReportData([this.thing], start, end)).subscribe({
      next: (res: any) => {

        const collectionData = JSON.parse(res.collections); // get the thing collection data
        if (!(collectionData.length > 0)) {
          //if collection data empty
          this.returnedResult = false; //show different UI for no results
          return; //exits function to avoid data processing error
        }
        ////////////// Monthly collection chart ///////////////////////
        for (let index = 0; index < collectionData.length; index++) {
          //for each collection in the collection data set
          currentRow = collectionData[index]; //get the current collection
          previousRow = index > 0 ? collectionData[index - 1] : null; //get the previous collecton if it exixts

          currentDate = new Date(currentRow.preceding_timestamp * 1000); //get the date of the current collection

          formattedDate = currentDate.toLocaleDateString('en-CA', {
            //formats the date
            year: 'numeric',
            month: 'short',
          });

          if (currentRow) collectionCount++, totalCollections++; // Increment collectionCount amd totalCollection for each row
          if (previousRow) {
            //if previous row exixts
            const previousDate = new Date(
              previousRow.preceding_timestamp * 1000
            ); //get previous date
            if (!this.isInSameMonth(currentDate, previousDate)) {
              // Check if current and previous dates are not in the same month
              barChartRow.push({ name: formattedDate, value: collectionCount }); // Push the data into the array with the current collectionCount
              collectionCount = 0; // Reset collectionCount for the new month
            } else if (this.isInSameMonth(currentDate, previousDate)) {
              //if current and previous dates are in the same month
              collectionCount++; //increment the count
              barChartRow.push({ name: formattedDate, value: collectionCount }); // Push the data into the array with the current collectionCount
            }

            //////////// days with no collection////////////
            daysWithNoCollectionCount =
              daysWithNoCollectionCount +
              this.calculateDaysBetweenDates(previousDate, currentDate); //calculate days with no collection
            noCollectionsCount.push(daysWithNoCollectionCount); //add days with no collection for min/max values
            noCollectionCountArray.push({
              name: totalCollections,
              value: daysWithNoCollectionCount,
            }); //push data to array for graphs
            daysWithNoCollectionCount = 0; //recet count for next itteration
            //////////// days with no collection////////////
          } else {
            // Push the first entry into the array
            barChartRow.push({ name: formattedDate, value: collectionCount });
          }

          ////////////////// bin capacity per collection ////////////////////////////////////
          const collectedQuantity = Number(
            (
              ((this.binData.bin_height - currentRow.preceding_dst) /
                this.binData.bin_height) *
              100
            ).toFixed(2)
          ); //calculate collected volume

          this.collections.push({
            name: currentDate.toLocaleDateString(
              this.charts.getDateLanguage(),
              {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              }
            ),
            value: collectedQuantity,
          }); //push data to array for graphs
          ////////////////// bin capacity per collection ////////////////////////////////////
        }
        ////////// total waste collected calculation //////////////////////

        const cubicMetersOfWaste =
          this.calculateCubicMeters(
            this.binData.bin_volume,
            JSON.parse(res.counts)[0].avg_preceding_dst,
            this.binData.bin_height
          ) * this.collections.length;
        const tonnage =
          this.calculateWasteCollection(
            this.binData.bin_usage,
            cubicMetersOfWaste
          ) * 0.001;
        this.tonnage = tonnage;

        ////////// total waste collected calculation //////////////////////

        const counts: { [key: string]: number } = {}; // to store counts for each month
        barChartRow.forEach((item) => {
          //for each item in barChartRow
          const name = item.name; //get the name
          counts[name] = (counts[name] || 0) + 1; //increment count by 1 for matching month or initalize it to 0 if it doesn't exist
        });
        const filteredArray = Object.keys(counts).map((name) => ({
          name,
          value: counts[name],
        })); //new array of object with unique month and their count

        //To add the months with no collection to the array
        for (let monthName of monthNames) {
          //for each month in array
          const monthExists = filteredArray.some((item) =>
            item.name.toLowerCase().includes(monthName.toLowerCase())
          ); // Check if the month already exists in the barChartRow array
          if (!monthExists) {
            // If the month does not exist, add it with a value of 0
            const formattedDate = `${monthName} ${currentDate.getFullYear()}`; //create the name property
            filteredArray.push({ name: formattedDate, value: 0 }); //add the month with a value of 0
          }
        }
        // Sort the filteredArray array by asc month
        filteredArray.sort((a, b) => {
          const dateA = new Date(a.name);
          const dateB = new Date(b.name);
          return dateA.getTime() - dateB.getTime();
        });
        //translate month name to the current language for graphics
        for (let monthData of filteredArray) {
          monthData.name = new Date(monthData.name).toLocaleDateString(
            this.charts.getDateLanguage(),
            {
              year: 'numeric',
              month: 'short',
            }
          );
        }
        barChartRow = filteredArray; //updates monthly collection chart array with correct data
        /* remove the comments for debug 
        console.log(this.collections)
        console.log(noCollectionCountArray)
        console.log('with missing months ',filteredArray);*/

        this.charts.singleBarChartAdmin = barChartRow; // Set monthly collection chart
        this.charts.singleBarChartCollections = this.collections; // set capacity per collection chart
        this.charts.singleBarChart = noCollectionCountArray; // set days without collection chart

        this.totalCollections = totalCollections; //set the total number of collections
        this.totalWasteCollected = Number(tonnage.toFixed(2)); //set the total waste collected
        this.maxDaysWithNoCollections = Math.max(...noCollectionsCount); //set maximum days without collection
        this.minDaysWithNoCollections = Math.min(...noCollectionsCount); //set minimum days without collection
        this.binThreshold = `${JSON.parse(res.bins)[0].threshold}%`; //set threshold alert level

        if(this.binThreshold === 'null%') {
          this.binThreshold = '';
        }
      },
      error: (error: any) => {
        // should add a message to let user know what device is without data
        console.error('error retrieving data: ', error);
      },
    });
    this.charts.showLegendBarChart = false;
    this.charts.viewBarChart = [600, 450];
    this.charts.viewBarChartCollections = [600, 450];
    this.charts.showLegendBarChartCollections = false;
    this.charts.showXAxisBarChartCollections = true;
  }

  //Convert UTC Unix timestamp(H) to local date-time string
  formatDate(date: number) {
    const formattedDate = new Date(date * 1000);
    return formattedDate;
  }

  //Check if 2 rows are within the same month
  isInSameMonth(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth()
    );
  }

  calculateDaysBetweenDates(startDate: Date, endDate: Date): number {
    // Convert both dates to UTC to ensure consistent calculations
    const utcStartDate = Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    const utcEndDate = Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );

    // Calculate the difference in milliseconds
    const millisecondsPerDay = 1000 * 60 * 60 * 24; // Number of milliseconds in a day
    const differenceInMilliseconds = Math.abs(utcEndDate - utcStartDate);

    // Convert the difference to days
    const daysDifference = Math.floor(
      differenceInMilliseconds / millisecondsPerDay
    );
    return daysDifference;
  }

  // Asynchronous function to fetch device relationships
  async getRelationships() {
    // Construct the API URL using environment variables
    const url =
      environment.api.stage + environment.api.route.getDeviceRelationships;

    // Send HTTP GET request to fetch device relationships
    return this.http.get(url).pipe(
      map((response) => {
        // Process the API response and populate the relationships array
        if (response) {
          for (const row of JSON.parse(JSON.stringify(response))) {
            this.relationships.push({
              thing_name: row.thing_name,
              bin_id: row.bin_id,
            });
          }
        }
      }),
      catchError((error) => {
        // Log and handle API errors
        console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      })
    );
  }

}
