<section class="container-fluid">
  <button class="btn btn-primary" (click)="pdf.savePDF()">Generate PDF</button>
  <canvas #usageCanvas style="display: none;"></canvas>
  <canvas #marketCanvas style="display: none;"></canvas>
  <canvas #countCanvas style="display: none;"></canvas>
  <canvas #tonnageCanvas style="display: none;"></canvas>
</section>



