import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class Model {
  Model: string = '';
  Type: string = '';
  Code: string = '';
  Version: string ='';
}

export class Alert {
  Alert: string = '';
  Type: string = '';
  Account: string = '';
  Owner: string ='';
}

export class Device {
  DeviceID: string = '';
  Model: string = '';
  Type: string = '';
  Status: string = '';
  State: any = Array();
  Gps: any = Array();
  Date: any;
  ExpandInfo: boolean = false;
}

export class DeviceService {

  constructor() {  }

  public alertsArrayHC: string = "";
  public modelsArrayHC: string = "";
  public devicesArrayHC: string = "";

 public getAllAlerts() : Alert[] {
  // Initialize an empty array to store alerts
  let alertArray: Alert[] = [];

  // Return the array of alerts (which is currently empty)
  return alertArray;
 }

  public getAllModels() : Model[] {
    // Initialize an empty array to store models
    let modelsArray: Model[] = [];

     // Return the array of models (which is currently empty)
    return modelsArray;
  }

  public getAllDevices(): Device[]{
    // Initialize an empty array to store devices
    let devicesArray: Device[] = [ ]

    // Return the array of devices (which is currently empty)
    return devicesArray;
  }

  // Retrieves the current state of alerts @returns An array of processed Alert objects.
  public getAlertState() {
    // Parse JSON data for alerts retrieved
    var alertData = JSON.parse(this.getAlert());

    // Initialize an empty array to store processed alerts
    let alertsArray: Alert[] = [];

    // Loop through each alert data retrieved
    for (let i=0; i < this.modelsArrayHC.length; i++) {
      // Check if there is data for this alert
      if (alertData[i]){
        // Create a new Alert object and populate its properties
        let testAlert: Alert = new Alert();
        testAlert.Alert = alertData[i].Alert;
        testAlert.Type = alertData[i].Type;
        testAlert.Account = alertData[i].Account;
        testAlert.Owner = alertData[i].Owner;
        // Add the processed alert to the array
        alertsArray.push(testAlert);
      }
    }

    // Return the array of processed alerts
    return alertsArray;
  }

  // Retrieves the current state of models @returns An array of processed Model objects
  public getModelState() {
    // Parse JSON data for models retrieved
    var modelData = JSON.parse(this.getModel());

    // Initialize an empty array to store processed models
    let modelsArray: Model[] = [];

    // Loop through each model data retrieved
    for (let i=0; i < this.modelsArrayHC.length; i++) {
      // Check if there is data for this model
      if (modelData[i]){
        // Create a new Model object and populate its properties
        let testModel: Model = new Model();
        testModel.Model = modelData[i].Model; // Assign model name
        testModel.Type = modelData[i].Type; // Assign model type
        testModel.Code = modelData[i].Code; // Assign model code
        testModel.Version = modelData[i].Version; // Assign model version


        // Add the processed model to the array
        modelsArray.push(testModel);
      }
    }
    // Return the array of processed models
    return modelsArray;
  };

  // Retrieves the current state of devices @returns An array of processed Device objects
  public getDeviceState(){
    // Parse JSON data for devices retrieved
    var deviceData = JSON.parse(this.getDevice());

    // Initialize an empty array to store processed devices
    let devicesArray: Device[] = [];

    // Loop through each device data retrieved
    for (let i = 0; i < this.devicesArrayHC.length; i++) {
      // Check if there is data for this device
      if (deviceData[i]){

        // Create a new Device object and populate its properties
        let testDevice: Device = new Device();
        testDevice.DeviceID = deviceData[i].DeviceID; // Assign device ID
        testDevice.Model = deviceData[i].Model; // Assign device model
        testDevice.Status = deviceData[i].Status; // Assign device status
        testDevice.State = deviceData[i].State; // Assign device state
        testDevice.Gps = deviceData[i].State.state.reported.dat.gps; // Assign GPS data

        // Calculate the timestamp value from the data and convert to a formatted date string
        let timestampTest = deviceData[i].State.metadata.reported.dat.fdv.timestamp * 1000;
        let date = new Date(timestampTest).toLocaleString();

        testDevice.Date = date; // Assign device date
        testDevice.ExpandInfo = false; // Set additional info flag to false

        // Add the processed device to the array
        devicesArray.push(testDevice);
      }
    }
    // Return the array of processed devices
    return devicesArray;
  }

  // Simulates retrieving alert data from a hardcoded source @returns A JSON-formatted string containing sample alert data
  public getAlert() {
    // Initialize a string to store the alert data in JSON format
    this.alertsArrayHC = '[';

    // Append individual alert objects with properties to the JSON string
    this.alertsArrayHC +='{';
    this.alertsArrayHC +='"Alert": "IL00000034-1F",';
    this.alertsArrayHC +='"Type": "Threshold",';
    this.alertsArrayHC +='"Account": "Muirwood Sudio",';
    this.alertsArrayHC +='"Owner": ""';
    this.alertsArrayHC+='},';
    this.alertsArrayHC +='{';
    this.alertsArrayHC +='"Alert": "Collection",';
    this.alertsArrayHC +='"Type": "Threshold",';
    this.alertsArrayHC +='"Account": "Muirwood Sudio",';
    this.alertsArrayHC +='"Owner": ""';
    this.alertsArrayHC+='},';
    this.alertsArrayHC +='{';
    this.alertsArrayHC +='"Alert": "IL00000046-F0",';
    this.alertsArrayHC +='"Type": "Threshold",';
    this.alertsArrayHC +='"Account": "Sifec North",';
    this.alertsArrayHC +='"Owner": ""';
    this.alertsArrayHC+='},';
    this.alertsArrayHC +='{';
    this.alertsArrayHC +='"Alert": "IL00000047-46",';
    this.alertsArrayHC +='"Type": "Threshold",';
    this.alertsArrayHC +='"Account": "Sifec North",';
    this.alertsArrayHC +='"Owner": ""';
    this.alertsArrayHC+='}';

    // Close the array in the JSON string
    this.alertsArrayHC +=']';

    // Return the JSON-formatted alert data
    return this.alertsArrayHC;
  }
  // Simulates retrieving model data from a hardcoded source @returns A JSON-formatted string containing sample model data.
  public getModel() {
    // Initialize a string to store the model data in JSON format
    this.modelsArrayHC = '[';

    // Append individual model objects with properties to the JSON string
    this.modelsArrayHC +='{';
    this.modelsArrayHC +='"Model": "MWS-B01",';
    this.modelsArrayHC +='"Type": " ",';
    this.modelsArrayHC +='"Code": "MWS-B01",';
    this.modelsArrayHC +='"Version": "1.0"';
    this.modelsArrayHC+='},';
    this.modelsArrayHC +='{';
    this.modelsArrayHC +='"Model": "MWS-I01",';
    this.modelsArrayHC +='"Type": " ",';
    this.modelsArrayHC +='"Code": "",';
    this.modelsArrayHC +='"Version": "1.0"';
    this.modelsArrayHC+='},';
    this.modelsArrayHC +='{';
    this.modelsArrayHC +='"Model": "MWS-DEV",';
    this.modelsArrayHC +='"Type": " ",';
    this.modelsArrayHC +='"Code": "",';
    this.modelsArrayHC +='"Version": "1.0"';
    this.modelsArrayHC+='},';
    this.modelsArrayHC +='{';
    this.modelsArrayHC +='"Model": "MWS-B02",';
    this.modelsArrayHC +='"Type": " ",';
    this.modelsArrayHC +='"Code": "",';
    this.modelsArrayHC +='"Version": "1.0"';
    this.modelsArrayHC+='}';

    // Close the array in the JSON string
    this.modelsArrayHC +=']';

    // Return the JSON-formatted model data
    return this.modelsArrayHC;
  }

  public getDevice(){
    this.devicesArrayHC = '[';
    this.devicesArrayHC += '{';
    this.devicesArrayHC += '  "DeviceID": "MWS-B01-001",';
    this.devicesArrayHC += ' "Model": "MWS-B01",';
    this.devicesArrayHC += '   "Type": "Waste/Recycling",';
    this.devicesArrayHC += '       "Status": "In Service",';
    this.devicesArrayHC += '       "State": {';
    this.devicesArrayHC += '         "state": {';
    this.devicesArrayHC += '           "desired": {';
    this.devicesArrayHC += '             "config": {';
    this.devicesArrayHC += '               "dst": 1,';
    this.devicesArrayHC += '               "env": 1,';
    this.devicesArrayHC += '               "voc": 0,';
    this.devicesArrayHC += '               "img": 1,';
    this.devicesArrayHC += '               "lfr": 0,';
    this.devicesArrayHC += '               "gps": 0,';
    this.devicesArrayHC += '               "gtc": 0,';
    this.devicesArrayHC += '               "slp": 3600,';
    this.devicesArrayHC += '               "hit": -35,';
    this.devicesArrayHC += '               "his": 28800';
    this.devicesArrayHC += '             }';
    this.devicesArrayHC += '           },';
    this.devicesArrayHC += '           "reported": {';
    this.devicesArrayHC += '             "dat": {';
    this.devicesArrayHC += '               "fdv": "0.1.10",';
    this.devicesArrayHC += '               "tmp": 25.58,';
    this.devicesArrayHC += '               "sig": 57,';
    this.devicesArrayHC += '               "vcc": 3.27,';
    this.devicesArrayHC += '               "bat": 5,';
    this.devicesArrayHC += '               "dst": 185,';
    this.devicesArrayHC += '               "gps": [';
    this.devicesArrayHC += '                 45.65081,';
    this.devicesArrayHC += '                 -73.84834';
    this.devicesArrayHC += '               ],';
    this.devicesArrayHC += '               "tm0": 29,';
    this.devicesArrayHC += '               "nct": 0,';
    this.devicesArrayHC += '               "img": [';
    this.devicesArrayHC += '                 "00b900b900b900b900b800b800b800b800b800b800b800b8",';
    this.devicesArrayHC += '                 "00ae00ae00ae00ae00ae00ae00ae00ae00ae00db00db00db",';
    this.devicesArrayHC += '                 "00db00db00db00db00db00db00bd00bd00bd00bd00bd00bd",';
    this.devicesArrayHC += '                 "00bd00bd00bd0092009200920092009200920092009200a2",';
    this.devicesArrayHC += '                 "00a200a200a200a200a200a200a200a20154015401540154",';
    this.devicesArrayHC += '                 "0154015401540154015400a800a800a800a800a800a800a8",';
    this.devicesArrayHC += '                 "00a800a800aa00aa00aa00aa00aa00aa00aa00aa00f100f1",';
    this.devicesArrayHC += '                 "00f100f100f100f100f100f1010701070107010701070107",';
    this.devicesArrayHC += '                 "0107010701ae01ae01ae01ae01ae01ae01ae01ae01ae0293",';
    this.devicesArrayHC += '                 "0293029302930293029303ae03ae03ae03ae03ae03ae03ae",';
    this.devicesArrayHC += '                 "03ae03ae02a802a802a802a802a802a802a802a802a802c9",';
    this.devicesArrayHC += '                 "02c902c902c902c903a803a803a803a803a803a803a803a8"';
    this.devicesArrayHC += '               ],';
    this.devicesArrayHC += '               "pre": 97.6,';
    this.devicesArrayHC += '               "tm2": 25.58,';
    this.devicesArrayHC += '               "hum": 77.86001,';
    this.devicesArrayHC += '               "hib": 0';
    this.devicesArrayHC += '             },';
    this.devicesArrayHC += '             "car": {';
    this.devicesArrayHC += '               "phn": "+14384312392",';
    this.devicesArrayHC += '               "imei": "354724644847300",';
    this.devicesArrayHC += '               "fmv": "L0.0.00.00.05.11,A.02.16",';
    this.devicesArrayHC += '               "icc": "89302690100004272107",';
    this.devicesArrayHC += '               "oper": "Bell",';
    this.devicesArrayHC += '               "hwi": "B08-4B4E/11417"';
    this.devicesArrayHC += '             }';
    this.devicesArrayHC += '           },';
    this.devicesArrayHC += '           "delta": {';
    this.devicesArrayHC += '             "config": {';
    this.devicesArrayHC += '               "dst": 1,';
    this.devicesArrayHC += '               "env": 1,';
    this.devicesArrayHC += '               "voc": 0,';
    this.devicesArrayHC += '               "img": 1,';
    this.devicesArrayHC += '               "lfr": 0,';
    this.devicesArrayHC += '               "gps": 0,';
    this.devicesArrayHC += '               "gtc": 0,';
    this.devicesArrayHC += '               "slp": 3600,';
    this.devicesArrayHC += '               "hit": -35,';
    this.devicesArrayHC += '               "his": 28800';
    this.devicesArrayHC += '             }';
    this.devicesArrayHC += '           }';
    this.devicesArrayHC += '         },';
    this.devicesArrayHC += '         "metadata": {';
    this.devicesArrayHC += '           "desired": {';
    this.devicesArrayHC += '             "config": {';
    this.devicesArrayHC += '               "dst": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "env": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "voc": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "img": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "lfr": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "gps": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "gtc": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "slp": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "hit": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "his": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               }';
    this.devicesArrayHC += '             }';
    this.devicesArrayHC += '           },';
    this.devicesArrayHC += '           "reported": {';
    this.devicesArrayHC += '             "dat": {';
    this.devicesArrayHC += '               "fdv": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "tmp": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "sig": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "vcc": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "bat": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "dst": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "gps": [{';
    this.devicesArrayHC += '                   "timestamp": 1642802890';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1642802890';
    this.devicesArrayHC += '                 }';
    this.devicesArrayHC += '               ],';
    this.devicesArrayHC += '               "tm0": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "nct": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "img": [{';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 }';
    this.devicesArrayHC += '               ],';
    this.devicesArrayHC += '               "pre": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "tm2": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "hum": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "hib": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               }';
    this.devicesArrayHC += '             },';
    this.devicesArrayHC += '             "car": {';
    this.devicesArrayHC += '               "phn": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "imei": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "fmv": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "icc": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "oper": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "hwi": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               }';
    this.devicesArrayHC += '             }';
    this.devicesArrayHC += '           }';
    this.devicesArrayHC += '         },';
    this.devicesArrayHC += '         "version": 4732,';
    this.devicesArrayHC += '         "timestamp": 1690307088';
    this.devicesArrayHC += '       }';
    this.devicesArrayHC += '     }';
    this.devicesArrayHC += ',';
    this.devicesArrayHC += '{';
    this.devicesArrayHC += '  "DeviceID": "MWS-B01-002",';
    this.devicesArrayHC += ' "Model": "MWS-B01",';
    this.devicesArrayHC += '   "Type": "Waste/Recycling",';
    this.devicesArrayHC += '       "Status": "unknown",';
    this.devicesArrayHC += '       "State": {';
    this.devicesArrayHC += '         "state": {';
    this.devicesArrayHC += '           "desired": {';
    this.devicesArrayHC += '             "config": {';
    this.devicesArrayHC += '               "dst": 1,';
    this.devicesArrayHC += '               "env": 1,';
    this.devicesArrayHC += '               "voc": 0,';
    this.devicesArrayHC += '               "img": 1,';
    this.devicesArrayHC += '               "lfr": 0,';
    this.devicesArrayHC += '               "gps": 0,';
    this.devicesArrayHC += '               "gtc": 0,';
    this.devicesArrayHC += '               "slp": 3600,';
    this.devicesArrayHC += '               "hit": -35,';
    this.devicesArrayHC += '               "his": 28800';
    this.devicesArrayHC += '             }';
    this.devicesArrayHC += '           },';
    this.devicesArrayHC += '           "reported": {';
    this.devicesArrayHC += '             "dat": {';
    this.devicesArrayHC += '               "fdv": "0.1.10",';
    this.devicesArrayHC += '               "tmp": 25.58,';
    this.devicesArrayHC += '               "sig": 57,';
    this.devicesArrayHC += '               "vcc": 3.27,';
    this.devicesArrayHC += '               "bat": 5,';
    this.devicesArrayHC += '               "dst": 185,';
    this.devicesArrayHC += '               "gps": [';
    this.devicesArrayHC += '                 45.54997317508054,';
    this.devicesArrayHC += '                 -73.76714214938698';
    this.devicesArrayHC += '               ],';
    this.devicesArrayHC += '               "tm0": 29,';
    this.devicesArrayHC += '               "nct": 0,';
    this.devicesArrayHC += '               "img": [';
    this.devicesArrayHC += '                 "00b900b900b900b900b800b800b800b800b800b800b800b8",';
    this.devicesArrayHC += '                 "00ae00ae00ae00ae00ae00ae00ae00ae00ae00db00db00db",';
    this.devicesArrayHC += '                 "00db00db00db00db00db00db00bd00bd00bd00bd00bd00bd",';
    this.devicesArrayHC += '                 "00bd00bd00bd0092009200920092009200920092009200a2",';
    this.devicesArrayHC += '                 "00a200a200a200a200a200a200a200a20154015401540154",';
    this.devicesArrayHC += '                 "0154015401540154015400a800a800a800a800a800a800a8",';
    this.devicesArrayHC += '                 "00a800a800aa00aa00aa00aa00aa00aa00aa00aa00f100f1",';
    this.devicesArrayHC += '                 "00f100f100f100f100f100f1010701070107010701070107",';
    this.devicesArrayHC += '                 "0107010701ae01ae01ae01ae01ae01ae01ae01ae01ae0293",';
    this.devicesArrayHC += '                 "0293029302930293029303ae03ae03ae03ae03ae03ae03ae",';
    this.devicesArrayHC += '                 "03ae03ae02a802a802a802a802a802a802a802a802a802c9",';
    this.devicesArrayHC += '                 "02c902c902c902c903a803a803a803a803a803a803a803a8"';
    this.devicesArrayHC += '               ],';
    this.devicesArrayHC += '               "pre": 97.6,';
    this.devicesArrayHC += '               "tm2": 25.58,';
    this.devicesArrayHC += '               "hum": 77.86001,';
    this.devicesArrayHC += '               "hib": 0';
    this.devicesArrayHC += '             },';
    this.devicesArrayHC += '             "car": {';
    this.devicesArrayHC += '               "phn": "+14384312392",';
    this.devicesArrayHC += '               "imei": "354724644847300",';
    this.devicesArrayHC += '               "fmv": "L0.0.00.00.05.11,A.02.16",';
    this.devicesArrayHC += '               "icc": "89302690100004272107",';
    this.devicesArrayHC += '               "oper": "Bell",';
    this.devicesArrayHC += '               "hwi": "B08-4B4E/11417"';
    this.devicesArrayHC += '             }';
    this.devicesArrayHC += '           },';
    this.devicesArrayHC += '           "delta": {';
    this.devicesArrayHC += '             "config": {';
    this.devicesArrayHC += '               "dst": 1,';
    this.devicesArrayHC += '               "env": 1,';
    this.devicesArrayHC += '               "voc": 0,';
    this.devicesArrayHC += '               "img": 1,';
    this.devicesArrayHC += '               "lfr": 0,';
    this.devicesArrayHC += '               "gps": 0,';
    this.devicesArrayHC += '               "gtc": 0,';
    this.devicesArrayHC += '               "slp": 3600,';
    this.devicesArrayHC += '               "hit": -35,';
    this.devicesArrayHC += '               "his": 28800';
    this.devicesArrayHC += '             }';
    this.devicesArrayHC += '           }';
    this.devicesArrayHC += '         },';
    this.devicesArrayHC += '         "metadata": {';
    this.devicesArrayHC += '           "desired": {';
    this.devicesArrayHC += '             "config": {';
    this.devicesArrayHC += '               "dst": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "env": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "voc": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "img": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "lfr": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "gps": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "gtc": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "slp": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "hit": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "his": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               }';
    this.devicesArrayHC += '             }';
    this.devicesArrayHC += '           },';
    this.devicesArrayHC += '           "reported": {';
    this.devicesArrayHC += '             "dat": {';
    this.devicesArrayHC += '               "fdv": {';
    this.devicesArrayHC += '                 "timestamp": 1661172460';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "tmp": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "sig": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "vcc": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "bat": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "dst": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "gps": [{';
    this.devicesArrayHC += '                   "timestamp": 1642802890';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1642802890';
    this.devicesArrayHC += '                 }';
    this.devicesArrayHC += '               ],';
    this.devicesArrayHC += '               "tm0": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "nct": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "img": [{';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 }';
    this.devicesArrayHC += '               ],';
    this.devicesArrayHC += '               "pre": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "tm2": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "hum": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "hib": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               }';
    this.devicesArrayHC += '             },';
    this.devicesArrayHC += '             "car": {';
    this.devicesArrayHC += '               "phn": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "imei": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "fmv": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "icc": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "oper": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "hwi": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               }';
    this.devicesArrayHC += '             }';
    this.devicesArrayHC += '           }';
    this.devicesArrayHC += '         },';
    this.devicesArrayHC += '         "version": 4732,';
    this.devicesArrayHC += '         "timestamp": 1690307088';
    this.devicesArrayHC += '       }';
    this.devicesArrayHC += '     }';
    this.devicesArrayHC += ',';
    this.devicesArrayHC += '{';
    this.devicesArrayHC += '  "DeviceID": "MWS-B01-003",';
    this.devicesArrayHC += ' "Model": "MWS-B01",';
    this.devicesArrayHC += '   "Type": "Waste/Recycling",';
    this.devicesArrayHC += '       "Status": "In Service",';
    this.devicesArrayHC += '       "State": {';
    this.devicesArrayHC += '         "state": {';
    this.devicesArrayHC += '           "desired": {';
    this.devicesArrayHC += '             "config": {';
    this.devicesArrayHC += '               "dst": 1,';
    this.devicesArrayHC += '               "env": 1,';
    this.devicesArrayHC += '               "voc": 0,';
    this.devicesArrayHC += '               "img": 1,';
    this.devicesArrayHC += '               "lfr": 0,';
    this.devicesArrayHC += '               "gps": 0,';
    this.devicesArrayHC += '               "gtc": 0,';
    this.devicesArrayHC += '               "slp": 3600,';
    this.devicesArrayHC += '               "hit": -35,';
    this.devicesArrayHC += '               "his": 28800';
    this.devicesArrayHC += '             }';
    this.devicesArrayHC += '           },';
    this.devicesArrayHC += '           "reported": {';
    this.devicesArrayHC += '             "dat": {';
    this.devicesArrayHC += '               "fdv": "0.1.10",';
    this.devicesArrayHC += '               "tmp": 25.58,';
    this.devicesArrayHC += '               "sig": 57,';
    this.devicesArrayHC += '               "vcc": 3.27,';
    this.devicesArrayHC += '               "bat": 5,';
    this.devicesArrayHC += '               "dst": 185,';
    this.devicesArrayHC += '               "gps": [';
    this.devicesArrayHC += '                 45.605999,'; // 45.605999,-73.762554,14
    this.devicesArrayHC += '                 -73.762554,14';
    this.devicesArrayHC += '               ],';
    this.devicesArrayHC += '               "tm0": 29,';
    this.devicesArrayHC += '               "nct": 0,';
    this.devicesArrayHC += '               "img": [';
    this.devicesArrayHC += '                 "00b900b900b900b900b800b800b800b800b800b800b800b8",';
    this.devicesArrayHC += '                 "00ae00ae00ae00ae00ae00ae00ae00ae00ae00db00db00db",';
    this.devicesArrayHC += '                 "00db00db00db00db00db00db00bd00bd00bd00bd00bd00bd",';
    this.devicesArrayHC += '                 "00bd00bd00bd0092009200920092009200920092009200a2",';
    this.devicesArrayHC += '                 "00a200a200a200a200a200a200a200a20154015401540154",';
    this.devicesArrayHC += '                 "0154015401540154015400a800a800a800a800a800a800a8",';
    this.devicesArrayHC += '                 "00a800a800aa00aa00aa00aa00aa00aa00aa00aa00f100f1",';
    this.devicesArrayHC += '                 "00f100f100f100f100f100f1010701070107010701070107",';
    this.devicesArrayHC += '                 "0107010701ae01ae01ae01ae01ae01ae01ae01ae01ae0293",';
    this.devicesArrayHC += '                 "0293029302930293029303ae03ae03ae03ae03ae03ae03ae",';
    this.devicesArrayHC += '                 "03ae03ae02a802a802a802a802a802a802a802a802a802c9",';
    this.devicesArrayHC += '                 "02c902c902c902c903a803a803a803a803a803a803a803a8"';
    this.devicesArrayHC += '               ],';
    this.devicesArrayHC += '               "pre": 97.6,';
    this.devicesArrayHC += '               "tm2": 25.58,';
    this.devicesArrayHC += '               "hum": 77.86001,';
    this.devicesArrayHC += '               "hib": 0';
    this.devicesArrayHC += '             },';
    this.devicesArrayHC += '             "car": {';
    this.devicesArrayHC += '               "phn": "+14384312392",';
    this.devicesArrayHC += '               "imei": "354724644847300",';
    this.devicesArrayHC += '               "fmv": "L0.0.00.00.05.11,A.02.16",';
    this.devicesArrayHC += '               "icc": "89302690100004272107",';
    this.devicesArrayHC += '               "oper": "Bell",';
    this.devicesArrayHC += '               "hwi": "B08-4B4E/11417"';
    this.devicesArrayHC += '             }';
    this.devicesArrayHC += '           },';
    this.devicesArrayHC += '           "delta": {';
    this.devicesArrayHC += '             "config": {';
    this.devicesArrayHC += '               "dst": 1,';
    this.devicesArrayHC += '               "env": 1,';
    this.devicesArrayHC += '               "voc": 0,';
    this.devicesArrayHC += '               "img": 1,';
    this.devicesArrayHC += '               "lfr": 0,';
    this.devicesArrayHC += '               "gps": 0,';
    this.devicesArrayHC += '               "gtc": 0,';
    this.devicesArrayHC += '               "slp": 3600,';
    this.devicesArrayHC += '               "hit": -35,';
    this.devicesArrayHC += '               "his": 28800';
    this.devicesArrayHC += '             }';
    this.devicesArrayHC += '           }';
    this.devicesArrayHC += '         },';
    this.devicesArrayHC += '         "metadata": {';
    this.devicesArrayHC += '           "desired": {';
    this.devicesArrayHC += '             "config": {';
    this.devicesArrayHC += '               "dst": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "env": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "voc": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "img": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "lfr": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "gps": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "gtc": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "slp": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "hit": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "his": {';
    this.devicesArrayHC += '                 "timestamp": 1643203609';
    this.devicesArrayHC += '               }';
    this.devicesArrayHC += '             }';
    this.devicesArrayHC += '           },';
    this.devicesArrayHC += '           "reported": {';
    this.devicesArrayHC += '             "dat": {';
    this.devicesArrayHC += '               "fdv": {';
    this.devicesArrayHC += '                 "timestamp": 1684393096';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "tmp": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "sig": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "vcc": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "bat": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "dst": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "gps": [{';
    this.devicesArrayHC += '                   "timestamp": 1642802890';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1642802890';
    this.devicesArrayHC += '                 }';
    this.devicesArrayHC += '               ],';
    this.devicesArrayHC += '               "tm0": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "nct": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "img": [{';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 },';
    this.devicesArrayHC += '                 {';
    this.devicesArrayHC += '                   "timestamp": 1661811164';
    this.devicesArrayHC += '                 }';
    this.devicesArrayHC += '               ],';
    this.devicesArrayHC += '               "pre": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "tm2": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "hum": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "hib": {';
    this.devicesArrayHC += '                 "timestamp": 1661811164';
    this.devicesArrayHC += '               }';
    this.devicesArrayHC += '             },';
    this.devicesArrayHC += '             "car": {';
    this.devicesArrayHC += '               "phn": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "imei": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "fmv": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "icc": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "oper": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               },';
    this.devicesArrayHC += '               "hwi": {';
    this.devicesArrayHC += '                 "timestamp": 1642802890';
    this.devicesArrayHC += '               }';
    this.devicesArrayHC += '             }';
    this.devicesArrayHC += '           }';
    this.devicesArrayHC += '         },';
    this.devicesArrayHC += '         "version": 4732,';
    this.devicesArrayHC += '         "timestamp": 1690307088';
    this.devicesArrayHC += '       }';
    this.devicesArrayHC += '     }';
    this.devicesArrayHC += '   ]';
    return this.devicesArrayHC;
  }
}





    // testDevice.DeviceID = deviceData[1].DeviceID;
    // testDevice.Model = deviceData[1].Model;
    // testDevice.Status = deviceData[1].Status;
    // testDevice.State = deviceData[1].State;
    // testDevice.Gps = deviceData[1].State.state.reported.dat.gps;
    // let timestampTest = deviceData[1].State.metadata.reported.dat.fdv.timestamp * 1000;
    // let date = new Date(timestampTest).toLocaleString();
    // testDevice.Date = date;
    // devicesArray.push(testDevice);
