export const WorkOrderStatus = [
  { "value" : "C", "label" : "_complete"},
  { "value" : "I", "label" : "_in-progress"},
  { "value" : "P", "label" : "_paused"},
  { "value" : "X", "label" : "_cancelled"},
  { "value" : "E", "label" : "_error"},
  { "value" : "D", "label" : "_delayed"},
  { "value" : "O", "label" : "_open"},
  { "value" : "A", "label" : "_assigned"},
];
