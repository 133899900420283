import { Component, OnInit } from '@angular/core';
import { CognitoService } from '../service/cognito.service';
import { ActivatedRoute } from '@angular/router';
import { DistributorsService } from '../service/distributors.service';

@Component({
  selector: 'app-bin-dashboard',
  templateUrl: './bin-dashboard.component.html',
  styleUrl: './bin-dashboard.component.css'
})
export class BinDashboardComponent implements OnInit{

  constructor(public cognitoService: CognitoService,){

  }

  async ngOnInit() {
    await this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();
  }
}
