import { map, catchError, count, ConnectableObservable } from 'rxjs';
import { Component, Inject, forwardRef, HostListener, ViewChild, ElementRef, OnInit, AfterViewChecked, Renderer2} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule} from '@angular/common';
import { DashboardService } from '../service/dashboard.service';
import { ThemeService } from '../service/theme.service';
import { IotService } from '../service/iot.service';
import { ChartService } from '../service/chart.service';
import { CognitoService } from '../service/cognito.service';
import { ReportsService, Bin } from '../service/reports.service';
import { BinsService } from '../service/bins.service';
import { TranslateService } from '@ngx-translate/core';
import { error } from 'jquery';
import { BinUsage } from '../constants/bin-usage';
import { BinArrayItem } from '../service/dashboard.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { CsvService } from '../service/csv.service';
import { forEach } from 'jszip';
import { SystemMessageService } from '../service/system-message.service';



interface collection {
  name: string,
  value: number,
}
interface BinData {
  bin_id: string;
  bin_location: string;
  thing_name: string;
  bin_usage: string;
  bin_address: string;
  legal_name: string;
  market_segment: string;
  threshold: number;
  bin_height: number;
  total_volume: number;
}

@Component({
  selector: 'app-device-statistics',
  templateUrl: './device-statistics.component.html',
  styleUrls: [
    './device-statistics.component.css',
    '../../charts.css',
    '../../global-elements.css',
  ],
})
export class DeviceStatisticsComponent implements OnInit, AfterViewChecked {
  constructor(
    public theme: ThemeService,
    public systemMessage: SystemMessageService,
    public dashboard: DashboardService,
    public devices: IotService,
    public charts: ChartService,
    public cognitoService: CognitoService,
    public reports: ReportsService,
    public bins: BinsService,
    public iot: IotService,
    @Inject(forwardRef(() => TranslateService))
    @Inject(forwardRef(() => TranslateService))
    private translate: TranslateService,
    public http: HttpClient,
    private route: ActivatedRoute,
    public router: Router,
    public csv: CsvService,
    private renderer:Renderer2
   
  ) { this.translate.use(localStorage.getItem('language') || 'fr'); }
  public series: any = [];
  public color: string = 'black';
  public binData: Bin = {
    above_ground: 0,
    active: 0,
    bin_address: "",
    bin_depth: 0,
    bin_height: 0,
    bin_id: "",
    bin_location: "",
    bin_model_number: "",
    bin_postal_code: "",
    bin_shape: "",
    bin_usage: "",
    bin_width: 0,
    client_id: "",
    distributor_id: "",
    thing_name: "",
    bin_volume: 0
  };
  public loading: boolean = true;
  public maxDaysWithNoCollections: number = 0;
  public minDaysWithNoCollections: number = 0;
  isSmallScreenWidth: boolean = this.getScreenWidth() <= 859;
  public cubicMetersOfWasteCollected: number = 0;
  public relationships: BinArrayItem[] = [];
  public bat: string = '';
  public timestamp: number = 0;
  public totalCollections: number = 0;
  public collections: collection[] = [];
  public statistic = "Collections";
  public startDate = this.reports.getDateThirtyDaysAgo();
  public endDate = this.reports.getCurrentDate();
  public thing = "";
  public binThreshold = '';
  public totalWasteCollected = 0;
  public tonnage = 0;
  public returnedResult = true;
  @ViewChild('details') detailsElement!: ElementRef;
  async ngOnInit() {
    const thingName = this.route.snapshot.params['thing'];
    this.thing = thingName;
    const statistic = this.route.snapshot.params['report']; //Unimplemented
    const start = this.route.snapshot.params['start'];
    const end = this.route.snapshot.params['end'];
    

    if (start !== undefined && (new Date(start) < new Date(this.endDate) || new Date(start) < new Date(end))) {
      this.startDate = start;
    }

    if (end !== undefined && new Date(end) > new Date(this.startDate)) {
      this.endDate = end;
    }

    //Fetch Array of device relationships
    (await this.getRelationships()).subscribe();
    (await this.getRelationships()).subscribe();

    //Get Bin Data for a device
    (await this.reports.getBinByDevice(thingName)).subscribe((response) => {
     
      for (const row of response) {

        //Set Bin Data object for device
        this.binData.above_ground = row.above_ground;
        this.binData.active = row.active;
        this.binData.bin_address = row.bin_address;
        this.binData.bin_depth = row.bin_depth;
        this.binData.bin_height = row.bin_height;
        this.binData.bin_width = row.bin_width;
        this.binData.bin_shape = row.bin_shape;
        this.binData.bin_id = row.bin_id;
        this.binData.bin_location = row.bin_location;
        this.binData.bin_model_number = row.bin_model_number;
        this.binData.bin_postal_code = row.bin_postal_code;
        this.binData.bin_usage = row.bin_usage;
        this.binData.bin_volume = row.total_volume;
      }
    });

    //Get Historical Data for a device
    (await this.reports.getHistory(thingName)).subscribe((response: any) => {
      for (const row of response) {
        
        this.reports.historyArray.push(row);
      }
    
      this.initLineChart();
      this.initCollections();
    });
    //Artificial Loading Screen delay
    setTimeout(() => {
      this.loading = false;
      this.reports.showGraphs();
    }, 2000)


    //Get current screen width on init
    const currentScreenWidth = this.getScreenWidth();
    const currentScreenHeight = this.getScreenHeight();


    // Check the screen width and update the isSmallScreen flag accordingly
    this.isSmallScreenWidth = currentScreenWidth <= 859;

    //Adjust chart parameters for view size
    this.charts.adjustView(currentScreenWidth, currentScreenHeight);
  }
  ngAfterViewChecked(){
      this.toggleDisableClass(this.detailsElement);
  }

  filterHighestDstTimestamp(array: any[]): any {
    const yearStart = new Date(this.startDate);
    const yearEnd = new Date(this.endDate);

    // Filter the array for rows within the year 2023
    const filteredArray = array.filter(row => new Date(row.dst_timestamp * 1000) >= yearStart && new Date(row.dst_timestamp * 1000) < yearEnd);

    // Check if there are rows within the year 2023
    if (filteredArray.length === 0) {
      return null; // No rows within the year 2023
    }

    // Find the row with the highest dst_timestamp
    const highestTimestampRow = filteredArray.reduce((maxRow, currentRow) =>
      currentRow.dst_timestamp > maxRow.dst_timestamp ? currentRow : maxRow
    );

    return highestTimestampRow;
  }

  //Initialize data for Line Chart and Required Parameters for device health
  async initLineChart() {
    //Get Translated Label for Line Chart
    const yAxisLabel = await this.charts.getChartLabel('yAxisLineChartLabel');

    //Set Line Chart Default dimensions
    this.charts.viewLineChartAdmin = [600, 450]

    //Get Last Row of History array (Most Recent Shadow Data Entry)
    const lastRow = this.reports.historyArray.length - 1;

    //Set Class Parameters for Device Health Check
    this.bat = this.reports.historyArray[lastRow].bat;
    this.timestamp = this.reports.historyArray[lastRow].dst_timestamp;

    const start = new Date(this.startDate);
    start.setUTCHours(5, 0, 0, 0);
    const end = new Date(this.endDate);
    end.setUTCHours(5, 0, 0, 0);

    //X Axis Label
    this.charts.xAxisLabelLineChartAdmin = `${start.toLocaleDateString(
      this.charts.getDateLanguage(),
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
    )} - ${end.toLocaleDateString(this.charts.getDateLanguage(), {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })}`;

    for (let row of this.reports.historyArray) {
      //Format Timestamps
      const date = this.formatDate(row.dst_timestamp);
      if (this.isDateIn(date)) {


        //Format Timestamps
        const formattedDate = date.toLocaleDateString(
          this.charts.getDateLanguage(),
          {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }
        );

        //Line Chart Row Data
        this.series.push({
          name: formattedDate,
          value: row.dst / this.binData.bin_height * 100,
        });
      }
    }

    //Compile Line Chart Data
    this.charts.singleLineChart = [
      {
        name: this.reports.historyArray[0].thing_name,
        series: this.series,
      },
    ];

    //Set Y Axis Label
    this.charts.yAxisLabelLineChartAdmin = yAxisLabel;
  }
  dateToUnixTimestamp(dateString: string): number {
    // Parse the date string into a Date object
    const date = new Date(dateString + 'T00:00:00');

    // Get the UTC timestamp in milliseconds
    const timestampInMilliseconds = date.getTime();


    return Math.floor(timestampInMilliseconds / 1000);
  }

  //Initialize Data for both Bar Charts
  async initCollections() {
    //Declare function variables
    let collectionCount = 0,//to count collection for the month
      totalCollections = 0,//total collection count
      barChartRow: any[] = [], // array for monthly collects
      noCollectionCountArray: any[] = [],//to save days with no collection for graphs
      noCollectionsCount: number[] = [],//to saves days with no collection for min/max
      currentRow,//to save current row
      previousRow, //to save previous row
      currentDate: Date,//to save current date
      formattedDate;//to save formatted current date



    //Set Color Pallet for Horizontal Bar Chart
    this.charts.colorSchemeBarChartAdmin.domain = [
      '#1f77b4',
      '#ff7f0e',
      '#2ca02c',
      '#d62728',
      '#9467bd',
      '#8c564b',
      '#e377c2',
      '#7f7f7f',
      '#bcbd22',
      '#17becf',
      '#ff9896',
      '#aec7e8',
    ];

    this.charts.xAxisLabelBarChartAdmin = await this.charts.getChartLabel(
      'xAxisCollectionBarChartLabel'
    );
    this.charts.showXAxisLabelBarChartAdmin = false;
    this.charts.showLegendBarChartAdmin = false;
    this.charts.viewBarChartAdmin = [600, 450];

    //Initialize values for iteration

    let daysWithNoCollectionCount = 0;


    let start = this.dateToUnixTimestamp(this.startDate),
      end = this.dateToUnixTimestamp(this.endDate);

    (await this.reports.getReportData([this.thing], start, end)).subscribe({
      next: (res: any) => {

        const collectionData = JSON.parse(res.collections); // get the thing collection data
        if (!(collectionData.length > 0)) {//if collection data empty
          this.returnedResult = false;//show different UI for no results
          return;//exits function
        }
        ////////////// Monthly collection chart ///////////////////////
        for (let index = 0; index < collectionData.length; index++) {//for each collection in the collection data set
          currentRow = collectionData[index];//get the current collection
          previousRow = index > 0 ? collectionData[index - 1] : null;//get the previous collecton if it exixts

          currentDate = new Date(currentRow.preceding_timestamp * 1000);//get the date of the current collection

          formattedDate = currentDate.toLocaleDateString('en-CA', {//formats the current date month/year
            year: 'numeric',
            month: 'short',
          });

          if (currentRow) collectionCount++, totalCollections++;// Increment collectionCount and totalCollection for each row
          if (previousRow) {//if previous row exixts
            const previousDate = new Date(previousRow.preceding_timestamp * 1000);//get previous date
            if (!this.isInSameMonth(currentDate, previousDate)) {//if current and previous dates are not in the same month
              barChartRow.push({ name: formattedDate, value: collectionCount });// Push the data into the array with the current collectionCount
              collectionCount = 0; // Reset collectionCount for the new month
            } else if (this.isInSameMonth(currentDate, previousDate)) {//if current and previous dates are in the same month
              collectionCount++;//increment the count
              barChartRow.push({ name: formattedDate, value: collectionCount });// Push the data into the array with the current collectionCount
            }

            //////////// days with no collection////////////
            daysWithNoCollectionCount = daysWithNoCollectionCount + this.calculateDaysBetweenDates(previousDate, currentDate);//calculate days with no collection
            noCollectionsCount.push(daysWithNoCollectionCount);//add days with no collection for min/max values
            noCollectionCountArray.push({ name: totalCollections, value: daysWithNoCollectionCount });//push data to array for graphs
            daysWithNoCollectionCount = 0;//reset count for next itteration
            //////////// days with no collection////////////

          } else {
            // Push the first entry into the array
            barChartRow.push({ name: formattedDate, value: collectionCount });
          }

          ////////////////// bin capacity per collection ////////////////////////////////////
          const collectedQuantity = Number(((this.binData.bin_height - currentRow.preceding_dst) / this.binData.bin_height * 100).toFixed(2));//calculate collected volume

          this.collections.push({
            name: currentDate.toLocaleDateString(this.charts.getDateLanguage(), {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }),
            value: collectedQuantity
          });//push data to array for graphs
          ////////////////// bin capacity per collection ////////////////////////////////////
        }
        ////////// total waste collected calculation //////////////////////

        const cubicMetersOfWaste = (this.calculateCubicMeters(this.binData.bin_volume, JSON.parse(res.counts)[0].avg_preceding_dst, this.binData.bin_height) * this.collections.length);
         this.tonnage = this.calculateWasteCollection(this.binData.bin_usage, cubicMetersOfWaste) * 0.001;
      
        ////////// total waste collected calculation //////////////////////



        const counts: { [key: string]: number } = {};// to store counts for each month
        barChartRow.forEach(item => {//for each item in barChartRow
          const name = item.name;//get the name
          counts[name] = (counts[name] || 0) + 1; //increment count by 1 for matching month or initalize it to 0 if it doesn't exist
        })
        const filteredArray = Object.keys(counts).map(name => ({ name, value: counts[name] }));//new array of object with unique month and their count

        const missingMonths = this.findMissingMonths(start, end, collectionData);// get the month(s) missing from collection data set

        for (let monthName of missingMonths) {//for each month in the missing months array
          filteredArray.push({ name: monthName, value: 0 })//add the months with value of 0
        }
        // Sort the filteredArray array chronologically
        filteredArray.sort((a, b) => {
          const dateA = new Date(a.name);
          const dateB = new Date(b.name);
          return dateA.getTime() - dateB.getTime();
        });
        //translate month name to the current language for graphics
        for (let monthData of filteredArray) {
          monthData.name = new Date(monthData.name).toLocaleDateString(this.charts.getDateLanguage(), {
            year: 'numeric',
            month: 'short',
          })
        }
        barChartRow = filteredArray;//updates monthly collection chart array with correct data
       
        this.charts.singleBarChartAdmin = barChartRow; // Set monthly collection chart
        this.charts.singleBarChartCollections = this.collections; // set capacity per collection chart
        this.charts.singleBarChart = noCollectionCountArray; // set days without collection chart
        this.charts.singleBarChartAdmin = barChartRow; // Set monthly collection chart
        this.charts.singleBarChartCollections = this.collections; // set capacity per collection chart
        this.totalCollections = totalCollections;//set the total number of collections
        this.totalWasteCollected = Number(this.tonnage.toFixed(2));//set the total waste collected
        this.maxDaysWithNoCollections = Math.max(...noCollectionsCount);//set maximum days without collection
        this.minDaysWithNoCollections = Math.min(...noCollectionsCount);//set minimum days without collection
        this.binThreshold = `${JSON.parse(res.bins)[0].threshold}%`;//set threshold alert level

      },
      error: (error: any) => {
        console.error('error retrieving data: ', error)
      }
    })
    this.charts.showLegendBarChart = false;
    this.charts.viewBarChart = [600, 450];
    this.charts.viewBarChartCollections = [600, 450];
    this.charts.viewBarChart = [600, 450];
    this.charts.viewBarChartCollections = [600, 450];
    this.charts.showLegendBarChartCollections = false;
    this.charts.showXAxisBarChartCollections = true;
  }


  //function that returns missing months from a collection of report Data
  findMissingMonths(start: number, end: number, collectionData: any[]): string[] {

    const missingMonths = [], //to save missing months
      monthsMap: { [key: string]: boolean } = {}; //map to save the months witch collection(s)


    for (const collection of collectionData) {// Iterate through a collection data set to mark months with collections
      const date = new Date(collection.preceding_timestamp * 1000);//create date with the timestamp
      const yearMonth = date.toLocaleDateString('en-CA', {//formats the date month and year
        year: 'numeric',
        month: 'short',
      });
      monthsMap[yearMonth] = true;//mark the month as founda
    }
    const monthsArray = Object.entries(monthsMap);//makes an array of the map

    monthsArray.sort((a, b) => {//sorts the array chronologicaly
      const dateA = new Date(a[0]);
      const dateB = new Date(b[0]);
      return dateA.getTime() - dateB.getTime();
    });
    const sortedMonthsMap = new Map(monthsArray); // Convert the sorted array back into a map
  
    let startDate = new Date(start * 1000);//start date
    let endDate = new Date(end * 1000);//end date
    
    while (startDate <= endDate) {//itterates through the time interval
      const yearMonth = startDate.toLocaleDateString('en-CA', {//formats the date
        year: 'numeric',
        month: 'short',
      });

      if (!(sortedMonthsMap.get(yearMonth))) {// If the month is not found in the sorted months map
        missingMonths.push(yearMonth);//add it to the missingMonths array
      }
      startDate.setDate(1);// set day the first to get all months
      startDate.setMonth(startDate.getMonth() + 1);// Move to the next month
    }
    return missingMonths;//returns the array of missing months
  }

  fillRate(fillRate: number) {

    return (fillRate > 20);
  }

  calculateDaysBetweenDates(startDate: Date, endDate: Date): number {
    // Convert both dates to UTC to ensure consistent calculations
    const utcStartDate = Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    const utcEndDate = Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );

    // Calculate the difference in milliseconds
    const millisecondsPerDay = 1000 * 60 * 60 * 24; // Number of milliseconds in a day
    const differenceInMilliseconds = Math.abs(utcEndDate - utcStartDate);

    // Convert the difference to days
    const daysDifference = Math.floor(
      differenceInMilliseconds / millisecondsPerDay
    );
    return daysDifference;
  }

  //Check if 2 rows are within the same month
  isInSameMonth(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth()
    );
  }

  isDateIn(date: Date): boolean {
    const start = new Date(this.startDate);
    const end = new Date(this.endDate);

    // Check if the date is after or equal to the start of 2023
    // and before the start of 2024
    return date >= start && date <= end;
  }

  //Convert UTC Unix timestamp(H) to local date-time string
  formatDate(date: number) {
    const formattedDate = new Date(date * 1000);
    return formattedDate;
  }

  // Get the pourcentage and put the color(red, yellow or green) for the batery of the selected device
  getBatteryPercentage(battery: number) {

    let percentage = 0;
    let inCharge = false;
    // Will put this.pourcentage at zero if the pourcentage received is null or not a number
    if ((battery !== 0 || battery !== (null) || battery !== undefined)) {
      // Convert the max voltage of the batery(3.7V) in pourcentage 0% to 100%
      percentage = Math.round((battery / 3.7) * 100);
    } else {
      percentage = 0;
    }
    // If the batery is more then 3.7V it's because she is in charging mode
    if (battery > 3.7) {
      percentage = 100;
      inCharge = true;
    }

    const element = document.getElementById('battery');
    // Put the good color for the text depend on the pourcentage of the battery
    switch (true) {
      case percentage >= 0 && percentage <= 30:
        this.color = 'red';
        break;

      case percentage >= 31 && percentage <= 60:
        this.color = 'yellow';
        break;

      case percentage >= 61:
        this.color = 'green';
        break;

      default:
        this.color = 'red';
        break;
    }
    if (element) { element.style.color = this.color }
    return percentage;
  }

  // Function that will calculate the volume if all field are well filled
  calculateVolume(shape: string, width: number, height: number, depth: number) {

    // Calculate the volume of a rectangular box in liters
    if (shape === 'S' && width > 0 && height > 0 && depth > 0) {
      const volumeCubicMM = width * height * depth;
      const volumeLiters = (volumeCubicMM / 1000000).toFixed(2);

      return volumeLiters + ' L';
    }

    // Calculate the volume of a cylinder in liters
    if (shape === 'C' && width > 0 && height > 0) {
      const radius = width / 2;
      const volumeCubicMM = Math.PI * Math.pow(radius, 2) * height;
      const volumeLiters = (volumeCubicMM / 1000000).toFixed(2);

      return volumeLiters + ' L';
    }

    return '';
  }

  // Function that will calculate the volume if all field are well filled
  calculateCubicMeters(binVolume: number, dst: number, binHeight: number) {
    const multiplier = (1 - (dst / binHeight));
    return binVolume / 1000 * multiplier;
  }

  //Calculate the total waste collected
  calculateWasteCollection(usage: string, totalCubicMetersOfWaste: number): number {
    const weightPerCubicMeter = this.getWeightByValue(usage);
    if (!isNaN(totalCubicMetersOfWaste) && weightPerCubicMeter !== undefined) {
      return totalCubicMetersOfWaste * weightPerCubicMeter;
    }
    else {
      return 0;
    }
  }

  //Get the average weight for the bin usage type
  getWeightByValue(value: string): number {
    const bin = BinUsage.find((bin) => bin.value === value);
    return bin?.weight || 0;
  }

  //Displays the correct label for the Device Health row in the Device Information element
  displayHealth(thingName: string, timestamp: number, bat: string) {

    //Check Health Statistics
    const lastUpdate = new Date(timestamp * 1000);
    const battery: number = (parseFloat(bat) / 3.7) * 100;
    switch (true) {
      case (this.isDateGapGreaterThan48Hours(lastUpdate) && this.isBinIdPopulated(thingName, this.relationships)) && battery < 40:
        return 'Unhealthy'
      case !this.isDateGapGreaterThan48Hours(lastUpdate) && this.isBinIdPopulated(thingName, this.relationships) && battery > 40:
        return 'Healthy'
      default:
        return 'Inactive';
    }
  }

  //Return Label
  translateStatus(status: number) {
    if (status === 1) {
      return 'active'
    }
    else {
      return 'inactive'
    }
  }

  isDateGapGreaterThan48Hours(dateToCompare: Date): boolean {
    // Get the current date and time
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate.getTime() - dateToCompare.getTime();

    // Convert the time difference to hours
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    // Check if the gap is greater than 48 hours
    return hoursDifference > 48;
  }

  isBinIdPopulated(thingName: string, array: BinArrayItem[]): boolean {
    const matchingItem = array.find((item) => item.thing_name === thingName);

    return !!matchingItem && !!matchingItem.bin_id;
  }

  async getRelationships() {
    const url = environment.api.stage + environment.api.route.getDeviceRelationships;

    return this.http.get(url).pipe(
      map((response) => {
        if (response) {
          for (const row of JSON.parse(JSON.stringify(response))) {
            this.relationships.push({ thing_name: row.thing_name, bin_id: row.bin_id })
          }
        }
      }),
      catchError((error) => {
        console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      })
    );
  }

  //Apply filter preferences
  applyFilter() {
    window.location.href = `/device-statistics/${this.thing}/${this.startDate}/${this.endDate}`;
  }

  async exportCSVReports(thingName: string, option:number) {

    const binUsage = await this.charts.getChartLabel(this.bins.getBinUsage(this.binData.bin_usage));
    const battery = this.getBatteryPercentage(this.reports.historyArray[this.reports.historyArray.length - 1].bat).toString() + '%';
    const health = this.displayHealth(this.reports.historyArray[0].thing_name, this.timestamp, this.bat)
    const translatedHealth = await this.charts.getChartLabel(health);
    const deviceReport: any = [];
    const collectionReport: any = [];
    const collectionsArray: any = [];
    const deviceInformation: any = [];
    const startDate = this.formatDateISO8601(this.startDate)
    const endDate = this.formatDateISO8601(this.endDate)

    switch (this.charts.getDateLanguage()) {

      case "en-CA":
        deviceInformation.push({
          "Device Name": this.thing,
          "Alert Threshold": this.binThreshold,
          "Start Date": startDate,
          "End Date": endDate,
          "Bin Height": this.binData.bin_height,
          "Bin Volume": this.binData.bin_volume,
          "Device Health": translatedHealth,
          "Battery": battery,
          "Usage": binUsage,
          "Collected Waste (tonnes)": this.tonnage.toFixed(2),
          "Collections": this.totalCollections,
        });




        for (const row of this.collections) {
          collectionsArray.push({
            "Device": thingName,
            "Collection Date": row.name,
            "Percentage Filled": row.value,
            "Location": this.binData.bin_address
          })
        }

        break;
      case "es-MX":
        deviceInformation.push({
          "Dispositivo": this.thing,
          "Umbral de alerta": this.binThreshold,
          "Fecha de inicio": startDate,
          "Fecha final": endDate,
          "Altura del contenedor": this.binData.bin_height,
          "Volumen del contenedor": this.binData.bin_volume,
          "salud del dispositivo": translatedHealth,
          "Batería": battery,
          "Uso": binUsage,
          "Toneladas recolectadas": this.tonnage.toFixed(2),
          "Colecciones": this.totalCollections,
        })



        for (const row of this.collections) {
          collectionsArray.push({
            "Dispositivo": thingName,
            "Fecha de colecció": row.name,
            "Porcentaje llenado": row.value,
            "Ubicación": this.binData.bin_address
          })
        }
        break;
      default:
        deviceInformation.push({
          "Nom du dispositif": this.thing,
          "Seuil d'alerte": this.binThreshold,
          "Date de début": startDate,
          "Date de fin": endDate,
          "Hauteur de la benne (mm)": this.binData.bin_height,
          "Volume de la benne": this.binData.bin_volume,
          "État de l'appareil": translatedHealth,
          "Batterie": battery,
          "Type d'utilisation": binUsage,
          "Déchets collectés (tonnes)": this.tonnage.toFixed(2),
          "collections totales": this.totalCollections,
        })

        for (const row of this.collections) {
          collectionsArray.push({
            "Dispositif": thingName,
            "Date de collections": row.name,
            "Pourcentage rempli": row.value,
            "Emplacement": this.binData.bin_address.toString()
          })
        }
        break;
    }

    const historyArray = this.reports.historyArray;

    for (const row of historyArray) {
      const date = new Date(row.dst_timestamp * 1000);
      if (row.timestamp.toString().length === 10) {
        row.timestamp = new Date(row.timestamp * 1000)
      }
      else {
        row.timestamp = new Date(parseInt(row.timestamp));
      }

      if (this.isDateIn(date)) {
        row.img = "N/A";
        row.fdv_timestamp = new Date(row.fdv_timestamp * 1000);
        row.img_timestamp = new Date(row.img_timestamp * 1000);
        row.tmp_timestamp = new Date(row.tmp_timestamp * 1000);
        row.vcc_timestamp = new Date(row.vcc_timestamp * 1000);
        row.sig_timestamp = new Date(row.sig_timestamp * 1000);
        row.pre_timestamp = new Date(row.pre_timestamp * 1000);
        row.bat_timestamp = new Date(row.bat_timestamp * 1000);
        row.tm1_timestamp = new Date(row.tm1_timestamp * 1000);
        row.tm2_timestamp = new Date(row.tm2_timestamp * 1000);
        row.dst_timestamp = new Date(row.dst_timestamp * 1000);
        row.tm0_timestamp = new Date(row.tm0_timestamp * 1000);
        row.nct_timestamp = new Date(row.nct_timestamp * 1000);
        row.lfr_timestamp = new Date(row.lfr_timestamp * 1000);
        row.hum_timestamp = new Date(row.hum_timestamp * 1000);
        row.voc_timestamp = new Date(row.voc_timestamp * 1000);
        row.hib_timestamp = new Date(row.hib_timestamp * 1000);

        deviceReport.push(row);
      }
    }
    switch(option){
      case 1://history report
        this.csv.exportToCsv(deviceReport, `${thingName}-History-${this.startDate.replaceAll("-", "")}-${this.endDate.replaceAll("-", "")}.csv`)
        break;
      case 2://bin report
        this.csv.exportToCsv(deviceInformation, `${thingName}-bin-report-${this.startDate.replaceAll("-", "")}-${this.endDate.replaceAll("-", "")}.csv`)
        break;
      case 3://collection report
        this.systemMessage.selectRibbon('info', 'openCSV');
        setTimeout(()=>{this.csv.exportToCsv(collectionsArray, `${thingName}-collections-report-${this.startDate.replaceAll("-", "")}-${this.endDate.replaceAll("-", "")}.csv`)},3000)
        break;
    }
  }

  //Unused
  printElementContent() {
    const element = document.getElementById('device-statistics');

    if (element) {
      const content = element.outerHTML;
      const printWindow = window.open('', '_blank');

      if (printWindow) {
        printWindow.document.write(content);
        printWindow.document.close();
        printWindow.print();
      } else {
        console.error('Failed to open print window');
      }
    } else {
      console.error(`Element with ID device-statistics not found`);
    }
  }

  // Event listener for window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {

    const currentScreenWidth = this.getScreenWidth();
    const currentScreenHeight = this.getScreenHeight();
    // Check the screen width and update the isSmallScreen flag accordingly
    this.isSmallScreenWidth = currentScreenWidth <= 859;

    //Adjust chart parameters for view size
    this.adjustView(currentScreenWidth, currentScreenHeight);
    //Adjust chart parameters for view size
    this.adjustView(currentScreenWidth, currentScreenHeight);
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  // Funciton to get the current screen height
  getScreenHeight(): number {
    // Retunr the current screen height of the window
    return window.innerHeight;
  }

  //UI Functions
  adjustView(currentScreenWidth: number, currentScreenHeight: number) {
    const widthPercentage = currentScreenWidth / 100;
    const heightPercentage = currentScreenHeight / 100;


    switch (true) {
      case (currentScreenWidth >= 1400):
        this.charts.viewLineChartAdmin = [600, 450];
        this.charts.viewBarChart = [600, 450];
        this.charts.viewBarChartCollections = [600, 450];
        this.charts.viewBarChartAdmin = [600, 450];
        break;
      case currentScreenWidth >= 1200 && currentScreenWidth < 1400:
        this.charts.viewLineChartAdmin = [1000, 550];
        this.charts.viewBarChart = [
          widthPercentage * 52,
          heightPercentage * 40,
        ];
        this.charts.viewBarChartCollections = [
          widthPercentage * 52,
          heightPercentage * 40,
        ];
        this.charts.viewBarChartAdmin = [
          widthPercentage * 52,
          heightPercentage * 40,
        ];
        break;
      case currentScreenWidth > 915 && currentScreenWidth < 1200:
        this.charts.viewLineChartAdmin = [
          widthPercentage * 52,
          heightPercentage * 40,
        ];
        this.charts.viewBarChart = [
          widthPercentage * 52,
          heightPercentage * 40,
        ];
        this.charts.viewBarChartCollections = [
          widthPercentage * 52,
          heightPercentage * 40,
        ];
        this.charts.viewBarChartAdmin = [
          widthPercentage * 52,
          heightPercentage * 40,
        ];
        break;
      case currentScreenWidth <= 915 && currentScreenWidth > 800:
        this.charts.viewLineChartAdmin = [
          widthPercentage * 60,
          heightPercentage * 60,
        ];
        this.charts.viewBarChart = [
          widthPercentage * 60,
          heightPercentage * 60,
        ];
        this.charts.viewBarChartCollections = [
          widthPercentage * 60,
          heightPercentage * 60,
        ];
        this.charts.viewBarChartAdmin = [
          widthPercentage * 60,
          heightPercentage * 60,
        ];
        break;
      case currentScreenWidth <= 800 && currentScreenWidth > 575:
        this.charts.viewLineChartAdmin = [
          widthPercentage * 60,
          heightPercentage * 50,
        ];
        this.charts.viewBarChart = [
          widthPercentage * 60,
          heightPercentage * 50,
        ];
        this.charts.viewBarChartCollections = [
          widthPercentage * 60,
          heightPercentage * 50,
        ];
        this.charts.viewBarChartAdmin = [
          widthPercentage * 60,
          heightPercentage * 50,
        ];
        break;
      case (currentScreenWidth <= 700) && (currentScreenWidth > 600):
        this.charts.viewLineChartAdmin = [widthPercentage * 60, heightPercentage * 50];
        this.charts.viewBarChart = [widthPercentage * 60, heightPercentage * 50];
        this.charts.viewBarChartCollections = [widthPercentage * 60, heightPercentage * 50];
        this.charts.viewBarChartAdmin = [widthPercentage * 60, heightPercentage * 50];
        break;
      case (currentScreenWidth <= 600) && (currentScreenWidth > 500):
        this.charts.viewLineChartAdmin = [400, 200];
        this.charts.viewBarChart = [
          widthPercentage * 62,
          heightPercentage * 42,
        ];
        this.charts.viewBarChartCollections = [
          widthPercentage * 62,
          heightPercentage * 42,
        ];
        this.charts.viewBarChartAdmin = [
          widthPercentage * 60,
          heightPercentage * 40,
        ];
        break;
      case currentScreenWidth <= 500:
        this.charts.viewLineChartAdmin = [
          widthPercentage * 60,
          heightPercentage * 30,
        ];
        this.charts.viewBarChart = [
          widthPercentage * 60,
          heightPercentage * 30,
        ];
        this.charts.viewBarChartCollections = [
          widthPercentage * 60,
          heightPercentage * 30,
        ];
        this.charts.viewBarChartAdmin = [
          widthPercentage * 60,
          heightPercentage * 30,
        ];
        break;
    }
  }
  //function that check if a number is finite or not
  isFiniteNumber(value: number): boolean {
    return Number.isFinite(value);
  }
  //function that formats a date to ISO8601
  formatDateISO8601(date:string): string {
    const dateToFormat = new Date(date);
    const year = dateToFormat.getFullYear();
    const month = String(dateToFormat.getMonth() + 1).padStart(2, '0');
    const day = String(dateToFormat.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  //function that toggles the disabled class
  toggleDisableClass(element:ElementRef){
    const nativeElement = element.nativeElement;//saves the native element
    switch(this.returnedResult){//checks boolean for returned results
      case true: // results returned
        if(nativeElement.classList.contains('disabled')) this.renderer.removeClass(element.nativeElement, 'disabled')//removes the disabled class if its there and result were returned
        break;
      case false://no results returned
        if(!(nativeElement.classList.contains('disabled'))) this.renderer.addClass(element.nativeElement, 'disabled')//adds the disabled class if its not there and result were not returned
        break;
    }
  }
}
