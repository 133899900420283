import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-microsoft-sign-up',
  templateUrl: './microsoft-sign-up.component.html',
  styleUrl: './microsoft-sign-up.component.css'
})
export class MicrosoftSignUpComponent {

}
