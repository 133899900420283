<!-- Modal for displaying device information when 'showDeviceModal' is true -->
<div [ngClass]="theme.getThemeClass(true)" *ngIf="this.modal.showSingleDeviceModal"  class="modal col-10">
    <!-- Modal container for Device list-->
    <div class="hideNavBar"></div>
    <div *ngIf="this.modal.showSingleDeviceModal" class="hideNavBarE"></div>
    <section class="modal col-10" [ngClass]="theme.getThemeClass(true)">
        <ul class="tabs col-12" [ngClass]="theme.getThemeClass(true)" id="headerTab">
            <li [ngClass]="{'active' : isDetailsActive}" class="nav-item nav-link tab col-6" id="tabDetails" (click)="detailsClicked()" ><img src="./assets/details.png" alt="Details" width="25px" height="25px"></li>
            <li [ngClass]="{'active' : isConfigsActive}" class="nav-item nav-link tab col-6" id="tabConfigs" (click)="configsClicked()"><img src="./assets/settings.png" alt="Configurations" width="25px" height="25px"></li>
        </ul>
        <!-- Portion of the code for Details display -->
        <article class="modalContainer col-8" [ngClass]="theme.getThemeClass(true)">
            <ul *ngIf="isDetailsActive">
                <li>
                    <h4 [ngClass]="theme.getThemeClass(true)" class="col-12">{{ device.thingName }}</h4>
                </li>
                    <div class="boxContainerDetails">
                        <li class="detail">
                            <label [ngClass]="theme.getThemeClass(true)" class="secondtitle text-dark">{{ "iotModalViewDeviceTypeName" | translate }}</label>
                            <br>
                            <label>{{ device.thingTypeName }}</label>
                        </li>
                        <li class="detail">
                            <label [ngClass]="theme.getThemeClass(true)" class="secondtitle text-dark">{{ "iotModalViewDeviceLocation" | translate }}</label>
                            <br>
                            <label [style.color]="device.gps ? 'green' : 'red'">
                                {{ device.gps.latitude + ', ' + device.gps.longitude || 'Location Unknown' }}
                            </label>
                        </li>
                        <li class="detail">
                          <label [ngClass]="theme.getThemeClass(true) + ' secondtitle text-dark'">{{ "iotModalViewDeviceTrashBinStatus" | translate }}</label>
                          <br>
                          <label [ngStyle]="getStatusStyle()">{{  this.modal.thingStatus | translate  }}</label>
                        </li>

                        <li class="detail">
                            <label [ngClass]="theme.getThemeClass(true)" class="secondtitle text-dark">{{ "iotModalViewDeviceBattery" | translate }}</label>
                            <br>
                            <label [ngClass]="{ 'red': this.iotService.color === 'red', 'yellow': this.iotService.color === 'yellow', 'green': this.iotService.color === 'green' }">{{ device.dat.bat }} V </label>
                            <label class="labelInCharge" *ngIf="this.iotService.inCharge"> {{ "iotModalViewDeviceBatInCharge" | translate }}</label>
                        </li>
                        <li class="detail">
                            <label [ngClass]="theme.getThemeClass(true)" class="secondtitle text-dark">{{ "iotModalViewFillLevelOfTrashBin" | translate }}</label>
                            <br>
                            <label [ngStyle]="getFillLevelStyle()">{{ this.fillLevel }} </label>
                        </li>
                    </div>
            </ul>
            <!-- Portion of the code for config display -->
            <ul *ngIf="isConfigsActive" [ngClass]="theme.getThemeClass(true)">
                <li><h4 [ngClass]="theme.getThemeClass(true)" class="col-12">{{ device.thingName }}</h4></li>
                <div class="configContainerDisplay">
                    <div class="boxContainerConfigs">
                        <li class="config">
                            <div class="configActivable col-10">
                                <label [ngClass]="theme.getThemeClass(true)" class="configtitle col-8">{{ "iotModalViewDeviceSleepTime" | translate }}</label>
                                <label class="dataConfig col-2">{{ convertSecondsToHours(device.config.slp) }}</label>
                            </div>
                        </li>
                        <li class="config">
                            <div class="configActivable col-10">
                                <label [ngClass]="theme.getThemeClass(true)" class="configtitle col-8">{{ "iotModalViewDeviceHibernation" | translate }}</label>
                                <label class="dataConfig col-2">{{ device.config.hit }} °C</label>
                            </div>
                        </li>
                        <li class="config">
                            <div class="configActivable col-10">
                                <label [ngClass]="theme.getThemeClass(true)" class="configtitle col-8">{{ "iotModalViewDeviceHibernationST" | translate }}</label>
                                <label class="dataConfig col-2">{{ convertSecondsToHours(device.config.his) }}</label>
                            </div>
                        </li>
                        <li class="config">
                            <div class="configActivable col-10">
                                <label [ngClass]="theme.getThemeClass(true)" class="configtitle col-8">{{ "deviceConfigModalDistance" | translate }}</label>
                                <label class="dataConfig col-2">{{ device.config.dst ? 'True' : 'False' }}</label>
                            </div>
                        </li>
                        <li class="config">
                            <div class="configActivable col-10">
                                <label [ngClass]="theme.getThemeClass(true)" class="configtitle col-8">{{ "deviceConfigModalLfr" | translate }}</label>
                                <label class="dataConfig col-2">{{ device.config.lfr ? 'True' : 'False' }}</label>
                            </div>
                        </li>
                        <li class="config">
                            <div class="configActivable col-10">
                                <label [ngClass]="theme.getThemeClass(true)" class="configtitle col-8">{{ "deviceConfigModalEnvironmentalMeter" | translate }}</label>
                                <label class="dataConfig col-2">{{ device.config.env ? 'True' : 'False' }}</label>
                            </div>
                        </li>
                        <li class="config">
                            <div class="configActivable col-10">
                                <label [ngClass]="theme.getThemeClass(true)" class="configtitle col-8">{{ "deviceConfigModalGps" | translate }}</label>
                                <label class="dataConfig col-2">{{ device.config.gps ? 'True' : 'False' }}</label>
                            </div>
                        </li>
                        <li class="config">
                            <div class="configActivable col-10">
                                <label [ngClass]="theme.getThemeClass(true)" class="configtitle col-8">{{ "deviceConfigModalVOC" | translate }}</label>
                                <label class="dataConfig col-2">{{ device.config.voc ? 'True' : 'False' }}</label>
                            </div>
                        </li>
                        <li class="config">
                            <div class="configActivable col-10">
                                <label [ngClass]="theme.getThemeClass(true)" class="configtitle col-8">{{ "deviceConfigModalGtc" | translate }}</label>
                                <label class="dataConfig col-2">{{ device.config.gtc ? 'True' : 'False' }}</label>
                            </div>
                        </li>
                        <li class="config">
                            <div class="configActivable col-10">
                                <label [ngClass]="theme.getThemeClass(true)" class="configtitle col-8">{{ "deviceConfigModalImg" | translate }}</label>
                                <label class="dataConfig col-2">{{ device.config.img ? 'True' : 'False' }}</label>
                            </div>
                        </li>
                    </div>
                </div>
            </ul>
          </article>
        <button [ngClass]="theme.getThemeClass()" class="close col-6" (click)="closeModal()">{{ "iotModalButtonClose" | translate }}</button>
        </section>
    </div>

<!-- Modal for selecting a device when 'showMultipleDevicesModal' is true -->
<div class="container-fluid" *ngIf="this.modal.showMultipleDevicesModal">
    <div [ngClass]="theme.getThemeClass(true)"  class="modalMultiple col-10">
        <div class="hideNavBar"></div>
        <section class="modalMultiple2 col-10" [ngClass]="theme.getThemeClass(true)">
            <!-- Modal header -->
            <article class="central">
                <div class="header col-12">
                    <!-- Display a header message -->
                    <h4 class="col-12">{{ "iotModalViewSelectDeviceHeader" | translate }}</h4>
                    <!-- Close button -->
                </div>
                <!-- Container for modal content -->
                <div class="modalContainer2 col-12 selectModal">
                    <!-- Selection form for choosing a device -->
                    <div class="selectModalRow">
                        <label class="verticalCenter" for="selectDevice">{{ "iotModalViewSelectDevice" | translate }}</label>
                        <br>
                        <!-- Dropdown list for selecting a device -->
                        <select id="selectDevice" [(ngModel)]="selectedDevice" class="selectedDeviceList">
                            <!-- Display options based on available device names -->
                            <option *ngFor="let deviceName of this.modal.selectedMultipleDevicesNames" [value]="deviceName">{{ deviceName }}</option>
                        </select>
                        <br>
                    </div>
                </div>
              </article>
            <nav class="buttonRow">
                <button class="close2 col-6" (click)="this.modal.closeModal()">{{ "iotModalButtonClose" | translate }}</button>
                <button class="close2Ok col-6" (click)="onOkClick()">{{ "iotModalButtonConfirm" | translate }}</button>
            </nav>
          </section>
        </div>
</div>

<!-- Section for JSON display of the shadow -->
<section [ngClass]="theme.getThemeClass()" class="modal col-10" *ngIf="this.modal.showJsonShadow">
    <div class="hideNavBar"></div>
    <div class="shadowTitle col-12">
        <h5>
          {{ "shadow" | translate }} : {{ this.modal.thingName }}
        </h5>
    </div>
    <textarea [ngClass]="theme.getThemeClass()" class="textarea col-10" [value]="this.modal.convertShadowToJson()" readonly></textarea>
    <button class="close col-6" (click)="closeModal()">{{ "iotModalButtonClose" | translate }}</button>
</section>

<!-- Section for the relationship modal -->
<section [ngClass]="theme.getThemeClass()" class="modal col-10" *ngIf="this.modal.showRelationship">
  <div class="hideNavBar"></div>
  <h4 class="device-association-title">
    {{ "deviceRelationship" | translate }}
  </h4>
  <article class="device-association-article col-12">
    <div class="col-10 device-association">
      <h5 class="association-sub-title">
        {{ "iotModalViewDeviceName" | translate}}
      </h5>
      <!-- Section for the device name -->
      <label class="association-label">
        {{ this.modal.thingName }}
      </label>
      <!-- Section for distributor association -->
      <h5 class="association-sub-title">
        {{ "distributorAssociated" | translate}}
      </h5>
      <label *ngIf="this.cognitoService.userType !== 'distributor'" class="association-label">
        {{ this.distributorLegalName || ( "noDistributorAssociated" | translate ) }}
      </label>

      <!-- Section for client association label and select when changing association -->
      <h5 class="association-sub-title">
        {{ "clientAssociated" | translate}}
      </h5>
      <label *ngIf="!this.changeAssociationClicked && this.cognitoService.userType !== 'client'" class="association-label">
        {{ this.clientLegalname || ( "noClientAssociated" | translate ) }}
      </label>
      <div class="association-select-div">
        <ng-select [(ngModel)]="selectedClient" *ngIf="changeAssociationClicked && cognitoService.userType !== 'client'"
          class="form-control col-8 ng-select" (change)="selectedClientAssociationChange()" [ngClass]="{'has-value': selectedClient }">
          <ng-option value="" selected>{{ "selectClient" | translate }}</ng-option>
          <ng-container *ngFor="let client of modal.clientsArray">
            <ng-option [value]="client.client_id">
              {{ client.legal_name }}
            </ng-option>
          </ng-container>
        </ng-select>
      </div>
      <!-- Section for bin association label and select when changing association -->
      <h5 class="association-sub-title">
        {{ "binAssociated" | translate}}
      </h5>
      <label *ngIf="!this.changeAssociationClicked" class="association-label">
        {{ this.binNumber || ( "noBinAssociated" | translate ) }}
      </label>
      <div class="association-select-div">
        <ng-select [(ngModel)]="selectedBin" *ngIf="changeAssociationClicked" class="form-control col-8 ng-select"
          (change)="selectedBinAssociationChange()" [ngClass]="{'has-value': selectedBin }">
          <ng-option value="" selected>{{ "selectBin" | translate }}</ng-option>
          <ng-container *ngFor="let bin of binArray">
            <ng-option [value]="bin.bin_id">
              {{ bin.details.bin_model_number }} - {{ bin.bin_postal_code || ("binNoAddress" | translate) }}
            </ng-option>
          </ng-container>
        </ng-select>
      </div>
    </div>
  </article>
  <nav class="col-6">
    <button [ngClass]="theme.getThemeClass()" *ngIf="!this.changeAssociationClicked && this.userIsAdmin()" class="btn-device change-association col-6" (click)="this.changeAssociationClicked = true;">{{ "changeAssociation" | translate }}</button>
    <button [ngClass]="theme.getThemeClass()" *ngIf="this.changeAssociationClicked" class="btn-device change-association col-6" (click)="saveDeviceAssociation()">{{ "save" | translate }}</button>
    <button [ngClass]="theme.getThemeClass()" *ngIf="this.changeAssociationClicked" class="btn-device close col-6" (click)="this.changeAssociationClicked = false;">{{ "userEditButtonLabelReturn" | translate }}</button>
    <button [ngClass]="theme.getThemeClass()" *ngIf="!this.changeAssociationClicked" class="btn-device close col-6" (click)="closeDeviceRelationModal()">{{ "iotModalButtonClose" | translate }}</button>
  </nav>
</section>


<!-- Section for the deletion of a device -->
<section [ngClass]="theme.getThemeClass()" class="delete-modal col-10" *ngIf="this.iotService.showDeleteThing">
  <label>{{ "deleteThingQuestionPart1" | translate }}</label>
  <div class="div-input-thing-name">
    <input class="form-control thing-name-input" type="text" [(ngModel)]="this.iotService.desiredDeleteThingName" #inputThingName>
  </div>
  <nav class="nav-delete-thing">
    <button [ngClass]="theme.getThemeClass()" class="btn btn-delete-thing" (click)="this.modal.showDeviceModal = false; this.iotService.showDeleteThing = false;">{{ "iotModalButtonClose" | translate }}</button>
    <button [ngClass]="theme.getThemeClass()" class="btn btn-delete-thing" (click)="this.iotService.confirmDeleteThing()">{{ "usereditFormLabelConfirm" | translate }}</button>
  </nav>
</section>


<!-- Section for deletion of device datas -->
<section [ngClass]="theme.getThemeClass()" class="delete-modal col-10" *ngIf="this.iotService.showDeleteDataThing">
  <label>{{ "deviceDeleted" | translate }}</label>
  <!-- Checkbox for the reference deletion -->
  <div class="card-title mt-0 status-thing-delete">
    <label class="switch">
        <input type="checkbox" id="checkboxReferencesDelete" (change)="deleteReferencesChange()">
        <span class="slider round"></span>
    </label>
    <label class="toggle-thing-delete">{{ "deleteReferences" | translate }}</label>
  </div>
  <!-- Checkbox for the Logs deletion -->
  <div class="card-title mt-0 status-thing-delete">
    <label class="switch">
        <input type="checkbox" id="checkboxLogsDelete" (change)="deleteLogsChange()">
        <span class="slider round"></span>
    </label>
    <label class="toggle-thing-delete">{{ "deleteLogs" | translate }}</label>
  </div>
  <!-- Checkbox for the report deletion -->
  <div class="card-title mt-0 status-thing-delete">
    <label class="switch">
        <input type="checkbox" id="checkboxReportDataDelete" (change)="deleteReportDatasChange()">
        <span class="slider round"></span>
    </label>
    <label class="toggle-thing-delete">{{ "deleteReportData" | translate }}</label>
  </div>
  <nav class="nav-delete-thing">
    <button [ngClass]="theme.getThemeClass()" class="btn btn-delete-thing" (click)="this.modal.showDeviceModal = false; this.iotService.showDeleteDataThing = false;">{{ "iotModalButtonClose" | translate }}</button>
    <button [ngClass]="theme.getThemeClass()" class="btn btn-delete-thing" (click)="this.iotService.confirmDeleteDataThing(); this.modal.showDeviceModal = false;">{{ "usereditFormLabelConfirm" | translate }}</button>
  </nav>
</section>
