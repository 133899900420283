<app-system-message></app-system-message>

<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">
    <section [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'devicecreateHeaderCreateDevice' | translate }}</h4>
            <form>
                <!-- Input field for Client -->
                <label class="label-spacer" for="deviceClient">{{ 'devicecreateLabelClient' | translate }}</label>
                <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><i class="far fa-address-card"></i></span>
                <!-- Select Element with placeholder -->
                <select class="form-select" id="deviceClient" name="deviceClient" [(ngModel)]="selectedClient" (change)="selectedClientChange()">
                    <option value="" selected>{{ 'devicecreateSelectClient' | translate }}</option>
                    <!-- Fill options with Thing Types -->
                    <option *ngFor="let client of thingClient" [value]="client.client_id">{{ client.legal_name }}</option>
                </select>
                </div>
                <!-- Input field for distrubutor -->
                <div *ngIf="this.cognitoService.userType === 'muirwood'">
                  <label class="label-spacer" for="deviceDistributor">{{ '_distributor' | translate }}</label>
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="far fa-address-card"></i></span>
                    <!-- Select Element with placeholder -->
                    <select class="form-select" id="deviceDistributor" name="deviceClient" [(ngModel)]="selectedDistributorId">
                      <option value="" selected>{{ 'distributorName' | translate }}</option>
                      <!-- Fill options with Thing Types -->
                      <option *ngFor="let distributor of distributorArray" [value]="distributor.distributor_id">{{ distributor.legal_name }}</option>
                    </select>
                  </div>
                </div>

                <!-- Select for bin Id -->
                  <label class="label-spacer" for="deviceClient">{{ 'bin' | translate }}</label>
                  <div class="input-group mb-3">
                    <span class="input-group-text"><i class="fa fa-hashtag"></i></span>
                    <select [(ngModel)]="selectedBinId" class="form-control" name="binNumber"
                      aria-label="Bin number" aria-describedby="basic-addon1" (change)="binChange()">
                      <option value="" selected>{{ "selectBinId" | translate }}</option>
                      <ng-container *ngFor="let option of this.binService.bin_detail_array">
                          <option *ngIf="option.active === 1" value="{{ option.bin_id }}">{{ option.details.bin_model_number }} - {{ option.bin_postal_code }}</option>
                      </ng-container>
                    </select>
                  </div>

                <!-- Input field for Device Type -->
                <label class="label-spacer" for="deviceType">{{ 'devicecreateLabelDeviceType' | translate }}</label>
                <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><i class="fas fa-vote-yea"></i></span>
                <!-- Select Element with placeholder -->
                <select class="form-select" id="deviceType" name="deviceType" [(ngModel)]="selectedDeviceType">
                    <option value="" disabled selected>{{ 'devicecreateSelectType' | translate }}</option>
                    <!-- Fill options with Thing Types -->
                    <option *ngFor="let types of thingTypeNames" [value]="types">{{ types }}</option>
                </select>
                </div>
                <!-- Input field for Device Model -->
                <label class="label-spacer" for="deviceModel">{{ 'devicecreateLabelDeviceModel' | translate }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fab fa-gg"></i></span>
                    <!-- Select Element with placeholder -->
                    <select class="form-select" id="deviceModel" name="deviceModel" [(ngModel)]="selectedDeviceModel">
                      <option value="" disabled selected>{{ 'devicecreateSelectModel' | translate }}</option>
                      <option *ngFor="let model of deviceModels" [value]="model.value">{{ model.value }}</option>
                    </select>
                </div>
                <!-- Input field for Device Group -->
                <label class="label-spacer" for="deviceGroup">{{ 'devicecreateLabelDeviceGroup' | translate }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-th"></i></span>
                    <!-- Select Element with placeholder -->
                    <select class="form-select" id="deviceGroup" name="deviceGroup" [(ngModel)]="selectedDeviceGroup">
                      <option value="" disabled selected>{{ 'devicecreateSelectGroup' | translate }}</option>
                      <option *ngFor="let groups of thingGroups" [value]="groups">{{ groups }}</option>
                    </select>
                </div>
                <!-- Section for device quantity -->
                <label class="label-spacer" for="deviceQuantity" *ngIf="!this.isCustomSufix">{{ 'devicecreateLabelDevicesQuantity' | translate }}</label>
                <div class="input-group mb-3 firstInput" *ngIf="!this.isCustomSufix">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-asterisk"></i></span>
                    <input type="number" [(ngModel)]="selectedQuantity"
                    class="form-control" name="deviceQuantity"
                    placeholder=""
                    [disabled]="selectedBinId !== ''"
                    [value]="(selectedBinId === '') ? 0 : 1"
                    aria-label="Device Quantity" aria-describedby="basic-addon1">
                </div>
                <!-- Section for custum sufix -->
                <label class="label-spacer" for="deviceQuantity" *ngIf="this.isCustomSufix">{{ 'customSufix' | translate }}</label>
                <div class="input-group mb-3 firstInput" *ngIf="this.isCustomSufix">
                    <span class="input-group-text"><i class="fas fa-asterisk"></i></span>
                    <input type="number" [(ngModel)]="customSufix"
                    class="form-control" name="customSufix"
                    placeholder=""
                    aria-label="Custom sufix">
                </div>

                <!-- Checkbox for the custom sufix toggle -->
                <div class="card-title mt-0 status">
                  <label class="switch">
                      <input type="checkbox" id="checkboxSufix" [ngModel]="isCustomSufix" (change)="customSufixChange()" name="customSufixToggle">
                      <span class="slider round"></span>
                  </label>
                  <label class="toggle">{{ "customSufix" | translate }}</label>
              </div>

                <!-- Submit button -->
                <nav class="form-group displayFlex">
                    <button (click)="cancelButton()" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-primary btn-block btnCancel fixed-size">{{ 'cancel' | translate }}</button>
                    <button (click)="createDevice()" [ngClass]="theme.getThemeClass()"  type="submit" class="btn btn-primary btn-block btnCreate fixed-size">{{ 'create' | translate }}</button>
                </nav>
            </form>
        </article>
    </section>
</div>


