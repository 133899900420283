import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { Ribbon } from '../constants/ribbon';
import { SystemMessageService } from '../service/system-message.service';








@Component({
  selector: 'app-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.css']
})
export class SystemMessageComponent implements OnInit {


  constructor(public systemMessageService: SystemMessageService, private elementRef:ElementRef) {

  }


  ngOnInit() {
    //THIS IS AN EXAMPLE, PLEASE REMOVE BEFORE IMPLEMENTATION
    //this.systemMessageService.selectRibbon('success',"alert-success-generic-message")

    //Watches for click event on the document
     document.addEventListener('click', this.handleDocumentClick.bind(this));


  }
  @ViewChild('box') boxElement!: ElementRef | undefined;

  // please verify errors when code is uncommented
  //handles document mouse click event
  handleDocumentClick(event: MouseEvent) {
    //checks status of ribbon message
    if(this.systemMessageService.showRibbon ){
      //checks mouse click position
       if (this.boxElement && !this.boxElement.nativeElement.contains(event.target)) {
        console.log('clicked outside')
        //shakes element when clicked outside of it
       this.systemMessageService.shake(this.boxElement);
      }/* else{
        console.log('clicked inside')
       } */
     }
  }


  
}



