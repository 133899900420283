import { Injectable } from '@angular/core';

Injectable({
  providedIn: 'root'
}) 

export const ISOProvince = [
    { "value" : "CA-AB", "label" : "alberta"},
    { "value" : "CA-BC", "label" : "britishColumbia"},
    { "value" : "CA-MB", "label" : "manitoba"},
    { "value" : "CA-NL", "label" : "Newfoundland"},
    { "value" : "CA-NS", "label" : "novascotia"},
    { "value" : "CA-ON", "label" : "ontario"},
    { "value" : "CA-PE", "label" : "princeedward"},
    { "value" : "CA-QC", "label" : "quebec"},
    { "value" : "CA-SK", "label" : "saskatchewan"},
    { "value" : "CA-NT", "label" : "northwestterritories"},
    { "value" : "CA-NU", "label" : "nunavut"},
    { "value" : "CA-YT", "label" : "yukon"},
]