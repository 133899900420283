import { Component, Injectable, ChangeDetectorRef, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { Devices, IotService, MultipleDevices } from '../service/iot.service';
import { LocalStorageService } from '../local-storage.service';
import { ModalService } from '../service/device-modal.service';
import { ThemeService } from '../service/theme.service';
import { RoleService } from '../service/role.service';
import { CognitoService } from '../service/cognito.service';
import { Bin, BinDetails, BinsService } from '../service/bins.service';
import { ClientService } from '../service/client.service';
import { SystemMessageService } from '../service/system-message.service';
import { DashboardService } from '../service/dashboard.service';

@Component({
  selector: 'app-device-modal',
  templateUrl: './device-modal.component.html',
  styleUrls: ['./device-modal.component.css', './device-modal.css', '../../global-elements.css'],
})

@Injectable({
  providedIn: 'root'
})

export class DeviceModalComponent implements OnInit, AfterViewInit {
  // This will have all the items for the selected device
  public device: Devices = {} as Devices;
  public devices: MultipleDevices[] = [];
  public devicesNames: string[] = [];
  public selectedDevice: string = "";

  // Variables to handle the change of tabs in the modal
  public command: string = 'Details';
  public currentTab: number = 0;
  public modalTabs = document.getElementsByClassName("tab") as HTMLCollectionOf<HTMLElement>;
  public isDetailsActive: boolean = false;
  public isConfigsActive: boolean = false;

  // Variables for the batery of the device
  public pourcentage: number = 0;
  public color: string = '';
  public inCharge: boolean = false;

  // Variables for the capacity of the trash can
  public distanceForDevice: number = 0;

  public lastCommand: any

  public binDepthInMeters = 2.5;

  private successMessage: any;

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  // Set a timeout variable for UI display
  public timeout: boolean = false;
  public numberOfTimeoutIteration: number = 0;

  // Variables used in device association
  public clientLegalname: string = '';
  public distributorLegalName: string = '';
  public binNumber: string = '';
  public changeAssociationClicked: boolean = false;
  public selectedClient: string = '';
  public selectedDistributor: string = '';
  public selectedBin: string = '';
  public binArray: BinDetails[] = [];
  private date: number = 0;

  public oldBinData: Bin[] = [];

  public associationResult: any[] = []

  public binID: any;

  public deviceStatus2: string = "";

  public fillLevel: any;

  constructor(
    private localStorageService: LocalStorageService,
    public modal: ModalService,
    private cdRef: ChangeDetectorRef,
    public theme: ThemeService,
    private roleService: RoleService,
    public cognitoService: CognitoService,
    private binService: BinsService,
    private clientService: ClientService,
    private systemMessageService: SystemMessageService,
    public iotService: IotService,
    public bin: BinsService,
    public dashboardService: DashboardService
  ) { }

  @ViewChild('inputThingName') inputField!: ElementRef;

  // Get current theme from localstorage
  themeStatus: string = this.localStorageService.getItem('theme');

  getBinId(thingName: string) {
    // Get the list of relationships for your service
    const relationships = this.dashboardService.relationships;

    // Search for thingName in the list of relationships
    const relationshipFound = relationships.find(rel => rel.thing_name === thingName);

    // If found, return the bin_id, otherwise, return an empty string or treat as you prefer
    return relationshipFound ? relationshipFound.bin_id : '';

  }

  async ngOnInit(){
    // Will select only thing that the modal need depend from where it is showed
    switch(true){
      case this.modal.showSingleDeviceModal:
        this.detailsClicked();
        this.device = this.modal.selectedDevice;
        // Call the function pourcentage batterie to be sure that at the begining the color class of the batterie showed is allready on the html balise
        // this.getBateryPourcentage();

        // Get the bin detail array
        this.modal.binArray = await this.binService.setBinDetailArray();

        const binCapacity = this.modal.obtainBinDepthByBinId(this.getBinId(this.device.thingName));
        const distanceToLid = this.device.dat.dst;

        this.fillLevel = this.iotService.checkFillLevelPercentage(distanceToLid, binCapacity)
        break;

      case this.modal.showMultipleDevicesModal:
        this.detailsClicked();
        this.device = this.modal.selectedDevice;
        this.devices = this.modal.selectedMultipleDevices;
        // Call the function pourcentage batterie to be sure that at the begining the color class of the batterie showed is allready on the html balise
        this.getBatteryPercentage();
        // this.checkBinFillLevel();
        break;

      case this.modal.showJsonShadow:
        this.device = this.modal.selectedDevice;
        break;

      case this.modal.showRelationship:
        // Recall this function with a timeout of 0.1 second to check if we got all information we need
        const checkDeviceAssociation = () => {
          // Incriss a timeout number so if we don't have information in 5 sec it will set info to nothing found
          this.numberOfTimeoutIteration ++;
          // If the device association is not found yet and it not pass over 5 sec, set the timeout to true and recall the function
          if(!this.checkAllArrayNotEmpty()){
            this.timeout = true;
            setTimeout(checkDeviceAssociation, 100);
          }else {
            // If we got all infos for the device association , set the time out at false
            this.timeout = false;
            // If we got something, we set the selected client and go to the association functino to set the labels and selects
            if(this.modal.deviceAssociationRow.length > 0){
              // Call the device association funciton to set all arrays and labels
              this.getDeviceRelationships();
            }else{
              this.getAllAvailableBins();
            }
          }
        }
        checkDeviceAssociation();
        break;
    }
    this.selectedClientAssociationChange();
  }

  ngAfterViewInit(): void {
    this.inputField.nativeElement.focus();
  }

  // Function to load status from HTML for a given thingName
  loadStatusFromHtml(thingName: string): string {
    // Call the function in iotService to check health status for the specified thingName
    // and return the result
    return this.iotService.checkHealthStatusUsingThingName(thingName);
  }

  loadFillLevelFromHtml(thingName: string): string {
    return this.iotService.checkDeviceFillLevelUsingThingName(thingName);
  }

  // Function called when we need to check if we got all arrays for the device association
  checkAllArrayNotEmpty(){
    if((this.modal.deviceAssociationRow === undefined ||
      this.modal.deviceAssociationRow.length === 0 ||
      this.modal.clientsArray === undefined ||
      this.modal.clientsArray.length === 0 ||
      this.modal.distributorsArray === undefined ||
      this.modal.distributorsArray.length === 0 ||
      this.binService.bin_detail_array === undefined ||
      this.binService.bin_detail_array.length === 0) &&
      this.numberOfTimeoutIteration <= 50){
        // If all condition are not met, return false to made an other loop
        return false;
      }
    else{
      return true;
    }
  }

  // Function to change command for the tabs
  changeCommand(command: string, index: number){
    this.currentTab = index;
    let selectedTab: HTMLElement | null = null;

    // Get the command and put the selected tab to the good one
    switch(command){
      case 'Details':
        this.command = 'Details';
        selectedTab = document.getElementById("tabDetails");
        break;

      case 'Configs':
        this.command = 'Configs';
        selectedTab = document.getElementById("tabConfigs");
        break;
    }
    this.lastCommand = command;

    // This loop set the propertys background-color and box-shadow for the different tabs of the menu
    // It will put also and remove the class active and deactive for witch one of the tab is selected
    for(let i = 0; i < this.modalTabs.length; i ++){
      if(this.modalTabs[i] !== selectedTab){ // This is for the unselected tabs
        // Set background color to darkgray
        this.modalTabs[i].style.setProperty('background-color', 'darkgray');
        // For now, this is for only two tabs, details is the first tab and will put a box shadow
        if(index === 0){
          this.modalTabs[i].style.setProperty('box-shadow', 'inset 2px -2px 4px 1px var(--theme-color)');
        }else{
          this.modalTabs[i].style.setProperty('box-shadow', 'inset -2px -2px 4px 1px var(--theme-color)');
        }
        // Those class are for the mouse over the tabs
        this.modalTabs[i].classList.remove('active');
        this.modalTabs[i].classList.add('deactive');
      }else{ // This will be what is for the selected tab
        // It will first remove the darkgray and the box shadow of the unselected tab
        this.modalTabs[i].style.removeProperty('background-color');
        this.modalTabs[i].style.removeProperty('box-shadow');
        // Those class are for the mouse over the tabs
        this.modalTabs[i].classList.remove('deactive');
        this.modalTabs[i].classList.add('active');
      }
    }
  }

  // Get the pourcentage and put the color(red, yellow or green) for the battery of the selected device
  getBatteryPercentage(){

    // Will put this.pourcentage at zero if the pourcentage received is null or not a number
    if((this.device.dat.bat !== 0 || this.device.dat.bat !== (null) || this.device.dat.bat !== undefined) && typeof(this.device.dat.bat) === 'number'){
      // Convert the max voltage of the battery(3.7V) in pourcentage 0% to 100%
      this.pourcentage = Math.round((this.device.dat.bat / 3.7) * 100);
    }else{
      this.pourcentage = 0;
    }
    // If the battery is more then 3.7V it's because she is in charging mode
    if(this.device.dat.bat > 3.7){
      this.pourcentage = 100;
      this.inCharge = true;
    }

    // Put the good color for the text depend on the pourcentage of the battery
    switch(true){
      case this.pourcentage >= 0 && this.pourcentage <= 30:
        this.color = 'red';
        break;

      case this.pourcentage >= 31 && this.pourcentage <= 60:
        this.color = 'yellow';
        break;

      case this.pourcentage >= 61:
        this.color = 'green';
        break;

      default:
        this.color = 'red';
        break;
    }
    return this.pourcentage;
  }

  // This function determines the fill level style based on the fill percentage.
  getFillLevelStyle() {

    // Obtenha o valor numérico da porcentagem
    const numericFillLevel = this.getNumericValueFromFillLevel(this.fillLevel);

    // Determine o estilo com base no nível de preenchimento
    if (numericFillLevel <= 60) {
      return { color: 'green' }; // Estilo para a cor verde
    } else if (numericFillLevel <= 79.09) {
      return { color: '#B8860B' }; // Estilo para a cor amarela
    } else {
      return { color: 'red' }; // Estilo para a cor vermelha
    }
  }

  getNumericValueFromFillLevel(fillLevel: string): number {
    // Extrai apenas os caracteres numéricos e o ponto decimal da string
    const numericValue = parseFloat(fillLevel.replace(/[^0-9.]/g, ''));

    // Verifique se o valor obtido é um número válido
    return isNaN(numericValue) ? 0 : numericValue;
  }

  getStatusStyle() {
    const status = this.modal.thingStatus;

    switch (status) {
      case "Healthy":
        return { color: 'green' };
        // break;
      case "Unhealthy":
        return { color: 'red' };
        // break;
      default:
        return  { color: 'gray' };
        // break;
    }
  }

  // Close the modal view
  closeModal(){
    // Set all HTML boolean variable to false
    this.modal.showDeviceModal = false;
    this.modal.showJsonShadow = false;
    this.modal.showSingleDeviceModal = false;
    this.modal.showRelationship = false;

    // initialize all the datas
    this.modal.selectedMultipleDevicesNames = [];
    this.modal.selectedMultipleDevices = [];
  }

  // Funciton that reset all the instance of the modal relationships to avoid bad infos
  closeDeviceRelationModal(){
    // Reset all arrays in modal service
    this.modal.deviceAssociationRow = [];
    this.modal.binArray = [];
    this.modal.clientsArray = [];
    this.modal.distributorsArray = [];
    this.binService.bin_array = [];
    this.binService.bin_model_array = [];

    // Reset all his own data
    this.selectedBin = '';
    this.selectedClient = '';
    this.selectedDevice = '';
    this.selectedDistributor = '';
    this.clientLegalname = '';
    this.distributorLegalName = '';
    this.binNumber = '';

    // Call close function
    this.closeModal();
  }

  // Function called when the "Ok" button is clicked
  onOkClick() {
     // Call a function to retrieve device information using the selected device's name
    this.modal.getDeviceUsingName(this.selectedDevice);

     // Check if the 'check' property is true in the modal
    if (this.modal.check) {
      // If 'check' is true, assign the selected device to 'this.device'
      this.device = this.modal.selectedDevice;

      // Open a modal for the selected device
      this.modal.openModalDevice(this.device);
    }
  }

  // This function resets button stats for details and configurations.
  cleanButtonStats() {
    this.isDetailsActive = false;
    this.isConfigsActive = false;
  }

  // This function handles the click event for the "Details" button.
  detailsClicked() {
    this.cleanButtonStats();
    this.isDetailsActive = true;
    this.isConfigsActive = false;
  }

  // This function handles the click event for the "Configs" button.
  configsClicked() {
    this.cleanButtonStats();
    this.isDetailsActive = false;
    this.isConfigsActive = true;
  }

  // Function called to update device association
  async saveDeviceAssociation(){
    // Set the current date format to yyyyMMDD
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    this.date = parseInt(`${year}${month}${day}`);
    this.iotService.thingName = this.modal.thingName;

    // Call the function to update device association in device modal service
    (await this.iotService.updateDeviceAssociationLambda(this.selectedClient, this.selectedBin, this.selectedDistributor, this.date)).subscribe((response: any) => {

      this.successMessage = response;
      if(this.successMessage.message === 'success'){
        this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
        this.closeDeviceRelationModal();
      }else{
        this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      }
    });
  }

  // Function called inside ngIf of the html to know if current user have the role admin
  userIsAdmin(){
    // Initiate a tag variable
    let validateAdmin = false;

    // Check if this.userRole is already initialize
    if(this.modal.userRole){
      // Set the validation variable to true if we find the role admin in userRole array
      validateAdmin = this.modal.userRole.find((role: string) => role === '_admin');
    }
    return validateAdmin;
  }

  // Function called when the select of client is changed in device association modal
  selectedClientAssociationChange(){
    // Reset the bin array
    this.binArray = [];

    // Set the bin array depend on if a client is selected or not
    if(this.selectedClient){
      this.binArray = this.modal.binArray.filter(bin => bin.client_id === this.selectedClient && (bin.thing_name === '' || bin.thing_name === null || bin.thing_name === this.modal.thingName));
    }else{
      this.getAllAvailableBins();
    }
    // If the selectedBin is not any more in the binArray
    if(!this.binArray.find(bin => bin.bin_id === this.selectedBin)){
      // Set selectedBin to nothing
      this.selectedBin = '';
    }
  }

  // Funciton called when user change distributor in device association modal
  selectedDistributorAssociationChange(){
    this.getAllAvailableBins();
    const tempBinModelArray = this.binService.bin_model_array.filter(binModel => binModel.distributor_id === this.selectedDistributor);

    this.binArray = this.modal.binArray.filter(bin => tempBinModelArray.some(binModel => binModel.bin_model_id === bin.bin_model_id) && bin.client_id === this.selectedClient);

    const bin = this.binArray.find(bin => bin.bin_model_id === this.selectedBin);
    if(!bin){
      this.systemMessageService.selectRibbon('info', 'binRemove');
    }
  }

  // Function called when the slect of bin is change in device association modal
  selectedBinAssociationChange(){
    // Set the instance of the bin and bin_model
    const newBin = this.modal.binArray.find(bin => bin.bin_id === this.selectedBin);
    const newBinModel = this.binService.bin_model_array.find(binModel => binModel.bin_model_id === newBin?.bin_model_id);
    let oldBin: any;
    if(this.modal.deviceAssociationRow.length > 0){
      oldBin = this.modal.binArray.find(bin => bin.bin_id === this.modal.deviceAssociationRow.bin_id);
    }

    const oldBinModel = this.binService.bin_model_array.find(binModel => binModel.bin_model_id === oldBin?.bin_model_id);

    // Get the client_id
    const client_id = newBin?.client_id;
    if(client_id){
      // Set the client id for the client select
      this.selectedClient = client_id;
    }

    // Get the distributor id
    const distributor_id = newBinModel?.distributor_id;
    if(distributor_id){
      // Set the distributor id from the device selected
      this.selectedDistributor = distributor_id;
    }
    // If the user change the bin that was device required, it will automatically set the bin inactive
    if((newBin?.bin_model_id !== oldBinModel?.bin_model_id) && oldBinModel?.with_thing === 1  && oldBin){
      // Set the active to the old bin to 0
      oldBin.active = 0;
      // Preserve the old bin data
      this.oldBinData = oldBin;
      this.systemMessageService.selectRibbon('info', 'binDeactivated');
    }
  }

  getAllAvailableBins(){
    this.binArray = this.modal.binArray.filter(bin => bin.thing_name === null);
  }

  // Function called from ngOnInit for device association modal
  async getDeviceRelationships(){
    // If there's an association with a client
    if(this.modal.deviceAssociationRow[0].client_id){
      // Find and set client legal if there's an association with the selected device
      const client = this.modal.clientsArray.find((client: any) => client.client_id === this.modal.deviceAssociationRow[0].client_id);
      this.clientLegalname = client.legal_name;

      // Set the binArray to the bins that client have and are not already assign to a device
      this.binArray = this.modal.binArray.filter(bin => (bin.client_id === this.modal.deviceAssociationRow[0].client_id && (bin.thing_name === null || bin.thing_name === this.modal.deviceAssociationRow[0].thing_name)));
      // Set the client select to the proper selected client
      this.selectedClient = this.modal.deviceAssociationRow[0].client_id;
    }

    // If there's an association with a distributor
    if(this.modal.deviceAssociationRow[0].distributor_id){
      // Find and set the distributor legal name if there's an association with the device
      const distributor = this.modal.distributorsArray.find((distributor: any) => distributor.distributor_id === this.modal.deviceAssociationRow[0].distributor_id);

      this.distributorLegalName = distributor.legal_name;
      // Set the client select to the proper selected distributor
      this.selectedDistributor = this.modal.deviceAssociationRow[0].distributor_id;
    }

    // If there's an association with a bin
    if(this.modal.deviceAssociationRow[0].bin_id){
      const bin_detail_data = this.binService.bin_detail_array.find(bin => bin.bin_id === this.modal.deviceAssociationRow[0].bin_id);
      // Set bin variable for label
      this.binNumber = bin_detail_data?.details.bin_model_number + ' - ' + bin_detail_data?.bin_postal_code;
      // Set the client select to the proper selected bin
      this.selectedBin = this.modal.deviceAssociationRow[0].bin_id;
    }
  }

  // This function converts seconds to hours and minutes in a formatted string.
  convertSecondsToHours(seconds: number): string {
    const hours = Math.floor(seconds / 3600); // 3600 seconds in an hour
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    return `${hours}h ${minutes}m`;
  }

  // Function call to change the delete reference from a device
  deleteReferencesChange(){
    this.iotService.deleteReference = !this.iotService.deleteReference;
  }

  // Function call to change the delete Logs from a device
  deleteLogsChange(){
    this.iotService.deleteLogs = !this.iotService.deleteLogs;
  }

  // function call to change the delete report datats from device
  deleteReportDatasChange(){
    this.iotService.deleteReportDatas = !this.iotService.deleteReportDatas;
  }
}
