import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, Subject } from 'rxjs';
import { SystemMessageService } from './system-message.service';

export interface Locations {
  location_id: string;
  entity_id: string;
  entity_legal_name: string;
  location: string;
  entity_type: string;
  active: number;
}

@Injectable({
  providedIn: 'root'
})
export class EntitiesLocationsService {
  location_data = {
    location_id: '',
    entity_id: '',
    entity_legal_name: '',
    location: '',
    entity_type: '',
    active: 0
  }

  // Array used for filter
  public old_location_details_array: Locations[] = [];

  // Array used by lambda function
  public location_array: any;
  public location_detail_array: Locations[] = [];

  // Variables used for the filter
  private filter_count: number = 0;

  // Variable on success CRUD
  public successMessage: any;

  private array: any;

  // Variables that bin and bin-model list will used to trigger there function when there is a create or update made or not
  private changeMade = new Subject<void>;
  changeMade$ = this.changeMade.asObservable();
  private noChangeMade = new Subject<void>;
  noChangeMade$ = this.noChangeMade.asObservable();

  constructor(private http: HttpClient,
              private systemMessageService: SystemMessageService) { }

  initLocation(){
    this.location_data = {
      location_id: '',
      entity_id: '',
      entity_legal_name: '',
      location: '',
      entity_type: '',
      active: 0
    }
  }

  // Will be called by the component that call filter service to share the last userFilter count
  getCountFilter(){
    return this.filter_count;
  }

  setCountFilter(filterCount: number){
    this.filter_count = filterCount;
  }

  resetLocationDetailArray(){
    this.location_detail_array = [...this.old_location_details_array]
  }

  onSuccessCRUD(){
    this.changeMade.next();
  }

  noChangeHaveBeenMade(){
    this.noChangeMade.next();
  }


  //////////////////////// Those function will transfert, for certain keys, the received JSON from API in Integer


  switchJSONToIntForEntityResponse(element: any){
    // Tableau of witch keys we need to check
    const keysToCheck = [
      'active'
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  keyToTransfert(keysToCheck: any, element: any){
    keysToCheck.forEach((key: any) => {
      // Vérifier si la clé existe dans l'élément et si sa valeur est une chaîne
      if (typeof element[key] === 'string') {
          // Convertir la valeur de la clé en entier
          element[key] = parseInt(element[key], 10);
      }
    });
  }



  /////////////////////// Section for function that wil call lambda function ///////////////////////////////////



  // Function called to get all locations
  getEntitiesLocations(){
    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.getEntitiesLocationLambda().subscribe((response) => {
        // Put the array of Bins returned by the lambda function into an tmp array
        this.location_array = response;
        resolve(this.location_array);
      });
    });
  }

  // Function called to get one location
  async getEntityLocationById(location_id: string){
    // Append thingNmae to the url
    const url = environment.api.stage + environment.api.route.getEntityLocationById + "&location_id=" + location_id;

    return this.http.get(url).pipe(
      map((response) => {
        // Process the response data here if needed
        const tempArray:any  = response;

        this.location_array = tempArray[0];

        this.location_data.location_id = this.location_array.location_id;
        this.location_data.location = this.location_array.locations;
        this.location_data.entity_id = this.location_array.entity_id;
        this.location_data.entity_type = this.location_array.entity_type;
        this.location_data.active = this.location_array.active;

      }),
      catchError((error) => {
          console.error('API Error:', error);
          throw error(error); // Re-throw the error for the calling code to handle
      }));
  }

  // Function called to create a new entity location
  createEntityLocation(){
    // Call the function that made the call to lambda function and wait for is response
    this.createEntityLocationLambda().subscribe((response) => {
      this.successMessage = response;
      if(this.successMessage.message === 'success'){
        this.onSuccessCRUD();
        this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
      }else{
        this.noChangeHaveBeenMade();
        this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      }
    });
  }

  // Function called to update entity location
  updateEntityLocation(){
    // Call the function that made the call to lambda function and wait for is response
    this.updateEntityLocationLambda().subscribe((response) => {
      this.successMessage = response;
      if(this.successMessage.message === 'success'){
        this.onSuccessCRUD();
        this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
      }else{
        this.noChangeHaveBeenMade();
        this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      }
    });
  }



  /////////////////////// Section for lambda funciton ///////////////////////////////////////////////////////////



  // Function that will call lambda to get all locations
  getEntitiesLocationLambda(){
    // Get the url for the lambda function getBinsModels
    const url = environment.api.stage + environment.api.route.getEntitiesLocations;
    // Will call the lambda function in getBinModel url then return a response
    return this.http.get(url).pipe(
      map((response) => {
        this.array = response;

        this.array = this.array.forEach((element: any) => {
          this.switchJSONToIntForEntityResponse(element);
        });
        // return the response data
        return response;
      }),
      catchError((error) => {
        console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      })
    );
  }

  // Function that will call lambda function to create a new location
  createEntityLocationLambda(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in createBin url whit the passed data then return a response
    return this.http.post(environment.api.stage + environment.api.route.createEntitiesLocations, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "entity_id": this.location_data.entity_id,
        "locations": this.location_data.location,
        "entity_type": this.location_data.entity_type
      }, { headers: headers }
    );
  }

  // Function that will call lambda function to update a location
  updateEntityLocationLambda(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in createBin url whit the passed data then return a response
    return this.http.post(environment.api.stage + environment.api.route.updateEntitiesLocations, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "location_id": this.location_data.location_id,
        "locations": this.location_data.location,
        "active": this.location_data.active
      }, { headers: headers }
    );
  }
}
