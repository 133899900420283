<app-system-message></app-system-message>

<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">

    <section [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'newDistributorUser' | translate }}</h4>
            <form>
                <!-- DISTRIBUTOR NAME SELECT INPUT -->
                <div *ngIf="this.cognitoService.userType === 'muirwood'">
                    <label class="label-spacer" for="clientName">{{ 'distributorName' | translate }}</label>
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-briefcase"></i></span>
                        <ng-select
                            [(ngModel)]="this.cognitoService.cognitoUserData.custom_distributor_id"
                            class="form-control"
                            name="distributor_id"
                            [placeholder]="'distributorName' | translate"
                            [appendTo]="'body'"            
                            >
                            <ng-option [value]="" disabled selected>{{ 'distributorName' | translate }}</ng-option>
                            <ng-option *ngFor="let distributor of this.distributorArray" [value]="distributor.distributor_id">{{ distributor.distributor_name }}</ng-option>
                        </ng-select>
                    </div>
                </div>

                <!-- USERNAME INPUT -->
                <label class="label-spacer" for="username">{{ 'username' | translate }}</label>
                <div class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-user-tie"></i></span>
                    <input [(ngModel)]="this.cognitoService.cognitoUserData.username" type="text"
                    class="form-control" name="username"
                    placeholder="{{ 'username' | translate }}"
                    aria-label="Username" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'usernameInvalid' || this.validationService.validationErrorArray[0] == 'usernameNotUnique' ">
                </div>

                <!-- GIVEN NAME INPUT -->
                <label class="label-spacer" for="givenName">{{ 'givenName' | translate }}</label>
                <div class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-address-card"></i></span>
                    <input [(ngModel)]="this.cognitoService.cognitoUserData.given_name" type="text"
                    class="form-control" name="given_name"
                    placeholder="{{ 'givenName' | translate }}"
                    aria-label="Given Name" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'givenNameInvalid'">
                </div>

                <!-- FAMILY NAME INPUT -->
                <label class="label-spacer" for="familyName">{{ 'familyName' | translate }}</label>
                <div class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="far fa-address-card"></i></span>
                    <input [(ngModel)]="this.cognitoService.cognitoUserData.family_name" type="text"
                    class="form-control" name="family_name"
                    placeholder="{{ 'familyName' | translate }}"
                    aria-label="Family Name" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'familyNameInvalid'">
                </div>

                <!-- EMAIL INPUT -->
                <label class="label-spacer" for="deviceClient">{{ 'email' | translate }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope"></i></span>
                    <input [(ngModel)]="this.cognitoService.cognitoUserData.email" type="text"
                    class="form-control" name="email"
                    placeholder="{{ 'email' | translate }}"
                    aria-label="Email" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientEmailInvalid'">
                </div>

                <!-- PHONE NUMBER INPUT -->
                <label class="label-spacer" for="deviceClient">{{ 'phoneNumber' | translate }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-phone"></i></span>
                    <input [(ngModel)]="this.cognitoService.cognitoUserData.phone_number" type="text"
                    class="form-control telefone-input" name="phone_number"
                    placeholder="(000) 000-0000" aria-label="Phone Number"
                    aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientPhoneInvalid'">
                </div>

                <!-- ADDRESS INPUT -->
                <label class="label-spacer" for="deviceClient">{{ 'address' | translate }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-search-location"></i></span>
                    <input [(ngModel)]="this.cognitoService.cognitoUserData.address" id="autocomplete"
                    type="text" class="form-control" name="address" (keydown)="onInputAddressKeydown($event);"
                    placeholder="{{ 'clientcreateFormInputFindAddress' | translate }}"
                    aria-label="Enter a place" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientAddressInvalid'">
                </div>

                <!-- ROLES CHECKBOX INPUT -->
              <label class="toggle-container-label">{{ 'roles' | translate }}</label>
              <div class="toggle-container">
                  <ng-container *ngFor="let role of Roles">
                      <div class="row" *ngIf="role.label !== '_client'">
                          <div class="form-group">
                              <label class="switch">
                                  <input type="checkbox" [value]="role.label" (change)="this.cognitoService.toggleRole(role.label)" [checked]="this.cognitoService.roleUserArray.includes(role.label)">
                                  <span class="slider round"></span>
                              </label>
                              <label>{{ role.label | translate }}</label>
                          </div>
                      </div>
                  </ng-container>
              </div>

                <!-- Submit button -->
                <nav class="form-group displayFlex">
                    <button (click)="returnToAdmin()" *ngIf="this.cognitoService.userType ==='muirwood'" [ngClass]="theme.getThemeClass()" type="submit" class="btn btn-primary btn-block btnCancel fixed-size">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button (click)="returnToDistributorAdminDashboard()" *ngIf="this.cognitoService.userType ==='distributor'" [ngClass]="theme.getThemeClass()" type="submit" class="btn btn-primary btn-block btnCancel fixed-size">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button [ngClass]="theme.getThemeClass()" (click)="createUser()" type="submit" class="btn btn-primary btn-block btnCreate fixed-size">{{ 'clientcreateFormBtnCreateClient' | translate }}</button>
                </nav>
            </form>
        </article>
    </section>
</div>





