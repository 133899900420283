import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SystemMessageService } from './system-message.service';
@Injectable({
  providedIn: 'root'
})
export class WorkOrderDashboardModalService {
  public showStatusModal: boolean = false;
  public showDeadlineDateModal: boolean = false;
  public showDescriptionModal: boolean = false;
  public showCommentModal: boolean = false;
  public showModal: boolean = false;

  public work_order_id: string = '';
  private oldStatus : string = '';
  public status: string = '';
  private oldDeadline: string = '';
  public deadlineDate: string = '';
  private oldDescription: string = '';
  public description: string = '';
  public comments: string = '';
  private date: string = '';

  public userSub: string = '';

  private successMessage: any;
  public applyingChanges: boolean = false;

  constructor(private http: HttpClient,
              private router: Router
              ,private systemMessageService: SystemMessageService) { }

  // Function called from the work order list to open the status change modal
  changeStatus(work_order_id: string, status: string){
    this.work_order_id = work_order_id;
    this.status = status;
    this.oldStatus = status;
    this.showModal = true;
    this.showStatusModal = true;
  }

  // Function called from the work order list to open the deadline date modal
  changeDeadline(work_order_id: string, deadlineDate: string){
    this.work_order_id = work_order_id;
    this.deadlineDate = deadlineDate;
    this.oldDeadline = deadlineDate;
    this.showModal = true;
    this.showDeadlineDateModal = true;
  }

  // function called by the work order list to open the description modal
  changeDescription(work_order_id: string, description: string){
    this.work_order_id = work_order_id;
    this.description = description;
    this.oldDescription = description;
    this.showModal = true;
    this.showDescriptionModal = true;
  }

  // function called by the work order list to open the comments modal
  addComments(work_order_id: string, comments: string, userSub: string){
    this.work_order_id = work_order_id;
    this.comments = comments;
    this.userSub = userSub;
    this.showModal = true;
    this.showCommentModal = true;
  }

  // Function called when user change the status of the work order
  statusChange(status: string){
    this.status = status;
  }

  // Function called when user chaneg the date on the modal
  deadlineDateChange(selectedDate: string){
    const convertedDate = new Date(selectedDate);
    const utcTimeStamp = convertedDate.getTime();
    this.deadlineDate = utcTimeStamp.toString();
  }

  // Function called when user change the description on modal
  descriptionChange(selectedDescription: string){
    this.description = selectedDescription;
  }

  // Function called when user change the comments on the modal
  commentChange(comments: string){
    this.comments = comments;
  }

  // Function called when user submit the change made
  submitChange(){
    // Get today's date for saving in DB
    const today: Date = new Date();
    this.date = today.getTime().toString();
    this.applyingChanges = true;

    if(this.work_order_id !== ''){
      // Call the function that made the call to lambda function and wait for is response
      this.updateWorkOrderLambda().subscribe((response: any) => {
        setTimeout(() => {
          this.close();
          window.location.reload();
        }, 500);

        // if(response.status === 200){
        // }else{
        //   this.noChangeHaveBeenMade();
        // }
      });
    }

  }

  updateWorkOrderLambda(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in updateBinModel url whit the passed data then return a response
    return this.http.post(environment.api.stage + environment.api.route.updateWorkOrder, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "work_order_id": this.work_order_id,
        "user_sub": this.userSub,
        "status": this.status,
        "deadlineDate": this.deadlineDate,
        "description": this.description,
        "comment": this.comments,
        "date": this.date,
      }, { headers: headers }
    );
  }

  // Function that close all from modal
  close(){
    // Set all variable for showing modal at false to hide the modal
    this.showModal = false;
    this.showDeadlineDateModal = false;
    this.showDescriptionModal = false;
    this.showStatusModal = false;
    this.showCommentModal =false;

    // Set all his variables to default to avoid bad entry
    this.work_order_id = '';
    this.status = '';
    this.description = '';
    this.comments = '';
  }
}
