import { Component, OnInit, Input } from '@angular/core';
import { BinsService } from '../service/bins.service';
import { ValidationService } from '../service/validation.service';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { CognitoService } from '../service/cognito.service';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';
import { environment } from '../environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';
import { RoleService } from '../service/role.service';

@Component({
  selector: 'app-bin-model-create',
  templateUrl: './bin-model-create.component.html',
  styleUrls: ['./bin-model-create.component.css', '../../global-elements.css']
})
export class BinModelCreateComponent implements OnInit {

  // Variable to made the volume calcul
  public selectedShape: string = '';
  public width: string = '';
  public height: string = '';
  public depth: string = '';
  public totalVolume: string = '';
  public noShapeSelected: boolean = false;
  public validVolume: boolean = false;

  // Variables for the check before creation
  public validateCreate: boolean = false;
  public sameNumber: boolean = false;
  private systemMessage: string = '';

  // Variable used for distributor associated to the bin
  public associatedDistributorId: string = '';

  constructor(public bin: BinsService,
              public validationService: ValidationService,
              private route: Router,
              public theme: ThemeService,
              public cognitoService: CognitoService,
              private systemMessageService: SystemMessageService,
              private roleService: RoleService){



  }

  async ngOnInit() {
    // Get current user validation and types from cognito
    this.cognitoService.confirmValidUser();
    this.cognitoService.getUserType();

    // Get current user role from DB
    await this.roleService.getRoles();

    // Validate current user roles for the page
    this.cognitoService.getCurrentRole([environment.users.role.administrator], [environment.users.superAdmin, environment.users.supplier], true, this.roleService.roles);

    // Initialize bin_model_data array
    this.bin.initBinModelData();

    await this.bin.getBinsModel();

    // Set his own property of the user type and distributor_id when user type is distributor
    await this.cognitoService.getUserType();
    // Check witch user type is the current user and get only infos he can associate bins
    switch(this.cognitoService.userType){
      // Remove the break because each one of hte two user type get the client list
      case 'muirwood':
        // Distributor user will be abble to associate a bin to his own distributor ID or clients
        await this.cognitoService.getDistributorsInfos();
        break;
    }
  }

  async onSubmit(){
    // Create a variable whit the current date
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'yyyyMMdd', 'en_US');

    // Set created and modified date to current date, modified is required event if it's not so the query won't break
    this.bin.bin_model_data.created = parseInt(formattedDate, 10);
    this.bin.bin_model_data.modified = parseInt(formattedDate, 10);

    // Check if the total volume is not empty. If so, it will initialize bin_model_data's stuff to it's proper stuff
    // Input in html are string for the need of UI display and BD are int()
    if(this.totalVolume !== ''){
      // If the total is displayed, alle the inputs will be entered so we implement all bin_model_data stuff
      this.bin.bin_model_data.bin_width = parseInt(this.width);
      this.bin.bin_model_data.bin_height = parseInt(this.height);
      this.bin.bin_model_data.bin_depth = parseInt(this.depth);
      this.bin.bin_model_data.bin_shape = this.selectedShape;
      this.bin.bin_model_data.total_volume = parseFloat(this.totalVolume.split(' ')[0]); // I split this.totalVolume because I put a string at the end for the display ' L'
    }

    // Call the funtion for validation in addition of validation service
    this.checkValidation();

    if(this.validateCreate){
      // Call the function that create the bin in bin service
      await this.bin.createBinModel();
      this.returnToAdmin(); // Set session variable then go back to admin page
    } else {
      // If system message have nothing it put a generic message
      if(this.systemMessage === ''){
        this.systemMessage = 'alert-danger-generic-message';
      }
      // Set the system message service to display the error to the user
      this.systemMessageService.selectRibbon('danger', this.systemMessage);
    }
  }

  // Function commented because we don't calculate volume for the moment ( Don't remove untill further notice )

  // Function that calculate the volume of the bin
  // calculateVolume(){
  //   let volumeMiliLiter = 0;
  //   let volumeLiter = '';

  //   // calculate the volume of a square
  //   if(this.selectedShape === 'S' && this.width !== '' && this.height !== '' && this.depth !== ''){
  //     volumeMiliLiter = parseFloat(this.width) * parseFloat(this.height) * parseFloat(this.depth);
  //     volumeLiter = (volumeMiliLiter / 1000).toFixed(2);
  //     this.totalVolume = volumeLiter + ' L';
  //     this.noShapeSelected = false; // UI use this variable when user enter values whitout selected a shape first
  //   }
  //   // Calculate the volume of a cylinder
  //   if(this.selectedShape === 'C' && this.width !== '' && this.height !== ''){
  //     volumeMiliLiter = Math.PI * Math.pow(parseFloat(this.width) / 2, 2) * parseFloat(this.height);
  //     volumeLiter = (volumeMiliLiter / 1000).toFixed(2);
  //     this.totalVolume = volumeLiter + ' L';
  //     this.noShapeSelected = false; // UI use this variable when user enter values whitout selected a shape first
  //   }

  //   // Check when user enter values for the calculation of total volume if there is a shape selected first
  //   if(this.selectedShape === ''){
  //     // Set the message that will be display to the user
  //     this.systemMessage = 'binModelCreateViewAlertNoShape';
  //     // Set the system message service to display the error to the user
  //     this.systemMessageService.selectRibbon('danger', this.systemMessage);
  //   }
  // }

  // If user change the selected shape, it willl back to nothing all the calculation
  shapeChange(){
    this.width = '';
    this.height = '';
    this.depth = '';
    this.totalVolume = '';
  }

  // Function in addition of validation service for the check of variables
  checkValidation(){
    // Loop thought bin_model_array to know if user enter twice the same bin-model-number and will throw an error message if so
    for(let i = 0; i < this.bin.bin_model_array.length; i ++){
      if(this.bin.bin_model_data.bin_model_number === this.bin.bin_model_array[i].bin_model_number){
        this.sameNumber = true;
      }
    }
    // Check if sameNumber is at true then set systemMessage variable to the proper translate value
    if(this.sameNumber){
      this.systemMessage = 'sameBinModelNumber';
    }

    // Will check if we got values on each inputs then validate the creation of the bin model
    if(this.totalVolume !== '' &&
      this.height !== '' &&
      this.bin.bin_model_data.bin_model_number !== '' &&
      this.bin.bin_model_data.above_ground !== null &&
      !this.sameNumber){

        switch(true){
          // Check if the user type is a muirwood user and if so, will check if the distributor_id is not empty
          case (this.cognitoService.userType === 'muirwood' && this.bin.bin_model_data.distributor_id !== ''):
            // Set the validation variable to true
            this.validateCreate = true;
            break;

          case this.cognitoService.userType === 'distributor':
            // Set the distributr id variable to his own distributor id
            this.bin.bin_model_data.distributor_id = this.cognitoService.distributorId;
            // Set the validation variabe to true
            this.validateCreate = true;
            break;

          default:
            this.systemMessage = 'enterDistributor';
            break;
        }
    }
  }

  // Implemented by cancel button to return to the admin component
  returnToAdmin(){
    // Put a sessions store variable so admin component could know to return on bin-list
    sessionStorage.setItem("previous", "bin-model-list");
    sessionStorage.setItem("from", "bin-model-create"); // Set this variable so bin-model-list will use it on success creation to put he good UI message
    this.route.navigate(['/admin']); // Return to admin component
  }

  //
  returnToDistributorBinsDashboard(){
    this.route.navigate(['/distributor-bins-model-dashboard']);
  }
}
