<div [ngClass]="theme.getThemeClass()" class="container-fluid">
  <div class="page-header">
    <!-- For future development -->
    <!-- <button class="btn back" (click)="backToBinModelList()"><i class="fa fa-arrow-left"></i></button> -->
    <h2>{{"binListViewBinList" | translate}}</h2>
  </div>
  <nav
    id="search-component"
    class="nav-sort-filter"
  >
    <input
      #searchBox
      type="search"
      id="search-box"
      [(ngModel)]="userFilter"
      (input)="
        this.search()
      "
      class="form-control rounded searchInput"
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}"
      aria-label="Search"
      aria-describedby="search-addon"
    />
    <ng-select class="form-control ng-select sort-by-select" [(ngModel)]="this.filterBy" placeholder="{{ 'filterBy' | translate }}" [ngClass]="{'has-value': this.filterBy }">
      <ng-option value="" disabled selected>{{ "filterBy" | translate }}</ng-option>
      <ng-option value="bin_address">{{ "usereditFormLabelAddress" | translate }}</ng-option>
      <ng-option value="thing_name">{{ "iotModalViewDeviceName" | translate }}</ng-option>
      <ng-option value="bin_model_number">{{ "binListViewNumber" | translate }}</ng-option>
    </ng-select>
    <ng-select class="form-control ng-select sort-by-select" [(ngModel)]="this.sortBy" (change)="onSortChange('select')" placeholder="{{ 'sortBy' | translate }}" [ngClass]="{'has-value': this.sortBy }">
      <ng-option value="" disabled selected>{{ 'sortBy' | translate }}</ng-option>
      <ng-option value="bin_address">{{ "usereditFormLabelAddress" | translate }}</ng-option>
      <ng-option value="thing_name">{{ "iotModalViewDeviceName" | translate }}</ng-option>
      <ng-option value="bin_model_number">{{ "binListViewNumber" | translate }}</ng-option>
    </ng-select>
    <div class="card-title mt-0 status">
      <label class="switch">
          <input type="checkbox" [ngModel]="this.ascDesc" (change)="onSortChange('switch')">
          <span class="slider round">
            <span class="toggle-switch-label-off">{{ "asc" | translate }}</span>
            <span class="toggle-switch-label-on">{{ "desc" | translate }}</span>
          </span>
      </label>
  </div>
  </nav>
<div class="grid-container" [ngClass]="theme.getThemeClass()">
  <section [ngClass]="theme.getThemeClass()" class="gridster" [ngStyle]="{'grid-template-columns': getColumnStyle()}">
    <ng-container *ngFor="let item of this.clientBinArray">
    <article class="gridster-item">
      <!-- Your tile content goes here -->
        <nav [ngClass]="theme.getThemeClass()">
          <header class="dashboard-tile-header">
            <a [routerLink]="['/bin-update', item.bin_id]">
              <h6 class="dashboard-tile-title">{{ item.bin_address || ("binNoAddress" | translate) }}</h6></a>
          </header>
          <hr>
          <div class="tiles-container">
            <div class="titles-items">
              <i class="fa-solid fa-dumpster tile-icon"></i>
              <label class="item-label">{{ item.bin_name | translate }}</label>
            </div>
            <div class="titles-items">
              <i class="fa fa-trash tile-icon"></i>
              <label class="item-label">{{ item.bin_usage | translate }}</label>
            </div>
            <div class="titles-items">
              <i class="fa-solid fa-store tile-icon"></i>
              <label class="item-label">{{( item.market_segment | translate ) || "N/A" }}</label>
            </div>
            <div class="titles-items" *ngIf="item.thing_name">
              <i class="fa-solid fa-microchip tile-icon device-icon"></i>
              <label class="item-label device-label">{{ item.thing_name }}</label>
            </div>
          </div>
        </nav>
      </article>
  </ng-container>
</section>
</div>
</div>

