<app-system-message></app-system-message>
<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">

  <section [ngClass]="theme.getThemeClass()" class="card bg-light">
      <article class="card-body mx-auto article">
          <h4 class="card-title mt-0 text-center">{{ 'editOperatorUser' | translate }}</h4>
          <form>

              <!-- USERNAME INPUT -->
              <label class="label-spacer" for="username">{{ 'username' | translate }}</label>
              <div class="input-group mb-3 firstInput">
                  <span class="input-group-text no-edit" id="basic-addon1"><i class="fas fa-user-tie"></i></span>
                  <input [(ngModel)]="this.cognitoService.cognitoUserData.username" type="text"
                  class="form-control no-edit" name="username"
                  placeholder=""
                  aria-label="Username" aria-describedby="basic-addon1"
                  value="this.cognitoService.cognitoUserData.username" readonly>
              </div>

              <!-- GIVEN NAME INPUT -->
              <label class="label-spacer" for="givenName">{{ 'givenName' | translate }}</label>
              <div class="input-group mb-3 firstInput">
                  <span class="input-group-text" id="basic-addon1"><i class="fas fa-address-card"></i></span>
                  <input [(ngModel)]="this.cognitoService.cognitoUserData.given_name" type="text"
                  class="form-control" name="given_name"
                  placeholder="{{ 'givenName' | translate }}"
                  aria-label="Given Name" aria-describedby="basic-addon1"
                  (blur)="this.validationService.validateGivenName(this.cognitoService.cognitoUserData.given_name)"
                  [class.error-border]="this.validationService.validationErrorArray[0] == 'givenNameInvalid'"
                  value="this.cognitoService.cognitoUserData.given_name">
              </div>

              <!-- FAMILY NAME INPUT -->
              <label class="label-spacer" for="familyName">{{ 'familyName' | translate }}</label>
              <div class="input-group mb-3 firstInput">
                  <span class="input-group-text" id="basic-addon1"><i class="far fa-address-card"></i></span>
                  <input [(ngModel)]="this.cognitoService.cognitoUserData.family_name" type="text"
                  class="form-control" name="family_name"
                  placeholder="{{ 'familyName' | translate }}"
                  aria-label="Family Name" aria-describedby="basic-addon1"
                  (blur)="this.validationService.validateFamilyName(this.cognitoService.cognitoUserData.family_name)"
                  [class.error-border]="this.validationService.validationErrorArray[0] == 'familyNameInvalid'"
                  value="this.cognitoService.cognitoUserData.family_name">
              </div>

              <!-- EMAIL INPUT -->
              <label class="label-spacer" for="deviceClient">{{ 'email' | translate }}</label>
              <div class="input-group mb-3 ">
                  <span class="input-group-text no-edit" id="basic-addon1"><i class="fa fa-envelope"></i></span>
                  <input [(ngModel)]="this.cognitoService.cognitoUserData.email" type="text"
                  class="form-control no-edit " name="email"
                  placeholder="{{ 'email' | translate }}"
                  aria-label="Email" aria-describedby="basic-addon1"
                  value="this.cognitoService.cognitoUserData.email" readonly>
              </div>

              <!-- PHONE NUMBER INPUT -->
              <label class="label-spacer" for="deviceClient">{{ 'phoneNumber' | translate }}</label>
              <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1"><i class="fa fa-phone"></i></span>
                  <input [(ngModel)]="this.cognitoService.cognitoUserData.phone_number" type="text"
                  class="form-control telefone-input" name="phone_number"
                  placeholder="(000) 000-0000" aria-label="Phone Number"
                  aria-describedby="basic-addon1"
                  (blur)="this.validationService.validatePhoneNumber(this.cognitoService.cognitoUserData.phone_number)"
                  [class.error-border]="this.validationService.validationErrorArray[0] == 'clientPhoneInvalid'"
                  value="this.cognitoService.cognitoUserData.phone_number">
              </div>

              <!-- ADDRESS INPUT -->
              <label class="label-spacer" for="deviceClient">{{ 'address' | translate }}</label>
              <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1"><i class="fas fa-search-location"></i></span>
                  <input [(ngModel)]="this.cognitoService.cognitoUserData.address" id="autocomplete"
                  type="text" class="form-control" name="address" (keydown)="onInputAddressKeydown($event);"
                  placeholder="{{ 'clientcreateFormInputFindAddress' | translate }}"
                  aria-label="Enter a place" aria-describedby="basic-addon1"
                  (blur)="this.validationService.validateAddress(this.cognitoService.cognitoUserData.address)"
                  [class.error-border]="this.validationService.validationErrorArray[0] == 'clientAddressInvalid'"
                  value="this.cognitoService.cognitoUserData.address">
              </div>

              <!-- ROLES CHECKBOX INPUT -->
              <label class="toggle-container-label">{{ 'roles' | translate }}</label>
              <div class="toggle-container">
                  <ng-container *ngFor="let role of Roles">
                      <div class="row" *ngIf="role.label !== '_distributor' && role.label !== '_client'">
                          <div class="form-group switch-group">
                              <label class="switch">
                                  <input type="checkbox" [value]="role.label" (change)="this.roleService.toggleRoleUpdate(role.label, this.userFound.sub)" [checked]="isRoleChecked(role.label)">
                                  <span class="slider round"></span>
                              </label>
                              <label>{{ role.label | translate }}</label>
                          </div>
                      </div>
                  </ng-container>
              </div>
              <!-- Submit button -->
              <nav class="form-group displayFlex">
                  <button (click)="cancel()" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-primary btn-block btnCancel fixed-size">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                  <button [ngClass]="theme.getThemeClass()" (click)="saveUser()" type="submit" class="btn btn-primary btn-block btnCreate fixed-size">{{ 'save' | translate }}</button>
              </nav>
              <nav class="form-group displayFlex">
                  <button *ngIf="this.cognitoService.cognitoUserData.enabled === '1'" (click)="disableUser()" type="button" class="btn btn-primaryy btn-block btnCreate fixed-size red">{{ 'disable' | translate }}</button>
                  <button *ngIf="this.cognitoService.cognitoUserData.enabled !== '1'" (click)="enableUser()" type="button" class="btn btn-primary btn-block btnCreate fixed-size green">{{ 'enable' | translate }}</button>
              </nav>
          </form>
      </article>
    </section>
</div>






