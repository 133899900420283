import { Injectable } from '@angular/core';
import * as Papa from 'papaparse';
@Injectable({
  providedIn: 'root'
})
export class CsvService {

  constructor() { }

  /**
   * Exports data to a CSV file, given an array of objects and a filename.
   * @param data An array of objects to be exported to CSV.
   * @param filename The desired filename for the CSV file.
   */
   exportToCsv(data: any[], filename: string): void {
    // console.log("Starting CSV export");

    // Check if there is data to export
    if (data.length === 0) {
        console.warn("No data to export.");
        return;
    }

    // Assumes the first item in the array contains the field names and extracts them as headers
    const headers = Object.keys(data[0]);

    // Generates an array of arrays to represent the data
    const dataArray = data.map(item => {
        return headers.map(header => item[header]);
    });

    // Adds headers to the beginning of the data
    dataArray.unshift(headers);

    const csv = Papa.unparse(dataArray);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    const navigatorAny: any = navigator;

    if (navigatorAny.msSaveBlob) {
        // For Internet Explorer
        navigatorAny.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement('a');

        if (link.download !== undefined) {
            // For other browsers
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }
    }

    // console.log("Export completed");
  }
}

