import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { AdminService } from '../service/admin.service';
import { CognitoService } from '../service/cognito.service';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';
import { ValidationService } from './../service/validation.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  // Variables used to display lists
  public isBinListActive: boolean = false;
  public isUserListActive: boolean = true;
  public isBinModelListActive: boolean = false;
  public isTaxeTypeActive: boolean = false;

  constructor(private localStorageService: LocalStorageService,
              private admin: AdminService,
              private cognito: CognitoService,
              public theme: ThemeService,
              public systemMessage: SystemMessageService,
              public validationService: ValidationService){


  }

  // Get current theme from localstorage
  themeStatus: string = this.localStorageService.getItem('theme');

  async ngOnInit() {
    // Check for user permissions
    await this.cognito.confirmValidUser();
    await this.cognito.getCurrentRole([environment.users.role.administrator], [environment.users.superAdmin]);

    // Call the function that will get the list from witch the component came from
    this.getPreviousList();

    // Function called when user click on a bin model in his modal and will filter the bin-list whit the selected bin model number
    this.admin.returnToBinList$.subscribe(() => {
      // Call the function that will get the list from witch the component came from
      this.getPreviousList();
    });

    if(this.validationService.createSuccessMessage) {
      this.systemMessage.selectRibbon('success',"userCreated")
    }

    if(this.validationService.updateSuccessMessage) {
      this.systemMessage.selectRibbon('success',"userUpdated")
    }
  }

  // Function that will get the list that user came from
  getPreviousList(){
    // Set a variable previous list if there is something in session strorage only when user come back from a create component
    const previousList =  sessionStorage.getItem("previous");
    if(previousList){
      // Call function that will set the good tab that user was on before
      this.showPreviousList(previousList);
      sessionStorage.removeItem("previous"); // Clear session storage variable previous
    }
  }

  // Will set the good tab that user went from in create or update component and will return to this list
  showPreviousList(previousList: string){
    switch(previousList){
      case 'user-list':
        this.userListClicked();
        break;
      case 'taxe-type-list':
        this.taxeTypeClicked();
        break;
      case 'bin-list':
        this.binListClicked();
        break;
      case 'bin-model-list':
        this.binModelListClicked();
        break;
      default:
        this.userListClicked();
        break;
    }
  }


  // Set all variables used with the tabs of the page to false
  setIsActiveFalse(){
    this.isTaxeTypeActive = false;
    this.isBinListActive = false;
    this.isBinModelListActive = false;
    this.isUserListActive = false
  }

  // Set the variable used for the tab taxe-type to true
  taxeTypeClicked(){
    // Set all tabs variable to false
    this.setIsActiveFalse();
    this.isTaxeTypeActive = true; // Then put the proper variable at true so the proper list will be showed
  }

  // Set the variable used for the tab bin-list to true
  binListClicked(){
    // Set all tabs variable to false
    this.setIsActiveFalse();
    this.isBinListActive = true; // Then put the proper variable at true so the proper list will be showed
  }

  userListClicked(){
    // Set all tabs variable to false
    this.setIsActiveFalse();
    this.isUserListActive = true; // Then put the proper variable at true so the proper list will be showed
  }

  // Set the variable used for the tab bin-model-list to true
  binModelListClicked(){
    // Set all tabs variable to false
    this.setIsActiveFalse();
    this.isBinModelListActive = true; // Then put the proper variable at true so the proper list will be showed
  }
}
