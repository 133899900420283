import { Component, OnInit, HostListener } from '@angular/core';
import { CognitoService } from '../service/cognito.service';
import { ThemeService } from '../service/theme.service';
import { OperatorService } from '../service/operator.service';
import { environment } from '../environments/environment';
import { ValidationService } from '../service/validation.service';
import { SystemMessageService } from '../service/system-message.service';
import { Roles } from '../constants/roles';
import { Router } from '@angular/router';
import { Types } from '../constants/user-type';
import { LocalizationService } from '../service/localization.service';

@Component({
  selector: 'app-user-create-operator',
  templateUrl: './user-create-operator.component.html',
  styleUrl: './user-create-operator.component.css'
})
export class UserCreateOperatorComponent implements OnInit{

  public operatorArray: any;

  // Variable used for the validation
  private operatorInputValid: boolean = false;

  // Used by HTML array
  public RolesArray = Roles;

  private systemMessage: string = '';
  public userEnabled: boolean = false;

  constructor(public cognitoService: CognitoService,
              public theme: ThemeService,
              public operatorService: OperatorService,
              public validationService: ValidationService,
              public systemMessgeService: SystemMessageService,
              private router: Router,
              private localizationService: LocalizationService,
              private systemMessageService: SystemMessageService){

    this.cognitoService.confirmValidUser();
    this.cognitoService.getCurrentRole([environment.users.role.administrator], [environment.users.superAdmin]);
  }

  // Decorator @HostListener listens to the 'input' event on the specified target element
  @HostListener('input', ['$event.target']) onInput(input: HTMLInputElement): void {
    // Check if the input element has the class 'telefone-input'
    if (input.classList.contains('telefone-input')) {
      // Remove all non-numeric characters from the input value
      const value = input.value.replace(/\D/g, '');

      // Check if the value has a length of 10 characters or less
      if (value.length <= 12) {
        // Format the phone number as (000) 000-0000
        input.value = this.formatPhoneNumber(value);
      } else {
        // If the value is longer than 10 digits, limit the input to 10 digits
        input.value = input.value.slice(0, 12);
      }
    }
  }

  async ngOnInit() {
    // Get an array of all operator in DB
    this.operatorArray = await this.operatorService.getOperator();
    // set autocomplete for the address in localization service
    this.localizationService.initAutocomplete();
    // Clear the existing data on cognito user data
    this.cognitoService.clearCognitoUserData();
  }

  // Function called when user click on creation button
  async createUser(){
    // Set the address of the cognito user data
    this.cognitoService.cognitoUserData.address = this.localizationService.autoCompletionReturnAddress;

    // Remove non-numeric characters from the phone number using the validationService
    this.validationService.keepOnlyNumbers(this.cognitoService.cognitoUserData.phone_number);
    // Update the client's phone number and creation date with the formatted values
    this.cognitoService.cognitoUserData.phone_number = this.validationService.phoneNumberFixed;

    // Check the validation of all fields
    this.checkValidationAllInputs();

    if(this.validationService.usernameValid &&
      this.validationService.givenNameValid &&
      this.validationService.familyNameValid &&
      this.validationService.clientEmailValid &&
      this.validationService.clientPhoneNumberValid &&
      this.validationService.clientAddressValid &&
      this.validationService.userRolesValid){

        try {
          // Attempt to sign up the user and send a temporary password
          const result = await this.cognitoService.UserSignUpAndSendTemporaryPassword(Types[3].label, Roles[4].label);
          if(result === 'duplicated'){
            this.systemMessageService.selectRibbon('danger','Duplicated email');
          } else {
            // Variable used by bin-model-list html to show the proper message on success updating bin model
            sessionStorage.setItem("from", "user-create");
            // If cognito add user doesn't break then call the function to get back to admin component
            this.returnToAdmin();
          }
        }catch(error: any){
          if (error.response && error.response.status === 400) {
            // Handle 400 Bad Request errors
            if (error.name === 'UsernameExistsException') {
              console.error("Bad Request: User account already exists.");
              // Set the message throw to the user
              this.systemMessage = 'userAlreadyExist';

            } else {
              // Log an error if the 400 error is not due to an existing username
              console.error("Error creating user:", error.message);
            }
          } else {
            // Handle unexpected errors
            if(error = "UsernameExistsException: User account already exists"){
              // Set the message throw to the user
              this.systemMessage = 'userAlreadyExist';
            }
            console.error("Unexpected error:", error);
          }
          // Set the username as invalid if error = UsernameExistsException: User account already exists
          this.systemMessageService.selectRibbon('danger', this.systemMessage);
        }
      }else{
        // Set the message error throwed to the uer
        this.systemMessage = 'fieldEmptyOrIncorrect';
        // throw the message to the user
        this.systemMessageService.selectRibbon('danger', this.systemMessage);
      }
  }

  checkValidationAllInputs(){

    // Check if user select an operator
    if(this.cognitoService.cognitoUserData.custom_operator_id !== ''){
      this.operatorInputValid = true;
    }

    // Check if username is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateName(this.cognitoService.cognitoUserData.username);
    this.validationService.validateName(this.cognitoService.cognitoUserData.given_name);
    this.validationService.validateName(this.cognitoService.cognitoUserData.family_name);

    // Check if the clientEmail is not a valid email or is empty
    this.validationService.validateClientEmail(this.cognitoService.cognitoUserData.email);

    // Check if phoneNumber is not empty and its length is less than 10 or if it's empty
    this.validationService.validatePhoneNumber(this.cognitoService.cognitoUserData.phone_number);

    // Check if the address is empty
    this.validationService.validateAddress(this.cognitoService.cognitoUserData.address);

    this.validationService.validateRoles();
  }

  // Function that avoid submitting the page when user press enter at the end of inputting address in address input
  onInputAddressKeydown(event: KeyboardEvent): void {
    if(event.key === 'Enter'){
      event.preventDefault(); // Don't submit the page
    }
  }

  formatPhoneNumber(numberSequence: string | undefined | null): string {
    if (numberSequence === undefined || numberSequence === null) {
        return ''; // or handle the case appropriately
    }

    // Remove non-numeric characters
    const justNumbers = numberSequence.replace(/\D/g, '');

    // Check if the input is empty after removing non-numeric characters
    if (justNumbers.length === 0) {
        return '';
    }

    // Extract the last 10 digits (ignoring the country code, if present)
    const last10Digits = justNumbers.slice(-10);

    // Format the last 10 digits
    const parte1 = last10Digits.slice(0, 3);
    const parte2 = last10Digits.slice(3, 6);
    const parte3 = last10Digits.slice(6);
    const numeroFormatado = `(${parte1}) ${parte2}-${parte3}`;

    // If the country code was present, add it back
    const codigoPais = justNumbers.slice(0, -10);
    return codigoPais.length > 0 ? `+${codigoPais} ${numeroFormatado}` : numeroFormatado;
  }

  // Implemented by cancel button to return to the admin component
  returnToAdmin(){
    // Put a sessions store variable so admin component could know to return on bin-list
    sessionStorage.setItem("previous", "user-list");
    this.router.navigate(['/admin']); // Return to admin component
  }
}
