<!-- Displaying a custom system message component -->
<app-system-message></app-system-message>

<!-- Main content of the device configuration modal desktop version -->
<div [ngClass]="theme.getThemeClass()" class="big-screen" *ngIf="this.iotService.showDeviceConfigModal">
    <!-- Navigation bar for the device configuration modal -->
    <nav class="title">
        <h3>{{ "deviceConfigModalDeviceConfig" | translate }}</h3><label class="updated" *ngIf="this.changeSaved">{{ "usereditFormLabelChangeConfirm" | translate }}</label><h5 class="model">{{ this.iotService.thingName}}</h5>
    </nav>
    <!-- Left block containing configuration options -->
    <section class="block-left col-5">
        <!-- Configuration option for sleep -->
        <article class="sleep" [ngClass]="theme.getThemeClass()">
            <!-- Label for sleep configuration -->
            <label class="labelSleep col-7">{{ "deviceConfigModalSlp" | translate }}</label>
            <div class="col-5">
                <select class="col-12 form-control" [(ngModel)]="this.iotService.thingShadow.state.desired.config.slp">
                    <ng-container *ngFor="let time of this.sleepTime">
                        <option [ngValue]="time.value" [selected]="time.value === this.iotService.thingShadow.state.desired.config.slp">{{ time.label | translate}}</option>
                    </ng-container>
                </select>
            </div>
        </article>
        <!-- Configuration option for Proximity meter trigger -->
        <article class="trigger">
            <div class="card-title mt-0 status">
                <label class="switch col-2">
                    <input type="checkbox" [name]="'active-dst'" [(ngModel)]="this.iotService.thingShadow.state.desired.config.dst" (ngModelChange)="this.iotService.thingShadow.state.desired.config.dst = $event ? 1 : 0">
                    <span [ngClass]="{'active-checkbox': this.iotService.thingShadow.state.desired.config.dst === 1, 'inactive-checkbox': this.iotService.thingShadow.state.desired.config.dst === 0}"  class="slider round"></span>
                </label>
                <label class="toggle col-10">{{ "deviceConfigModalDistance" | translate }}</label>
            </div>
        </article>
        <!-- Configuration option for Environmental Meter trigger -->
        <article class="trigger">
            <div class="card-title mt-0 status">
                <label class="switch col-2">
                    <input type="checkbox" [name]="'active-env'" [(ngModel)]="this.iotService.thingShadow.state.desired.config.env" (ngModelChange)="this.iotService.thingShadow.state.desired.config.env = $event ? 1 : 0">
                    <span [ngClass]="{'active-checkbox': this.iotService.thingShadow.state.desired.config.env === 1, 'inactive-checkbox': this.iotService.thingShadow.state.desired.config.env === 0}"  class="slider round"></span>
                </label>
                <label class="toggle col-10">{{ "deviceConfigModalEnvironmentalMeter" | translate }}</label>
            </div>
        </article>
        <!-- Configuration option for VOC measurement trigger -->
        <article class="trigger">
            <div class="card-title mt-0 status">
                <label class="switch col-2">
                    <input type="checkbox" [name]="'active-voc'" [(ngModel)]="this.iotService.thingShadow.state.desired.config.voc" (ngModelChange)="this.iotService.thingShadow.state.desired.config.voc = $event ? 1 : 0">
                    <span [ngClass]="{'active-checkbox': this.iotService.thingShadow.state.desired.config.voc === 1, 'inactive-checkbox': this.iotService.thingShadow.state.desired.config.voc === 0}"  class="slider round"></span>
                </label>
                <label class="toggle col-10">{{ "deviceConfigModalVOC" | translate }}</label>
            </div>
        </article>
        <!-- Configuration option for 3d image trigger -->
        <article class="trigger">
            <div class="card-title mt-0 status">
                <label class="switch col-2">
                    <input type="checkbox" [name]="'active-img'" [(ngModel)]="this.iotService.thingShadow.state.desired.config.img" (ngModelChange)="this.iotService.thingShadow.state.desired.config.img = $event ? 1 : 0">
                    <span [ngClass]="{'active-checkbox': this.iotService.thingShadow.state.desired.config.img === 1, 'inactive-checkbox': this.iotService.thingShadow.state.desired.config.img === 0}"  class="slider round"></span>
                </label>
                <label class="toggle col-10">{{ "deviceConfigModalImg" | translate }}</label>
            </div>
        </article>
        <!-- Configuration option for LFR thermal camera trigger -->        
        <article class="trigger">
            <div class="card-title mt-0 status">
                <label class="switch col-2">
                    <input type="checkbox" [name]="'active-lfr'" [(ngModel)]="this.iotService.thingShadow.state.desired.config.lfr" (ngModelChange)="this.iotService.thingShadow.state.desired.config.lfr = $event ? 1 : 0">
                    <span [ngClass]="{'active-checkbox': this.iotService.thingShadow.state.desired.config.lfr === 1, 'inactive-checkbox': this.iotService.thingShadow.state.desired.config.lfr === 0}"  class="slider round"></span>
                </label>
                <label class="toggle col-10">{{ "deviceConfigModalLfr" | translate }}</label>
            </div>
        </article>
        <!-- Configuration option for GPS coordinate from GSS trigger -->                
        <article class="trigger">
            <div class="card-title mt-0 status">
                <label class="switch col-2">
                    <input type="checkbox" [name]="'active-gps'" [(ngModel)]="this.iotService.thingShadow.state.desired.config.gps" (ngModelChange)="this.iotService.thingShadow.state.desired.config.gps = $event ? 1 : 0">
                    <span [ngClass]="{'active-checkbox': this.iotService.thingShadow.state.desired.config.gps === 1, 'inactive-checkbox': this.iotService.thingShadow.state.desired.config.gps === 0}"  class="slider round"></span>
                </label>
                <label class="toggle col-10">{{ "deviceConfigModalGps" | translate }}</label>
            </div>
        </article>
        <!-- Configuration option for Get Telecom Carrier Information trigger -->   
        <article class="trigger">
            <div class="card-title mt-0 status">
                <label class="switch col-2">
                    <input type="checkbox" [name]="'active-gtc'" [(ngModel)]="this.iotService.thingShadow.state.desired.config.gtc" (ngModelChange)="this.iotService.thingShadow.state.desired.config.gtc = $event ? 1 : 0">
                    <span [ngClass]="{'active-checkbox': this.iotService.thingShadow.state.desired.config.gtc === 1, 'inactive-checkbox': this.iotService.thingShadow.state.desired.config.gtc === 0}"  class="slider round"></span>
                </label>
                <label class="toggle col-10">{{ "deviceConfigModalGtc" | translate }}</label>
            </div>
        </article>
    </section>

    <!-- Right block containing additional configuration options -->
    <section class="block-right col-7">
        <!-- Configuration option for hibernation temperature -->
        <article class="sleep" [ngClass]="theme.getThemeClass()">
            <label class="labelSleep col-8">{{ "deviceConfigModalHit" | translate }}</label>
            <div class="col-4 inputBlockRight">
                <input type="text" class="hibernationTemp col-12 form-control" [(ngModel)]="this.iotService.thingShadow.state.desired.config.hit">
            </div>
        </article>
        <!-- Configuration option for hibernation time -->
        <article class="sleep" [ngClass]="theme.getThemeClass()">
            <label class="labelSleep col-8">{{ "deviceConfigModalHis" | translate }}</label>
            <div class="col-4 inputBlockRight">
                <select [(ngModel)]="this.iotService.thingShadow.state.desired.config.his" class="col-12 form-control">
                    <ng-container *ngFor="let time of this.sleepTime">
                        <option [ngValue]="time.value" [selected]="time.value === this.iotService.thingShadow.state.desired.config.his">{{ time.label | translate}}</option>
                    </ng-container>
                </select>
            </div>
        </article>
      </section>
      <!-- Navigation for modal actions (cancel and submit) -->
      <nav class="button col-4">
        <button [ngClass]="theme.getThemeClass()" class="btn" (click)="cancel()" type="button" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
        <button [ngClass]="theme.getThemeClass()" class="btn" (click)="onSubmit()" type="submit" class="btn btn-block btnCreate">{{ 'usereditFormLabelConfirm' | translate }}</button>
      </nav>

</div>
<!-- Show in mobile -->

<!-- Main content of the device configuration modal mobile version -->
<div [ngClass]="theme.getThemeClass()" class="small-screen" *ngIf="this.iotService.showDeviceConfigModal">
    <!-- Navigation bar for the device configuration modal -->
    <nav class="title">
        <h3 class="col-8">{{ "deviceConfigModalDeviceConfig" | translate }}</h3><h5 class="model col-4">{{ this.iotService.thingName}}</h5>
    </nav>
    <section>
        <!-- Configuration option for sleep -->
        <article class="sleep" [ngClass]="theme.getThemeClass()">
            <!-- Label for sleep configuration -->
            <label class="labelSleep col-9">{{ "deviceConfigModalSlp" | translate }}</label>
            <div class="col-3">
                <select class="col-12 form-control" [(ngModel)]="this.iotService.thingShadow.state.desired.config.slp">
                    <ng-container *ngFor="let time of this.sleepTime">
                        <option [ngValue]="time.value" [selected]="time.value === this.iotService.thingShadow.state.desired.config.slp">{{ time.label | translate}}</option>
                    </ng-container>
                </select>
            </div>
        </article>
        <!-- Configuration option for hibernation temperature -->
        <article class="sleep" [ngClass]="theme.getThemeClass()">
            <label class="labelSleep col-9">{{ "deviceConfigModalHit" | translate }}</label>
            <div class="col-3">
                <input type="text" class="hibernationTemp col-12 form-control" [(ngModel)]="this.iotService.thingShadow.state.desired.config.hit">
            </div>
        </article>
        <!-- Configuration option for hibernation time -->
        <article class="sleep" [ngClass]="theme.getThemeClass()">
            <label class="labelSleep col-9">{{ "deviceConfigModalHis" | translate }}</label>
            <div class="col-3">
                <select [(ngModel)]="this.iotService.thingShadow.state.desired.config.his" class="col-12 form-control">
                    <ng-container *ngFor="let time of this.sleepTime">
                        <option [ngValue]="time.value" [selected]="time.value === this.iotService.thingShadow.state.desired.config.his">{{ time.label | translate}}</option>
                    </ng-container>
                </select>
            </div>
        </article>
        <!-- Configuration option for Proximity meter trigger -->
        <article class="trigger">
            <div class="card-title mt-0 status">
                <label class="switch col-2">
                    <input type="checkbox" [name]="'active-dst'" [(ngModel)]="this.iotService.thingShadow.state.desired.config.dst" (ngModelChange)="this.iotService.thingShadow.state.desired.config.dst = $event ? 1 : 0">
                    <span [ngClass]="{'active-checkbox': this.iotService.thingShadow.state.desired.config.dst === 1, 'inactive-checkbox': this.iotService.thingShadow.state.desired.config.dst === 0}"  class="slider round"></span>
                </label>
                <label class="toggle col-10">{{ "deviceConfigModalDistance" | translate }}</label>
            </div>
        </article>
        <!-- Configuration option for Environmental Meter trigger -->
        <article class="trigger">
            <div class="card-title mt-0 status">
                <label class="switch col-2">
                    <input type="checkbox" [name]="'active-env'" [(ngModel)]="this.iotService.thingShadow.state.desired.config.env" (ngModelChange)="this.iotService.thingShadow.state.desired.config.env = $event ? 1 : 0">
                    <span [ngClass]="{'active-checkbox': this.iotService.thingShadow.state.desired.config.env === 1, 'inactive-checkbox': this.iotService.thingShadow.state.desired.config.env === 0}"  class="slider round"></span>
                </label>
                <label class="toggle col-10">{{ "deviceConfigModalEnvironmentalMeter" | translate }}</label>
            </div>
        </article>
        <!-- Configuration option for VOC measurement trigger -->
        <article class="trigger">
            <div class="card-title mt-0 status">
                <label class="switch col-2">
                    <input type="checkbox" [name]="'active-voc'" [(ngModel)]="this.iotService.thingShadow.state.desired.config.voc" (ngModelChange)="this.iotService.thingShadow.state.desired.config.voc = $event ? 1 : 0">
                    <span [ngClass]="{'active-checkbox': this.iotService.thingShadow.state.desired.config.voc === 1, 'inactive-checkbox': this.iotService.thingShadow.state.desired.config.voc === 0}"  class="slider round"></span>
                </label>
                <label class="toggle col-10">{{ "deviceConfigModalVOC" | translate }}</label>
            </div>
        </article>
        <!-- Configuration option for 3d image trigger -->
        <article class="trigger">
            <div class="card-title mt-0 status">
                <label class="switch col-2">
                    <input type="checkbox" [name]="'active-img'" [(ngModel)]="this.iotService.thingShadow.state.desired.config.img" (ngModelChange)="this.iotService.thingShadow.state.desired.config.img = $event ? 1 : 0">
                    <span [ngClass]="{'active-checkbox': this.iotService.thingShadow.state.desired.config.img === 1, 'inactive-checkbox': this.iotService.thingShadow.state.desired.config.img === 0}"  class="slider round"></span>
                </label>
                <label class="toggle col-10">{{ "deviceConfigModalImg" | translate }}</label>
            </div>
        </article>
        <!-- Configuration option for LFR thermal camera trigger -->        
        <article class="trigger">
            <div class="card-title mt-0 status">
                <label class="switch col-2">
                    <input type="checkbox" [name]="'active-lfr'" [(ngModel)]="this.iotService.thingShadow.state.desired.config.lfr" (ngModelChange)="this.iotService.thingShadow.state.desired.config.lfr = $event ? 1 : 0">
                    <span [ngClass]="{'active-checkbox': this.iotService.thingShadow.state.desired.config.lfr === 1, 'inactive-checkbox': this.iotService.thingShadow.state.desired.config.lfr === 0}"  class="slider round"></span>
                </label>
                <label class="toggle col-10">{{ "deviceConfigModalLfr" | translate }}</label>
            </div>
        </article>
        <!-- Configuration option for GPS coordinate from GSS trigger -->                
        <article class="trigger">
            <div class="card-title mt-0 status">
                <label class="switch col-2">
                    <input type="checkbox" [name]="'active-gps'" [(ngModel)]="this.iotService.thingShadow.state.desired.config.gps" (ngModelChange)="this.iotService.thingShadow.state.desired.config.gps = $event ? 1 : 0">
                    <span [ngClass]="{'active-checkbox': this.iotService.thingShadow.state.desired.config.gps === 1, 'inactive-checkbox': this.iotService.thingShadow.state.desired.config.gps === 0}"  class="slider round"></span>
                </label>
                <label class="toggle col-10">{{ "deviceConfigModalGps" | translate }}</label>
            </div>
        </article>
        <!-- Configuration option for Get Telecom Carrier Information trigger -->   
        <article class="trigger">
            <div class="card-title mt-0 status">
                <label class="switch col-2">
                    <input type="checkbox" [name]="'active-gtc'" [(ngModel)]="this.iotService.thingShadow.state.desired.config.gtc" (ngModelChange)="this.iotService.thingShadow.state.desired.config.gtc = $event ? 1 : 0">
                    <span [ngClass]="{'active-checkbox': this.iotService.thingShadow.state.desired.config.gtc === 1, 'inactive-checkbox': this.iotService.thingShadow.state.desired.config.gtc === 0}"  class="slider round"></span>
                </label>
                <label class="toggle col-10">{{ "deviceConfigModalGtc" | translate }}</label>
            </div>
        </article>
    </section>
    <!-- Navigation for modal actions (cancel and submit) -->
    <nav class="form-group button col-12">
        <button [ngClass]="theme.getThemeClass()" class="btn" (click)="cancel()" type="button" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
        <button [ngClass]="theme.getThemeClass()" class="btn" (click)="onSubmit()" type="submit" class="btn btn-block btnCreate">{{ 'usereditFormLabelConfirm' | translate }}</button>
    </nav>
</div>
