import { filter } from 'rxjs';
import { Component, Input, OnInit, Renderer2, ChangeDetectorRef, ElementRef, Inject, forwardRef } from '@angular/core'; //EventEmitter,
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../service/theme.service';
import { CognitoService } from '../service/cognito.service';
import { environment } from '../environments/environment';
import { navigation } from '../constants/navigation';
import { error } from 'jquery';
import { NavigationService } from '../service/navigation.service';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  public selectedLanguage: string = "";
  public currentLanguage:string = "";
  languageStatus:string = this.localStorageService.getItem('language');

  // Input properties to control the status of side navigation and small screens
  @Input() sideNavStatus: boolean = false;
  @Input() smallScreenStatus: boolean = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private renderer: Renderer2,
    private el: ElementRef,
    public  theme: ThemeService,
    public cognito: CognitoService,
    public nav: NavigationService
    ) {
      // Check if the user has selected a language in local storage
      //or use a default language
      if (this.languageStatus == null){
        // Set the default language to French
        translate.use('fr');
      } else {
        // Set the default language to the user's selected language
        translate.use(this.languageStatus);
      }

    }

  // Store the selected menu item
  selectedMenu: string = '';

  async ngOnInit() {
    this.updateTranslationsOnLanguageChange();
    await this.nav.filterList()
  }


  // Change language based on user selection
  changeLanguage(language: string) {
    this.translate.use(language);
  }

  // Set the selected menu item
  selectedMenuFunction(item: string) {
    // Assign the selected menu item to the 'selectedMenu' variable
    this.selectedMenu = item;
  }

  // Update translations for menu items
  updateTranslationsOnLanguageChange() {
    this.translate.onLangChange.subscribe(() => {
      // Update translation for 'HOME' menu item
      this.translate.get('sidenavMenu.HOME').subscribe((translationHome: string) => {
        this.nav.list[0].name = translationHome;
      });

      // Update translation for 'ACCOUNTS' menu item
      this.translate.get('sidenavMenu.CLIENTS').subscribe((translationAccounts: string) => {
        this.nav.list[1].name = translationAccounts;
      });

      // Update translation for 'LOGS' menu item
      this.translate.get('sidenavMenu.IOT').subscribe((translationLogs: string) => {
        this.nav.list[3].name = translationLogs;
      });

      // Update translation for 'CONFIGS' menu item
      this.translate.get('sidenavMenu.ZONES').subscribe((translationConfigs: string) => {
        this.nav.list[4].name = translationConfigs;
      });

      // Force change detection to update the view
      this.cdRef.detectChanges();
    });
  }

  isDashboard(){
    // Get the complete URL of the current page
    const url = window.location.href;

    // Check if the URL includes the string 'dashboard'
    // This indicates whether the current page is the login page
    return url.includes('dashboard')
 }

}
