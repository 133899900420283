import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LegendPosition, NgxChartsModule } from '@swimlane/ngx-charts';
import { ColorHelper } from '@swimlane/ngx-charts';
import { ScaleType } from '@swimlane/ngx-charts';
import { firstValueFrom } from 'rxjs';
import { ReportsService } from './reports.service';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  //Pie Chart//
  singlePieChart: any[] =  [
    {
      "name": "",
      "value": 1
    },
    {
      "name": "",
      "value": 29
    },

  ];
  totalPieChart: number = 0;
  viewPieChart: [number, number] = [450, 300];

  // options
  gradientPieChart: boolean = true;
  showLegendPieChart: boolean = true;
  showLabelsPieChart: boolean = false;
  isDoughnutPieChart: boolean = false;
  legendPositionPieChart: LegendPosition = LegendPosition.Below;

  colorSchemePieChart = {
    name: "",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#5AA454', '#A10A28', 'lightgrey']
  };


  //Pie Chart functions
  onSelectPieChart(data:any): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivatePieChart(data:any): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivatePieChart(data:any): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }


  // pie chart multiple slice
  singlePieChartSlice: any[] = [
    {
      "name": "",
      "value": 0
    },
    {
      "name": "",
      "value": 0
    },
  ];
  viewPieChartSlice: [number, number] = [625, 275];

  // options
  gradientPieChartSlice: boolean = true;
  showLegendPieChartSlice: boolean = true;
  showLabelsPieChartSlice: boolean = false;
  isDoughnutPieChartSlice: boolean = false;
  legendPositionPieChartSlice: LegendPosition = LegendPosition.Right;
  legendTitlePieChartSlice: string = "Legend";

  colorSchemePieChartSlice = {
    name: "",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#28a745', '#007bff', '#ffc107', '#dc3545', '#e83e8c', '#ff8c00', '#c8e6c9', '#17a2b8']
    // domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  // Pie chatr slice functions
  onSelectPieChartSlice(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivatePieChartSlice(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivatePieChartSlice(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }


  //Bar Chart//
  singleBarChart: any[]= [
    {
      "name": "",
      "value": 0
    }
  ];

  multiBarChart: any[] = [
    {
      "name": "",
      "series": [
        {
          "name": "",
          "value": 0
        },
        {
          "name": "",
          "value": 0
        }
      ]
    }
  ];

  viewBarChart: [number,number] = [400, 250];

  // options
  showXAxisBarChart = false;
  showYAxisBarChart = true;
  gradientBarChart = false;
  showLegendBarChart = true;
  showXAxisLabelBarChart = false;
  xAxisLabelBarChart = '';
  showYAxisLabelBarChart = false;
  yAxisLabelBarChart = '';
  legendPositionBarChart: LegendPosition = LegendPosition.Below;
  legendTitleBarChart: string = "Legend";

  colorSchemeBarChart = {
    name: "",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#5AA454', '#6699CC', '#AAAAAA']
  };

  //Bar Chart Functions
  onSelectBarChart(event:any) {
    console.log(event);
  }

  //Bar Chart Collections//
  singleBarChartCollections: any[]= [
    {
      "name": "",
      "value": 0
    }
  ];

  multiBarChartCollections: any[] = [
    {
      "name": "",
      "series": [
        {
          "name": "",
          "value": 0
        },
        {
          "name": "",
          "value": 0
        }
      ]
    }
  ];

  viewBarChartCollections: [number,number] = [650, 450];

  // options
  showXAxisBarChartCollections = false;
  showYAxisBarChartCollections = true;
  gradientBarChartCollections = false;
  showLegendBarChartCollections = true;
  showXAxisLabelBarChartCollections = false;
  xAxisLabelBarChartCollections = '';
  showYAxisLabelBarChartCollections = false;
  yAxisLabelBarChartCollections = '';
  legendPositionBarChartCollections: LegendPosition = LegendPosition.Below;

  colorSchemeBarChartCollections = {
    name: "",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#5AA454', '#6699CC', '#AAAAAA']
  };

  //Bar Chart Functions
  onSelectBarChartCollections(event:any) {
    console.log(event);
  }

   //Bar Chart Admin//

   //Chart Data
   singleBarChartAdmin: any[]= [
    {
      "name": "",
      "value": 0
    }
  ];

  multiBarChartAdmin: any[] = [
    {
      "name": "",
      "series": [
        {
          "name": "",
          "value": 0
        },
        {
          "name": "",
          "value": 0
        }
      ]
    }
  ];

  //View Dimensions
  viewBarChartAdmin: [number,number] = [500, 250];

  // options
  showXAxisBarChartAdmin = true;
  showYAxisBarChartAdmin = true;
  gradientBarChartAdmin = false;
  showLegendBarChartAdmin = true;
  showXAxisLabelBarChartAdmin = true;
  xAxisLabelBarChartAdmin = '';
  showYAxisLabelBarChartAdmin = false;
  yAxisLabelBarChartAdmin = '';
  legendPositionBarChartAdmin: LegendPosition = LegendPosition.Below;
  legendTitleBarChartAdmin: string = "Legend";


  colorSchemeBarChartAdmin = {
    name: "",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#5AA454', '#6699CC', '#AAAAAA', '#aa03fc']
  };

  //Bar Chart Admin functions
  onSelectBarChartAdmin(event:any) {
    console.log(event);
  }

  //Line Chart Functions
 // options
 showXAxisLineChartAdmin = false;
 showYAxisLineChartAdmin = true;
 gradientLineChartAdmin = false;
 showLegendLineChartAdmin = true;
 showXAxisLabelLineChartAdmin = false;
 xAxisLabelLineChartAdmin = '';
 showYAxisLabelLineChartAdmin = true;
 yAxisLabelLineChartAdmin = 'Distance From Device';
 legendPositionLineChartAdmin: LegendPosition = LegendPosition.Below;

   //View Dimensions
   viewLineChartAdmin: [number,number] = [1000, 450];

   singleLineChart: any[] = [{
    "name": "",
    "series": [{
      "name": "",
      "value": ""
    }]
   }];


  colorSchemeLineChart = {
    name: "",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#5AA454', '#6699CC', '#AAAAAA', '#aa03fc']
  };

  dates: any[] = [];

  public yAxisArray: any[] = []

  onSelectLineChart(data:any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivateLineChart(data:any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivateLineChart(data:any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  // Function to format timestamps if needed
  formatTimestamp(timestamp: number): string {
    const date = new Date(timestamp);


    // Customize the formatting as needed, e.g., show only months
   // return date.toLocaleDateString('en-US', {day:'numeric', month: 'short', year: 'numeric' });
    return '';
  }

  //UI Functions
  adjustView(currentScreenWidth: number, currentScreenHeight: number){
    const widthPercentage = currentScreenWidth / 100;
    const heightPercentage = currentScreenHeight / 100;

    this.showLegendBarChart = true;
    this.showLegendBarChartAdmin = true;
    this.showXAxisBarChart = false;
    this.showLegendPieChartSlice = true;

    switch(true){
      case(currentScreenWidth > 1150):
        this.viewPieChart = [widthPercentage * 35, heightPercentage * 28];
        this.viewBarChart = [widthPercentage * 32, heightPercentage * 20];
        this.viewBarChartAdmin = [widthPercentage * 32, heightPercentage * 20];
        this.viewPieChartSlice = [widthPercentage * 33, heightPercentage * 28];
        break;
      case (currentScreenWidth <= 1150) && (currentScreenWidth > 916):
        this.viewPieChart = [widthPercentage * 35, heightPercentage * 28];
        this.viewBarChart = [widthPercentage * 32, heightPercentage * 20];
        this.viewBarChartAdmin = [widthPercentage * 32, heightPercentage * 20];
        this.viewPieChartSlice = [widthPercentage * 33, heightPercentage * 28];
        this.showLegendPieChartSlice = false;
        break;
      case (currentScreenWidth <= 915) && (currentScreenWidth > 800):
        this.viewPieChart = [widthPercentage * 60, heightPercentage * 60];
        this.viewBarChart = [widthPercentage * 60, heightPercentage * 45];
        this.viewBarChartAdmin = [widthPercentage * 60, heightPercentage * 45];
        this.viewPieChartSlice = [widthPercentage * 30, heightPercentage * 60];
        this.showLegendPieChartSlice = false;
        break;
      case (currentScreenWidth <= 800) && (currentScreenWidth > 576):
        this.viewPieChart = [widthPercentage * 66, heightPercentage * 28];
        this.viewBarChart = [widthPercentage * 50, heightPercentage * 28];
        this.viewBarChartAdmin = [widthPercentage * 50, heightPercentage * 28];
        this.viewPieChartSlice = [widthPercentage * 30, heightPercentage * 28];
        this.showLegendBarChart = false;
        this.showLegendBarChartAdmin = false;
        this.showLegendPieChartSlice = false;
        this.showLegendPieChart = false;
        break;
      case (currentScreenWidth <= 575) && (currentScreenWidth >= 501):
        this.viewPieChart = [widthPercentage * 60, heightPercentage * 28];
        this.viewBarChart = [widthPercentage * 60, heightPercentage * 28];
        this.viewBarChartAdmin = [widthPercentage * 60, heightPercentage * 28];
        this.viewPieChartSlice = [widthPercentage * 30, heightPercentage * 28];
        this.showLegendBarChart = false;
        this.showLegendBarChartAdmin = false;
        this.showLegendPieChartSlice = false;
        this.showLegendPieChart = false;
        break;
      case (currentScreenWidth <= 500):
        this.viewPieChart = [widthPercentage * 60, heightPercentage * 20];
        this.viewBarChart = [widthPercentage * 60, heightPercentage * 20];
        this.viewBarChartAdmin = [widthPercentage * 60, heightPercentage * 20];
        this.viewPieChartSlice = [widthPercentage * 30, heightPercentage * 20];
        this.showLegendBarChart = false;
        this.showLegendBarChartAdmin = false;
        this.showLegendPieChartSlice = false;
        this.showLegendPieChart = false;
        break;
      case (currentScreenWidth <= 400):
          this.viewPieChart = [widthPercentage * 60, heightPercentage * 18];
          this.viewBarChart = [widthPercentage * 60, heightPercentage * 20];
          this.viewBarChartAdmin = [widthPercentage * 50, heightPercentage * 10];
          this.viewPieChartSlice = [widthPercentage * 30, heightPercentage * 18];
          this.showLegendBarChart = false;
          this.showLegendBarChartAdmin = false;
          this.showLegendPieChartSlice = false;
          this.showLegendPieChart = false;
          break
    }


  }



  // Function to format Y-axis ticks as percentages
 formatYAxisTicks(value: number): string {

    // Format the percentage with a fixed number of decimal places
    return value + '%';
 }

  getDateLanguage(){

    let dateLanguage = 'fr-CA'
    const language = localStorage.getItem('language')?.toString().replace(/"/g, "")
    switch(language){
      case 'en':
          dateLanguage = 'en-CA';
        break;
      case 'fr':
          dateLanguage = 'fr-CA'
        break;
      case 'es':
          dateLanguage = 'es-MX'
        break;
    }
    return dateLanguage;
  }

  //Returns a translated label for charts
  async getChartLabel(key: string){
    const label:string = await firstValueFrom(this.translate.get(key));
    return label;
  }

  constructor(private translate: TranslateService,
              private reports: ReportsService) {

  }

  translateChartsLegends() {
    switch(this.getDateLanguage()){ 
      case "en-CA":
        this.legendTitlePieChartSlice = 'Status';
        this.legendTitleBarChart = 'Fill Level';
        this.legendTitleBarChartAdmin = 'User Types'
        break;
      case "es-MX":
        this.legendTitlePieChartSlice = 'Estado';
        this.legendTitleBarChart = 'Nivel de Llenado';
        this.legendTitleBarChartAdmin = 'Tipo de usuario'
        break;
      default:
        this.legendTitlePieChartSlice = 'Statut';
        this.legendTitleBarChart = 'Niveau de Remplissage';
        this.legendTitleBarChartAdmin = 'Type d\'utilisateur'
        break; 
    }
  }
}
