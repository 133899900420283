import { Injectable } from '@angular/core';
import { Bin } from './bins.service';
import { Subject } from 'rxjs';
import { ISOProvince } from '../constants/ISO-province';
import { ISOCountry } from '../constants/ISO-country';

@Injectable({
  providedIn: 'root'
})
export class ModalAlertService {

  // Variable used to show the modal
  public showAlertDelete: boolean = false;

  // Variable used to show witch modal to use
  public deleteBin: boolean = false;
  public deleteBinModel: boolean = false;
  public deleteTaxeType: boolean = false;
  public deleteOperator: boolean = false;
  public deleteLocation: boolean = false;

  // Variables used for bin and bin model
  public bin_id: string = '';
  public bin_address: string = '';
  public bin_model_id: string = '';
  public bin_model_number: string = '';

  // Variable used to get the active status
  public active: number = 0;

  // Variable used for taxe type
  public taxe_type_id: string = '';
  public taxe_name: string = '';
  public region: string = '';
  public regionArray: any[] = [...ISOProvince, ...ISOCountry];

  // Variable used for Operator
  public operator_id: string = '';
  public operator_legal_name: string = '';

  // Variable used for Locations
  public location_id: string = '';
  public location: string = '';

  // Variable used for parent component to subscribe at cancel button on modal
  private cancel = new Subject<void>;
  cancel$ = this.cancel.asObservable();

  // Variable used by component-list when confirm active/deactive in there update component
  private confirmBin = new Subject<void>; // Used by bin-list
  confirmBin$ = this.confirmBin.asObservable();
  private confirmBinModel = new Subject<void>; // Used by bin-model-list
  confirmBinModel$ = this.confirmBinModel.asObservable();
  private confirmTaxeType = new Subject<void>; // Used by taxe-type-list
  confirmTaxeType$ = this.confirmTaxeType.asObservable();
  private confirmOperator = new Subject<void>; // Used by operator-list
  confirmOperator$ = this.confirmOperator.asObservable();
  private confirmLocation = new Subject<void>; // Used by location-list
  confirmLocation$ = this.confirmLocation.asObservable();


  constructor() {

  }

  // Function called to initialize all HTML variables
  initAlert(){
    this.deleteBin = false;
    this.deleteBinModel = false;
    this.deleteTaxeType = false;
    this.deleteOperator = false;
    this.deleteLocation = false;
  }

  // Function triggered by checkbox in bin-update then press confirm change in the modal alert component
  deactiveBin(bin_id: string, bin_address: string, bin_active: number){
    // Variable that trigger the HTML to show
    this.deleteBin = true;

    // Call a function that invert active 0 or 1
    this.invertActive(bin_active);

    // Set local variable needed for update
    this.bin_id = bin_id;
    this.bin_address = bin_address;
  }

  // Function triggered by checkbox in bin-model-update then press confirm change in the modal alert component
  deactiveBinModel(bin_model_id: string, bin_model_number: string, bin_active: number){
    // Variable that trigger the HTML to show
    this.deleteBinModel = true;
    // Call a function that invert active 0 or 1
    this.invertActive(bin_active);

    // Set local variable needed for update
    this.bin_model_id = bin_model_id;
    this.bin_model_number = bin_model_number;
  }

  deactivetaxeType(taxe_type_data: any){
    // Variable that trigger the HTML to show
    this.deleteTaxeType = true;
    // Call a function that invert active 0 or 1
    this.invertActive(taxe_type_data.active);
    // Set local variable needed for update
    this. taxe_type_id = taxe_type_data.taxe_type_id;
    this.taxe_name = taxe_type_data.taxe_name;
    for(let i = 0; i < this.regionArray.length; i ++){
      if(this.regionArray[i].value === taxe_type_data.ISO_code){
        this.region = this.regionArray[i].label;
      }
    }
  }

  // Function triggered by checkbox in operator-update to change the active status of an operator
  deactiveOperator(operator_id: string, operator_legal_name: string, active: string){
    // Variable that trigger the HTML to show
    this.deleteOperator = true;
    // Call the function thet invert the active 0 or 1
    this.invertActive(parseInt(active));

    // Set the local variable needed for update
    this.operator_id = operator_id;
    this.operator_legal_name = operator_legal_name;
  }

  // Function called by checkbox in location-update to change the active status of a location
  deactiveLocation(location_id: string, location:string, active: number){
    // Variable that trigger the HTML to show
    this.deleteLocation = true;
    //console.log(active);
    // Call the function thet invert the active 0 or 1
    this.invertActive(active);
    // Set the local variable needed for update
    this.location_id = location_id;
    this.location = location;
  }

  // Function that cancel the active o r deactive change on both bin and bin-model
  cancelActive(){
    // Put all variables that trigger the show/hide HTML to false
    this.deleteBin = false;
    this.deleteBinModel = false;
    this.deleteTaxeType = false;
    this.deleteLocation = false;
    this.showAlertDelete = false;

    // Invert the bin
    this.invertActive(this.active);
    this.cancel.next();
  }

  // Function used by the modal for bin and bin model to confirm change on active status
  confirmActive(){
    // Close the modal
    this.showAlertDelete = false;

    switch(true){
      case this.deleteBin:
        this.confirmBin.next(); // Trigger the function in bin-update to made the change on bin.service
        break;
      case this.deleteBinModel:
        this.confirmBinModel.next(); // Trigger the function in bin-model-update to made the change on bin.service
        break;
      case this.deleteTaxeType:
        this.confirmTaxeType.next(); // Trigger the function in taxe-type-update to made the change on taxeType.service
        break;
      case this.deleteOperator:
        this.confirmOperator.next(); // Trigger the function in operator-update to made the change on operator.service
        break;
      case this.deleteLocation:
        this.confirmLocation.next(); // Trigger the function in location-update to made the change on location.service
        break;
    }
  }

  // Function that received the active status of bin or bin model and revert it
  invertActive(active: number){
    if(active === 1){
      this.active = 0;
    }else{
      this.active = 1;
    }
  }
}
