import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeService } from '../service/theme.service';
import { FilterService } from '../service/filter.service';
import { EntitiesLocationsService, Locations } from '../service/entities-locations.service';
import { ClientService } from '../service/client.service';
import { DistributorsService } from '../service/distributors.service';
import { OperatorService } from '../service/operator.service';

export interface EntityIdType {
  entity_id: string;
  type: string;
  leagel_name: string;
}

@Component({
  selector: 'app-entities-location-list',
  templateUrl: './entities-location-list.component.html',
  styleUrl: './entities-location-list.component.css'
})
export class EntitiesLocationListComponent implements OnInit{
  public mainContent: string = 'locations';
  public userFilter: string = '';

  constructor(public theme: ThemeService,
              public filter: FilterService,
              public entitiesLocationService: EntitiesLocationsService,
              private clientService: ClientService,
              private distributorService: DistributorsService,
              private operatorService: OperatorService){

  }

  async ngOnInit() {
    await this.clientService.getClientsForSelect();
    await this.distributorService.getDistributorForSelect();
    await this.operatorService.getOperator();

    console.log(this.distributorService.distributorsArray);
    await this.initList();

    // Function that is automatically trigger used by bin service when there is a change made from bin-create or bin-update component
    this.entitiesLocationService.changeMade$.subscribe(async () => {
      // Function called to init the list
      this.initList();
    });

    this.entitiesLocationService.noChangeMade$.subscribe(async () => {

    });
  }

  // Function to initialize the list
  async initList(){
    await this.entitiesLocationService.getEntitiesLocations();
    this.entitiesLocationService.initLocation();
    this.entitiesLocationService.location_detail_array.splice(0, this. entitiesLocationService.location_detail_array.length);
    this.getEntitiesLocationDetailArray();
  }

  getEntitiesLocationDetailArray(){
    // Loop through the location array to implement a new array for UI display
    for(let location of this.entitiesLocationService.location_array){
      // Reset local_location_data
      this.entitiesLocationService.initLocation();

      // Set the new location_data for the implementation of the location_detail_array
      this.entitiesLocationService.location_data.location_id = location.location_id;
      this.entitiesLocationService.location_data.location = location.locations;
      this.entitiesLocationService.location_data.entity_id = location.entity_id;
      this.entitiesLocationService.location_data.entity_type = location.entity_type;
      this.entitiesLocationService.location_data.active = location.active;

      // check witch one is the entity type to get the proper entity legal_name
      switch(location.entity_type){
        case 'C':
          const client = this.clientService.clientsArray.find((client: any) => client.client_id === location.entity_id);
          this.entitiesLocationService.location_data.entity_legal_name = client.legal_name;
          break;

        case 'D':
          const distributor = this.distributorService.distributorsArray.find((distributor: any) => distributor.distributor_id === location.entity_id);
          this.entitiesLocationService.location_data.entity_legal_name = distributor.legal_name;
          break;

        case 'O':
          const operator = this.operatorService.operator_array.find(operator => operator.operator_id === location.entity_id);
          if(operator){
            this.entitiesLocationService.location_data.entity_legal_name = operator?.legal_name;
          }
          break;
      }
      // Push the location_data into the detail array
      this.entitiesLocationService.location_detail_array.push(this.entitiesLocationService.location_data);
    }
    // Set the detail arry for the filter
    this.entitiesLocationService.old_location_details_array = this.entitiesLocationService.location_detail_array;
  }
}
