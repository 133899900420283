import { Component } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { Bin, BinsService } from '../service/bins.service';
import { ValidationService } from '../service/validation.service';
import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalizationService } from '../service/localization.service';
import { ModalAlertService } from '../service/alert-modal.service';
import { ThemeService } from '../service/theme.service';
import { CognitoService } from '../service/cognito.service';
import { SystemMessageService } from '../service/system-message.service';
import { environment } from '../environments/environment';
import { MarketSegment } from '../constants/market-segments';
import { RoleService } from '../service/role.service';
import { IotService } from '../service/iot.service';
import { DashboardService } from '../service/dashboard.service';

interface Device {
  thing_name: string;
}

@Component({
  selector: 'app-bin-update',
  templateUrl: './bin-update.component.html',
  styleUrls: ['./bin-update.component.css', '../../global-elements.css']
})
export class BinUpdateComponent {

  lastUrl:string = this.localStorageService.getItem('lastUrl')

  public MarketSegment = MarketSegment;

   // Variable used when user change the address field
   public addressChange: boolean = false;

   // Variable used for the validation of the update
   validateCreate: boolean = false;

   // Variable used for the selected option of the bin-usage component
   public binUsageSelected: string = '';

   // Variables used by html to throw error messages
   public showErrorMessageEmptyInput: boolean = false;
   private systemMessage: string = '';

   // Variable for bin arrays
   public bin_array: any;
   public bin_model_array: any;

   private bin_id: string = '';

   public availableDevices: any[] = [];

   // Variables used for the client assiciate to the bin
    public associatedClientId: string = '';
    public thing_name: string = '';
    public redirectToCLientList: boolean = false;
    public redirectToBinList: boolean = false;
    public originalBinName: string = "";

   constructor(public bin: BinsService,
              public iotService: IotService,
               public validationService: ValidationService,
               private router: Router,
               private localization: LocalizationService,
               private activatedRoute: ActivatedRoute,
               public alert: ModalAlertService,
               public cognitoService: CognitoService,
               public theme: ThemeService,
               private systemMessageService: SystemMessageService,
               private roleService: RoleService,
               private localStorageService: LocalStorageService,
              public dashboardService: DashboardService,
    ){

   }

   async ngOnInit(): Promise<void> {

    // preparing bin names list - this.bin.binsNameList - to be used on duplicated bin name validation
    await this.bin.generateBinNamesList();

    (await this.dashboardService.getRelationships()).subscribe(() => {

      this.availableDevices = this.dashboardService.relationships.filter(item => item.bin_id === '' && item.client_id === 'CL4ccb363d4cd095d852');

      // Use the map method to create a new array with just the values ​​of the thing_name property
      const thingNames: string[] = this.availableDevices.map((device: Device) => device.thing_name);

      this.availableDevices = thingNames;
    
    });

    // Remove the 'lastUrl' item from the localStorage
    this.localStorageService.removeItem('lastUrl');

    // Switch statement based on the value of 'lastUrl'
    switch (this.lastUrl) {
      // If 'lastUrl' is "deviceList"
      case "deviceList":
        // Activate the "clients" state
        this.redirectToCLientList = true;
        this.redirectToBinList = false;
      break;
      // If 'lastUrl' is "binList"
      case "binList":
        // Activate the "binList" state
        this.redirectToCLientList = false;
        this.redirectToBinList = true;
      break;

      // For any other value of 'lastUrl'
      default:
        // Deactivate both states
        this.redirectToCLientList = false;
        this.redirectToBinList = false;
      break; 
    }

    await this.bin.getBinsModel();
    // Get the param that was pass from the bin-list of the bin_id selected
    this.activatedRoute.params.subscribe(params => {
      this.bin_id = params['id']; // Set bin_model_id to the param:id receive whit url
    });

    // Get the selected bin
    await this.bin.getBinById(this.bin_id).then(async (response) => {
      this.bin_array = response;
      this.bin.bin_data = this.bin_array[0];
      this.originalBinName = this.bin.bin_data.bin_name;
    });

    await this.iotService.getThingListByClient(this.bin.bin_data.client_id).then(async (response) => {
      console.log(response)
    });

    const deviceList = await this.iotService.getThingListByClient(this.bin.bin_data.client_id)

    // Set cognito service user type and if it's a valid user
    await this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();

    // Get current user roles from DB
    await this.roleService.getRoles();

    // Check witch user type is the current user and get only infos he can associate bins
    switch(this.cognitoService.userType){
      // Remove the break because each one of hte two user type get the client list
      case 'muirwood':
      case 'distributor':
        // Distributor user will be abble to associate a bin to his own distributor ID or clients
        await this.cognitoService.getClientsInfos();
        break;
    }
    // Check if the user is
    this.cognitoService.getCurrentRole([environment.users.role.administrator], [environment.users.superAdmin, environment.users.standardUser], true, this.roleService.roles);

    if(this.bin.bin_data.thing_name !== ''){
      this.thing_name = this.bin.bin_data.thing_name;
      // Set a variable oldThingName in case of device change from the user
      this.bin.oldThingName = this.thing_name;
    }

    // Set the caller for localization service so he know who will call him
    this.localization.caller = 'bin-create';
    this.localization.initAutocomplete();

    // Subscribe to bin_usage component when user change the select inside this component to get the value of it
    this.bin.selectedTypeOfWaste$.subscribe(() => {
      this.binUsageSelected = this.bin.typeOfWaste; // Set is own value of bin-usage
    });

    // Function trigger when user get alerted if he change active status then click on cancel
    this.alert.cancel$.subscribe(() => {
      let checkboxStatus = document.getElementById('checkboxStatus') as HTMLInputElement; // Set the a html variable of the checkbox for the status
      this.bin.bin_data.active = this.alert.active; // Set the bin_model_data active status to the good value

      // Will reset the active status to what it was before the alert was throw then set the checkbox to the proper value
      if(this.alert.active === 1 && checkboxStatus){
        checkboxStatus.checked = true;
      } else if(this.alert.active === 0 && checkboxStatus){
        checkboxStatus.checked = false;
      }
    });

    // Function automatically trigger when user click on confirm into the alert modal
    this.alert.confirmBin$.subscribe(async () => {
      this.bin.bin_data.active = this.alert.active; // Set the bin_model_data active status to the good value
    });
   }

   // Checks if the provided bin name already exists in the list of bin names.
   isTrashBinNameDuplicated(binName: string) {

      // Check if the list of bin names is not an array
      if (!Array.isArray(this.bin.binsNameList)) {
        console.error('The list of bin names is not an array.');
        return false;
      }
    
      // Checks if the bin_name already exists in the list
      return this.bin.binsNameList.includes(binName);

    }

  async onSubmit(){
    // Create a variable whit the current date
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'yyyyMMdd', 'en_US');

    // Set created and modified date to current date, modified is required event if it's not so the query won't break
    this.bin.bin_data.modified = parseInt(formattedDate, 10);
    // If user change the address input the localization will be check and set back
    if(this.addressChange){
      // Set bin_location to the variable in localization service that google return
      this.bin.bin_data.bin_location = this.localization.autoCompletionReturnGpsLocation;
      this.bin.bin_data.bin_address = this.localization.autoCompletionReturnAddress;
      this.bin.bin_data.bin_postal_code = this.localization.autoCompletionReturnPostalCode;
    }

    // Call the fonction to see if there is value on each inputs
    this.checkValidation();

    if(this.validateCreate){
      // If there's device related to the bin model, set the thing_name in iotService then call the fonction to update the device association
      this.bin.thing_name = this.thing_name;
      this.bin.distributor_id = this.bin.selectedBinModel?.distributor_id || '';
      // Call the function that create the bin in bin service
      await this.bin.updateBin();

      switch (true) {
        case this.redirectToCLientList:
          this.router.navigate(['client-thing-list']);
        break;
        
        case this.redirectToBinList:
          this.router.navigate(['client-bin-dashboard']);
        break;

        case this.cognitoService.userType === 'muirwood' && !this.redirectToCLientList:
          this.returnToAdmin();
        break;

        default:
          this.returnClientBinList();
        break;
      }

    } else {
      // If system message have nothing it put a generic message
      if(this.systemMessage === ''){
        this.systemMessage = 'alert-danger-generic-message';
      }
      // Set the system message service to display the error to the user
      this.systemMessageService.selectRibbon('danger', this.systemMessage);
    }
  }

  // Function in addition of validation service that put the variable validateCreate at rue if all variables are properly implemented
  checkValidation(){
    let notDuplicatedBinName = false;

    // Checks if bin name has been changed
    switch(true) {
      // if bin name still the same - avoid duplicate bin name validation
      case this.originalBinName === this.bin.bin_data.bin_name:      
  
        // Check if all required variables are not empty and validation errors are not present
        if(this.bin.bin_data.bin_name!=='' && this.bin.bin_data.bin_model_id !== '' && this.bin.bin_data.bin_usage !== '' && this.bin.bin_data.market_segment !== '' && this.bin.bin_data.client_id !== '' &&
          (this.validationService.validationErrorArray[0] === '' || this.validationService.validationErrorArray[0] === undefined))           {

            // Perform additional validation for the client ID
            this.ClientIdValidation();

          } else {
            // Display an error message if any required variable is empty or validation errors are present
            this.systemMessage = 'binCreateViewAlertEmptyInput';
          }

      break;

      // if bin name has been changed
      case this.originalBinName !== this.bin.bin_data.bin_name:

        // Check if the bin name is not duplicated
        if(!this.isTrashBinNameDuplicated(this.bin.bin_data.bin_name)) {
          notDuplicatedBinName = true;

          // Check if all required variables are not empty and validation errors are not present
          if(notDuplicatedBinName && this.bin.bin_data.bin_name!=='' && this.bin.bin_data.bin_model_id !== '' && this.bin.bin_data.bin_usage !== '' && this.bin.bin_data.market_segment !== '' && this.bin.bin_data.client_id !== '' &&
            (this.validationService.validationErrorArray[0] === '' || this.validationService.validationErrorArray[0] === undefined)){
              // Perform additional validation for the client ID
              this.ClientIdValidation();
          } else {
            // Display an error message if any required variable is empty or validation errors are present
            this.systemMessage = 'binCreateViewAlertEmptyInput';
          }
    
        } else {
          // Display an error message if the bin name is duplicated
          notDuplicatedBinName = false;
          this.systemMessage = 'binCreateViewAlertDuplicatedBinName';
        }

      break;
    }
    
  }

  // Function called to validate if the client_id was properly set
  ClientIdValidation(){
    switch(true){
      // Will check if the user type is muirwood or distributor, the client id need to be assign. if not there will be a message displayed
      case ((this.cognitoService.userType === 'muirwood' || this.cognitoService.userType === 'distributor') && this.bin.bin_data.client_id !== ''):
        // Set validation variable to true;
        this.validateCreate = true;
        break;

      // Will set the bin_data client_id variable to the client id if the user type is client
      case this.cognitoService.userType === 'client':
        // Set the bin_data client_id variable to the user client id
        this.bin.bin_data.client_id = this.cognitoService.clientId;
        // Set the validation variable to true
        this.validateCreate= true;
        break;

      // Goes into default if there is no client selected and user type is not client
      default:
        this.systemMessage = 'enterClient';
    }
  }

  // Function called when user select a bin model number to associate to th bin
  async binModelChange(){
    this.bin.binModelOrClientChange();
  }

  // Function called when user change the threshold input and won't let him to put threshold under 40% or over 90%
  thresholdChange(){
    if(this.bin.bin_data.threshold < 40 || this.bin.bin_data.threshold > 90){
      this.systemMessageService.selectRibbon('danger', 'thresholdBreakPoint');
      this.bin.bin_data.threshold = 80;
    }
  }

  thingChange(){
    
  }

  // Functino called when user change the select of client
  async selectedClientChange(){
    if(this.bin.bin_data.bin_model_id !== ''){
      // If bin model select is not empty it will call his function to get all available device
      this.binModelChange();
    }
  }

  // Implemented by cancel button to return to the admin component
  returnToAdmin(){
    // Put a sessions store variable so admin component could know to return on bin-list
    sessionStorage.setItem("previous", "bin-list");
    sessionStorage.setItem("from", "bin-update"); // Variable used by bin-model-list html to show the proper message on success updating bin model
    this.router.navigate(['/admin']); // Return to admin component
  }

  // Function used by client user type to return to the client bin list
  returnClientBinList(){
    this.router.navigate(['/client-bin-dashboard']);
  }

  returnDeviceClientList() {
    this.router.navigate(['client-thing-list']);
  }

  // Function called when user touch to the address input
  onInputAddressKeydown(event: KeyboardEvent): void {
    this.addressChange = true; // If user change something on the address input

    // If user press enter when he's in the address field the form won't submit
    if(event.key === 'Enter'){
      event.preventDefault();
    }
  }

  trackByClientId(option: any): string {
    return option.client_id;
  }

  trackBinModelId(option: any): string {
    return option.bin_model_id;
  }
}
