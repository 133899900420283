<app-system-message></app-system-message>
<!-- Search input component, shown when mainContent is 'bin-model-list' -->
<div [ngClass]="theme.getThemeClass()" class="min-width-div container-fluid">
    <nav id="search-component" class="input-group" *ngIf="mainContent === 'bin-model-list'">
        <input #searchBox type="search"  id="search-box" [(ngModel)]="userFilter" (input)="this.filter.setCallingComponent('bin-model-list'); this.filter.search(userFilter, this.bin.bin_model_array, this.bin.getCountFilter());"
            class="form-control rounded searchInput"
            placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}" aria-label="Search" aria-describedby="search-addon" />
    </nav>

    <!-- Table header for the bin-model-list, shown when mainContent is 'bin-model-list' -->
    <header [ngClass]="theme.getThemeClass()" class="table-header" *ngIf="mainContent === 'bin-model-list'">
        <span class="left">{{ 'binModelListViewBinModelList' | translate }}</span>
        <span class="right" style="scale: 0.9;"><a routerLink="/bin-model-create" href="javascript:void(0)">&#x2b; {{ 'binModelListViewNew' | translate }} </a></span>
    </header>

    <!-- Table container for the bin-model-list, shown when mainContent is 'bin-model-list' -->
    <section [ngClass]="theme.getThemeClass()" class="table-container" *ngIf="mainContent === 'bin-model-list'">
        <article  class="table-responsive ">
            <table  class="table table-hover">
                <thead >
                    <tr>
                        <th [ngClass]="theme.getThemeClass()" scope="col">
                            {{ 'binListViewNumber' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                            {{ 'binModelListViewTotalVolume' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                            {{ 'binListViewCreated' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                            {{ 'binListViewModified' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" scope="col">
                            {{ 'iotTabHeaderResources' | translate }}
                        </th>
                    </tr>
                </thead>
                <ng-container  *ngFor="let binModel of this.bin.bin_model_array">
                    <tbody>
                        <tr style="vertical-align: middle;">
                            <td [ngClass]="theme.getThemeClass()"><a (click)="this.binModal.openModal(binModel);" [style.color]="this.binModel.active ? 'green!important' : 'red!important'">{{ binModel.bin_model_number }}</a></td>
                            <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{ binModel.total_volume }} L</td>
                            <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{ this.validationService.intToDate(binModel.created) | date: 'dd/MM/yyyy' }}</td>
                            <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{ this.validationService.intToDate(binModel.modified) | date: 'dd/MM/yyyy' }}</td>
                            <td [ngClass]="theme.getThemeClass()">
                                <a [routerLink]="['/bin-model-update', binModel.bin_model_id]"><img class="iconUpdate" src="./assets/update-pencil.png" alt="icon of update" height="30" width="30" style="color: gray;"></a>
                            </td>
                        </tr>
                    </tbody>
                </ng-container>
            </table>
          </article>
        <app-bin-modal *ngIf="this.binModal.showModal" class="bin-modal"></app-bin-modal>
      </section>
</div>
