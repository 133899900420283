

<app-system-message></app-system-message>

<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">

    <div [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'createWorkOrder' | translate }}</h4>
            <form>

                <!-- CLIENT LIST/ID SELECT INPUT -->
                <label class="label-spacer" for="clientName">{{ 'clientName' | translate }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-briefcase"></i></span>
                    <select [(ngModel)]="this.workOrderService.workOrderData.client_id"
                        class="form-control"
                        name="client_id"
                        aria-label="Client Name"
                        aria-describedby="basic-addon1"
                        (change)="this.getDataUsingClientId()"
                        [ngClass]="{'error-border': this.validationService.validationErrorArray[0] === 'clientInputError'}">
                        <option value="" disabled selected>{{ 'clientName' | translate }}</option>
                        <option *ngFor="let client of this.clientArray" [value]="client.client_id">{{ client.client_name }}</option>
                    </select>
                </div>

                <!-- DEVICE LIST/THING NAMES SELECT INPUT -->
                <label class="label-spacer" for="clientName">{{ 'selectDevice' | translate }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-angle-double-right"></i></span>
                    <select [(ngModel)]="this.workOrderService.workOrderData.thing_name"
                            class="form-control"
                            name="work_order_type"
                            aria-label="Work Order Type"
                            aria-describedby="basic-addon1"
                            [ngClass]="{'error-border': this.validationService.validationErrorArray[0] === 'clientInputError'}"
                            [disabled]="thingsClientArray.length === 0">
                        <option value="" disabled selected>{{ 'selectDevice' | translate }}</option>
                        <option *ngFor="let thingClient of thingsClientArray" [value]="thingClient">{{ thingClient }}</option>
                    </select>
                </div>

                <!-- BINS ID LIST SELECT INPUT -->
                <label class="label-spacer" for="clientName">{{ 'selectBinId' | translate }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fab fa-artstation"></i></span>
                    <select [(ngModel)]="this.workOrderService.workOrderData.bin_id"
                            class="form-control"
                            name="work_order_type"
                            aria-label="Work Order Type"
                            aria-describedby="basic-addon1"
                            [ngClass]="{'error-border': this.validationService.validationErrorArray[0] === 'clientInputError'}"
                            [disabled]="thingsClientArray.length === 0">
                        <option value="" disabled selected>{{ 'selectBinId' | translate }}</option>
                        <option *ngFor="let binIdClient of binsClientArray" [value]="binIdClient">{{ binIdClient }}</option>
                    </select>
                </div>

                <!-- WORK ORDER TYPE SELECT INPUT -->
                <label class="label-spacer" for="clientName">{{ 'workOrderType' | translate }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-clipboard-list"></i></span>
                    <select [(ngModel)]="this.workOrderService.workOrderData.work_order_type"
                            class="form-control"
                            name="work_order_type"
                            aria-label="Work Order Type"
                            aria-describedby="basic-addon1"
                            [ngClass]="{'error-border': this.validationService.validationErrorArray[0] === 'clientInputError'}"
                            [disabled]="thingsClientArray.length === 0">
                        <option value="" disabled selected>{{ 'workOrderType' | translate }}</option>
                        <option *ngFor="let workOrderType of WorkOrderTypes" [value]="workOrderType.value">{{ getTranslatedLabel(workOrderType.label) }}</option>
                    </select>
                </div>

                <!-- WORK ORDER STATUS SELECT INPUT -->
                <label class="label-spacer" for="clientName">{{ 'workOrderStatus' | translate }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-circle-notch"></i></span>
                    <select [(ngModel)]="this.workOrderService.workOrderData.work_order_status"
                            class="form-control"
                            name="work_order_status"
                            aria-label="Work Order Status"
                            aria-describedby="basic-addon1"
                            [ngClass]="{'error-border': this.validationService.validationErrorArray[0] === 'clientInputError'}"
                            [disabled]="thingsClientArray.length === 0">
                        <option value="" disabled selected>{{ 'workOrderStatus' | translate }}</option>
                        <option *ngFor="let workOrderStatus of WorkOrderStatus" [value]="workOrderStatus.value">{{ getTranslatedLabel(workOrderStatus.label) }}</option>
                    </select>
                </div>

                <!-- WORK ORDER DEADLINE DATE INPUT  -->
                <label class="label-spacer" for="deadline">{{ 'deadline' | translate }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="deadline-input"><i class="far fa-clock"></i></span>
                    <input
                        type="datetime-local"
                        class="form-control"
                        aria-label="Deadline"
                        aria-describedby="deadline-addon"
                        (input)="updateDeadline($event)"
                        [disabled]="thingsClientArray.length === 0"
                    />
                </div>

                <!-- DESCRIPTION TEXT INPUT  -->
                <label class="label-spacer" for="deadline">{{ 'workOrderDescription' | translate }}</label>
                <div class="input-group mb-3">
                    <textarea style="width: 100%; height: 100px; overflow-y: auto; -webkit-overflow-scrolling: touch;"
                    id="descricao" name="descricao"
                    [disabled]="thingsClientArray.length === 0"
                    [(ngModel)]="descriptionData.description"
                    maxlength="600"
                    (touchstart)="startScroll($event)"
                    (touchmove)="scrollTextarea($event)"
                    (touchend)="stopScroll()">></textarea>
                    <!-- REMAINING CHARACTERS COUNTER -->
                    <div>{{ 'remainingCharacters' | translate }} {{ 600 - descriptionData.description.length }}</div>
                </div>

                <!-- Submit button -->
                <div class="form-group displayFlex">
                    <button (click)="pageReload()" [ngClass]="theme.getThemeClass()" *ngIf="this.cognitoService.userType === 'muirwood'" type="button" class="btn btn-primary btn-block btnCancel fixed-size">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button (click)="returnToDistributorDashboard()" [ngClass]="theme.getThemeClass()" *ngIf="this.cognitoService.userType === 'distributor'" type="button" class="btn btn-primary btn-block btnCancel fixed-size">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button (click)="returnToClientDashboard()" [ngClass]="theme.getThemeClass()" *ngIf="this.cognitoService.userType === 'client'" type="button" class="btn btn-primary btn-block btnCancel fixed-size">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button [ngClass]="theme.getThemeClass()" (click)="createBtn()" type="button" class="btn btn-primary btn-block btnCreate fixed-size">{{ 'clientcreateFormBtnCreateClient' | translate }}</button>
                </div>
            </form>
        </article>
    </div>
</div>





