import { Injectable } from '@angular/core';
import { CognitoService } from './cognito.service';
import { IotService } from './iot.service';
import { DistributorsService } from './distributors.service';
import { WorkOrderService } from './work-order.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { SystemMessageService } from './system-message.service';
import { map, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserEditModalService {
  showUserEditModal: boolean = false;
  showUserEditAdvancedOption: boolean = false;

  selectedThingAlertCollection: string[] = [];
  selectedThingAlertThreshold: string[] = [];
  selectedThingAlertMonthlyReport: string[] = [];

  collectionArray: any[] = [];
  thresholdArray: any[] = [];
  monthlyReportArray: any[] = [];

  public things: any[] = [];
  private userSub: string = '';

  constructor(private cognitoService: CognitoService,
              private iotService: IotService,
              private distributorService: DistributorsService,
              private workOrderService: WorkOrderService,
              private http: HttpClient,
              private systemMessageService: SystemMessageService) { }

  // Function called when user clieck on advance alert options
  async openAvancedOptionModal(){
    // Get all things that user have
    await this.getAllThings();

    // Get all user alert preferences
    await this.getUserAlertPreferences();

    // Set boolean variable to show the modal
    this.showUserEditModal = true;
    this.showUserEditAdvancedOption = true;
  }

  // Function that get all the thing of the user
  async getAllThings(){
    if(!this.cognitoService.userType){
      await this.cognitoService.getUserType();
    }

    if(this.things.length === 0 || this.things === undefined){
      // Depend on user type, it will go get the list from else where
      switch(this.cognitoService.userType){
        case 'muirwood':
          await this.iotService.getDeviceList();
          this.things = this.iotService.thingArray.map((item: any) => item.thingName);
          break;

        case 'distributor':
          await this.distributorService.getDeviceByDistributorId(this.cognitoService.distributorId);
          this.things = this.distributorService.distributorIotDashboardArray.map((item: any) => item.thing_name);
          break;

        case 'client':
          await this.workOrderService.getThingNamesList(this.cognitoService.clientId);
          this.things = this.workOrderService.thingNamesList.map((item: any) => item.thing_name);
          break;

        case 'operator':
          // To implement once we get data to do so......
          break;
      }
    }
  }

  // Function called to get all of the user alert preferences
  async getUserAlertPreferences(){
    // Get the user sub for research
    this.userSub = await this.cognitoService.getCurrentUserSub();

    // Call the function that made the call to lambda function and wait for is response
    this.getUserAlertPreferencesLambda(this.userSub).subscribe((response: any) => {
      if(response){
        // Set the values of the proper array for the alert preferences
        for(const element of response){
          switch(element.alert_type){
            case 'CO':
              this.collectionArray.push(element.thing_name);
              break;

            case 'TH':
              this.thresholdArray.push(element.thing_name);
              break;

            case 'MR':
              this.monthlyReportArray.push(element.thing_name);
              break;
          }
        }
      }
    });
  }

  // Function called when user close the modal
  closeModal(){
    // Set boolean whowing variable to false to hide the modal
    this.showUserEditModal = false;
    this.showUserEditAdvancedOption = false;

    // Set the array to nothing
    this.things = [];
    this.collectionArray = [];
    this.thresholdArray = [];
    this.monthlyReportArray = [];
  }

  // Funciton called to save the advanced alert options
  async saveAdvanceOption(){
    // Made the call to get user sub only if we don't already have it
    if(this.userSub === ''){
      this.userSub = await this.cognitoService.getCurrentUserSub();
    }

    // First delete all the instance of the user alert type in DB
    await this.deleteAdvanceAlertPreferences();

    // For all the arrays of things, it will set the new user alert preference option
    await this.setUserAdvanceAlertPreferences(this.selectedThingAlertCollection, 'CO');
    await this.setUserAdvanceAlertPreferences(this.selectedThingAlertThreshold, 'TH');
    await this.setUserAdvanceAlertPreferences(this.selectedThingAlertMonthlyReport, 'MR');


    // Call a function that set to 1 the column is_advance_preference in alert_preference table
    await this.setIsAdvanceAlertPreference();

    // Call the function to close the modal
    this.closeModal();
  }

  // Function that set to 1 the column is_advance_preference in alert_preference table
  async setIsAdvanceAlertPreference(){
    // Call the function that made the call to lambda function and wait for is response
    this.setIsAdvanceAlertPreferenceLambda(this.userSub).subscribe((response: any) => {
      if(response.message === 'success'){

      }else{

      }
    });
  }

  // Function that delete all user alert preferences
  async deleteAdvanceAlertPreferences(){
    // Call the function that made the call to lambda function and wait for is response
    this.deleteAdvanceAlertPreferencesLambda().subscribe((response: any) => {
      if(response.message === 'success'){

      }else{

      }
    });
  }

  // Funciton that set all user alert preferences
  async setUserAdvanceAlertPreferences(things_name: string[], alert_type: string){
    // Call the function that made the call to lambda function and wait for is response
    this.setUserAdvanceAlertPreferencesLambda(things_name, alert_type).subscribe((response: any) => {
      if(response.message === 'success'){
        this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
      }else{
        this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      }
    });
  }

  setIsAdvanceAlertPreferenceLambda(sub: string){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    // Call lambda fucntion whit the url of updateBin  and return the response
    return this.http.post(environment.api.stage + environment.api.route.setUserIsAdvanceAlertPreference, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "user": sub
      }, {headers : headers}
    );
  }

  // Function that call the lambda function to get all user alert preferences
  getUserAlertPreferencesLambda(sub: string){
    // Get the url for the lambda function getBinsModels
    const url = environment.api.stage + environment.api.route.getUserAdvanceAlertPreferences + '&user=' + sub;
    // Will call the lambda function in getBinModel url then return a response
    return this.http.get(url).pipe(
      map((response: any) => {

        // return the response data
        return response;
      }),
      catchError((error) => {
        //console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      })
    );
  }

  // Function that call the lambda to delete all user alert preferences
  deleteAdvanceAlertPreferencesLambda(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Call lambda fucntion whit the url of updateBin  and return the response
    return this.http.post(environment.api.stage + environment.api.route.deleteUserAdvanceAlertPreferences, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "user": this.userSub
      }, {headers : headers}
    );
  }

  // Function that call the lambda to set all user alert preferences
  setUserAdvanceAlertPreferencesLambda(thing_name: string[], alert_type: string){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Call lambda fucntion whit the url of updateBin  and return the response
    return this.http.post(environment.api.stage + environment.api.route.setUserAdvanceAlertPreferences, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "user": this.userSub,
        "thing_name": thing_name,
        "alert_type": alert_type
      }, {headers : headers}
    );
  }
}
