<app-system-message></app-system-message>
<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">
    <section [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'taxeTypeUpdateViewUpdateTaxeType' | translate }}</h4>
            <form>
                <!-- Select for the country -->
                <div class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-flag"></i></span>
                    <select [(ngModel)]="this.country" (change)="updateTaxeTypeArray('country')" class="form-control" name="country"
                    aria-label="Country" aria-describedby="basic-addon1">
                        <option value="" disabled selected>{{ "taxeTypeCreateViewSelectCountry" | translate }}</option>
                        <ng-container *ngFor="let option of this.countryISOConstant">
                            <option value="{{ option.value }}">{{ option.label | translate }}</option>
                        </ng-container>
                    </select>
                </div>
                <!-- Select for the federal or provincial taxe -->
                <div class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-institution"></i></span>
                    <select [(ngModel)]="this.is_federal" (change)="updateTaxeTypeArray(this.is_federal)" class="form-control" name="is_federal"
                    aria-label="is Federal" aria-describedby="basic-addon1">
                        <option value="" disabled selected>{{ "taxeTypeViewTaxeFor" | translate }}</option>
                        <option value="1">{{ "taxeTypeViewFederal" | translate }}</option>
                        <option value="0">{{ "taxeTypeViewProvincial" | translate }}</option>
                    </select>
                </div>
                <!-- Select for the province, only showed when taxe taxe selected for province -->
                <div class="input-group mb-3 firstInput" *ngIf="this.is_federal === '0'">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-flag"></i></span>
                    <select [(ngModel)]="this.province" (change)="updateTaxeTypeArray('province')" class="form-control" name="province"
                    aria-label="Province" aria-describedby="basic-addon1">
                        <option value="" disabled selected>{{ "taxeTypeCreateViewSelectProvince" | translate }}</option>
                        <ng-container *ngFor="let option of this.provinceISOConstant">
                            <option value="{{ option.value }}">{{ option.label | translate }}</option>
                        </ng-container>
                    </select>
                </div>
                <!-- Select for taxe type / taxe name -->
                <div class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-money"></i></span>
                    <select [(ngModel)]="this.type" class="form-control" name="taxe type"
                    aria-label="taxe type" aria-describedby="basic-addon1">
                        <ng-container *ngFor="let option of taxeTypeArray">
                            <option value="{{ option.label }}" >{{ option.label | translate }} : {{ option.name | translate }} {{ option.province | translate }}</option>
                        </ng-container>
                    </select>
                </div>
                <!-- Input for the taxe rate -->
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-percent"></i></span>
                    <input [(ngModel)]="this.rate" type="text"
                    class="form-control" name="taxe-type-rate"
                    placeholder="{{ 'taxeTypeCreateViewEnterRate' | translate }}" aria-label="taxe rate"
                    aria-describedby="basic-addon1"
                    (blur)="this.validationService.validateTaxeRate(this.rate)"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'taxeRateInvalid'">
                </div>
                <!-- Checkbox to toggle the status of the taxe -->
                <div class="card-title mt-0 status">
                    <label class="switch">
                        <input type="checkbox" id="checkboxStatus" [name]="'active-bin-' +this.taxeTypeService.taxe_type_data.taxe_type_id" [ngModel]="this.taxeTypeService.taxe_type_data.active" (change)="this.alert.showAlertDelete = true; this.alert.deactivetaxeType(this.taxeTypeService.taxe_type_data)" [disabled]="this.alert.showAlertDelete">
                        <span [ngClass]="{'active-checkbox': this.taxeTypeService.taxe_type_data.active === 1, 'inactive-checkbox': this.taxeTypeService.taxe_type_data.active === 0}"  class="slider round"></span>
                    </label>
                    <label class="toggle">{{ "iotTabHeaderStatus" | translate }}</label>
                </div>

                <!-- Submit button -->
                <nav class="form-group displayFlex">
                    <button (click)="returnToAdmin()" [ngClass]="theme.getThemeClass()" type="submit" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button [ngClass]="theme.getThemeClass()" (click)="onSubmit()" type="submit" class="btn btn-block btnCreate">{{ 'taxeTypeUpdateViewConfirmUpdateTaxeType' | translate }}</button>
                </nav>
            </form>
        </article>
        <app-alert-modal *ngIf="this.alert.showAlertDelete" class="alertDelete" [ngClass]="theme.getThemeClass()"></app-alert-modal>
      </section>
</div>

