import { Component } from '@angular/core';
import { WorkOrderDashboardModalService } from '../service/work-order-dashboard-modal.service';
import { WorkOrderStatus } from '../constants/work-order-status';
import { ThemeService } from '../service/theme.service';

@Component({
  selector: 'app-work-order-dashboard-modal',
  templateUrl: './work-order-dashboard-modal.component.html',
  styleUrls: ['./work-order-dashboard-modal.component.css', '../../global-elements.css']
})
export class WorkOrderDashboardModalComponent{
  public selectedStatus: string = this.workOrderModalService.status;
  public selectedDate: string = this.workOrderModalService.deadlineDate;
  public selectedDescription: string = this.workOrderModalService.description;
  public selectedComments: string = this.workOrderModalService.comments;

  public statusArray = WorkOrderStatus;

  constructor(public workOrderModalService: WorkOrderDashboardModalService,
              public theme: ThemeService){

  }
}
