<div [ngClass]="theme.getThemeClass()" class="container-fluid" (window:scroll)="onScroll()" style="background-color: rgb(255, 255, 255); min-height: 100vh;" >
<!-- Menu CONFIGS COMPONENT -->
  <header style="width: 100%; justify-content: center; margin-left: auto; margin-right: auto; z-index: 0;">
    <nav [ngClass]="theme.getThemeClass()" class="navbar navbar-expand-lg navbar-light bg-light" style="display: flex; flex-direction: column;margin-left: auto; margin-right: auto;">
      <div [ngClass]="theme.getThemeClass()" class="navbar-nav flex-nowrap" id="navbarNavAltMarkup" style="width: 100%; justify-content: center;">
        <a class="nav-item nav-link" href="javascript:void(0)"
        (click)="devicesTypesClicked()" [ngClass]="{'active' : isDeviceTypesActive}">
        {{ 'configsNavDeviceTypes' | translate }}
        </a>
        <a class="nav-item nav-link" href="javascript:void(0)"
        (click)="variablesClicked()" [ngClass]="{'active' : isVariablesActive}">
        {{ 'configsNavVariables' | translate }}
        </a>
        <a class="nav-item nav-link" href="javascript:void(0)"
        (click)="metricsClicked()" [ngClass]="{'active' : isMetricsActive}">
        {{ 'configsNavMetrics' | translate }}
        </a>
        <!-- Hides two buttons to fit small screen view -->
        <a class="nav-item nav-link hide-small-screen" href="javascript:void(0)"
        (click)="eventsClicked()" [ngClass]="{'active' : isEventsActive}">
        {{ 'configsNavEvents' | translate }}
        </a>
        <a class="nav-item nav-link hide-small-screen" href="javascript:void(0)"
        (click)="jobsClicked()" [ngClass]="{'active' : isJobsActive}">
        {{ 'configsNavJobs' | translate }}
        </a>
        <a class="nav-item nav-link hide-small-screen" href="javascript:void(0)"
        (click)="issuesClicked()" [ngClass]="{'active' : isIssuesActive}">
        {{ 'configsNavIssues' | translate }}
        </a>
        <a class="nav-item nav-link hide-small-screen" href="javascript:void(0)"
        (click)="skillsClicked()" [ngClass]="{'active' : isSkillsActive}">
        {{ 'configsNavSkills' | translate }}
        </a>
      </div>
      <div [ngClass]="theme.getThemeClass()" class="navbar-nav flex-nowrap" id="navbarNavAltMarkup" style="padding-top: 10px; margin-left: auto; margin-right: auto; scale: 0.9;">
        <a class="nav-item nav-link show-small-screen hide-big-screen" href="javascript:void(0)" (click)="eventsClicked()" [ngClass]="{'active' : isEventsActive}">{{ 'configsNavEvents' | translate }}</a>
        <a class="nav-item nav-link show-small-screen hide-big-screen" href="javascript:void(0)" (click)="jobsClicked()" [ngClass]="{'active' : isJobsActive}">{{ 'configsNavJobs' | translate }}</a>
        <a class="nav-item nav-link show-small-screen hide-big-screen" href="javascript:void(0)" (click)="issuesClicked()" [ngClass]="{'active' : isIssuesActive}">{{ 'configsNavIssues' | translate }}</a>
      </div>
    </nav>
  </header>
  <!-- Search component -->
  <nav id="search-component" class="input-group" *ngIf="mainContent === 'clients'">
    <input #searchBox type="search"  id="search-box" [(ngModel)]="userFilterTest" class="form-control rounded" style="border: 0.1px solid; opacity: 50%; color: black;" placeholder="Filter" aria-label="Search" aria-describedby="search-addon" />
  </nav>

  <!-- Table header -->
  <div [ngClass]="theme.getThemeClass()" class="table-header" *ngIf="mainContent === 'clients'">
    <span class="left">{{ "configsNavDeviceTypes" | translate }}</span>
    <span class="right" style="scale: 0.9;"><a href="javascript:void(0)">&#x2b; {{ "userlistTableHeaderNew" | translate }} <span class="hide-small-screen">{{ "iotTabHeaderDevice" | translate }}</span></a></span>
  </div>

  <!-- Table container -->
  <section [ngClass]="theme.getThemeClass()" class="table-container" *ngIf="mainContent === 'clients'">
    <article  class="table-responsive ">
      <table  class="table table-hover">
        <thead>
          <tr>
            <th [ngClass]="theme.getThemeClass()" scope="col">{{ "iotTabHeaderDevice" | translate }}</th>
            <th [ngClass]="theme.getThemeClass()" scope="col">{{ "iotNavButtonModels" | translate }}</th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">{{ "iotModelsViewTabHeaderType" | translate }}</th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">{{ "_client" | translate }}</th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">{{ "roles" | translate }}</th>
          </tr>
        </thead>
        <ng-container>
          <tbody>
            <tr style="vertical-align: middle;">

            </tr>
          </tbody>
        </ng-container>
      </table>
    </article>
  </section>
</div>
