import { BinsService } from './../service/bins.service';
import { State } from './../constants/state';
import { Component, HostListener, Inject, forwardRef } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { navigation } from '../constants/navigation';
import { DashboardService, ThingStatusArray } from '../service/dashboard.service';
import { environment } from '../environments/environment';
import { ChartService } from '../service/chart.service';
import { ClientService } from '../service/client.service';
import { ActivatedRoute } from '@angular/router';
import { CognitoService } from '../service/cognito.service';
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '../service/role.service';
import { IotService, Devices } from '../service/iot.service';
import { lastValueFrom } from 'rxjs';
import { ModalService } from '../service/device-modal.service';
import { ScaleType } from '@swimlane/ngx-charts';
import { DistributorsService } from '../service/distributors.service';
import { SystemMessageService } from '../service/system-message.service';


@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.css', '../../dashboard-tiles.css']
})
export class ClientDashboardComponent {

  // Gridster options configuration
  gridsterOptions = {
    // Draggable configuration
    draggable: {
      enabled: true,  // Allow items to be draggable
    },
    // Resizable configuration
    resizable: {
      enabled: true,  // Allow items to be resizable
    },
    // Minimum and maximum number of columns
    minCols: 1,
    maxCols: 3,
    // Minimum and maximum number of rows
    minRows: 1,
    maxRows: 2,
    // Default size of items in terms of columns and rows
    defaultItemCols: 1,
    defaultItemRows: 1,
  };

  languageStatus:string = this.localStorageService.getItem('language');

  public navigationItemsArray: any = [];

  // Variable for the size of the screen
  public isSmallScreenWidth: boolean = this.getScreenWidth() <= 859;

  public userSub: any;
  public userUsername: any;
  public userType: any;

  public filteredArray: any[] = [];
  public devicesArray: any[] = [];
  public thingNames: any;

  public healthy: number = 0;
  public unhealthy: number  = 0;
  public inactive: number  = 0;
  public binFillLevelLow: number = 0;
  public binFillLevelMedium: number = 0;
  public binFillLevelHigh: number = 0;
  public currentClientId: any;
  public cognitoUsersArray: any[] = [];
  public originalArray: any[] = []

  public clientAdminCount: number = 0;
  public clientClientCount: number = 0;
  public clientCollectionCount: number = 0;

  public adminArray: any[] = [];
  public clientArray: any[] = [];
  public distributorArray: any[] = [];
  public operatorArray: any[] = [];
  public collectionArray: any[] = [];

  public workOrdersStatusComplete: number = 0;
  public workOrdersStatusInProgress: number = 0;
  public workOrdersStatusPaused: number = 0;
  public workOrdersStatusCancelled: number = 0;
  public workOrdersStatusError: number = 0;
  public workOrdersStatusDelayed: number = 0;
  public workOrdersStatusOpen: number = 0;
  public workOrdersStatusAssigned: number = 0;

  public stopLoading: boolean = false;
  public errorFetchingWorkOrderData: boolean = false;
  public errorFetchingClientUSerCountData: boolean = false;

  constructor(
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private localStorageService: LocalStorageService,
    public theme: ThemeService,
    private cognitoService: CognitoService,
    public charts: ChartService,
    public clientService: ClientService,
    private route: ActivatedRoute,
    public roleService: RoleService,
    public iotService: IotService,
    public dashboardService: DashboardService,
    public modal: ModalService,
    private binService: BinsService,
    public distributorService: DistributorsService,
    public systemMessageService: SystemMessageService,

    ){
      this.route.queryParams.subscribe(params => {
        this.userSub = params['userSub'];
        this.userUsername = params['userUsername'];
        this.userType = params['userType'];
    });

      // Check if the user has selected a language in local storage
      //or use a default language
      if (this.languageStatus == null){
        // Set the default language to French
        translate.use('fr');
      } else {
        // Set the default language to the user's selected language
        translate.use(this.languageStatus);
      }
  }

  // This function counts the number of work orders in different statuses
  // and updates corresponding class properties for each status.
  countWorkOrders(workOrders: any[]): void {
    workOrders
      .filter(order => order.status && typeof order.status === 'string')
      .forEach(order => {
        switch (order.status) {
          case "_complete":
            this.workOrdersStatusComplete++;
            break;
          case "_in-progress":
            this.workOrdersStatusInProgress++;
            break;
          case "_paused":
            this.workOrdersStatusPaused++;
            break;
          case "_cancelled":
            this.workOrdersStatusCancelled++;
            break;
          case "_error":
            this.workOrdersStatusError++;
            break;
          case "_delayed":
            this.workOrdersStatusDelayed++;
            break;
          case "_open":
            this.workOrdersStatusOpen++;
            break;
          case "_assigned":
            this.workOrdersStatusAssigned++;
            break;
          default:
            break;
        }
      });
  }


  // Asynchronous ngOnInit method, often used for component initialization
  async ngOnInit() {

    // Check if the user is a valid cognito user
    this.cognitoService.confirmValidUser();
    await this.roleService.getRoles();

    // Check if user can be on this page
    this.cognitoService.getCurrentRole([environment.users.role.administrator, environment.users.role.client, environment.users.role.driver, environment.users.role.operations], [environment.users.standardUser], true, this.roleService.roles);

    // Set color scheme for the bar chart
    this.charts.colorSchemeBarChart = {
      name: "",               // Color scheme name (optional)
      selectable: true,       // Allow colors to be selected
      group: ScaleType.Ordinal, // Define the color scale type
      domain: ['#5AA454', '#FFCC00', '#FF0000'] // Specify color domain for filllevel (green - yellow - red)
    };
    await lastValueFrom(await this.dashboardService.getRelationships());
    this.dashboardService.things =  this.dashboardService.getThingNamesByClientId(this.cognitoService.clientId);
    await this.dashboardService.countClientDashboardDevicesAndParameters();
    // function to translate all labels in the charts
    this.charts.translateChartsLegends();

    // Filter navigation items based on user type
    this.navigationItemsArray = navigation.filter(item => item.userType === environment.users.standardUser);

    // Get the current screen width and height on initialization
    const currentScreenWidth = this.getScreenWidth();
    const currentScreenHeight = this.getScreenHeight();

    // Check if the screen width is small (less than or equal to 859 pixels)
    this.isSmallScreenWidth = currentScreenWidth <= 859;

    // Adjust chart parameters based on the view size
    this.charts.adjustView(currentScreenWidth, currentScreenHeight);
    this.charts.showLegendPieChartSlice = false;


    // Initialize data asynchronously
    await this.initData();
  }


  // Function to filter and count users based on user_type
  async getAllClientUsers(clientId: string) {

    try {
      const res = await this.clientService.getUsersByClientId(clientId);

      // Iterate through the users and categorize them based on user_type
      for (let user of res) {
          switch (user.user_type) {
              case 'admin':
                  this.adminArray.push(user);
                  break;
              case 'muirwood':
                  this.adminArray.push(user);
                  break;
              case 'client':
                  this.clientArray.push(user);
                  break;
              case 'collection':
                  this.collectionArray.push(user);
                  break;
          }
      }

      // Update counts for each user type
      this.clientAdminCount = this.adminArray.length;
      this.clientClientCount = this.clientArray.length;
      this.clientCollectionCount = this.collectionArray.length;
    } catch (error) {
        // Handle errors
        console.error('Error: ', error);
        this.systemMessageService.selectRibbon('danger', 'loadingDataGeneralError');
    }
  }

  resetDataStatus() {
    this.iotService.resetDeviceArray();
    this.filteredArray = [];
    this.thingNames = [];
    this.errorFetchingWorkOrderData = false;
    this.errorFetchingClientUSerCountData = false;
  }

   findDeviceByThingName(devices: Devices[], targetThingName: string): Devices | undefined {
    return devices.find(device => device.thingName === targetThingName);
  }

  async initData() {

    this.resetDataStatus();

    // verify user
    await this.cognitoService.confirmValidUser();

    this.currentClientId = this.cognitoService.clientId;

    if(this.currentClientId === "") {
      this.systemMessageService.selectRibbon('danger', 'loadingDataGeneralError');
    }

    // tries to get work order data from database table
    try {
      const result = await this.distributorService.getAllDistributorWorkOrders(this.currentClientId);

      // get the quantity of each type of work order - to send to the chart
      this.countWorkOrders(result)

    } catch (error) {
      this.errorFetchingWorkOrderData = true;
      console.error('Error:', (error));
      this.systemMessageService.selectRibbon('danger', 'loadingDataGeneralError');

    }

    // tries to filter and count users based on user_type
    try {
      await this.getAllClientUsers(this.currentClientId);
    } catch (error) {
      this.errorFetchingClientUSerCountData = true;
      console.error('Error: ', error);
      this.systemMessageService.selectRibbon('danger', 'loadingDataGeneralError');
    }

    try {
        // Show legend for pie chart slices - to make sure that the legend is not displayed while the data still being loaded
        this.charts.showLegendPieChartSlice = true;

          // Initialize client data for the dashboard service with various parameters
          await this.dashboardService.initializeClientData(
          // Health statuses
          this.healthy, this.unhealthy, this.inactive,

          // Current client ID
          this.currentClientId,

          // Client counts
          this.clientAdminCount, this.clientClientCount, this.clientCollectionCount,

          // Bin fill levels
          this.binFillLevelLow, this.binFillLevelMedium, this.binFillLevelHigh,

          // Work order statuses
          this.workOrdersStatusComplete, this.workOrdersStatusInProgress,
          this.workOrdersStatusPaused, this.workOrdersStatusCancelled,
          this.workOrdersStatusError, this.workOrdersStatusDelayed,
          this.workOrdersStatusOpen, this.workOrdersStatusAssigned
        );

          // Set stopLoading to true to indicate the completion of data loading
          this.stopLoading = true;

      // this.dataLoaded = true; // Set dataLoaded to true when data initialization is complete
    } catch (error) {
        console.error('Error initializing data:', error);
        this.systemMessageService.selectRibbon('danger', 'loadingDataGeneralError');
    }
         // Set stopLoading to true to indicate the completion of data loading
         this.stopLoading = true;
  }

  // Event listener for window resize
  @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
      // get the current window width and height
      const currentScreenWidth = this.getScreenWidth();
      const currentScreenHeight = this.getScreenHeight();
      // Check the screen width and update the isSmallScreen flag accordingly
      this.isSmallScreenWidth = currentScreenWidth <= 859;

      //Adjust chart parameters for view size
      this.charts.adjustView(currentScreenWidth, currentScreenHeight);
  }



  /**
   * Asynchronous function to retrieve the bin ID associated with a given thingName.
   * @param thingName - The name of the thing for which to find the bin ID.
   * @returns A Promise that resolves to the bin ID if found, or an empty string if not found.
   */
  async getBinId(thingName: string): Promise<string> {
    // Clear the binArray in the modal
    this.modal.binArray = [];

    // Set binArray using the setBinDetailArray method from the binService
    this.modal.binArray = await this.binService.setBinDetailArray();

    // Get the list of relationships from your dashboard service
    const relationships = this.dashboardService.relationships;

    // Find the relationship in the list based on the thingName
    const relationshipFound = relationships.find(rel => rel.thing_name === thingName);

    // If a relationship is found, return the bin_id; otherwise, return an empty string
    return relationshipFound ? relationshipFound.bin_id : '';
  }


  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  // Funciton to get the current screen height
  getScreenHeight(): number {
    // Retunr the current screen height of the window
    return window.innerHeight;
  }

  // Function to filter an array of items by specified thing names
  // originalArray: The array of items to be filtered
  // thingNames: The list of thing names to filter by
  // Returns a new array containing only items with thing names present in the specified list
  filterArrayByThingNames(originalArray: any[], thingNames: string[]): any[] {
    // Use the filter method to create a new array with items matching the specified thing names
    return originalArray.filter(item => thingNames.includes(item.thing_name));
  }
}


