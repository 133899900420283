<!-- Google Maps API script -->
<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCyzMRxB8Re27NYcuI8KDFCEucI0GwHLHA"></script>

<app-system-message class="system-message"></app-system-message>

<!-- Container for the IOT component -->
<div
  [ngClass]="theme.getThemeClass()"
  class="container-fluid iotComponentDiv"
  (window:scroll)="onScroll()"
>
  <!-- Menu IOT COMPONENT -->
  <header>
    <nav
      [ngClass]="theme.getThemeClass()"
      class="navbar navbar-expand-lg navbar-light bg-light"
      style="
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
      "
    >
      <!-- Navigation links for IOT component -->
      <div
        style="width: 100%; justify-content: center"
        class="navbar-nav flex-nowrap"
        id="navbarNavAltMarkup"
      >
        <!-- Links for different sections -->
        <a
          class="nav-item nav-link"
          href="javascript:void(0)"
          (click)="devicesClicked()"
          [ngClass]="{ active: isDevicesActive }"
        >
          {{ "iotNavButtonDevices" | translate }}
        </a>
        <!-- <a
          class="nav-item nav-link"
          href="javascript:void(0)"
          (click)="modelsClicked()"
          [ngClass]="{ active: isModelsActive }"
        >
          {{ "iotNavButtonModels" | translate }}
        </a>
        <a
          class="nav-item nav-link"
          href="javascript:void(0)"
          (click)="alertsClicked()"
          [ngClass]="{ active: isAlertsActive }"
        >
          {{ "iotNavButtonAlerts" | translate }}
        </a> -->
        <!-- Hides two buttons to fit small screen view -->
        <a
          class="nav-item nav-link hide-small-screen"
          href="javascript:void(0)"
          (click)="reportsClicked()"
          [ngClass]="{ active: isConnectorsActive }"
        >
          {{ "iotNavButtonReports" | translate }}
        </a>
      </div>
      <!-- Show two buttons to fit small screen view -->
      <div
        [ngClass]="theme.getThemeClass()"
        class="navbar-nav flex-nowrap divButtonsSmallScreen"
        id="navbarNavAltMarkup"
      >
        <a
          class="nav-item nav-link show-small-screen hide-big-screen"
          href="javascript:void(0)"
          (click)="reportsClicked()"
          [ngClass]="{ active: isConnectorsActive }"
        >
          {{ "iotNavButtonReports" | translate }}
        </a>
      </div>
    </nav>
  </header>
  <!-- DEVICES VIEW -->
  <div
    id="rowContent"
    class="rowContentDevices"
    [ngClass]="theme.getThemeClass()"
    *ngIf="mainContent === 'devices'"
  >
    <div [hidden]="hideMap" id="columMap" class="hide-small-screen columMap">
      <div
        (click)="this.show = false"
        #mapContainer
        id="mapDesktop"
        class="mapDesktop"
      ></div>
    </div>
    <section id="columList" class="hide-small-screen columList">
      <article
        [ngClass]="theme.getThemeClass()"
        class="table-container"
        *ngIf="mainContent === 'devices'"
      >
        <div class="table-responsive">
          <nav
            colspan="5"
            id="search-component"
            (click)="this.show = false"
            class="input-group searchDiv"
            *ngIf="mainContent === 'devices'"
          >
            <input
              #searchBox
              type="search"
              id="search-box"
              [(ngModel)]="userFilter"
              (input)="
                filter.search(
                  userFilter,
                  this.iotService.devicesArray,
                  this.iotService.getCountFilter()
                );
                filter.setCallingComponent('iotComponent')
              "
              class="form-control rounded searchInput"
              placeholder="{{ 'iotSearchPlaceHolderFilter' | translate }}"
              aria-label="Search"
              aria-describedby="search-addon"
            />
          </nav>
          <article
            [ngClass]="theme.getThemeClass()"
            class="table-header"
            *ngIf="mainContent === 'devices'"
          >
            <span class="left"
              >{{ "userlistTableHeaderDeviceList" | translate }}
              <a (click)="refreshList()"
                ><i class="scale-0-8 fas fa-sync"></i
              ></a>
            </span>
            <span class="right scale-0-9"
              ><a routerLink="/device-create" href="javascript:void(0)"
                >&#x2b; {{ "userlistTableHeaderNew" | translate }}
                <span class="hide-small-screen">{{
                  "iotlistTableHeaderNewIot" | translate
                }}</span></a
              ></span
            >
          </article>
          <table class="table table-hover">
            <thead (click)="this.show = false">
              <tr>
                <th
                  [ngClass]="theme.getThemeClass()"
                  scope="col"
                  class="table-header-device"
                >
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('thingName')"
                    href="javascript:void(0)"
                  >
                    {{ "iotTabHeaderDevice" | translate }} </a
                  ><img
                    *ngIf="
                      this.sortDirection === 'asc' &&
                      this.sortBy === 'thingName'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' &&
                      this.sortBy === 'thingName'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('thingName')"
                    href="javascript:void(0)"
                  >
                    {{'VCC'|translate}}
                  </a>
                </th>
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('thingName')"
                    href="javascript:void(0)"
                  >
                    {{'iotModalHeaderBattery' | translate}}
                  </a>
                </th>
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('thingName')"
                    href="javascript:void(0)"
                  >
                    {{'version' | translate}}
                  </a>
                </th>
                <!-- <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('thingName')"
                    href="javascript:void(0)"
                  >
                    {{'binListViewLocation' | translate}}
                  </a>
                </th> -->
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('thingName')"
                    href="javascript:void(0)"
                  >
                    {{'signal' | translate}}
                  </a>
                </th>
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('thingName')"
                    href="javascript:void(0)"
                  >
                    {{'temperature' | translate}}
                  </a>
                </th>
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('thingName')"
                    href="javascript:void(0)"
                  >
                    {{'filled' | translate}}
                  </a>
                </th>
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('thingName')"
                    href="javascript:void(0)"
                  >
                   {{'height' | translate}}
                  </a>
                </th>
                <th [ngClass]="theme.getThemeClass()" scope="col">
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('thingName')"
                    href="javascript:void(0)"
                  >
                    {{"deviceConfigModalDistance" | translate}}
                  </a>
                </th>
                <th
                  [ngClass]="theme.getThemeClass()"
                  scope="col"
                  class="table-header-status"
                >
                  {{ "iotTabHeaderStatus" | translate }}
                </th>
                <th
                  [ngClass]="theme.getThemeClass()"
                  class="hide-small-screen table-header-last-activity"
                  scope="col"
                >
                  <a
                    [ngClass]="theme.getThemeClass()"
                    (click)="this.sortItems('date')"
                    href="javascript:void(0)"
                  >
                    {{ "iotTabHeaderLastActivity" | translate }} </a
                  ><img
                    *ngIf="
                      this.sortDirection === 'asc' && this.sortBy === 'date'
                    "
                    src="./assets/arrow-up.png"
                    width="10px"
                    height="10px"
                  /><img
                    *ngIf="
                      this.sortDirection === 'desc' && this.sortBy === 'date'
                    "
                    src="./assets/arrow-down.png"
                    width="10px"
                    height="10px"
                  />
                </th>
                <th
                  [ngClass]="theme.getThemeClass()"
                  class="hide-small-screen table-header-ressources"
                  scope="col"
                >
                  {{ "iotTabHeaderResources" | translate }}
                </th>
              </tr>
            </thead>
            <!-- table populated with data from array of devices -->
            <ng-container
              *ngFor="
                let deviceRow of iotService.devicesArray.slice(
                  0,
                  loadedItems + itemsPerPage
                );
                let i = index
              "
            >
              <tbody>
                <tr #minhadiv class="rowTableData">
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.thingName }}
                  </td>
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.dat.vcc }}
                  </td>
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.dat.bat }}V
                  </td>
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.dat.fdv }}
                  </td>
                  <!-- <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.bin?.bin_location }}
                  </td> -->
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.dat.sig }}
                  </td>
                  <td [ngClass]="theme.getThemeClass()">
                    {{ deviceRow.dat.tm2 }}°C
                  </td>
                  <td [ngClass]="theme.getThemeClass()">
                    {{calculateFillPercentage(deviceRow.bin?.bin_height,deviceRow.dat.dst)}}
                  </td>
                  <td [ngClass]="theme.getThemeClass()">
                    {{deviceRow.bin?.bin_height}}
                  </td>
                  <td [ngClass]="theme.getThemeClass()">
                    {{deviceRow.dat.dst}}
                  </td>

                  <!-- For now status are unknown -->
                  <td [ngClass]="theme.getThemeClass()">
                    <div
                      [ngClass]="{
                        healthy:
                          verifyStatus(deviceRow.thingName) === 'Healthy',
                        unhealthy:
                          verifyStatus(deviceRow.thingName) === 'Unhealthy',
                        inactive:
                          verifyStatus(deviceRow.thingName) === 'Inactive'
                      }"
                    >
                      {{ verifyStatus(deviceRow.thingName) | translate }}
                    </div>
                  </td>
                  <td
                    class="hide-small-screen"
                    [ngClass]="theme.getThemeClass()"
                  >
                    {{ deviceRow.date }}
                  </td>
                  <td [ngClass]="theme.getThemeClass()">
                    <div class="divButton">
                      <a
                        href="javascript:void(0)"
                        (click)="showMap(deviceRow.gps, deviceRow)"
                      >
                        <img
                          [title]="'showLocation' | translate"
                          class="iconMap"
                          src="./assets/mappoint.png"
                          alt="icon of a map"
                          height="20"
                          width="20"
                        />
                      </a>
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.iotService.showConfig(deviceRow.thingName)
                        "
                      >
                        <img
                          [title]="'iotModalViewConfig' | translate"
                          class="iconParam"
                          src="./assets/settings.png"
                          alt="icon of param"
                          height="20"
                          width="20"
                          style="color: gray"
                        />
                      </a>
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.modal.showShadow(
                            this.iotService.getDeviceShadow(
                              deviceRow.thingName
                            ),
                            deviceRow.thingName
                          )
                        "
                      >
                        <img
                          [title]="'shadow' | translate"
                          class="iconParam"
                          src="./assets/json-icon.png"
                          alt="icon of json"
                          height="20"
                          width="20"
                          style="color: gray"
                        />
                      </a>
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.getCertificateFolderUrl(deviceRow.thingName)
                        "
                      >
                        <img
                          [title]="'generateCertificate' | translate"
                          class="iconCert"
                          src="./assets/grey-certificate-48.png"
                          alt="icon of certificate"
                          height="25"
                          width="25"
                          style="color: gray"
                        />
                      </a>
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.showRelation(deviceRow.thingName);
                          this.modal.showRelation(deviceRow.thingName)
                        "
                      >
                        <img
                          [title]="'deviceRelationship' | translate"
                          class="iconCert"
                          src="./assets/relationship.png"
                          alt="icon of relationship"
                          height="25"
                          width="25"
                          style="color: gray"
                        />
                      </a>
                      <a href="/device-statistics/{{ deviceRow.thingName }}">
                        <img
                          [title]="'showReports' | translate"
                          class="iconStat"
                          src="./assets/bar-chart-48.png"
                          alt="icon of a chart"
                          height="25"
                          width="25"
                          style="color: gray"
                        />
                      </a>
                      <a
                        href="javascript:void(0)"
                        (click)="
                          this.modal.showDeviceModal = true;
                          this.iotService.ShowDeleteThingModal(
                            deviceRow.thingName
                          )
                        "
                      >
                        <i class="fa-solid fa-trash delete-device"></i>
                        <!-- <img [title]="'deleteDevice' | translate"
                        class="iconDelete"
                        src="./assets/thing-delete-48.png"
                        alt="icon of a can"
                        height="24"
                        width="24"
                      /> -->
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </ng-container>
          </table>
        </div>
      </article>
    </section>
  </div>
  <app-device-modal-config
    *ngIf="this.iotService.showDeviceConfigModal"
    class="appDeviceConfigModal"
  ></app-device-modal-config>
  <app-device-modal
    *ngIf="this.modal.showDeviceModal"
    class="appDeviceModal"
  ></app-device-modal>
  <!-- DEVICES VIEW @ MOBILE -->
  <div class="show-small-screen hide-big-screen">
    <section
      [ngClass]="theme.getThemeClass()"
      class="table-container"
      *ngIf="mainContent === 'devices'"
    >
      <article class="table-responsive">
        <div
          colspan="5"
          id="search-component"
          (click)="this.show = false"
          class="input-group searchDiv"
          *ngIf="mainContent === 'devices'"
        >
          <input
            #searchBox
            type="search"
            id="search-box"
            [(ngModel)]="userFilter"
            (input)="
              filter.search(
                userFilter,
                this.iotService.devicesArray,
                this.iotService.getCountFilter()
              );
              filter.setCallingComponent('iotComponent')
            "
            class="form-control rounded searchInput"
            placeholder="{{ 'iotSearchPlaceHolderFilter' | translate }}"
            aria-label="Search"
            aria-describedby="search-addon"
          />
        </div>
        <nav
          [ngClass]="theme.getThemeClass()"
          class="table-header"
          *ngIf="mainContent === 'devices'"
        >
          <span class="left"
            >{{ "userlistTableHeaderDeviceList" | translate }}
            <a (click)="refreshList()"><i class="scale-0-8 fas fa-sync"></i></a>
          </span>
          <span class="right scale-0-9"
            ><a routerLink="/device-create" href="javascript:void(0)"
              >&#x2b; {{ "userlistTableHeaderNew" | translate }}
              <span class="hide-small-screen">{{
                "iotlistTableHeaderNewIot" | translate
              }}</span></a
            ></span
          >
        </nav>
        <table class="table table-hover">
          <thead>
            <tr>
              <th [ngClass]="theme.getThemeClass()" scope="col">
                <a
                  [ngClass]="theme.getThemeClass()"
                  (click)="this.sortItems('thingName')"
                  href="javascript:void(0)"
                >
                  {{ "iotTabHeaderDevice" | translate }} </a
                ><img
                  *ngIf="
                    this.sortDirection === 'asc' && this.sortBy === 'thingName'
                  "
                  src="./assets/arrow-up.png"
                  width="10px"
                  height="10px"
                /><img
                  *ngIf="
                    this.sortDirection === 'desc' && this.sortBy === 'thingName'
                  "
                  src="./assets/arrow-down.png"
                  width="10px"
                  height="10px"
                />
              </th>

              <!-- <th [ngClass]="theme.getThemeClass()" scope="col">
                <a
                  [ngClass]="theme.getThemeClass()"
                  (click)="this.sortItems('modal')"
                  href="javascript:void(0)"
                >
                  {{ "iotTabHeaderModel" | translate }} </a
                ><img
                  *ngIf="
                    this.sortDirection === 'asc' && this.sortBy === 'modal'
                  "
                  src="./assets/arrow-up.png"
                  width="10px"
                  height="10px"
                /><img
                  *ngIf="
                    this.sortDirection === 'desc' && this.sortBy === 'modal'
                  "
                  src="./assets/arrow-down.png"
                  width="10px"
                  height="10px"
                />
              </th> -->
              <th [ngClass]="theme.getThemeClass()" scope="col">
                {{ "iotTabHeaderStatus" | translate }}
              </th>
              <th
                [ngClass]="theme.getThemeClass()"
                class="hide-small-screen"
                scope="col"
              >
                {{ "iotTabHeaderLastActivity" | translate }}
              </th>
              <th [ngClass]="theme.getThemeClass()" scope="col">
                <i class="fas fa-search-location iconSearchMobile"></i>
              </th>
            </tr>
          </thead>
          <!-- table populated with data from array of devices -->
          <ng-container
            *ngFor="
              let deviceRow of iotService.devicesArray.slice(
                0,
                loadedItems + itemsPerPage
              );
              let i = index
            "
          >
            <tbody>
              <tr class="rowTableDataMobile">
                <td [ngClass]="theme.getThemeClass()">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#myModal"
                    href="javascript:void(0)"
                    (click)="
                      this.modal.getStatus(verifyStatus(deviceRow.thingName))
                    "
                    >{{ deviceRow.thingName }}</a
                  >
                </td>
                <!-- <td [ngClass]="theme.getThemeClass()">
                  {{
                    deviceRow.thingName.split("-")[0] +
                      "-" +
                      deviceRow.thingName.split("-")[1]
                  }}
                </td> -->
                <!-- For now status are unknown -->
                <td [ngClass]="theme.getThemeClass()">
                  <div
                    [ngClass]="{
                      healthy: verifyStatus(deviceRow.thingName) === 'Healthy',
                      unhealthy:
                        verifyStatus(deviceRow.thingName) === 'Unhealthy',
                      inactive: verifyStatus(deviceRow.thingName) === 'Inactive'
                    }"
                  >
                    {{ verifyStatus(deviceRow.thingName) | translate }}
                  </div>
                </td>
                <td class="hide-small-screen" [ngClass]="theme.getThemeClass()">
                  {{ deviceRow.date }}
                </td>
                <td [ngClass]="theme.getThemeClass()">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#myModal"
                    href="javascript:void(0)"
                    (click)="showMap(deviceRow.gps, deviceRow)"
                  >
                    <img
                      class="iconMap"
                      src="./assets/mappoint.png"
                      alt="icon of a map"
                      height="20"
                      width="20"
                    />
                  </a>
                  <a
                    href="javascript:void(0)"
                    (click)="
                      this.resetMap();
                      showMap(deviceRow.gps, deviceRow);
                      this.modal.getStatus(verifyStatus(deviceRow.thingName))
                    "
                  >
                    <img
                      [title]="'iotModalViewDetails' | translate"
                      class="iconMap"
                      src="./assets/details.png"
                      alt="icon of a list"
                      height="20"
                      width="20"
                    />
                  </a>
                  <a
                    href="javascript:void(0)"
                    (click)="this.iotService.showConfig(deviceRow.thingName)"
                  >
                    <img
                      class="iconParam"
                      src="./assets/settings.png"
                      alt="icon of param"
                      height="20"
                      width="20"
                      style="color: gray"
                    />
                  </a>
                  <a
                    href="javascript:void(0)"
                    (click)="
                      this.modal.showShadow(
                        this.iotService.getDeviceShadow(deviceRow.thingName),
                        deviceRow.thingName
                      )
                    "
                  >
                    <img
                      class="iconParam"
                      src="./assets/json-icon.png"
                      alt="icon of json"
                      height="20"
                      width="20"
                      style="color: gray"
                    />
                  </a>
                  <a
                    href="javascript:void(0)"
                    (click)="this.getCertificateFolderUrl(deviceRow.thingName)"
                  >
                    <img
                      [title]="'generateCertificate' | translate"
                      class="iconCert"
                      src="./assets/grey-certificate-48.png"
                      alt="icon of certificate"
                      height="25"
                      width="25"
                      style="color: gray"
                    />
                  </a>
                  <a
                    href="javascript:void(0)"
                    (click)="
                      this.showRelation(deviceRow.thingName);
                      this.modal.showRelation(deviceRow.thingName)
                    "
                  >
                    <img
                      [title]="'deviceRelationship' | translate"
                      class="iconCert"
                      src="./assets/relationship.png"
                      alt="icon of relationship"
                      height="25"
                      width="25"
                      style="color: gray"
                    />
                  </a>
                  <a href="/device-statistics/{{ deviceRow.thingName }}">
                    <img
                      [title]="'showReports' | translate"
                      class="iconStat"
                      src="./assets/bar-chart-48.png"
                      alt="icon of a chart"
                      height="25"
                      width="25"
                      style="color: gray"
                    />
                  </a>
                  <a
                    href="javascript:void(0)"
                    (click)="
                      this.modal.showDeviceModal = true;
                      this.iotService.ShowDeleteThingModal(deviceRow.thingName)
                    "
                  >
                    <i class="fa-solid fa-trash delete-device"></i>
                    <!-- <img [title]="'deleteDevice' | translate"
                        class="iconDelete"
                        src="./assets/thing-delete-48.png"
                        alt="icon of a can"
                        height="24"
                        width="24"
                      /> -->
                  </a>
                </td>
              </tr>
            </tbody>
          </ng-container>
        </table>
      </article>
    </section>
  </div>

  <!-- MODELS VIEW -->
  <div class="iot-table">
    <app-iot-typess
      *ngIf="mainContent === 'models'"
      class="container-fluido componentBelow"
    ></app-iot-typess>
  </div>

  <!-- ALERTS VIEW -->
  <div
    [ngClass]="theme.getThemeClass()"
    *ngIf="mainContent === 'alerts'"
    class="divSimpleViewConfig"
  >
    <section class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ "iotAlertsViewTabHeaderAlert" | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ "iotAlertsViewTabHeaderType" | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ "iotAlertsViewTabHeaderAccount" | translate }}
            </th>
            <th
              [ngClass]="theme.getThemeClass()"
              class="hide-small-screen"
              scope="col"
            >
              {{ "iotAlertsViewTabHeaderOwner" | translate }}
            </th>
          </tr>
        </thead>
        <!-- table populated with data from array of alerts -->
        <ng-container *ngFor="let alertRow of alertArray">
          <tbody>
            <tr class="tableVerticalMiddle">
              <td [ngClass]="theme.getThemeClass()">
                <a href="javascript:void(0)">{{ alertRow.Alert }}</a>
              </td>
              <td [ngClass]="theme.getThemeClass()">{{ alertRow.Type }}</td>
              <td [ngClass]="theme.getThemeClass()">{{ alertRow.Account }}</td>
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">
                {{ alertRow.Owner }}
              </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </section>
  </div>
  <!-- Reports VIEW -->
  <div
    [ngClass]="theme.getThemeClass()"
    *ngIf="mainContent === 'reports'"
    class="divReportViewConfig"
  >
    <h4 class="filter-title">{{ "filters" | translate }}</h4>
    <nav class="filter-bar">
      <!-- Device Statistics Filters -->
      <div class="device-filter">
        <div class="form-group">
          <div class="force-column">
            <label class="form-label" id="deciveStats">{{
              "deviceStatistics" | translate
            }}</label>
            <ng-select
              disabled="true"
              [(ngModel)]="statistic"
              class="form-control ng-select"
            >
              <ng-option disabled value="Collections" selected>{{
                "collections" | translate
              }}</ng-option>
            </ng-select>
          </div>
        </div>

        <!-- Device Statistics Filters -->
        <!--Device Date pickers-->
        <div class="device-filter">
          <div class="form-group">
            <div class="force-column">
              <label class="form-label">{{ "startingDate" | translate }}</label>
              <input [(ngModel)]="startDate" class="form-control" type="date" />
            </div>
          </div>

          <div class="form-group">
            <div class="force-column">
              <label class="form-label">{{ "endDate" | translate }}</label>
              <input [(ngModel)]="endDate" class="form-control" type="date" />
            </div>
          </div>
        </div>

        <div class="toggle-stack">
          <div class="toggle-box">
            <label class="devices-toggle">{{ "allDevices" | translate }}</label>
            <label class="markets-toggle">{{ "allMarkets" | translate }}</label>
          </div>

          <div class="filter-toggle">
            <div class="custom-control custom-switch cs-container">
              <!-- Rounded switch -->
              <label class="switch">
                <input
                  type="checkbox"
                  [ngModel]="allThings"
                  name="devices"
                  (change)="onChangeAllThings($event)"
                />
                <span class="slider round"></span>
              </label>
            </div>

            <div class="custom-control custom-switch cs-container">
              <!-- Rounded switch -->
              <label class="switch">
                <input
                  type="checkbox"
                  [ngModel]="allMarkets"
                  name="markets"
                  (change)="onChangeAllMarkets($event)"
                  [disabled]="true"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!allThings" class="form-group">
        <div class="force-column">
          <label class="form-label">{{ "devices" | translate }}</label>
          <ng-select
            [(ngModel)]="selectedThings"
            class="form-control ng-select"
            [multiple]="true"
          >
            <ng-container *ngFor="let option of this.things">
              <ng-option value="{{ option.id }}"> {{ option.name }}</ng-option>
            </ng-container>
          </ng-select>
        </div>
      </div>

      <!-- Device Statistics Filters -->
      <div *ngIf="!allMarkets" class="form-group">
        <div class="force-column">
          <label class="form-label">{{ "marketSegments" | translate }}</label>
          <ng-select
            disabled="true"
            [(ngModel)]="selectedMarkets"
            [multiple]="true"
            class="form-control ng-select"
          >
            <ng-option disabled value="All" selected>{{
              "allMarkets" | translate
            }}</ng-option>
          </ng-select>
        </div>
      </div>
    </nav>
    <div class="btn-holder col-3">
      <button
        [ngClass]="theme.getThemeClass()"
        class="form-control btn btn-primary btn-bloc btn-second"
        (click)="compileReport()"
        [disabled]="pdf.generatingPDF"
      >
        <span *ngIf="!pdf.generatingPDF">
          {{ "printPDF" | translate }}
        </span>
        <div
          class="spinner-border spinner-position spinner-button"
          role="status"
          *ngIf="pdf.generatingPDF"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
      <button
        [ngClass]="theme.getThemeClass()"
        class="form-control btn btn-primary btn-bloc btn-second"
        (click)="exportCSVReports()"
        [disabled]="generatingCSV"
      >
        <span *ngIf="!generatingCSV">
          {{ "exportCSV" | translate }}
        </span>
        <div
          class="spinner-border spinner-position spinner-button"
          role="status"
          *ngIf="generatingCSV"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </div>
  </div>

  <!-- ACTIONS VIEW - UNUSED -->
  <div
    [ngClass]="theme.getThemeClass()"
    *ngIf="mainContent === 'actions'"
    class="divSimpleViewConfig"
  ></div>
</div>

<!-- Graph Canvas for PDF Report -->
<div id="canvas-holder" style="max-height: 300px; overflow-y: auto">
  <canvas #tonnageCanvas style="display: none"></canvas>
  <canvas #usageCanvas style="display: none"></canvas>
  <canvas #marketCanvas style="display: none"></canvas>
  <canvas #countCanvas style="display: none"></canvas>
</div>
