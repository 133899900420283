<div *ngIf="this.binModal.showModal" class="binModel" [ngClass]="theme.getThemeClass()">
    <h4>{{ "binModalViewBinModelInfoTitle" | translate }}</h4>
    <!-- Portion that have al the bin model selected options -->
    <section [ngClass]="theme.getThemeClass()" class="border col-8">
        <article [ngClass]="theme.getThemeClass()" class="items">
            <label class="title">{{ "binModalViewBinModelNumber" | translate }}</label><br>
            <label>{{ this.binModal.binModel.bin_model_number }}</label>
        </article>
        <article [ngClass]="theme.getThemeClass()" class="items">
            <label class="title">{{ "binModalViewBinModelShape" | translate }}</label><br>
            <label>{{ this.binModal.binModel.bin_shape === 'S' ?  ("binModelUpdateOptionSquare" | translate)  :  ("binModelUpdateOptionCylinder" | translate) }}</label>
        </article>
        <article [ngClass]="theme.getThemeClass()" class="items">
            <label class="title">{{ "binModalViewBinModelWidth" | translate }}</label><br>
            <label>{{ this.binModal.binModel.bin_width }} mm</label>
        </article>
        <article [ngClass]="theme.getThemeClass()" class="items">
            <label class="title">{{ "binModalViewBinModelHeight" | translate }}</label><br>
            <label>{{ this.binModal.binModel.bin_height }} mm</label>
        </article>
        <article [ngClass]="theme.getThemeClass()" class="items">
            <label class="title">{{ "binModalViewBinModelDepth" | translate }}</label><br>
            <label>{{ this.binModal.binModel.bin_depth }} mm</label>
        </article>
        <article [ngClass]="theme.getThemeClass()" class="items">
            <label class="title">{{ "binModalViewBinModelTotalVolume" | translate }}</label><br>
            <label>{{ this.binModal.binModel.total_volume }} L</label>
        </article>
        <article [ngClass]="theme.getThemeClass()" class="items">
            <label class="title">{{ "binModalViewBinModelTotalUsed" | translate }}</label><br>
            <label><a (click)="this.binModal.getBinNumber();">{{ this.binModal.binModel.number_of_bin }}</a></label>
        </article>
      </section>
    <button class="close col-6" (click)="this.binModal.closeModal()">{{ "iotModalButtonClose" | translate }}</button>
</div>
