
<div [ngClass]="theme.getThemeClass()">
  <!-- Section for header and filter -->
  <header class="page-header">
    <!-- For future development -->
    <!-- <button class="btn back" (click)="backToDashboardList()"><i class="fa fa-arrow-left"></i></button> -->
    <h2>{{"binModelListViewBinModelList" | translate}}</h2>
  </header>
    <nav
    id="search-component"
    class="nav-sort-filter"
  >
    <input
      #searchBox
      type="search"
      id="search-box"
      [(ngModel)]="userFilter"
      (input)="
        this.search()
      "
      class="form-control rounded searchInput"
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}"
      aria-label="Search"
      aria-describedby="search-addon"
    />
    <ng-select class="form-control ng-select sort-by-select" [(ngModel)]="this.sortBy"
      (change)="onSortChange('select', $event)" placeholder="{{ 'sortBy' | translate }}" [ngClass]="{'has-value': this.sortBy}">
      <ng-option value="" disabled selected>{{ "sortBy" | translate }}</ng-option>
          <ng-option value="number">{{ "binModalViewBinModelNumber" | translate }}</ng-option>
          <ng-option value="volume">{{ "binModelUpdateViewTotalVolume" | translate }}</ng-option>
          <ng-option value="numberBin">{{ "numberOfBins" | translate }}</ng-option>
          <ng-option value="thing">{{ "haveDevice" | translate }}</ng-option>
    </ng-select>
    <div class="card-title mt-0 status" *ngIf="this.isSortBy">
      <label class="switch">
          <input type="checkbox" [ngModel]="this.ascDesc" (change)="onSortChange('switch', $event)">
          <span class="slider round">
            <span class="toggle-switch-label-off">{{ "asc" | translate }}</span>
            <span class="toggle-switch-label-on">{{ "desc" | translate }}</span>
          </span>
      </label>
  </div>
  </nav>
  <div class="grid-container">
    <section [ngClass]="theme.getThemeClass()" class="gridster" [ngStyle]="getColumnStyle()" style="height: 100vh!important;">
      <ng-container *ngFor="let item of this.uniqueBinModelArray">
      <article class="gridster-item">
        <!-- Your tile content goes here -->
          <nav [ngClass]="theme.getThemeClass()" class="dashboard-tile">
            <header class="dashboard-tile-header">
              <h5 class="dashboard-tile-title"><a [ngClass]="theme.getThemeClass()" [routerLink]="['/bin-model-update', item.bin_model_id]">{{item.bin_model_number | translate}}</a></h5>
              <a [ngClass]="theme.getThemeClass()" class="number-of-bins" (click)="goToDistributorBinDashboard(item.bin_model_id)">
                <i class="fa fa-trash trash-icon textAlignCenter"></i>
                <label class="label-clickable">{{item.number_of_bin}}</label>
              </a>
            </header>
            <hr>
            <div class="tiles-container">
              <div class="titles-items-above-ground col-2">
                <img src="../../assets/above_ground.png" *ngIf="item.above_ground === 1">
                <i class="fa-solid fa-microchip device-icon" *ngIf="item.with_thing"></i>
              </div>
              <div class="titles-items-dimensions col-10">
                <div class="shapes col-2">
                  <i *ngIf="item.bin_shape === 'S'" class="fa fa-cube"></i>
                  <img src="../../assets/cylinder.png" *ngIf="item.bin_shape === 'C'">
                </div>
                <div class="dimensions col-10">
                  <div class="bin-model-dimensions">
                    <i class="fa fa-arrows-h" *ngIf="item.bin_width !== null"></i><label class="item-label">{{ item.bin_width }}</label>
                    <i class="fa fa-arrows-v"></i><label class="item-label">{{ item.bin_height }}</label>
                    <img *ngIf="item.bin_depth !== null" src="../../assets/depth.png" class="depth"><label class="item-label">{{ item.bin_depth }}</label>
                  </div>
                  <div class="bin-model-volume">
                    <i class="fa fa-v"></i><label class="item-label">{{ item.total_volume }}</label>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </article>
    </ng-container>
  </section>
  </div>
</div>
