<div [ngClass]="theme.getThemeClass()" class="container-fluid">

  <!-- Nav Filter Search  -->
  <nav id="search-component" class="input-group">
    <input #searchBox type="search"  id="search-box" [(ngModel)]="userFilterTest" (input)="search()" class="form-control" 
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}" aria-label="Search" aria-describedby="search-addon" />
  </nav>  

  <!-- Header for the work order list -->
  <header [ngClass]="theme.getThemeClass()" class="table-header" >
    <span class="left">{{ 'workOrderList' | translate }}
        <a (click)="refreshList()"><i class="fas fa-sync"></i></a>
    </span>
    <span class="right">        
        <a class="lastBtnStyle" routerLink="/work-order-create" href="javascript:void(0)">&#x2b; {{ 'userlistTableHeaderNew' | translate }}
            <span class="hide-small-screen">{{ 'newWorkOrder' | translate }}</span>
        </a>
    </span>
  </header>

  <!-- Section for work order list -->
  <section [ngClass]="theme.getThemeClass()" class="table-container"  >
    <article  class="table-responsive minMarginBotton">
      <table  class="table table-hover">
        <thead >
          <tr>            
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ 'workOrderTypeth' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
                {{ 'workOrderStatusth' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'workOrderDatetime' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ 'workOrderDeadline' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ '_client' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                {{ 'createdBy' | translate }}
            </th>
          </tr>
        </thead>
        <ng-container  *ngFor="let order of workOrderListArray">
          <tbody>
            <tr class="verticalAlign">
              <!-- Order Type -->
              <td [ngClass]="theme.getThemeClass()">
                <a (click)="identifyUserType(order)" [routerLink]="" href="javascript:void(0)">
                    {{ orderTypeMap[order.work_order_type] }}
                </a>
              </td>
              <!-- Status -->
              <td [ngClass]="theme.getThemeClass()">
                <a [routerLink]="" href="javascript:void(0)">
                    {{ orderStatusMap[order.status] }}
                </a>
              </td>
              <!-- Datetime -->
              <td class="hide-small-screen" [ngClass]="theme.getThemeClass()">
                <a [routerLink]="" href="javascript:void(0)">
                  {{ formatTimestamp(order.work_order_datetime) }}
                </a>
              </td>
              <!-- Deadline -->
              <td [ngClass]="theme.getThemeClass()">
                <a [routerLink]="" href="javascript:void(0)">
                    {{ formatTimestamp(order.work_order_deadline) }}
                </a>
              </td>
              <!-- Client -->
              <td [ngClass]="theme.getThemeClass()">
                <a [routerLink]="" href="javascript:void(0)">
                    {{ getClientNameById(order.client_id) }}
                </a>
              </td>
              <!-- Created By -->
              <td class="hide-small-screen" [ngClass]="theme.getThemeClass()">
                <a [routerLink]="" href="javascript:void(0)">
                    {{ this.cognitoService.getUserNameBySub(order.created_by) }}
                </a>
              </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </article>
  </section>
</div>









