import { Component, OnInit, ElementRef, HostListener, Inject, forwardRef } from '@angular/core';
import { RoleService } from '../service/role.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { ClientService } from '../service/client.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../service/validation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from '../service/theme.service';
import { NavigationService } from '../service/navigation.service';
import { Roles } from '../constants/roles'
import { lastValueFrom } from 'rxjs';
import { SystemMessageService } from '../service/system-message.service';
import { LocalizationService } from '../service/localization.service';
import { environment } from '../environments/environment';


interface Role {
  value: string;
  label: string;
}

interface UserRole {
  user: string;
  role: number;
  label?: string; // Adicionamos a propriedade label como opcional
}

@Component({
  selector: 'app-user-update-distributor',
  templateUrl: './user-update-distributor.component.html',
  styleUrls: ['./user-update-distributor.component.css']
})
export class UserUpdateDistributorComponent implements OnInit {

  // Variables used for array
  public roleUserArray: any[] = [];
  public userFound: any;
  public cognitoUsersArray: any[] = [];
  private userRolesNumber: any[] = [];
  public userRoles: UserRole[] = [];

  Roles = Roles;

  public form: FormGroup;

  public validateRolesCheckBox: boolean = false;

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  public userSub: any;
  public userUsername: any;
  public userType: any;

  constructor(
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    public cognitoService: CognitoService,
    public roleService: RoleService,
    public clientService: ClientService,
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private router: Router,
    public theme: ThemeService,
    public navigationService: NavigationService,
    private route: ActivatedRoute,
    public systemMessageService: SystemMessageService,
    public localizationService: LocalizationService

  ) {

    this.route.queryParams.subscribe(params => {
      this.userSub = params['userSub'] || params['user'];
      this.userUsername = params['userUsername'];
      this.userType = params['userType'];
  });

    // Create a form using the FormBuilder
    this.form = this.formBuilder.group({
      // Define form controls and their initial values
      client_name: ['', Validators.required],
      legal_name: [''],
      email: ['', [Validators.required, Validators.email]],
      phone_number: [''],
      address: ['']
    });

    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  // Decorator @HostListener listens to the 'input' event on the specified target element
  @HostListener('input', ['$event.target']) onInput(input: HTMLInputElement): void {
    // Check if the input element has the class 'telefone-input'
    if (input.classList.contains('telefone-input')) {
      // Remove all non-numeric characters from the input value
      const value = input.value.replace(/\D/g, '');

      // Check if the value has a length of 10 characters or less
      if (value.length <= 10) {
        // Format the phone number as (000) 000-0000
        input.value = this.validationService.formatPhoneNumber(value);
      } else {
        // If the value is longer than 10 digits, limit the input to 10 digits
        input.value = input.value.slice(0, 10);
      }
    }
  }

  // Asynchronous method to initialize the component
  async ngOnInit(): Promise<void> {
    // Confirm and get role for the current user
    await this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();

    await this.roleService.getRoles();
    await this.cognitoService.getCurrentRole([environment.users.role.administrator], [environment.users.superAdmin, environment.users.supplier], true, this.roleService.roles);


    // Reset user role array in role service
    this.roleService.userRoles = [];

    // Clear any lingering data related to user creation
    this.cognitoService.clearCognitoUserData(); // clear last client create data

    // Will do an getAllUsers only if the cognito user array is empty to avoid making more calls to the cognito service
    if(this.cognitoService.cognitoUsersArray.length === 0){
      // Call the function to get all user from cognito
      await this.cognitoService.getAllUsers();
    }

    // Assign the array of Cognito users to a local variable
    this.cognitoUsersArray = this.cognitoService.cognitoUsersArray;

    // Find the user with a matching 'sub' (subject) in the Cognito user array
    this.userFound =  this.cognitoUsersArray.find(item => item.sub === this.userSub);

    // If the user is found, populate user data
    if(this.userFound) {
      // Populate the cognito user data
      this.populateCognitoUserData();

      try {
        // Retrieve user roles for the current user
        const sub = this.cognitoService.cognitoUserData.sub;
        const response = await lastValueFrom(await this.roleService.getRolesForUser(sub));

        // Map all role number to the userRoleNuber array to implement the userRoles array used in HTML
        this.userRolesNumber = response.map((item: any) => item.role);
          // If user roles exist and are an array, process and filter them
          if (this.userRolesNumber && Array.isArray(this.userRolesNumber)) {
            // Map user roles to their corresponding labels
            this.userRoles = this.userRolesNumber.map((userRole: any) => {
              const roleDefinition = Roles.find((role: Role) => role.value === String(userRole));
              return roleDefinition ? { user: sub, role: Number(roleDefinition.value), label: roleDefinition.label } : null;
            }).filter(role => role !== null) as UserRole[];
          }

        // Set userRoles in role service as same of the userRoles array of the component
        this.roleService.userRoles = this.userRoles;
      } catch (error) {
        // Handle errors during role retrieval
        console.error('Error:', error);
      }
      // Update user roles based on the processed data
      this.updateUserRoles();
    }
    // Call the function in localization service to implement the auto complete of the address input
    this.localizationService.initAutocomplete();

    // Function called automatically when a change is made in role service by chnaging a role switch
    this.roleService.userRoleChange$.subscribe(() => {
      this.userRoles = this.roleService.userRoles;
    });
  }

  // Function called when user save the change made
  async saveUser(){
    if(this.userRoles && this.userRoles.length > 0) {
      this.validateRolesCheckBox = true;
    }
    if(this.localizationService.autoCompletionReturnAddress !== ''){
      // Set the address of the cognito user data
      this.cognitoService.cognitoUserData.address = this.localizationService.autoCompletionReturnAddress;
    }

    // Check validation for all input fields
    this.checkValidationAllInputs();
    //Check if any of the validation flags are false, indicating validation errors
    if (
      !this.validationService.givenNameValid ||
      !this.validationService.familyNameValid ||
      !this.validationService.clientPhoneNumberValid ||
      !this.validationService.clientAddressValid ||
      !this.validateRolesCheckBox) {
        if(!this.validateRolesCheckBox){
          // Throw an error message to the user if there's no roles selected
          this.systemMessageService.selectRibbon('danger', 'leastOneRoleSelected');
        }else{
          // Throw an error message to the user if there's a invalid value
          this.systemMessageService.selectRibbon('danger', 'fieldEmptyOrIncorrect');
        }

        // will update the attributes of the cognito user if all values are valid
      } else {
        // Update user attributes in cognito if there is no errors in validation
        const updateUserResult = await this.cognitoService.updateUserAttributesCognito(this.cognitoService.cognitoUserData.username)

        // delete all outdated roles from user
        try {
          if(updateUserResult){
            // Get all user roles and will do changes only if there a difference between userRoles array and the role in DB
            (await this.roleService.getRolesForUser(this.userRoles[0].user)).subscribe(async roleNumberArray => {
              if(roleNumberArray.length !== this.userRoles.length){
                // Call the function to delete all user roles
                await this.roleService.deleteAllUserRole(this.userRoles[0].user);

                for (const element of this.userRoles) {
                  try {
                    // Set the new roel to the user in the DB
                    await this.roleService.updateUserRole(element.user, element.role);

                  } catch (error) {
                    console.error('Error updating user role:', error);
                  }
                }
              }
            });

            // Set the routing of the user depend on witch user type he is
            if(this.cognitoService.userType === 'muirwood'){
              this.router.navigate(['/admin']); // Redirect to the '/admin' route after the delay
            }else{
              this.router.navigate(['/distributor-admin-dashboard']);
            }
          }
        } catch(error) {
          console.error('Error deleting all user role:', error);
        }
      }
  }

  // Function that disable user access to the app
  async disableUser() {

    // Get the translation of the confirmation message
    const confirmationMessage$ = this.translate.get('questionEnable/DisableUser');
    const confirmationMessage = await lastValueFrom(confirmationMessage$);

    // Display a confirmation dialog to the user
    const isConfirmed = window.confirm(confirmationMessage);

    // Check if the user confirmed the deletion
    if (isConfirmed) {
      try {
        // If successful, proceed to delete the user
        const disabledResult = await this.cognitoService.disableUserAccess(this.cognitoService.cognitoUserData.username);

        if(disabledResult === 'success'){
          this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
          sessionStorage.setItem('from', 'user-update');
          sessionStorage.setItem('previous', 'user-List')
          //this.router.navigate(['/admin']); // Redirect to the '/admin' route after the delay
        } else {
          this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
        }
      } catch (error) {
        // Handle any errors that occur during the deletion process
        console.error('Error deleting user roles:', error);
        // Handle the error appropriately, depending on your use case.
      }
    }
  }

  // Function that enable user acces to the app
  async enableUser(){
    // Get the translation of the confirmation message
    const confirmationMessage$ = this.translate.get('questionEnable/DisableUser');
    const confirmationMessage = await lastValueFrom(confirmationMessage$);

    // Display a confirmation dialog to the user
    const isConfirmed = window.confirm(confirmationMessage);

    // Check if the user confirmed the deletion
    if (isConfirmed) {
      try {
        // If successful, proceed to delete the user
        const enabledResult = await this.cognitoService.enableUserAcces(this.cognitoService.cognitoUserData.username);
        if(enabledResult === 'success'){
          this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
          sessionStorage.setItem('from', 'user-update');
          sessionStorage.setItem('previous', 'user-List')
          //this.router.navigate(['/admin']); // Redirect to the '/admin' route after the delay
        } else {
          this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
        }
      } catch (error) {
        // Handle any errors that occur during the deletion process
        console.error('Error deleting user roles:', error);
        // Handle the error appropriately, depending on your use case.
      }
    }
  }

  // runs a validation test checking every inputs
  checkValidationAllInputs() {

    // Check if given_name is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateGivenName(this.cognitoService.cognitoUserData.given_name);

    // Check if family_name is not empty, its length is greater than 16,
    // or it doesn't match the alphanumeric pattern
    this.validationService.validateFamilyName(this.cognitoService.cognitoUserData.family_name);

    // Check if phoneNumber is not empty and its length is less than 10 or if it's empty
    this.validationService.validatePhoneNumber(this.cognitoService.cognitoUserData.phone_number);

    // Check if the address is empty
    this.validationService.validateAddress(this.cognitoService.cognitoUserData.address);

    // Define a mapping of error codes to corresponding error messages
    const errorMappings: Record<string, string> = {
      'clientInputError': 'invalidClientSelection',
      'roleSelectionInvalid': 'invalidRoleSelection',
      'givenNameInvalid': 'invalidGivenNameError',
      'familyNameInvalid': 'invalidFamilyNameError',
      'clientPhoneInvalid': 'invalidInput',
      'clientAddressInvalid': 'invalidAddress',
      'usernameNotUnique': 'usernameInUse',
    };

    // Retrieve the first validation error from the array
    const validationError = this.validationService.validationErrorArray[0];

    // Check if the validation error code exists in the mapping
    if (errorMappings[validationError]) {
      // If so, display a danger ribbon message with the corresponding key
      this.systemMessageService.selectRibbon('danger', errorMappings[validationError]);
    }
  }

  // Function called from the ngOnInt function to populate cognito user data
  populateCognitoUserData(){
    // Populate user data from the found Cognito user
    this.cognitoService.cognitoUserData.username = this.userFound.username;
    this.cognitoService.cognitoUserData.given_name = this.userFound.given_name;
    this.cognitoService.cognitoUserData.family_name = this.userFound.family_name;
    this.cognitoService.cognitoUserData.email = this.userFound.email;
    this.cognitoService.cognitoUserData.phone_number = this.validationService.formatPhoneNumber(this.userFound.phone_number);
    this.cognitoService.cognitoUserData.address = this.userFound.address;
    this.cognitoService.cognitoUserData.custom_current_role = this.userFound.custom_current_role;
    this.cognitoService.cognitoUserData.custom_role = this.userFound.custom_role;
    this.cognitoService.cognitoUserData.sub = this.userFound.sub;
    this.cognitoService.cognitoUserData.enabled = this.userFound.enabled;
    this.cognitoService.cognitoUserData.custom_user_type = this.userFound.custom_user_type;
    this.cognitoService.cognitoUserData.custom_client_id = this.userFound.custom_client_id || '';
    this.cognitoService.cognitoUserData.custom_distributor_id = this.userFound.custom_distributor_id || '';
    this.cognitoService.cognitoUserData.custom_operator_id = this.userFound.custom_operator_id || '';
    this.cognitoService.cognitoUserData.client_name = this.userFound.client_name || '';
  }

  // function to reload page when cancel button is clicked
  cancel() {
    this.localStorageService.addItem('adminContent', 'userList')
    this.router.navigate(['/admin']); // Redirect to the '/admin' route after the delay
  }

  // Function called by distributor to go back to his user list
  goToDistributorUserList(){
    this.router.navigate(['./distributor-admin-dashboard']);
  }

  // Function called to implement the labels for userRoles object array
  updateUserRoles(): void {
    this.userRoles = this.userRoles.map(this.roleService.addLabel);
  }

  // Function called from html to know witch roles is selected
  isRoleChecked(roleLabel: string): boolean {
    return this.userRoles && this.userRoles.some((role: { label?: string }) => role.label === roleLabel);
  }

  // Function that avoid submitting the page when user press enter at the end of inputting address in address input
  onInputAddressKeydown(event: KeyboardEvent): void {
    if(event.key === 'Enter'){
      event.preventDefault(); // Don't submit the page
    }
  }

  // Function to change the language based on user selection
  changeLanguage(language: string) {
    // Use the 'translate' service to set the language to the specified 'language'
    this.translate.use(language);
  }
}
