import { ErrorModalService } from './../service/error-modal.service';

import { Component } from '@angular/core';
import { CognitoService } from '../service/cognito.service'; // , IUser
import { OnInit,Inject, forwardRef } from '@angular/core' // NgModule, ViewChild
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '../service/role.service';
import { map } from 'rxjs/operators'; // Import map from 'rxjs/operators'
import { IotService } from '../service/iot.service';
import { ClientService } from '../service/client.service';
import { BinsService } from '../service/bins.service';
import { Shadow } from '../constants/shadow';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';
import { error } from 'jquery';
import { Router } from '@angular/router';
import { S3Service } from '../service/s3.service';
import { firstValueFrom } from 'rxjs';


interface MarkerProperties {
  position: {
    lat: number;
    lng: number;
  }
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {


   // Variable to store the language selected value
  public selectedLanguage: string = "";

  // public currentLanguage:string = "";

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  constructor(
    public cognitoService: CognitoService,
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private iotService: IotService,
    public theme: ThemeService,
    public systemMessage: SystemMessageService,
    private errorModal: ErrorModalService
    ){

      // Check if the user has selected a language in local storage
      //or use a default language
      if (this.languageStatus == null){
        // Set the default language to French
        translate.use('fr');
      } else {
        // Set the default language to the user's selected language
        translate.use(this.languageStatus);
      }
  }

  async ngOnInit() {
    await this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();
  }


  async getMessage(): Promise<string>{
    const message = await this.systemMessage.getMessageText('iotModalViewDeviceHibernationST');
    return message;
  }

  initErrorModal(){
    this.errorModal.setShowErrorModal();
    this.errorModal.isShowErrorModal();
    this.errorModal.setErrorDetails("Error", "28 Chemin de la Côte Saint-Louis Ouest, suite 110-111, Blainville, Quebec J7C 1B8 28 Chemin de la Côte Saint-Louis Ouest, suite 110-111, Blainville, Quebec J7C 1B8")
  }

  // Change language based on user selection
  changeLanguage(language: string) {
    this.translate.use(language);
  }


}
