import { Component, HostListener } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { navigation } from '../constants/navigation';
import { DashboardService } from '../service/dashboard.service';
import { environment } from '../environments/environment';
import { ChartService } from '../service/chart.service';
import { CognitoService } from '../service/cognito.service';

@Component({
  selector: 'app-distributor-dashboard',
  templateUrl: './distributor-dashboard.component.html',
  styleUrls: ['./distributor-dashboard.component.css', '../../dashboard-tiles.css']
})
export class DistributorDashboardComponent {
  gridsterOptions = {
    draggable: {
      enabled: true,
    },
    resizable: {
      enabled: true,
    },
    minCols: 1,
    maxCols: 3,
    minRows: 1,
    maxRows: 2,
    defaultItemCols: 1,
    defaultItemRows: 1,
  };

  public navigationItemsArray: any = [];

  // Variable for the size of the screen
  public isSmallScreenWidth: boolean = this.getScreenWidth() <= 859;

  public stopLoading: boolean = false;

  constructor(public theme: ThemeService,
              private dashboard: DashboardService,
              public charts: ChartService,
              private cognitoService: CognitoService){

  }

  async ngOnInit(){
    this.navigationItemsArray = navigation.filter(item => item.userType === environment.users.supplier);
    this.dashboard.initArrays();

    //Get current screen width on init
    const currentScreenWidth = this.getScreenWidth();
    const currentScreenHeight = this.getScreenHeight();

    // Check the screen width and update the isSmallScreen flag accordingly
    this.isSmallScreenWidth = currentScreenWidth <= 859;

    //Adjust chart parameters for view size
    this.charts.adjustView(currentScreenWidth, currentScreenHeight);

    this.initialize();

    this.stopLoading = true;
    console.log(this.cognitoService.distributorId);
  }

  // Event listener for window resize
  @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
      // get the current window width and height
      const currentScreenWidth = this.getScreenWidth();
      const currentScreenHeight = this.getScreenHeight();
      // Check the screen width and update the isSmallScreen flag accordingly
      this.isSmallScreenWidth = currentScreenWidth <= 859;

      //Adjust chart parameters for view size
      this.charts.adjustView(currentScreenWidth, currentScreenHeight);
  }

  async initialize(){
    await this.dashboard.initializaDistributorDashboardData();
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  // Funciton to get the current screen height
  getScreenHeight(): number {
    // Retunr the current screen height of the window
    return window.innerHeight;
  }
}
