<app-system-message></app-system-message>

<div [ngClass]="theme.getThemeClass()" class="container-fluid">
  <header id="search-component" class="input-group">
    <input #searchBox type="search" id="search-box" [(ngModel)]="userFilterTest" (input)="search()"
      class="form-control rounded" placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}" aria-label="Search"
      aria-describedby="search-addon" />
  </header>

  <nav [ngClass]="theme.getThemeClass()" class="table-header">
    <span class="left customScale1">{{ 'Device Model List' | translate }}
      <a (click)="refreshList()"><i class="fas fa-sync"></i></a>
    </span>
    <!-- Remove the New Client button -->
  </nav>

  <section [ngClass]="theme.getThemeClass()" class="table-container width100">
    <article class="table-responsive minMarginBotton">
      <table class="table table-hover">
        <thead>
          <tr>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ 'Thing Type' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'Model' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ 'Count' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let device of novoArrayDeDispositivos" class="verticalAlignMiddle">
            <td [ngClass]="theme.getThemeClass()">
              <a [routerLink]="['/client-edit', device[0]]" href="javascript:void(0)">{{ device[0] }}</a>
            </td>
            <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">
              <span *ngFor="let modelName of device.slice(1)">
                {{ modelName }}<br /> <!-- Display model names on separate lines -->
              </span>
            </td>
            <td [ngClass]="theme.getThemeClass()">
              <div class="count-container">
                <div class="count-value">{{ this.getDeviceCount(device[0]) || 0 }}</div>
                <div class="count-value">{{ this.getDeviceCount(device[1]) || 0 }}</div>
              </div>
            </td>
            <!-- Add the remaining columns as needed -->
          </tr>
        </tbody>
      </table>
    </article>
  </section>
</div>
