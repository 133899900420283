import { Component, OnInit } from '@angular/core';
import { NotificationService,Notification } from '../service/notification.service';
import { ThemeService } from '../service/theme.service';
import { LocalStorageService } from '../local-storage.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})

export class NotificationListComponent implements OnInit {

  notifications: Notification[]=[];

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  constructor(
    private notificationService: NotificationService,
    public theme: ThemeService,
    private localStorageService: LocalStorageService,
    )
    { }

  // Get current theme from localstorage
  themeStatus: string = this.localStorageService.getItem('theme');


  formatTimestampToEasternTime(timestamp: number): string {
    if (!timestamp) return '';

    const timestampMilliseconds = timestamp * 1000;
    const date = new Date(timestampMilliseconds);

    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });

    return formatter.format(date);
  }

  ngOnInit(): void {
    this.notifications = this.notificationService.getNotifications();
    this.theme.getThemeClass();
  }
}
