import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { error } from 'jquery';
import { BinUsage } from '../constants/bin-usage';
import { BinShapes } from '../constants/bin-shapes';
import { WorkOrderStatus } from '../constants/work-order-status';
import { WorkOrderTypes } from '../constants/work-order-types';

export interface Distributors {
  name: string;
  legalName: string;
  phoneNumber: string;
  address: string;
  logo: string;
  email: string;
  created: string;
  modified: string;
}

@Injectable({
  providedIn: 'root'
})
export class DistributorsService {

  distributorData = {
    distributor_id: "",
    distributor_name: "",
    legal_name: "",
    phone_number: "",
    address: "",
    email: "",
    created: "",
    modified: ""
  };

  public distributorsArray: any[] = [];
  public distributorUsersArray: any[] = [];
  public distributorBinsDashboardArray: any[] = [];
  public distributorIotDashboardArray: any[] = [];
  public distributorWorkOrderArray: any[] = [];
  public binArray: any[] = [];

  public dateFixed: any;

  public array: any;

  constructor(private http: HttpClient) {}

  // Function called to replace bin usage key by there proper translation key
  replaceBinUsageKey(): void{
    this.distributorBinsDashboardArray.forEach(item => {
      const binUsage = BinUsage.find(usage => usage.value === item.bin_usage);
      if(binUsage){
        item.bin_usage = binUsage.label;
      }
    });
  }

  // function called to replace work order status and type key by proper translation key
  // replaceWorkOrderStatusAndTypeAndDates(){
  //   this.distributorWorkOrderArray.forEach(item => {
  //     const status = WorkOrderStatus.find(status => status.value === item.status);
  //     const type = WorkOrderTypes.find(types => types.value === item.work_order_type);
  //     if(status){
  //       item.status = status.label;
  //     }
  //     if(type){
  //       item.work_order_type = type.label;
  //     }
  //   })
  // }

  switchJSONToIntForBinModelResponse(element: any){
    // Tableau of witch keys we need to check
    const keysToCheck = [
      'above_groud',
      'active',
      'bin_height',
      'bin_width',
      'bin_depth',
      'total_volume',
      'with_thing',
      'number_of_bin',
      'created',
      'modified'
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  switchJSONToIntForUserResponse(element: any){
    // Tableau of witch keys we need to check
    const keysToCheck = [
      'created',
      'modified'
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  keyToTransfert(keysToCheck: any, element: any){
    keysToCheck.forEach((key: any) => {
      // Vérifier si la clé existe dans l'élément et si sa valeur est une chaîne
      if (typeof element[key] === 'string') {
          // Convertir la valeur de la clé en entier
          element[key] = parseInt(element[key], 10);
      }
    });
  }

  // Function async used for select in modals to insure that select are implemented
  getDistributorForSelect(): Promise<any[]>{
    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.getDistributors().subscribe((response) => {
        this.array = response;
        this.distributorsArray = this.array;
        resolve(this.distributorsArray);
      });
    });
  }

  async getBinModelAssociationByDistributorId(distributor_id: string): Promise<any[]>{
    return new Promise<any[]>((resolve) => {
      this.getBinModelAssociationByDistributorIdLambda(distributor_id).subscribe((response) => {
        // Put the array returned by the lambda function into an tmp array
        this.array = response;
        this.distributorBinsDashboardArray = this.array;
        this.replaceBinUsageKey();

        resolve(this.array);
      });
    });
  }

  async getDeviceByDistributorId(distributor_id: string): Promise<any[]>{
    return new Promise<any[]>((resolve) => {
      this.getDeviceByDistributorIdLambda(distributor_id).subscribe((response) => {
        // Put the array returned by the lambda function into an tmp array
        this.array = response;
        this.distributorIotDashboardArray = this.array;
        resolve(this.array);
      })
    });
  }

  async getUsersByDistributorId(distributor_id: string): Promise<any[]>{
    return new Promise<any[]>((resolve) => {
      this.getUserByDistributorIdLambda(distributor_id).subscribe((response) => {
        // Put the array returned by the lambda function into an tmp array
        this.array = response;
        this.distributorUsersArray = this.array;
        resolve(this.distributorUsersArray);
      })
    });
  }

  async getAllDistributorWorkOrders(distributor_id: string): Promise<any[]>{
    return new Promise<any[]>((resolve) => {
      this.getAllDistributorWorkOrdersLambda(distributor_id).subscribe((response) => {
        // Put the array returned by the lambda function into an tmp array
        this.array = response;
        this.distributorWorkOrderArray = this.array;
        this.replaceWorkOrderStatusAndTypeAndDates();
        resolve(this.distributorWorkOrderArray);
      })
    });
  }



  ///////////////////////////// Section for lambda functinos ////////////////////////////////////



  createDistributors(): Observable<any>{

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    return this.http.post(environment.api.stage + environment.api.route.createDistributor,
      {
        "distributor_name": this.distributorData.distributor_name,
        "legal_name": this.distributorData.legal_name,
        "phone_number": this.distributorData.phone_number,
        "email": this.distributorData.email,
        "address": this.distributorData.address,
        "created": this.distributorData.created,
        "modified": this.distributorData.modified
      }, {headers: headers}
    );
  }

  //Update Client with an observable function
  updateDistributor(): Observable<any>{

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    return this.http.post(environment.api.stage + environment.api.route.updateDistributor,
      {
       "distributor_id": this.distributorData.distributor_id,
       "distributor_name": this.distributorData.distributor_name,
       "legal_name": this.distributorData.legal_name,
       "phone_number": this.distributorData.phone_number,
       "email": this.distributorData.email,
       "address": this.distributorData.address,
       "modified": this.distributorData.modified
     }, {headers: headers}
   );
 }

 getDistributors(){
  // Append the 'user' parameter to the URL as a query string
  const url = environment.api.stage + environment.api.route.getDistributors

  return this.http.get(url).pipe(
    map((response) => {
      // return the response data
      this.distributorsArray.push(response);
      return response;
    }),
    catchError((error) => {
      console.error('API Error:', error);
      throw error(error); // Re-throw the error for the calling code to handle
    }));
  }

  getBinModelAssociationByDistributorIdLambda(distributor_id: string){
    // set the url for lambda call
    const url = environment.api.stage + environment.api.route.getBinModelAssociationsByDistributorId + "&distributor_id=" + distributor_id;

    // Will call the lambda function then return a response
    return this.http.get(url).pipe(
      map((response) => {
        this.array = response;

        this.array = this.array.forEach((element: any) => {
          this.switchJSONToIntForBinModelResponse(element);
        });
        // return the response data
        return response;
      }),
      catchError((error) => {
        console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      })
    );
  }

  getDeviceByDistributorIdLambda(distributor_id: string){
    // Set url for lambda call
    const url = environment.api.stage + environment.api.route.getDeviceByDistributorId + "&distributor_id=" + distributor_id;

    // Will call the lambda function then return a response
    return this.http.get(url).pipe(
      map((response) => {
        // Return the response data
        return response;
      }),
      catchError((error) => {
        console.error("API Error: ", error);
        throw error(error);
      })
    );
  }

  // function that call the lambda API to get all users of the distributor
  getUserByDistributorIdLambda(distributor_id: string){
    // Set url for lambda call
    const url = environment.api.stage + environment.api.route.getUsersByDistributorId + "&distributor_id=" + distributor_id;

    // Will call the lambda function then return a response
    return this.http.get(url).pipe(
      map((response) => {
        this.array = response;

        this.array = this.array.forEach((element: any) => {
          this.switchJSONToIntForUserResponse(element);
        });
        // Return the response data
        return response;
      }),
      catchError((error) => {
        console.error("API Error: ", error);
        throw error(error);
      })
    );
  }

  getAllDistributorWorkOrdersLambda(distributor_id: string){
    // Set url for the lambda function
    const url = environment.api.stage + environment.api.route.getAllDistributorWorkOrders + "&distributor_id=" + distributor_id;

    // Will call the lambda function then return a response
    return this.http.get(url).pipe(
      map((response) => {
        // Return the response data
        return response;
      }),
      catchError((error) => {
        console.error("API Error: ", error);
        throw error(error);
      })
    );
  }

  // function called to replace work order status and type key by proper translation key
  replaceWorkOrderStatusAndTypeAndDates(){
    this.distributorWorkOrderArray.forEach(item => {
      const status = WorkOrderStatus.find(status => status.value === item.status);
      const type = WorkOrderTypes.find(types => types.value === item.work_order_type);
      if(status){
        item.status = status.label;
      }
      if(type){
        item.work_order_type = type.label;
      }
    })
  }
}
