<app-system-message></app-system-message>

<!-- Navbar whit the option of list we get -->
<header class="headerClass">
    <nav [ngClass]="theme.getThemeClass()" class="navbar navbar-expand-lg navbar-light bg-light">
      <div [ngClass]="theme.getThemeClass()" class="navbar-nav flex-nowrap divButton" id="navbarNavAltMarkup">
        <a class="nav-item nav-link" href="javascript:void(0)" [ngClass]="{ 'active' : isUserListActive }"
        (click)="userListClicked()">
        {{ 'adminViewUserListNavBar' | translate }}
        </a>
        <a class="nav-item nav-link" href="javascript:void(0)" [ngClass]="{ 'active' : isBinListActive }"
        (click)="binListClicked()">
        {{ 'adminViewBinListNavBar' | translate }}
        </a>
        <a class="nav-item nav-link" href="javascript:void(0)" [ngClass]="{ 'active' : isBinModelListActive }"
        (click)="binModelListClicked()">
        {{ 'adminViewBinModelListNavBar' | translate }}
        </a>
        <a class="nav-item nav-link" href="javascript:void(0)" [ngClass]="{ 'active' : isTaxeTypeActive }"
        (click)="taxeTypeClicked()">
        {{ 'taxeTypeViewTaxeType' | translate }}
        </a>
      </div>
    </nav>
</header>

<!-- List component that admin have -->
<app-user-list *ngIf="this.isUserListActive" class="taxeTypeList"></app-user-list>
<app-bin-list *ngIf="this.isBinListActive" class="binList"></app-bin-list>
<app-bin-model-list *ngIf="this.isBinModelListActive" class="binModelList"></app-bin-model-list>
<app-taxe-type-list *ngIf="this.isTaxeTypeActive" class="taxeTypeList"></app-taxe-type-list>


