import { Injectable } from '@angular/core';

Injectable({
  providedIn: 'root'
}) 

// This constant get the type of the taxe in his value the name of the taxe in label

export const TaxeType = [
    { "value" : "GST", "label" : "taxeNameGST"},
    { "value" : "QST", "label" : "taxeNameQST"},
    { "value" : "TVP", "label" : "taxeNameTVP"},
    { "value" : "TVH", "label" : "taxeNameTVH"}
]