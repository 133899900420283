import { ValidationService } from './../service/validation.service';
import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../service/theme.service';
import { NavigationService } from '../service/navigation.service';
import { SystemMessageService } from '../service/system-message.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.css']
})

export class EntitiesComponent implements OnInit {
  // Variable to store the language selected value
  public selectedLanguage: string = "";

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  lastEntitiesContent:string = this.localStorageService.getItem('entitiesContent')

  // Variables for user filtering
  public mainContent: string = '';

  // Flags for UI state
  public isClientsActive: boolean = false;
  public isDistributorsActive: boolean = false;
  public isProvidersActive: boolean = false;
  public isOperatorActive: boolean = false;
  public isLocationActive: boolean = false;

  constructor(
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private cognitoService: CognitoService,
    public validationService: ValidationService,
    public theme: ThemeService,
    public navigationService: NavigationService,
    public systemMessage: SystemMessageService

  ) {

    this.cognitoService.confirmValidUser();

    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  ngOnInit(): void {

    //Verifies that the current User is allowed to view this component
    this.cognitoService.getCurrentRole([environment.users.role.administrator],[environment.users.superAdmin]);//Role Check

    // displays the list of customers when the entities component is first accessed
    this.isClientsActive = true

    switch (this.lastEntitiesContent) {

      case "clients":
        // Activate the "clients" button UI state
        this.clientsClicked();
        break;

      case "distributors":
        // Activate the "distributors" button UI state
        this.distributorsClicked();
        break;

      case "operator":
        // Activate the operator button UI state
        this.operatorClicked();
        break;

      case "locations":
        // Activate the location button UI state
        this.locationsClicked();
        break;

      default:
        // Activate the "clients" button UI state as default option
        this.clientsClicked();
    }

    // Check if there is a success message for distributor creation
    if (this.validationService.distributorCreateSuccessMessage) {
      // If so, display a success ribbon message with the specified key
      this.systemMessage.selectRibbon('success', "distributorCreateSuccessMessage");
    }

    // Check if there is a success message for client creation
    if (this.validationService.clientCreateSuccessMessage) {
      // If so, display a success ribbon message with the specified key
      this.systemMessage.selectRibbon('success', "clientCreateSuccessMessage");
    }

    // Check if there is a success message for client update
    if (this.validationService.clientUpdateSuccessMessage) {
      // If so, display a success ribbon message with the specified key
      this.systemMessage.selectRibbon('success', "clientUpdateSuccessMessage");
    }

  }

  AfterViewInit() {
    switch (this.lastEntitiesContent) {
      case "clients":
        // Activate the "clients" button UI state
        this.clientsClicked();
        break;

      case "distributors":
        // Activate the "distributors" button UI state
        this.distributorsClicked();
        break;

      case "operator":
        // Activate the "operator" button UI state
        this.operatorClicked();
        break;

      case "locations":
        // Activate the location button UI state
        this.locationsClicked();
        break;

      default:
        // Activate the "clients" button UI state as default option
        this.clientsClicked();
    }

  }

   // Change language based on user selection
  changeLanguage(language: string) {
    this.translate.use(language);
  }

  // Function to clear the UI state flags for different user types
  clearButtonsStats() {
    // Deactivate the "clients" UI state
    this.isClientsActive = false;

    // Deactivate the "distributors" UI state
    this.isDistributorsActive = false;

    // Deactivate the "providers" UI state
    this.isProvidersActive  = false;

    // Deactivate the 'operator' UI state
    this.isOperatorActive = false;

    // Deactive the location UI state
    this.isLocationActive = false;
  }

  // Function to handle the "Clients" button click event
  clientsClicked(){
    // Clear the UI state flags for other user types
    this.clearButtonsStats();

    // Set the "isClientsActive" flag to indicate the "Clients" button is active
    this.isClientsActive = true;

    // Update the main content to display the content for "Clients"
    this.mainContent = "clients";

    // Update the navigation service contoller content to "Clients"
    this.localStorageService.addItem('entitiesContent', 'clients');
  }

  // Function to handle the "Distributors" button click event
  distributorsClicked() {
    // Clear the UI state flags for other user types
    this.clearButtonsStats();

    // Set the "isDistributorsActvive" flag to indicate the "Distributors" button is active
    this.isDistributorsActive = true;

    // Update the main content to display the content for "Distributors"
    this.mainContent = "distributors"

    // Update the navigation service contoller content to "Distributors"
    this.localStorageService.addItem('entitiesContent', 'distributors')
  }

  // Function to handle the "Operator" button click event
  operatorClicked() {
    // Clear the UI state flags for other user types
    this.clearButtonsStats();

    // Set the "isProvidersActive" flag to indicate the "Providers" button is active
    this.isOperatorActive = true;

    // Update the main content to display the content for "Providers"
    this.mainContent = "operator";

    // Update the navigation service contoller content to "Providers"
    this.localStorageService.addItem('entitiesContent', 'operator');
  }

  // Function to handdle the location button UI click event
  locationsClicked(){
    // Clear the UI state flags for other user types
    this.clearButtonsStats();

    // Set the "isProvidersActive" flag to indicate the "locations" button is active
    this.isLocationActive = true;

    // Update the main content to display the content for "locations"
    this.mainContent = "locations";

    // Update the navigation service contoller content to "locations"
    this.localStorageService.addItem('entitiesContent', 'locations');
  }
}

