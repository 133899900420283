import { Component, OnInit } from '@angular/core';
import { CognitoService } from '../service/cognito.service';
import { ThemeService } from '../service/theme.service';
import { ValidationService } from '../service/validation.service';
import { TaxeTypeService } from '../service/taxe-type.service';
import { ISOCountry } from '../constants/ISO-country';
import { ISOProvince } from '../constants/ISO-province';
import { TaxeType } from '../constants/taxe-type';
import { TaxeTypeISOCode } from '../constants/taxe-type-iso-code';
import { ModalAlertService } from '../service/alert-modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { SystemMessageService } from '../service/system-message.service';

export interface taxeType {
  value: string,
  label: string,
  name: string,
  province: string
}
@Component({
  selector: 'app-taxe-type-update',
  templateUrl: './taxe-type-update.component.html',
  styleUrls: ['./taxe-type-update.component.css']
})
export class TaxeTypeUpdateComponent implements OnInit {

  // Varialbes used by HTML initialize whit Constants
  public countryISOConstant = ISOCountry;
  public provinceISOConstant = ISOProvince;

  // Variables used for HTML inputs
  public country: string = 'CA';
  public province: string = '';
  public is_federal: string = '';
  public type: string = '';
  public rate: string = '';

  public taxe_type_id: string = '';

  // Array to implement the HTML select
  public taxeTypeArray: taxeType[] = [];
  private newArray = {
    value: '',
    label: '',
    name: '',
    province: ''
  }

  //Variables used in the validation of on submit
  public validateCreate: boolean = false;
  private systemMessage: string = '';

  constructor(private cognito: CognitoService,
              public theme: ThemeService,
              public validationService: ValidationService,
              public taxeTypeService: TaxeTypeService,
              public alert: ModalAlertService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private systemMessageService: SystemMessageService){

    this.cognito.confirmValidUser();
  }

  ngOnInit(): void {
    // Get the taxe type id passed by the ur;
    this.activatedRoute.params.subscribe(params => {
      this.taxe_type_id = params['id']; // Set this taxe type id to our own variable
    });

    // Get the selected taxe type whit his id and set it to taxe_type_data
    this.taxeTypeService.getTaxeTypeById(this.taxe_type_id);

    // Set the two inputs of is_federal ad rate to the good value because in DB those are integer and we take then as string in HTML
    this.type = this.taxeTypeService.taxe_type_data.taxe_type;
    this.is_federal = this.taxeTypeService.taxe_type_data.is_federal.toString();
    this.rate = this.taxeTypeService.taxe_type_data.rate.toString();

    // Set the variable used in ngModel for the province or country depend on witch taxe is for provincial/federal
    if(this.is_federal === '0'){
      // If it's not for federal taxe the ISO code will go on province
      this.province = this.taxeTypeService.taxe_type_data.ISO_code;
      this.updateTaxeTypeArray('province'); // Set the select array of taxe type for the selected province
    }else{
      // If it's for federal taxe the ISO code will go on country
      this.country = this.taxeTypeService.taxe_type_data.ISO_code;
      this.updateTaxeTypeArray('country'); // Set the select array of the taxe type for the selected country
    }

    // Function trigger when user get alerted if he change active status then click on cancel
    this.alert.cancel$.subscribe(() => {
      let checkboxStatus = document.getElementById('checkboxStatus') as HTMLInputElement; // Set the a html variable of the checkbox for the status
      this.taxeTypeService.taxe_type_data.active = this.alert.active; // Set the bin_model_data active status to the good value

      // Will reset the active status to what it was before the alert was throw then set the checkbox to the proper value
      if(this.alert.active === 1 && checkboxStatus){
        checkboxStatus.checked = true;
      } else if(this.alert.active === 0 && checkboxStatus){
        checkboxStatus.checked = false;
      }
    });

    // Function automatically trigger when user click on confirm into the alert modal
    this.alert.confirmTaxeType$.subscribe(async () => {
      this.taxeTypeService.taxe_type_data.active = this.alert.active; // Set the bin_model_data active status to the good value
    });
  }

  async onSubmit(){
    // Create a variable whit the current date
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'yyyyMMdd', 'en_US');

    // Set created and modified date to current date, modified is required event if it's not so the query won't break
    this.taxeTypeService.taxe_type_data.modified = parseInt(formattedDate, 10);

    // Set the rest of data needed
    this.taxeTypeService.taxe_type_data.rate = parseFloat(this.rate.replace(',','.'));
    this.taxeTypeService.taxe_type_data.is_federal = parseInt(this.is_federal);

    // Loop through taxeTypeArray that is initialize whit the constant of taxe type and taxeType ISO_code
    for(let i = 0; i < this.taxeTypeArray.length; i ++){
      if(this.type === this.taxeTypeArray[i].label){
        // Once we get the taxe type in taxeTypeArray we set the taxe name and taxe type of the taxe_type_data in the service
        this.taxeTypeService.taxe_type_data.taxe_name = this.taxeTypeArray[i].name;
        this.taxeTypeService.taxe_type_data.taxe_type = this.taxeTypeArray[i].label;
      }
    }

    // Call the funtion for validation in addition of validation service
    this.checkValidation();

    if(this.validateCreate){
      // Call the function that create the bin in bin service
      await this.taxeTypeService.updateTaxeType();
      this.returnToAdmin(); // Set session variable then go back to admin page
    } else {
      // If system message have nothing it put a generic message
      if(this.systemMessage === ''){
        this.systemMessage = 'alert-danger-generic-message';
      }
      // Set the system message service to display the error to the user
      this.systemMessageService.selectRibbon('danger', this.systemMessage);
    }
  }

  // Function call in addition of validation service to validate input of the
  checkValidation(){

    // Will check first for the validation is the federal select have something in
    if(this.is_federal !== ''){
      // If the taxe type is federal it will set the ISO_code of taxe type data to the country iso code
      if(this.is_federal === '1'){
        this.taxeTypeService.taxe_type_data.ISO_code = this.country;
      }
      // If the taxe type is provincial it will set the iso_code of taxe type data to the province iso code
      if(this.is_federal === '0'){
        this.taxeTypeService.taxe_type_data.ISO_code = this.province;
      }
      // Run the service function to check there is data in each key of taxe type data
      this.taxeTypeService.hasOwnProperty('update');
    }

    if(this.taxeTypeService.emptyField){
      this.systemMessage = 'fieldEmptyOrIncorrect';
    }

    // Check if all function that we passed before not returning a true value then will set the validation variable to true to be able to create a new taxe type
    if(!this.taxeTypeService.emptyField && this.is_federal !== '' && (this.validationService.validationErrorArray[0] === '' || this.validationService.validationErrorArray[0] === undefined)){
      // If the country tax is already there, it won't let the user made an other one
      this.validateCreate = true;
    }
  }

  // Function used to update the select of tax type in HTML
  updateTaxeTypeArray(code: string){
    // Reset the taxeTypeArray to avoid duplicated values in the HTML select
    this.taxeTypeArray = [];
    this.resetNewArray(); // Call the functino that reset the newArray to avoid duplicated values
    if(code === '0'){
      code = 'province';
    }
    if(code === '1'){
      code = 'country';
    }

    // Loop through constant TaxeTypeISOCode that have for each ISO code that taxe type related
    for(let i = 0; i < TaxeTypeISOCode.length; i ++){

      // We check if the function was call from the province select or the country select
      switch(code){
        case 'province':
          if(this.province === TaxeTypeISOCode[i].value){
            // Set the value and the label of newArray to the value of TaxtTypeISOCode constant witch is the ISO code and the label witch is the type of taxe related to this ISO code
            this.newArray.value = TaxeTypeISOCode[i].value;
            this.newArray.label = TaxeTypeISOCode[i].label;

            // Loop through TaxeType constant to get the related name of this taxe
            for(let y = 0; y < TaxeType.length; y ++){
              if(this.newArray.label === TaxeType[y].value){
                this.newArray.name = TaxeType[y].label;
              }
            }
            // Loop through ISOProvince constant to get the name of the province
            for(let z = 0; z < ISOProvince.length; z ++){
              if(this.newArray.value === ISOProvince[z].value){
                this.newArray.province = ISOProvince[z].label;
              }
            }
            // Push newArray into taxeTypeArray that implement the taxe type HTML select
            this.taxeTypeArray.push(this.newArray);
          }
          break;

        case 'country':
          if(this.country === TaxeTypeISOCode[i].value){
            // Set the value and the label of newArray to the value of TaxtTypeISOCode constant witch is the ISO code and the label witch is the type of taxe related to this ISO code
            this.newArray.value = TaxeTypeISOCode[i].value;
            this.newArray.label = TaxeTypeISOCode[i].label;
            // Loop through TaxeType constant to get the related name of this taxe
            for(let y = 0; y < TaxeType.length; y ++){
              if(this.newArray.label === TaxeType[y].value){
                this.newArray.name = TaxeType[y].label;
              }
            }
            // Loop through ISOProvince constant to get the name of the province
            for(let z = 0; z < ISOCountry.length; z ++){
              if(this.newArray.value === ISOCountry[z].value){
                this.newArray.province = ISOCountry[z].label;
              }
            }
            // Push newArray into taxeTypeArray that implement the taxe type HTML select
            this.taxeTypeArray.push(this.newArray);
          }
          break;
      }
    }
  }

  // Function called when user change the select of country, province or is_federal and will reset the newArray that have the stuff to implement the taxe type select
  resetNewArray(){
    this.newArray = {
      value: '',
      label: '',
      name: '',
      province: ''
    }
  }

  // Implemented by cancel button to return to the admin component
  returnToAdmin(){
    // Put a sessions store variable so admin component could know to return on bin-list
    sessionStorage.setItem("previous", "taxe-type-list");
    sessionStorage.setItem("from", "taxe-type-update"); // Variable used by bin-model-list html to show the proper message on success updating bin model
    this.router.navigate(['/admin']); // Return to admin component
  }
}
