import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { FilterService } from '../service/filter.service';
import { OperatorService } from '../service/operator.service';
import { ValidationService } from '../service/validation.service';
import { SystemMessageService } from '../service/system-message.service';

@Component({
  selector: 'app-operator-list',
  templateUrl: './operator-list.component.html',
  styleUrls: ['./operator-list.component.css']
})
export class OperatorListComponent implements OnInit {

  // Variables used for HTML whit ngIf for the showing or not of block of html cde
  public mainContent: string = 'operator-list';
  public fromComponent: string = '';

  // Variable used in HTMl for the active status of
  public active: number = 0;

  // Variables used for the filter service
  public userFilter: string = '';

  constructor(public theme: ThemeService,
              public filter: FilterService,
              public operator: OperatorService,
              public validationService: ValidationService,
              private systemMessageService: SystemMessageService){

  }

  ngOnInit(): void {
    // Call the function that init the list
    this.initList();

    // Function that is automatically trigger used by bin service when there is a change made from bin-create or bin-update component
    this.operator.changeMade$.subscribe(async () => {
      // Call the function that init the list
      this.initList();
      this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
    });

    this.operator.noChangeMade$.subscribe(async () => {
      this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
    });
  }

  // Function that will initializa the lists
  async initList(){
    // Initialize Bin array in bin.service
    await this.operator.getOperator();
  }
}
