import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartService } from '../service/chart.service';
import { ThemeService } from '../service/theme.service';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css', '../../charts.css']
})
export class LineChartComponent {
  constructor(public charts: ChartService,
              public theme: ThemeService){
                 //Declare chart data as a constant
    const single = charts.singleLineChart
    //Assign Data to the Graph object
    Object.assign(this, { single });

  }


}
