import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CognitoService, UpdatedAttributes } from '../service/cognito.service';
import { RoleService } from '../service/role.service';
import { ThemeService } from '../service/theme.service';
import { ValidationService } from '../service/validation.service';
import { ClientService } from '../service/client.service';
import { SystemMessageService } from '../service/system-message.service';
import { LocalizationService } from '../service/localization.service';
import { Roles } from '../constants/roles';
import { AlertPreferece } from '../constants/alert-preference';
import { TranslationService } from '../translation.service';
import { UserEditModalService } from '../service/user-edit-modal.service';

interface UserRole {
  user: string;
  role: number;
  label?: string; // Adicionamos a propriedade label como opcional
}

interface Role {
  value: string;
  label: string;
}

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})

export class UserEditComponent implements OnInit {
  currentUser: any = [];

  // Variable to store the language selected value
  public selectedLanguage: string = "";

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  // Google Places Autocomplete
  public autocomplete: any = google.maps.places;

  // Variables to store edited user information
  public userName:any = '';
  public email:any = '';
  public firstName:any = '';
  public middleName:any = '';
  public lastName:any = '';
  public company:any = '';
  public address: string = '';
  public roles:any = [];
  public phoneNumber:any = '';
  public phoneNumberVerified: boolean = false;
  public avatarUrl:any = '';
  public userid: string = "a"; // Default user ID
  public testUserArray:any = [];
  public parameterMatched: boolean = false;
  public currentRole: string = "";
  public selectedRole: string = "";
  public userSub: string  = "";
  public userUsername: any;
  public userType: any;

  // Array Fetch customer data
  public userRolesNumber: any = [];
  // public userRoles: UserRole[] = [];
  public userRoles: any;

  public Roles = Roles;

  // Variable to handdle the confirmation of the change on user
  public changePasswordConfirm = false;
  public changePasswordFailed = false;

  //User Attributes Object
  userAttributes: UpdatedAttributes = {
    email : "",
    phone_number : "",
    given_name : "",
    family_name : "",
    middle_name : "",
    picture : "",
    address : "",
    role: ""
  };

  public AlertPreference = AlertPreferece;

  public alertPreferencesFetchError: boolean = false;
  private preferedLanguage: string = '';

  constructor(
    private router: Router,private activedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    public cognitoService: CognitoService,
    public roleService: RoleService,
    public theme: ThemeService,
    public validationService: ValidationService,
    private clientService: ClientService,
    public systemMessageService: SystemMessageService,
    private localizationService: LocalizationService,
    private route: ActivatedRoute,
    private languageService: TranslationService,
    public userEditModalService: UserEditModalService

    ) {
      this.roleService.rolesArray = [];

      // Check if the user has selected a language in local storage
      //or use a default language
    if (this.languageStatus == null){
        // Set the default language to French
        translate.use('fr');
      } else {
        // Set the default language to the user's selected language
        translate.use(this.languageStatus);
      }
    }

  // Fetch current user data from the user service
  async getCurrentUserData() {

    //Get the actual user whit Cognito AWS
    await this.cognitoService.getUser().then((result)=>{

      //Assign result of Cognito actual user to currentUser variable
      this.currentUser = result;
      this.firstName = this.currentUser.attributes.given_name;
      this.middleName = this.currentUser.attributes.middle_name;
      this.lastName = this.currentUser.attributes.family_name;
      this.email = this.currentUser.attributes.email;
      this.address = this.currentUser.attributes.address;
      this.phoneNumber = this.currentUser.attributes.phone_number;
      this.phoneNumberVerified = this.currentUser.attributes.phone_number_verified;
      this.currentRole = this.currentUser.attributes['custom:current_role'];
      this.selectedLanguage = this.currentRole;
      this.userSub = result.attributes.sub
      this.userRoles = this.currentUser.attributes['custom:role'];
    }).catch((error)=>{
      console.error("Error getting user: ", error);

      // show a message that let the user knows  there was an issue with data loading
      this.systemMessageService.selectRibbon('danger', 'loadingDataGeneralError');
    });
  }

  async ngOnInit(): Promise<void> {
    await this.cognitoService.confirmValidUser();
    await this.roleService.getRoles().then(() => {
      this.userRolesNumber = this.roleService.modifiedData;
    });
    // Reset user role array in role service
    this.roleService.userRoles = [];

    if (this.userRolesNumber && Array.isArray(this.userRolesNumber)) {
      // Map user roles to their corresponding labels
      this.userRoles = this.userRolesNumber.map((userRole: any) => {
        const roleDefinition = Roles.find((role: Role) => role.value === String(userRole));
        return roleDefinition ? { user: this.userSub, role: Number(roleDefinition.value), label: roleDefinition.label } : null;
      }).filter(role => role !== null) as UserRole[];
    }

    // Set userRoles in role service as same of the userRoles array of the component
    this.roleService.userRoles = this.userRoles;

    //get user data from cognito service
    await this.getCurrentUserData();

    // Clear any lingering data related to user creation
    this.cognitoService.clearCognitoUserData(); // clear last client create data

    // Call getUserPreference to know if user have MFA set to is cognito user
    this.cognitoService.getUserMFAPreference();

    // Retrieve and store the 'id' parameter from the route
    this.activedRoute.params.subscribe((param: any) => {
      this.userid = param.id; // Retrieve the 'id' parameter
      this.userid = param['id']; // Alternative syntax to retrieve the 'id' parameter
    })

    this.updateUserRoles();

    // init the google api auto complete
    this.localizationService.initAutocomplete();

    // checks in the local storage whether there is a status of a successful alert preference change
    // and if successful, it displays a message of operation completed successfully
    const checkAlertUpdate = this.localStorageService.getItem('successAlertsUpdate');
    if (checkAlertUpdate) {
      this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
      this.localStorageService.removeItem('successAlertsUpdate');
    }

    // checks in the local storage whether there is a status of a failed alert preference change
    // and if failed, it displays a message of operation not completed
    const checkAlertFailUpdate = this.localStorageService.getItem('successAlertsFail');
    if (checkAlertFailUpdate) {
      this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      this.localStorageService.removeItem('successAlertsFail');
    }

    // Function triggered by cognito service when user adhere to MFA authentification
    this.cognitoService.MFASignUpSuccess$.subscribe(() => {
      this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
    });
  }

  // Function called to implement the labels for userRoles object array
  updateUserRoles(): void {
    this.userRoles = this.userRoles.map(this.roleService.addLabel);
  }

  // Function called from the html to check if the role in in roles constant is check in the userRoles
  checkRoles(role: string){
    return this.roleService.modifiedData.includes(parseInt(role));
  }

  async onSubmit(){

    // Check if all validation criteria are met
    if (this.validateInputsValues()) {

      // Set the user role based on the selected role
      this.userAttributes.role = this.currentRole;
      if(this.selectedRole !== "" && this.selectedRole !== undefined){
        this.userAttributes.role = this.selectedRole;
      }

      // Set all variable whit the input in the user edit page
      this.userAttributes.email = this.email;
      this.cognitoService.cognitoUserData.sub = this.userSub;

      // Set user attributes with values from the input in the user edit page
      this.userAttributes.phone_number = this.phoneNumber;
      this.cognitoService.cognitoUserData.phone_number = this.phoneNumber

      this.userAttributes.given_name = this.firstName;
      this.cognitoService.cognitoUserData.given_name = this.firstName;

      this.userAttributes.family_name = this.lastName;
      this.cognitoService.cognitoUserData.family_name = this.lastName;

      this.userAttributes.middle_name = this.middleName ? this.middleName : "";

      this.userAttributes.address = this.address;
      this.cognitoService.cognitoUserData.address = this.address;

      this.cognitoService.cognitoUserData.custom_role = this.userAttributes.role;

      try {
        // Attempt to update user attributes using the Cognito service
        const updated = await this.cognitoService.updateUserAttributes(this.userAttributes);
        // Code to be executed on success
        if(updated){
          // Call the function to show and hide the <div> for 5 seconds
          this.active3SecondTrigger((newValue) => {
            this.changePasswordConfirm = newValue; // The element will be set whit the function
          });
        }
        this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
        this.localStorageService.removeItem('user');
        this.localStorageService.addItem('user', this.firstName + ' ' + this.lastName);
      } catch (error) {
        // Code to be executed on error
        this.active3SecondTrigger((newValue) => {
          this.changePasswordFailed = newValue; // The element will be set whit the function
        });
        console.error('Error updating user:', error);
        this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      }
    }
  }

  // Function called from html to know witch roles is selected
  isRoleChecked(roleLabel: string): boolean {
    return this.userRoles && this.userRoles.some((role: { label?: string }) => role.label === roleLabel);
  }

  validateInputsValues() {
    // Validate individual input values using the validation service
    this.validationService.validateGivenName(this.firstName);
    this.validationService.validateFamilyName(this.lastName);
    this.validationService.validateAddress(this.address);
    this.validationService.validatePhoneNumber(this.phoneNumber);

    // Check if all individual validations are successful
    if (
      this.validationService.givenNameValid &&
      this.validationService.familyNameValid &&
      this.validationService.clientAddressValid &&
      this.validationService.clientPhoneNumberValid
    ) {
      // If all validations pass, return true
      return true;
    } else {
      // Define error mappings for different validation errors
      const errorMappings: Record<string, string> = {
        'clientInputError': 'invalidClientSelection',
        'roleSelectionInvalid': 'invalidRoleSelection',
        'givenNameInvalid': 'invalidGivenNameError',
        'familyNameInvalid': 'invalidFamilyNameError',
        'clientPhoneInvalid': 'invalidInput',
        'clientAddressInvalid': 'invalidAddress',
        'usernameNotUnique': 'usernameInUse',
      };

      // Retrieve the first validation error from the array
      const validationError = this.validationService.validationErrorArray[0];

      // Check if the validation error code exists in the mapping
      if (errorMappings[validationError]) {
        // If so, display a danger ribbon message with the corresponding key
        this.systemMessageService.selectRibbon('danger', errorMappings[validationError]);
      } else {
        // If the error code is not found in the mapping, log an error message
        console.error('An error occurred, please contact support');
      }

      // Return false as there are validation errors
      return false;
    }
  }


  // This function made a show and hide whit differents properties variables that we can pass whit ngIf in html
  active3SecondTrigger(callback: (prop: boolean) => void){
    // Put the variable to true to show the <div>
    callback(true);
    // Set a time out of 5 second then put the variable at false to hide the <div>
    setTimeout(() => {
      callback(false);
    }, 5000);
  }

  // Change language based on user selection
  changeLanguage(language: string) {
    this.translate.use(language);
  }

  //This function redirect the user at login/ whit the action of changePW
  changePW(){
    this.router.navigate(['/login/change-password/user-edit']);
  }

  // Function call when user click the link to subscribe to MFA
  signUpMFA(){
    // Redirect user to login page in sign-up mfa page
    this.router.navigate(['/login/sign-up-MFA/user-edit']);
  }

  // Function that will route user to alert preference page
  changePreference(){
    // redirect user to the alert preference page
    this.router.navigate(['/user-edit-preference/' + this.userSub]);
  }

  // Function call when phone number of user is not authenticated and user click on not-authenticated icon on top of his phone number
  authenticatePhoneNumber(){
    // Redirect user to login page in authenticated phone number page
    this.router.navigate(['/login/authenticate-phone/user-edit']);
  }

  roleToggle(role : string){
    this.selectedRole = role;
  }

  // Function to initialize the Google Maps Places Autocomplete
  initAutocomplete() {
    // Ensure that the Google Maps API script is loaded before calling this function
    if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
      throw new Error('Google Maps API is not loaded.');
    }

    // Get the input element with the ID 'autocomplete'
    const inputElement = document.getElementById('autocomplete') as HTMLInputElement;

    // Check if the input element was found
    if (!inputElement) {
      throw new Error('"autocomplete" input element not found.');
    }

    // Set options for the Autocomplete widget
    const autocompleteOptions: google.maps.places.AutocompleteOptions = {
      // Specify the types of places to search for (geocode in this case)
      types: ['geocode'],
      // Restrict results to Canada
      componentRestrictions: { country: 'ca' },
      // Define the fields to be retrieved from place details
      fields: ['place_id', 'geometry', 'name', 'formatted_address'],
    };

    // Create a new instance of the Autocomplete widget and associate it with the input element
    this.autocomplete = new google.maps.places.Autocomplete(inputElement, autocompleteOptions);

    // Add a listener to handle the selection of a place in the Autocomplete results
    this.autocomplete.addListener('place_changed', () => {

      // Variable to store the result of a place selection from Google Places Autocomplete.
      // It can hold a valid PlaceResult object or be undefined if no place is selected.
      const selectedPlace = this.autocomplete.getPlace();

      if (selectedPlace && selectedPlace.geometry) {
        let formattedAddress = selectedPlace.formatted_address;

        // Check if the 'formatted_address' property is available
        if (!formattedAddress && selectedPlace.name) {
          formattedAddress = selectedPlace.name;
        }

        // Check if the 'name' property is available
        if (!formattedAddress && selectedPlace.address_components) {
          // Build the address from address components
          formattedAddress = selectedPlace.address_components.map((component: { long_name: any; }) => component.long_name).join(', ');
        }

        if (formattedAddress) {
          this.clientService.clientData.address = formattedAddress;
          this.address = formattedAddress;
        }
      }
    });
  }

  // Handler to handle the selection of a place
  onPlaceChanged() {
    const selectedPlace = this.autocomplete.getPlace();

    if (selectedPlace && selectedPlace.geometry) {
      // Associate the selected address with the variable 'xaxa'
      const formattedAddress = selectedPlace.formatted_address;
    }
  }
}
