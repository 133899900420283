import { Injectable, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

//Define Ribbon Parameters
interface Ribbon {
  label: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class SystemMessageService {

  //Ribbon Class Public Parameters
  public ribbonTitle: string = '';
  public ribbonMessage: string = '';
  public showRibbon: boolean = false;

  //Ribbon types
  ribbons: Ribbon[] = [
    { label: 'alert-danger', value: 'danger' },
    { label: 'alert-warning', value: 'warning' },
    { label: 'alert-info', value: 'info' },
    { label: 'alert-success', value: 'success' }
  ];


  selectedRibbon: Ribbon | null = null; // Initialize with null
  constructor(private translate: TranslateService) {}

  //Set title and message text for ribbon
  setRibbonDetails(title: string, message: string) : void {
    this.ribbonTitle = title;
    this.ribbonMessage = message;
    this.showRibbon = true;
  }

  //Set boolean to hide message ribbon
  closeRibbon() {
    this.showRibbon = false;
  }

  //Select Ribbon type for system message
  selectRibbon(value: string, message:string) : void {
    this.selectedRibbon = this.ribbons.find(ribbon => ribbon.value === value)!;
    this.setRibbonContent(value, message);
    this.showRibbon = true;
  }

  //Defines ribbon label and message key for translated texts
  setRibbonContent(type: string, message: string) : void {
    const ribbon = this.ribbons.find(rib => rib.value === type);
    if (ribbon) {
     this.setRibbonDetails(ribbon.label, message);
    }
  }

  //Pass a label message key to return a translated string from the .json files
  async getMessageText(messageKey: string) : Promise<string>{
    try {
      //Get first value from the translate service call
      const message: string = await firstValueFrom(this.translate.get(messageKey));

      //return the text value
      return message;
    } catch (error) {
      console.error('Error fetching translation:', error);
      return ''; // or handle the error as needed
    }
  }
  
  //UI animation 
  shake(element:ElementRef) {
    if (element && element.nativeElement) {
      const box = element.nativeElement;
      box.style.transition = 'transform 0.05s ease-in-out';
      box.style.transform = 'translateX(-5px)';
      setTimeout(() => {
        box.style.transform = 'translateX(5px)';
        setTimeout(() => {
          box.style.transform = 'translateX(-5px)';
          setTimeout(() => {
            box.style.transform = 'translateX(5px)';
            setTimeout(() => {
              box.style.transform = 'translateX(0)';
            }, 50);
          }, 50);
        }, 50);
      }, 50);
    }
  }
  /* 
  Anime.js code
  shake(){
    const box = this.elementRef.nativeElement.querySelector('.box'); // Assuming your component has a box element
    anime({
      targets: box,
      translateX: [
        { value: '-5px', duration: 50 },
        { value: '5px', duration: 50 },
        { value: '-5px', duration: 50 },
        { value: '5px', duration: 50 },
        { value: '0px', duration: 50 }
      ],
      loop: false // Do not loop the animation
  })
  } */
  


}
