import { DeviceService } from './device.service';

import { error } from 'jquery';
import { Injectable, ViewChild, ElementRef } from '@angular/core';
import { jsPDF } from 'jspdf';
import { Chart } from 'chart.js/auto';
import { map, catchError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ReportsService } from '../service/reports.service';
import { BinUsage } from '../constants/bin-usage';
import { ChartService } from '../service/chart.service';
import { SystemMessageService } from './system-message.service';

interface BinData {
  bin_id: string;
  bin_name?: string;
  bin_location: string;
  thing_name: string;
  bin_usage: string;
  bin_address: string;
  legal_name: string;
  market_segment: string;
  threshold: number;
  bin_height: number;
  total_volume: number;
}

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(
    public reports: ReportsService,
    public charts: ChartService,
    public message: SystemMessageService
  ) {}

  public history: any = [];
  public counts: any = [];
  public collections: any = [];
  public historyLabels: any[] = [];
  public historyData: any[] = [];
  public historyTitle: string = '';
  public collectionsLabels: any[] = [];
  public collectionsData: any[] = [];
  public collectionsTitle: string = '';
  public countsLabels: any[] = [];
  public countsData: any[] = [];
  public countsTitle: string = '';
  public label: string = '';

  public thingCollectionLabels: any[] = [];
  public collectionTonnage: any[] = [];
  public usageData: any[] = [];
  public marketData: any[] = [];
  public usageLabels: any[] = [];
  public marketLabels: any[] = [];

  public countChartInstance: any;
  public tonnageChartInstance: any;
  public usageChartInstance: any;
  public marketChartInstance: any;

  public start: string = '';
  public end: string = '';

  public usageCanvas!: ElementRef;
  public marketCanvas!: ElementRef;
  public countCanvas!: ElementRef;
  public tonnageCanvas!: ElementRef;

  public things: any[] = [];

  public generatingPDF: boolean = false;

  public cleanData =  {
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  };

  async savePDF() {
    await this.applyReportFilters();
  }

  async initUsageCanvas() {
    // Destroy the previous chart instance
    if (this.usageChartInstance) {
      this.usageChartInstance.destroy();
      this.usageChartInstance = null; // Clear the reference
    }
    // Clear the canvas element
    const canvas = this.usageCanvas.nativeElement;
    const context = canvas.getContext('2d');
    context.width = context.width;

    // Create a sample chart using chart.js
    // Create a sample chart using chart.js
    this.usageChartInstance = this.usageCanvas.nativeElement;
    this.usageChartInstance = new Chart(this.usageChartInstance, {
      type: 'doughnut',
      data: {
        labels: this.usageLabels,
        datasets: [
          {
            label: 'Usage Distribution',
            data: this.usageData,
            backgroundColor: [
              'rgba(50, 141, 30)',
              'rgb(255, 0, 0)',
              'rgb(0, 0, 255)', // Blue
              'rgb(255, 255, 0)', // Yellow
              'rgb(255, 0, 255)', // Magenta
              'rgb(0, 255, 255)', // Cyan
              'rgb(128, 0, 0)', // Maroon
              'rgb(0, 128, 0)', // Dark Green
              'rgb(0, 0, 128)', // Navy
              'rgb(128, 128, 0)', // Olive
              'rgb(128, 0, 128)', // Purple
              'rgb(0, 128, 128)', // Teal
              'rgb(192, 192, 192)', // Silver
              'rgb(128, 128, 128)', // Gray
              'rgb(255, 165, 0)', // Orange
              'rgb(255, 0, 0)', // Red
            ],
            borderColor: [
              'rgba(50, 141, 30)',
              'rgb(255, 0, 0)',
              'rgb(0, 0, 255)', // Blue
              'rgb(255, 255, 0)', // Yellow
              'rgb(255, 0, 255)', // Magenta
              'rgb(0, 255, 255)', // Cyan
              'rgb(128, 0, 0)', // Maroon
              'rgb(0, 128, 0)', // Dark Green
              'rgb(0, 0, 128)', // Navy
              'rgb(128, 128, 0)', // Olive
              'rgb(128, 0, 128)', // Purple
              'rgb(0, 128, 128)', // Teal
              'rgb(192, 192, 192)', // Silver
              'rgb(128, 128, 128)', // Gray
              'rgb(255, 165, 0)', // Orange
              'rgb(255, 0, 0)', // Red
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
            labels: {
              font: {
                size: 30,
              },
            },
          },
        },
      },
    });
  }

  async initMarketCanvas() {
    // Destroy the previous chart instance
    if (this.marketChartInstance) {
      this.marketChartInstance.destroy();
      this.marketChartInstance = null; // Clear the reference
    }
    // Clear the canvas element
    const canvas = this.marketCanvas.nativeElement;
    const context = canvas.getContext('2d');
    context.width = context.width;
    // Create a sample chart using chart.js
    // Create a sample chart using chart.js
    this.marketChartInstance = this.marketCanvas.nativeElement;
    this.marketChartInstance = new Chart(this.marketChartInstance, {
      type: 'doughnut',
      data: {
        labels: this.marketLabels,
        datasets: [
          {
            label: 'Market Distribution',
            data: this.marketData,
            backgroundColor: [
              'rgba(50, 141, 30)',
              'rgb(255, 0, 0)',
              'rgb(0, 0, 255)', // Blue
              'rgb(255, 255, 0)', // Yellow
              'rgb(255, 0, 255)', // Magenta
              'rgb(0, 255, 255)', // Cyan
              'rgb(128, 0, 0)', // Maroon
              'rgb(0, 128, 0)', // Dark Green
              'rgb(0, 0, 128)', // Navy
              'rgb(128, 128, 0)', // Olive
              'rgb(128, 0, 128)', // Purple
              'rgb(0, 128, 128)', // Teal
              'rgb(192, 192, 192)', // Silver
              'rgb(128, 128, 128)', // Gray
              'rgb(255, 165, 0)', // Orange
              'rgb(255, 0, 0)', // Red
            ],
            borderColor: [
              'rgba(50, 141, 30)',
              'rgb(255, 0, 0)',
              'rgb(0, 0, 255)', // Blue
              'rgb(255, 255, 0)', // Yellow
              'rgb(255, 0, 255)', // Magenta
              'rgb(0, 255, 255)', // Cyan
              'rgb(128, 0, 0)', // Maroon
              'rgb(0, 128, 0)', // Dark Green
              'rgb(0, 0, 128)', // Navy
              'rgb(128, 128, 0)', // Olive
              'rgb(128, 0, 128)', // Purple
              'rgb(0, 128, 128)', // Teal
              'rgb(192, 192, 192)', // Silver
              'rgb(128, 128, 128)', // Gray
              'rgb(255, 165, 0)', // Orange
              'rgb(255, 0, 0)', // Red
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
            labels: {
              font: {
                size: 30,
              },
            },
          },
        },
      },
    });
  }

  async initCollectionCountsCanvas() {
    // Destroy the previous chart instance
    if (this.countChartInstance) {
      this.countChartInstance.destroy();
      this.countChartInstance = null; // Clear the reference
    }
    // Clear the canvas element
    const canvas = this.countCanvas.nativeElement;
    const context = canvas.getContext('2d');
    context.width = context.width;

    // Create a sample chart using chart.js
    this.countChartInstance = this.countCanvas.nativeElement;
    console.log(this.countsData)
    this.countChartInstance = new Chart(this.countChartInstance, {
      type: 'bar',
      data: {
        labels: this.countsLabels,
        datasets: [
          {
            label: 'Total Collections',
            data: this.countsData,
            backgroundColor: ['rgba(50, 141, 30, 0.509)'],
            borderColor: ['rgb(50, 108, 30)'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            ticks: {
              font: {
                size: 30,
              },
            },
          },
          y: {
            ticks: {
              font: {
                size: 30,
              },
            },
          },
        },
      },
    });

    console.log(this.countChartInstance)
  }

  async initTonnageCanvas() {
    // Destroy the previous chart instance
    if (this.tonnageChartInstance) {
      this.tonnageChartInstance.destroy();
      this.tonnageChartInstance = null; // Clear the reference
    }
    // Clear the canvas element
    const canvas = this.tonnageCanvas.nativeElement;
    const context = canvas.getContext('2d');
    context.width = context.width;

    this.tonnageChartInstance = this.tonnageCanvas.nativeElement;
    this.tonnageChartInstance = new Chart(this.tonnageChartInstance, {
      type: 'bar',
      data: {
        labels: this.thingCollectionLabels,
        datasets: [
          {
            label: 'Total Tonnage Collected',
            data: this.collectionTonnage,
            backgroundColor: ['rgb(0, 94, 255, 0.509)'],
            borderColor: ['rgb(93, 0, 255)'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            ticks: {
              font: {
                size: 30,
              },
            },
          },
          y: {
            ticks: {
              font: {
                size: 30,
              },
            },
          },
        },
      },
    });
  }

  totalCollectionsPerDevice(): string {
    const language = localStorage.getItem('language');
    switch (language) {
      case '"en"':
        return 'Total Collections per Device';
      case '"es"':
        return 'Cobranzas totales por dispositivo';
    }

    return 'Collections totales par appareil';
  }

  collectionStatisticsHeader(): string {
    const language = localStorage.getItem('language');
    switch (language) {
      case '"en"':
        return 'Collection Statistics';
      case '"es"':
        return 'Estadísticas de recaudación';
    }

    return 'Statistiques de collecte';
  }

  collectionReport(): string {
    const language = localStorage.getItem('language');
    switch (language) {
      case '"en"':
        return 'Collections Report';
      case '"es"':
        return 'Informe de cobranzas';
    }

    return 'Rapport de collections';
  }

  fileNameCollectionReport(): string {
    const language = localStorage.getItem('language');
    switch (language) {
      case '"en"':
        return 'Collections-Report';
      case '"es"':
        return 'Informe-de-cobranzas';
    }

    return 'Rapport-de-collections';
  }

  infoTextStatistics(): string {
    const language = localStorage.getItem('language');
    switch (language) {
      case '"en"':
        return `This portion of the report contains some aggregate collection statistics between ${this.start} and ${this.end}.`;
      case '"es"':
        return `Esta parte del informe contiene algunas estadísticas de colección agregadas entre ${this.start} y ${this.end}.`;
    }

    return `Cette partie du rapport contient quelques statistiques agrégées de collecte entre le ${this.start} et ${this.end}.`;
  }

  totalTonnagePerDevice(): string {
    const language = localStorage.getItem('language');
    switch (language) {
      case '"en"':
        return 'Total Estimated Tonnage Collected per Device';
      case '"es"':
        return 'Tonelaje total estimado recolectado por dispositivo';
    }

    return 'Tonnage total estimé collecté par appareil';
  }

  usageDistribution(): string {
    const language = localStorage.getItem('language');
    switch (language) {
      case '"en"':
        return 'Usage Distribution';
      case '"es"':
        return 'Distribución de uso';
    }
    return "Répartition de l'utilisation";
  }

  marketDistribution(): string {
    const language = localStorage.getItem('language');
    switch (language) {
      case '"en"':
        return 'Market Distribution';
      case '"es"':
        return 'Distribución del mercado';
    }

    return 'Répartition du marché';
  }

  collectionDataHeader(): string {
    const language = localStorage.getItem('language');
    switch (language) {
      case '"en"':
        return 'Collection Data';
      case '"es"':
        return 'Datos de recopilación';
    }

    return 'Données de collecte';
  }

  infoTextCollectionData(): string {
    const language = localStorage.getItem('language');
    switch (language) {
      case '"en"':
        return `This section of the report contains the parsed data for each individual collection between ${this.start} and ${this.end}.`;
      case '"es"':
        return `Esta sección del informe contiene los datos analizados para cada recolección individual entre ${this.start} y ${this.end}.`;
    }
    return `Cette section du rapport contient les données analysées pour chaque collecte individuelle entre ${this.start} et ${this.end}.`;
  }

  async generatePdf() {
    console.log("GENERATEEEE")
    let headerModifier = 0;
    const language = localStorage.getItem('language');
    switch (language) {
      case '"en"':
        headerModifier = 5;
        break;
      case '"es"':
        headerModifier = 0;
        break;
    }

    // Create a PDF using jsPDF
    const pdf = new jsPDF();
    let totalPages = 1;
    const pageHeader = () => {
      // Add icon
      const icon = '../../assets/muirwood-studio.png'; // Update with your icon path
      pdf.addImage(icon, 'PNG', 10, 5, 32, 10); // Adjust position and size as needed
      const iconHeight = 10; // Adjust as needed
      const marginTop = 10; // Adjust as needed

      pdf.setFontSize(20);
      // Add text to the top right corner
      const text = this.collectionReport();
      const textWidth =
        (pdf.getStringUnitWidth(text) * 12) / pdf.internal.scaleFactor; // Calculate text width
      const pageWidth = pdf.internal.pageSize.getWidth(); // Get page width
      const marginRight = 10; // Adjust as needed
      const textX = pageWidth - textWidth - marginRight - 25;
      pdf.setTextColor(150); // Light grey color (adjust as needed)
      pdf.text(text, textX, marginTop + iconHeight / 2 + -3);
      // Draw a line below the text
      const lineY = marginTop + iconHeight; // Adjust as needed
      const lineWidth = pageWidth * 0.9; // 90% of page width
      const lineX = (pageWidth - lineWidth) / 2; // Center the line
      pdf.setDrawColor(180, 220, 160); // Set line color
      pdf.setLineWidth(1); // Set line width
      pdf.line(lineX, lineY, lineX + lineWidth, lineY);
    };

    // Define a function to increment the total number of pages
    const incrementTotalPages = () => {
      totalPages++;
    };

    // Set up a callback function to be executed before each page is added
    const beforePageAddCallback = () => {
      incrementTotalPages(); // Increment the total number of pages
    };

    // Repeat the header for the second page
    pageHeader();

    // Add header with icon
    const header = this.collectionStatisticsHeader();
    pdf.setFontSize(16);
    pdf.text(header, 10, 30);

    // Add informational body of text
    const infoText = this.infoTextStatistics();
    pdf.setFontSize(12);
    const textLines = pdf.splitTextToSize(infoText, 150); // Adjust width as needed
    pdf.text(textLines, 10, 40); // Adjust position as needed

    console.log(this.countCanvas)

    // Get the chart canvas as an image
    const collectionTotal =
      this.countCanvas.nativeElement.toDataURL('image/png');
      console.log(collectionTotal)
    const collectedTonnage =
      this.tonnageCanvas.nativeElement.toDataURL('image/png');
    const usageDistribution =
      this.usageCanvas.nativeElement.toDataURL('image/png');
    const marketDistribution =
      this.marketCanvas.nativeElement.toDataURL('image/png');

    // Add header with icon
    const headerChart = this.totalCollectionsPerDevice();
    pdf.setFontSize(14);
    pdf.text(headerChart, 70 + headerModifier, 65);
    console.log(collectionTotal)
    // Add the chart image to the PDF
    pdf.addImage(collectionTotal, 'PNG', 30, 70, 150, 75);

    const headerChart2 = this.totalTonnagePerDevice();
    pdf.setFontSize(14);
    pdf.text(headerChart2, 55, 185);
    // Add the chart image to the PDF
    pdf.addImage(collectedTonnage, 'PNG', 25, 190, 155, 75);

    beforePageAddCallback();
    pdf.addPage();

    // Repeat the header for the second page
    pageHeader();
    const headerChart3 = this.usageDistribution();
    pdf.setFontSize(14);
    pdf.text(headerChart3, 80 + headerModifier, 45);
    // Add the chart image to the PDF
    pdf.addImage(usageDistribution, 'PNG', 55, 50, 100, 100);

    const headerChart4 = this.marketDistribution();
    pdf.setFontSize(14);
    pdf.text(headerChart4, 80 + headerModifier, 165);
    // Add the chart image to the PDF
    pdf.addImage(marketDistribution, 'PNG', 55, 170, 100, 100);

    beforePageAddCallback();
    pdf.addPage();

    // Repeat the header for the second page
    pageHeader();

    // Add header with icon
    const header2 = this.collectionDataHeader();
    pdf.setFontSize(16);
    pdf.text(header2, 10, 30);

    // Add informational body of text
    const infoText2 = this.infoTextCollectionData();
    pdf.setFontSize(12);
    const textLines2 = pdf.splitTextToSize(infoText2, 150); // Adjust width as needed
    pdf.text(textLines2, 10, 40); // Adjust position as needed

    // Add data tables
    const data = this.collections;

    const tableStartY = 90; // Adjust position as needed
    const colWidth = pdf.internal.pageSize.getWidth() / data[0].length - 2;
    const rowHeight = 10;
    const lineHeight = 7;
    let currentY = tableStartY;

    const renderColumnHeaders = (currentY = 55) => {
      this.collections[0].forEach((header: string, cellIndex: number) => {
        pdf.setFont('helvetica', 'bold');
        pdf.setFontSize(10); // Adjust font size as needed
        pdf.setTextColor(150); // Light grey color (adjust as needed)
        pdf.text(header, cellIndex * colWidth + 10, currentY + lineHeight + 10);

        // Add underline directly below the text
        const textWidth =
          (pdf.getStringUnitWidth(header) * 12) / pdf.internal.scaleFactor;
        const startX = cellIndex * colWidth + 10;
        const endX = startX + textWidth;
        const lineY = currentY + lineHeight + 12; // Adjust the Y position to drop the line below the text

        pdf.setLineWidth(0.1);
        pdf.setDrawColor(150); // Set line color
        pdf.line(startX, lineY, endX, lineY);
      });
    };

    let columnHeaders: string[] = [];
    let headersRendered = false;
    renderColumnHeaders();
    let first = true;
    this.collections.forEach((row: any, rowIndex: any) => {
      // Check if there's enough space for the row, otherwise start a new page
      if (currentY + rowHeight > pdf.internal.pageSize.getHeight() - 25) {
        first = false;
        beforePageAddCallback();
        pdf.addPage(); // Start a new page
        // Add icon
        // Repeat the header for the second page
        pageHeader();
        currentY = 25; // Reset Y position for the table

        // Render column headers at the beginning of each new page
        renderColumnHeaders(currentY);
        currentY += rowHeight; // Move Y position down after rendering headers
      }

      // Render row cells
      Object.keys(row).forEach((key: string, cellIndex: number) => {
        const cell = row[key];

        // Set font style for the first row to extract column headers
        if (rowIndex === 0 && !headersRendered) {
          columnHeaders.push(cell);
        }

        let modifier = 0;
        if (first) {
          modifier = 25;
        }

        // Render row data
        if (rowIndex !== 0) {
          if (cellIndex === 0) {
            currentY += rowHeight;
          }
          pdf.setFont('helvetica', 'normal');
          pdf.setTextColor(0); // Black color
          pdf.setFontSize(8); // Adjust font size as needed
          pdf.text(
            cell,
            cellIndex * colWidth + 10,
            currentY + lineHeight - modifier
          );
        }
      });
    });

    // Calculate line positions
    const lineStartX = pdf.internal.pageSize.getWidth() * 0.05; // Start 5% from the left edge
    const lineEndX = pdf.internal.pageSize.getWidth() * 0.95; // End 5% from the right edge
    const lineY = pdf.internal.pageSize.getHeight() - 10; // Position the line above the footer

    // Set draw color for the line
    pdf.setDrawColor(180, 220, 160);

    // Draw the line
    pdf.line(lineStartX, lineY, lineEndX, lineY);

    let page = 'Page';
    let adverb = 'sur';

    switch (language) {
      case '"en"':
        adverb = 'of';
        break;
      case '"es"':
        page = 'Página';
        adverb = 'de';
        break;
    }

    // After the PDF is generated, add page numbers to each page
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i); // Go to specified page
      pdf.setDrawColor(180, 220, 160);
      // Draw the line
      pdf.line(lineStartX, lineY, lineEndX, lineY);
      // Get the current local date
      const currentDate = new Date().toLocaleDateString();

      pdf.setTextColor(150);
      // Add current local date on the right side of the footer
      pdf.text(
        currentDate,
        pdf.internal.pageSize.getWidth() - 195,
        pdf.internal.pageSize.getHeight() - 5
      );

      // Add page number on the right
      pdf.text(
        `${page} ${i} ${adverb} ${totalPages}`,
        pdf.internal.pageSize.getWidth() - 30,
        pdf.internal.pageSize.getHeight() - 5
      );
    }

    // Save or display the PDF
    pdf.save(
      `${this.fileNameCollectionReport()}-${this.start}-${this.end}.pdf`
    );
    this.generatingPDF = false;
    this.clearCanvas();
  }

  dateToUnixTimestamp(dateString: string): number {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Get the UTC timestamp in milliseconds
    const timestampInMilliseconds = date.getTime();

    // Convert milliseconds to seconds and return
    return Math.floor(timestampInMilliseconds / 1000);
  }

  formatDate(date: string): string {
    // Convert input date string to a date object
    const dateObject = new Date(date);

    // Adjust the date to represent the correct local date
    dateObject.setUTCFullYear(dateObject.getUTCFullYear());
    dateObject.setUTCMonth(dateObject.getUTCMonth());
    dateObject.setUTCDate(dateObject.getUTCDate() + 1); // Increment by one day

    // Format the date as a string
    const dateString = dateObject.toLocaleDateString(
      this.charts.getDateLanguage(),
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
    );

    return dateString;
  }

  async applyReportFilters() {

    this.history = [];
    this.counts = [];
    this.collections = [];
    this.usageData = [];
    this.usageLabels = [];
    this.marketLabels = [];
    this.marketData = [];
    this.collectionsData = [];
    this.collectionTonnage = [];
    this.thingCollectionLabels = [];
    this.countsLabels = [];
    this.countsData = [];

    const start = this.dateToUnixTimestamp(this.start);
    console.log(start)
    const end = this.dateToUnixTimestamp(this.end);
    console.log(end)


    this.start = this.formatDate(this.start);
    console.log(this.start)
    this.end = this.formatDate(this.end);
    console.log(this.end)


    let things = this.things;
    console.log(things)

    try {
      (await this.reports.getReportData(things, start, end))
        .pipe(
          map((response) => {
            console.log(response)
            // return the response data
            return response;
          }),
          catchError((error) => {
            console.error('API Error:', error);
            this.generatingPDF = false;
            this.message.selectRibbon('danger', 'alert-danger-generic-message');
            throw error; // Re-throw the error for the calling code to handle
          })
        )
        .subscribe(async (res: any) => {
          console.log(res)
          console.log('usage', res?.usage);
          console.log('markets', res?.markets);
          let label = '';
          let index = 0;
          let first = true;
          const binData: BinData[] = JSON.parse(res.bins);

          for (const usage of JSON.parse(res.usage)) {
            this.usageData.push(usage.bin_usage_count);
            console.log(this.usageData)
            this.usageLabels.push(this.binUsage(usage.bin_usage));
            console.log(this.usageLabels)

          }

          for (const market of JSON.parse(res.markets)) {
            this.marketData.push(market.market_segment_count);
            this.marketLabels.push(this.marketSegment(market.market_segment));
          }

          this.collections.push([
            'Device',
            'Capacity',
            'Date',
            'Threshold',
            'Volume',
            'Usage',
            'Market',
            'Distributor',
          ]);
          for (const collection of JSON.parse(res.collections)) {
            const bin = this.filterBinData(binData, collection.thing_name)[0];

            this.collections.push([
              collection.thing_name,
              `${(
                ((bin.bin_height - collection.preceding_dst) / bin.bin_height) *
                100
              ).toFixed(2)}%`,
              new Date(
                collection.preceding_timestamp * 1000
              ).toLocaleDateString(this.charts.getDateLanguage(), {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }),
              `${bin.threshold}%`,
              `${bin.total_volume}L`,
              `${this.binUsage(bin.bin_usage)}`,
              `${this.marketSegment(bin.market_segment)}`,
              `${bin.legal_name}`,
            ]);
          }

          for (const counts of JSON.parse(res.counts)) {
            const bin = this.filterBinData(binData, counts.thing_name)[0];
            this.counts.push(counts);
            this.countsLabels.push(counts.thing_name);
            this.countsData.push(counts.collection_count);

            this.thingCollectionLabels.push(counts.thing_name);
            const cubicMetersOfWaste =
              this.calculateCubicMeters(
                bin.total_volume,
                counts.avg_preceding_dst,
                bin.bin_height
              ) * counts.collection_count;
            const tonnage =
              this.calculateWasteCollection(bin.bin_usage, cubicMetersOfWaste) *
              0.001;
            this.collectionTonnage.push(tonnage);
          }

          await this.initCollectionCountsCanvas();
          await this.initTonnageCanvas();
          await this.initUsageCanvas();
          await this.initMarketCanvas();

          console.log('history', this.history);
          console.log('counts', this.counts);
          console.log('collections', this.collections);
          setTimeout(() => {
            this.generatePdf();
          }, 2000);
          this.generatingPDF = false;
        });
    } catch (error) {
      console.error('API Error: ', error);
      this.generatingPDF = false;
    }
  }

  filterBinData(array: BinData[], thingName: string): any {
    return array
      .filter((item) => item.thing_name === thingName)
      .map(
        ({
          bin_id,
          bin_location,
          bin_usage,
          bin_address,
          legal_name,
          market_segment,
          threshold,
          bin_height,
          total_volume,
        }) => ({
          bin_id,
          bin_location,
          bin_usage,
          bin_address,
          legal_name,
          market_segment,
          threshold,
          bin_height,
          total_volume,
        })
      );
  }

  // Function that will calculate the volume if all field are well filled
  calculateCubicMeters(binVolume: number, dst: number, binHeight: number) {
    const multiplier = 1 - dst / binHeight;
    console.log(multiplier, (binVolume / 1000) * multiplier);
    return (binVolume / 1000) * multiplier;
  }

  marketSegment(id: string): string {
    const language = localStorage.getItem('language');
    switch (language) {
      case '"en"':
        switch (id) {
          case 'TE':
            return 'Technology';
          case 'FO':
            return 'Restaurant';
          case 'ST':
            return 'Commerce';
          case 'CO':
            return 'Construction';
          case 'MA':
            return 'Manufacturing';
          case 'RE':
            return 'Residential';
          case 'MU':
            return 'Municipal';
          case 'SC':
            return 'Education';
          default:
            return 'N/A';
        }
      case '"es"':
        switch (id) {
          case 'TE':
            return 'Tecnología';
          case 'FO':
            return 'Restaurante';
          case 'ST':
            return 'Comercio';
          case 'CO':
            return 'Construcción';
          case 'MA':
            return 'Fabricación';
          case 'RE':
            return 'Residencial';
          case 'MU':
            return 'Municipal';
          case 'SC':
            return 'Educación';
          default:
            return 'N/A';
        }
      default:
        switch (id) {
          case 'TE':
            return 'Technologie';
          case 'FO':
            return 'Restaurant';
          case 'ST':
            return 'Commerce';
          case 'CO':
            return 'Construction';
          case 'MA':
            return 'Fabrication';
          case 'RE':
            return 'Résidentiel';
          case 'MU':
            return 'Municipal';
          case 'SC':
            return 'Éducation';
          default:
            return 'N/A';
        }
    }
  }

  binUsage(id: string): string {
    const language = localStorage.getItem('language');
    switch (language) {
      case '"en"':
        switch (id) {
          case 'MT':
            return 'Metal';
          case 'PC':
            return 'Paper';
          case 'PL':
            return 'Plastic';
          case 'CN':
            return 'Cans';
          case 'GL':
            return 'Glass';
          case 'OR':
            return 'Organic';
          case 'GW':
            return 'Waste';
          case 'GR':
            return 'Recycling';
          case 'BA':
            return 'Batteries';
          case 'MW':
            return 'Medical Waste';
          case 'RB':
            return 'Rubber';
          case 'TW':
            return 'Electronics';
          case 'CM':
            return 'Construction';
          case 'CL':
            return 'Clothing';
          case 'CB':
            return 'Cardboard';
          default:
            return 'N/A';
        }
      case '"es"':
        switch (id) {
          case 'MT':
            return 'Metal';
          case 'PC':
            return 'Papel';
          case 'PL':
            return 'Plástico';
          case 'CN':
            return 'Latas';
          case 'GL':
            return 'Vidrio';
          case 'OR':
            return 'Orgánicos';
          case 'GW':
            return 'Generales';
          case 'GR':
            return 'Reciclaje';
          case 'BA':
            return 'Baterías';
          case 'MW':
            return 'Médicos';
          case 'RB':
            return 'Goma';
          case 'TW':
            return 'Electrónicos';
          case 'CM':
            return 'Construcción';
          case 'CL':
            return 'Ropa';
          case 'CB':
            return 'Cartón';
          default:
            return 'N/A';
        }
      default:
        switch (id) {
          case 'MT':
            return 'Métal';
          case 'PC':
            return 'Papier';
          case 'PL':
            return 'Plastique';
          case 'CN':
            return 'Boîtes';
          case 'GL':
            return 'Verre';
          case 'OR':
            return 'Organiques';
          case 'GW':
            return 'Déchets';
          case 'GR':
            return 'Recyclage';
          case 'BA':
            return 'Batteries';
          case 'MW':
            return 'Médicaux';
          case 'RB':
            return 'Caoutchouc';
          case 'TW':
            return 'Électroniques';
          case 'CM':
            return 'Construction';
          case 'CL':
            return 'Vêtements';
          case 'CB':
            return 'Carton';
          default:
            return 'N/A';
        }
    }
  }

  calculateWasteCollection(
    usage: string,
    totalCubicMetersOfWaste: number
  ): number {
    const weightPerCubicMeter = this.getWeightByValue(usage);
    console.log(
      totalCubicMetersOfWaste,
      weightPerCubicMeter,
      totalCubicMetersOfWaste * weightPerCubicMeter
    );
    if (!isNaN(totalCubicMetersOfWaste) && weightPerCubicMeter !== undefined) {
      return totalCubicMetersOfWaste * weightPerCubicMeter;
    } else {
      return 0;
    }
  }

  //Get the average weight for the bin usage type
  getWeightByValue(value: string): number {
    const bin = BinUsage.find((bin) => bin.value === value);
    return bin?.weight || 0;
  }

  clearCanvas(nullify = true) {

    console.log(this.tonnageChartInstance);
    this.tonnageChartInstance.clear();
    this.countChartInstance.clear();
    this.marketChartInstance.clear();
    this.usageChartInstance.clear();

    this.tonnageChartInstance.config.data = this.cleanData;

    this.tonnageChartInstance.update();
    this.countChartInstance.update();
    this.marketChartInstance.update();
    this.usageChartInstance.update();

    this.tonnageChartInstance.destroy();
    this.countChartInstance.destroy();
    this.marketChartInstance.destroy();
    this.usageChartInstance.destroy();

    if (nullify) {
      this.tonnageChartInstance = null; // Clear the reference
      this.countChartInstance = null;
      this.marketChartInstance = null;
      this.usageChartInstance = null;
    }
  }
}
