<app-system-message></app-system-message>

<div [ngClass]="theme.getThemeClass()" class="container-fluid">
  <!-- Search input component -->
  <nav id="search-component" class="input-group">
    <input #searchBox type="search"  id="search-box" [(ngModel)]="userFilter" (input)="search()" class="form-control"
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}" aria-label="Search" aria-describedby="search-addon" />
  </nav>


  <!-- Table header for the user list -->
  <section [ngClass]="theme.getThemeClass()" class="table-header" >
    <span class="left">
      {{ 'userList' | translate }}
    </span>
    <article [ngClass]="theme.getThemeClass()" (click)="dropdownAction()" class="dropdownDiv">
      <button [ngClass]="theme.getThemeClass()" class="btn btn-dropdown" id="buttonDropdown">
        {{ "iotNavButtonActions" | translate }}
      </button>
      <div [ngClass]="theme.getThemeClass()" *ngIf="isDropdownOpen" class="dropdown-content" id="dropdownContent">
        <a class="btnStyle" routerLink="/user-create-muirwood" href="javascript:void(0)">
          &#x2b; {{ 'createMuirwoodUser' | translate }}
        </a>
        <a class="btnStyle" routerLink="/user-create-client" href="javascript:void(0)">
          &#x2b; {{ 'createClientUser' | translate }}
        </a>
        <a class="lastBtnStyle" routerLink="/user-create-distributor" href="javascript:void(0)">
          &#x2b; {{ 'createDistributorUser' | translate }}
        </a>
        <a class="lastBtnStyle" routerLink="/user-create-operator" href="javascript:void(0)">
          &#x2b; {{ 'createOperatorUser' | translate }}
        </a>
      </div>
    </article>
  </section>

  <!-- Table container for the user list -->
  <section [ngClass]="theme.getThemeClass()" class="table-container">
    <article  class="table-responsive minMarginBotton">
      <table  class="table table-hover">
        <thead >
          <tr>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ 'name' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'legalName' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
                {{ 'email' | translate }}
              </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'phoneNumber' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'address' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'role' | translate }}
            </th>
          </tr>
        </thead>
        <ng-container  *ngFor="let user of userList">
          <tbody>
            <tr class="vertical-align">
              <!--   NAME    -->
              <td [ngClass]="theme.getThemeClass()">
                <a (click)="identifyUserType(user)" [routerLink]="" href="javascript:void(0)" [style.color]="this.user.enabled === '1' ? 'green!important' : 'red!important'">
                  {{ user.given_name }}  {{ user.family_name }}
                </a>
              </td>
              <!--   LEGAL NAME    -->
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">
                <a [routerLink]="" href="javascript:void(0)">
                  {{ user.legal_name }}
                </a>
              </td>
              <!--   EMAIL    -->
              <td [ngClass]="theme.getThemeClass()">
                <a [routerLink]="" href="javascript:void(0)">
                  {{ user.email }}
                </a>
              </td>
              <!--   PHONE NUMBER    -->
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">
                <a [routerLink]="" href="javascript:void(0)">
                  {{ this.formatarNumero(user.phone_number) }}
                </a>
              </td>
              <!--   ADDRESS    -->
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">
                <a [routerLink]="" href="javascript:void(0)">
                  {{ user.address }}
                </a>
              </td>
              <!--   ROLE    -->
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">
                <a [routerLink]="" href="javascript:void(0)">
                  {{ user.custom_current_role | translate }}
                </a>
              </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </article>
  </section>
</div>







