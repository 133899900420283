import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorModalService {
  public showErrorModal:boolean = false;
  public errorHeaderIcon: string ='assets/error.png'; // Define the headerIconPath here;
  public errorTitle:string='Test Error';
  public errorMessage:string='Loren Ipsum - Test Error Message – 200 Characters including spaces – Checking to see if this error message displays properly on a variety of screen sizes – ideally the entire message should be visible';

  constructor() { }

  setShowErrorModal() {
    localStorage.setItem('showErrorModal', 'true'); // Set localStorage to true
  }

  isShowErrorModal() {
    this.showErrorModal=false;
    this.errorTitle = localStorage.getItem("errorTitle") || "";
    this.errorMessage = localStorage.getItem("errorMessage") || "";

    if(localStorage.getItem('showErrorModal') === 'true'){
      localStorage.setItem('showErrorModal', 'false'); // Set localStorage to false

      this.showErrorModal=true;
    }

  }
closeErrorModal() {
  localStorage.setItem('showErrorModal', 'false'); // Set localStorage to false
      this.showErrorModal=false;
      localStorage.setItem('showErrorModal', 'false'); // Set localStorage to false
      localStorage.removeItem('errorTitle');
      localStorage.removeItem('errorMessage');
}

// Add methods to set and update error details
setErrorDetails(title: string, message: string) {
  localStorage.setItem("errorTitle", title);
  localStorage.setItem("errorMessage", message)
  this.errorTitle = title;
  this.errorMessage = message;
}

}
