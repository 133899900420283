import { Injectable } from '@angular/core';
import * as fs from 'fs';
import * as AWS from 'aws-sdk';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  constructor() {
      // Set your AWS credentials and region
      AWS.config.update({
        accessKeyId: environment.iot.accessKeyId,
        secretAccessKey: environment.iot.secretAccessKey,
        region: environment.iot.region,
      });
  }


// Upload a generated Zip Folder of newly provisioned Thing Certificates to an S3 bucket
async uploadCertificateFolderToS3(folderName: string, zipContent: any, zipFolderName:string) {

  // Create an S3 instance
  const s3 = new AWS.S3();

  // Upload the zipped folder to the specified bucket and folder
  await s3.putObject({
    Bucket: environment.s3.bucketName,
    Key: environment.s3.folderPath +`${folderName}/${zipFolderName}`,
    Body: zipContent,
    ContentType: 'application/zip',
  }).promise();

  //console.log('Zipped folder uploaded successfully.');
}

async getCertificatesForThing(thingName: string){
  const params = {
    Bucket: environment.s3.bucketName,
    Prefix: environment.s3.folderPath + thingName,
  };

  const s3 = new AWS.S3();

  return s3.listObjectsV2(params).promise()
    .then(data => {
      return data.Contents?.map(object => {

        return this.generatePresignedUrl(object.Key);
      });
    })
    .catch(error => {
      console.error('Error listing objects:', error);
      return [];
    });
}

private generatePresignedUrl(objectKey: string = ""): string {
  const s3 = new AWS.S3();
  const params = {
    Bucket: environment.s3.bucketName,
    Key: objectKey,
    Expires: 60, // Set the expiration time for the presigned URL in seconds
  };

  return s3.getSignedUrl('getObject', params);
}

}
