import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { BinsService } from '../service/bins.service';
import { BinUsage } from '../constants/bin-usage';

@Component({
  selector: 'app-bin-usage',
  templateUrl: './bin-usage.component.html',
  styleUrls: ['./bin-usage.component.css', '../../global-elements.css']
})

@Injectable({
  providedIn: 'root'
})

export class BinUsageComponent implements OnInit {

  public selectedValue: string = '';
  public binUsage = BinUsage;

  constructor(public bin: BinsService){

  }

  ngOnInit(): void {

  }

  // function called when user change the selected option
  onSelectChange(){
    this.bin.typeOfWaste = this.selectedValue; // Set value of the bin-service to is selected value
    this.bin.typeOfWasteSelected(); // call the function that will trigger bin-create or bin-update to get the value of the selected option
  }

  // Function that is called to get the selected value
  getSelectedValue(): string {
    return this.selectedValue;
  }
}
