import { Component, OnInit, HostListener, ChangeDetectorRef, ViewChild, Renderer2, numberAttribute } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { DistributorsService } from '../service/distributors.service';
import { Inject, forwardRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CognitoService } from '../service/cognito.service';
import { RoleService } from '../service/role.service';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FilterService } from '../service/filter.service';
import { BinsService } from '../service/bins.service';

@Component({
  selector: 'app-distributor-bins-model-dashboard',
  templateUrl: './distributor-bins-model-dashboard.component.html',
  styleUrls: ['./distributor-bins-model-dashboard.component.css', './distributor-tiles.css', '../../global-elements.css']
})
export class DistributorBinsModelDashboardComponent implements OnInit {

  public distributorBinArray: any[] = [];
  public uniqueBinModelArray: any[] = [];
  private originalUniqueBinArray: any[] = [];
  public isSmallScreenWidth: boolean = this.getScreenWidth() <= 859;

  public userFilter: string = '';
  private filterCount: number = 0;
  private lastFilterCount: number = 0;
  private isFilter: boolean = false;
  public isSortBy: boolean = false;
  public sortBy: string = '';
  public ascDesc: number = 0;

  constructor(public theme: ThemeService,
              public distributors: DistributorsService,
              @Inject(forwardRef(() => TranslateService)) public translate: TranslateService,
              private cognitoService: CognitoService,
              private roleService: RoleService,
              private route: Router,
              private cdr: ChangeDetectorRef,
              public filter: FilterService,
              public bin: BinsService
              ){

  }

  async ngOnInit() {
    // Call cognito to confirm valid user and get the user type of the current user
    this.cognitoService.confirmValidUser();
    this.cognitoService.getUserType();

    // Call role service to get the current user roles
    await this.roleService.getRoles();

    // Call the function to get all bin model association of the distributor
    await this.getBinModelAssociationByDistributor();

    // Call a function to create a new array with only single bin model
    this.createArrayOfBinModel();

    // Set an original array used with the filter when we reset the array
    this.originalUniqueBinArray = this.uniqueBinModelArray;

    const currentScreenWidth = this.getScreenWidth();
  }

  // Function call to create a new array of only single bin model
  createArrayOfBinModel(){
    // Set an object array that will receiving bin_number to be abble to compare if we already have this bin model number
    let valueAlreadyIn: { [key: string]: boolean } = {};

    // Filter the new array
    this.uniqueBinModelArray = this.distributorBinArray.filter(element => {
      let bin_number = element.bin_model_number;
      if(!valueAlreadyIn[bin_number]){
        valueAlreadyIn[bin_number] = true;
        return true;
      }
      return false;
    });
  }

  resetUniqueBinArray(){
    this.uniqueBinModelArray = [...this.originalUniqueBinArray];
  }

  // Event listener for window resize
  @HostListener('window:resize', ['$event'])
    onResize(event: any): void {

      const currentScreenWidth = this.getScreenWidth();
      // Check the screen width and update the isSmallScreen flag accordingly
      this.isSmallScreenWidth = currentScreenWidth <= 859;
  }

  // Function called to get the column style of the grid list
  getColumnStyle(): any {
    const numberOfColumn = this.ajustNumberOfColumns(this.getScreenWidth());
    if(numberOfColumn){
      if(this.uniqueBinModelArray.length >= numberOfColumn){
        const gridColumns = `repeat(${numberOfColumn}, minmax(0, 325px))`;
        return {
          'grid-template-columns': gridColumns
        };
      }else{
        return {
          'display': `flex`,
          'justify-content': `center`,
          'margin': '0 -10px'
        };
      }
    }
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  // function called to adjust the number of column of the grid depbending of inner window width
  ajustNumberOfColumns(width: number): number | undefined{
    // Return the floor of the inner page width divided by 325 px as the width of the tile size
    return Math.floor((width / 325) * (90 / 100));
  }

  // Get all ths bin model for the distributor
  async getBinModelAssociationByDistributor(){
    await this.distributors.getBinModelAssociationByDistributorId(this.cognitoService.distributorId).then((res) => {
      try{
        this.distributorBinArray = res;
      }catch(error){
        console.error('Error: ', error);
      }
    });
  }

  // function called from the filter to filter the bin model array
  search(){
    // Set last filter count, filter count and if there's a filter
    this.lastFilterCount = this.filterCount;
    this.isFilter = this.userFilter.toLowerCase() !== '';
    this.filterCount = this.userFilter.length;

    // Switch if user enter more filter, press backspace or delete filter
    switch(true){
      case (this.filterCount > this.lastFilterCount):
        this.uniqueBinModelArray = this.uniqueBinModelArray.filter( v => v.bin_model_number.toLowerCase().includes(this.userFilter));
        this.lastFilterCount = this.filterCount; // Set the count of the user filter
        break;

      case (this.filterCount < this.lastFilterCount):
        this.resetUniqueBinArray(); // Reset the array of bin models
        // Filter the array of bin models
        this.uniqueBinModelArray = this.uniqueBinModelArray.filter( v => v.bin_model_number.toLowerCase().includes(this.userFilter));
        this.lastFilterCount = this.filterCount; // Set the count of the user filter
        break;

      case (this.isFilter === false):
        this.resetUniqueBinArray(); // Reset the array of bin models
        this.filterCount = 0;
        this.lastFilterCount = 0;
        break;
    }
  }

  // Function called from switch ascendind/descending or select of sort by to sort the array of bin models
  onSortChange(caller: string, event: any){
    // Reset sortBy variable to hide the toggle switch when user press the X in the select
    if(event === undefined){
      this.isSortBy = false;
    }else{
      this.isSortBy = true;
    }

    // Switch the value of ascending or descending
    if(caller === 'switch'){
      if(this.ascDesc === 0){
        this.ascDesc = 1;
      }else{
        this.ascDesc = 0;
      }
    }

    // Avoid sortBy to have no value
    if(this.sortBy === ''){
      this.sortBy = 'number';
    }

    // Function that sort the list
    this.uniqueBinModelArray.sort((a, b) => {
      switch(this.sortBy){
        case 'number':
          return this.ascDesc === 0 ? b.bin_model_number.localeCompare(a.bin_model_number) : a.bin_model_number.localeCompare(b.bin_model_number);

        case 'volume':
          return this.ascDesc === 0 ? b.total_volume - a.total_volume : a.total_volume - b.total_volume;

        case 'numberBin':
          return this.ascDesc === 0 ? b.number_of_bin - a.number_of_bin : a.number_of_bin - b.number_of_bin;

        case 'thing':
          return this.ascDesc === 0 ? b.with_thing - a.with_thing : a.with_thing - b.with_thing;
      }
    });
  }

  // Function called to go to distributor bin list from the passed bin model id and filter the list of bin related to that bin model
  goToDistributorBinDashboard(bin_model_id: string){
    // Filter the bin array of the distributor
    this.distributors.binArray = this.distributorBinArray.filter(bin => bin.bin_model_id === bin_model_id);
    this.route.navigate(['./bin-dashboard', bin_model_id]);
  }

  // Function called to go back to the dashboard
  backToDashboardList(){
    this.route.navigate(['./dashboard']);
  }
}
