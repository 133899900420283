import { environment } from './../environments/environment';

export const navigation = [
  {
    number: '1',
    name: 'sidenavMenu.HOME',
    mobile:'headerMobileMenuHome',
    icon: 'fas fa-home', // Icon representing configuration settings
    routeUrl: 'dashboard',// Route URL for the configurations page
    validation: false,
    userType: "",
    userRole: "",
    actions: []
  },
  {
    number: '2',
    name: 'sidenavMenu.CLIENTS',
    mobile: 'headerMobileMenuACCOUNTS',
    icon: 'fas fa-users', // Icon representing configuration settings
    routeUrl: 'entities', // Route URL for the configurations page
    validation: true,
    userType: environment.users.superAdmin,
    userRole: environment.users.role.administrator,
    actions: []
  },
  {
    number: '3',
    name: 'sidenavMenu.IOT',
    mobile: 'headerMobileMenuIOT',
    icon: 'fas fa-satellite-dish', // Icon representing configuration settings
    routeUrl: 'iot', // Route URL for the configurations page
    validation: true,
    userType: environment.users.superAdmin,
    userRole: environment.users.role.administrator,
    actions: []
  },
  {
    number: '6',
    name: 'sidenavMenu.ADMIN',
    mobile: 'headerMobileMenuADMIN',
    icon: 'fab fa-centos', // Icon representing configuration settings
    routeUrl: 'admin', // Route URL for the configurations page
    validation: true,
    userType: environment.users.superAdmin,
    userRole: environment.users.role.administrator,
    actions: []
  },
  {
    number: '7',
    name: 'sidenavMenu.ZONES',
    mobile: 'headerMobileMenuZONES',
    icon: 'far fa-map', // Icon representing configuration settings
    routeUrl: 'zones', // Route URL for the configurations page
    validation: true,
    userType: environment.users.superAdmin,
    userRole: environment.users.role.administrator,
    actions: []
  },
  {
    number: '8',
    name: 'sidenavMenu.distributor.ADMIN',
    mobile: 'sidenavMenu.distributor.ADMIN',
    icon: 'fab fa-centos', // Icon representing configuration settings
    routeUrl: 'distributor-admin-dashboard', // Route URL for the configurations page
    validation: true,
    userType: environment.users.supplier,
    userRole: environment.users.role.distributor,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
        selectedOption: ''
      },
      {
        title: 'adminViewUserListNavBar',
        options: [
          {name: 'createUser', icon: 'fa fa-plus', route: '/user-create-distributor'},
          {name: 'userList', icon: 'fa fa-list', route: '/distributor-admin-dashboard'}
        ]
      }
    ]
  },
  {
    number: '9',
    name: 'sidenavMenu.distributor.IOT',
    mobile: 'sidenavMenu.distributor.IOT',
    icon: 'fa-solid fa-satellite-dish', // Icon representing configuration settings
    routeUrl: 'distributor-iot-dashboard', // Route URL for the configurations page
    validation: true,
    userType: environment.users.supplier,
    userRole: environment.users.role.distributor,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
        selectedOption: ''
      },
      {
        title: 'iotNavButtonDevices',
        options: [
          {name: 'userlistTableHeaderDeviceList', icon: 'fa fa-trash', route: '/distributor-iot-dashboard'}
        ]
      }
    ]
  },
  {
    number: '10',
    name: 'sidenavMenu.distributor.BINS',
    mobile: 'sidenavMenu.distributor.BINS',
    icon: 'far fa-trash-alt', // Icon representing configuration settings
    routeUrl: 'distributor-bins-model-dashboard', // Route URL for the configurations page
    validation: true,
    userType: environment.users.supplier,
    userRole: environment.users.role.distributor,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
      },
      {
        title: 'chartLabelBins',
        options: [
          {name: 'binCreateViewButtonCreateBin', icon: 'fa fa-plus', route: '/bin-create'},
          {name: 'binModelCreateBinModelCreate', icon: 'fa fa-plus', route: '/bin-model-create'},
          {name: 'binModelListViewBinModelList', icon: 'fa fa-list', route: '/distributor-bins-model-dashboard'}
        ]
      }
    ]
  },
  {
    number: '11',
    name: 'sidenavMenu.client.ADMIN',
    mobile: 'headerMobileMenuClientADMIN',
    icon: 'fas fa-users', // Icon representing configuration settings
    routeUrl: 'client-user-list', // Route URL for the configurations page
    validation: true,
    userType: environment.users.standardUser,
    userRole: environment.users.role.client,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
        selectedOption: ''
      },
      {
        title: 'adminViewUserListNavBar',
        options: [
          {name: 'createUser', icon: 'fa fa-plus', route: '/user-create-client'},
          {name: 'userList', icon: 'fa fa-list', route: '/client-admin-dashboard'}
        ]
      }
    ]
  },
  {
    number: '12',
    name: 'sidenavMenu.client.IOT',
    mobile: 'headerMobileMenuClientIOT',
    icon: 'fa-solid fa-satellite-dish', //
    routeUrl: 'client-thing-list', // Route URL for the configurations page
    validation: true,
    userType: environment.users.standardUser,
    userRole: environment.users.role.client,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
        selectedOption: ''
      },
      {
        title: 'iotNavButtonDevices',
        options: [
          {name: 'availableDevice', icon: 'icon-device', route: '/client-iot-dashboard'},
          {name: 'associatedDevice', icon: 'fa fa-trash'}
        ]
      }
    ]
  },
  {
    number: '13',
    name: 'sidenavMenu.client.BINS',
    mobile: 'headerMobileMenuClientBINS',
    icon: 'far fa-trash-alt', //
    routeUrl: 'client-bin-dashboard', // Route URL for the configurations page
    validation: true,
    userType: environment.users.standardUser,
    userRole: environment.users.role.client,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
      },
      {
        title: 'chartLabelBins',
        options: [
          {name: 'binCreateViewButtonCreateBin', icon: 'fa fa-plus', route: '/bin-create'},
          {name: 'binModelListViewBinModelList', icon: 'fa fa-list', route: '/client-bin-dashboard'}
        ]
      }
    ]
  },
  {
    number: '14',
    name: 'sidenavMenu.distributor.WORKORDER',
    mobile: 'sidenavMenu.distributor.WORKORDER',
    icon: 'fa-solid fa-list-check', // Icon representing configuration settings
    routeUrl: 'distributor-work-order-dashboard', // Route URL for the configurations page
    validation: true,
    userType: environment.users.supplier,
    userRole: environment.users.role.distributor,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
      },
      {
        title: 'chartLabelBins',
        options: [
          {name: 'createWorkOrder', icon: 'fa fa-plus', route: '/work-order-create'},
          {name: 'workOrderList', icon: 'fa fa-plus', route: '/distributor-work-order-dashboard'}
        ]
      }
    ]
  },
  {
    number: '15',
    name: 'sidenavMenu.client.WORKORDER',
    mobile: 'headerMobileMenuClientWORKORDER',
    icon: 'fa-solid fa-list-check', // Icon representing configuration settings
    routeUrl: 'client-work-order-dashboard', // Route URL for the configurations page
    validation: true,
    userType: environment.users.standardUser,
    userRole: environment.users.role.client,
    actions: [
      {
        title: 'graphs',
        options: [
          { name: 'pieChart', icon: 'icon-pie'},
          { name: 'lineChart', icon: 'icon-line'},
          { name: 'barChart', icon: 'icon-bar'}
        ],
      },
      {
        title: 'chartLabelBins',
        options: [
          {name: 'createWorkOrder', icon: 'fa fa-plus', route: '/work-order-create'},
          {name: 'workOrderList', icon: 'fa fa-plus', route: '/client-work-order-dashboard'}
        ]
      }
    ]
  },
];
