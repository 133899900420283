import { subscribeOn } from 'rxjs';
import {
  Component,
  OnInit,
  Renderer2,
  HostListener,
  Inject,
  forwardRef,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core'; // AfterViewInit, ViewChild, ElementRef
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { CognitoService } from '../service/cognito.service';
import { ModalService } from '../service/device-modal.service';
import { FilterService } from '../service/filter.service';
import { IotService, Devices } from '../service/iot.service';
import { ThemeService } from '../service/theme.service';
import { environment } from '../environments/environment';
import { ValidationService } from '../service/validation.service';
import { SystemMessageService } from '../service/system-message.service';
import { S3Service } from '../service/s3.service';
import { RoleService } from '../service/role.service';
import { ClientService } from '../service/client.service';
import { BinsService } from '../service/bins.service';
import { DistributorsService } from '../service/distributors.service';
import { DashboardService } from '../service/dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { LocalizationService } from '../service/localization.service';
import { ReportsService, ReportData } from '../service/reports.service';
import { error } from 'jquery';
import { CsvService } from '../service/csv.service';
import { PdfService } from '../service/pdf.service';
import { map, catchError } from 'rxjs';
// Interface for map marker properties
interface Marker {
  position: google.maps.LatLngLiteral;
  title: string;
}

interface MyObject {
  thingName: string;
  thingARN: string;
  thingTypeName: string;
  gps: any;
  location: string;
}

@Component({
  selector: 'app-iot',
  templateUrl: './iot.component.html',
  styleUrls: ['./iot.component.css', '../../global-elements.css', './iot.css'],
})
export class IotComponent implements OnInit {
  @ViewChild('usageCanvas') usageCanvas!: ElementRef;
  @ViewChild('marketCanvas') marketCanvas!: ElementRef;
  @ViewChild('countCanvas') countCanvas!: ElementRef;
  @ViewChild('tonnageCanvas') tonnageCanvas!: ElementRef;
  // Variable to store the language selected value
  public selectedLanguage: string = '';

  // Retrieve current language selected from local storage
  languageStatus: string = this.localStorageService.getItem('language');

  // Constructor for dependency injection
  constructor(
    @Inject(forwardRef(() => TranslateService))
    private translate: TranslateService,
    private cognitoService: CognitoService,
    private localStorageService: LocalStorageService,
    private renderer: Renderer2,
    public iotService: IotService,
    public modal: ModalService,
    public filter: FilterService,
    public theme: ThemeService,
    public validationService: ValidationService,
    public systemMessage: SystemMessageService,
    public s3: S3Service,
    private roleService: RoleService,
    private clientService: ClientService,
    private binService: BinsService,
    private distributorService: DistributorsService,
    public dashboardService: DashboardService,
    public route: ActivatedRoute,
    public location: Location,
    public localizationService: LocalizationService,
    public reports: ReportsService,
    public csv: CsvService,
    public pdf: PdfService
  ) {
    this.cognitoService.confirmValidUser();
    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null) {
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  // String that controls the section that is displayed to the user
  public mainContent: string = 'devices';
  public hideMap: boolean = true;

  public userFilter: string = ''; // User filter string - should be changed to device filter
  public newMap: string = ''; // Active map element ID
  public lastExpanded: string = ''; // Store last expanded device ID
  public currentId: string = ''; // Current device ID for modal
  public errorCalculatingFill: string = "";

  // Various flags and values for UI control
  public showModal = false;
  public showModalInfoDevicesMobile = false;
  public showModalDevice = false;
  public selectedDevice: Devices = {} as Devices;
  public show = false;
  public filterOn: boolean = false;
  public showList: boolean = false;
  public loadingMoreData = false;
  public stopMap: boolean = false;

  public isDevicesActive: boolean = false; // true when Device view is displayed
  public isModelsActive: boolean = false; // true when Models view is displayed
  public isAlertsActive: boolean = false; // true when Alerts view is displayed
  public isConnectorsActive: boolean = false; // true when Connectors view is displayed
  public isActionsActive: boolean = false; // true when Actions view is displayed

  // Variables to handle the positions of the map pin
  public position = { lat: 0, lng: 0 };
  public longitude = 0;
  public latitude = 0;
  public item = 0;

  // Data arrays and maps for devices, models, alerts, and maps
  public modelArray: any = [];
  public alertArray: any = [];
  public mapaGerado: any = [];
  public markers: Marker[] = [];
  public map: any;

  public thingStatusArray: any;

  // sortBy varaibles
  public sortBy: string = 'thingName';
  public sortDirection: string = '';

  // Variable used for the device association in modal service
  public userType: string = '';
  public userRole: any;

  get stateName() {
    return this.show ? 'show' : 'hide';
  }

  // Value to manage the pagination
  public itemsPerPage = 10;
  public loadedItems = 0;

  //Report Filters
  public startDate: any = this.reports.getDateThirtyDaysAgo();
  public endDate: any = this.reports.getCurrentDate();
  public statistic: string = 'Collections';
  public selectedThings: string[] = [];
  public selectedMarkets: any = 'All';

  //Filter Arrays
  public things: any = [];
  public zones: any = [];

  //Filter Booleans
  public allThings: boolean = true;
  public allMarkets: boolean = true;
  public generatingCSV: boolean = false;

  //Reports
  public collections: any[] = [];
  public history: any[] = [];
  public counts: any[] = [];

  async ngOnInit(): Promise<void> {
    this.errorCalculatingFill = await this.systemMessage.getMessageText('errorCalculatingFill');

    const checkCreateDeviceSucess = this.localStorageService.getItem('successCreateDevice');
    if (checkCreateDeviceSucess) {
      this.systemMessage.selectRibbon('success', 'alert-success-generic-message');
      this.localStorageService.removeItem('successCreateDevice');
    }

    const checkCreateDeviceFail = this.localStorageService.getItem('failCreateDevice');
    if (checkCreateDeviceFail) {
      this.systemMessage.selectRibbon('danger', 'alert-danger-generic-message');
      this.localStorageService.removeItem('failCreateDevice');
    }

    const tab = this.route.snapshot.params['tab'];

    // Initialize component with device view
    switch (tab) {
      case 'reports':
        this.reportsClicked();
        break;
      case 'alerts':
        this.alertsClicked();
        break;
      case 'devices':
        this.devicesClicked();
        break;
      case 'models':
        this.modelsClicked();
        break;
      default:
        this.devicesClicked();
        break;
    }

    (await this.reports.getReportFilterData()).subscribe((response) => {
      for (const row of response.things) {
        this.things.push({ id: row.thing_name, name: row.thing_name });
      }
      for (const row of response.zones) {
        this.zones.push({ id: row.zone_id, name: row.zone_name });
      }
    });

    //Verifies that the current User is allowed to view this component
    this.cognitoService.getCurrentRole(
      [environment.users.role.administrator],
      [environment.users.superAdmin]
    ); //Role Check

    // this.dashboardService.initArrays();

    (await this.dashboardService.getRelationships()).subscribe();

    await this.dashboardService.countApplicationBinModels();
    await this.dashboardService.countApplicationBins();
    await this.dashboardService.countApplicationClients();

    await this.dashboardService.countApplicationDevicesAndParameters();

    // data loading to device list - device view
    this.initData();
      console.log(this.iotService.devicesArray)
    document.readyState;

    // Initialize maps
    this.initMap(); // desktop version map
    this.initMapMobile(); // mobile version map

    // Function that subscribe in modal service and will trigger this when modal.service.showmodal function is called from device-modal.html will be called.
    this.modal.showMap$.subscribe(() => {
      // Get the gps and the device selected in the modal service and trow it to this.showMap function so it
      this.showMap(this.modal.selectedDevice.gps, this.modal.selectedDevice);
    });
    // Function that subscribe in modal service and will trigger this when modal.resetMap function is called from device-modal.html will be called.
    this.modal.resetMap$.subscribe(() => {
      this.resetMap();
    });

    this.iotService.shadowUpdate$.subscribe(() => {
      this.systemMessage.selectRibbon('success', 'alert-success-generic-message');
    });

    if(this.validationService.updateSuccessMessage) {
      this.systemMessage.selectRibbon('success',"deviceUpdated")
    }

  }

  onChangeAllThings(event: any) {
    this.allThings = (event.target as HTMLInputElement).checked;
    //console.log(this.allThings)
    return this.allThings;
  }

  onChangeAllMarkets(event: any) {
    this.allMarkets = (event.target as HTMLInputElement).checked;
    //console.log(this.selectedThings)
    return this.allMarkets;
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any) => {
      items.forEach((element: any) => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };

    allSelect(items);
  }

  onMaterialGroupChange(event: any) {
    console.log(event);
  }

  change() {
    alert(this.selectedThings);
  }

  verifyStatus(thingName: string): string {


    // Check if this.thingStatusArray is defined
    const array = this.thingStatusArray.status;
    const filteredDevices = array.filter((device:any) => device.thing_name === thingName);
    if (filteredDevices.length > 0) {
        return filteredDevices[0].status; // Assuming there's only one device with the given thingName
    }
    // Returns null or some default value if no match is found
    return 'Inactive';
  }

  ngAfterViewInit(): void {
    this.pdf.usageCanvas = this.usageCanvas;
    this.pdf.marketCanvas = this.marketCanvas;
    this.pdf.countCanvas = this.countCanvas;
    this.pdf.tonnageCanvas = this.tonnageCanvas;
    // Call the function to detect if the user has reached the end of the page on a mobile device
    this.detectEndOfPageOnMobile();
  }

  // Load data to Device List
  async initData() {
    // clean the device array of previous data - avoiding duplication of information
    this.iotService.resetDeviceArray();

    // activate getDeviceList function and devicesArray @ iot service
    await this.iotService.getDeviceList();

    await this.dashboardService.countApplicationDevicesAndParameters().then((res) => {
      this.thingStatusArray = res});

  }

  // Function to load status from HTML for a given thingName
  loadStatusFromHtml(thingName: string): string {
    // Call the function in iotService to check health status for the specified thingName
    // and return the result
    return this.iotService.checkHealthStatusUsingThingName(thingName);
  }

  // function used to load more data into the list
  loadMoreItems() {
    if (
      this.loadedItems + this.itemsPerPage <
      this.iotService.devicesArray.length
    ) {
      this.loadedItems += this.itemsPerPage;
    }
  }


  // Initialize maps
  resetMap() {
    this.initMap();
    this.initMapMobile();
    this.position = { lat: 0, lng: 0 };
    this.showMap({ latitude: 0, longitude: 0 }, []);
  }

  refreshList() {
    window.location.reload();
  }

  // Change language based on user selection
  changeLanguage(language: string) {
    this.translate.use(language);
  }

  // Clear active state for UI buttons
  clearButtonsStats() {
    this.isDevicesActive = false;
    this.isModelsActive = false;
    this.isAlertsActive = false;
    this.isConnectorsActive = false;
    this.isActionsActive = false;
  }

  // Initialize Google Maps
  initMap(
    gps: { latitude: number; longitude: number } = { latitude: 0, longitude: 0 }
  ): void {
    let map: google.maps.Map;
    this.markers.forEach((markerObj) => {
      const marker = new google.maps.Marker({
        position: markerObj.position,
        title: markerObj.title,
        map: map,
      });
      marker.setMap(map);
    });
    const MM = this.markers;

    async function initMap(
      gps: { latitude: number; longitude: number } = {
        latitude: 0,
        longitude: 0,
      }
    ): Promise<void> {
      // Initialisation of the position of the centered map
      let position: any = '';
      if (
        gps.latitude !== 0 ||
        (gps.latitude !== undefined && gps.longitude !== 0)
      ) {
        position = { lat: gps.latitude, lng: gps.longitude };
      } else {
        position = { lat: 45.65054871734081, lng: -73.84789617258333 };
      }

      // instanciate the position that th emap will be centered on
      //const position = { lat: 45.65054871734081, lng: -73.84789617258333 };
      const position2 = { lat: 51.5, lng: 0.1167 };

      // Import the necessary libraries for the Google Maps API
      const { Map } = (await google.maps.importLibrary(
        'maps'
      )) as google.maps.MapsLibrary;
      const { AdvancedMarkerElement } = (await google.maps.importLibrary(
        'marker'
      )) as google.maps.MarkerLibrary;
      // Create a new Google Map instance
      map = new google.maps.Map(
        document.getElementById('mapDesktop') as HTMLElement,
        {
          zoom: 10, // 1: World - 5: Landmass/continent - 10: City - 15: Streets - 20: Buildings
          center: position,
          mapId: 'DEVICES_MAP',
          disableDefaultUI: true,
        }
      );

      // Create markers on the map using the marker objects from MM arra
      MM.forEach((markerObj) => {
        const marker = new google.maps.Marker({
          position: markerObj.position,
          title: markerObj.title,
          map: map,
        });
        marker.setMap(map);
      });
    }
    // Call the function to initialize the Google Map
    initMap(gps);
  }

  // Initialize goolge map for mobile
  initMapMobile(
    gps: { latitude: number; longitude: number } = { latitude: 0, longitude: 0 }
  ): void {
    let map: google.maps.Map;

    // Loop through each marker object and create markers on the map
    this.markers.forEach((markerObj) => {
      const marker = new google.maps.Marker({
        position: markerObj.position,
        title: markerObj.title,
        map: map,
      });
      marker.setMap(map);
    });
    const MM = this.markers;

    async function initMap(
      gps: { latitude: number; longitude: number } = {
        latitude: 0,
        longitude: 0,
      }
    ): Promise<void> {
      // Initialisation of the position of the centered map
      let position: any = '';
      if (
        gps.latitude !== 0 ||
        (gps.latitude !== undefined && gps.longitude !== 0)
      ) {
        position = { lat: gps.latitude, lng: gps.longitude };
      } else {
        position = { lat: 45.65054871734081, lng: -73.84789617258333 }; // Position could be change on the user position or something like neer him in the futur
      }

      // Define initial positions for the map
      //const position = { lat: 45.65054871734081, lng: -73.84789617258333 };
      const position2 = { lat: 51.5, lng: 0.1167 };

      // Import the necessary libraries for the Google Maps API
      const { Map } = (await google.maps.importLibrary(
        'maps'
      )) as google.maps.MapsLibrary;

      const { AdvancedMarkerElement } = (await google.maps.importLibrary(
        'marker'
      )) as google.maps.MarkerLibrary;

      // Create a new Google Map instance
      // map = new google.maps.Map(document.getElementById("mapMobile") as HTMLElement, {
      //   zoom: 10, // 1: World - 5: Landmass/continent - 10: City - 15: Streets - 20: Buildings
      //   center: position,
      //   mapId: 'DEVICES_MAP', // Specify the map style
      //   disableDefaultUI: true, // Disable default map controls
      // });
      // Create markers on the map using the marker objects from MM array
      MM.forEach((markerObj) => {
        const marker = new google.maps.Marker({
          position: markerObj.position,
          title: markerObj.title,
          map: map,
        });
        marker.setMap(map);
      });
    }
    // Call the initMap function to initialize the Google Map
    initMap(gps);
  }

  // Show specific map with device's location
  showMap(gps: { latitude: number; longitude: number }, deviceRow: any): void {
   // this.hideMap = false;
    // Clear any existing markers
    this.markers = [];
    // Initialize the main map
    this.initMap(gps);

    // Initialize the secondary map
    this.initMapMobile(gps);

    // Store the ID of the last expanded device
    this.lastExpanded = deviceRow.thingName;

    // Push the DeviceID to the generated map list
    this.mapaGerado.push(deviceRow.thingName);

    // Store the current device information
    this.item = deviceRow;

    // Set the stopMap flag to false
    this.stopMap = false;

    // Check if the map should be stopped
    if (!this.stopMap) {
      // Get the coords directly by gps passing
      const latNumber = Number(gps.latitude);
      const lngNumber = Number(gps.longitude);

      // Set latitude and longitude for the map
      this.latitude = latNumber;
      this.longitude = lngNumber;

      // Define a new marker based on the GPS coordinates
      const newMarker: Marker = {
        position: { lat: latNumber, lng: lngNumber },
        title: 'New Marker',
      };

      // Add the new marker to the markers array
      this.markers.push(newMarker);
    } else {
      // If map should be stopped, set GPS coordinates but don't add a marker
      const latNumber = Number(gps.latitude);
      const lngNumber = Number(gps.longitude);

      // Set latitude and longitude for the map
      this.latitude = latNumber;
      this.longitude = lngNumber;
    }
  }

  // Handle navigation to device view
  devicesClicked() {
    // Clear any buttons' stats
    this.clearButtonsStats();

    // Set the isDevicesActive flag to true, indicating the devices view is active
    this.isDevicesActive = true;

    // Update the main content to show the "devices" view
    this.mainContent = 'devices';
    this.location.go('/iot/devices');

    // Clear any existing markers
    this.markers = [];

    // Initialize the main map
    this.initMap();
  }

  // Handle navigation to model view
  modelsClicked() {
    // Clear any buttons' stats
    this.clearButtonsStats();

    // Set the isModelsActive flag to true, indicating the models view is active
    this.isModelsActive = true;

    // Update the main content to show the "models" view
    this.mainContent = 'models';
    this.location.go('/iot/models');

    // Set the isDevicesActive flag to false, indicating the devices view is not active
    this.isDevicesActive = false;
  }

  // Handle navigation to alert view
  alertsClicked() {
    // Clear any buttons' stats
    this.clearButtonsStats();

    // Set the isAlertsActive flag to true, indicating the alerts view is active
    this.isAlertsActive = true;

    // Update the main content to show the "alerts" view
    this.mainContent = 'alerts';
    this.location.go('/iot/alerts');

    // Set the isDevicesActive flag to false, indicating the devices view is not active
    this.isDevicesActive = false;
  }

  // Handle navigation to REPORTS view
  reportsClicked() {
    // Clear any buttons' stats
    this.clearButtonsStats();

    // Set the isConnectorsActive flag to true, indicating the connectors view is active
    this.isConnectorsActive = true;

    // Update the main content to show the "connectors" view
    this.mainContent = 'reports';
    this.location.go('/iot/reports');

    // Set the isDevicesActive flag to false, indicating the devices view is not active
    this.isDevicesActive = false;
  }

  // Handle navigation to action view
  actionsClicked() {
    // Clear any buttons' stats
    this.clearButtonsStats();

    // Set the isActionsActive flag to true, indicating the actions view is active
    this.isActionsActive = true;

    // Update the main content to show the "actions" view
    this.mainContent = 'actions';

    // Set the isDevicesActive flag to false, indicating the devices view is not active
    this.isDevicesActive = false;
  }

  sortItems(itemSortBy: string) {
    // Choose the sort direction if user click more than once on the same column
    if (this.sortBy === itemSortBy) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // Else sort direction will be at ascendant
      this.sortDirection = 'asc';
    }

    // Reasign the value of sortyBy so next click system will know if it's the first click on the column
    this.sortBy = itemSortBy;

    // This will sort between each of the selected column by the user
    this.iotService.devicesArray.sort((a, b) => {
      switch (itemSortBy) {
        case 'thingName':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'asc') {
            return b.thingName.localeCompare(a.thingName);
          } else {
            return a.thingName.localeCompare(b.thingName);
          }

        case 'modal':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'asc') {
            return (
              b.thingName.split('-')[0] +
              '-' +
              b.thingName.split('-')[1]
            ).localeCompare(
              a.thingName.split('-')[0] + '-' + a.thingName.split('-')[1]
            );
          } else {
            return (
              a.thingName.split('-')[0] +
              '-' +
              a.thingName.split('-')[1]
            ).localeCompare(
              b.thingName.split('-')[0] + '-' + b.thingName.split('-')[1]
            );
          }

        case 'date':
          // This will sort asc or desc depend on the sort direction initialize up here
          if (this.sortDirection === 'asc') {
            return b.lastUpdate - a.lastUpdate;
          } else {
            return a.lastUpdate - b.lastUpdate;
          }

        default:
          // Default will sort on thingName asc
          return a.thingName.localeCompare(b.thingName);
      }
    });
  }

  sortOnLoad() {
    this.iotService.devicesArray.sort((a, b) => {
      return b.lastUpdate - a.lastUpdate;
    });
  }

  // Handle scroll event
  @HostListener('window:scroll', []) onScroll(): void {
    // Get the height of the visible window area
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;

    // Get references to the document's body and html elements
    const body = document.body;
    const html = document.documentElement;

    // Calculate the maximum document height using various metrics
    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    // Get the current scroll position
    const scrollPosition =
      window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    // Check if the user has scrolled near the bottom of the page
    if (
      documentHeight - windowHeight - scrollPosition < 100 &&
      !this.loadingMoreData &&
      !this.filterOn
    ) {
      this.loadMoreItems();
    }
  }

  // Handle End Of Page On Mobile event
  detectEndOfPageOnMobile(): void {
    // Check if the user is using a mobile device
    if (this.isMobileDevice()) {
      // Listen for the 'touchmove' event to track scrolling on the document
      this.renderer.listen('document', 'touchmove', (event: TouchEvent) => {
        // Get the current scroll position, window height, and document height
        const scrollPosition =
          window.scrollY ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0;
        const windowHeight =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight ||
          0;
        const documentHeight = Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.documentElement.clientHeight
        );
        // Check if the user has scrolled to the end of the page and not currently loading more data
        if (
          scrollPosition + windowHeight >= documentHeight &&
          !this.loadingMoreData
        ) {
          this.loadMoreItems();
        }
      });
    }
  }

  // checks if the application is being accessed from a mobile device
  isMobileDevice(): boolean {
    // Get the user agent string and convert it to lowercase
    const userAgent = navigator.userAgent.toLowerCase();

    // Check if the user agent matches any common mobile device keywords
    return /mobile|android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    );
  }

  async getCertificateFolderUrl(thingName: string) {
    await this.s3
      .getCertificatesForThing(thingName)
      .then((res) => {
        if (res?.length) {
          let cert = res[0];

          // Create a link element
          const a = document.createElement('a');

          // Set the link's href to the Blob URL
          a.href = cert;

          // Set the link's download attribute to specify the filename
          a.download = `${thingName}-certificates.zip`;

          // Append the link to the body
          document.body.appendChild(a);

          // Trigger a click on the link to start the download
          a.click();

          // Remove the link from the body
          document.body.removeChild(a);
        } else {
          this.systemMessage.selectRibbon(
            'danger',
            'noCertificatesSystemMessage'
          );
        }
      })
      .catch((error) => {
        this.systemMessage.selectRibbon(
          'danger',
          'noCertificatesSystemMessage'
        );
        console.error('Error', error);
      });
  }

  // Function called from the click of the relation logo in the iot list
  async showRelation(thingName: string) {
    // Call the function that get all infos for device association
    (await this.iotService.getDeviceRelationship(thingName)).subscribe(() => {
      this.modal.deviceAssociationRow = this.iotService.deviceAssociationRow;
    });

    // Get user role
    this.modal.userRole = await this.roleService.getRoles();
    await this.cognitoService.getUserType();

    // Set the distributor array
    let array = await this.distributorService.getDistributorForSelect();
    this.modal.distributorsArray = array;

    // Set the client array
    array = await this.clientService.getClientsForSelect();
    this.modal.clientsArray = array;

    this.binService.resetBinDetailArray();
    this.modal.binArray = await this.binService.setBinDetailArray();
  }

  dateToUnixTimestamp(dateString: string): number {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Get the UTC timestamp in milliseconds
    const timestampInMilliseconds = date.getTime();

    // Convert milliseconds to seconds and return
    return Math.floor(timestampInMilliseconds / 1000);
  }

  calculateFillPercentage(height:number = 0, dst: number = 0){
    if(height > 0 && dst >= 0){
      let percentFilled =  (height - dst)/height * 100;
      if(percentFilled < 0 && dst > height){
       return this.errorCalculatingFill;
      }
      return percentFilled+"%";

    }
    else{
      return "N/A";
    }
  }

  async compileReport() {
    this.pdf.generatingPDF = true;
    try {
      let things = [];
      if (!this.allThings) {
        things = this.selectedThings;
      } else {
        for (const row of this.things) {
          things.push(row.id);
        }
      }

      this.pdf.things = things;
      console.log(this.pdf.things)

      this.pdf.start = this.startDate;
      console.log(this.pdf.start)

      this.pdf.end = this.endDate;
      console.log(this.pdf.end)


      await this.pdf.savePDF();
    } catch (error) {
      this.pdf.generatingPDF = false;
    }
  }

  async exportCSVReports() {
    this.generatingCSV = true;

    this.history = [];
    this.counts = [];
    this.collections = [];
    const start = this.dateToUnixTimestamp(this.startDate+ "T00:00:00");
    const end = this.dateToUnixTimestamp(this.endDate+ "T00:00:00");

    let things = [];
    if (!this.allThings) {
        things = this.selectedThings;
    } else {
      for (const row of this.things) {
        things.push(row.id);
      }
    }

    try {
      (await this.reports.getReportData(things, start, end))
        .pipe(
          map((response) => {
            return response
          }),
          catchError((error) => {
            console.error('API Error:', error);
            this.generatingCSV = false;
            this.systemMessage.selectRibbon(
              'danger',
              'alert-danger-generic-message'
            );
            throw error; // Re-throw the error for the calling code to handle
          })
        )
        .subscribe((res: any) => {
           console.log(res);

          for (const collection of JSON.parse(res?.collections)) {
            this.collections.push(collection);
          }

          for (const counts of JSON.parse(res?.counts)) {
            this.counts.push(counts);
          }

          for (const history of JSON.parse(res?.history)) {
            this.history.push(history);
          }

          const deviceReport: any = [];
          const historyArray = this.history;

          for (const row of historyArray) {
            // const date = new Date(row.dst_timestamp*1000);
            if (row.timestamp.toString().length <= 10) {
              row.timestamp = new Date(row.timestamp * 1000);
            } else {
              row.timestamp = new Date(parseInt(row.timestamp));
            }

            row.img = 'N/A';
            row.fdv_timestamp = new Date(row.fdv_timestamp * 1000);
            row.img_timestamp = new Date(row.img_timestamp * 1000);
            row.tmp_timestamp = new Date(row.tmp_timestamp * 1000);
            row.vcc_timestamp = new Date(row.vcc_timestamp * 1000);
            row.sig_timestamp = new Date(row.sig_timestamp * 1000);
            row.pre_timestamp = new Date(row.pre_timestamp * 1000);
            row.bat_timestamp = new Date(row.bat_timestamp * 1000);
            row.tm1_timestamp = new Date(row.tm1_timestamp * 1000);
            row.tm2_timestamp = new Date(row.tm2_timestamp * 1000);
            row.dst_timestamp = new Date(row.dst_timestamp * 1000);
            row.tm0_timestamp = new Date(row.tm0_timestamp * 1000);
            row.nct_timestamp = new Date(row.nct_timestamp * 1000);
            row.lfr_timestamp = new Date(row.lfr_timestamp * 1000);
            row.hum_timestamp = new Date(row.hum_timestamp * 1000);
            row.voc_timestamp = new Date(row.voc_timestamp * 1000);
            row.hib_timestamp = new Date(row.hib_timestamp * 1000);

            deviceReport.push(row);
          }

          this.csv.exportToCsv(
            deviceReport,
            `History-${this.startDate.replaceAll(
              '-',
              ''
            )}-${this.endDate.replaceAll('-', '')}.csv`
          );
          this.generatingCSV = false;
        });
    } catch (error) {
      this.systemMessage.selectRibbon(
        'danger',
        'alert-danger-generic-message'
      );
      console.error("CSV Error: ", error)
      this.generatingCSV = false;
    }

  }
}
