<!-- The aside element represents a section of the page that is tangentially related to the content around it -->
<aside>
    <!-- The navigation section with dynamic classes based on the theme -->
    <nav [ngClass]="theme.getThemeClass()" class="side-nav-content bg-light">
        <!-- The unordered list containing navigation items with a dynamic class for open/closed state -->
        <ul class="nav-list" [ngClass]="{'nav-list-open': sideNavStatus}">
            <!-- Using ngFor to iterate over each item in the navigation list -->
            <li [ngClass]="theme.getThemeClass()" class="nav-list-item" *ngFor="let item of nav.list">
                <!-- Using ng-container to group multiple elements without introducing an extra HTML element -->
                <ng-container>
                    <!-- Displaying an icon with a link to the specified route -->
                    <span>
                        <a [ngClass]="theme.getThemeClass()" href="{{item.routeUrl}}">
                            <img class="imgSize" class="{{ item.icon }}">
                        </a>
                    </span>
                    <!-- Displaying the name of the navigation item with a link to the specified route, and translating it -->
                    <span class="labels">
                        <a [ngClass]="theme.getThemeClass()" href="{{item.routeUrl}}">
                            {{item.name | translate}}
                        </a>
                    </span>
                </ng-container>
            </li>
        </ul>
    </nav>
</aside>

