<app-system-message></app-system-message>
<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">
    <section [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'operatorUpdateViewUpdateOperator' | translate }}</h4>
            <form>
                <!-- Input for operator name -->
                <div class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-user"></i></span>
                    <input [(ngModel)]="this.operator.operator_data.operator_name" type="text"
                    class="form-control" name="operator_name"
                    placeholder="{{ 'operatorCreateViewOperatorName' | translate }}" aria-label="operator_name"
                    aria-describedby="basic-addon1"
                    (blur)="this.validationService.validateClientName(this.operator.operator_data.operator_name)"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientNameInvalid'">
                </div>
                <!-- Input for operator legal name -->
                <div class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-user"></i></span>
                    <input [(ngModel)]="this.operator.operator_data.legal_name" type="text"
                    class="form-control" name="legal_name"
                    placeholder="{{ 'clientcreateFormInputLegalName' | translate }}" aria-label="legal_name"
                    aria-describedby="basic-addon1"
                    (blur)="this.validationService.validateClientLegalName(this.operator.operator_data.legal_name)"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientNameInvalid'">
                </div>
                <!-- Input for operator phone number -->
                <div class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-phone"></i></span>
                    <input [(ngModel)]="this.operator.operator_data.phone_number" type="text"
                    class="form-control" name="phone_number"
                    placeholder="{{ 'userlistClientsViewTableHeaderPhoneNumber' | translate }}" aria-label="phone_number"
                    aria-describedby="basic-addon1"
                    (blur)="this.validationService.validatePhoneNumber(this.operator.operator_data.phone_number)"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientPhoneInvalid'">
                </div>
                <!-- Input field for address with Autocomplete -->
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-map-marker"></i></span>
                    <input id="autocomplete" [(ngModel)]="this.operator.operator_data.address" (keydown)="onInputAddressKeydown($event);"
                    type="text" class="form-control" name="address"
                    placeholder="{{ 'clientcreateFormInputFindAddress' | translate }}"aria-label="Enter a place" aria-describedby="basic-addon1"
                    (blur)="this.validationService.validateAddress(this.operator.operator_data.address)"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientAddressInvalid'">
                </div>
                <!-- Input for operator email -->
                <div class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope"></i></span>
                    <input [(ngModel)]="this.operator.operator_data.email" type="text"
                    class="form-control" name="email"
                    placeholder="{{ 'loginForgotPasswordPlaceHolderEmailAddress' | translate }}" aria-label="email"
                    aria-describedby="basic-addon1"
                    (blur)="this.validationService.validateClientEmail(this.operator.operator_data.email)"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientEmailInvalid'">
                </div>
                <!-- section for the checkbox toggle of the status active/inactive -->
                <div class="card-title mt-0 status">
                    <label class="switch">
                        <input type="checkbox" id="checkboxStatus" [name]="'active-operator-' + operator.operator_data.operator_id" [ngModel]="this.active" (change)="this.alert.showAlertDelete= true; this.alert.deactiveOperator(operator.operator_data.operator_id, operator.operator_data.legal_name, operator.operator_data.active)" [disabled]="this.alert.showAlertDelete">
                        <span [ngClass]="{'active-checkbox': this.active === 1, 'inactive-checkbox': this.active === 0}" class="slider round"></span>
                    </label>
                    <label class="toggle">{{ "iotTabHeaderStatus" | translate }}</label>
                </div>
                <!-- Submit button -->
                <nav class="form-group buttonDiv">
                    <button (click)="returnToEntities()" [ngClass]="theme.getThemeClass()" type="submit" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button [ngClass]="theme.getThemeClass()" (click)="onSubmit()" type="submit" class="btn btn-block btnCreate">{{ 'update' | translate }}</button>
                </nav>
            </form>
        </article>
        <app-alert-modal *ngIf="this.alert.showAlertDelete" class="alertDelete" [ngClass]="theme.getThemeClass()"></app-alert-modal>
    </section>
</div>


