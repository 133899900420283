<div [ngClass]="theme.getThemeClass()" class="container-fluid" > 
    <div class="page-header">
      <!-- For future development -->
      <!-- <button class="btn back" (click)="backToBinModelList()"><i class="fa fa-arrow-left"></i></button> -->
      <h2>{{"userList" | translate}}</h2>
    </div>
    <nav
      id="search-component"
      class="nav-sort-filter"
    >
      <input
        #searchBox
        type="search"
        id="search-box"
        [(ngModel)]="userFilter"
        (input)="
          this.search()
        "
        class="form-control rounded searchInput"
        placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}"
        aria-label="Search"
        aria-describedby="search-addon"
      />
      <ng-select class="form-control ng-select sort-by-select" [(ngModel)]="this.filterBy"
        placeholder="{{ 'filterBy' | translate }}" [ngClass]="{'has-value': this.filterBy }">
        <ng-option value="" disabled selected>{{ "filterBy" | translate }}</ng-option>
            <ng-option value="address">{{ "usereditFormLabelAddress" | translate }}</ng-option>
            <!-- <ng-option value="thing_name">{{ "iotModalViewDeviceName" | translate }}</ng-option> -->
            <ng-option value="legal_name">{{ "clientcreateFormInputClientName" | translate }}</ng-option>
      </ng-select>
      <ng-select class="form-control ng-select sort-by-select" [(ngModel)]="this.sortBy"
        (change)="onSortChange('select')" placeholder="{{ 'sortBy' | translate }}" [ngClass]="{'has-value': this.sortBy}">
        <ng-option value="" disabled selected>{{ "sortBy" | translate }}</ng-option>
            <ng-option value="address">{{ "usereditFormLabelAddress" | translate }}</ng-option>
            <!-- <ng-option value="thing_name">{{ "iotModalViewDeviceName" | translate }}</ng-option> -->
            <ng-option value="legal_name">{{ "clientcreateFormInputClientName" | translate }}</ng-option>
      </ng-select>
      <div class="card-title mt-0 status">
        <label class="switch">
            <input type="checkbox" [ngModel]="this.ascDesc" (change)="onSortChange('switch')">
            <span class="slider round">
              <span class="toggle-switch-label-off">{{ "asc" | translate }}</span>
              <span class="toggle-switch-label-on">{{ "desc" | translate }}</span>
            </span>
        </label>
    </div>
    </nav>
  <div class="grid-container" [ngClass]="theme.getThemeClass()">
    <section [ngClass]="theme.getThemeClass()" class="gridster" [ngStyle]="getColumnStyle()">
      <ng-container *ngFor="let item of clientUserArray">
      <article class="gridster-item" [ngClass]="theme.getThemeClass()">
        <!-- Your tile content goes here -->
          <nav [ngClass]="theme.getThemeClass()" class="dashboard-tile">
            <header class="dashboard-tile-header">
              <a [ngClass]="{ 'disabled-link': this.currentClientRole !== '_admin' }" (click)="goToUserUpdate(item.user, item.username)"><h6 class="dashboard-tile-title">{{ item.given_name }} {{ item.family_name }}</h6></a>
            </header>
            <div class="tiles-container">
              <div class="titles-items">
                <i class="fa fa-user"></i><label>{{ item.username}}</label>
              </div>
              <div class="titles-items">
                <i class="fa-solid fa-location-dot pointer"></i> <label >{{ item.address }}</label>
              </div>
              <div class="titles-items">
                <i class="fa-solid fa-phone"></i><label>{{ item.phone_number }}</label>
              </div>
              <div class="titles-items">
                <i class="fa-solid fa-at"></i><label>{{ item.email }}</label>
              </div>
            </div>
          </nav>
        </article>
    </ng-container>
  </section>
  </div>
  </div>
  
  