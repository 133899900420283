import { Component, OnInit, ViewChild } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { BinsService } from '../service/bins.service';
import { ValidationService } from '../service/validation.service';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { LocalizationService } from '../service/localization.service';
import { CognitoService } from '../service/cognito.service';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';
import { environment } from '../environments/environment';
import { RoleService } from '../service/role.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { IotService } from '../service/iot.service';
import { MarketSegment } from '../constants/market-segments';

@Component({
  selector: 'app-bin-create',
  templateUrl: './bin-create.component.html',
  styleUrls: ['./bin-create.component.css', '../../global-elements.css']
})
export class BinCreateComponent implements OnInit {
  @ViewChild('binSelect') binSelect!: NgSelectComponent;
  // Get current theme from localstorage
  themeStatus: string = this.localStorageService.getItem('theme');

  // Variable used for validate function  in addition of validation service;
  validateCreate: boolean = false;
  validateClientId: boolean = false;

  // Own variable that get the selected usage from bin-usage component
  public binUsageSelected: string = '';

  // Variables used by html to show/hide codes
  public showErrorMessageEmptyInput: boolean = false;
  private systemMessage: string = '';

  // Variables used for the client assiciate to the bin
  public associatedClientId: string = '';

  // Variable used to set number of bin we want to create at once
  public numberToCreate: string = '';
  public thing_name: string = '';
  public thingArray: any;

  public MarketSegment = MarketSegment;

  constructor(private localStorageService: LocalStorageService,
              public bin: BinsService,
              public validationService: ValidationService,
              private route: Router,
              private localization: LocalizationService,
              public cognitoService: CognitoService,
              public theme: ThemeService,
              private systemMessageService: SystemMessageService,
              private roleService: RoleService,
              private translate: TranslateService,
              private iotService: IotService){

  }

  async ngOnInit(): Promise<void> {

    // preparing bin names list - this.bin.binsNameList - to be used on duplicated bin name validation
    await this.bin.generateBinNamesList();

    await this.cognitoService.confirmValidUser();
    await this.roleService.getRoles();

    this.cognitoService.getCurrentRole([environment.users.role.administrator], [environment.users.superAdmin, environment.users.standardUser], true, this.roleService.roles);
    // Get the user type
    await this.cognitoService.getUserType();
    // Call a function that reset the bin_data to nothing so duplication of the list is avoid
    this.bin.initBinData();
    // Set back the bin model list
    await this.bin.getBinsModel();

    this.bin.isDeviceArray = false;

    // Set cognito service user type
    await this.cognitoService.getUserType();
    // Check witch user type is the current user and get only infos he can associate bins
    switch(this.cognitoService.userType){
      // Remove the break because each one of hte two user type get the client list
      case 'muirwood':
      case 'distributor':
        // Distributor user will be abble to associate a bin to his own distributor ID or clients
        await this.cognitoService.getClientsInfos();
        break;
    }

    // Set the caller variable of localization service at bin-create so it will be used by this service for some use
    this.localization.caller = 'bin-create';
    this.localization.initAutocomplete(); // Initiate the autocomplete of the address input whit google.map

    // Function that will be automatically trigger and used whit bin-service when bin-usage component change the value of it's select
    this.bin.selectedTypeOfWaste$.subscribe(() => {
      this.binUsageSelected = this.bin.typeOfWaste; // Set the value of is own binusage variable to the one of bin service
    });
  }

  async onSubmit(){
    // Create a variable whit the current date
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'yyyyMMdd', 'en_US');

    // Set created and modified date to current date, modified is required event if it's not so the query won't break
    this.bin.bin_data.created = parseInt(formattedDate, 10);
    this.bin.bin_data.modified = parseInt(formattedDate, 10);

    // Set bin_location to the variable in localization service that google return
    this.bin.bin_data.bin_location = this.localization.autoCompletionReturnGpsLocation;
    this.bin.bin_data.bin_address = this.localization.autoCompletionReturnAddress;
    this.bin.bin_data.bin_postal_code = this.localization.autoCompletionReturnPostalCode;

    // This function is in addition of validation service to be sure that input are well filled and will set validateCreate at true if so
    this.checkValidation();

    if(this.validateCreate){
      this.createBin();
    } else {
      // If system message have nothing it put a generic message
      if(this.systemMessage === ''){
        this.systemMessage = 'alert-danger-generic-message';
      }
      // Set the system message service to display the error to the user
      this.systemMessageService.selectRibbon('danger', this.systemMessage);
    }
  }

  // Function called when all validation are done and we can create bins
  async createBin(){
    // const originalName = this.bin.bin_data.bin_name;

    if(parseInt(this.numberToCreate) > 1){
      const givenBinName = this.bin.bin_data.bin_name;
      for(let i = 0; i < parseInt(this.numberToCreate); i ++){

        // create bin name for multiples devices  
        let binName = `${givenBinName}-${i + 1}`;
        this.bin.bin_data.bin_name = binName;

        // If there's device related to the bin model, set the thing_name in iotService then call the fonction to update the device association
        if(this.bin.isDeviceArray){
          this.bin.thing_name = this.bin.device_array[i].thing_name;
          this.bin.distributor_id = this.bin.selectedBinModel.distributor_id;
          
        }
        // Call the function that will create the bin
        await this.bin.createBin();
        this.bin.bin_data.bin_name = givenBinName;
      }
    }else{

      if(this.thing_name !== ''){
        // If there's device related to the bin model, set the thing_name in iotService then call the fonction to update the device association
        this.bin.thing_name = this.thing_name;
        this.bin.distributor_id = this.bin.selectedBinModel.distributor_id;
      }
      // Call the funciton to create the bin
      await this.bin.createBin();
    }
    // Function that put session variable then return to the admin page
    this.returnToAdmin();
  }

  // Function in addition of validation service that put the variable validateCreate at rue if all variables are properly implemented
  checkValidation(){

    let notDuplicatedBinName = false;

    // Check if the trash bin name is not duplicated
    if(!this.isTrashBinNameDuplicated(this.bin.bin_data.bin_name)) {
      notDuplicatedBinName = true;

      // Check if all required variables are properly set and validation errors are empty
      if(notDuplicatedBinName && this.bin.bin_data.bin_model_id !== '' && this.bin.bin_data.bin_usage !== '' && this.bin.bin_data.client_id !== '' && parseInt(this.numberToCreate) > 0 &&
        (this.validationService.validationErrorArray[0] === '' || this.validationService.validationErrorArray[0] === undefined)){
          // Perform additional validations for client ID
          this.ClientIdValidation();
          // Check if additional device-related checks are needed
          this.checkIfNeedDevice();
      } else {
        // Set system message if any of the validation checks fail
        this.systemMessage = 'binCreateViewAlertEmptyInput';
      }

    } else {
      // Set system message if trash bin name is duplicated
      notDuplicatedBinName = false;
      this.systemMessage = 'binCreateViewAlertDuplicatedBinName';
    }

  }

  // Checks if the provided bin name already exists in the list of bin names.
  isTrashBinNameDuplicated(binName: string) {

    // Check if the list of bin names is not an array
    if (!Array.isArray(this.bin.binsNameList)) {
      console.error('The list of bin names is not an array.');
      return false;
    }
  
    // Checks if the bin_name already exists in the list
    return this.bin.binsNameList.includes(binName);

  }

  // Function called to validate if the client_id was properly set
  ClientIdValidation(){
    switch(true){
      // Will check if the user type is muirwood or distributor, the client id need to be assign. if not there will be a message displayed
      case ((this.cognitoService.userType === 'muirwood' || this.cognitoService.userType === 'distributor') && this.bin.bin_data.client_id !== ''):
        // Set validation variable to true;
        this.validateCreate = true;
        break;

      // Will set the bin_data client_id variable to the client id if the user type is client
      case this.cognitoService.userType === 'client':
        // Set the bin_data client_id variable to the user client id
        this.bin.bin_data.client_id = this.cognitoService.clientId;
        // Set the validation variable to true
        this.validateCreate= true;
        break;

      // Goes into default if there is no client selected and user type is not client
      default:
        this.systemMessage = 'enterClient';
    }
  }

  // Function called to check if the been model required a device and if so, if the device is selected
  checkIfNeedDevice(){
    const binModel = this.bin.bin_model_array.find(binModel => binModel.bin_model_id === this.bin.bin_data.bin_model_id);
    if(binModel?.with_thing && this.thing_name === ''){
      this.validateCreate = false;
      this.systemMessage = 'deviceNeeded';
    }
  }

  // Function called when user select a bin model number to associate to th bin
  async binModelChange(){
    this.bin.binModelOrClientChange();
  }

  // Function called when user change the threshold input and won't let him to put threshold under 40% or over 90%
  thresholdChange(){
    if(this.bin.bin_data.threshold < 40 || this.bin.bin_data.threshold > 90){
      this.systemMessageService.selectRibbon('danger', 'thresholdBreakPoint');
      this.bin.bin_data.threshold = 80;
    }
  }

  // Function called when user change the number
  async changeNumberToCreate(){
    if(this.bin.bin_data.bin_model_id !== ''){
      const bin_model = this.bin.bin_model_array.find(binModel => binModel.bin_model_id === this.bin.bin_data.bin_model_id);
      // Check if number user want to create is bigger of the available device number of this distributor
      if((parseInt(this.numberToCreate) > this.bin.numberOfThingsAvailable) && (bin_model?.with_thing === 1)){
        this.systemMessageService.selectRibbon('danger', 'distributorHaveNoMoreDevice');
        // Set the input to the maximum number of device available
        this.numberToCreate = this.bin.numberOfThingsAvailable.toString();

        // If it's a muirwood user, it will ask if he want to go to iot component to get new device for the distributor
        if(this.cognitoService.userType === 'muirwood'){
          const result = window.confirm(await firstValueFrom(this.translate.get('distributorHaveNoMoreDevice')) + '. ' + await firstValueFrom(this.translate.get('beRedirectToIot')));
          if(result === true){
            this.route.navigate(['/iot']); // Return to iot component
          }
        }
      }
    }else{
      this.systemMessageService.selectRibbon('danger', 'selectBinModelFirst');
      this.numberToCreate = '0';
    }
  }

  // Function called when user change the device select
  selectedDeviceChange(){
    // Set bin_data thing name to the selected thing_name
    this.bin.bin_data.thing_name = this.thing_name;

    // Set the number to create at one
    if(this.thing_name !== ''){
      this.numberToCreate = '1';
    }else{
      this.numberToCreate = '0';
    }
  }

  // Functino called when user change the select of client
  async selectedClientChange(){
    if(this.bin.bin_data.bin_model_id !== ''){
      // If bin model select is not empty it will call his function to get all available device
      this.binModelChange();
    }
  }

  // Implemented by cancel button to return to the admin component
  returnToAdmin(){
    // Put a sessions store variable so admin component could know to return on bin-list
    sessionStorage.setItem("previous", "bin-list");
    sessionStorage.setItem("from", "bin-create"); // This variable is used for bin-list to show the proper success message on create or update
    this.route.navigate(['/admin']); // Return to admin component
  }

  // function called when a distributor user click on cancel button and return him to the bin model list
  returnToDistributorBinDashboard(){
    this.route.navigate(['/distributor-bins-model-dashboard']);
  }

  // Function called when a client user click on cancel button and bring him back to bin list
  returnToClientBinDashboard(){
    this.route.navigate(['/client-bin-dashboard']);
  }

  // Function that avoid submitting the page when user press enter at the end of inputting address in address input
  onInputAddressKeydown(event: KeyboardEvent): void {
    if(event.key === 'Enter'){
      event.preventDefault(); // Don't submit the page
    }
  }
}
