import { Injectable } from '@angular/core';
import { IotService, Devices } from './iot.service';
import { BinsService } from './bins.service';
import { TaxeTypeService } from './taxe-type.service';
import { OperatorService } from './operator.service';
import { filter } from 'rxjs';
import { EntitiesLocationsService } from './entities-locations.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  public isFilter: boolean = false;
  public lastUserFilterCount: number = 0;
  public callingComponent: string = '';
  public newArray: any;

  constructor(public iotService: IotService,
              public bin: BinsService,
              public taxeTypeService: TaxeTypeService,
              public operatorService: OperatorService,
              public entitiesLocationService: EntitiesLocationsService) { }

  // Will set the caller that use the service
  setCallingComponent(caller: string){
    this.callingComponent = caller;
  }

  // Needed for the search function to know what exactly to do whit the filter
  getCallingComponent(){
    return this.callingComponent;
  }

  /*                             Explenation of the service

    If you need to do a new filter, you will need first to call this fonction. in your HTML component you call filter.search("Values of the inputFilter" , "your component.service.array", "getLastFilterCount() from component.yourService")
      and filter.setCallingComponent('your component that call the service')
    In your service.array ( or where your used array is) you need two function
      setLastFilterCount(filterCount: number) that set a variable for the filterCount(you have to create it) and it will be used to know when user just do a backspace.
      getLastFilterCount() that return the filterCount(this will be the last count of the filter) will be used to know when user just do a backspace.
      resetArray() you will need to create an originalArray that you gonna initialize when you create you component.service.array then in reset function,
        component.service.array = originalArray
    see iotComponent for more details

  */

  // Function that will do the filter on components array
  search(userFilter: string, array: any, lastUserFilterCount: number){
    // Determine whether to show the list based on the filter value
    this.isFilter = userFilter.toLowerCase() !== '';
    // Initializa the lastUserFilterCount
    this.lastUserFilterCount = lastUserFilterCount;

    // count the string that is passed by the user to keep the count of the filter
    const filterCount = userFilter.length;

    // Check if the list is being shown (filter is active) and if user have done a backspace(lastUserCountFilter will be less then filterCount)
    switch(true){
      case (this.isFilter === true && filterCount > this.lastUserFilterCount):
        // Switch between each of the component callers for all tree options
        switch(this.callingComponent){
          case 'iotComponent':
            // Filter the array of devices
            this.iotService.devicesArray = this.iotService.devicesArray.filter( v => v.thingName.toLowerCase().includes(userFilter));
            this.iotService.setCountFilter(filterCount); // Set the count of the user filter
            break;

          case 'bin-details-list':
            // Filter the array of binss
            this.bin.bin_detail_array = this.bin.bin_detail_array.filter( v => v.details.bin_model_number.toLowerCase().includes(userFilter));
            this.bin.setCountFilter(filterCount); // Set the count of the user filter
            break;

          case 'bin-model-list':
            // Filter the array of bin-model
            this.bin.bin_model_array = this.bin.bin_model_array.filter( v => v.bin_model_number.toLowerCase().includes(userFilter));
            this.bin.setCountFilter(filterCount); // Set the count of the user filter
            break;

          case 'taxe-type-list':
            // Filter the array of taxe-type
            this.taxeTypeService.taxe_type_array = this.taxeTypeService.taxe_type_array.filter( v => v.ISO_code.toLowerCase().includes(userFilter));
            this.taxeTypeService.setCountFilter(filterCount); // Set the count of the user filter
            break;

          case 'operator-list':
            // Filter the array of operator
            this.operatorService.operator_array = this.operatorService.operator_array.filter( v => v.legal_name.toLowerCase().includes(userFilter));
            this.operatorService.setCountFilter(filterCount); // Set the count of the user filter
            break;

          case 'entities-locations-list':
            // Filter array of locations
            this.entitiesLocationService.location_detail_array = this.entitiesLocationService.location_detail_array.filter(v => v.entity_legal_name.toLowerCase().includes(userFilter));
            this.entitiesLocationService.setCountFilter(filterCount); // Set the count of the location
        }
        break;

      case (this.isFilter === true && filterCount < this.lastUserFilterCount):
        // Switch between each of the component callers for all tree options
        switch(this.callingComponent){
          case 'iotComponent':
            this.iotService.resetDevicesArray(); // Reset the array of devices
            // Filter the array of devices
            this.iotService.devicesArray = this.iotService.devicesArray.filter( v => v.thingName.toLowerCase().includes(userFilter));
            this.iotService.setCountFilter(filterCount); // Set the count of the user filter
            break;

          case 'bin-details-list':
            this.bin.resetBinArray(); // Reset the array of bins
            // Filter the array of bins
            this.bin.bin_detail_array = this.bin.bin_detail_array.filter( v => v.details.bin_model_number.toLowerCase().includes(userFilter));
            this.bin.setCountFilter(filterCount); // Set the count of the filter
            break;

          case 'bin-model-list':
            this.bin.resetBinArray(); // Reset the array of bin-model
            // Filter the array of bin-model
            this.bin.bin_model_array = this.bin.bin_model_array.filter( v => v.bin_model_number.toLowerCase().includes(userFilter));
            this.bin.setCountFilter(filterCount); // Set the count of the filter
            break;

          case 'taxe-type-list':
            this.taxeTypeService.resetTaxeTypeArray(); // Reset the array of taxe-type
            // Filter the array of bin-model
            this.taxeTypeService.taxe_type_array = this.taxeTypeService.taxe_type_array.filter( v => v.ISO_code.toLowerCase().includes(userFilter));
            this.taxeTypeService.setCountFilter(filterCount); // Set the count of the filter
            break;

          case 'operator-list':
            this.operatorService.resetOperatorArray(); // Reset the array of operator
            // Filter the array of operator
            this.operatorService.operator_array = this.operatorService.operator_array.filter( v => v.legal_name.toLowerCase().includes(userFilter));
            this.operatorService.setCountFilter(filterCount);
            break;

          case 'entities-locations-list':
            this.entitiesLocationService.resetLocationDetailArray(); // reset the array of locations
            // Filter the array of locations
            this.entitiesLocationService.location_detail_array = this.entitiesLocationService.location_detail_array.filter(v => v.entity_legal_name.toLowerCase().includes(userFilter));
            this.entitiesLocationService.setCountFilter(filterCount);
        }
        break;

      case (this.isFilter === false):
        // Switch between each of the component callers for all tree options
        switch(this.callingComponent){
          case 'iotComponent':
            this.iotService.resetDevicesArray(); // Reset the array of devices
            this.iotService.setCountFilter(filterCount); // Set the count of the user filter
            break;

          case 'bin-details-list':
            this.bin.resetBinArray(); // Reset the array of bins
            this.bin.setCountFilter(filterCount); // Set the count of the user filter
            break;

          case 'bin-model-list':
            this.bin.resetBinArray(); // Reset the array of bins
            this.bin.setCountFilter(filterCount); // Set the count of the user filter
            break;

          case 'taxe-type-list':
            this.taxeTypeService.resetTaxeTypeArray(); // Reset the array of taxe-type
            this.taxeTypeService.setCountFilter(filterCount); // Set the count of the user filter
            break;

          case 'operator-list':
            this.operatorService.resetOperatorArray(); // Reset the operator array
            this.operatorService.setCountFilter(filterCount); // Set the count of the user filter
            break;

          case 'entities-locations-list':
            this.entitiesLocationService.resetLocationDetailArray(); // Reset the location array
            this.entitiesLocationService.setCountFilter(filterCount); // Set the count of the user filter
        }
        break;
    }
  }
}
