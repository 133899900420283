<app-system-message></app-system-message>
<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">
    <section [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'binModelUpdateViewBinModelUpdate' | translate }}</h4>
            <form>
                <!-- Input field for bin model number -->
                <div class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-hashtag"></i></span>
                    <input [(ngModel)]="this.bin.bin_model_data.bin_model_number" type="text"
                    class="form-control" name="bin_model_number"
                    placeholder="{{ 'binModelUpdateEnterBinNumber' | translate }}" aria-label="bin_model_number"
                    aria-describedby="basic-addon1"
                    (blur)="this.validationService.validateBinModelNumber(this.bin.bin_model_data.bin_model_number)"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'binModelNumberInvalid'">
                </div>
                <!-- Select for bin model shape -->
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-star"></i></span>
                    <select [(ngModel)]="this.bin.bin_model_data.bin_shape" class="form-control" name="bin_model_shape">
                        <option value="" disabled selected>{{ "binModelUpdateSelectEnterShape" | translate }}</option>
                        <option value="S">{{ "binModelUpdateOptionSquare" | translate }}</option>
                        <option value="C">{{ "binModelUpdateOptionCylinder" | translate }}</option>
                    </select>
                </div>
                <!-- Input for bin model width -->
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-arrows-alt-h"></i></span>
                    <input [(ngModel)]="this.bin.bin_model_data.bin_width" type="text"
                    class="form-control" name="bin-width"
                    placeholder="{{ 'binModelUpdateEnterWidth' | translate }}" aria-label="width"
                    aria-describedby="basic-addon1">
                </div>
                <!-- Input for bin model heigth -->
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-arrows-alt-v"></i></span>
                    <input [(ngModel)]="this.height" type="text"
                    class="form-control" name="bin_height"
                    placeholder="{{ 'binModelUpdateEnterHeight' | translate }}" aria-label="bin_height"
                    aria-describedby="basic-addon1"
                    (blur)="this.validationService.validateBinWidthHeightDepth(this.height, 'height')"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'binHeightInvalid'">
                </div>
                <!-- Input for bin model depth -->
                <div class="input-group mb-3" *ngIf="this.bin.bin_model_data.bin_shape === 'S'">
                    <span class="input-group-text" id="basic-addon1"><i class="fa depth"></i></span>
                    <input [(ngModel)]="this.depth" type="text"
                    class="form-control" name="bin_depth"
                    placeholder="{{ 'binModelUpdateEnterDepth' | translate }}" aria-label="bin_depth"
                    aria-describedby="basic-addon1">
                </div>
                <!-- Input for tha total volume calculated -->
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa total_volume"></i></span>
                    <input [(ngModel)]="this.totalVolume" type="text"
                    class="form-control totalVolume" name="total_volume"
                    placeholder="{{ 'binModelUpdateViewTotalVolume' | translate }}" aria-label="total_volume">
                </div>
                <!-- Select to know if the bin is above ground or not -->
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa above_ground"></i></span>
                    <select [(ngModel)]="this.bin.bin_model_data.above_ground" class="form-control" name="bin_model_above_ground">
                        <option value="" disabled selected>{{ "binModelUpdateSelectEnterAboveGround" | translate }}</option>
                        <option value="1">{{ "binModelUpdateOptionYes" | translate }}</option>
                        <option value="0">{{ "binModelUpdateOptionNo" | translate }}</option>
                    </select>
                </div>
                <!-- Select to know if bin model comme with a device or not -->
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-microchip"></i></span>
                  <select [(ngModel)]="this.bin.bin_model_data.with_thing" class="form-control" name="bin_model_with_thing">
                      <option value="" disabled selected>{{ "comingWithDevice" | translate }}</option>
                      <option value="1">{{ "binModelUpdateOptionYes" | translate }}</option>
                      <option value="0">{{ "binModelUpdateOptionNo" | translate }}</option>
                  </select>
              </div>
                <!-- Select used to get the distributor we associate to the bin model -->
                <div class="input-group mb-3" *ngIf="this.cognitoService.userType === 'muirwood'">
                  <span class="input-group-text" id="basic-addon1"><i class="fa fa-hashtag"></i></span>
                  <ng-select [(ngModel)]="this.bin.bin_model_data.distributor_id" class="form-control ng-select-distributor" name="client"
                    aria-label="Client Name" aria-describedby="basic-addon1" [ngClass]="{'has-value': this.bin.bin_model_data.distributor_id }">
                      <ng-option value="" selected disabled>{{ "distributorName" | translate }}</ng-option>
                      <ng-option *ngFor="let option of this.cognitoService.distributorsInfosArray; trackBy: trackByDistributorId" value="{{ option.distributor_id }}">{{ option.legal_name }}</ng-option>
                  </ng-select>
                </div>
                <!-- Checkbos for the status of the bin model -->
                <div class="card-title mt-0 text-center status d-flex align-items-center">
                    <label class="switch">
                        <input type="checkbox" id="checkboxStatus" [name]="'active-bin-' + bin.bin_model_data.bin_model_id" [ngModel]="bin.bin_model_data.active" (change)="this.alert.showAlertDelete= true; this.alert.deactiveBinModel(bin.bin_model_data.bin_model_id, bin.bin_model_data.bin_model_number, bin.bin_model_data.active)" [disabled]="this.alert.showAlertDelete">
                        <span [ngClass]="{'active-checkbox': bin.bin_model_data.active === 1, 'inactive-checkbox': bin.bin_model_data.active === 0}"  class="slider round"></span>
                    </label>
                    <label class="toggle">{{ "iotTabHeaderStatus" | translate }}</label>
                </div>
                <!-- Submit button -->
                <nav class="form-group displayFlex">
                    <button (click)="returnToAdmin()" *ngIf="this.cognitoService.userType === 'muirwood'" [ngClass]="theme.getThemeClass()" type="submit" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button (click)="returnToDistributorBinModel()" *ngIf="this.cognitoService.userType === 'distributor'" [ngClass]="theme.getThemeClass()" type="submit" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
                    <button [ngClass]="theme.getThemeClass()" (click)="onSubmit()" type="submit" class="btn btn-block btnCreate">{{ 'update' | translate }}</button>
                </nav>
            </form>
        </article>
        <app-alert-modal *ngIf="this.alert.showAlertDelete" class="alertDelete" [ngClass]="theme.getThemeClass()"></app-alert-modal>
      </section>
</div>


