import { catchError, forkJoin, from, last, map, mergeMap, toArray } from 'rxjs';
import { Injectable, Inject, forwardRef } from '@angular/core';
import { CognitoService } from './cognito.service';
import { IotService } from './iot.service';
import { BinData, BinsService } from './bins.service';
import { ClientService } from './client.service';
import { DistributorsService } from './distributors.service';
import { OperatorService } from './operator.service';
import { TaxeTypeService } from './taxe-type.service';
import { ChartService } from './chart.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { RoleService } from './role.service';
import { PdfService } from './pdf.service';
import { HttpClient } from '@angular/common/http';
import { consumerPollProducersForChange } from '@angular/core/primitives/signals';

export interface BinArrayItem {
  thing_name: string;
  bin_id: string;
  client_id?: string;
  distributor_id?: string;
}

export interface ThingStatusArray {
  thing_name: string;
  status: string;
  battery?: number;
  distance_to_lid?: number;
  last_update?: number;
}

interface StatusData {
  healthy?: number,
  unhealthy?: number,
  inactive?: number,
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  // For global use
  public userType: string = '';
  public userRole: string = '';
  public adminArray: any[] = [];
  public clientArray: any[] = [];
  public distributorArray: any[] = [];
  public operatorArray: any[] = [];
  public collectionArray: any[] = [];

  // For admin dashboard
  public userCount: number = 0;
  public binCount: number = 0;
  public binModelCount: number = 0;
  public taxTypeCount: number = 0;
  public clientCount: number = 0;
  public distributorCount: number = 0;
  public operatorCount: number= 0;
  public deviceCount: number = 0;
  public healthyDeviceCount: number = 0;
  public unhealthyDeviceCount: number = 0;
  public healthyDeviceArray: any = [];
  public unhealthyDeviceArray: any = [];
  public relationships: BinArrayItem[] = [];

  // For distributor dashboard
  public distributorBinModelCount: number = 0;
  public distributorBinCount: number = 0;
  public distributorClientCount: number = 0;
  public distributorBinModelArray: any[] = [];
  public distributorBinArray: any[] = [];
  public distributorDeviceArray: any[] = [];
  public distributorDeviceCount: number = 0;
  public distributorAdminCount: number = 0;
  public distributorDistributorCount: number = 0;
  public distributorOperatorCount: number = 0;
  public distributorCollectionCount: number = 0;
  public distributorWorkOrderArray: any[] = [];
  public distributorWorkOrderCount: number = 0;

  // For distributor work order status count pie chart
  public distributorWorkOrderStatusCompleteCount: number = 0;
  public distributorWorkOrderStatusInProgressCount: number = 0;
  public distributorWorkOrderStatusPausedCount: number = 0;
  public distributorWorkOrderStatusCancelledCount: number = 0;
  public distributorWorkOrderStatusErrorCount: number = 0;
  public distributorWorkOrderStatusDelayedCount: number = 0;
  public distributorWorkOrderStatusOpenCount: number = 0;
  public distributorWorkOrderStatusAssignedCount: number = 0;
  public binFillLevelLow: number = 0;
  public binFillLevelMedium: number = 0;
  public binFillLevelHigh: number = 0;

  // variable to control the loading element
  public stopLoading: boolean = false;

  // for client dashboard
  public healthy: number = 0;
  public unhealthy: number = 0;
  public inactive: number = 0;
  public clientAdminCount: number = 0;
  public clientClientCount: number = 0;
  public clientCollectionCount: number = 0;
  public thingStatusArray: ThingStatusArray[] = [];
  public binDataArray: BinData[] = [];
  public countStatus: StatusData[] = [];
  public finalStats: any[] = [];
  public things: string[] = [];

  constructor(
    public users: CognitoService,
    public devices: IotService,
    public bins: BinsService,
    public clients: ClientService,
    public distributors: DistributorsService,
    public operators: OperatorService,
    public taxes: TaxeTypeService,
    private charts: ChartService,
    @Inject(forwardRef(() => TranslateService)) @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private role: RoleService,
    public pdf: PdfService,
    private iot: IotService,
    private http: HttpClient
  ) { }

  initArrays(){
    this.adminArray = [];
    this.clientArray = [];
    this.distributorArray = [];
    this.operatorArray = [];
    this.collectionArray = [];
  }

  // Asynchronous function to fetch all users associated with a client
  async getAllClientUsers(){
    // Using await with promise to ensure asynchronous execution
    await this.distributors.getUsersByDistributorId(this.users.distributorId).then((res) => {
      try{
        // Iterating through the response array of users
        for(let users of res){
          // Categorizing users based on their user_type
          switch(users.user_type){
            case 'admin':
              this.adminArray.push(users);
              break;
            case 'distributor':
              this.distributorArray.push(users);
              break;
            case 'operator':
              this.operatorArray.push(users);
              break;
            case 'collection':
              this.collectionArray.push(users);
              break;
          }
        }
        // Updating counts for each user type
        this.distributorAdminCount = this.adminArray.length;
        this.distributorDistributorCount = this.distributorArray.length;
        this.distributorOperatorCount = this.operatorArray.length;
        this.distributorCollectionCount = this.collectionArray.length;
      }catch(error){
        // Handling any potential errors and logging them
        console.error('Error: ', error);
      }
    });
  }

  // initializes data for the client dashboard
  async initializeClientData(
    healthyCount: number, unhealthyCount: number, inactiveCount: number,
    clientId: string,
    adminCount: number, clientCount: number, collectionCount: number,
    binUsageLow: number, binUsageMedium: number, binUsageHigh: number,
    workOrdersComplete: number, workOrdersInProgress: number, workOrdersPaused: number, workOrdersCanceled: number, workOrdersError: number, workOrdersDelayed: number, workOrdersOpen: number, workOrdersAssigned: number,
    ) {
      // this.healthy = 0;
      // this.unhealthy = 0;
      // this.inactive = 0;

      // device status count to chart
      this.charts.singlePieChart = [
        { "name": await this.charts.getChartLabel('chartLabelHealthy'), "value": this.healthy},
        { "name": await this.charts.getChartLabel('chartLabelUnhealthy'), "value": this.unhealthy},
        { "name": await this.charts.getChartLabel('inactive'), "value": this.inactive}
      ];

      // user list count to chart
      this.charts.singleBarChartAdmin = [
        { "name": await this.charts.getChartLabel('chartLabelAdmin'), "value": adminCount },
        { "name": await this.charts.getChartLabel('chartLabelClients'), "value": clientCount },
        { "name": await this.charts.getChartLabel('chartLabelCollection'), "value": collectionCount }
      ]

      this.charts.totalPieChart = healthyCount + unhealthyCount + inactiveCount;

      // bin usage count to chart
      this.charts.singleBarChart = [
        { "name": await this.charts.getChartLabel('chartLabelLowFillLevel'), "value": this.binFillLevelLow},
        { "name": await this.charts.getChartLabel('chartLabelMediumFillLevel'), "value": this.binFillLevelMedium},
        { "name": await this.charts.getChartLabel('chartLabelHighFillLevel'), "value": this.binFillLevelHigh}
      ]

      // work order status count to chart
      this.charts.singlePieChartSlice = [
        { "name": await this.charts.getChartLabel('Complete'), "value": workOrdersComplete },
        { "name": await this.charts.getChartLabel('In-progress'), "value": workOrdersInProgress },
        { "name": await this.charts.getChartLabel('Paused'), "value": workOrdersPaused },
        { "name": await this.charts.getChartLabel('Cancelled'), "value": workOrdersCanceled },
        { "name": await this.charts.getChartLabel('Error'), "value": workOrdersError },
        { "name": await this.charts.getChartLabel('Delayed'), "value": workOrdersDelayed },
        { "name": await this.charts.getChartLabel('Open'), "value": workOrdersOpen },
        { "name": await this.charts.getChartLabel('Assigned'), "value": workOrdersAssigned }
      ]

      // Assuming your divs have a class named 'chart-container'
      var targetDivs = document.querySelectorAll('.chart-container');

      // Check if there are elements with the specified class
      if (targetDivs.length > 0) {
          // Iterate through the NodeList and set the display property to flex for each element
          targetDivs.forEach(function(div) {
            if (div instanceof HTMLElement) {
                div.style.display = 'flex';
            }
        });
      } else {
          console.error('No elements found with the specified class');
      }

      this.stopLoading = true
  }

  // initializes data for the admin dashboard
  async initializeDashboardData(){
    this.healthy = 0;
    this.unhealthy = 0;
    this.inactive = 0;

    // loading data for chart
    (await this.getRelationships()).subscribe();
    await this.countApplicationBinModels();
    await this.countApplicationBins();
    await this.countApplicationClients();
    await this.countDashboardDevicesAndParameters();
    await this.countApplicationDistributors();
    await this.countApplicationOperators();
    await this.countApplicationUsers();
    await this.countTaxTypes();

    // device status count to chart
    this.charts.singlePieChart = [
      { "name": await this.charts.getChartLabel('chartLabelHealthy'), "value": this.healthy},
      { "name": await this.charts.getChartLabel('chartLabelUnhealthy'), "value": this.unhealthy},
      { "name": await this.charts.getChartLabel('inactive'), "value": this.inactive}
    ];

    this.charts.totalPieChart = this.deviceCount;

    // user list count to chart
    this.charts.singleBarChart = [
      { "name": await this.charts.getChartLabel('chartLabelClients'), "value": this.clientCount},
      { "name": await this.charts.getChartLabel('chartLabelDistributors'), "value": this.distributorCount},
      { "name": await this.charts.getChartLabel('chartLabelOperators'), "value": this.operatorCount},
    ];


    this.charts.singleBarChartAdmin = [

      // bin count to chart
      { "name": await this.charts.getChartLabel('chartLabelBins'), "value": this.binCount},

      // bin model count to chart
      { "name": await this.charts.getChartLabel('chartLabelBinModels'), "value": this.binModelCount},

      // user count to chart
      { "name": await this.charts.getChartLabel('chartLabelUsers'), "value": this.userCount},

      // tax type count to chart
      { "name": await this.charts.getChartLabel('chartLabelTaxTypes'), "value": this.taxTypeCount},
    ];
      // Assuming your divs have a class named 'chart-container'
    var targetDivs = document.querySelectorAll('.chart-container');

    // Check if there are elements with the specified class
    if (targetDivs.length > 0) {
        // Iterate through the NodeList and set the display property to flex for each element
        targetDivs.forEach(function(div) {
          if (div instanceof HTMLElement) {
              div.style.display = 'flex';
          }
      });
    } else {
        console.error('No elements found with the specified class');
    }

    this.stopLoading = true

  }

  // initializes data for the distributor dashboard
  async initializaClientDashboardData(){





    await this.getBinModelAssociationByDistributor();
    await this.getDeviceByDistributorId();
    await this.setDistributorDeviceHealth();
    await this.getAllDistributorUsers();
    await this.getAllDistributorWorkOrders();

    this.charts.singleBarChart = [
      { "name": await this.charts.getChartLabel('chartLabelBinModels'), "value": this.distributorBinModelCount},
      { "name": await this.charts.getChartLabel('chartLabelBins'), "value": this.distributorBinCount},
      { "name": await this.charts.getChartLabel('chartLabelClients'), "value": this.distributorClientCount}
    ]

    // device status count to chart
    this.charts.singlePieChart = [
      { "name": await this.charts.getChartLabel('chartLabelHealthy'), "value": this.healthy},
      { "name": await this.charts.getChartLabel('chartLabelUnhealthy'), "value": this.unhealthy},
      { "name": await this.charts.getChartLabel('Inactive'), "value": this.inactive}
    ]

    this.charts.totalPieChart = this.distributorDeviceCount;

    // users count to chart
    this.charts.singleBarChartAdmin = [
      { "name": await this.charts.getChartLabel('chartLabelAdmin'), "value": this.distributorAdminCount },
      { "name": await this.charts.getChartLabel('chartLabelDistributors'), "value": this.distributorDistributorCount },
      { "name": await this.charts.getChartLabel('chartLabelOperator'), "value": this.distributorOperatorCount },
      { "name": await this.charts.getChartLabel('chartLabelCollection'), "value": this.distributorCollectionCount }
    ]

    // work order status count to chart
    this.charts.singlePieChartSlice = [
      { "name": await this.charts.getChartLabel('Complete'), "value": this.distributorWorkOrderStatusCompleteCount },
      { "name": await this.charts.getChartLabel('In-progress'), "value": this.distributorWorkOrderStatusInProgressCount },
      { "name": await this.charts.getChartLabel('Paused'), "value": this.distributorWorkOrderStatusPausedCount },
      { "name": await this.charts.getChartLabel('Cancelled'), "value": this.distributorWorkOrderStatusCancelledCount },
      { "name": await this.charts.getChartLabel('Error'), "value": this.distributorWorkOrderStatusErrorCount },
      { "name": await this.charts.getChartLabel('Delayed'), "value": this.distributorWorkOrderStatusDelayedCount },
      { "name": await this.charts.getChartLabel('Open'), "value": this.distributorWorkOrderStatusOpenCount },
      { "name": await this.charts.getChartLabel('Assigned'), "value": this.distributorWorkOrderStatusAssignedCount }
    ]

      // // Assuming your divs have a class named 'chart-container'
      var targetDivs = document.querySelectorAll('.chart-container');

      // Check if there are elements with the specified class
      if (targetDivs.length > 0) {
          // Iterate through the NodeList and set the display property to flex for each element
          targetDivs.forEach(function(div) {
            if (div instanceof HTMLElement) {
                div.style.display = 'flex';
            }
        });
      } else {
          console.error('No elements found with the specified class');
      }
  }

  // initializes data for the distributor dashboard
  async initializaDistributorDashboardData(){
    await this.getBinModelAssociationByDistributor();
    await this.getDeviceByDistributorId();
    await this.setDistributorDeviceHealth();
    await this.getAllDistributorUsers();
    await this.getAllDistributorWorkOrders();

    this.charts.singleBarChart = [
      { "name": await this.charts.getChartLabel('chartLabelBinModels'), "value": this.distributorBinModelCount},
      { "name": await this.charts.getChartLabel('chartLabelBins'), "value": this.distributorBinCount},
      { "name": await this.charts.getChartLabel('chartLabelClients'), "value": this.distributorClientCount}
    ]

    // device status count to chart
    this.charts.singlePieChart = [
      { "name": await this.charts.getChartLabel('chartLabelHealthy'), "value": this.healthy},
      { "name": await this.charts.getChartLabel('chartLabelUnhealthy'), "value": this.unhealthy},
      { "name": await this.charts.getChartLabel('Inactive'), "value": this.inactive}
    ]

    this.charts.totalPieChart = this.distributorDeviceCount;

    // users count to chart
    this.charts.singleBarChartAdmin = [
      { "name": await this.charts.getChartLabel('chartLabelAdmin'), "value": this.distributorAdminCount },
      { "name": await this.charts.getChartLabel('chartLabelDistributors'), "value": this.distributorDistributorCount },
      { "name": await this.charts.getChartLabel('chartLabelOperator'), "value": this.distributorOperatorCount },
      { "name": await this.charts.getChartLabel('chartLabelCollection'), "value": this.distributorCollectionCount }
    ]

    // work order status count to chart
    this.charts.singlePieChartSlice = [
      { "name": await this.charts.getChartLabel('Complete'), "value": this.distributorWorkOrderStatusCompleteCount },
      { "name": await this.charts.getChartLabel('In-progress'), "value": this.distributorWorkOrderStatusInProgressCount },
      { "name": await this.charts.getChartLabel('Paused'), "value": this.distributorWorkOrderStatusPausedCount },
      { "name": await this.charts.getChartLabel('Cancelled'), "value": this.distributorWorkOrderStatusCancelledCount },
      { "name": await this.charts.getChartLabel('Error'), "value": this.distributorWorkOrderStatusErrorCount },
      { "name": await this.charts.getChartLabel('Delayed'), "value": this.distributorWorkOrderStatusDelayedCount },
      { "name": await this.charts.getChartLabel('Open'), "value": this.distributorWorkOrderStatusOpenCount },
      { "name": await this.charts.getChartLabel('Assigned'), "value": this.distributorWorkOrderStatusAssignedCount }
    ]

      // // Assuming your divs have a class named 'chart-container'
      var targetDivs = document.querySelectorAll('.chart-container');

      // Check if there are elements with the specified class
      if (targetDivs.length > 0) {
          // Iterate through the NodeList and set the display property to flex for each element
          targetDivs.forEach(function(div) {
            if (div instanceof HTMLElement) {
                div.style.display = 'flex';
            }
        });
      } else {
          console.error('No elements found with the specified class');
      }
  }

  //Sets the total number of Users in numeric form as a service property
  async countApplicationUsers(){
    await this.users.getAllUsers().then((res)=>{
        this.userCount = res.length;
    }).catch((error)=>{
      console.error("Error: ", error);
    })
  }

  //Sets the total number of Bins in numeric form as a service property
  async countApplicationBins(){
    await this.bins.getBins().then((res)=>{
      this.binCount = res.length;
    }).catch((error)=>{
      console.error("Error: ", error);
    })
  }

  //Sets the total number of Bin Models in numeric form as a service property
  async countApplicationBinModels(){
    await this.bins.getBinsModel().then((res)=>{
      this.binModelCount = res.length;
    }).catch((error)=>{
      console.error("Error: ", error);
    })
  }

  //Sets the total number of Tax Types in numeric form as a service property
  async countTaxTypes(){
    await this.taxes.getTaxeTypes().then((res)=>{
      this.taxTypeCount = res.length;
    }).catch((error)=>{
      console.error("Error: ", error);
    })
  }

  //Sets the total number of clients in numeric form as a service property
  async countApplicationClients(){
    await this.clients.getClients().subscribe((res)=>{
      try{
        let clientArray = JSON.parse(JSON.stringify(res));
        this.clientCount = clientArray.length;
      }
      catch(error){
        console.error("Error: ", error);
      }

    });
  }

  //Sets the total number of distributors in numeric form as a service property
  async countApplicationDistributors(){
    this.distributors.getDistributors().subscribe((res)=>{
      try{
        let distributorArray = JSON.parse(JSON.stringify(res));
        this.distributorCount = distributorArray.length;
      }
      catch(error){
        console.error("Error: ", error);
      }

    });
  }

  //Sets the total number of operators in numeric form as a service property
  async countApplicationOperators(){
    await this.operators.getOperator().then((res)=>{
      try{
        let OperatorArray = JSON.parse(JSON.stringify(res));
        this.operatorCount = OperatorArray.length;
      }
      catch(error){
        console.error("Error: ", error);
      }

    });
  }


// Funciton that loop into
  async setDeviceHealthLoop(shadows: any){
    let index: number = 0;
    for(const shadow of shadows){

      const name = shadow.thing_name || "";

      // Extracting timestamp and converting it to Date format
      let timestamp: number | undefined = shadow.dst_timestamp;
      timestamp = typeof timestamp !== 'undefined' ? timestamp : 0;
      const lastUpdate = new Date(timestamp * 1000);

      // Calculating battery percentage and rounding it
      const batteryPercentage: number = (parseFloat(shadow.bat) / 3.7) * 100;
      const roundedBatteryPercentage = Math.round(batteryPercentage);
      const distanceToLidInMilimeters = shadow.dst

      // Checking conditions and updating device status and counts
      switch(true){
        case (this.isDateGapGreaterThan48Hours(lastUpdate) && this.isBinIdPopulated(name, this.relationships)) && roundedBatteryPercentage < 40:
          this.unhealthy++;
          // Creating object for unhealthy device status
          const deviceUnhealthy: ThingStatusArray = {
            thing_name: name,
            status: "Unhealthy",
            battery: roundedBatteryPercentage,
            distance_to_lid: distanceToLidInMilimeters,
            last_update: timestamp
          }
          // Adding the unhealthy device to the status array
          this.thingStatusArray.push(deviceUnhealthy)
          break;
        case !this.isDateGapGreaterThan48Hours(lastUpdate) && this.isBinIdPopulated(name, this.relationships) && roundedBatteryPercentage > 40:
          this.healthy++;
          // Creating object for healthy device status
          const deviceHealthy: ThingStatusArray = {
            thing_name: name,
            status: "Healthy",
            battery: roundedBatteryPercentage,
            distance_to_lid: distanceToLidInMilimeters,
            last_update: timestamp
          }
          // Adding the healthy device to the status array
          this.thingStatusArray.push(deviceHealthy)
          break;
        default:
          this.inactive++;
          // Creating object for inactive device status
          const deviceInactive: ThingStatusArray = {
            thing_name: name,
            status: "Inactive",
            battery: roundedBatteryPercentage,
            distance_to_lid: distanceToLidInMilimeters,
            last_update: timestamp
          }
          // Adding the inactive device to the status array
          this.thingStatusArray.push(deviceInactive)
          break;
      }

      // Incrementing the index for each processed device
      index++
    }

    // Setting the total device count as a service property
    this.deviceCount = index;
  }

// Asynchronous function to count application devices and parameters, setting them as service properties
  async countDashboardDevicesAndParameters(){
    // Initializing arrays for healthy and unhealthy devices
    this.unhealthyDeviceArray = [];
    this.healthyDeviceArray = [];
    // Fetching the list of things
    await this.devices.listThings().then(async (result)=>{

      let things = [];
      // Extracting thing names from the result
      for(const thing of result){
        things.push(thing.thingName);
      }

      // Fetching thing shadows using the obtained thing names
      (await this.devices.getThingShadows(things)).subscribe((result: any)=>{
        this.setDeviceHealthLoop(JSON.parse(result.shadows));
      });
    });
  }

  // Asynchronous function to count application devices and parameters, setting them as service properties
  async countClientDashboardDevicesAndParameters(){
    // Initializing arrays for healthy and unhealthy devices
    this.unhealthyDeviceArray = [];
    this.healthyDeviceArray = [];

    // Fetching thing shadows using the obtained thing names
    (await this.devices.getThingShadows(this.things)).subscribe((res: any)=>{

      this.setDeviceHealthLoop(JSON.parse(res.shadows));
      this.countBinStatus(JSON.parse(res.shadows), JSON.parse(res.bins));
    });

  }

  // Method get data to fill charts based on devices status and bin fill levels
  countBinStatus(devicesArray: any[], bins: BinData[]) {




    // Loop through each device in the array
    devicesArray.forEach(item => {
      const binData =  this.bins.filterBinData(bins, item.thing_name) ;
      let binHeight = binData?.bin_height ?? 0;
      // Extract the numeric fill level by removing "%" and replacing commas
      const fixedFillLevel = 100 - (parseFloat(item.dst)/binHeight*100);

      // Check the fill level and increment corresponding counters
      switch (true) {
        case fixedFillLevel < 0:
          //Do nothing, these values don't count
          break;
        case fixedFillLevel <= 60:
            this.binFillLevelLow++;
            break;
        case fixedFillLevel <= 79.09:
            this.binFillLevelMedium++;
            break;
        case fixedFillLevel >= 79.09 && fixedFillLevel <= 100:
            this.binFillLevelHigh++;
            break;
      }

    });

  }

    // Function to retrieve thing names associated with a given client ID
  // clientId: The client ID for which to fetch thing names
  // Returns an array of matching thing names
   getThingNamesByClientId(clientId: string): string[] {

    // Array to store matching thing names
    const matchingThingNames: string[] = [];

    // Iterate through relationships in the dashboard service
    this.relationships.forEach(item => {
        // Check if the client ID matches the specified client ID
        if (item.client_id === clientId) {
            // Add the thing name to the array if the client ID matches
            matchingThingNames.push(item.thing_name);
        }
    });

    // Return the array of matching thing names
    return matchingThingNames;
  }

   // Asynchronous function to count application devices and parameters, setting them as service properties
   async countApplicationDevicesAndParametersForClientDashBoard(thingsss: string[]){
    // Initializing arrays for healthy and unhealthy devices
    this.unhealthyDeviceArray = [];
    this.healthyDeviceArray = [];
    let index: number = 0;

    try {

      (await this.devices.getThingShadows(thingsss)).subscribe((res: any) => {


        for(const bin of JSON.parse(res.bins)){
          this.binDataArray.push(bin);
        }
        for (const shadow of JSON.parse(res.shadows)) {

          const name = shadow.thing_name || "";

          // Extracting timestamp and converting it to Date format
          let timestamp: number | undefined = shadow.dst_timestamp;
          timestamp = typeof timestamp !== 'undefined' ? timestamp : 0;
          const lastUpdate = new Date(timestamp * 1000);

          // Calculating battery percentage and rounding it
          const batteryPercentage: number = ((shadow.bat) / 3.7) * 100;
          const roundedBatteryPercentage = Math.round(batteryPercentage);
          const distanceToLidInMilimeters = shadow.dst;

          // Checking conditions and updating device status and counts
          switch (true) {
              case (this.isDateGapGreaterThan48Hours(lastUpdate) && this.isBinIdPopulated(name, this.relationships)) && roundedBatteryPercentage < 40:
                  this.unhealthy++;
                  // Creating object for unhealthy device status
                  const deviceUnhealthy: ThingStatusArray = {
                      thing_name: name,
                      status: "Unhealthy",
                      battery: roundedBatteryPercentage,
                      distance_to_lid: distanceToLidInMilimeters
                  };
                  // Adding the unhealthy device to the status array
                  this.thingStatusArray.push(deviceUnhealthy);
                  break;
              case !this.isDateGapGreaterThan48Hours(lastUpdate) && this.isBinIdPopulated(name, this.relationships) && roundedBatteryPercentage > 40:
                  this.healthy++;
                  // Creating object for healthy device status
                  const deviceHealthy: ThingStatusArray = {
                      thing_name: name,
                      status: "Healthy",
                      battery: roundedBatteryPercentage,
                      distance_to_lid: distanceToLidInMilimeters

                  };
                  // Adding the healthy device to the status array
                  this.thingStatusArray.push(deviceHealthy);

                  break;
              default:
                  this.inactive++;
                  // Creating object for inactive device status
                  const deviceInactive: ThingStatusArray = {
                      thing_name: name,
                      status: "Inactive",
                      battery: roundedBatteryPercentage,
                      distance_to_lid: distanceToLidInMilimeters

                  };
                  // Adding the inactive device to the status array
                  this.thingStatusArray.push(deviceInactive);

                  break;
          }








          // Incrementing the index for each processed device
          index++;
      }
      this.countStatus = [{healthy:this.healthy, unhealthy: this.unhealthy, inactive: this.inactive}]

      });

    } catch (error) {
      console.error('An error occurred:', error);
    }
    // const qttArray = [this.healthy, this.unhealthy, this.inactive]

    return {"status":this.thingStatusArray, "bins": this.binDataArray, "qttStats": this.countStatus}

  }

  // Asynchronous function to count application devices and parameters, setting them as service properties
  async countApplicationDevicesAndParameters(){
    // Initializing arrays for healthy and unhealthy devices
    this.unhealthyDeviceArray = [];
    this.healthyDeviceArray = [];
    let index: number = 0;

    try {
      await this.devices.listThings().then(async (result) => {
        let things = [];
        this.binDataArray = [];
        // Extracting thing names from the result
        for (const thing of result) {
          things.push(thing.thingName);
        }

        // Fetching thing shadows using the obtained thing names
        (await this.devices.getThingShadows(things)).subscribe((res: any) => {

          for(const bin of JSON.parse(res.bins)){
            this.binDataArray.push(bin);
          }
          for (const shadow of JSON.parse(res.shadows)) {

            const name = shadow.thing_name || "";

            // Extracting timestamp and converting it to Date format
            let timestamp: number | undefined = shadow.dst_timestamp;
            timestamp = typeof timestamp !== 'undefined' ? timestamp : 0;
            const lastUpdate = new Date(timestamp * 1000);

            // Calculating battery percentage and rounding it
            const batteryPercentage: number = ((shadow.bat) / 3.7) * 100;
            const roundedBatteryPercentage = Math.round(batteryPercentage);
            const distanceToLidInMilimeters = shadow.dst;

            // Checking conditions and updating device status and counts
            switch (true) {
                case (this.isDateGapGreaterThan48Hours(lastUpdate) && this.isBinIdPopulated(name, this.relationships)) && roundedBatteryPercentage < 40:
                    this.unhealthy++;
                    // Creating object for unhealthy device status
                    const deviceUnhealthy: ThingStatusArray = {
                        thing_name: name,
                        status: "Unhealthy",
                        battery: roundedBatteryPercentage,
                        distance_to_lid: distanceToLidInMilimeters,
                        last_update: shadow.dst_timestamp,
                    };
                    // Adding the unhealthy device to the status array
                    this.thingStatusArray.push(deviceUnhealthy);
                    break;
                case !this.isDateGapGreaterThan48Hours(lastUpdate) && this.isBinIdPopulated(name, this.relationships) && roundedBatteryPercentage > 40:
                    this.healthy++;
                    // Creating object for healthy device status
                    const deviceHealthy: ThingStatusArray = {
                        thing_name: name,
                        status: "Healthy",
                        battery: roundedBatteryPercentage,
                        distance_to_lid: distanceToLidInMilimeters,
                        last_update: shadow.dst_timestamp,

                    };
                    // Adding the healthy device to the status array
                    this.thingStatusArray.push(deviceHealthy);

                    break;
                default:
                    this.inactive++;
                    // Creating object for inactive device status
                    const deviceInactive: ThingStatusArray = {
                        thing_name: name,
                        status: "Inactive",
                        battery: roundedBatteryPercentage,
                        distance_to_lid: distanceToLidInMilimeters,
                        last_update: shadow.dst_timestamp,

                    };
                    // Adding the inactive device to the status array
                    this.thingStatusArray.push(deviceInactive);

                    break;
            }

            // Incrementing the index for each processed device
            index++;
        }
        });
      });
    } catch (error) {
      console.error('An error occurred:', error);
    }

    return {"status":this.thingStatusArray, "bins": this.binDataArray}

  }

  // Asynchronous function to retrieve bin model association details by distributor
  async getBinModelAssociationByDistributor(){
    // Fetching bin model association details based on distributor ID
    await this.distributors.getBinModelAssociationByDistributorId(this.users.distributorId).then((res) => {
      try{
        // Assigning the retrieved array to the distributorBinArray property
        this.distributorBinArray = res;
        // Updating counts based on the first element of the retrieved array
        this.distributorBinCount = this.distributorBinArray[0].bin_count;
        this.distributorBinModelCount = this.distributorBinArray[0].bin_model_count;
        this.distributorClientCount = this.distributorBinArray[0].client_count;
      }catch(error){
        // Handling any potential errors and logging them
        console.error('Error: ', error);
      }
    });
  }

  // Asynchronous function to retrieve devices by distributor ID
  async getDeviceByDistributorId(){
    await this.distributors.getDeviceByDistributorId(this.users.distributorId).then((res) => {
      try{
        // Updating the device count based on the length of the distributorDeviceArray
        this.distributorDeviceCount = this.distributors.distributorIotDashboardArray.length;
      }catch(error){
        // Handling any potential errors and logging them
        console.error('Error: ', error);
      }
    });
  }

  // Asynchronous function to retrieve all users associated with a distributor
  async getAllDistributorUsers(){
    // Fetching users based on distributor ID
    await this.distributors.getUsersByDistributorId(this.users.distributorId).then((res) => {
      try{
        // Iterating through the response array of users
        for(let users of res){
          // Categorizing users based on their user_type
          switch(users.user_type){
            case 'admin':
              this.adminArray.push(users);
              break;
            case 'distributor':
              this.distributorArray.push(users);
              break;
            case 'operator':
              this.operatorArray.push(users);
              break;
            case 'collection':
              this.collectionArray.push(users);
              break;
          }
        }
        // Updating counts for each user type
        this.distributorAdminCount = this.adminArray.length;
        this.distributorDistributorCount = this.distributorArray.length;
        this.distributorOperatorCount = this.operatorArray.length;
        this.distributorCollectionCount = this.collectionArray.length;
      }catch(error){
        // Handling any potential errors and logging them
        console.error('Error: ', error);
      }
    });
  }

  // Function called to get all relationship of a device
  async getRelationships(){
    // Append the 'user' parameter to the URL as a query string
    const url = environment.api.stage + environment.api.route.getDeviceRelationships;

    return this.http.get(url).pipe(
      map((response) => {

        // Initializing relationships array
        this.relationships = [];
        if(response){
          // Parsing and pushing each row of the response to the relationships array
          for(const row of JSON.parse(JSON.stringify(response))){
            this.relationships.push({thing_name : row.thing_name, bin_id: row.bin_id, client_id: row.client_id, distributor_id: row.distributor_id})
        }
      }}),
      catchError((error) => {
        // Logging API error and re-throwing for the calling code to handle
        console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      }));
  }

  // Function called to get all work order for a distributor
  async getAllDistributorWorkOrders(){
    await this.distributors.getAllDistributorWorkOrders(this.users.distributorId).then((res) => {
      try{

        this.distributorWorkOrderArray = res;
        this.distributorWorkOrderCount = this.distributorWorkOrderArray.length;
        this.distributorWorkOrderStatusCompleteCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_complete').length;
        this.distributorWorkOrderStatusInProgressCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_in-progress').length;
        this.distributorWorkOrderStatusPausedCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_paused').length;
        this.distributorWorkOrderStatusCancelledCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_cancelled').length;
        this.distributorWorkOrderStatusErrorCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_error').length;
        this.distributorWorkOrderStatusDelayedCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_delayed').length;
        this.distributorWorkOrderStatusOpenCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_open').length
        this.distributorWorkOrderStatusAssignedCount = this.distributorWorkOrderArray.filter(workOrder => workOrder.status === '_assigned').length;
      }catch(error){
        console.error('Error: ', error);
      }
    });
  }

  isDateGapGreaterThan48Hours(dateToCompare: Date): boolean {
    // Get the current date and time
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate.getTime() - dateToCompare.getTime();

    // Convert the time difference to hours
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    // Check if the gap is greater than 48 hours
    return hoursDifference > 48;
  }

  isBinIdPopulated(thingName: string, array: BinArrayItem[]): boolean {
    const matchingItem = array.find(item => item.thing_name === thingName);

    return !!matchingItem && !!matchingItem.bin_id;
  }

  async setDistributorDeviceHealth(){
    // Set device relationship in dashboard service
    this.relationships = this.distributors.distributorIotDashboardArray.map(item => ({
      thing_name: item.thing_name,
      bin_id: item.bin_id,
      client_id: item.client_id,
      distributor_id: item.distributor_id
    }));

    let things: any[] = []
    // Set the things array with the map of the distributo device array to get only the thing_name in an array
    things = this.distributors.distributorIotDashboardArray.map(item => item.thing_name);

    // Fetching thing shadows using the obtained thing names
    (await this.devices.getThingShadows(things)).subscribe((result: any)=>{
      this.setDeviceHealthLoop(JSON.parse(result.shadows));

      this.thingStatusArray.forEach((filteredItem) => {
        let distance_to_lid = filteredItem.distance_to_lid;
        let distributorDevice = this.distributors.distributorIotDashboardArray.find(device => device.thing_name === filteredItem.thing_name);
        if(distributorDevice){
          let bin = this.distributors.distributorBinsDashboardArray.find(bin => bin.bin_id === distributorDevice.bin_id);
          if(bin && distance_to_lid){
            let object = {
              battery: filteredItem.battery,
              distance_to_lid: filteredItem.distance_to_lid,
              last_update: filteredItem.last_update,
              status: filteredItem.status,
              thing_name: filteredItem.thing_name,
              bin_capacity: bin.bin_height,
              location: bin.bin_address ?? '',
              fill_level: this.devices.checkFillLevelPercentage(distance_to_lid, bin.bin_height),
              bin_id: bin.bin_id ?? ''
            }

          this.distributorDeviceArray.push(object);
          }
        }
      });
    });
  }
}
