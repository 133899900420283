import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { EntitiesLocationsService } from '../service/entities-locations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../local-storage.service';
import { LocalizationService } from '../service/localization.service';
import { ModalAlertService } from '../service/alert-modal.service';

@Component({
  selector: 'app-entities-location-update',
  templateUrl: './entities-location-update.component.html',
  styleUrl: './entities-location-update.component.css'
})
export class EntitiesLocationUpdateComponent implements OnInit {

  // Variable from location id
  private location_id: string = '';

  // Variable used to know if user change the location address
  private addressChange: boolean = false;

  public active: number = 0;

  constructor(public theme: ThemeService,
              public entitiesLocationService: EntitiesLocationsService,
              private router: Router,
              private localStorageService: LocalStorageService,
              private localizationService: LocalizationService,
              public alert: ModalAlertService,
              private activatedRoute: ActivatedRoute){

  }

  async ngOnInit() {
    this.addressChange = false;
    this.entitiesLocationService.initLocation();
    // Get the param that was pass from the bin-list of the bin_id selected
    this.activatedRoute.params.subscribe(params => {
      this.location_id = params['id']; // Set bin_model_id to the param:id receive whit url
    });
    (await this.entitiesLocationService.getEntityLocationById(this.location_id)).subscribe(() => {
      this.active = this.entitiesLocationService.location_data.active;
    });

    this.localizationService.initAutocomplete();

    // Function trigger when user get alerted if he change active status then click on cancel
    this.alert.cancel$.subscribe(() => {
      let checkboxStatus = document.getElementById('checkboxStatus') as HTMLInputElement; // Set the a html variable of the checkbox for the status
      this.entitiesLocationService.location_data.active = this.alert.active; // Set the location_data active status to the good value
      // Will put the right checkbox statement
      if(this.alert.active === 1 && checkboxStatus){
        checkboxStatus.checked = true;
      } else if(this.alert.active === 0 && checkboxStatus){
        checkboxStatus.checked = false;
      }
    });

    // Function automatically trigger when user click on confirm into the alert modal
    this.alert.confirmLocation$.subscribe(() => {
      this.active = this.alert.active;
      // Set are own variable used in ngModel HTML
      this.entitiesLocationService.location_data.active = this.active;
    });
  }

  onSubmit(){
    if(this.addressChange){
      this.entitiesLocationService.location_data.location = this.localizationService.autoCompletionReturnAddress;
    }

    if(this.entitiesLocationService.location_data.location !== '' && this.entitiesLocationService.location_data.location_id !== '' && this.entitiesLocationService.location_data.active !== undefined){
      this.entitiesLocationService.updateEntityLocation();

      this.returnToEntities();
    }
  }

  // Implemented by cancel button to return to the admin component
  returnToEntities(){
    // Put a local store variable so entitie component could know to return on operator-list
    this.localStorageService.addItem('entitiesContent', 'locations');
    sessionStorage.setItem("from", "location-update"); // This variable is used for operator-list to show the proper success message on create or update
    this.router.navigate(['/entities']); // Return to entitie component
  }

  // Function that avoid submitting the page when user press enter at the end of inputting address in address input
  onInputAddressKeydown(event: KeyboardEvent): void {
    this.addressChange = true;
    if(event.key === 'Enter'){
      event.preventDefault(); // Don't submit the page
    }
  }
}
