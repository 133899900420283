import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { Router } from '@angular/router';
import { DistributorsService } from '../service/distributors.service';
import { CognitoService } from '../service/cognito.service';
import { environment } from '../environments/environment';
import { RoleService } from '../service/role.service';

@Component({
  selector: 'app-distributor-admin-dashboard',
  templateUrl: './distributor-admin-dashboard.component.html',
  styleUrls: ['./distributor-admin-dashboard.component.css', '../distributor-bins-model-dashboard/distributor-tiles.css', '../../global-elements.css']
})
export class DistributorAdminDashboardComponent implements OnInit{
  gridsterOptions = {
    draggable: {
      enabled: true,
    },
    resizable: {
      enabled: true,
    },
    minCols: 1,
    maxCols: 5,
    minRows: 4,
    maxRows: 100,
    defaultItemCols: 1,
    defaultItemRows: 1,
  };

  private originalUserArray: any[] = [];

  public userFilter: string = '';
  public filterBy: string = '';
  public sortBy: string = '';
  public ascDesc: number = 0;
  private filterCount: number = 0;
  private lastFilterCount: number = 0;
  private isFilter: boolean = false;
  public isSortBy: boolean = false;

  constructor(public theme: ThemeService,
              private route: Router,
              public distributors: DistributorsService,
              private cognitoService: CognitoService,
              public roleService: RoleService){

  }

  async ngOnInit() {
    // Call cognito to confirme the user and get his user type
    await this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();

    // Get user roles
    await this.roleService.getRoles();

    // If the array of users for the distributor ID of this user is not implemented yet, it go that it
    if(this.distributors.distributorUsersArray.length === 0 || this.distributors.distributorUsersArray === undefined){
      await this.distributors.getUsersByDistributorId(this.cognitoService.distributorId);
    }
    // Set the original array for the search
    this.originalUserArray = this.distributors.distributorUsersArray;
  }

  // Function called to reset the user array
  resetUserArray(){
    this.distributors.distributorUsersArray = [...this.originalUserArray];
  }

  // Funciton called to know if user have the admin role
  isUserHaveAdminRole(){
    return this.roleService.roles.includes('_admin');
  }

  // Function called to filter the array
  search(){
    // Set last filter count, filter count and if there's a filter
    this.lastFilterCount = this.filterCount;
    this.isFilter = this.userFilter.toLowerCase() !== '';
    this.filterCount = this.userFilter.length;

    // Set a filter property
    let filterProperty = this.filterBy;
    if(filterProperty === '' || filterProperty === undefined || filterProperty === null){
      filterProperty = 'username';
      this.filterBy = 'username';
    }

    // Switch if user enter more filter, press backspace or delete filter
    switch(true){

      case (this.filterCount > this.lastFilterCount):
        this.distributors.distributorUsersArray = this.distributors.distributorUsersArray.filter( v => {
          if(v[filterProperty] && typeof v[filterProperty] === 'string'){
            return v[filterProperty].toLowerCase().includes(this.userFilter);
          }else{
            return false;
          }
        });
        this.lastFilterCount = this.filterCount; // Set the count of the user filter
        break;

      case (this.filterCount < this.lastFilterCount):
        this.resetUserArray(); // Reset the array of bin models
        // Filter the array of bin models
        this.distributors.distributorUsersArray = this.distributors.distributorUsersArray.filter( v => {
          if(v[filterProperty] && typeof v[filterProperty] === 'string'){
            return v[filterProperty].toLowerCase().includes(this.userFilter);
          }else{
            return false;
          }
        });
        this.lastFilterCount = this.filterCount; // Set the count of the user filter
        break;

      case (this.isFilter === false):
        this.resetUserArray(); // Reset the array of bin models
        this.filterCount = 0;
        this.lastFilterCount = 0;
        break;
    }
  }

  onSortChange(caller: string, event: any){
    // Reset sortBy variable to hide the toggle switch when user press the X in the select
    if(event === undefined){
      this.isSortBy = false;
    }else{
      this.isSortBy = true;
    }

    // Switch the value of ascending or descending
    if(caller === 'switch'){
      if(this.ascDesc === 0){
        this.ascDesc = 1;
      }else{
        this.ascDesc = 0;
      }
    }

    // Avoid sortBy to have no value
    if(this.sortBy === ''){
      this.sortBy = 'username';
    }

    // Function that sort the list
    this.distributors.distributorUsersArray.sort((a, b) => {
      switch(this.sortBy){
        case 'username':
          return this.ascDesc === 0 ? b.username.localeCompare(a.username) : a.username.localeCompare(b.username);

        case 'given_name':
          return this.ascDesc === 0 ? b.given_name.localeCompare(a.given_name) : a.given_name.localeCompare(b.given_name);

        case 'family_name':
          return this.ascDesc === 0 ? b.family_name.localeCompare(a.family_name) : a.family_name.localeCompare(b.family_name);
      }
    });
  }

  // Function called to get the style of the columns of the grid list
  getColumnStyle(): any {
    const numberOfColumn = this.ajustNumberOfColumns(this.getScreenWidth());
    if(numberOfColumn){
      if(this.distributors.distributorUsersArray.length >= numberOfColumn){
        const gridColumns = `repeat(${numberOfColumn}, minmax(0, 350px))`;
        return {
          'grid-template-columns': gridColumns
        };
      }else{
        return {
          'display': `flex`,
          'justify-content': `center`,
          'margin': '0 -10px'
        };
      }
    }
  }

  // function called to adjust the number of column of the grid depbending of inner window width
  ajustNumberOfColumns(width: number): number | undefined{
    // Return the floor of the inner page width divided by 450 px as the width of the tile size
    return Math.floor((width / 350) * (90 / 100));
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }

  goToUserUpdate(user:string, username: string){
    let userType = environment.users.supplier;
    this.route.navigate(['./user-update-distributor'], {
      queryParams: {
        user,
        username,
        userType
      }
    })
  }

  backToDashboardList(){
    this.route.navigate(['./dashboard']);
  }
}
