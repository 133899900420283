import { Component, OnInit, HostListener } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ThemeService } from './service/theme.service';
import { ModalService } from './service/device-modal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers : []
})

export class AppComponent {


  // Application title
  title = 'IFM2';

  // Status of side navigation (open/closed)
  sideNavStatus: boolean = false;

  // Screen width and height variables
  getScreenWidth: any;
  getScreenHeight: any;

  // Screen width threshold for small screen
  screenWidthDeadLine: number = 450;

  // Flag for small screen
  smallscreen: boolean = false;

  //  Flag for mobile device
  isMobile: boolean = false;

  constructor(public router: Router, public theme: ThemeService,
    public modal: ModalService) {}

  ngOninit() {
    // Check if the current page is the login page
    this.IsLoginPageActive();

    // Get the initial screen width and height
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    // Check and set the initial small screen status
    this.checkScreenWidth();
    this.smallscreen = false;
  }

  ngAfterViewInit() {
    // Check the screen width after the view has been initialized
    this.checkScreenWidth();
  }

  // Host listener: Listen for window resize events
  @HostListener('window:resize', ['$event'])onWindowResize() {

    // Update screen width and height on window resize
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    // Check and update small screen status
    this.checkScreenWidth();
  }

  // Function to check the screen width and update small screen status
  checkScreenWidth() {
    if (this.getScreenWidth <= this.screenWidthDeadLine){
       // If screen width is below the threshold, set smallscreen flag to true
      this.smallscreen = true;
    } else {
      // Otherwise, set smallscreen flag to false
      this.smallscreen = false;
    }

    if(!this.smallscreen) {
      // If not a small screen, implement logic to show mobile view here...
    }
  }

  // Function to check if the current page is the login page
  IsLoginPageActive() {
    // Get the complete URL of the current page
    const url = window.location.href;

    // Check if the URL includes the string 'login'
    // This indicates whether the current page is the login page
    return url.includes('login')
  }
}
