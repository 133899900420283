import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartService } from '../service/chart.service';
import { ThemeService } from '../service/theme.service';

@Component({
  selector: 'app-bar-chart-admin',
  templateUrl: './bar-chart-admin.component.html',
  styleUrls: ['./bar-chart-admin.component.css', '../../charts.css']
})
export class BarChartAdminComponent {

  constructor(public charts: ChartService,
    public theme: ThemeService){
    //Declare the data points in a constant
    const single = charts.singleBarChart
    //Assign data to the Graph object
    Object.assign(this, { single });
}

//Prevents decimal points from appearing in axis labels
axisFormat(val:any) {
  if (val % 1 === 0) {
    return val.toLocaleString();
  } else {
    return '';
  }
}
}
