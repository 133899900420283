import { Component } from '@angular/core';
import { BinsService } from '../service/bins.service';
import { ValidationService } from '../service/validation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalAlertService } from '../service/alert-modal.service';
import { formatDate } from '@angular/common';
import { ThemeService } from '../service/theme.service';
import { CognitoService } from '../service/cognito.service';
import { environment } from '../environments/environment';
import { SystemMessageService } from '../service/system-message.service';
import { RoleService } from '../service/role.service';

@Component({
  selector: 'app-bin-model-update',
  templateUrl: './bin-model-update.component.html',
  styleUrls: ['./bin-model-update.component.css','../../global-elements.css']
})
export class BinModelUpdateComponent {

  public bin_model_id: string = '';

  // Variable to made the volume calcul
  public selectedShape: string = '';
  public width: string = '';
  public height: string = '';
  public depth: string = '';
  public totalVolume: string = '';
  public noShapeSelected: boolean = false;
  public validVolume: boolean = false;

  // Variable to show the error message of empty input at the submit click
  public showErrorMessageEmptyInput: boolean = false;

  // Variables for to validation of the update
  public validateCreate: boolean = false;
  private systemMessage: string = '';

  constructor(public bin: BinsService,
              public validationService: ValidationService,
              private route: Router,
              public alert: ModalAlertService,
              private activatedRoute: ActivatedRoute,
              public theme: ThemeService,
              public cognitoService: CognitoService,
              private systemMessageService: SystemMessageService,
              private roleService: RoleService){

  }

  async ngOnInit(): Promise<void> {
    // Get the param that was pass from the bin-list of the bin_id selected
    this.activatedRoute.params.subscribe(params => {
      this.bin_model_id = params['id']; // Set bin_model_id to the param:id receive whit url
    });

    // Get the selected bin-model data
    await this.bin.getBinModelById(this.bin_model_id);
    // Get cuurent user validation and types from cognito
    await this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();

    // Get current user roles from DB
    await this.roleService.getRoles();

    // Validate user role for the page
    await this.cognitoService.getCurrentRole([environment.users.role.administrator], [environment.users.superAdmin, environment.users.supplier], true, this.roleService.roles);

    // Check witch user type is the current user and get only infos he can associate bins
    switch(this.cognitoService.userType){
      // Remove the break because each one of hte two user type get the client list
      case 'muirwood':
        // Distributor user will be abble to associate a bin to his own distributor ID or clients
        await this.cognitoService.getDistributorsInfos();
        break;
    }
    // Set the variables for all inputs in the page. Input are string for UI needs and check if there's a value from the DB first to avoid breaking code
    if(this.bin.bin_model_data.bin_width){
      this.width = this.bin.bin_model_data.bin_width.toString();
    }
    if(this.bin.bin_model_data.bin_height){
      this.height = this.bin.bin_model_data.bin_height.toString();
    }
    if(this.bin.bin_model_data.bin_depth){ // If the bin model is a cylinder there will be no depth even in the data base. Will be set at null by default
      this.depth = this.bin.bin_model_data.bin_depth.toString();
    }
    if(this.bin.bin_model_data.total_volume){
      this.totalVolume = this.bin.bin_model_data.total_volume.toString();
    }

    this.selectedShape = this.bin.bin_model_data.bin_shape;

    // Function trigger when user get alerted if he change active status then click on cancel
    this.alert.cancel$.subscribe(() => {
      let checkboxStatus = document.getElementById('checkboxStatus') as HTMLInputElement; // Set the a html variable of the checkbox for the status
      this.bin.bin_model_data.active = this.alert.active; // Set the bin_model_data active status to the good value

      // Will reset the active status to what it was before the alert was throw then set the checkbox to the proper value
      if(this.alert.active === 1 && checkboxStatus){
        checkboxStatus.checked = true;
      } else if(this.alert.active === 0 && checkboxStatus){
        checkboxStatus.checked = false;
      }
    });

    // Function automatically trigger when user click on confirm into the alert modal
    this.alert.confirmBinModel$.subscribe(async () => {
      this.bin.bin_model_data.active = this.alert.active; // Set the bin_model_data active status to the good value
    });
  }

  // Function called when the user submit the update
  async onSubmit(){
    // Create a variable whit the current date
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'yyyyMMdd', 'en_US');

    // Set created and modified date to current date, modified is required event if it's not so the query won't break
    this.bin.bin_model_data.created = parseInt(formattedDate, 10);
    this.bin.bin_model_data.modified = parseInt(formattedDate, 10);

    // If the total is displayed, alle the inputs will be entered so we implement all bin_model_data stuff
    this.bin.bin_model_data.bin_width = parseInt(this.width);
    this.bin.bin_model_data.bin_height = parseInt(this.height);
    this.bin.bin_model_data.bin_depth = parseInt(this.depth);
    this.bin.bin_model_data.bin_shape = this.selectedShape;
    this.bin.bin_model_data.total_volume = parseFloat(this.totalVolume.split(' ')[0]); // I split this.totalVolume because we put a string at the end for the display ' L'

    // Call the function in addition of validation service to check if input are all filled
    this.checkValidation();
    // if checkValidation put validateCreate at true
    if(this.validateCreate){
      // Call the function that create the bin in bin service
      await this.bin.updateBinModel();
      this.returnToAdmin(); // Return to admin
    } else {
      // If system message have nothing it put a generic message
      if(this.systemMessage === ''){
        this.systemMessage = 'alert-danger-generic-message';
      }
      // Set the system message service to display the error to the user
      this.systemMessageService.selectRibbon('danger', this.systemMessage);
    }
  }

  // Function called if user change the selection of the shape
  shapeChange(){
    this.width = '';
    this.height = '';
    this.depth = '';
    this.totalVolume = '';
  }

  // Function called in addition of validate service to check if all input are filled
  checkValidation(){
    if(this.totalVolume !== '' && this.height !== '' && this.bin.bin_model_data.bin_model_number !== '' && this.bin.bin_model_data.above_ground !== null && this.bin.bin_model_data.bin_shape !== ''){
      this.validateCreate = true; // If all the field are well filled will put this variable to true, then it will be used in onSubmit function
    }else{
      this.systemMessage = 'fieldEmptyOrIncorrect';
    }
  }

  // Implemented by cancel button to return to the admin component
  returnToAdmin(){
    // Put a sessions store variable so admin component could know to return on bin-list
    sessionStorage.setItem("previous", "bin-model-list");
    sessionStorage.setItem("from", "bin-model-update"); // Variable used by bin-model-list html to show the proper message on success updating bin model
    this.route.navigate(['/admin']); // Return to admin component
  }

  returnToDistributorBinModel(){
    this.route.navigate(['/distributor-bins-model-dashboard']);
  }

  trackByDistributorId(option: any): string {
    return option.distributor_id;
  }
}
