import { Injectable } from '@angular/core';
import { Devices, IotService, MultipleDevices } from './iot.service';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { BinDetails } from './bins.service';
import { SystemMessageService } from './system-message.service';

export interface DeviceAssociation {
  thingName: string;
  client_id: string;
  bin_id: string;
  bin_depth: number;
}

export interface DeviceClient {
  thingName: string;
  client_id: string;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  // Store the name of the selected device as a string
  public selectedDeviceString: string = "";

  // Variable used only to show the app-device-modal
  public showDeviceModal: boolean = false;

  // Flags to control the visibility of different modals
  public showSingleDeviceModal: boolean = false;
  public showMultipleDevicesModal: boolean = false;
  public showJsonShadow: boolean = false;
  public showRelationship: boolean = false;

  // Variable that hold thingShadow in a json and thing name to show shadow of the thing in a modal
  public thingShadow: any;
  public thingName: string = '';
  public jsonStringTrimmed: string = '';
  public formattedJson: string = '';

  // Store the selected device as an object of type 'Devices'
  public selectedDevice: Devices = {} as Devices;

  // Store an array of selected multiple devices
  public selectedMultipleDevices: MultipleDevices[] = []

  // Store an array of names of selected multiple devices
  public selectedMultipleDevicesNames: any[] = []

  // A flag to check if a device has been selected
  public check: boolean = false;

  public twoTimeSameThing: boolean = false;

  public thingStatus: string = "";

  // Variable for the open map on modal
  private showMap = new Subject<void>();
  private resetMap = new Subject<void>();

  // Variable for device association
  public tempDeviceAssociation: any;
  public deviceClient: DeviceClient = {
    thingName: '',
    client_id: ''
  }
  public deviceAssociationRow: any;
  public userType: string = '';
  public userRole: any;
  public clientsArray: any;
  public binArray: BinDetails[] = [];
  public distributorsArray: any;

  public deviceAssociation: DeviceAssociation = {
    thingName: '',
    client_id: '',
    bin_id: '',
    bin_depth: 0
  }

  constructor(private http: HttpClient
              ) {

  }

  getStatus(status: string) {
    this.thingStatus = status
  }

  /**
   * Open a modal for a single device.
   * @param device - The device to display in the modal.
  */
  public openModalDevice(device: Devices){

    // Set the selected device
    this.selectedDevice = device;

    // Variable
    this.showDeviceModal = true;

    // Hide the multiple devices modal and show the single device modal
    this.showMultipleDevicesModal = false;
    this.showSingleDeviceModal = true;
  }

  // Variable that iotComponent gonna see to know to trigger his fonction
  showMap$ = this.showMap.asObservable();

  showDeviceMap(){// Called from device-modal.html to trigger iotComponent show marker on map
    this.showMap.next();
  }

  // Variable that iotComponent gonna see to know to trigger his fonction
  resetMap$ = this.resetMap.asObservable();

  resetDeviceMap(){ // Called from device-modal.html to reset the map when you exit the modal
    this.resetMap.next();
  }

  // Get bin depth based on provided bin ID
  obtainBinDepthByBinId(binIdProvided: string): number | null {

    // Find the object in the array based on bin_id
    const foundBin = this.binArray.find(bin => bin.bin_id === binIdProvided);

    // Check if the bin was found
    if (foundBin) {

        // Set the deviceAssociation's bin_depth to the bin_height from the found bin
        this.deviceAssociation.bin_depth = foundBin.details.bin_height;

        // Return the value of bin_depth if it exists, otherwise return null
        return foundBin.details.bin_height ?? null;
    } else {
        // Return null if the bin is not found
        return null;
    }
  }

  /**
   * Open a modal for selecting from multiple devices.
   * @param devices - The array of multiple devices to choose from.
   * @param deviceName - The name of the device.
  */
  public openModalMultipleDevices(devices: MultipleDevices, deviceName: string){
    // Add the device name to the list of selected multiple devices' names
    this.selectedMultipleDevicesNames.push(deviceName)

    // Add the selected multiple devices to the list
    this.selectedMultipleDevices.push(devices)

    // Show the multiple devices modal
    this.showMultipleDevicesModal = true;
  }

  // Function called when user want to show the shadow of the device
  showShadow(thingShadow: any, thingName: string){
    this.thingShadow = thingShadow;
    this.thingName = thingName;

    this.showDeviceModal = true;
    this.showJsonShadow = true;
  }

  // Function called when user want to show the relationships of the device client and bins
  async showRelation(thingName: string){
    this.thingName = thingName;

    // Set the HTML show/hide variable to true to show the modal
    this.showDeviceModal = true;
    this.showRelationship = true;
  }

  convertShadowToJson() {
    const json = JSON.stringify(this.thingShadow, null, 2).replace(/\\n/g, '\n');

    return json;
  }

  /**
   * Close the modals and reset selected devices.
  */
  closeModal(){
    // Clear the arrays storing selected multiple devices and their names
    this.selectedMultipleDevices = [];
    this.selectedMultipleDevicesNames = [];

    // Hide both the single device modal and the multiple devices modal
    this.showDeviceModal = false;
    this.showMultipleDevicesModal = false;
  }

  /**
   * Retrieves a device using its name from a list of selected multiple devices.
   * @param name - The name of the device to find.
  */
  public getDeviceUsingName(name: string) {
    // Filter the selected multiple devices to find a device with the given name
    const matchingDevices = this.selectedMultipleDevices.filter(device => {
      // Use the some function to check if any device has the given name
      return device.devices.some(deviceItem => deviceItem.thingName === name);
    });

    // Check if matching devices were found
    if (matchingDevices) {

      // Set the selected device to the first device in the matching devices list
      this.selectedDevice = matchingDevices[0].devices[0];

      // Set a flag to indicate that a device was found
      this.check = true;

    }
  }

  // Function called when the "Ok" button is clicked in a modal
  public onOkClick() {
    // Call the 'getDeviceUsingName' function to find the selected device by its name
    this.getDeviceUsingName(this.selectedDeviceString)

    // Check if a matching device was found
    if (this.check) {
      // Call a function to open a modal for the selected device
      this.openModalDevice(this.selectedDevice);
    }
  }
}


