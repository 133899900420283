import { Component, OnInit, HostListener, Inject, forwardRef  } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../service/theme.service';
@Component({
  selector: 'app-configs',
  templateUrl: './configs.component.html',
  styleUrls: ['./configs.component.css']
})

export class ConfigsComponent implements OnInit {
  // Variable to store the language selected value
  public selectedLanguage: string = "";

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  constructor(
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private localStorageService: LocalStorageService,
    public theme: ThemeService
  ){
    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

   // Main content area identifier
  public mainContent: string = '';

  // User filter input
  public userFilterTest: string ="";

  // Filter state and list display state
  public filterOn: boolean = false;
  public showList: boolean = false;

  // Loading state for more data
  public loadingMoreData = false;

  // Active state for different buttons
  public isDeviceTypesActive: boolean = false;
  public isVariablesActive: boolean = false;
  public isMetricsActive: boolean = false;
  public isEventsActive:boolean = false;
  public isJobsActive: boolean = false;
  public isIssuesActive: boolean = false;
  public isSkillsActive: boolean = false;


  ngOnInit(): void {
    // Initialize the component with the "devicesTypes" content
    this.devicesTypesClicked();
  }

  // Change language based on user selection
  changeLanguage(language: string) {
    this.translate.use(language);
  }

  // Function to load initial data
  loadInitData(): void {
  }

  // // Function to clear the state of all buttons
  clearButtonsStats() {
    // Reset the active state for each button to false
    this.isDeviceTypesActive = false;
    this.isVariablesActive = false;
    this.isMetricsActive = false;
    this.isEventsActive = false;
    this.isJobsActive = false;
    this.isIssuesActive = false;
    this.isSkillsActive = false;
  }

  // Functions to handle click events on menu
  devicesTypesClicked(){ // show devices types content
    // Clear the status of other buttons or states
    this.clearButtonsStats();

    // Set the status of the "Device Types" button as active
    this.isDeviceTypesActive = true;

    // Set the main content to be displayed as "devicesTypes"
    this.mainContent = "devicesTypes";
  }

  variablesClicked() { // show variables content
    // Clear the status of other buttons or states
    this.clearButtonsStats();

    // Set the status of the "Variables" button as active
    this.isVariablesActive = true;

    // Set the main content to be displayed as "variables"
    this.mainContent = "variables"
  }

  metricsClicked() { // show metrics content
    // Clear the status of other buttons or states
    this.clearButtonsStats();

    // Set the status of the "Metrics" button as active
    this.isMetricsActive = true;

    // Set the main content to be displayed as "metrics"
    this.mainContent = "metrics"
  }

  eventsClicked() { // show events content
    // Clear the status of other buttons or states
    this.clearButtonsStats();

    // Set the status of the "Events" button as active
    this.isEventsActive = true;

    // Set the main content to be displayed as "events"
    this.mainContent = "events"
  }

  jobsClicked() { // show jobs content
    // Clear the status of other buttons or states
    this.clearButtonsStats();

    // Set the status of the "Jobs" button as active
    this.isJobsActive = true;

    // Set the main content to be displayed as "jobs"
    this.mainContent = "jobs"
  }

  issuesClicked() { // show issues content
    // Clear the status of other buttons or states
    this.clearButtonsStats();

    // Set the status of the "Issues" button as active
    this.isIssuesActive = true;

    // Set the main content to be displayed as "issues"
    this.mainContent = "issues"
  }

  skillsClicked() { // show skills content
    // Clear the status of other buttons or states
    this.clearButtonsStats();

    // Set the status of the "Skills" button as active
    this.isSkillsActive = true;

    // Set the main content to be displayed as "skills"
    this.mainContent = "skills"
  }

  // Event listener for scroll - used to load more data to list
  @HostListener('window:scroll', []) onScroll(): void {
    // code to handle the scroll event

    // Determine the height of the visible window area
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;

    // Get references to the body and HTML elements
    const body = document.body;
    const html = document.documentElement;

    // Calculate the maximum height of the document
    const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    // Calculate the current scroll position
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
  }

}
