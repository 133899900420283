import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../environments/environment';
import { map, catchError } from 'rxjs/operators'; // Import map from 'rxjs/operators'
import { ChartService } from './chart.service';
import { DashboardService } from './dashboard.service';

export interface Client {
  clientName: string;
  legalName: string;
  clientLogo: string;
  phoneNumber: string;
  email: string;
  address: string;
  created: string;
}

interface UserObject {
  created: string;
  modified: string;
  address: string;
  client_id: string;
  current_role: string;
  distributor_id: string;
  email: string;
  enable: string;
  family_name: string;
  given_name: string;
  operator_id: string;
  phone_number: string;
  user: string;
  user_type: string;
  username: string;
}

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  clientData = {
    client_id: "",
    client_name: "",
    legal_name: "",
    phone_number: "",
    email: "",
    address: "",
    created: "",
    modified: "",
    distributor_id: ""
  };

  // array to keep the client data
  public clientsArray: any;

  public clientsUsersArray: any[] = [];


  public clientLegalname: any;

  public dateFixed: any;
  public binArray: any[] = [];

  public array: any;

  // For client dashboard
  public clientBinModelCount: number = 0;
  public clientBinCount: number = 0;
  public clientClientCount: number = 0;
  public clientBinModelArray: any[] = [];
  public clientBinArray: any[] = [];
  public clientDeviceArray: any[] = [];
  public clientDeviceCount: number = 0;
  public clientAdminCount: number = 0;
  public clientDistributorCount: number = 0;
  public clientOperatorCount: number = 0;
  public clientCollectionCount: number = 0;

  // For admin dashboard
  public userCount: number = 0;
  public binCount: number = 0;
  public binModelCount: number = 0;
  public taxTypeCount: number = 0;
  public clientCount: number = 0;
  public distributorCount: number = 0;
  public operatorCount: number= 0;
  public deviceCount: number = 0;
  public healthyDeviceCount: number = 0;
  public unhealthyDeviceCount: number = 0;
  public healthyDeviceArray: any = [];
  public unhealthyDeviceArray: any = [];
  // public relationships: BinArrayItem[] = [];

  public stopLoading: boolean = false;
  public healthy: number = 0;
  public unhealthy: number = 0;
  public inactive: number = 0;


  constructor(private http: HttpClient,
    private charts: ChartService,
    // public dashboardService: DashboardService
    ) {}



  // async initializaClientDashboardData() {
  //   this.healthy = 0;
  //   this.unhealthy = 0;
  //   this.inactive = 0;
  //   // await this.dashboardService.countApplicationDevicesAndParameters();

  //   // (await this.getRelationships()).subscribe();

  //   // await this.countApplicationBinModels();
  //   // await this.countApplicationBins();
  //   // await this.countApplicationClients();

  //   // await this.countApplicationDevicesAndParameters();
  //   // await this.countApplicationDistributors();
  //   // await this.countApplicationOperators();
  //   // await this.countApplicationUsers();
  //   // await this.countTaxTypes();


  //   this.charts.singlePieChart = [
  //     { "name": await this.charts.getChartLabel('chartLabelHealthy'), "value": this.healthy},
  //     { "name": await this.charts.getChartLabel('chartLabelUnhealthy'), "value": this.unhealthy},
  //     { "name": await this.charts.getChartLabel('inactive'), "value": this.inactive}
  //   ];

  //   this.charts.totalPieChart = this.deviceCount;

  //   this.charts.singleBarChart = [
  //     { "name": await this.charts.getChartLabel('chartLabelClients'), "value": this.clientCount},
  //     { "name": await this.charts.getChartLabel('chartLabelDistributors'), "value": this.distributorCount},
  //     { "name": await this.charts.getChartLabel('chartLabelOperators'), "value": this.operatorCount},
  //   ];

  //   this.charts.singleBarChartAdmin = [
  //     { "name": await this.charts.getChartLabel('chartLabelBins'), "value": this.binCount},
  //     { "name": await this.charts.getChartLabel('chartLabelBinModels'), "value": this.binModelCount},
  //     { "name": await this.charts.getChartLabel('chartLabelUsers'), "value": this.userCount},
  //     { "name": await this.charts.getChartLabel('chartLabelTaxTypes'), "value": this.taxTypeCount},
  //   ];
  //     // Assuming your divs have a class named 'chart-container'
  //   var targetDivs = document.querySelectorAll('.chart-container');

  //   // Check if there are elements with the specified class
  //   if (targetDivs.length > 0) {
  //       // Iterate through the NodeList and set the display property to flex for each element
  //       targetDivs.forEach(function(div) {
  //         if (div instanceof HTMLElement) {
  //             div.style.display = 'flex';
  //         }
  //     });
  //   } else {
  //       console.error('No elements found with the specified class');
  //   }

  //   this.stopLoading = true


  //////////////// Those functions are called when we get a response from lambdas function to transfert some of the keys from receiving JSON in Integer /////////////////
  //////////////// Instead of changing all stuff in app for the integer we change the data directly after receiving them /////////////////////////////////////////////

  switchJSONToIntForClientAndUserResponse(element: any){
    // Tableau of witch keys we need to check
    const keysToCheck = [
      'created',
      'modified'
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  switchJSONToIntForBinResponse(element: any){
    // Tableau of witch keys we need to check
    const keysToCheck = [
      'active',
      'created',
      'modified',
      'threshold'
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  keyToTransfert(keysToCheck: any, element: any){
    keysToCheck.forEach((key: any) => {
      // Vérifier si la clé existe dans l'élément et si sa valeur est une chaîne
      if (typeof element[key] === 'string') {
          // Convertir la valeur de la clé en entier
          element[key] = parseInt(element[key], 10);
      }
    });
  }



  // }

  getClients(){
     // Append the 'user' parameter to the URL as a query string
     const url = environment.api.stage + environment.api.route.getClients;
     return this.http.get(url).pipe(
       map((response) => {
         // return the response data
         this.clientsArray = response;
        // For each element of the array, we'r gonna transfer the JSON received in integer for some of the keys
         this.clientsArray = this.clientsArray.forEach((element: any) => {
            this.switchJSONToIntForClientAndUserResponse(element);
         });
         return response;
       }),
       catchError((error) => {
         console.error('API Error:', error);
         throw error(error); // Re-throw the error for the calling code to handle
       }));
  }

  async getUsersByClientId(client_id: string): Promise<any[]>{
    return new Promise<any[]>((resolve) => {
      this.getUserByClientIdLambda(client_id).subscribe((response) => {

        // Put the array returned by the lambda function into an tmp array
        this.array = response;

        this.clientsUsersArray = this.array;
        resolve(this.clientsUsersArray);
      })
    });
  }

  getUserByClientIdLambda(client_id: string){
    // Set url for lambda call
    const url = environment.api.stage + environment.api.route.getUsersByClientId + "&client_id=" + client_id;

    // Will call the lambda function then return a response
    return this.http.get(url).pipe(

      map((response: any) => {
        if (Array.isArray(response)) {

          this.array = response.map((item: UserObject) => {
            return {
                created: parseInt(item.created, 10),
                modified: parseInt(item.modified, 10),
                address: item.address,
                client_id: item.client_id,
                current_role: item.current_role,
                distributor_id: item.distributor_id,
                email: item.email,
                enable: item.enable,
                family_name: item.family_name,
                given_name: item.given_name,
                operator_id: item.operator_id,
                phone_number: item.phone_number,
                user: item.user,
                user_type: item.user_type,
                username: item.username

            };
        });
          return this.array;
        } else {
          console.error('Invalid response format:', response);
          throw new Error('Invalid response format');
        }
      }),
      catchError((error) => {
        if (error.status === 404) {
          return of([]);
        } else {
          console.error('API Error:', error);
          throw error; // Re-throw the error for the calling code to handle
        }
      })
    );
  }

  createClient(): Observable<any>{

     // Define the HTTP headers with content type
     const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    return this.http.post(environment.api.stage + environment.api.route.createClient,
      {
        "client_name": this.clientData.client_name,
        "legal_name": this.clientData.legal_name,
        "phone_number": this.clientData.phone_number,
        "email": this.clientData.email,
        "address": this.clientData.address,
        "created": this.clientData.created,
        "modified": this.clientData.modified
      }, {headers: headers}
    );
  }

  //Update Client with an observable function
  updateClient(): Observable<any>{

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
     'Content-Type':  'application/json' // Adjust content type as needed
   });

   return this.http.post(environment.api.stage + environment.api.route.updateClient,
     {
       "client_id": this.clientData.client_id,
       "client_name": this.clientData.client_name,
       "legal_name": this.clientData.legal_name,
       "phone_number": this.clientData.phone_number,
       "email": this.clientData.email,
       "address": this.clientData.address,
       "created": this.clientData.created,
       "modified": this.clientData.modified
     }, {headers: headers}
   );

 }

 // Function async used for select in modals to insure that select are implemented
 getClientsForSelect(): Promise<any[]>{
  return new Promise<any[]>((resolve) => {
    // Call the function that call the lambda function
    this.getClients().subscribe((response) => {
      this.clientsArray = response;
      resolve(this.clientsArray);
    });
  });
  }

  // Function called to get all bins for a client id
  getBinByClientId(clientId: string){
    // Initiate url whit the url of lambda fonction getBins
    const url = environment.api.stage + environment.api.route.getBinsByClientId + "&client_id=" + clientId;
    // Call the lambda fonction whit the url
    return this.http.get(url).pipe(
      map((response) => {
        this.array = response;

        this.array = this.array.forEach((element: any) => {
          this.switchJSONToIntForBinResponse(element);
        });
        return response;// Return response data
      }),
      catchError((error) => {
        //console.error('API Error : ' + error);
        throw error;
      })
    );
  }
}

