import { Injectable } from '@angular/core';
import { NotificationTypes } from '../constants/notification-types';

export interface Notification {
  notificationType: string;
  notificationTitle: string;
  notificationBody: string;
  timestamp: string;
  icon: string;
  displayed: boolean; // Added property to track displayed state
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notifications: Notification[] = [];

  private notificationsArray: any[] = [
    {
      notificationType: "urgent",
      notificationTitle: "Repairs!",
      notificationBody: "This is an example of the notification description. We just need to be sure all this text is displayed.",
      timestamp: "1695158984",
      //displayed: false,
    },
    {

      notificationType: "device",
      notificationTitle: "Device Offline!",
      notificationBody: "This is an example of the notification description. We just need to be sure all this text is displayed.",
      timestamp: "1695158984"

    },

    {

      notificationType: "billing",
      notificationTitle: "Invoice Ready!",
      notificationBody: "This is an example of the notification description. We just need to be sure all this text is displayed.",
      timestamp: "1695158984"

    },

    {

      notificationType: "work_order",
      notificationTitle: "Collection Route",
      notificationBody: "This is an example of the notification description. We just need to be sure all this text is displayed.",
      timestamp: "1695158984"
    }
  ];

  constructor() { }

  getNotifications(): Notification[] {
    this.notifications = [];

    for (const notificationData of this.notificationsArray) {
      const { notificationType, notificationBody, notificationTitle, timestamp, displayed } = notificationData;

      if (!displayed) {
        const filteredNotifications = NotificationTypes.filter((notification) =>
          notification.value === notificationType
        );

        if (filteredNotifications.length > 0) {
          const newNotification = {
            icon: filteredNotifications[0].icon,
            notificationBody,
            notificationTitle,
            notificationType,
            timestamp: this.getTimestampDisplay(timestamp),
            displayed: true,
          };

          this.notifications.push(newNotification);
        }
      }
    }

    return this.notifications;
  }

  private getTimestampDisplay(timestamp: string): string {
    // Implement logic to format timestamp in hours/days
    // You can use libraries like moment.js or Angular DatePipe for this
    // For simplicity, we'll just return the timestamp as is in this example
    return timestamp;
  }
}

