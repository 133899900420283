import { Injectable } from '@angular/core';
import { navigation } from '../constants/navigation';
import { CognitoService } from './cognito.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  list: any[] = [];
  listFull: any[] = navigation;

  constructor(private cognito: CognitoService) {}

  public async filterList(): Promise<any> {
    const filteredList: any[] = [];
    const promises: any[] = [];

    for (const item of this.listFull) {
      const promise = await this.validateListItem(
        item.validation,
        item.userRole,
        item.userType
      )
        .then((result) => {
          if (result) {
            filteredList.push(item);
          }
        })
        .catch((error) => {
          // Handle error if needed
        });

      promises.push(promise);
    }

    await Promise.all(promises);

    this.list = filteredList.sort(this.sortByNumberAscending);
  }

  //Validate the list item based on user role/type
  async validateListItem(
    validation: boolean,
    userRole: string = '',
    userType: string = ''
  ): Promise<boolean> {
    if (validation) {
      let allowed = false;
      await this.cognito.getCurrentRole([userRole], [userType], false);
      allowed = this.cognito.userAllowed;

      return allowed;
    } else {
      return true;
    }
  }

  //order array of list items based on the "number" attribute
  sortByNumberAscending(a: any, b: any): number {
    const numberA = parseInt(a.number);
    const numberB = parseInt(b.number);

    return numberA - numberB;
  }
}
