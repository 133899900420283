import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeService } from '../service/theme.service';
import { DashboardService } from '../service/dashboard.service';
import { IotService, MultipleDevices } from '../service/iot.service';
import { ChartService } from '../service/chart.service';
import { ZoneService } from '../service/zone.service';
import { CognitoService } from '../service/cognito.service';
import { environment } from '../environments/environment';
import { Devices } from '../service/iot.service';

interface ZoneItem {
  strokeColor: string;
  zoneName: string;
  clientId: string,
  client_name: string,
  fillColor: string,
  patch: [],
  markers: [],
  active: ""
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrl: './map.component.css'
})
export class MapComponent implements OnInit {

  public map: any = {};
  public showCreateZoneModal: boolean = false;
  public showUpdateZoneModal: boolean = false;
  isSmallScreen: boolean = this.getScreenWidth() <= 859;
  public navigationItemsArray: any = [];
   // Initialize an array to store GPS data
   public gpsArray: any[] = [];
   public newClientArray: any[] = [];
   public clientsData: any[] = [];
   public zonesData: any[] = [];
   public patchArray: any[] = [];
   public globalPath: any[] = [];
   public dots: any[] = [];
   public clientArray: any[] = [];
   public zonesArray2: any[] = [];
   public zonesArray: ZoneItem[] = []

   // when true show all zones whitin zonesArray
  public showAllZones: boolean = true;

  // Initialize isCustomComponent as true
  public isCustomComponent: boolean = true;

  public enable: any;

  // Initialize lastLocationgroup
  public lastLocationgroup: any;

  // Initialize LATITUDE with 0
  public LAT: number = 0;

  // Initialize LONGITUDE with 0
  public LNG: number = 0;

  // the value will be updated according to the zone chosen for visualization by the user
  public chosenZone: number = 3;

  // Initialize array of Devices
  public array: Devices[] = [];

  // Initialize an array for MultipleDevices
  public myDevicesArray: MultipleDevices[] = [];




  constructor(public theme: ThemeService,
    public dashboard: DashboardService,
    public devices: IotService,
    public charts: ChartService,
    public iotService: IotService,
    public zoneService: ZoneService,
    public cognitoService: CognitoService
   ){

    }
    async ngOnInit(){
      //Verifies that the current User is allowed to view this component
      this.cognitoService.getCurrentRole([environment.users.role.administrator],[environment.users.superAdmin]); //Role Check

      this.initialize();
      await this.getZonesList();


    }


    async initialize(){
      await this.dashboard.initializeDashboardData();
    }

      // Method to check if the screen meets the condition for applying a new layout
      shouldApplyNewLayout(): boolean {
        // Return true if either the showUpdateZoneModal or showCreateZoneModal is true
        // and the screen size is considered small (based on the isSmallScreen flag)
        return (this.showUpdateZoneModal || this.showCreateZoneModal) && this.isSmallScreen;
      }

        // Event listener for window resize
      @HostListener('window:resize', ['$event'])
      onResize(event: any): void {
          // Check the screen width and update the isSmallScreen flag accordingly
          this.isSmallScreen = this.getScreenWidth() <= 859;
      }

      // Function to get the current screen width
      getScreenWidth(): number {
        // Return the inner width of the window as the screen width
        return window.innerWidth;
      }

    // Function to create a Google Map
    // Takes a center object with latitude (lat) and longitude (lng) properties
    // Returns a Google Map instance
    createGoogleMap(center: { lat: number, lng: number }): google.maps.Map {
      console.log("map called")
      // Create a new Google Map instance, passing the HTML element with the id "mainMap" as the map container
      // Set initial zoom level to 2.5, center the map at the specified coordinates, and use the map with the ID 'DEVICES_MAP'
      // Disable default UI elements for a cleaner map interface
      return new google.maps.Map(document.getElementById("mainMap") as HTMLElement, {
          zoom: 3,
          center: center,
          mapId: 'DEVICES_MAP',
          disableDefaultUI: true,
      });
    }

    // Initialize Google Maps
    async initMap(): Promise<void> {

      // Initialize variables for drawing polygon and storing polygon path
      let drawingPolygon: google.maps.Polygon | null = null;
      let polygonPath: google.maps.LatLng[] = [];

      // Defines the initial center of the map
      let center = { lat: 55.93003396123511, lng: -7.835004784029504 };

      // Create Google Map with the specified center
      let map = this.createGoogleMap(center);

      // Display markers on the map
      await this.displayMarkers(map);

      // Display predefined zones on the map
      this.displayZones(map);

    }

    // Asynchronously fetch a list of things and device information from the IoT service
    async displayMarkers(map: google.maps.Map): Promise<void> {
      // Retrieve the list of things and device information
      const thingsList = await this.iotService.listThings();
      const deviceList = await this.iotService.getDeviceList();

      // Loop through the list of things to gather GPS information
      for (let i = 0; i < thingsList.length; i++) {
          let name: string | undefined;
          name = thingsList[i].thingName;
          if (name !== undefined) {
              // Retrieve the device shadow information, including GPS coordinates
              const result = await this.iotService.getDeviceShadow(name);
            //  this.gpsArray.push(result.state.reported.dat.gps);
          }
      }

      // Loop through the collected GPS coordinates to create markers on the map
      for (let i = 0; i < this.gpsArray.length; i++) {
          // Extract latitude and longitude from the GPS coordinate string
          let gpsString: string = this.gpsArray[i].toString();
          let gpsSplited = gpsString.split(",");
          let latNumber = Number(gpsSplited[0]);
          let lngNumber = Number(gpsSplited[1]);
          this.LAT = latNumber;
          this.LNG = lngNumber;

          // Define marker properties
          const marker = {
              position: { lat: this.LAT, lng: this.LNG },
              map: map,
              icon: "assets/bin.png",
              device: thingsList[i],
              gps: this.gpsArray[i],
              animation: google.maps.Animation.DROP
          };

          // Check if modals are not being displayed before creating a new marker
          if (!this.showCreateZoneModal && !this.showUpdateZoneModal) {
              // Create a new marker on the map
              const newMarker = new google.maps.Marker(marker);

              // Add a click event listener to the marker
              newMarker.addListener("click", () => {
                  // Call the hasMultipleDevices function with the corresponding GPS coordinates and the list of devices
                  this.hasMultipleDevices(this.gpsArray[i], deviceList);

                  // Set the map zoom level to 10 and center it on the clicked marker position
                  map.setZoom(10);
                  map.setCenter(newMarker.getPosition() as google.maps.LatLng);
              });
          }
      }
    }

    async displayZones(map: google.maps.Map): Promise<void> {
      // function that displays zones


          // loop through zonesArray
          this.zonesArray.forEach((zone, index) => {
              const polygon = new google.maps.Polygon({
                  paths: zone.patch, // apply path according to each zone
                  strokeColor: zone.strokeColor, // apply stroke color according to each zone
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: zone.fillColor, // apply fill color according to each zone
                  fillOpacity: 0.35,
                  editable: false,
              });
              polygon.setMap(map); // draw the polygons / zones

              // example of how to Add event listener to edit the polygon when clicked
              // google.maps.event.addListener(polygon, 'click', function (event: any) {
              //   // Make the polygon editable or non-editable based on its current state
              //   polygon.setEditable(!polygon.getEditable());
              // });

          });
    }



    async getZonesList(): Promise<void> {
      try {
        // Call the zoneService to get zones data
        this.zoneService.getZones().subscribe(
          // Successful response callback
          (response: any) => {
            // Store the API response in the zonesData array
            this.zonesData = response;

            // Map the response data to a new format for easier use
            this.zonesArray2 = this.zonesData.map(item => {
              return {
                fillColor: item.zone_color,
                patch: JSON.parse(item.zone_coordinates),
                strokeColor: item.zone_color,
                zoneId: item.zone_id,
                clientId: item.client_id,
                zoneCoordinates: JSON.parse(item.zone_coordinates),
                zoneName: item.zone_name,
                modified: item.modified,
                active: item.active
              };
            });

            // Copy zonesArray2 to zonesArray
            for (let i = 0; i < this.zonesArray2.length; i++) {
              this.zonesArray.push(this.zonesArray2[i]);
            }

            // Initialize the map
            this.initMap();

            // Remove Muirwood Studio from client list to display correctly in the selection client list
            this.clientArray = this.clientArray.filter(client => client.client_name !== '');

            // Deduplicate client data based on client_name and client_id
            this.newClientArray = this.clientArray.reduce((accumulator, item) => {
              const key = item.client_name + item.client_id;
              if (!this.map[key]) {
                this.map[key] = true;
                // Add unique clients to the newClientArray
                accumulator.push({ client_id: item.client_id, client_name: item.client_name });
              }
              return accumulator;
            }, []);
          }
        );

      } catch (error) {
        // Handle and log any errors that occur during the process
        console.error("Error: ", error);
      }
    }

    async hasMultipleDevices(array: any, devices: Devices[]){
      // Call the IOT service to fetch the device location group and return group
      // of devices with the same coordinates.
      let locationGroup = await this.iotService.getDeviceLocationGroup(array, devices);
      if (this.lastLocationgroup == locationGroup) {
      }
      // Check if 'locationGroup' is undefined
      if (locationGroup == undefined) {
        // If it's undefined, assign it the value of 'this.lastLocationgroup'
        locationGroup = this.lastLocationgroup
      } else {
        // If it's defined, update 'this.lastLocationgroup' with its value
        this.lastLocationgroup = locationGroup;
      }

      // Loop through each element in the 'locationGroup' array
      for (let i = 0; i < locationGroup.length; i++) {

        // Get the device name from the current 'locationGroup' element
        const deviceName = locationGroup[i].thingName;

        // Create an object 'multipleDevicesWithSameCoordinates' with the current device
        const multipleDevicesWithSameCoordinates: MultipleDevices = {
          devices: [locationGroup[i]]
        }

        // Push 'multipleDevicesWithSameCoordinates' into 'myDevicesArray'
        this.myDevicesArray.push(multipleDevicesWithSameCoordinates);

      }
      return locationGroup;
    }


  }

