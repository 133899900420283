<app-system-message></app-system-message>
<!-- Search input component, shown when mainContent is 'clients' -->
<div [ngClass]="theme.getThemeClass()" class="min-width-div container-fluid">
    <nav id="search-component" class="input-group" *ngIf="mainContent === 'operator-list'">
        <input #searchBox type="search"  id="search-box" [(ngModel)]="userFilter" (input)="this.filter.setCallingComponent('operator-list'); this.filter.search(userFilter, this.operator.operator_array, this.operator.getCountFilter());"
            class="form-control rounded searchInput"
            placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}" aria-label="Search" aria-describedby="search-addon" />
    </nav>

    <!-- Table header for the client list, shown when mainContent is 'clients' -->
    <heater [ngClass]="theme.getThemeClass()" class="table-header" *ngIf="mainContent === 'operator-list'">
        <span class="left">{{ 'operatorListViewOperatorList' | translate }}</span>
        <span class="right scale0-9"><a routerLink="/operator-create" href="javascript:void(0)">&#x2b; {{ 'operatorListViewNew' | translate }} </a></span>
    </heater>

    <!-- Table container for the user list, shown when mainContent is 'clients' -->
    <section [ngClass]="theme.getThemeClass()" class="table-container" *ngIf="mainContent === 'operator-list'">
        <article  class="table-responsive ">
            <table  class="table table-hover">
                <thead >
                    <tr>
                        <th [ngClass]="theme.getThemeClass()" scope="col">
                            {{ 'userlistClientsViewTableHeaderName' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" scope="col">
                            {{ 'userlistClientsViewTableHeaderPhoneNumber' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
                            {{ 'usereditFormLabelAddress' | translate }}
                        </th>
                        <th [ngClass]="theme.getThemeClass()" scope="col">
                            {{ 'iotTabHeaderResources' | translate }}
                        </th>
                    </tr>
                </thead>
                <ng-container  *ngFor="let operator of this.operator.operator_array">
                    <tbody>
                        <tr style="vertical-align: middle;">
                            <td [ngClass]="theme.getThemeClass()" [style.color]="(operator.active === '1') ? 'green!important' : 'red!important'">{{ operator.operator_name }}</td>
                            <td [ngClass]="theme.getThemeClass()">{{ operator.phone_number }}</td>
                            <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">{{ operator.address }}</td>
                            <td [ngClass]="theme.getThemeClass()">
                                <a [routerLink]="['/operator-update', operator.operator_id]"><img class="iconUpdate" src="./assets/update-pencil.png" alt="icon of update" height="30" width="30"></a>
                            </td>
                        </tr>
                    </tbody>
                </ng-container>
            </table>
          </article>
      </section>
    </div>
