<div [ngClass]="theme.getThemeClass()">
    <section>
        <!-- Message show when user want to activate/deactivate Bin -->
        <article *ngIf="this.toActiveAction && this.alert.deleteBin">
            <h3 [ngClass]="theme.getThemeClass()">{{ "binModalViewAboutToActivate" | translate }}</h3>
            <div class="content">{{ this.alert.bin_address }}</div>
        </article>
        <article *ngIf="!this.toActiveAction && this.alert.deleteBin">
            <h3>{{ "binModalViewAboutToDeactive" | translate }}</h3>
            <div class="content">{{ this.alert.bin_address }}</div>
        </article>
        <!-- Message show when user want to activate/deactivate Bin Model -->
        <article *ngIf="this.toActiveAction && this.alert.deleteBinModel">
            <h3 [ngClass]="theme.getThemeClass()">{{ "binModelModalViewAboutToActivate" | translate }}</h3>
            <div class="content">{{ this.alert.bin_model_number }}</div>
        </article>
        <article *ngIf="!this.toActiveAction && this.alert.deleteBinModel">
            <h3>{{ "binModelModalViewAboutToDeactivate" | translate }}</h3>
            <div class="content">{{ this.alert.bin_model_number }}</div>
        </article>
        <!-- Message show when user want to activate/deactivate Taxe Type -->
        <article *ngIf="this.toActiveAction && this.alert.deleteTaxeType">
            <h3 [ngClass]="theme.getThemeClass()">{{ "alertModalViewActivateTaxeType" | translate }}</h3>
            <div class="content">{{ this.alert.taxe_name | translate }} {{ this.alert.region | translate }}</div>
        </article>
        <article *ngIf="!this.toActiveAction && this.alert.deleteTaxeType">
            <h3>{{ "alertModalViewDeactivateTaxeType" | translate }}</h3>
            <div class="content">{{ this.alert.taxe_name | translate }} {{ this.alert.region | translate }}</div>
        </article>
        <!-- Message show when user want to activate/deactivate Operators -->
        <article *ngIf="this.toActiveAction && this.alert.deleteOperator">
            <h3 [ngClass]="theme.getThemeClass()">{{ "alertModalViewActiveOperator" | translate }}</h3>
            <div class="content">{{ this.alert.operator_legal_name | translate }}</div>
        </article>
        <article *ngIf="!this.toActiveAction && this.alert.deleteOperator">
            <h3>{{ "alertModalViewDeactivateOperator" | translate }}</h3>
            <div class="content">{{ this.alert.operator_legal_name | translate }}</div>
        </article>
        <!-- Message show when user want to activate/deactivate Location -->
        <article *ngIf="this.toActiveAction && this.alert.deleteLocation">
          <h3 [ngClass]="theme.getThemeClass()">{{ "alertModalViewActiveLocation" | translate }}</h3>
          <div class="content">{{ this.alert.location | translate }}</div>
      </article>
      <article *ngIf="!this.toActiveAction && this.alert.deleteLocation">
          <h3>{{ "alertModalViewDeactivateLocation" | translate }}</h3>
          <div class="content">{{ this.alert.location | translate }}</div>
      </article>
      </section>
    <nav class="btnNav">
      <button class="btn btnCancel" (click)="this.alert.cancelActive();">{{ "clientcreateFormBtnCancel" | translate }}</button>
      <button class="btn" (click)="this.alert.confirmActive()">{{ "usereditFormLabelConfirm" | translate }}</button>
    </nav>
</div>
