import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartService } from '../service/chart.service';
import { ThemeService } from '../service/theme.service';
import { PdfService } from '../service/pdf.service';


@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css', '../../charts.css']
})
export class BarChartComponent {
  @ViewChild('chartContainer') chartContainer!: HTMLElement;

  constructor(public charts: ChartService,
              public theme: ThemeService,
              public pdf: PdfService){
    //Declare chart data as a constant
    const single = charts.singleBarChart
    //Assign Data to the Graph object
    Object.assign(this, { single });
  }

}

