import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { TaxeTypeService } from '../service/taxe-type.service';
import { FilterService } from '../service/filter.service';
import { ISOProvince } from '../constants/ISO-province';
import { ISOCountry } from '../constants/ISO-country';
import { ModalAlertService } from '../service/alert-modal.service';
import { SystemMessageService } from '../service/system-message.service';

@Component({
  selector: 'app-taxe-type-list',
  templateUrl: './taxe-type-list.component.html',
  styleUrls: ['./taxe-type-list.component.css']
})
export class TaxeTypeListComponent implements OnInit {

  // Variable used by HTML to show/hide containers
  public mainContent: string = 'taxe-type-list';
  public fromComponent: string = '';

  // Variable that contain both of the constant country and region
  public regionArray: any[] = [...ISOProvince, ...ISOCountry]

  // Variables used for to filter the list
  public userFilter: string = '';

  constructor(public theme: ThemeService,
              public taxeTypeService: TaxeTypeService,
              public filter: FilterService,
              private alert: ModalAlertService,
              private systemMessageService: SystemMessageService){

  }

  async ngOnInit() {
    this.alert.initAlert(); // Reset alert variables into alert-modal service

    // Get taxe_type_data from taxe type service and implement taxe_type_data
    await this.taxeTypeService.getTaxeTypes();

    // Check if there is a session variable called from. then it wil be used in html if there is a success create or update essage to show the proper stuff
    const fromComponent = sessionStorage.getItem('from');
    if(fromComponent){
      // Initialize is own variable
      this.fromComponent = fromComponent;
      sessionStorage.removeItem("from"); // Then remove it from session variable to avoid is not wanted use from other components
    }

    // Function that is automatically trigger used by bin service when there is a change made from bin-create or bin-update component
    this.taxeTypeService.changeMade$.subscribe(async () => {
      await this.taxeTypeService.getTaxeTypes(); // Reinitialize the bin-list
      this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
    });

    // Function trigger by the taxe type service when the change create/update haven't work properly
    this.taxeTypeService.noChangeMade$.subscribe(async () => {
      this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
    });
  }
}
