<app-system-message></app-system-message>
<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">
  <section [ngClass]="theme.getThemeClass()" class="card bg-light">
    <article class="card-body mx-auto article">
      <h4 class="card-title mt-0 text-center">{{ 'binUpdateViewBinUpdate' | translate }}</h4>
      <form>
        <!-- BIN NAME -->
        <label class="label-spacer">{{ "binName" | translate }}</label>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-dumpster"></i></span>
          <input 
            [(ngModel)]="this.bin.bin_data.bin_name"
            id="binName"  
            type="text" class="form-control" name="bin_name"
            value="{{ this.bin.bin_data.bin_name }}"
            >
        </div>
        <!-- BIN MODEL -->
        <label class="label-spacer">{{ "binModel" | translate }}</label>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1"><i class="fa fa-hashtag"></i></span>
          <ng-select [(ngModel)]="this.bin.bin_data.bin_model_id" id="binSelect" class="form-control" name="binSelect"
          aria-label="Bin_model_number"
          (change)="binModelChange()" [ngClass]="{'has-value': this.bin.bin_data.bin_model_id}">
              <ng-container *ngFor="let option of this.bin.bin_model_array; trackBy: trackBinModelId">
                <ng-option *ngIf="option.active === 1" [value]="option.bin_model_id" [attr.selected]="this.bin.bin_data.bin_model_id === option.bin_model_id">{{ option.bin_model_number }}</ng-option>
              </ng-container>
          </ng-select>
        </div>
        <!-- BIN USAGE -->
        <label class="label-spacer">{{ "adminViewBinUsageNavBar" | translate }}</label>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1"><i class="fa fa-trash"></i></span>
          <app-bin-usage class="form-control bin-usage"></app-bin-usage>
        </div>
        <!-- MARKET SEGMENT -->
        <label class="label-spacer">{{ "marketSegment" | translate }}</label>
        <div class="input-group mb-3 firstInput">
          <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-thumbtack"></i></span>
          <ng-select [(ngModel)]="this.bin.bin_data.market_segment" [ngClass]="{'has-value': this.bin.bin_data.market_segment }"
          id="marketSelect" class="form-control" name="marketSelect"
          aria-label="bin_market_segment" aria-describedby="basic-addon1"
          (change)="binModelChange()" placeholder="{{ 'marketSegment' | translate }}">
              <ng-option value="" selected>{{ "marketSegment" | translate }}</ng-option>
              <ng-container *ngFor="let option of MarketSegment">
                  <ng-option value="{{ option.value }}">{{ option.label | translate }}</ng-option>
              </ng-container>
          </ng-select>
        </div>
        <!-- Input field for address with Autocomplete -->
        <label class="label-spacer">{{ "usereditFormLabelAddress" | translate }}</label>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1"><i class="fas fa-search-location"></i></span>
          <input id="autocomplete" [(ngModel)]="this.bin.bin_data.bin_address" (keydown)="onInputAddressKeydown($event);"
            type="text" class="form-control" name="bin_location"
            placeholder="{{ 'clientcreateFormInputFindAddress' | translate }}">
        </div>
          <!-- Select used to get put an association between bin and client -->
          <label class="label-spacer">{{ "clientName" | translate }}</label>
          <div class="input-group mb-3" *ngIf="this.cognitoService.userType === 'muirwood' || this.cognitoService.userType === 'distributor'">
            <span class="input-group-text" id="basic-addon1"><i class="fas fa-briefcase"></i></span>
            <ng-select [(ngModel)]="this.bin.bin_data.client_id" class="form-control ng-select" name="client" [ngClass]="{'has-value': this.bin.bin_data.client_id }"
              aria-label="Client Name" aria-describedby="basic-addon1" (change)="selectedClientChange()">
                <ng-option value="" selected disabled>{{ "devicecreateSelectClient" | translate }}</ng-option>
                <ng-container *ngFor="let option of this.cognitoService.clientsInfosArray">
                  <ng-option value="{{ option.client_id }}">{{ option.legal_name }}</ng-option>
                </ng-container>
            </ng-select>
          </div>
          <!-- Select used to get put an association between bin/client/thing -->
          <div class="input-group mb-3" *ngIf="this.bin.isDeviceArray">
            <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-microchip"></i></span>
            <ng-select [(ngModel)]="this.thing_name" class="form-control ng-select" name="thing"
              aria-label="thing_name" aria-describedby="basic-addon1" [ngClass]="{'has-value': this.thing_name }">
                <ng-option value="" selected>{{ "iotModalViewDeviceName" | translate }}</ng-option>
                <ng-container *ngFor="let option of this.bin.device_array">
                  <ng-option value="{{ option.thing_name }}">{{ option.thing_name }}</ng-option>
                </ng-container>
            </ng-select>
          </div>
        <!-- Input for the threshold -->
        <label class="label-spacer">{{ "alertThreshold" | translate }}</label>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1"><i class="fa-regular fa-bell"></i></span>
          <input [(ngModel)]="this.bin.bin_data.threshold" type="number"
          class="form-control" name="threshold" aria-label="bin_depth" (change)="thresholdChange()">
        </div>

        <!-- Input for the device attached -->
        <label class="label-spacer">{{ "assignedDevice" | translate }}</label>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-microchip tile-icon device-icon"></i></span>
          <ng-select [(ngModel)]="this.bin.bin_data.thing_name" class="form-control ng-select" name="thing" [ngClass]="{'has-value': this.bin.bin_data.thing_name }"
              aria-label="thing Name" aria-describedby="basic-addon1" >
                <ng-option value="this.bin.bin_data.thing_name" selected disabled>{{ "deviceSelectBinUpdate" | translate }}</ng-option>
                <ng-container *ngFor="let option of this.availableDevices">
                  <ng-option value="{{ option }}">{{ option }}</ng-option>
                </ng-container>
            </ng-select> 
        </div>
        
        <!-- Checkbox for the status -->
        <div class="card-title mt-0 status">
            <label class="switch">
                <input type="checkbox" id="checkboxStatus" [name]="'active-bin-' + bin.bin_data.bin_id" [ngModel]="bin.bin_data.active" (change)="this.alert.showAlertDelete= true; this.alert.deactiveBin(bin.bin_data.bin_id, bin.bin_data.bin_address, bin.bin_data.active)" [disabled]="this.alert.showAlertDelete">
                <span [ngClass]="{'active-checkbox': bin.bin_data.active === 1, 'inactive-checkbox': bin.bin_data.active === 0}"  class="slider round"></span>
            </label>
            <label class="toggle">{{ "iotTabHeaderStatus" | translate }}</label>
        </div>
        <!-- Submit button -->
        <nav class="form-group displayFlex">
            <button (click)="returnToAdmin()" *ngIf="this.cognitoService.userType === 'muirwood' && !this.redirectToCLientList" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
            <button (click)="returnClientBinList()" *ngIf="this.cognitoService.userType === 'client' && !this.redirectToCLientList" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
            <button (click)="returnDeviceClientList()" *ngIf="this.redirectToCLientList" [ngClass]="theme.getThemeClass()" type="button" class="btn btn-block btnCancel">{{ 'clientcreateFormBtnCancel' | translate }}</button>
            <button [ngClass]="theme.getThemeClass()" (click)="onSubmit()" type="button" class="btn btn-block btnCreate">{{ 'update' | translate }}</button>
        </nav>
      </form>
    </article>
    <app-alert-modal *ngIf="this.alert.showAlertDelete" class="alertDelete" [ngClass]="theme.getThemeClass()"></app-alert-modal>
  </section>
</div>

