import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { OperatorService } from '../service/operator.service';
import { ValidationService } from '../service/validation.service';
import { LocalStorageService } from '../local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalAlertService } from '../service/alert-modal.service';
import { formatDate } from '@angular/common';
import { CognitoService } from '../service/cognito.service';
import { SystemMessageService } from '../service/system-message.service';

@Component({
  selector: 'app-operator-update',
  templateUrl: './operator-update.component.html',
  styleUrls: ['./operator-update.component.css']
})
export class OperatorUpdateComponent implements OnInit {

  private operator_id: string = '';
  public active: number = 0;

  private validateCreate: boolean = false;
  private systemMessage: string = '';

  constructor(public theme: ThemeService,
              public operator: OperatorService,
              public validationService: ValidationService,
              private localStorageService: LocalStorageService,
              private router: Router,
              public alert: ModalAlertService,
              private activatedRoute: ActivatedRoute,
              private cognitoService: CognitoService,
              private systemMessageService: SystemMessageService){

    this.cognitoService.confirmValidUser();
  }

  ngOnInit(): void {
    // Get the param that was pass from the bin-list of the bin_id selected
    this.activatedRoute.params.subscribe(params => {
      this.operator_id = params['id']; // Set bin_model_id to the param:id receive whit url
    });

    this.operator.getOperatorById(this.operator_id);
    this.active = parseInt(this.operator.operator_data.active);

    // Function trigger when user get alerted if he change active status then click on cancel
    this.alert.cancel$.subscribe(() => {
      let checkboxStatus = document.getElementById('checkboxStatus') as HTMLInputElement; // Set the a html variable of the checkbox for the status
      this.operator.operator_data.active = this.alert.active.toString(); // Set the bin_model_data active status to the good value

      // Will put the right checkbox statement
      if(this.alert.active === 1 && checkboxStatus){
        checkboxStatus.checked = true;
      } else if(this.alert.active === 0 && checkboxStatus){
        checkboxStatus.checked = false;
      }
    });

    // Function automatically trigger when user click on confirm into the alert modal
    this.alert.confirmOperator$.subscribe(async () => {
      // Set are own variable used in ngModel HTML
      this.active = this.alert.active;
      // Set the operator_data active status to the good value
      this.operator.operator_data.active = this.active.toString();
    });
  }

  async onSubmit(){
    // Create a variable whit the current date
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'yyyyMMdd', 'en_US');

    // Set created and modified date to current date, modified is required event if it's not so the query won't break
    this.operator.operator_data.modified = formattedDate;

    // Call the function in addition of validationService to validate
    this.checkValidation();

    if(this.validateCreate){
      // Call the function in service that update the operator
      await this.operator.updateOperator();
      // Call the function that return to entitie component
      this.returnToEntities();
    }else{
      // If system message have nothing it put a generic message
      if(this.systemMessage === ''){
        this.systemMessage = 'alert-danger-generic-message';
      }
      // Set the system message service to display the error to the user
      this.systemMessageService.selectRibbon('danger', this.systemMessage);
    }
  }

  // Function call in addition of validation service to validate input of the
  checkValidation() {
    // Call the function to check if there is an empty input
    this.operator.hasOwnProperty('update');

    if(this.operator.emptyField){
      this.systemMessage = 'fieldEmptyOrIncorrect';
    }

    // Check if all function that we passed before not returning a true value then will set the validation variable to true to be able to create a new taxe type
    if(!this.operator.emptyField && (this.validationService.validationErrorArray[0] === '' || this.validationService.validationErrorArray[0] === undefined)){
      // Set validation variable to true and remove the empty input message
      this.validateCreate = true;
    }
  }

  // Function that avoid submitting the page when user press enter at the end of inputting address in address input
  onInputAddressKeydown(event: KeyboardEvent): void {
    if(event.key === 'Enter'){
      event.preventDefault(); // Don't submit the page
    }
  }

  // Implemented by cancel button to return to the admin component
  returnToEntities(){
    // Put a local store variable so entitie component could know to return on operator-list
    this.localStorageService.addItem('entitiesContent', 'operator-list');
    sessionStorage.setItem("from", "operator-update"); // This variable is used for operator-list to show the proper success message on create or update
    this.router.navigate(['/entities']); // Return to entitie component
  }
}
