import { Component, EventEmitter, OnInit, Output, HostListener, Renderer2, ElementRef, forwardRef, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { CognitoService } from '../service/cognito.service';
import { ThemeService } from '../service/theme.service';
import { ModalService } from '../service/device-modal.service';
import { environment } from '../environments/environment';
import { navigation } from '../constants/navigation';
import { NavigationService } from '../service/navigation.service';
import { TranslationService } from '../translation.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // Variable to store the language selected value
  public selectedLanguage: string = "";


// Retrieve current language selected from local storage
languageStatus:string = this.localStorageService.getItem('language');
  // Declaration of component properties
  @Output() sideNavToggled = new EventEmitter<boolean>(); // Event emitter for toggling the side nav
  @Output() showSidebar = new EventEmitter<boolean>(); // Event emitter for showing the sidebar
  @Output() mode = new EventEmitter<boolean>(); // Event emitter for selecting a mode

  public userName:any = ''; // Variable for storing the user's name
  unreadNotifications = 2; // Variable for storing the number of unread notifications
  isSwitchOn:boolean = false; // Flag for switch status
  isDarkMode: boolean = false; // Flag for dark mode
  menuStatus: boolean = false; // Flag for menu status
  showHeader: boolean = false; // Flag for showing the header
  public getScreenWidth: any; // Variable for storing the screen width
  public screenWidthDeadLine: any = 768 // Threshold for small screen width
  public getScreenHeight: any; // Variable for storing the screen height
  smallscreen: boolean = false; // Flag for small screen
  userid: string = "a"; // Variable for storing the user id
  currentUser: any = [];
  public notifications: boolean = false; //Notifications dialog box
  public authority: any = environment.users;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private renderer: Renderer2,
    private elRef: ElementRef, // Inject ElementRef here without @Inject
    public cognitoService: CognitoService,
    @Inject(forwardRef(() => TranslateService)) @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    public theme: ThemeService,
    public modal: ModalService,
    public nav: NavigationService
  ) {

   }

  // Lifecycle hook called when the component is initialized
  ngOnInit() {
     // Check if the user has selected a language in local storage
      //or use a default language
      if (this.languageStatus == null){
        // Set the default language to French
        this.translate.use('fr');
      } else {
        // Set the default language to the user's selected language
        this.translate.use(this.languageStatus);
      }
    // Check if the current page is the login page and set the theme to light if it is
    this.IsLoginPageActive();

    // Determine whether to show the header based on the current route
    this.showHeader = !this.router.url.includes('/login');

    // Get the current screen width and height
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    // Initialize small screen state
    this.smallscreen = false;

    // Check and adjust the screen width state
    this.checkScreenWidth();

    this.theme.getThemeClass();

  }

  //Lifecycle hook called when a component has been initialized and all page elements have been checked
  ngAfterViewChecked(): void {

    //Called after every check of the component's view. Applies to components only.
    this.getUsername();
  }

  // Change language based on user selection
  changeLanguage(language: string) {
    this.translate.use(language);
  }

  // Method for getting the current username
  async getUsername(){
   this.userName = this.localStorageService.getItem('user');
  }

  // Method for adjusting the toggle status
  adjustToggleStatus() {
    // Get a reference to the slider element
    const slider = document.querySelector('.slider');

    // Retrieve the saved theme status from local storage
    this.theme.themeStatus = this.localStorageService.getItem('theme');

    // Check if the theme status is set to 'dark'
    if (this.theme.themeStatus == 'dark' ) {
      // Add the 'checked' class to the slider to indicate it's on (dark theme)
      this.renderer.addClass(slider, 'checked');
    } else {
      // Remove the 'checked' class from the slider to indicate it's off (light theme)
      this.renderer.removeClass(slider,'checked');
    }
  }

  // Method for toggling the dark mode
  toggleDarkMode(event: Event) {
    // Toggle the switch status
    this.theme.isSwitchOn = !this.theme.isSwitchOn;

    // Get the target element of the event as an input element
    const target = event.target as HTMLInputElement;

    // Update the dark mode status based on the switch's checked state
    this.theme.isDarkMode = target.checked;

    // Apply dark mode styling to the body and update theme status in local storage
    if(this.theme.isDarkMode) {
      // Add the 'dark-mode' class to the body to enable dark mode
      document.body.classList.add('dark-mode');

      // Update theme status and save it in local storage
      this.theme.themeStatus = "dark";
      this.localStorageService.addItem('theme', this.theme.themeStatus);
    } else {
      // Remove the 'dark-mode' class from the body to disable dark mode
      document.body.classList.remove('dark-mode');

      // Update theme status and save it in local storage
      this.theme.themeStatus = "light";
      this.localStorageService.addItem('theme', this.theme.themeStatus);
    }
  }

  // Event listener for window resize
  @HostListener('window:resize', ['$event'])onWindowResize() {
    // Update the screen width and height based on the resized window
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    // Check and adjust the screen width state
    this.checkScreenWidth();
  }

  // Method for checking the screen width
  checkScreenWidth() {
    // Compare the current screen width with the defined threshold
    if (this.getScreenWidth <= this.screenWidthDeadLine){
      // If the screen width is below or equal to the threshold, set smallscreen state to true
      this.smallscreen = true;
    } else {
      // If the screen width is above the threshold, set smallscreen state to false
      this.smallscreen = false;
    }
  }

  // Method for toggling the side nav
  SideNavToggle() {
    // Toggle the menuStatus state to show/hide the side nav
    this.menuStatus = !this.menuStatus;

    // Emit the updated menuStatus using the sideNavToggled event
    this.sideNavToggled.emit(this.menuStatus);
  }

  // Method for showing the sidebar
  ShowSidebar() {
    // Emit the smallscreen state to show the sidebar based on the current screen width
    this.showSidebar.emit(this.smallscreen);
  }

  // Method for checking if the login page is active
  IsLoginPageActive() {
    // Get the current URL of the page
    const url = window.location.href;

    // Set the switch status to off
    this.theme.isSwitchOn = false;

    // Return true if the URL includes 'login', otherwise return false
    return url.includes('login')
  }

  // Method for clearing the local storage
  async clearStorage() {
    // Clear the local storage
    localStorage.clear();
    sessionStorage.clear();
    try{
      // Attempt to sign the user out using cognitoService
     await this.cognitoService.signOut().then(() => {
      //No action required (yet)
     });
    }
    catch(error){
      // If an error occurs during sign-out, log the error and navigate to the login page
      console.error('Error signout out:', error);
      this.router.navigate(['/login']);
    }
  }

// Method for clearing the notifications
clearNotifications() {
  this.unreadNotifications = 0;
}

// Method for showing the notifications dropdown
showNotificationsDropdown() {
  // Toggle the notifications property
  this.notifications = !this.notifications;

  // If the notification box is open, schedule clearing of notifications
  if (this.notifications) {
    this.scheduleClearNotifications();
  }
}

// Method to schedule the clearing of notifications after a short delay
private scheduleClearNotifications() {
  // Use setTimeout to delay the execution of clearNotifications by a few milliseconds
  setTimeout(() => {
    this.clearNotifications();
  }, 100); // Adjust the delay as needed
}


  @HostListener('document:click', ['$event'])
  handleClick(event: Event): void {
    const target = event.target as HTMLElement;

    // If the click is outside the notification dropdown box and bell icon, close the box
    if (!this.isNotificationDropdown(target) && !target.closest('.customNotificationBtn1')) {
      this.notifications = false;
    }
  }

  private isNotificationDropdown(target: HTMLElement): boolean {
    // Check if the click is within the notification dropdown box
    return target.closest('.appDeviceModal') !== null;
  }


  async checkAuth(role:string, type:string){
       //Validate role and type. Sets cognitoService.userAllowed as a boolean value
     await this.cognitoService.getCurrentRole([role], [type], false);//Role Check
  }

  //Validates list items in the hamburger navigation menu
  validateListItem(role: string, type: string) : boolean {

    this.checkAuth(role, type);

    let allowed = this.cognitoService.userAllowed;

    return allowed;
  }

  isDashboard(){
    // Get the complete URL of the current page
    const url = window.location.href;

    // Check if the URL includes the string 'dashboard'
    // This indicates whether the current page is the login page
    return url.includes('dashboard')
 }
}
