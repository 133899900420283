import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { FilterService } from '../service/filter.service';
import { BinsService, Bin, BinModel, BinDetails } from '../service/bins.service';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { ValidationService } from '../service/validation.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { ModalAlertService } from '../service/alert-modal.service';
import { BinModalService } from '../service/bin-modal.service';
import { AdminService } from '../service/admin.service';
import { ThemeService } from '../service/theme.service';
import { SystemMessageService } from '../service/system-message.service';

@Component({
  selector: 'app-bin-model-list',
  templateUrl: './bin-model-list.component.html',
  styleUrls: ['./bin-model-list.component.css']
})
export class BinModelListComponent {


  // Variables used for HTML contents
  public mainContent: string = 'bin-model-list';
  public fromComponent: string = '';

  // Variables used for filter service
  public userFilter: string = '';

  // Array of the bins
  public array: any; // Array used to attribute a received array frombin-service to the bin_array and bin_model_array
  public binModelArray: BinModel = {} as BinModel;

  constructor(private localStorageService: LocalStorageService,
              public filter: FilterService,
              public bin: BinsService,
              public validationService: ValidationService,
              public alert: ModalAlertService,
              public binModal: BinModalService,
              private admin: AdminService,
              public theme: ThemeService,
              private systemMessageService: SystemMessageService){

  }

  async ngOnInit(): Promise<void> {
    this.alert.initAlert(); // Reset alert variables into alert-modal service

    // Check if there is a session variable called from. then it wil be used in html if there is a success create or update essage to show the proper stuff
    const fromComponent = sessionStorage.getItem('from');
    if(fromComponent){
      // Initialize is own variable
      this.fromComponent = fromComponent;
      sessionStorage.removeItem("from"); // Then remove it from session variable to avoid is not wanted use from other components
    }
    try{
      // Initialize Bin Model array in bin.service
      await this.bin.getBinsModel();
    }
    catch (error){
      console.error('An error as occured: ' + error);
    }

     // Function that is automatically trigger used by bin service when there is a change made from bin-create, bin-update, bin-model-create or bin-model-update components
    this.bin.changeMade$.subscribe(async () => {
      await this.bin.getBinsModel(); // Reinitialize the bin-list
      this.systemMessageService.selectRibbon('success', 'alert-success-generic-message');
    });

    this.bin.noChangeMade$.subscribe(async () => {
      this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
    });

    // Function that is trigger by bin-modal-service to return to admin component and bin-list and will automatically filter the list whit the bin-model-number
    this.binModal.returnToBinList$.subscribe(() => {
      this.admin.returnBinList();
    });
  }

}
