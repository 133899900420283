import { Component } from '@angular/core';
import { ChartService } from '../service/chart.service';
import { ThemeService } from '../service/theme.service';

@Component({
  selector: 'app-bar-chart-collections',
  templateUrl: './bar-chart-collections.component.html',
  styleUrls: ['./bar-chart-collections.component.css']
})
export class BarChartCollectionsComponent {

  constructor(public charts: ChartService,
              public theme: ThemeService,
             ){
              //Declare chart data as a constant
              const single = charts.singleBarChartCollections
              //Assign Data to the Graph object
              Object.assign(this, { single });
  }

}
