import { Component, OnInit } from '@angular/core';
import { ErrorModalService } from '../service/error-modal.service';
import { ThemeService } from '../service/theme.service'; // Import ThemeService

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.css']
})
export class ErrorModalComponent {
  errorHeaderIconPath = 'assets/error.png';

  constructor(public errorService: ErrorModalService, public theme: ThemeService) {} // Inject ThemeService

  closeErrorModal() {

    localStorage.removeItem('showErrorModal'); // Clear the 'showErrorModal' value
    this.errorService.closeErrorModal(); // Added to also close the modal in the service
  }
  // Add methods to set and update error details
  setErrorDetails(title: string, message: string) {
    this.errorService.setErrorDetails(title, message);
  }
}
