<app-system-message></app-system-message>

<div [ngClass]="theme.getThemeClass()" class="container-fluid" (window:scroll)="onScroll()">

  <!-- Search input component, shown when mainContent is 'clients' -->
  <header id="search-component" class="input-group" *ngIf="mainContent === 'clients'">
    <input #searchBox type="search"  id="search-box" [(ngModel)]="distributorFilter" (input)="search()" class="form-control rounded"
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}" aria-label="Search" aria-describedby="search-addon" />
  </header> 

  <!-- Table header for the client list, shown when mainContent is 'clients' -->
  <nav [ngClass]="theme.getThemeClass()" class="table-header" *ngIf="mainContent === 'clients'">
    <span class="left">{{ 'distributorsList' | translate }} <a (click)="refreshList()"><i class="fas fa-sync scale"></i></a> </span>
    <span class="right"><a routerLink="/distributors-create" href="javascript:void(0)">&#x2b; {{ 'userlistTableHeaderNew' | translate }} <span class="hide-small-screen">{{ 'distributorslistTableHeaderNewDistributors' | translate }}</span></a></span>
  </nav>

  <!-- Table container for the user list, shown when mainContent is 'distributors' -->
  <section [ngClass]="theme.getThemeClass()" class="table-container width" *ngIf="mainContent === 'clients'">
    <article  class="table-responsive ">
      <table  class="table table-hover">
        <thead>
          <tr>
            <th [ngClass]="theme.getThemeClass()" scope="col">
              {{ 'name' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'legalName' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" scope="col">
                {{ 'email' | translate }}
              </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'phoneNumber' | translate }}
            </th>
            <th [ngClass]="theme.getThemeClass()" class="hide-small-screen" scope="col">
              {{ 'address' | translate }}
            </th>
          </tr>
        </thead>
        <ng-container  *ngFor="let distributor of this.distributorsArray">
          <tbody>
            <tr class="align">
              <td [ngClass]="theme.getThemeClass()">
                <a [routerLink]="['/distributors-edit', distributor.distributor_id]" href="javascript:void(0)">
                  {{distributor.distributor_name}}
                </a>
              </td>
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">
                {{distributor.legal_name}}
              </td>
              <td [ngClass]="theme.getThemeClass()">
                {{distributor.email}}
              </td>
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">
                {{this.formatarNumero(distributor.phone_number)}}
              </td>
              <td [ngClass]="theme.getThemeClass()" class="hide-small-screen">
                {{distributor.address}}
              </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </article>
  </section>
</div>







