import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { map, catchError } from 'rxjs/operators'; // Import map from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  zoneData = {
    client_id: "",
    zone_coordinates: "",
    zone_name: "",
    modified: "",
    created: "",
    created_by: "",
    active: "",
    zone_color: "",
    client_name: "",
    zone_id: "",
    type_of_operation: ""

  }

  public zonesArray: any[] = [];

  public zoneCreated: any;
  public zoneUpdated: boolean = false;


  constructor(private http: HttpClient) { }

  clearZoneData() {

    this.zoneData.client_id = "",
    this.zoneData.zone_coordinates = "",
    this.zoneData.zone_name = "",
    this.zoneData.modified = "",
    this.zoneData.created = "",
    this.zoneData.created_by = "",
    this.zoneData.active = "",
    this.zoneData.zone_color = "",
    this.zoneData.client_name = ""

  }

  getZones() {
    // Append the 'user' parameter to the URL as a query string
    const url = environment.api.stage + environment.api.route.getZones;

    return this.http.get(url).pipe(
      map((response) => {
        // return the response data
        this.zonesArray.push(response)
        return response;
      }),
      catchError((error) => {
        console.error('API Error:', error);
        throw error(error); // Re-throw the error for the calling code to handle
      }));
  }

  createClientZone(): Observable<any> {

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    return this.http.post(environment.api.stage + environment.api.route.createClientZone,
      {
        "client_id": this.zoneData.client_id,
        "zone_coordinates": this.zoneData.zone_coordinates,
        "zone_name": this.zoneData.zone_name,
        "created": this.zoneData.created,
        "created_by": this.zoneData.created_by,
        "active": this.zoneData.active,
        "zone_color": this.zoneData.zone_color
      }, {headers: headers}
    );

  }

  updateClientZone(): Observable<any> {

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    return this.http.post(environment.api.stage + environment.api.route.updateClientZone,
      {
        "zone_id": this.zoneData.zone_id,
        "zone_coordinates": this.zoneData.zone_coordinates,
        "zone_name": this.zoneData.zone_name,
        "modified": this.zoneData.modified,
        "active": this.zoneData.active,
        "zone_color": this.zoneData.zone_color,
        "type_of_operation": this.zoneData.type_of_operation
      }, {headers: headers}
    );
  }

  deleteClientZone(): Observable<any> {

    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });

    return this.http.post(environment.api.stage + environment.api.route.deleteClientZone,
      {
        "zone_id": this.zoneData.zone_id,        
      }, {headers: headers}
    );
  }

}
