import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { IotService } from '../service/iot.service';
import { SleepTime } from '../constants/sleep-time';
import { SystemMessageService } from '../service/system-message.service';
import { CognitoService } from '../service/cognito.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-device-modal-config',
  templateUrl: './device-modal-config.component.html',
  styleUrls: ['./device-modal-config.component.css']
})
export class DeviceModalConfigComponent implements OnInit{

  // Variable that take the constant
  public sleepTime = SleepTime;

  public active: number = 0;

  // Variables used in the validation of the update
  public tempIsNumber: boolean = false;
  public tempIsTooLow: boolean = false;
  public submitClick: boolean = false;
  public changeSaved:boolean = false;

  public checkBoxValue: number = 0;

  // Variable that take the shadow object
  public thingShadow: any;
  public updatedThingShadow: any;
  public selectedShadow: any;

  // Message displayed to the user
  private systemMessage: string = '';

  constructor(public theme: ThemeService,
              public iotService: IotService,
              private systemMessageService: SystemMessageService,
              private cognitoService: CognitoService){

  }

  ngOnInit(): void {
    this.resetVariables();
  }

  // Function called went user click on cancel button of the modal
  cancel(){
    // Set the showDeviceConfigModal = false so it will hide the modal
    this.iotService.showDeviceConfigModal = false;
  }

  // Function called when user click the confirm button
  async onSubmit(){
    // Set his own thingShadow to be able to compare whit the updated one after change have been made
    this.thingShadow = this.iotService.thingShadow;
    this.resetVariables(); // Reset the variable for the validation
    // Set the variable submitClick at true, used in HTML alert
    this.submitClick = true;

    // Get the temperature input  in the DOM
    const temperatureInput = document.getElementsByClassName('hibernationTemp')[0] as HTMLInputElement;
    if(temperatureInput){ // Look if there is an input
      // We want to know if the input can be a number because a temperature is a number
      if(!isNaN(parseInt(temperatureInput.value))){
        // If it is, set the variable to true so we know that temperature is a number
        this.tempIsNumber = true;

        // Check if the temperature set by user is not under -45 so we avoid breaking the device whit too cold temperature
        if(parseInt(temperatureInput.value) < -45){
          // Set the system message
          this.systemMessage = 'deviceConfigModalAlertTempTooLow';
        }else{
          // If temperature is higher then -45
          this.tempIsTooLow = false;
        }
      }else{
        // Set the system message
        this.systemMessage = 'deviceConfigModalAlertTempNotNumber';
      }
    }

    // If the two temperature condition are met, we can update the shadow
    if(this.tempIsNumber && !this.tempIsTooLow){
      // Delete the delta from the shadow because the update function doesn't take the delta
      delete this.iotService.thingShadow.state.delta;
      // Update the shadow
      await this.iotService.updateThingShadow(this.iotService.thingName, this.iotService.thingShadow);
    }
    // Set a new instance of the new thingShadow to be able to compare whit the old
    this.updatedThingShadow = await this.iotService.getDeviceShadow(this.iotService.thingName);

    // Take the new shadow of the device and the one that we just pass to see if they are the same and if so we set the variable shadowUpdated at true
    for(const key in this.updatedThingShadow.state.desired.config){
      // If the key match and are equal we set the variable to true
      if(this.updatedThingShadow.state.desired.config[key] === this.thingShadow.state.desired.config[key]){
        this.iotService.shadowUpdated = true;
      }else{
        // If the key match but are not equal, set the variable to false then break to avoid the variable to be true
        this.iotService.shadowUpdated = false;
        break;
      }
    }

    // Check if the shadow updated variable is set to true
    if(this.iotService.shadowUpdated){
      // Hide the modal
      this.iotService.showDeviceConfigModal = false;

      // Call a function to made a log of the change in DB
      this.updateShadowLog()

      // Set the variable that iot component used in html to show the success message
      this.iotService.shadowSuccessUpdated();
    }else{
      // If system message have nothing it put a generic message
      if(this.systemMessage === ''){
        this.systemMessage = 'alert-danger-generic-message';
      }
      // Set the system message service to display the error to the user
      this.systemMessageService.selectRibbon('danger', this.systemMessage);
    }
  }

  // Function called to reset the validation variables
  resetVariables(){
    this.tempIsNumber = false;
    this.tempIsTooLow = false;
    this.iotService.shadowUpdated = false;
  }

  // Function called to made and update log in DB
  updateShadowLog(){
    // Get the id of the current user that will do the change on the shadow
    const userSubPromise = this.cognitoService.getCurrentUserSub();
    userSubPromise.then((userSub) => {
      // Get the current date and set it to the proper format the put it into DB
      const currentDate = new Date();
      const date = parseInt(formatDate(currentDate, 'yyyyMMdd', 'en_US'));

      // Call the function that write the log in DB
      const result = this.iotService.updateShadowDBLog(userSub, date);
    });
  }
}
