import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserEditModalService } from '../service/user-edit-modal.service';
import { ThemeService } from '../service/theme.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { select } from 'd3-selection';

@Component({
  selector: 'app-user-edit-modal',
  templateUrl: './user-edit-modal.component.html',
  styleUrls: ['./user-edit-modal.component.css', '../../global-elements.css']
})
export class UserEditModalComponent implements OnInit{
  @ViewChild('selectCollection') selectCollection!: NgSelectComponent;
  @ViewChild('selectThreshold') selectThreshold!: NgSelectComponent;
  @ViewChild('selectMonthlyReport') selectMonthlyReport!: NgSelectComponent;

  public allThingsCollection: boolean = false;
  public allThingsThreshold: boolean = false;
  public allThingsMonthlyReports: boolean = false;
  public selectedThingsCollection: string[] = this.userEditModalService.collectionArray;
  public selectedThingsThreshold: string[] = this.userEditModalService.thresholdArray;
  public selectedThingsMonthlyReports: string[] = this.userEditModalService.monthlyReportArray;

  private previousSelectedThingsCollection: string[] = [];
  private previousSelectedThingsThreshold: string[] = [];
  private previousSelectedThingsMonthlyReports: string[] = [];

  constructor(public theme: ThemeService,
              public userEditModalService: UserEditModalService,
              private elRef: ElementRef){

  }

  ngOnInit(): void {
    this.allThingsCollection = true;
    this.allThingsThreshold = true;
    this.allThingsMonthlyReports = true;

    setTimeout(() => {
      this.allThingsCollection = false;
      this.allThingsThreshold = false;
      this.allThingsMonthlyReports = false;

      this.checkIfAllThingSelected();
    }, 50);
  }

  // Function that will check the length of each Array to set the toggle variable at true if they are equal
  checkIfAllThingSelected(){
    if(this.userEditModalService.collectionArray.length === this.userEditModalService.things.length){
      this.allThingsCollection = true;
    }

    if(this.userEditModalService.thresholdArray.length === this.userEditModalService.things.length){
      this.allThingsThreshold = true;
    }

    if(this.userEditModalService.monthlyReportArray. length === this.userEditModalService.things.length){
      this.allThingsMonthlyReports = true;
    }
  }

  // Funciton called when user click on the toggle switch for all devices of collection
  onChangeAllThingsCollection(event: any) {
    this.allThingsCollection = (event.target as HTMLInputElement).checked;

    return this.allThingsCollection;
  }

  // Funciton called when user click on the toggle switch for all devices of threshold
  onChangeAllThingsThreshold(event: any) {
    this.allThingsThreshold = (event.target as HTMLInputElement).checked;

    return this.allThingsThreshold;
  }

  // Funciton called when user click on the toggle switch for all devices of monthly reports
  onChangeAllThingsMonthlyReports(event: any) {
    this.allThingsMonthlyReports = (event.target as HTMLInputElement).checked;

    return this.allThingsMonthlyReports;
  }

  // Funciton called when user change the select to scroll automatically to the bottom
  scrollToBottomCollection(): void{
    // set check variable to know if we add or remove things
    const addedItems = this.selectedThingsCollection.filter(item => !this.previousSelectedThingsCollection.includes(item));

    // Will scroll down only if user add an item, not if user delete an item from the list
    if(addedItems.length > 0){
      // Set the instance of the select list
      const selectCollectionList = this.selectCollection.element.querySelector('.ng-value-container') as HTMLElement;
      if(selectCollectionList){
        // If we got the instance it set the height of the box multiply by the number of things in it
        const heigth = selectCollectionList.offsetHeight * this.selectedThingsCollection.length;
        setTimeout(() => {
          // Set a time out and scroll down the input so user see the last thing entered
          selectCollectionList.scrollBy(0, heigth);
        }, 500);
      }
    }

    this.previousSelectedThingsCollection = [...this.selectedThingsCollection];
  }

  // Funciton called when user change the select to scroll automatically to the bottom
  scrollToBottomTreshold(): void{
    // set check variable to know if we add or remove things
    const addedItems = this.selectedThingsThreshold.filter(item => !this.previousSelectedThingsThreshold.includes(item));

    // Will scroll down only if user add an item, not if user delete an item from the list
    if(addedItems.length > 0){
      // Set the instance of the select list
      const selectThresholdList = this.selectThreshold.element.querySelector('.ng-value-container') as HTMLElement;
      if(selectThresholdList){
        // If we got the instance it set the height of the box multiply by the number of things in it
        const height = selectThresholdList.offsetHeight * this.selectedThingsThreshold.length;
        setTimeout(() => {
          // Set a time out and scroll down the input so user see the last thing entered
          selectThresholdList.scrollTo(0, height);
        }, 500);
      }
    }

    this.previousSelectedThingsThreshold = [...this.selectedThingsThreshold];
  }

  // Funciton called when user change the select to scroll automatically to the bottom
  scrollToBottomMonthlyReports(): void{
    // set check variable to know if we add or remove things
    const addedItems = this.selectedThingsMonthlyReports.filter(item => !this.previousSelectedThingsMonthlyReports.includes(item));

    // Will scroll down only if user add an item, not if user delete an item from the list
    if(addedItems.length > 0){
      // Set the instance of the select list
      const selectMonthlyReportList = this.selectMonthlyReport.element.querySelector('.ng-value-container') as HTMLElement;
      if(selectMonthlyReportList){
        // If we got the instance it set the height of the box multiply by the number of things in it
        const height = selectMonthlyReportList.offsetHeight * this.selectedThingsMonthlyReports.length;
        setTimeout(() => {
          // Set a time out and scroll down the input so user see the last thing entered
          selectMonthlyReportList.scrollTo(0, height);
        }, 500);
      }
    }

    this.previousSelectedThingsMonthlyReports = [...this.selectedThingsMonthlyReports];
  }

  // Function to save all advanced options in service and DB
  saveAdvanceOption(){
    if(this.allThingsCollection){
      this.userEditModalService.selectedThingAlertCollection = this.userEditModalService.things;
    }else{
      this.userEditModalService.selectedThingAlertCollection = this.selectedThingsCollection;
    }

    if(this.allThingsThreshold){
      this.userEditModalService.selectedThingAlertThreshold = this.userEditModalService.things;
    }else{
      this.userEditModalService.selectedThingAlertThreshold = this.selectedThingsThreshold;
    }

    if(this.allThingsMonthlyReports){
      this.userEditModalService.selectedThingAlertMonthlyReport = this.userEditModalService.things;
    }else{
      this.userEditModalService.selectedThingAlertMonthlyReport = this.selectedThingsMonthlyReports;
    }

    this.userEditModalService.saveAdvanceOption();
  }
}
