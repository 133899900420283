<div >
  <ngx-charts-line-chart
  [view]="charts.viewLineChartAdmin"
  [scheme]="charts.colorSchemeLineChart"
  [legend]="false"
  [legendPosition]="charts.legendPositionLineChartAdmin"
  [showXAxisLabel]="charts.showXAxisLabelLineChartAdmin"
  [showYAxisLabel]="charts.showYAxisLabelLineChartAdmin"
  [xAxis]="charts.showXAxisLineChartAdmin"
  [yAxis]="charts.showYAxisLineChartAdmin"
  [xAxisLabel]="charts.xAxisLabelLineChartAdmin"
  [yAxisLabel]="charts.yAxisLabelLineChartAdmin"
  [timeline]="true"
  [results]="charts.singleLineChart"
  (select)="charts.onSelectLineChart($event)"
  (activate)="charts.onActivateLineChart($event)"
  (deactivate)="charts.onDeactivateLineChart($event)"
  [xAxisTickFormatting]="charts.formatTimestamp"
  [yAxisTickFormatting]="charts.formatYAxisTicks"
  >
</ngx-charts-line-chart>
<div class="line-chart-container">
<h6>{{charts.xAxisLabelLineChartAdmin}}</h6>
</div>
</div>

