
<meta charset="UTF-8">
<!-- Container -->
<div [ngClass]="theme.getThemeClass()" class="container-fluid">
  <app-loading-element *ngIf="!dataLoaded" class="loadingElementContainer componentBelow"></app-loading-element>

  <header class="mobileAlign2" *ngIf="this.dataLoaded">
    <h2 class="mobile">{{"ClientListThings" | translate}}</h2>
  </header>
  <nav *ngIf="this.dataLoaded" id="search-component" class="nav-sort-filter">
    <input
      #searchBox
      type="search"
      id="search-box"
      [(ngModel)]="userFilter"
      (input)="search()"
      class="form-control rounded searchInput"
      placeholder="{{ 'userlistSearchInputPlaceHolder' | translate }}"
      aria-label="Search"
      aria-describedby="search-addon"
    />
    <ng-select placeholder="{{ 'filterBy' | translate }}" (input)="onStatusFilterChange()" class="form-control ng-select sort-by-select" [(ngModel)]="statusFilter" (ngModelChange)="onStatusFilterChange()">
      <!-- <ng-option value="" disabled selected>{{ "filterBy" | translate }}</ng-option> -->
      <ng-option value="" disabled selected>{{ 'filterBy' | translate }}</ng-option>


      <!-- FILTER BY STATUS -->
      <ng-option value="Healthy">{{ "Healthy" | translate }}</ng-option>
      <ng-option value="Unhealthy">{{ "Unhealthy" | translate }}</ng-option>
      <ng-option value="Inactive">{{ "Inactive" | translate }}</ng-option>

      <!-- FILTER BY BIN FILL LEVEL -->
      <ng-option value="low">{{ "Fill Level Low" | translate }}</ng-option>
      <ng-option value="medium">{{ "Fill Level Medium" | translate }}</ng-option>
      <ng-option value="high">{{ "Fill Level High" | translate }}</ng-option>

      <!-- FILTER BY BATTERY LEVEL -->
      <ng-option value="lowBattery">{{ "Battery Level Low" | translate }}</ng-option>
      <ng-option value="mediumBattery">{{ "Battery Level Medium" | translate }}</ng-option>
      <ng-option value="highBattery">{{ "Battery Level High" | translate }}</ng-option>

      <!-- <ng-option value="legal_name">{{ "clientcreateFormInputClientName" | translate }}</ng-option> -->
    </ng-select>
    <ng-select class="form-control ng-select sort-by-select"
           [(ngModel)]="sortBy" 
           (change)="onSortChange('select')" 
           placeholder="{{ 'sortBy' | translate }}">
      <ng-option value="" disabled selected>{{ 'sortBy' | translate }}</ng-option>
      <!-- <ng-option [value]="undefined" disabled selected>{{ 'sortBy' | translate }}</ng-option> -->
      <ng-option value="battery">{{' '}}{{ 'BatteryLevel' | translate }}</ng-option>
      <ng-option value="binFillLevel">{{' '}}{{ 'BinLevel' | translate }}</ng-option>
      <ng-option value="lastUpdate">{{' '}}{{ 'LastActivity' | translate }}</ng-option>
    </ng-select>

    <div class="card-title status" *ngIf="this.sortBy !== null && this.sortBy !== undefined && this.sortBy !== ''">
      <div class="mobileAlign">
        <label class="switch">
          <input type="checkbox" [ngModel]="this.ascDesc" (change)="onSortChange('switch')">
          <span class="slider round">
              <span class="toggle-switch-label-off">{{ "desc" | translate }}</span>
              <span class="toggle-switch-label-on">{{ "asc" | translate }}</span>
          </span>
        </label>
      </div>
    </div>
  </nav>
  <nav *ngIf="this.dataLoaded" id="search-component" class="nav-sort-filter">
    <button
            [ngClass]="theme.getThemeClass()"
            class="btn bg-light text-dark"
            (click)="showReportModal()"
            
        >
            <span >
            {{ "reportModal" | translate }}
            </span>
            
        </button>
  </nav>
  
  
  <!-- Gridster Parent Container -->
  <section *ngIf="this.dataLoaded" class="gridThing" [ngClass]="theme.getThemeClass()">
    
    <!-- Navigation Items -->
    <ng-container *ngFor="let thing of this.filteredArray">
      <!-- Tile -->
      <article  class=gridsterItemClientThing>
        <!-- Your tile content goes here -->
        <nav [ngClass]="theme.getThemeClass()" class="dashboardThing-tile">
          <header class="dashboard-tile-header">
            <h6 class="dashboardThing-tile-title">{{thing.thing_name | translate}}</h6>
            <span class="dashboard-tile-action-container">
              
              <a class="nav-link e" href="javascript:void(0);" data-bs-toggle="dropdown">
                <i class="fa fa-bars"></i>
              </a>
              <ul [ngClass]="theme.getThemeClass()" class="dropdown-menu dropdown-menu-lg-end">
                <!-- Menu Items -->
                  <li>
                    <a [ngClass]="theme.getThemeClass()" class="dropdown-item" href="/device-statistics/{{thing.thing_name}}">
                      <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                      <span class="customMarginLeft1">
                        {{ 'deviceStatistics' | translate }}
                      </span>
                    </a>
                  </li>
                  <li [ngClass]="{'closed': thing.binId === ''}">
                    <a  (click)="routeToUpdateBin(thing.binId)" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)" >
                      <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                      <span class="customMarginLeft1" [ngClass]="{'closed': thing.binId === ''}">
                        {{ 'viewBin' | translate }}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a (click)="exportCSV(thing)" [ngClass]="theme.getThemeClass()" class="dropdown-item" href="javascript:void(0)">
                      <i class="textAlignCenter" [ngClass]="thing.icon"></i>
                      <span class="customMarginLeft1">
                        {{ 'exportCSV' | translate }}
                      </span>
                    </a>
                  </li>
              </ul>
            </span>
          </header>
          <div [ngClass]="theme.getThemeClass()" class="dashboardThing-tile-content" >
            <div class="itemsDisplay">
              <div class="firstRow">
                <!-- HEALTHY -->
                <div class="itemsColum">
                  <div class="fa-3x">
                    <i *ngIf="thing.status === 'Healthy'" class="fa-solid fa-heart-pulse fa-fade greenC" [title]="'Healthy'"></i>
                    <i *ngIf="thing.status === 'Inactive'" class="fa-solid fa-heart-pulse grayC" [title]="'Inactive'"></i>
                    <i *ngIf="thing.status === undefined" class="fa-solid fa-heart-pulse grayC" [title]="'Inactive'"></i>
                    <i *ngIf="thing.status === 'Unhealthy'" class="fa-solid fa-heart-pulse fa-fade redC" [title]="'Unhealthy'"></i>                 
                  </div>
                  <div class="iconLabel">
                    {{ thing.status | translate }}
                  </div>
                </div>                
                <!-- BATTERY -->
                <div class="itemsColum">
                  <div class="fa-3x">
                    <i *ngIf="thing.status === 'Inactive'" class="fa-solid fa-battery-empty grayC"   [title]="'Inactive'"></i>
                    <i [ngStyle]="getBatteryLevel(thing.battery)" *ngIf="thing.battery > 3.5 && thing.status != 'Inactive'" class="fa-solid fa-battery-full"  [title]="thing.battery + '%'"></i>
                    <i [ngStyle]="getBatteryLevel(thing.battery)" *ngIf="thing.battery <= 3.5 && thing.battery > 2.5 && thing.status != 'Inactive'" class="fa-solid fa-battery-three-quarters"  [title]="thing.battery + '%'"></i>
                    <i [ngStyle]="getBatteryLevel(thing.battery)" *ngIf="thing.battery <= 2.5 && thing.battery > 1.5 && thing.status != 'Inactive'" class="fa-solid fa-battery-half"  [title]="thing.battery + 'V'"></i>
                    <i [ngStyle]="getBatteryLevel(thing.battery)" *ngIf="thing.battery <= 1.5 && thing.battery > 0 && thing.status != 'Inactive'" class="fa-solid fa-battery-quarter fa-fade"  [title]="thing.battery + '%'"></i>
                    <i [ngStyle]="getBatteryLevel(thing.battery)" *ngIf="thing.battery == 0 && thing.status != 'Inactive' " class="fa-solid fa-battery-empty fa-fade" [title]="thing.battery + '%'"></i>
                    <i [ngStyle]="getBatteryLevel(thing.battery)" *ngIf="thing.battery == null || thing.battery == undefined" class="fa-solid fa-battery-empty grayC"  [title]="'Inactive'"></i>
                  </div>
                  <div *ngIf="thing.battery >= 0 && thing.battery <= 100 && thing.status != 'Inactive'" class="iconLabel">
                    {{ thing.battery  + '%' | translate }}
                  </div>
                  <div *ngIf="thing.battery > 100 && thing.status === 'Inactive' " class="iconLabel">
                    {{ 'Inactive' | translate }}
                  </div>  
                  <div *ngIf="thing.battery > 100 && thing.status != 'Inactive' " class="iconLabel">
                    {{ 'iotModalViewDeviceBatInCharge' | translate }}
                  </div>                   
                </div>                
                <!-- FILL LEVEL -->
                <div class="itemsColum">
                  <div class="fa-3x">
                    <i [ngStyle]="getFillLevelStyle(thing.fillLevel)" *ngIf="thing.status === 'Healthy'" class="fa-solid fa-trash-can grayC" [title]="thing.fillLevel"></i>
                    <i [ngStyle]="getFillLevelStyle(thing.fillLevel)" *ngIf="thing.status === 'Unhealthy'" class="fa-solid fa-trash-can fa-fade grayC" [title]="thing.fillLevel"></i>
                    <i  *ngIf="thing.status === 'Inactive' || thing.status === undefined"  class="fa-solid fa-trash-can grayC" [title]="thing.fillLevel"></i>                    
                    
                  </div>
                  <div class="iconLabel">
                    {{ thing.fillLevel | translate }}
                  </div>                  
                </div>  
              </div>           
              <div class="secondRow">
                <div class="locationLabel" [hidden]="!thing.hasBin">
                  <i  class="fa-solid fa-map-pin locationLabel"></i>{{ thing.location | translate }}
                </div>
                <div class="locationLabel" [hidden]="thing.hasBin">
                  <i  class="fa-solid fa-map-pin locationLabel"></i>{{ 'withoutBin' | translate }}
                </div>
                <!-- <div class="locationLabel" [hidden]="thing.hasBin && !thing.hasBin">
                  <i  class="fa-solid fa-map-pin locationLabel"></i>{{ 'missingAddress' | translate }}
                </div> -->
              </div>  
            </div>
          </div>
        </nav>
      </article>
    </ng-container>
  </section>

  <app-report-modal class="appDeviceConfigModal" [hidden]="!this.reports.showReportModal"></app-report-modal>
</div>

<!-- Graph Canvas for PDF Report -->
<div id="canvas-holder" style="max-height: 300px;overflow-y: auto;">
  <canvas #tonnageCanvas style="display: none"></canvas>
  <canvas #usageCanvas style="display: none"></canvas>
  <canvas #marketCanvas style="display: none"></canvas>
  <canvas #countCanvas style="display: none"></canvas>
</div>