import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, Subject } from 'rxjs';

export interface TaxeType{
  taxe_type_id: string;
  ISO_code: string;
  taxe_type: string;
  taxe_name: string;
  rate: number;
  is_federal: number;
  active: number;
  create: number;
  modified: number;
}

@Injectable({
  providedIn: 'root'
})
export class TaxeTypeService {

  taxe_type_data = {
    taxe_type_id: '',
    ISO_code: '',
    taxe_type: '',
    taxe_name: '',
    rate: 0,
    is_federal: 0,
    active: 0,
    create: 0,
    modified: 0
  }

  // Variable used for message when CRUD is done
  public successMessage: any;

  // Variable used for user filter
  private filter_count: number = 0;

  // Variable for validation
  public emptyField: boolean = false;

  // Array of taxe-type
  public array: any; // Tmp array used when we get returned data from Lambda function
  public taxe_type_array: TaxeType[] = [];
  public original_taxe_type_array: TaxeType[] = [];
  private tmpJSONTransferArray: any;

  // Variables that bin and bin-model list will used to trigger there function when there is a create or update made
  private changeMade = new Subject<void>;
  changeMade$ = this.changeMade.asObservable();
  private noChangeMade = new Subject<void>;
  noChangeMade$ = this.noChangeMade.asObservable();

  constructor(private http: HttpClient) { }

  // Will be set whit filter service whit the count of the string(userFilter) to know when user do backspace and reset the array
  setCountFilter(count: number){
    this.filter_count = count;
  }

  getCountFilter(){
    return this.filter_count;
  }

  // Reset the filtered array whitout making a new call to AWS. Used when user clear filter or backspace
  resetTaxeTypeArray(){
    this.taxe_type_array = [...this.original_taxe_type_array];
  }

  // Function called from create/update bin and bin-model
  onSuccessCRUD(){
    // Call the function that will trigger bin-list or bin-model-list that there is a change made in the list
    this.changeHaveBeenMade();

    // Set a timeOut of 5 seconds so the message of success create or update will disapear
    setTimeout(() => {
      this.successMessage = '';
    }, 5000);
  }

  // Function called to check into tAXE TYPE DATA IF THERE's value when created or updated for an additionnal validation
  hasOwnProperty(CRUD:string){
    for(let key in this.taxe_type_data){
      if(CRUD === 'create'){
        // If there is an empty value it will return true to let the component know that there's an empty value and can't create or update data
        if(!this.taxe_type_data.hasOwnProperty(key) && !this.taxe_type_data['taxe_type_id']){
          this.emptyField = true;
        }
      }
      if(CRUD === 'update'){
        // If there is an empty value it will return true to let the component know that there's an empty value and can't create or update data
        if(!this.taxe_type_data.hasOwnProperty(key)){
          this.emptyField = true;
        }
      }
    }
  }

  // Funciton to transfert string in the JSON we receive into Integer as they are before
  switchJSONToIntForTaxTypeResponse(element: any){
    // Tableau of witch keys we need to check
    const keysToCheck = [
      'rate',
      'active',
      'isFederal',
      'created',
      'modified'
    ];
    // Call the function to transfert all value of the keys in Integer
    this.keyToTransfert(keysToCheck, element);
  }

  keyToTransfert(keysToCheck: any, element: any){
    keysToCheck.forEach((key: any) => {
      // Vérifier si la clé existe dans l'élément et si sa valeur est une chaîne
      if (typeof element[key] === 'string') {
          // Convertir la valeur de la clé en entier
          element[key] = parseInt(element[key], 10);
      }
    });
  }



  //////// Those functions are functions that trigger function whit subscribe in other components /////////


  // Function that is been subscribe in bin-list and bin-model-list when there is an create or update made in bin-create, bin-update, bin-model-create or bin-model-update
  // This function will made reinitialize the list of both when a change apend
  async changeHaveBeenMade(){
    this.changeMade.next();
  }


  async noChangeHaveBeenMade(){
    this.noChangeMade.next();
  }


  //////// Those CRUD functions are the one called by the components. Please do not do function bellow insted of function used to CALL Lambda functions /////////
  //////// No delete function is in because we just update the status of taxe-type ///////////


  // Create taxe type
  async createTaxeType(){
    // Call the function that call the lambda function
    this.createTaxeTypeLambda().subscribe((response) => {
      this.successMessage = response; // Return response from lambda and put it in success message so bin-list or bin-model-list and will be able to read it
      // if(this.successMessage.message === 'success'){
      //   this.onSuccessCRUD(); // Call a function that will set all thing for the UI
      // }else{
      //   this.noChangeHaveBeenMade();
      // }
    });
  }

  // Get all taxe type called from components
  getTaxeTypes(): Promise<any[]>{
    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.getTaxeTypeLambda().subscribe((response) => {
        // Put the array of taxe-type returned by the lambda function into an tmp array
        this.array = response;

        // Set the taxe_type_array and original_taxe_type_array whit the tmp array values
        this.taxe_type_array = this.array;
        this.original_taxe_type_array = this.array;
        resolve(this.array);
      });
    });
  }

  // Get taxe type by id
  getTaxeTypeById(taxe_type_id: string){
    // Loop thought the bin model array to get the selected bin
    for(let i = 0; i < this.taxe_type_array.length; i ++){
      // will select only the bin model in bin model array for the asked id
      if(this.taxe_type_array[i].taxe_type_id === taxe_type_id){
        this.taxe_type_data = this.taxe_type_array[i]; // Initiate binModelData whit the selected bin model in binModelArray
        break;
      }
    }
    return this.taxe_type_data; // Return binModelData
  }

  // Update taxe type
  async updateTaxeType(){
    // Call the function that made the call to lambda function and wait for is response
    this.updateTaxeTypeLambda().subscribe((response) => {
      this.successMessage = response;
      if(this.successMessage.message === 'success'){
        this.onSuccessCRUD(); // Call a function that will set all thing for the UI
      }else{
        this.noChangeHaveBeenMade();
      }
    });
  }


  ////////// All function bellow are function that call the lambda function from AWS. Please do not do functions bellow insted of Lambda calls /////////////
  //////// No delete function is in because we just update the status of taxe-type ///////////



  // Create a Bin
  createTaxeTypeLambda(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
     'Content-Type':  'application/json' // Adjust content type as needed
   });
   // Will call the lambda function in createBin url whit the passed data then return a response
   return this.http.post(environment.api.stage + environment.api.route.createTaxType, {
     // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
       "ISO_code": this.taxe_type_data.ISO_code,
       "taxe_type": this.taxe_type_data.taxe_type,
       "taxe_name": this.taxe_type_data.taxe_name,
       "rate": this.taxe_type_data.rate,
       "is_federal": this.taxe_type_data.is_federal,
       "created": this.taxe_type_data.create,
       "modified": this.taxe_type_data.modified
     }, { headers: headers }
   );
 }

  // Get all taxe type whit lambda
  getTaxeTypeLambda() {
    // Initiate url whit the url of lambda fonction getBins
    const url = environment.api.stage + environment.api.route.getTaxType;
    // Call the lambda fonction whit the url
    return this.http.get(url).pipe(
      map((response) => {
        this.tmpJSONTransferArray = response;

        this.tmpJSONTransferArray = this.tmpJSONTransferArray.forEach((element: any) => {
          this.switchJSONToIntForTaxTypeResponse(element);
        });
        return response;
      }),
      catchError((error) => {
        console.error('API Error:', error);
        throw error; // Re-throw the error for the calling code to handle
      })
    );
  }

  // Update the taxe type
  updateTaxeTypeLambda(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Call lambda fucntion whit the url of updateBin  and return the response
    return this.http.post(environment.api.stage + environment.api.route.updateTaxType, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "taxe_type_id": this.taxe_type_data.taxe_type_id,
        "ISO_code": this.taxe_type_data.ISO_code,
        "taxe_type": this.taxe_type_data.taxe_type,
        "taxe_name": this.taxe_type_data.taxe_name,
        "rate": this.taxe_type_data.rate,
        "is_federal": this.taxe_type_data.is_federal,
        "active": this.taxe_type_data.active,
        "modified": this.taxe_type_data.modified
      }, {headers : headers}
    );
  }
}
