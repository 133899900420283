<!-- Container -->
<div [ngClass]="theme.getThemeClass()" id="chart-container" class="chart-container">
  <!-- Chart -->
  <ngx-charts-bar-vertical id="bar-chart"
    [view]="charts.viewBarChart"
    [scheme]="charts.colorSchemeBarChart"
    [results]="charts.singleBarChart"
    [gradient]="charts.gradientBarChart"
    [xAxis]="charts.showXAxisBarChart"
    [yAxis]="charts.showYAxisBarChart"
    [legend]="charts.showLegendBarChart"
    [legendTitle]="charts.legendTitleBarChart"
    [showXAxisLabel]="charts.showXAxisLabelBarChart"
    [showYAxisLabel]="charts.showYAxisLabelBarChart"
    [xAxisLabel]="charts.xAxisLabelBarChart"
    [yAxisLabel]="charts.yAxisLabelBarChart"
    (select)="charts.onSelectBarChart($event)"
    [legendPosition]="charts.legendPositionBarChart"
    >
  </ngx-charts-bar-vertical>
</div>
