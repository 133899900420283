<app-system-message></app-system-message>

<!-- Container for the form with dynamic styling -->
<div [ngClass]="theme.getThemeClass()" class="container-fluido">
    <section [ngClass]="theme.getThemeClass()" class="card bg-light">
        <article class="card-body mx-auto article">
            <h4 class="card-title mt-0 text-center">{{ 'distributorcreateHeaderCreateDistributor' | translate }}</h4>
            <form>
                <!-- Input field for distributor name -->
                <label class="label-spacer" for="distributor_name">{{ 'name' | translate }}</label>
                <div class="input-group mb-3 firstInput">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-user"></i></span>
                    <input [(ngModel)]="this.distributorsService.distributorData.distributor_name" type="text"
                    class="form-control" name="distributor_name"
                    placeholder="{{ 'name' | translate }}"
                    aria-label="Distributor Name" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'usernameInvalid'">
                </div>
                <!-- Input field for legal name -->
                <label class="label-spacer" for="legal_name">{{ 'legalName' | translate }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-address-card"></i></span>
                    <input [(ngModel)]="this.distributorsService.distributorData.legal_name" type="text"
                    class="form-control" name="legal_name"
                    placeholder="{{ 'legalName' | translate }}"
                    aria-label="Legal Name" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientLegalNameInvalid'">
                </div>
                <!-- Input field for email -->
                <label class="label-spacer" for="email">{{ 'email' | translate }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope"></i></span>
                    <input [(ngModel)]="this.distributorsService.distributorData.email" type="text"
                    class="form-control" name="email"
                    placeholder="{{ 'email' | translate }}"
                    aria-label="Email" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientEmailInvalid'">
                </div>
                <!-- Input field for phone number -->
                <label class="label-spacer" for="phone_number">{{ 'phoneNumber' | translate }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-phone"></i></span>
                    <input [(ngModel)]="this.distributorsService.distributorData.phone_number" type="text"
                    class="form-control telefone-input" name="phone_number"
                    placeholder="(000) 000-0000" aria-label="Phone Number"
                    aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientPhoneInvalid'">
                </div>
                <!-- Input field for address with Autocomplete -->
                <label class="label-spacer" for="address">{{ 'address' | translate }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-search-location"></i></span>
                    <input [(ngModel)]="this.distributorsService.distributorData.address" id="autocomplete"
                    type="text" class="form-control" name="address"
                    placeholder="{{ 'clientcreateFormInputFindAddress' | translate }}"
                    aria-label="Enter a place" aria-describedby="basic-addon1"
                    [class.error-border]="this.validationService.validationErrorArray[0] == 'clientAddressInvalid'">
                </div>

                <!-- Submit button -->
                <nav class="form-group displayFlex">
                    <button [ngClass]="theme.getThemeClass()" (click)="pageReload()" type="submit" class="btn btn-primary btn-block btnCancel fixed-size">{{ 'cancel' | translate }}</button>
                    <button [ngClass]="theme.getThemeClass()" (click)="createBtn()" type="submit" class="btn btn-primary btn-block btnCreate fixed-size">{{ 'create' | translate }}</button>
                </nav>                
            </form>
        </article>
    </section>
</div>





