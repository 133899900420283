import { Component, OnInit, HostListener, Inject, forwardRef  } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { CognitoService } from '../service/cognito.service';
import { ThemeService } from '../service/theme.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
  // Variable to store the language selected value
  public selectedLanguage: string = "";

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  constructor(
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private cognitoService: CognitoService,
    public  theme: ThemeService
    ) {
      this.cognitoService.confirmValidUser();
      // Check if the user has selected a language in local storage
      //or use a default language
      if (this.languageStatus == null){
        // Set the default language to French
        translate.use('fr');
      } else {
        // Set the default language to the user's selected language
        translate.use(this.languageStatus);
      }
    }

  // Main content area identifier
  public mainContent: string = '';

  // User input for filtering
  public userFilterTest: string ="";

  // Flags and indicators
  public showList: boolean = false; // Indicates whether to show the filtered list
  public filterOn: boolean = false; // Indicates whether filtering is active
  public loadingMoreData = false; // Indicates whether more data is being loaded
  public isApiActive: boolean = false; // Indicates whether the API section is active
  public isActionsActive: boolean = false; // Indicates whether the Actions section is active
  public isEventsActive: boolean = false; // Indicates whether the Events section is active
  public isSystemsActive: boolean = false; // Indicates whether the Systems section is active
  public isNotificationsActive: boolean = false; // Indicates whether the Notifications section is active

  // Initialize the component on initialization
  ngOnInit(): void {
    // Reset button status indicators
    this.clearButtonsStats();
    // Set the API section as active by default
    this.apiClicked();
  }

  // Change language based on user selection
  changeLanguage(language: string) {
    this.translate.use(language);
  }

  // Listen for window scroll event
  @HostListener('window:scroll', []) onScroll(): void {
    // Calculate various scroll-related measurements

    // Determine the height of the visible window
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;

    // Get references to the <body> and <html> elements
    const body = document.body;
    const html = document.documentElement;

    // Calculate the total height of the document
    const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    // Calculate the total height of the document
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
  }

  // Clear button states
  clearButtonsStats() {
    // Reset button states to false
    this.isApiActive = false;
    this.isActionsActive = false;
    this.isEventsActive = false;
    this.isSystemsActive = false;
    this.isNotificationsActive = false;
  }

  // Handle API button click
  apiClicked(){

    // Clear the states of all buttons
    this.clearButtonsStats();

    // Set the API button state to active
    this.isApiActive = true;

    // Set the main content to display API-related content
    this.mainContent = "api";
  }

  // Handle Actions button click
  actionsClicked() {
    // Clear the states of all buttons
    this.clearButtonsStats();

    // Set the Actions button state to active
    this.isActionsActive = true;

    // Set the main content to display action-related content
    this.mainContent = "action"
  }

  // Handle Events button click
  eventsClicked() {
    // Clear the states of all buttons
    this.clearButtonsStats();

    // Set the Events button state to active
    this.isEventsActive = true;

    // Set the main content to display event-related content
    this.mainContent = "events"
  }

  // Handle Systems button click
  systemsClicked() {
    // Clear the states of all buttons
    this.clearButtonsStats();

    // Set the Systems button state to active
    this.isSystemsActive = true;

    // Set the main content to display systems-related content
    this.mainContent = "systems"
  }

  // Handle Notifications button click
  notificationsClicked() {
    // Clear the states of all buttons
    this.clearButtonsStats();

    // Set the Notifications button state to active
    this.isNotificationsActive = true;

    // Set the main content to display systems-related content (might be a typo, should it be "notifications" instead of "systems"?)
    this.mainContent = "systems"
  }

}
