import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { ClientService } from '../service/client.service';
import { CognitoService } from '../service/cognito.service';
import { RoleService } from '../service/role.service';
import { BinUsage } from '../constants/bin-usage';
import { MarketSegment } from '../constants/market-segments';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-client-bin-dashboard',
  templateUrl: './client-bin-dashboard.component.html',
  styleUrls: ['./client-bin-dashboard.component.css', '../../global-elements.css']
})
export class ClientBinDashboardComponent implements OnInit {
  public clientBinArray: any[] = [];
  private originalClientBinArray: any[] = [];
  private array: any;

  public userFilter: string = '';
  public filterBy: string = '';
  private filterCount: number = 0;
  private lastFilterCount: number = 0;
  private isFilter: boolean = false;
  public sortBy: string = '';
  public ascDesc: number = 0;

  public isPlaceholderVisible: boolean = true;

  constructor(public theme: ThemeService,
              public clients: ClientService,
              public cognitoService: CognitoService,
              private roleService: RoleService){

  }

  async ngOnInit() {
    await this.cognitoService.confirmValidUser();
    await this.cognitoService.getUserType();
    // Call role service to get the current user roles
    await this.roleService.getRoles();

    // Check if user have all permission to be in that component
    this.cognitoService.getCurrentRole([environment.users.role.administrator, environment.users.role.client, environment.users.role.driver], [environment.users.superAdmin, environment.users.standardUser], true, this.roleService.roles);

    await this.getClientBin();
  }

  // Get ll bins for the client
  getClientBin(){
    return new Promise<any[]>((resolve) => {
      // Call the function that call the lambda function
      this.clients.getBinByClientId(this.cognitoService.clientId).subscribe(async (response) => {
        // Put the array of Bins returned by the lambda function into an tmp array
        this.array = response;
        await this.replaceTypeOfWasteAndMarketSegmentKeys();

        this.clientBinArray = this.array;
        // Set the bin_array and original_bin_array whit the tmp array values
        this.originalClientBinArray = this.array;
        resolve(this.clientBinArray);
      });
    });
  }

  resetBinArray(){
    this.clientBinArray = [...this.originalClientBinArray];
  }

  search(){
    // Set last filter count, filter count and if there's a filter
    this.lastFilterCount = this.filterCount;
    this.isFilter = this.userFilter.toLowerCase() !== '';
    this.filterCount = this.userFilter.length;

    let filterProperty = this.filterBy;
    if(filterProperty === '' || filterProperty === undefined){
      filterProperty = 'bin_address';
    }

    // Switch if user enter more filter, press backspace or delete filter
    switch(true){
      case (this.filterCount > this.lastFilterCount):
        this.clientBinArray = this.clientBinArray.filter( v => {
          if(v[filterProperty] && typeof v[filterProperty] === 'string'){
            console.log('toto');
            return v[filterProperty].toLowerCase().includes(this.userFilter);
          }else{
            return false;
          }
        });
        this.lastFilterCount = this.filterCount; // Set the count of the user filter
        break;

      case (this.filterCount < this.lastFilterCount):
        this.resetBinArray(); // Reset the array of bin models
        // Filter the array of bin models
        this.clientBinArray = this.clientBinArray.filter( v => {
          if(v[filterProperty] && typeof v[filterProperty] === 'string'){
            return v[filterProperty].toLowerCase().includes(this.userFilter);
          }else{
            return false;
          }
        });
        this.lastFilterCount = this.filterCount; // Set the count of the user filter
        break;

      case (this.isFilter === false):
        this.resetBinArray(); // Reset the array of bin models
        this.filterCount = 0;
        this.lastFilterCount = 0;
        break;
    }
  }

  onSortChange(caller: string){
    this.isPlaceholderVisible = false;

    // Switch the value of ascending or descending
    if(caller === 'switch'){
      if(this.ascDesc === 0){
        this.ascDesc = 1;
      }else{
        this.ascDesc = 0;
      }
    }

    // Avoid sortBy to have no value
    if(this.sortBy === ''){
      this.sortBy = 'address';
    }

    // Function that sort the list
    this.clientBinArray.sort((a, b) => {
      switch(this.sortBy){
        case 'address':
          return this.ascDesc === 0 ? b.bin_address.localeCompare(a.bin_address) : a.bin_address.localeCompare(b.bin_address);

        case 'thing_name':
          if (a.thing_name && b.thing_name) {
            return this.ascDesc === 0 ? b.thing_name.localeCompare(a.thing_name) : a.thing_name.localeCompare(b.thing_name);
          } else {
            // If one of the instance in the array don't have thing_name it will put it at the end of the array
            if (a.thing_name) {
              return this.ascDesc === 0 ? -1 : 1; // Put a before if he have thing_name
            } else if (b.thing_name) {
              return this.ascDesc === 0 ? 1 : -1; // Put b before if he have thing_name
            } else {
              return 0;
            }
          }

        case 'bin_model_number':
          return this.ascDesc === 0 ? b.bin_model_number.localeCompare(a.bin_model_number) : a.bin_model_number.localeCompare(b.bin_model_number);
      }
    });
  }

  // Function called to get the column style of the grid list
  getColumnStyle(): any {
    const numberOfColumn = this.ajustNumberOfColumns(this.getScreenWidth());
    return  `repeat(${numberOfColumn}, minmax(350px, 1fr))`;
  }

  // Function called to replace the key of type of waste by there name
  async replaceTypeOfWasteAndMarketSegmentKeys(){
    this.array.forEach((item: any) => {
      const binUsage = BinUsage.find(usage => usage.value === item.bin_usage);
      const binMarketSegment = MarketSegment.find(marketSegment => marketSegment.value === item.market_segment);
      if(binUsage){
        item.bin_usage = binUsage.label;
      }
      if(binMarketSegment){
        item.market_segment = binMarketSegment.label;
      }
    });
  }

   // function called to adjust the number of column of the grid depbending of inner window width
   ajustNumberOfColumns(width: number): number | undefined{
    // Return the floor of the inner page width divided by 325 px as the width of the tile size
    return Math.floor((width / 325) * (85 / 100));
  }

  // Function to get the current screen width
  getScreenWidth(): number {
    // Return the inner width of the window as the screen width
    return window.innerWidth;
  }
}
