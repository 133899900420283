import { enableDebugTools } from '@angular/platform-browser';
import { Injectable, Pipe } from '@angular/core';
import { environment } from '../environments/environment';
import * as AWS from 'aws-sdk';
import { Amplify, Auth } from 'aws-amplify';
import { BehaviorSubject, subscribeOn, map, Subject, Observable, catchError, lastValueFrom } from 'rxjs';
import { Shadow } from '../constants/shadow';
import { LocalizationService } from './localization.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CognitoService, CognitoIdentityServiceProvider } from './cognito.service';
import * as JSZip from 'jszip';
import { error } from 'jquery';
import { S3Service } from './s3.service';
import { SystemMessageService } from './system-message.service';
import { ModalService } from './device-modal.service';
import { RoleService } from './role.service';
import { BinsService, BinData } from './bins.service';
import { LegendPosition } from '@swimlane/ngx-charts';

interface BinArrayItem {
  thing_name: string;
  bin_id: string;
}


export interface DeviceConfig{
  dst : number;
  env : number;
  gps : number;
  gtc : number;
  his : number;
  hit : number;
  img : number;
  lfr : number;
  slp : number;
  voc : number;
}

export interface DeviceCarrier{
  fmv: string;
  icc: number;
  imei: number;
  oper: string;
  phn: string;
}

export interface DeviceDat{
  bat: number;
  dst: number;
  fdv: string;
  hib: number;
  hum: number;
  img: [];
  nct: number;
  pre: number;
  sig: number;
  tm0: number;
  tm2: number;
  tmp: number;
  vcc: number;
  voc: number;
}

export interface ThingToCreate{
  thingName: AWS.IotData.ThingName
}

export interface Devices{
  thingName: string;
  bin?: BinData;
  thingARN: string;
  thingTypeName: string;
  gps: {latitude: number,longitude: number};
  location: string;
  lastUpdate: number;
  date: string;
  config: DeviceConfig;
  car: DeviceCarrier;
  dat: DeviceDat;
}

export interface MultipleDevices{
  devices: Devices[];
}
export interface Device {
  thingName: string;
  thingTypeName: string;
  thingArn: string;
  attributes: any; // Defina o tipo correto para attributes
  version: number;
}

export interface DeviceStatus {
  thingName: string;
  bin_id?: string;
  client_id?: string,
  distributor_id?: string,
  bind_model?: string;
  status?: string;
  last_update?: number;
  batery_level?: number;
  distance_to_lid?: number,
  bin_capacity?: any,
  fill_level?: any
}

export interface ThingListForDistributorsAndClients {
  thingName: string;
  bin_id?: string;
  client_id?: string,
  client?: string,
  distributor_id?: string,
  distributor?: string,
  bind_model?: string;
  status?: string;
  last_update?: number;
  batery_level?: number;
  distance_to_lid?: number,
  bin_capacity?: any
}

@Injectable({
  providedIn: 'root'
})

export class IotService {
  // Variables used for device association
  public selectedBinId: string = '';
  public selectedClientId: string = "";
  public selectedDistributorID: string = '';

  private iot: AWS.Iot;
  private iotData: AWS.IotData;

  // Variables for things
  public thingType: string = "MWS";
  public thingGroupName: string = "";
  public thingShadow: any = {};
  public thingName: string = '';

  // Variables used for shadow update
  public shadowUpdated: boolean = false;

  public deviceAssociationRow: any;

  private date: string = '';

  public relationships: BinArrayItem[] = [];

  //variable used to show the delete thing modal
  public showDeleteThing: boolean = false;
  public showDeleteDataThing: boolean = false;
  public desiredDeleteThingName: string = '';
  public deleteReference: boolean = false;
  public deleteLogs: boolean = false;
  public deleteReportDatas: boolean = false;

  device: Devices = {
    thingName: '',
    thingARN: '', // Initialize other properties as needed
    thingTypeName: '',
    gps: {latitude : 0, longitude : 0},
    location: '',
    lastUpdate: 0,
    date: '',
    config: {
      dst: 0,
      env: 0,
      gps: 0,
      gtc: 0,
      his: 0,
      hit: 0,
      img: 0,
      lfr: 0,
      slp: 0,
      voc: 0,
    },
    car: {
      fmv:  '',
      icc:  0,
      imei: 0,
      oper: '',
      phn:  '',
    },
    dat: {
      bat: 0,
      dst: 0,
      fdv: '',
      hib: 0,
      hum: 0,
      img: [],
      nct: 0,
      pre: 0,
      sig: 0,
      tm0: 0,
      tm2: 0,
      tmp: 0,
      vcc: 0,
      voc: 0,
    }
  };

  public pourcentage: number = 0;
  public inCharge: boolean = false;
  public color: string = '';

  public ARRAY: any[] = [];

  // Variable to show/hide HTML components device-config-modal
  public showDeviceConfigModal: boolean = false;

  public filteredArray: any[] = [] //Contains filtered results
  public devicesStatusArray: DeviceStatus[] = [];// Array of Device Status Objects
  public clientListThingsArray: DeviceStatus[] = [];
  public distributorListThingsArray: DeviceStatus[] = [];

  public listThingsArray: ThingListForDistributorsAndClients[] = [] // Array of List Things Objects
  public devicesArray: Devices[] = []; // Array of Device Objects
  public binArray: BinData[] = [];
  public thingArray: any = []; //Used for Iteration
  public biggestDeviceNumber: any //Used to Determine the Min starting number of a new device
  lastDataFromAdminMap: number[] = []; //Coordinate Array for Zones

  // Variable used to display messages in lits when you create/update
  public successMessage: any;

  public cityLocation: string = '';

  // Variables needed for the filter
  public originalDevicesArray: Devices[] = [];
  public filterCount: number = 0;

  // Varirable that will be use to share things for device between components when you are in device-modal
  private selectedDevice = new BehaviorSubject<Devices | null>(null);
  selectedDevice$ = this.selectedDevice.asObservable();

  private shadowUpdate = new Subject<void>;
  shadowUpdate$ = this.shadowUpdate.asObservable()

  // Public property to store device bin ID
  public deviceBinId: any;

  // Public property to store bin capacity
  public binCapacity: any;

  public filteredThingListByClient: any;
  public filteredThingListByDistributor: any;

  public shadowsArray: any[] = [];


  constructor(private localization: LocalizationService,
    private http: HttpClient,
    private cognito: CognitoService,
    private s3: S3Service,
    private systemMessageService: SystemMessageService,
    public modal: ModalService,
    private roleService: RoleService,
    public cognitoService: CognitoService,
    private binService: BinsService
    ) {

      Amplify.configure({
        cognito: environment.cognito
      })

      // Set up AWS configuration
      AWS.config.update({
        region: environment.iot.region,
        sessionToken: sessionStorage.getItem("sessionToken") || "",
        accessKeyId: sessionStorage.getItem("accessKeyId") || "",
        secretAccessKey: sessionStorage.getItem("secretAccessKey") || "",
        credentials: {
          sessionToken: sessionStorage?.getItem("sessionToken") || "",
          accessKeyId: sessionStorage.getItem("accessKeyId") || "",
          secretAccessKey: sessionStorage.getItem("secretAccessKey") || "",
        },
        iotdata:{
          endpoint: environment.iot.endpoint
        }
      });

      // Create instances of the IoT and IoT Data services
      this.iot = new AWS.Iot({
        sessionToken: sessionStorage.getItem("sessionToken") || "",
        accessKeyId: sessionStorage.getItem("accessKeyId") || "",
        secretAccessKey: sessionStorage.getItem("secretAccessKey") || "",
      });

      this.iotData = new AWS.IotData({
        endpoint: environment.iot.endpoint,
        credentials: AWS.config.credentials,
        region: environment.iot.region
      });

      this.setConfig();
  }

  async configure(){
    Amplify.configure({
      cognito: environment.cognito
    })

    //Returns temporary credentials for authenticated users
    const credentials = await Auth.currentCredentials();

    AWS.config.update({
      region: environment.iot.region,
      sessionToken: credentials.sessionToken,
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      credentials: {
        sessionToken: credentials.sessionToken,
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
      },
    });

    // Set up AWS configuration
    AWS.config.update({
      region: environment.iot.region,
      sessionToken: credentials.sessionToken,
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      credentials: {
        sessionToken: credentials.sessionToken,
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
      },
      iotdata:{
        endpoint: environment.iot.endpoint
      }
    });

    // Create instances of the IoT and IoT Data services
    this.iot = new AWS.Iot({
      sessionToken: credentials.sessionToken,
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
    });

    this.iotData = new AWS.IotData({
      endpoint: environment.iot.endpoint,
      credentials: AWS.config.credentials,
      region: environment.iot.region
    });

    await this.setConfig();
  }

  async getClientsThingList() {

    // Wait for the device list to be fetched before proceeding.
    await this.getDeviceList().then(() => {});

    // Return the array containing things for all clients.
    return this.clientListThingsArray;
  }

  async getDistributorsThingList() {

    // Wait for the device list to be fetched before proceeding.
    await this.getDeviceList().then(() => {});

    // Return the array containing things for all clients.
    return this.distributorListThingsArray;
  }

  async getThingListByClient(clientId: string) {

    // Wait for the device list to be fetched before proceeding.
    await this.getDeviceList().then(() => {});

    // Filter the device list based on the provided client ID.
    this.filteredThingListByClient = this.devicesStatusArray.filter(objeto => objeto.client_id === clientId);

    // Return the filtered array containing things for the specified client.
    return this.filteredThingListByClient;
  }


  async getThingListByDistributor(distributorId: string) {

    // Wait for the device list to be fetched before proceeding.
    await this.getDeviceList().then(() => {});

    // Filter the device list based on the provided distributor ID.
    this.filteredThingListByDistributor = this.devicesStatusArray.filter(objeto => objeto.client_id === distributorId);

    // Return the filtered array containing things for the specified distributor.
    return this.filteredThingListByDistributor;
  }

  //Attach Principal Policy to Authenticated User
  async setConfig() {
    try {

      const policy = await this.iot.getPolicy().send();
      const credentials = await Auth.currentCredentials();
      // Attach policy and principal policy
      this.iot.attachPolicy({
        policyName: 'MWS-B01',
        target: credentials.identityId
      }).send();

    } catch (error) {
      console.error('Error attaching policy:', error);
    }

  }

  //List all devices, excluding EGIOT devices
  async listThings() {
    await this.configure()
    try {

      const allThings = [];

      let nextToken: any  = null;
      do {

        const params: AWS.Iot.ListThingsRequest = {
          nextToken,
        };

        //Call IOT Core to get Things
        const listThingsResponse = await this.iot.listThings(params).promise();
        const things = listThingsResponse.things || [];

        // Add the current page of things to the array
        allThings.push(...things);

        // Check if there are more things to retrieve
        nextToken = listThingsResponse.nextToken;
      } while (nextToken);

      //Check if things[] is populated
      if (allThings.length === 0) {
        return []; // Return an empty array if there are no devices
      }

      //Filter EGIOT devices from our results
      const filteredThings = allThings.filter(thing => {
        // Check if thing is defined before accessing its properties
        return thing && thing.thingName && !thing.thingName.startsWith('EGIOT') && thing.thingTypeName?.startsWith('MWS');
      });
      // }).sort((a,b)=>{return a.thingName.localeCompare(b.thingName)});


      this.filteredArray.push(filteredThings)

      this.getLastDeviceNumber();
      return filteredThings;
      //return things;
    } catch (error) {
      this.cognitoService.checkForbidden(error);
      console.error('Error listing Things:', error);
      throw error;
    }
  }

  // Function that get last device number to increment it by 1
  getLastDeviceNumber() {
    this.biggestDeviceNumber = 0;

    let numbers = this.filteredArray[0].map((item: {
        thingName: string;
        thingTypeName: string;
        thingArn: string;
        attributes: any;
        version: number;
    }) => {
        const thingName = item.thingName;
        // Use uma expressão regular para extrair a última numeração
        const matches = thingName.match(/(\d+)$/);
        // Verifique se houve correspondência e retorne o número como inteiro
        return matches ? parseInt(matches[0]) : 0;
    });

    this.biggestDeviceNumber = Math.max(...numbers);
    this.biggestDeviceNumber++
  }

  //Get the Shadow for a specific device
  async getDeviceShadow(thingName: string) {
    try {

      // Specify the thingName of the device whose shadow you want to retrieve
      const params: AWS.IotData.GetThingShadowRequest = {
        thingName: thingName, // Use the device NAME here
      };

      // Call the getThingShadow API to retrieve the device shadow
      const shadowResponse = await this.iotData.getThingShadow(params).promise();

        // Check if the payload is present
        if (!shadowResponse.payload) {
          throw new Error('Payload is missing in the shadow response.');
        }

      // Parse and return the device shadow as a JSON object
      const shadow = JSON.parse(shadowResponse.payload as string);

      return shadow;
    } catch (error) {
      console.error(`Error retrieving shadow for device ${thingName}:`, error);

      this.cognitoService.verifySession();
    }
  }

  // Check health status using the provided thingName
  checkHealthStatusUsingThingName(thingName: any): string {

    // Find the device in the devicesStatusArray based on the thingName
    const device = this.devicesStatusArray.find(device => device.thingName === thingName);

    // Check if the device was found and if status is defined
    if (device && device.status !== undefined) {

        // Return the device status (assuming device.status is a string)
        return device.status;
    } else {
        // Handle the case where device is undefined or status is undefined
        // Return a default value, such as "Inactive"
        return "Inactive";
    }
  }

  checkDeviceFillLevelUsingThingName(thingName: any): string {
    const device = this.devicesStatusArray.find(device => device.thingName === thingName);

    if(device) {
      return device.fill_level
    } else {
      return "";
    }
  }

  // Function that will get all device name in the AWS list
  async getDeviceList(){
    this.devicesArray = [];
    this.filteredArray = [];
    this.originalDevicesArray = [];
    this.shadowsArray = [];



    // Get the array of all device name in the list and will loop through the array of attributes to made a new array of Devices
    await this.listThings().then(async (list: any) => {

      this.thingArray = list;
      // this.checkStatusFunction();
      let things = [];
      for(let i = 0; i < this.thingArray.length; i ++){
        if(list[i].thingName !== undefined){
          things.push(list[i].thingName);
        }
      }



      try{
        (await this.getThingShadows(things)).subscribe((results:any)=>{
          let i = 0
         // console.log("bins", this.binArray)
          this.shadowsArray = JSON.parse(results.shadows);
          let shadows = JSON.parse(results.shadows)
          let bins = JSON.parse(results.bins);
          for(const shadow of shadows){
              //Set some temporary variables
              let latitude = 0;
              let longitude = 0;
              let gpsBoolean = 0;
              let fmv = '';
              let icc = 0;
              let imei = 0;
              let oper = '';
              let phn = '';
              const thingDetails = this.getThingDetails(shadow.thing_name);

              // Set the date in format 'Thu Feb 29 2024 13:14:29 GMT-0500 (Eastern Standard Time)'
              const date = new Date(shadow.dst_timestamp * 1000);
              const formatedDate = this.formatDate(date);

            //Create the device shadow object
            const device: Devices = {
                  thingName: shadow.thing_name,
                  bin: this.binService.filterBinData(bins,shadow.thing_name),
                  thingARN: thingDetails?.thingARN ?? "",
                  thingTypeName: thingDetails?.thingTypeName ?? "",
                  gps: { latitude : latitude,longitude : longitude},
                  location: this.cityLocation,
                  lastUpdate: shadow.dst_timestamp ,
                  date: formatedDate,
                  config: {
                    dst: 0,
                    env: 0,
                    gps: 0,
                    gtc: 0,
                    his: 0,
                    hit: 0,
                    img: 0,
                    lfr: 0,
                    slp: 0,
                    voc: 0,
                  },
                  car: {
                    fmv:  shadow.fmv ?? fmv,
                    icc:  shadow.icc ?? icc,
                    imei: shadow.imei ?? imei,
                    oper: shadow.oper ?? oper,
                    phn:  shadow.phn ?? phn,
                  },
                  dat: {
                    bat: shadow.bat,
                    dst: shadow.dst,
                    fdv: shadow.fdv,
                    hib: shadow.hib,
                    hum: shadow.hum,
                    img: shadow.img,
                    nct: shadow.nct,
                    pre: shadow.pre,
                    sig: shadow.sig,
                    tm0: shadow.tm0,
                    tm2: shadow.tm2,
                    tmp: shadow.tmp,
                    vcc: shadow.vcc,
                    voc: shadow.voc,
                  }
                };
                //Push to Array
                this.devicesArray.push(device);
                i++;
          }

        });
      }
      catch(error){
        console.error(error);
        throw error;
      }



      }).catch((error) => {
        //Log errors for listThings()
        console.error('Error : ' + error);
        this.cognitoService.verifySession();
        return [];
      });

      // Put the devicesArray into an original array use to reset the filtered array whitout making a new call to AWS
      this.originalDevicesArray = this.devicesArray;
      console.log(this.devicesArray)
      //Return the final array
      return this.devicesArray;
  }

  getThingDetails(searchThingName: string): { thingARN: string, thingTypeName: string } | null {
    const foundThing = this.thingArray.find((thing:any) => thing.thingName === searchThingName);
    if (foundThing) {
        return {
            thingARN: foundThing.thingARN,
            thingTypeName: foundThing.thingTypeName
        };
    } else {
        return null; // Return null if no matching thingName is found
    }
  }

  formatDate(date: Date){
    const year = date.getUTCFullYear();
    const month =  ('0' + (date.getUTCMonth() + 1)).slice(-2);
    const day = ('0' + date.getUTCDate()).slice(-2);
    const hour = date.getUTCHours();
    const minute = ('0' + date.getUTCMinutes()).slice(-2);
    const second = ('0' + date.getUTCSeconds()).slice(-2);

    const regex = /GMT(.+?)\s*\(/;
    const match = date.toString().match(regex);

    if(match && match.length > 1){
      const GMT = match[1].trim();

      return `${year}-${month}-${day} ${hour}:${minute}:${second} GMT${GMT}`;
    }else{
      return `${year}-${month}-${day} ${hour}:${minute}:${second} GMT`;
    }

  }

  async getThingShadows(things: any = []){
     // Append the 'user' parameter to the URL as a query string
     const url = environment.api.stage + environment.api.route.getThingShadows;

     // Define the HTTP headers with content type
     const headers = new HttpHeaders({
       'Content-Type':  'application/json' // Adjust content type as needed
     });

     return this.http.post(url,
       {
         "things": things,
       }, {headers: headers}
     );
  }

   // Method to reorder the array by date in descending order
  reorderArrayByDateDescending(array: any) {
    array.sort((a:any, b:any) => {
        return b.lastUpdate - a.lastUpdate;
    });

    return array;
  }

  // Function to check the health status of a device
  // Parameters:
  // - lastUpdate: Timestamp of the last update in milliseconds
  // - distanceToLid: Distance from the lid in millimeters
  // - binCapacity: Total capacity of the bin in liters
  // - batteryVoltageRemaining: Remaining battery voltage in volts
  // Returns: Boolean indicating device health status (true if healthy, false if unhealthy)
  // to add fill level verification to health status check change to: checkDeviceHealthy(lastUpdate: number, distanceToLid: number, binCapacity: number, batteryVoltageRemaining: number) {
  checkDeviceHealthy(lastUpdate: number,  batteryVoltageRemaining: number) {
    // Check the time since the last update
    let now = new Date().getDate();
    const hoursDifference  = (now - lastUpdate) / (1000 * 60 * 60); // Calculate the difference in hours
    const hasExceeded48Hours: boolean = hoursDifference >= 48;

    // uncoment code below to activate fill level verification to health check
    // Check the fill level of the bin
    // const binCapacityInMM = binCapacity * 1000; // Convert capacity to millimeters
    // const percentageFill = ((binCapacity - distanceToLid) / binCapacity) * 100;
    // const percentageFillFixed = Math.max(0, Math.min(100, percentageFill));
    // const hasHighLevel: boolean = percentageFillFixed >= 80;

    // Check the battery level
     const percentageBattery = (batteryVoltageRemaining / 3.7) * 100;
     const hasLowBattery: boolean = percentageBattery < 30 || isNaN(percentageBattery);

    // Evaluate overall device health based on individual checks
    // to add fill level verification to health status check change to:if (hasLowBattery || hasExceeded24Hours || hasHighLevel) {
    if (hasLowBattery && hasExceeded48Hours) {
        return false;
    } else {
        // Device is healthy
        return true;
    }
  }

  /**
   * Retrieves a group of devices with identical coordinates based on input coordinates.
   *
   * @param dataFromAdminMap - An array containing latitude and longitude coordinates.
   * @param deviceList - A list of devices to filter based on coordinates.
   * @returns A Promise resolving to a list of devices with identical coordinates.
   */
  async getDeviceLocationGroup(dataFromAdminMap: number[], deviceList: Devices[]): Promise<any> {

    // Validate input coordinates
    if (dataFromAdminMap.length !== 2 || typeof dataFromAdminMap[0] !== 'number' || typeof dataFromAdminMap[1] !== 'number') {

      // Return an empty list for invalid coordinates.
      return Promise.resolve([]);
    }

    // Check if input coordinates are the same as the last ones
    if (dataFromAdminMap !== this.lastDataFromAdminMap) {

      // Update the last known coordinates
      this.lastDataFromAdminMap = dataFromAdminMap;

      // Filter devices with identical coordinates
      const listOfDevicesWithIdenticalCoordinates = deviceList.filter(device => {
        if (
          device.gps &&
          device.gps.latitude === dataFromAdminMap[0] &&
          device.gps.longitude === dataFromAdminMap[1]
        ) {
          return true;
        }
        return false;
      });

      // Return the list of devices with identical coordinates
      return Promise.resolve(listOfDevicesWithIdenticalCoordinates);
    }
  }

  // Reset the filtered array whitout making a new call to AWS
  resetDevicesArray(){
    this.devicesArray = [...this.originalDevicesArray];
  }

  // Will be set whit filter service whit the count of the string(userFilter) to know when user do backspace and reset the array
  setCountFilter(count: number){
    this.filterCount = count;
  }

  // Function called to reset the device array
  resetDeviceArray(){
    this.devicesArray = [];
  }

  // Will be called by the component that call filter service to share the last userFilter count
  getCountFilter(){
    return this.filterCount;
  }

  // Function called by iot-list to display the modal
  async showConfig(thingName: string){
    // Set thingShadow whit the getThingShadow function whit the device name
    this.thingShadow = await this.getDeviceShadow(thingName);
    this.thingName = thingName; // Set service thingName to the thingName received
    // Set the variable to true so the modal will be showed
    this.showDeviceConfigModal = true;
  }

  //Create a singular Thing for IOT Core. AWS-sdk limits Creation by 1 Thing per call.
  async createThing(thingName: AWS.IotData.ThingName): Promise<any> {
      // Step 1: Create the Thing
      const createThingParams: AWS.Iot.CreateThingRequest = {
        thingName: thingName,
        thingTypeName: this.thingType, // User selected option
      };

       // Step 2: Associate the Thing with the Thing Group
      const thingGroupParams: AWS.Iot.AddThingToThingGroupRequest = {
        thingName: thingName,
        thingGroupName: this.thingGroupName,
      };


      //Create Thing using aws-sdk
      return await this.iot.createThing(createThingParams).promise().then(() => {
          //Step 3: Adds Thing to Thing Group after creation
          this.iot.addThingToThingGroup(thingGroupParams).promise();
          this.updateCertificate(thingName);
          // Step 4: Update the Thing Shadow
          return this.updateThingShadow(thingName);
      });
  }

  //Called by @createThingAndUpdate
  updateThingShadow(thingName: string, shadow:any = ""): Promise<any> {

    if(shadow === ""){
      shadow = Shadow;
    }

    //Shadow is a global constant containing the Shadow structure including State and Metadata
    const updateThingShadowParams: AWS.IotData.UpdateThingShadowRequest = {
      thingName: thingName,
      payload: JSON.stringify(shadow),
    };

    return this.iotData.updateThingShadow(updateThingShadowParams).promise();
  }

  //Loops the provided (numberOfThings) number of times. Generates Thing Names and Creates a user-defined number of Things
  //Thing Name = EXAMPLE: MWS-BO1 (Thing Type + "-" + Thing Model)
  async createMultipleThings(numberOfThings: number, startingNumber: number, thingName: AWS.IotData.ThingName, isCustomSufix: boolean = false, customSufix: number = 0): Promise<any>{
    let currentThingNumber = 0;
    let currentThingName = "";
    let thingSuffix = "";
    for (let i = 0; i < numberOfThings; i++) {
      if(isCustomSufix){
        // Generate the current thing name based on the starting number and iteration
        currentThingNumber = customSufix;
        thingSuffix = customSufix.toString();
      }else{
        // Generate the current thing name based on the starting number and iteration
        currentThingNumber = startingNumber + i;
        thingSuffix = currentThingNumber.toString();
      }
      if(currentThingNumber < 10){thingSuffix = `00${currentThingNumber}`;}
      else if(currentThingNumber < 100){thingSuffix = `0${currentThingNumber}`;}
      this.thingName = `${thingName}-${thingSuffix}`; //Concat a unique Thing Name
      // Set the current date format to yyyyMMDD
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      this.date = `${year}${month}${day}`;

      //Create and update the Thing for the current iteration
      await this.createThing(this.thingName)
        .then(() => {
          this.setDeviceAssociationLambda().subscribe((response) => {
            this.successMessage = response;
          });
        })
        .catch((error) => {
          console.error(`Error creating or updating Thing ${currentThingName}:`, error);
          this.cognitoService.verifySession();
        });
    }
    return this.successMessage;
  }

  // Function that will update client_thing table in DB if already exist or will create if not
  setDeviceAssociationLambda() {
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in setThingClient url whit the passed data then return a response
    return this.http.post(environment.api.stage + environment.api.route.setDeviceAssociation, {
        "thing_name": this.thingName,
        "distributor_id": this.selectedDistributorID,
        "client_id": this.selectedClientId,
        "bin_id": this.selectedBinId,
        "created": this.date,
        "modified": this.date,
      }, { headers: headers }
    );
  }

  // Function called to update the association of the device in deice association modal
  async updateDeviceAssociationLambda(selectedClient: string, selectedBin: string, selectedDistributor: string, date: number){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Call lambda fucntion whit the url of updateBin  and return the response
    return this.http.post(environment.api.stage + environment.api.route.updateDeviceAssociation, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "thing_name": this.thingName,
        "distributor_id": selectedDistributor,
        "client_id": selectedClient,
        "bin_id": selectedBin,
        "modified": date
      }, {headers : headers}
    );
  }

  //Get all ThingTypes in IOT Core
  async  getThingTypes(): Promise<AWS.Iot.ListThingTypesResponse> {
    await this.configure();
    const params: AWS.Iot.ListThingTypesRequest = {};

    return this.iot.listThingTypes(params).promise();
  }

  //Get all ThingGroups in IOT Core
  async getThingGroups(): Promise<AWS.Iot.ListThingGroupsResponse> {
    await this.configure();
    const params: AWS.Iot.ListThingGroupsRequest = {};
    return this.iot.listThingGroups(params).promise();
  }

  //Update Group for Thing. Deletes from previous group prior to updating.
  async updateThingGroupForThing(thingName: string, newThingGroupName: string): Promise<any> {
    await this.configure();
    // Step 1: Get current Thing Groups for the Thing
    const listThingGroupsParams: AWS.Iot.ListThingGroupsForThingRequest = {
      thingName: thingName,
    };

    return await this.iot.listThingGroupsForThing(listThingGroupsParams).promise()
      .then((response) => {
        const currentThingGroups = response.thingGroups || []; // Default to an empty array if undefined

        if (currentThingGroups.length > 0) {
          // Step 2: Remove Thing from existing Thing Groups
          const removeThingParams: AWS.Iot.RemoveThingFromThingGroupRequest = {
            thingGroupName:  (currentThingGroups[0] as AWS.Iot.GroupNameAndArn).groupName, // Assuming a Thing is only in one Thing Group
            thingName: thingName,
          };
          return this.iot.removeThingFromThingGroup(removeThingParams).promise();
        }

        return response;
      })
      .then(() => {
        // Step 3: Add Thing to the new Thing Group
        const addThingParams: AWS.Iot.AddThingToThingGroupRequest = {
          thingGroupName: newThingGroupName,
          thingName: thingName,
        };

        return this.iot.addThingToThingGroup(addThingParams).promise();
      });
  }

  //Delete Thing and remove from all groups
  async deleteThing(thingName: string): Promise<any> {
    await this.configure();

    const ListThingPrincipals: AWS.Iot.ListThingPrincipalsRequest = {
      thingName: thingName
    }

    try{
      const response = await this.iot.listThingPrincipals(ListThingPrincipals).promise().then(async (response) => {

        if(response.principals && response.principals.length > 1){
          const principals = response.principals;

          for(const principal of principals){
            const detachThingPrincipalRequest: AWS.Iot.DetachThingPrincipalRequest = {
              thingName: thingName,
              principal: principal.toString()
            }

            await this.iot.detachThingPrincipal(detachThingPrincipalRequest).promise().then(() => {});
          }
          //Step 1: Remove Thing from all Thing Groups
          return this.removeThingFromAllGroups(thingName).then(() => {
            // Step 2: Delete the Thing
            const deleteThingParams: AWS.Iot.DeleteThingRequest = {
              thingName: thingName,
            };
            return this.iot.deleteThing(deleteThingParams).promise();
          });
        }
        if(response.principals && response.principals.length === 1){
          const principal = response.principals;

          if(principal){
            const detachThingPrincipalRequest: AWS.Iot.DetachThingPrincipalRequest = {
              thingName: thingName,
              principal: principal.toString()
            }

            return this.iot.detachThingPrincipal(detachThingPrincipalRequest).promise().then(() => {
              //Step 1: Remove Thing from all Thing Groups
              return this.removeThingFromAllGroups(thingName).then(() => {
                // Step 2: Delete the Thing
                const deleteThingParams: AWS.Iot.DeleteThingRequest = {
                  thingName: thingName,
                };
                return this.iot.deleteThing(deleteThingParams).promise();
              });
            });
          }
        }
        if(!response.principals){
           //Step 1: Remove Thing from all Thing Groups
           return this.removeThingFromAllGroups(thingName).then(() => {
            // Step 2: Delete the Thing
            const deleteThingParams: AWS.Iot.DeleteThingRequest = {
              thingName: thingName,
            };
            return this.iot.deleteThing(deleteThingParams).promise();
          });
        }
        return Promise.reject('Error in API');
      });
      return response;
    }
    catch(error){
      console.error('Error: ' + error);
      this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      throw error;
    }
  }

  //Remove Thing from all groups it might exist in
  async removeThingFromAllGroups(thingName: string): Promise<any> {
    await this.configure();
    // Get current Thing Groups for the Thing
    const listThingGroupsParams: AWS.Iot.ListThingGroupsForThingRequest = {
      thingName: thingName,
    };

    return await this.iot.listThingGroupsForThing(listThingGroupsParams).promise()
      .then((response) => {
        const currentThingGroups = response.thingGroups || [];

        // Remove Thing from each Thing Group
        const promises: Promise<any>[] = currentThingGroups.map((thingGroup) => {
          const removeThingParams: AWS.Iot.RemoveThingFromThingGroupRequest = {
            thingGroupName: thingGroup.groupName,
            thingName: thingName,
          };

          return  this.iot.removeThingFromThingGroup(removeThingParams).promise();
        });

        return Promise.all(promises);
      });
  }

  // Function to add/update a certificate for a Thing in AWS IoT Core
  async updateCertificate(thingName: string): Promise<void> {
    await this.configure();
    try {
     // Generate a new key and certificate for the Thing
     const createKeysAndCertificateResponse = await this.iot
     .createKeysAndCertificate({
       setAsActive: true,
     })
     .promise();

      const certificateArn = createKeysAndCertificateResponse.certificateArn || "";

      // Attach the certificate to the Thing
      await this.iot
        .attachThingPrincipal({
          thingName: thingName,
          principal: certificateArn,
        })
        .promise();

        // Attach the desired policy to the certificate
        await this.iot
        .attachPolicy({
          policyName: 'MWS-B01', // Specify the policy name here
          target: certificateArn,
        }).promise();

        // Retrieve the certificate details
      const certData = await this.iot.describeCertificate({ certificateId: certificateArn.split('/')[1] }).promise();

      // Create a zip folder using jszip
     const zip = new JSZip();

      // Add certificate files to the zip
      zip.file('certificate.pem.crt', certData.certificateDescription?.certificatePem || '');
      zip.file('public.pem.key', createKeysAndCertificateResponse.keyPair?.PublicKey || '');
      zip.file('private.pem.key', createKeysAndCertificateResponse.keyPair?.PrivateKey || '');

     // Generate the zip content
     const zipContent = await zip.generateAsync({ type: 'blob' });

     //Upload Certificates to s3
     this.s3.uploadCertificateFolderToS3(thingName, zipContent, `certificates-${thingName}.zip`);
    } catch (error) {
      console.error('Error adding/updating certificate:', error);
      this.cognitoService.verifySession();
      throw error;
    }
  }

  // Function called to delete a thing
  ShowDeleteThingModal(thingName: AWS.IotData.ThingName){
    this.thingName = thingName;

    // Set the HTML show/hide variables
    this.showDeleteThing = true;
  }



  // Method to get the count of devices
  /**
   * Asynchronously retrieves the count of devices based on the specified thing type and models.
   * @param thingType - The type of thing/device to filter.
   * @param models - An array of model names to filter devices.
   * @returns A Promise that resolves to an array of objects, each containing the model name and its corresponding device count.
   */
  async getDeviceCounts(thingType: string, models: string[]): Promise<{ modelName: string, count: number }[]> {
    try {
      // Call listThings to get the list of devices
      const devices = await this.listThings();

      // Use map to create an array of objects with model names and their corresponding device counts
      const counts = models.map(model => {
        const count = devices.reduce((accumulator, device) =>
          device.thingName && device.thingName.includes(this.buildSearchString(thingType, model)) ? accumulator + 1 : accumulator, 0);

        return { modelName: model, count };
      });

      return counts;
    } catch (error) {
      this.cognitoService.verifySession();
      // Log and rethrow any errors that occur during the process
      console.error('Error getting device counts:', error);
      throw error;
    }
  }

  buildSearchString(thingType: string, model: string): string {
    // Construa a string de busca no formato "thingType-model"
    return `${thingType}-${model}`;
  }


  getThingCertificateDescription(thingName: string = "MWS-B01-102"): void {
    this.iot.listThingPrincipals({ thingName }, (err, data) => {
      if (err) {
        console.error('Error fetching Thing principals:', err);
      } else {
        // The principals array should contain the certificate ARN
        const certificateArn = data.principals?.[0];

        if (certificateArn) {

        // Extract the certificate ID from the ARN
        const certificateId = certificateArn.split('/')[1];

        // Use describeCACertificate to get more details
        this.iot.describeCertificate({ certificateId }, (err, certData) => {
          if (err) {
            console.error('Error fetching certificate details:', err);
          } else {

            // Convert certificate details to JSON string
            const certJson = JSON.stringify(certData, null, 2);

            // Create a Blob with the JSON data
            const blob = new Blob([certJson], { type: 'application/json' });

            // Create a link element
            const a = document.createElement('a');

            // Set the link's href to the Blob URL
            a.href = window.URL.createObjectURL(blob);

            // Set the link's download attribute to specify the filename
            a.download = 'thing_certificate.json';


            // Append the link to the body
            document.body.appendChild(a);

             // Trigger a click on the link to start the download
             a.click();

             // Remove the link from the body
             document.body.removeChild(a);
          }

        });
        }
      }
    });
  }

  // Function triggered when shadow is updated in modal-device-config
  shadowSuccessUpdated(){
    this.shadowUpdate.next();
  }

  // Function called to insert a log in DB when a user update the shadow of a device
  updateShadowDBLog(userSub: string, date: number){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in shadowUpdateLogs url whit the passed data then return a response
    return this.http.post(environment.api.stage + environment.api.route.shadowUpdateLogs, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "thing_name": this.thingName,
        "user_id": userSub,
        "date": date
      }, { headers: headers }
    ).subscribe((response) => {});
  }


  // Get all device associations
  async getDeviceRelationship(thingName: string){

    // Append thingNmae to the url
    const url = environment.api.stage + environment.api.route.getDeviceRelationshipByThingname + "&thing_name=" + thingName;

    return this.http.get(url).pipe(
      map(async (response) => {
        // Process the response data here if needed
        const tempDeviceAssociation = response;
        this.deviceAssociationRow = tempDeviceAssociation;
        this.deviceBinId = this.deviceAssociationRow.bin_id;

        // variables that receive parameters for creating the device to check health status
        let deviceLastUpdate;
        let deviceBateryLevel;
        let distanceToLid;
        let binCapacity ;
        let status;
        let distributorId = this.deviceAssociationRow.distributor_id;
        let clientId = this.deviceAssociationRow.client_id;
        let fillLevel;

        // Search for the object in the devicesArray with the corresponding thingName
        const deviceFound = this.devicesArray.find(device => device.thingName === thingName);

        // Reset bin details array parameters
        // this.binService.resetBinDetailArray();

        // Get the bin detail array
        this.modal.binArray = await this.binService.setBinDetailArray();

        if (deviceFound) {
          // if device found - update variables that will be used to create the device
          deviceLastUpdate = deviceFound.lastUpdate; // get timestamp
          deviceBateryLevel = deviceFound.dat.bat; // get battery remaining
          distanceToLid = deviceFound.dat.dst; // get distance to lid remaining
          binCapacity = this.modal.obtainBinDepthByBinId(this.deviceBinId) // get bin height (capacity)

          // get the fill level
          fillLevel = this.checkFillLevelPercentage(distanceToLid, binCapacity)

          // Checking device's health status
          if (binCapacity != null) {

            // if(this.deviceBinId === '') {
            //   status = "Inactive";
            // } else if (deviceBateryLevel == undefined || '') {
            //   status = "Inactive";
            // } else {
              let isHealthy = this.checkDeviceHealthy(deviceLastUpdate, deviceBateryLevel)
              if(isHealthy) {
                status = "Healthy"
              } else {
                status = "Unhealthy"
              }
            // }

          } else {
            // set device health status to Unhealthy directly if bin capacity = null
            status = "Inactive"
          }

        }

        // Create device status object
        const deviceStatus: DeviceStatus = {
          thingName: thingName,               // Assign the thingName property
          bin_id: this.deviceBinId,           // Assign the bin_id property
          distributor_id: distributorId,      // Assign the distributor_id property
          client_id: clientId,                // Assign the client_id property
          last_update: deviceLastUpdate,      // Assign the last_update property
          batery_level: deviceBateryLevel,    // Assign the battery_level property
          distance_to_lid: distanceToLid,     // Assign the distance_to_lid property
          bin_capacity: binCapacity,          // Assign the bin_capacity property
          status: status,                     // Assign the status property
          fill_level: fillLevel               // Assign the fill level property
        }

        // if the device has client id - create an array of things by client
        if(!!deviceStatus.client_id) {
          this.clientListThingsArray.push((deviceStatus))
        }

        // if the device has distributor id - create an array of things by client
        if(!!deviceStatus.distributor_id) {
          this.distributorListThingsArray.push((deviceStatus))
        }

        // Add device status object to devicesStatusArray
        this.devicesStatusArray.push(deviceStatus)

        console.log(this.clientListThingsArray)
        console.log(this.distributorListThingsArray)
        console.log(this.devicesStatusArray)

      }) ,

      catchError((error) => {
          // Log the API error for debugging purposes
          console.error('API Error:', error);
          // Re-throw the error for the calling code to handle
          throw error(error);
      }));


  }

  getBatteryPercentage(batteryRemaining: number) {
    // Will put this.pourcentage at zero if the pourcentage received is null or not a number
    if((batteryRemaining !== 0 || batteryRemaining !== (null) || batteryRemaining !== undefined) && typeof(batteryRemaining) === 'number'){
      // Convert the max voltage of the batery(3.7V) in pourcentage 0% to 100%
      this.pourcentage = Math.round((batteryRemaining / 3.7) * 100);
    }else{
      this.pourcentage = 0;
    }
    // If the batery is more then 3.7V it's because she is in charging mode
    if(batteryRemaining > 3.7){
      this.pourcentage = 100;
      this.inCharge = true;
    }

    // Put the good color for the text depend on the pourcentage of the battery
    switch(true){
      case this.pourcentage >= 0 && this.pourcentage <= 30:
        this.color = 'red';
        break;

      case this.pourcentage >= 31 && this.pourcentage <= 60:
        this.color = 'yellow';
        break;

      case this.pourcentage >= 61:
        this.color = 'green';
        break;

      default:
        this.color = 'red';
        break;
    }
    return this.pourcentage;
  }

  // Method to calculate and check the fill level percentage of a bin
  checkFillLevelPercentage(distanceToLid: number, binCapacity: any): string {

    // Flag to indicate if the verification can proceed
    let canBeverified = true;

    let result;

    // Calculate the percentage fill based on the distance to the lid and bin capacity
    const percentageFill = ((binCapacity - distanceToLid) / binCapacity) * 100;

    // Check if the battery level is invalid or empty


    // Check if the distance to the lid is greater than the bin capacity
    if(distanceToLid > binCapacity || !canBeverified) {
      canBeverified = false;
      // uncomment to debug
      // console.error(
      //   "Problems when calculating the fill level - remaining fill level measured by the sensor is greater than the total capacity registered for the bin"
      //   );
    }

    // Check if the calculated percentage fill is NaN or if bin capacity is not provided
    if (Number.isNaN(percentageFill) || binCapacity == null || !canBeverified) {
      canBeverified = false;
      // uncomment to debug
      // console.error(
      //   "Problems when calculating the fill level - it must be checked that the bin is properly registered (height/capacity data)"
      //   );
    }

    // If all checks pass, set the result to the calculated percentage fill, otherwise set to "N/A"
    if (canBeverified) {
      result = percentageFill.toFixed(2) + "%";
    } else {
      result = "N/A"
    }

    return result;
  }

  isDateGapGreaterThan48Hours(dateToCompare: Date): boolean {
    // Get the current date and time
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate.getTime() - dateToCompare.getTime();

    // Convert the time difference to hours
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    // Check if the gap is greater than 48 hours
    return hoursDifference > 48;
  }

  isBinIdPopulated(thingName: string, array: BinArrayItem[]): boolean {
    const matchingItem = array.find(item => item.thing_name === thingName);

    return !!matchingItem && !!matchingItem.bin_id;
  }

  // Function used in device modal delete thing when user confirm that he delete a thing
  confirmDeleteThing(){
    if(this.desiredDeleteThingName === this.thingName){
      try{
        this.deleteThing(this.thingName).then((response) => {
          // const httpResponse = response.httpResponse;

          if(response.$response && response.$response.httpResponse.statusCode === 200){
            this.showDeleteThing = false;
            this.showDeleteDataThing = true;
          }
        });
      }
      catch(error){
        console.error('Error: ' + error);
        this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
      }
    }else{
      this.systemMessageService.selectRibbon('danger', 'selectedThingNameNotGood');
    }
  }

  // Function called in addition of delete thing to delete data of the thing in DB
  async confirmDeleteDataThing(){
    try{
      // Section when user select the sitch to delete device reference in DB
      if(this.deleteReference){
        // Call the function that call the lambda function
        (await this.deleteReferenceLambda()).subscribe();
      }

      // Section when user select the switch to delete device logs from DB
      if(this.deleteLogs){
        // Call the function that call the lambda function
        (await this.deleteLogsLambda()).subscribe();
      }

      // Section when user select the switch to delete report datas
      if(this.deleteReportDatas){
        // Call the function that call the lambda function
        (await this.deleteReportDataLambda()).subscribe();
      }

      this.showDeleteDataThing = false;
      this.desiredDeleteThingName = '';
      this.thingName = '';
      window.location.reload();
    }
    catch(error){
      console.error('Error: ' + error);
      this.systemMessageService.selectRibbon('danger', 'alert-danger-generic-message');
    }
  }

  // Function that call the lambda function to delete all device reference
  async deleteReferenceLambda(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in updateBinModel url whit the passed data then return a response
    return this.http.post(environment.api.stage + environment.api.route.deleteDeviceReferences, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "thing_name": this.thingName
      }, { headers: headers }
    );
  }

  // Funciton that call the lambda function to delete all device logs
  async deleteLogsLambda(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in updateBinModel url whit the passed data then return a response
    return this.http.post(environment.api.stage + environment.api.route.deleteDeviceLogs, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "thing_name": this.thingName
      }, { headers: headers }
    );
  }

  // Function that call the lambda function to delete all device report datas
  async deleteReportDataLambda(){
    // Define the HTTP headers with content type
    const headers = new HttpHeaders({
      'Content-Type':  'application/json' // Adjust content type as needed
    });
    // Will call the lambda function in updateBinModel url whit the passed data then return a response
    return this.http.post(environment.api.stage + environment.api.route.deleteDeviceReportDatas, {
      // Doubled coats things are used into lambda function as data and used for the SQL's calls that those functions does
        "thing_name": this.thingName
      }, { headers: headers }
    );
  }
}


