import { Injectable } from '@angular/core';

Injectable({
  providedIn: 'root'
})

export const MarketSegment = [
    { "value" : "TE", "label" : "techSegment"},
    { "value" : "SC", "label" : "schoolSegment"},
    { "value" : "MU", "label" : "municipalSegment"},
    { "value" : "RE", "label" : "residentialSegment"},
    { "value" : "MA", "label" : "manufacturingSegment"},
    { "value" : "CO", "label" : "constructionSegment"},
    { "value" : "ST", "label" : "storeSegment"},
    { "value" : "FO", "label" : "foodSegment"},
];
