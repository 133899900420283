import { ValidationService } from './../service/validation.service';
import { Component, OnInit, Renderer2, HostListener, Inject, forwardRef  } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { Subject, tap } from 'rxjs';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { Client } from '../service/client.service';
import { ThemeService } from '../service/theme.service';
import { DistributorsService } from '../service/distributors.service';
import { NavigationService } from '../service/navigation.service';
import { SystemMessageService } from '../service/system-message.service';


@Component({
  selector: 'app-distributors-list',
  templateUrl: './distributors-list.component.html',
  styleUrls: ['./distributors-list.component.css']
})
export class DistributorsListComponent implements OnInit {

  // Variable to store the language selected value
  public selectedLanguage: string = "";

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  // Variables for user filtering
  public mainContent: string = '';
  public userFilterTest: string ="";
  public distributorFilter: string ="";
  public userTest: string = "";
  public searchText: string = '';

  // Subject for search terms
  public searchTerms = new Subject<string>();

  // Arrays to store user data
  public filteredUserTest: any = [];
  public clientArray: any[] = [];
  public clientArrayToFilter: Client[] = [];

  // Flags for UI state
  public showList: boolean = false;
  public filterOn: boolean = false;
  public loadingMoreData = false;

  // Value to manage the pagination
  private itemsPerPage = 3;
  private loadedItems = 0;

  public clientsData: any[] = [];
  public distributorsData: any[] = [];
  public distributorsArray: any[] = [];

  constructor(
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private renderer: Renderer2,
    private cognitoService: CognitoService,
    public validationService: ValidationService,
    public theme: ThemeService,
    public distributorsService: DistributorsService,
    public navigationService: NavigationService,
    public systemMessage: SystemMessageService
  ) {
    this.cognitoService.confirmValidUser();
    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  ngOnInit(): void {
    // Call the getDistributorsList function to retrieve all distributors
    this.getDistributorsList()

    // Set the main content to "clients" by default
    this.mainContent = "clients";

  }

  refreshList() {
    this.localStorageService.addItem('entitiesContent', 'distributors')
    window.location.reload();
  }

  getDistributorsList() {
     // Use the clientService to fetch client data from an API
    this.distributorsService.getDistributors().pipe(
      // Use tap from rxjs/operators for side effects
      tap({
        next: (response: any) => {
          this.distributorsData = response; // Fill the array with customer data
          this.distributorsArray = this.distributorsData; // Assign the client data to the clientArray
          this.distributorsArray = this.distributorsArray.slice(0, this.itemsPerPage);

          this.loadedItems = this.itemsPerPage;
        },
        error: (error: any) => {
          console.error('Error when fetching customer data:', error);
        }
      })
    ).subscribe(); // Subscribe to trigger the observable
  }

  loadMoreItems() {
    // Slice the remaining items based on the loaded items and items per page
    const remainingItems = this.distributorsData.slice(this.loadedItems, this.loadedItems + this.itemsPerPage);

    // Add more items to the displayed list by concatenating the remaining items
    this.distributorsArray = this.distributorsArray.concat(remainingItems);

    // Update the count of loaded items
    this.loadedItems += this.itemsPerPage;
  }

   // Change language based on user selection
  changeLanguage(language: string) {
    this.translate.use(language);
  }

  ngAfterViewInit(): void {
    // Call the function to detect if the user has reached the end of the page on a mobile device
    this.detectEndOfPageOnMobile();
  }

  // Function to load initial user data and reset the loading state
  loadInitData(): void {
    this.getDistributorsList()
  }

  // Function to perform user search based on the entered filter text
  search() {
    // Convert the entered filter text to lowercase
    const value = (this.distributorFilter || '').toLowerCase();

    // Determine whether to show the filtered client list based on the presence of filter text
    this.showList = value !== '';

    if (this.showList) {
      // Filter the clientsData based on the entered filter text and update the clientFound array
      this.distributorsArray = this.distributorsData.filter((v) => {
        // Ensure that v.client_name is defined before calling toLowerCase
        return v.distributor_name && v.distributor_name.toLowerCase().includes(value);
      });

      // Set the filterOn flag to indicate that filtering is active
      this.filterOn = true;
    } else {
      this.distributorsArray = []
      // Clear the filter and reload the initial client data
      this.filterOn = false;
      this.loadInitData();
    }

    // Clear the filterOn flag if the showList is false (no filter text)
    if (!this.showList) {
      this.filterOn = false;
    }
  }

  // Function to detect if the user has reached the end of the page on a mobile device
  detectEndOfPageOnMobile(): void {
    // Check if the user is using a mobile device
    if (this.isMobileDevice()) {
      // Listen for the 'touchmove' event to track scrolling on the document
      this.renderer.listen('document', 'touchmove', (event: TouchEvent) => {
        // Get the current scroll position, window height, and document height
        const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0;
        const documentHeight = Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.documentElement.clientHeight
        );
        // Check if the user has scrolled to the end of the page and not currently loading more data
        if (scrollPosition + windowHeight >= documentHeight && !this.loadingMoreData) {
          this.loadMoreItems();
        }
      });
    }
  }

  // Function to determine if the current device is a mobile device
  isMobileDevice(): boolean {
    // Get the user agent string and convert it to lowercase
    const userAgent = navigator.userAgent.toLowerCase();

    // Check if the user agent matches any common mobile device keywords
    return /mobile|android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  }

  // Host listener to detect scroll events on the window
  @HostListener('window:scroll', []) onScroll(): void {
    // Get the height of the visible window area
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;

    // Get references to the document's body and html elements
    const body = document.body;
    const html = document.documentElement;

    // Calculate the maximum document height using various metrics
    const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    // Get the current scroll position
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // Check if the user has scrolled near the bottom of the page
    if (documentHeight - windowHeight - scrollPosition < 100 && !this.loadingMoreData && !this.filterOn) {
      this.loadMoreItems()
    }
  }

  // Function to format a number sequence as a phone number
  formatarNumero(numberSequence: string | undefined | null): string {
    // Check if the numberSequence is undefined or null
    if (numberSequence === undefined || numberSequence === null) {
      return ''; // Return an empty string, or handle the case appropriately
    }

    // Remove non-numeric characters from the numberSequence
    const justNumbers = numberSequence.replace(/\D/g, '');

    // Check if the cleaned numberSequence has a length of 10 (typical for phone numbers)
    if (justNumbers.length === 10) {
      // Split the number into three parts: (XXX) XXX-XXXX
      const parte1 = justNumbers.slice(0, 3);
      const parte2 = justNumbers.slice(3, 6);
      const parte3 = justNumbers.slice(6);

      // Return the formatted phone number
      return `(${parte1}) ${parte2}-${parte3}`;
    } else {
      // If the length is not 10, return the original numberSequence
      return numberSequence;
    }
  }
}
