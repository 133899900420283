import { CommonModule } from '@angular/common';
import { Component, OnInit, ElementRef, Inject, forwardRef } from '@angular/core';
import { RoleService } from '../service/role.service';
import { CognitoService } from '../service/cognito.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { ClientService } from '../service/client.service';
import { FormBuilder } from '@angular/forms';
import { ValidationService } from '../service/validation.service';
import { Router } from '@angular/router';
import { ThemeService } from '../service/theme.service';
import { NavigationService } from '../service/navigation.service';
import { firstValueFrom  } from 'rxjs';
import { WorkOrderTypes } from '../constants/work-order-types'
import { WorkOrderStatus } from '../constants/work-order-status'
import { WorkOrderService } from '../service/work-order.service';
import { SystemMessageService } from '../service/system-message.service';


class DescriptionData {
  description: string = '';
}

@Component({
  selector: 'app-work-order-create',
  templateUrl: './work-order-create.component.html',
  styleUrl: './work-order-create.component.css'
})
export class WorkOrderCreateComponent implements OnInit {

  // Constants import to be used @ select list input
  WorkOrderTypes = WorkOrderTypes;
  WorkOrderStatus = WorkOrderStatus;

  // Retrieve current language selected from local storage
  languageStatus:string = this.localStorageService.getItem('language');

  // Variable to store the description work order text
  descriptionData: DescriptionData = new DescriptionData();

  // Variable to store the language selected value
  public selectedLanguage: string = "";

  // Flag to keep input validations status
  public validInputs: boolean = false;

  // Array Fetch important data
  public clientsData: any[] = [];
  public clientArray: any[] = [];
  public newClientArray: any [] = [];
  public thingsClientArray: any[] = [];
  public binsClientArray: any[] = [];
  public thingNamesArray: any;
  public map: any = {};

  private startTouchY: number = 0;

  // Variable to store the result of a place selection from Google Places Autocomplete.
  // It can hold a valid PlaceResult object or be undefined if no place is selected.
  selectedPlace: google.maps.places.PlaceResult | undefined;

  constructor(
    private localStorageService: LocalStorageService,
    @Inject(forwardRef(() => TranslateService)) private translate: TranslateService,
    public cognitoService: CognitoService,
    public roleService: RoleService,
    public clientService: ClientService,
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private el: ElementRef,
    private router: Router,
    public theme: ThemeService,
    public navigationService: NavigationService,
    public workOrderService: WorkOrderService,
    public systemMessage: SystemMessageService
  ) {

    this.cognitoService.confirmValidUser();
    this.roleService.getRoles();

    // Check if the user has selected a language in local storage
    //or use a default language
    if (this.languageStatus == null){
      // Set the default language to French
      translate.use('fr');
    } else {
      // Set the default language to the user's selected language
      translate.use(this.languageStatus);
    }
  }

  // Function called when the component is initialized
  async ngOnInit() {
    // Retrieve the current user information
    this.getCurrentUser();

    await this.cognitoService.getUserType();

    // Clear any lingering data related to creating a user client in the Cognito service
    this.cognitoService.clearCognitoUserData();

    // Fetch the list of clients to populate the options for the select client input
    this.getClientList();
  }

  async getCurrentUser() {
    try {
      // Retrieve the current user's sub (subject) using the Cognito service
      // and assign it to the created_by property in the workOrderData of workOrderService.
      this.workOrderService.workOrderData.created_by = await this.cognitoService.getCurrentUserSub();
    } catch (error) {
      // If there's an error while obtaining the current user's data,
      // log an error message to the console.
      console.error('Error retrieving current user data:', error);
    }
  }

  getClientList() {
    try {
      // Use the clientService to fetch client data from an API
      this.clientService.getClients().subscribe(
        // Successful response callback
        (response: any) => {
          // Store the API response in the clientsData array
          this.clientsData = response;

          // Create a copy of the clientArray for further processing
          this.clientArray = this.clientsData;

          // Remove Muirwood Studio from client List to display correctly to selection client list
          this.clientArray = this.clientArray.filter(client => client.client_name !== '');

          // Deduplicate client data based on client_name and client_id
          this.newClientArray = this.clientArray.reduce((accumulator, item) => {
            const key = item.client_name + item.client_id;
            if (!this.map[key]) {
              this.map[key] = true;
              // Add unique clients to the newClientArray
              accumulator.push({ client_id: item.client_id, client_name: item.client_name });
            }
            return accumulator;
          }, []);
        }
      );
    } catch (error) {
      // Handle and log any errors that occur during the process
      console.error("Error: ", error);
    }
  }

  // Function to fetch data (Thing Names and Bins IDs) based on the client ID or distributor ID
  public async getDataUsingClientId() {
    // Initialize arrays to store Thing Names and Bins IDs
    this.thingsClientArray = [];
    this.binsClientArray = [];

    // Check if the client_id property in workOrderData is truthy
    switch (true) {
      case Boolean(this.workOrderService.workOrderData.client_id):

        try {
          // Retrieve Thing Names List and Bins ID List using the client_id
          await this.workOrderService.getThingNamesList(this.workOrderService.workOrderData.client_id);
          await this.workOrderService.getBinsIdList(this.workOrderService.workOrderData.client_id);



          // Process Thing Names List
          for(let i = 0; i < this.workOrderService.thingNamesList.length; i++) {
            this.thingsClientArray.push(this.workOrderService.thingNamesList[i].thing_name);
          }
          // Process Bins ID List
          for(let i = 0; i < this.workOrderService.binNamesList.length; i++) {
            this.binsClientArray.push(this.workOrderService.binNamesList[i].bin_id)
          }

        } catch (error) {
          // Handle errors during role retrieval
          console.error('Error:', error);
        }
        break;
      case Boolean(this.workOrderService.workOrderData.distributor_id):
        break;

      default:
        break;
    }



  }

  // Function to update the deadline and date data based on user input
  updateDeadline(event: any) {
    // Store the current date and time
    const currentDate = new Date();

    // Get the date and time string from the user input
    const selectedDateTimeString = event.target.value;

    // Create an instance of Date using the user-selected date and time
    const selectedDateTime = new Date(selectedDateTimeString);

    // Update the work order deadline with the selected date and time in milliseconds
    this.workOrderService.workOrderData.work_order_deadline = selectedDateTime.getTime().toString();

    // Update the work order datetime with the current date and time in milliseconds
    this.workOrderService.workOrderData.work_order_datetime = currentDate.getTime().toString();

    // Update the 'created' property with the transformed current date in 8-digit format
    this.workOrderService.workOrderData.created = this.transformDateTo8Digits(currentDate).toString();
  }

  // Function to transform a Date object to an 8-digit number representation (YYYYMMDD)
  transformDateTo8Digits(inputDate: Date): number {
    const year = inputDate.getFullYear(); // Extract year, month, and day components from the inputDate
    const month = inputDate.getMonth() + 1; // Months are zero-indexed (0 to 11)
    const day = inputDate.getDate();

    // Ensure adding leading zeros for single-digit months and days
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;

    // Concatenate year, month, and day as a string and convert to a number
    const result = parseInt(`${year}${formattedMonth}${formattedDay}`, 10);

    return result;
  }

  // Function to transform a date string to a numeric representation (YYYYMMDD)
  transformDateToNum(data: string): string {
    // Remove non-numeric characters from the input date string
    const numericDate = data.replace(/\D/g, '');

    // Ensure that we have at least 8 characters before formatting
    if (numericDate.length >= 8) {
      // Format the numeric date string to 'YYYYMMDD'
      return numericDate.substring(0, 8);
    } else {
      // Return an empty string if the input does not have enough characters
      console.error('The input does not have enough characters')
      return '';
    }
  }

  // Function to adjust a label as needed
  getTranslatedLabel(label: string): string {
    // Logic to adjust the label as needed
    if (label.startsWith('_')) {
        // Remove the underscore and capitalize the first letter
        return label.slice(1, 2).toUpperCase() + label.slice(2);
    }
    // Return the label unchanged if it doesn't start with '_'
    return label;
  }

  validateFormInputs() {
    // Initialize the flag for valid inputs
    this.validInputs = false;

    // Extract data from the work order service for validation
    const createWorkOrderData = [
      this.workOrderService.workOrderData.work_order_deadline,
      this.workOrderService.workOrderData.work_order_datetime,
      this.workOrderService.workOrderData.work_order_type,
      this.workOrderService.workOrderData.created,
      this.workOrderService.workOrderData.work_order_status,
      this.descriptionData.description,
      this.workOrderService.workOrderData.client_id,
      this.workOrderService.workOrderData.created_by,
      this.workOrderService.workOrderData.creator_entity_type,
      this.workOrderService.workOrderData.creator_entity_id,
      this.workOrderService.workOrderData.bin_id,
      this.workOrderService.workOrderData.thing_name
    ];

    // Check if any variable is empty, null, or undefined
    for (const item of createWorkOrderData) {
      if (item === undefined || item === null || item === '') {
        // Return false if any variable fails validation
        return false;
      }
    }
    // Return true if all variables pass validation
    return true;
  }

  // Function triggered when touch starts for scrolling
  startScroll(event: TouchEvent) {
    // Store the initial Y coordinate of the touch
    this.startTouchY = event.touches[0].clientY;
  }

  // Function to handle scrolling during touch movement
  scrollTextarea(event: TouchEvent) {
    // Calculate the change in Y coordinate since the last touch event
    const deltaY = event.touches[0].clientY - this.startTouchY;

    // Update the startTouchY for the next calculation
    this.startTouchY = event.touches[0].clientY;

    // Get the textarea element by its ID ('descricao')
    const textarea = document.getElementById('descricao') as HTMLTextAreaElement;

    // Update the scrollTop property of the textarea to simulate scrolling
    textarea.scrollTop += deltaY;
  }

  // Function triggered when touch ends, resetting the startTouchY
  stopScroll() {
    // Reset the startTouchY to 0 when touch ends
    this.startTouchY = 0;
  }

  // Function triggered when Create button is clicked
  async createBtn() {
    // Set the description from the input to the work order data
    this.workOrderService.workOrderData.description = this.descriptionData.description;

    // If the work order status is empty, set it to "O" (assuming it represents a default value)
    if (this.workOrderService.workOrderData.work_order_status === "") {
      this.workOrderService.workOrderData.work_order_status = "O";
    }

    // Validate the form inputs before proceeding
    if (this.validateFormInputs()) {

      try {
        // Create a new work order and wait for the result
        const result = await firstValueFrom(this.workOrderService.createWorkOrder());

        // Display success message using the system message service
        this.systemMessage.selectRibbon('success',"createOrderSuccess")
      } catch (error) {
        // Display error message and log the detailed error information
        this.systemMessage.selectRibbon('danger',"createOrderError")
        console.error('Error creating work order:', error);
      }
    } else {
      // Display error message for invalid inputs and log a simple message
      this.systemMessage.selectRibbon('danger',"createOrderErrorInvalidInputs")
      console.error('Empty input');
    }
  }

  // Function used by distributor to return to his dashboard page
  returnToDistributorDashboard(){
    this.router.navigate(['/distributor-work-order-dashboard']);
  }

  // Function used by client to return to his dashboard
  returnToClientDashboard(){
    this.router.navigate(['/client-dashboard']);
  }

  // function to reload page when cancel button is clicked
  pageReload() {
    this.localStorageService.addItem('', '') // please check if its necessary
    this.router.navigate(['/work-order-list']); // Redirect to the '/work-order-list'
  }

}

